import { colors } from 'theme/Colors'
import styled from 'styled-components'
import MuiDrawer from '@mui/material/Drawer'
import { Divider, Theme } from '@mui/material'
import { styled as MUIStyled } from '@mui/material/styles'
import { ReactComponent as ColoredSquare } from 'assets/images/coloredSquare.svg'

const drawerWidth = 244

const openedMixin = (theme: Theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  background: theme.palette.primary.main,
})

const closedMixin = (theme: Theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: theme.palette.primary.main,
  overflowX: 'hidden',
  width: '80px',
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
})

const Drawer = MUIStyled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(
  // @ts-ignore
  ({ theme, open }) => {
    return {
      width: drawerWidth,
      '& .MuiPaper-root': {
        display: 'flex',
        justifyContent: 'space-between',
      },
      // @ts-ignore
      boxShadow: theme.palette.shadows.main,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      }),
    }
  }
)

const CustomDivider = styled(Divider)`
  && {
    border-color: ${colors.navyGrey['200']};
    margin: 0 18px;
  }
`
const StyledSquare = styled(ColoredSquare)`
  & rect {
    fill: ${(props) => props.fill || '#fff'};
  }
`
const Container = styled.div``
const ListButtonsWrapper = styled.div``
const IconButtonWrapper = styled.div`
  padding-bottom: 10px;
  border-left: 1px solid #fff;
  & svg {
    padding: 12px 12px 12px 16px;
    box-sizing: initial;
  }

  .project-name-wrapper {
    display: flex;
    align-items: center;

    .project-name {
      ${({ theme }) => ({ ...theme.typography.regular })};
      font-weight: bold;
      color: ${colors.white};
      opacity: 0.75;
    }
  }

  .client-name {
    ${({ theme }) => ({ ...theme.typography.caption })};
    color: ${colors.white};
    opacity: 0.75;

    margin-left: 16px;
  }
`

export { ListButtonsWrapper, IconButtonWrapper, Container, StyledSquare, CustomDivider, Drawer }
