/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
export enum NavigationItems {
  Inbox = 'Inbox',
  Tasks = 'Tasks',
  Projects = 'Projects',
  Settings = 'Settings',
  Insights = 'Insights',
  Dashboard = 'Dashboard',
  Favorites = 'Favorites',
  GlobalAssets = 'Global Assets',
}
