import { NOTIFICATIONS_PAGINATION_LIMIT } from 'helpers/constants'
import {
  GetAllNotificationsQuery,
  GetAllNotificationsDocument,
  useSetNotificationAsReadMutation,
} from 'generated/graphql'

export const useSetAsRead = () => {
  const [setNotificationAsRead] = useSetNotificationAsReadMutation()

  return async (notificationId: string, userId: string) => {
    await setNotificationAsRead({
      variables: {
        input: {
          notificationId,
          userIds: [userId],
        },
      },
      update: (cache) => {
        const notificationsCache = cache.readQuery({
          query: GetAllNotificationsDocument,
          variables: {
            unreadArgs: { limit: NOTIFICATIONS_PAGINATION_LIMIT },
            readArgs: { limit: NOTIFICATIONS_PAGINATION_LIMIT },
          },
        }) as GetAllNotificationsQuery

        const unread = notificationsCache.notifications.unread.notifications
        const read = notificationsCache.notifications.read.notifications

        const modifiedUnreadNotifications = [...unread]
        const readNotifications = [...read]

        const notificationToModifyIndex = modifiedUnreadNotifications.findIndex(
          (cacheItem) => cacheItem.notification.id === notificationId
        )

        const targetNotificationArray = modifiedUnreadNotifications.splice(
          notificationToModifyIndex,
          1
        )

        if (targetNotificationArray && targetNotificationArray.length) {
          const [targetNotificationToModify] = targetNotificationArray

          const modifiedNotification = { ...targetNotificationToModify }
          modifiedNotification.isRead = true

          readNotifications.push(modifiedNotification)

          readNotifications.sort((a, b) => {
            const timeA = new Date(a.notification.createdAt).getTime()
            const timeB = new Date(b.notification.createdAt).getTime()

            return timeB - timeA
          })

          cache.writeQuery({
            query: GetAllNotificationsDocument,
            variables: {
              unreadArgs: { limit: NOTIFICATIONS_PAGINATION_LIMIT },
              readArgs: { limit: NOTIFICATIONS_PAGINATION_LIMIT },
            },
            data: {
              notifications: {
                ...notificationsCache.notifications,
                unread: {
                  ...notificationsCache.notifications.unread,
                  notifications: modifiedUnreadNotifications,
                },
                read: {
                  ...notificationsCache.notifications.read,
                  notifications: readNotifications,
                },
              },
            },
          })
        }
      },
    })
  }
}
