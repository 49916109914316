import { FC } from 'react'
import { Title, Wrapper } from './bt-deliverable-item.presets'
import type { BTDeliverableItemProps } from './bt-deliverable-item.props'

export const BTDeliverableItem: FC<BTDeliverableItemProps> = ({
  name,
  onClick,
  id,
  styles,
  isSelected,
}) => {
  const onInnerClick = () => {
    onClick(name, id)
  }
  return (
    <Wrapper onClick={onInnerClick} style={{ ...styles }} isSelected={isSelected}>
      <Title isSelected={isSelected}>{name}</Title>
    </Wrapper>
  )
}
