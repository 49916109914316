import styled from '@emotion/styled'

export const BTEditMessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 3px;
  width: 100%;

  input {
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  button {
    padding: 5px;
    min-width: fit-content;

    svg {
      font-size: 15px !important;
    }

    span {
      margin: 0;
    }
  }
`
