import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AnswerCommentType, QuestionTypeId } from 'generated/graphql'
import { QuestionTypes } from 'types/shared'

// import { BriefFormValue } from "../components/forms/briefs/Types"
interface BriefQuestion {
  content: QuestionTypes
  type: QuestionTypeId
  questionId: string
  deliverableId: string
  // briefId: string
}

export interface DeliverableWithQuestionsAndAnswersType {
  id: string
  answer: Partial<AnswerCommentType>
}
interface BriefCreationState {
  step: number
  subStep: number
  selectedDeliverables: []
  document: null | string
  questions: BriefQuestion[]
  briefId: string
  deliverableWithQuestionsAndAnswers: Array<DeliverableWithQuestionsAndAnswersType>
}

export interface StepChangedPayload {
  step?: number
  subStep?: number
}

const initialState: BriefCreationState = {
  step: 0,
  subStep: 0,
  selectedDeliverables: [],
  document: null,
  questions: [],
  briefId: '',
  deliverableWithQuestionsAndAnswers: [],
}

const briefCreationSlice = createSlice({
  name: 'briefCreation',
  initialState,
  reducers: {
    saveDocument(state, { payload }) {
      state.document = payload
    },
    stepChanged: (state, { payload }: PayloadAction<StepChangedPayload>) => {
      if (payload.step !== undefined) {
        state.step = payload.step
      }
      if (payload.subStep !== undefined) {
        state.subStep = payload.subStep
      }
      if (payload.step && !payload.subStep) {
        state.subStep = 0
      }
    },
    setupBriefQuestion(state, { payload }: PayloadAction<BriefQuestion>) {
      const { questionId } = payload
      const question = state.questions.find((el) => el.questionId === questionId)
      if (question) {
        question.content = payload.content
        return
      }
      state.questions.push(payload)
    },
    resetQuestions(state) {
      state.questions = []
    },
    setupBriefId(state, { payload }: PayloadAction<string>) {
      state.briefId = payload
    },
    addQuestionWithAnswer(
      state,
      { payload }: PayloadAction<DeliverableWithQuestionsAndAnswersType>
    ) {
      const existQuestionWithAnswer = state.deliverableWithQuestionsAndAnswers.find(
        (questionWithAnswer) => questionWithAnswer.id === payload.id
      )
      if (!existQuestionWithAnswer) {
        state.deliverableWithQuestionsAndAnswers.push(payload)
        return
      }
      existQuestionWithAnswer.answer = payload.answer
    },
    clearQuestionsAndAnswers(state) {
      state.deliverableWithQuestionsAndAnswers = []
    },
  },
})

export const {
  stepChanged,
  saveDocument,
  setupBriefQuestion,
  resetQuestions,
  setupBriefId,
  clearQuestionsAndAnswers,
  addQuestionWithAnswer,
} = briefCreationSlice.actions

export default briefCreationSlice.reducer
