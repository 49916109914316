/* eslint-disable max-len */
import {
  ButtonGroup,
  InputAdornment,
  TextField,
  Button,
  Typography,
  Badge,
  IconButton,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import SearchIcon from '@mui/icons-material/Search'
import Avatar from '@mui/material/Avatar'
import CancelIcon from '@mui/icons-material/Cancel'
import { FC, useState, useEffect, ChangeEvent } from 'react'
import { debounce } from 'ts-debounce'
import { AddMemberStepWrapper } from './bt-create-project-add-members-step.presets'
import type { IBTAddMemberStepProps } from './bt-create-project-add-members-step.props'
import { BTMemberListItem, IMemberListItem } from './components/bt-member-list-item'
import { BTMembersListModal } from './components/bt-mebers-list-modal'
import { OrganizationMember, ProjectRole } from '../../../generated/graphql'
import { useAppSelector } from '../../../redux/store'

export const BTAddMemberStep: FC<IBTAddMemberStepProps> = ({
  setActiveStep,
  title,
  membersData,
  isCreatedMemberImportant,
  formik,
  changeMemberData,
  placeholder,
  excludeUserId,
}: IBTAddMemberStepProps) => {
  // @ts-ignore
  const currentUserId: string = useAppSelector((state) => state.login.cognitoUser)
  const { currentOrganizationId, organizations } = useAppSelector((state) => state.login)

  const [membersList, setMembersList] = useState<Array<IMemberListItem>>([])
  const [open, setOpen] = useState<boolean>(false)
  const [allMembers, setAllMembers] = useState<OrganizationMember[]>([])

  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const filterMembers = (members: Array<IMemberListItem>, searchValue: string) => {
    const filteredMembers = members.filter(({ name }) =>
      name.toLowerCase().includes(searchValue.toLowerCase())
    )

    return excludeMemberById(filteredMembers)
  }

  const excludeMemberById = (allUsers: IMemberListItem[]) => {
    if (excludeUserId) {
      const filteredUsers = allUsers.filter(({ id }) => id !== excludeUserId)

      return filteredUsers
    }
    return allUsers
  }

  const filter = async (searchedMemberName: string) => {
    const allUsers =
      allMembers.map(({ user }) => ({
        id: user.id || '',
        name: user.name || '',
        avatarUrl: user.avatarUrl || '',
        projectRole: ProjectRole.None,
      })) || []

    if (searchedMemberName !== '') {
      const deboucedFunction = debounce(() => filterMembers(allUsers, searchedMemberName), 300)
      setMembersList(await deboucedFunction())
    } else {
      setMembersList(excludeMemberById(allUsers))
    }
  }

  const removeMember = (userId: string) => {
    const { members, setMembers } = membersData
    const currentMemberIndex = members.findIndex(({ id }) => userId === id)
    const currentMemberRole = members[currentMemberIndex].projectRole

    if (currentMemberRole !== ProjectRole.Owner) {
      setMembers((prevState) => prevState.filter(({ id }) => userId !== id))
      if (changeMemberData !== undefined) {
        changeMemberData({
          ...members[currentMemberIndex],
          projectRole: ProjectRole.None,
        })
      }
    }
  }

  useEffect(() => {
    const currentOrganization =
      organizations?.filter(({ id }) => id === currentOrganizationId) || []

    if (currentOrganization.length) {
      const { members } = currentOrganization[0]
      setAllMembers(members as OrganizationMember[])
    }
  }, [])

  useEffect(() => {
    if (allMembers) {
      const allUsers: IMemberListItem[] = allMembers.map(({ user }) => ({
        id: user.id || '',
        name: user.name || '',
        avatarUrl: user.avatarUrl || '',
        projectRole: ProjectRole.None,
      }))
      if (isCreatedMemberImportant) {
        const changedMembers = allUsers.map((member) =>
          member.id === currentUserId ? { ...member, projectRole: ProjectRole.Owner } : member
        )
        setMembersList(changedMembers)
        membersData.setMembers(
          changedMembers.filter(({ projectRole }) => projectRole !== ProjectRole.None)
        )
      } else {
        setMembersList(allUsers || [])
      }
      setMembersList(excludeMemberById(allUsers))
    }
  }, [allMembers])

  const previewMembersList = membersList.map(
    ({ id, avatarUrl, name, projectRole }, index) =>
      index < 5 &&
      projectRole === ProjectRole.None && (
        <BTMemberListItem
          id={id}
          key={id}
          avatarUrl={avatarUrl}
          name={name}
          onClick={() => {
            if (membersData.members.findIndex((elem) => elem.id === id) === -1) {
              const member = {
                id,
                avatarUrl,
                name,
                projectRole: ProjectRole.Member,
              }
              membersData.setMembers((prevState) => [...prevState, member])
              if (changeMemberData !== undefined) {
                changeMemberData(member)
              }
            }
          }}
        />
      )
  )

  const assignedListPreview = membersData.members.map(
    ({ id, avatarUrl }, index) =>
      index <= 2 && (
        <Badge
          key={id}
          overlap="circular"
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          badgeContent={
            <IconButton className="remove-button" onClick={() => removeMember(id)}>
              <CancelIcon fontSize="small" sx={{ color: '#DADADA' }} />
            </IconButton>
          }
        >
          <Avatar src={avatarUrl} sx={{ height: 35, width: 35 }} />
        </Badge>
      )
  )

  return (
    <AddMemberStepWrapper>
      <Typography className="title" component="span" variant="h5">
        {title}
      </Typography>
      <div className="assignees-list">
        {assignedListPreview}
        {membersData.members.length > 3 && (
          <Button className="rest-members-amount" onClick={handleOpen}>
            +{membersData.members.length - 3}
          </Button>
        )}
      </div>
      <TextField
        label={placeholder ? undefined : 'Search'}
        onChange={async (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
          await filter(event.target.value)
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        placeholder={placeholder}
      />
      <div className="members-list">{previewMembersList}</div>
      {setActiveStep && (
        <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
          className="button-group"
        >
          <Button onClick={setActiveStep} disabled={formik?.isSubmitting}>
            Prev
          </Button>
          <LoadingButton
            loading={formik?.isSubmitting}
            variant="contained"
            disabled={membersData.members.length <= 0}
            type="submit"
          >
            Next
          </LoadingButton>
        </ButtonGroup>
      )}
      <BTMembersListModal
        isOpen={open}
        handleCloseModal={handleClose}
        assignedList={membersData.members}
        removeMember={removeMember}
      />
    </AddMemberStepWrapper>
  )
}
