import { TaskStatus, TaskFragment, useUpdateTaskMutation } from '../../generated/graphql'

export function useMoveTask(): (
  task: TaskFragment,
  status: TaskStatus,
  rowVersion: number
) => Promise<void> {
  const [updateTask] = useUpdateTaskMutation()

  return async (task, status, rowVersion) => {
    await updateTask({
      variables: {
        input: { id: task.id, status },
      },
      optimisticResponse: {
        updateTask: {
          task: { ...task, status, rowVersion },
        },
      },
    })
  }
}
