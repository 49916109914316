import styled from '@emotion/styled'
import { colors } from 'theme/Colors'

interface IBTFeedbackMessageWrapperProps {
  collapsed: boolean
}

// eslint-disable-next-line max-len
export const BTFeedbackMessageWrapper = styled.div<IBTFeedbackMessageWrapperProps>`
  display: flex;
  flex-direction: column;
  min-width: 228px;

  .collapse-indicator {
    margin-top: 10px;
    background-color: inherit;
    border: none;
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    transition: all 0.2s ease-in-out;
    width: 100%;
    border-radius: 5px;

    &:hover {
      background-color: ${colors.navyGrey[200]};
    }

    svg {
      font-size: 12px;
      margin-right: 10px;
      margin-left: 8px;
      transition: all 0.2s ease-in-out;

      ${({ collapsed }) => (collapsed && collapsed ? 'transform: rotate(90deg);' : '')}
    }
  }

  .reply {
    border-left: 1px solid ${colors.navyGrey[400]};
    margin-left: 20px;
    padding-left: 10px;
    min-width: 228px;
    margin-top: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .reply-wrapper {
    .reply {
      ${({ collapsed }) => (collapsed && collapsed ? '' : 'margin-top: 0; padding-top: 0;')}
    }

    .MuiCollapse-vertical {
      display: flex;
      gap: 10px;
      flex-direction: column;
    }
  }
`
