import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import { BTLoginFormWrapper } from '../bt-login/bt-login.presets'

export const BTRecoverPassword: FC = () => {
  return (
    <BTLoginFormWrapper>
      <Box className="wrapper" sx={{ boxShadow: 3 }}>
        <Typography variant="h5">Recovery link sent!</Typography>
        <Typography align="center">
          Check your inbox and follow the instructions to reset your password
        </Typography>
      </Box>
    </BTLoginFormWrapper>
  )
}
