import { useAppSelector } from 'redux/store'
import {
  GetAllFeedbackMessagesDocument,
  GetAllFeedbackMessagesQuery,
  useAddFeedbackMemberMutation,
  User,
} from '../../generated/graphql'

export function useAddFeedbackMember() {
  const [addUser] = useAddFeedbackMemberMutation()
  const { assetId } = useAppSelector((state) => state.sidebarApps)

  return async (
    feedbackId: string,
    parentFeedbackMessageId: string,
    user: User,
    childFeedbackMessageId?: string
  ) => {
    await addUser({
      variables: {
        input: {
          feedbackMessageId: childFeedbackMessageId ?? parentFeedbackMessageId,
          userId: user.id,
        },
      },
      update: (cache) => {
        const messages = cache.readQuery({
          query: GetAllFeedbackMessagesDocument,
          variables: {
            input: { feedbackId: assetId ? undefined : feedbackId, assetId },
          },
        }) as GetAllFeedbackMessagesQuery

        if (messages && messages.getFeedbackMessagesByFeedbackId.messages.length) {
          cache.writeQuery({
            query: GetAllFeedbackMessagesDocument,
            variables: {
              input: { feedbackId: assetId ? undefined : feedbackId, assetId },
            },
            data: {
              getFeedbackMessagesByFeedbackId: {
                __typename: 'FeedbackMessageResponse',
                messages: !childFeedbackMessageId
                  ? [
                      ...messages.getFeedbackMessagesByFeedbackId.messages.map((elem) =>
                        elem.feedbackMessageId === parentFeedbackMessageId
                          ? {
                              ...elem,
                              members: [{ __typename: 'User', ...user }],
                            }
                          : elem
                      ),
                    ]
                  : [
                      ...messages.getFeedbackMessagesByFeedbackId.messages.map((elem) =>
                        elem.feedbackMessageId === parentFeedbackMessageId
                          ? {
                              ...elem,
                              replyedComments: elem.replyedComments.map((reply) =>
                                reply.feedbackMessageId === childFeedbackMessageId
                                  ? {
                                      ...reply,
                                      members: [{ __typename: 'User', ...user }],
                                    }
                                  : reply
                              ),
                            }
                          : elem
                      ),
                    ],
              },
            },
          })
        }
      },
    })
  }
}
