import { propEq } from 'ramda'
import { useMoveTask } from 'hooks'
import { colors } from 'theme/Colors'
import { useParams } from 'react-router-dom'
import { useAppSelector } from 'redux/store'
import AddIcon from '@mui/icons-material/Add'
import { FC, useEffect, useState } from 'react'
import { Button, Typography } from '@mui/material'
import { BTTaskCard } from '../shared/bt-task-card'
import { IAssigneeData } from '../shared/bt-assignee'
import ScrollContainer from 'react-indiana-drag-scroll'
import { NEW_TASK_ROW_VERSION } from 'helpers/constants'
import { BTModalWindow } from '../shared/bt-modal-window'
import { BTCreateTaskForm } from '../shared/bt-task-create-form'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { BTAddTaskButtonWrapper } from './bt-project-tasks-layout.presets'
import { BTGridColumn, BTGridsWrapper, IColumnData } from '../shared/bt-grid-column'
import { ProjectTasksDocument, TaskStatus, useAllTasksQuery } from 'generated/graphql'
import { BTTaskPageLayoutWrapper } from '../bt-task-page-layout/bt-task-page-layout.presets'

const STATUS_LABELS: Record<TaskStatus, string> = {
  [TaskStatus.Todo]: 'To do',
  [TaskStatus.InProgress]: 'In progress',
  [TaskStatus.Review]: 'Review',
  [TaskStatus.Complete]: 'Complete',
}

export const BTProjectTasksLayout: FC = () => {
  const moveTask = useMoveTask()
  const { sizes } = useAppSelector((state) => state.header)
  const [isOpen, setIsOpen] = useState(false)
  const { id } = useParams()
  const { data, loading, refetch, client } = useAllTasksQuery()

  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)

  const columnData: Array<IColumnData> = Object.entries(STATUS_LABELS).map(([name, title]) => ({
    name,
    title,
  }))

  const onDragEnd = async (result: DropResult): Promise<void> => {
    if (result.destination) {
      const task = data?.tasks.find(propEq('id', result.draggableId))!

      await moveTask(task, result.destination.droppableId as TaskStatus, task.rowVersion!)
    }
  }

  useEffect(() => {
    refetch()
    if (data?.tasks.length! > 0) {
      client.writeQuery({
        query: ProjectTasksDocument,
        data: { tasks: data?.tasks },
      })
    }
  }, [loading])

  return (
    <BTTaskPageLayoutWrapper>
      <BTGridsWrapper scrollWidth={sizes.width - 80}>
        <ScrollContainer
          vertical={false}
          horizontal
          hideScrollbars={false}
          className="horizontal-scroll-block"
        >
          <DragDropContext onDragEnd={onDragEnd}>
            {columnData.map((elem) => (
              <BTGridColumn
                title={elem.title}
                emptyColumnText="No tasks"
                name={elem.name}
                key={elem.name}
                isDataLoading={loading}
                cardsData={
                  data?.tasks
                    ?.filter(
                      ({ project, archivedAt, status }) =>
                        project?.id === id && archivedAt === null && status === elem.name
                    )
                    .map((task) => {
                      return {
                        id: task.id,
                        card: (
                          <BTTaskCard
                            id={task.id}
                            taskBelongsToId={task.project?.id}
                            description={task.description}
                            title={task.name}
                            labelData={{
                              title: 'Activity: ',
                              description: task.project?.name,
                              bgColor: task.project?.color,
                            }}
                            bgColor={colors.white}
                            assignees={task.assignedTo.map(
                              ({ name, avatarUrl, id: userId }): IAssigneeData => ({
                                name: name ?? '',
                                imageUrl: avatarUrl,
                                id: userId,
                              })
                            )}
                            isNew={task?.rowVersion! <= NEW_TASK_ROW_VERSION}
                            dueDate={task.dueDate}
                            checklist={task.checklist}
                          />
                        ),
                      }
                    }) || []
                }
              />
            ))}
          </DragDropContext>
          <BTAddTaskButtonWrapper>
            <Button type="button" variant="contained" endIcon={<AddIcon />} onClick={handleOpen}>
              <Typography variant="subtitle2">New Task</Typography>
            </Button>
          </BTAddTaskButtonWrapper>
        </ScrollContainer>
      </BTGridsWrapper>
      <BTModalWindow isOpened={isOpen} handleClose={handleClose}>
        <BTCreateTaskForm taskStatus={TaskStatus.Todo} handleClose={handleClose} outerId={id} />
      </BTModalWindow>
    </BTTaskPageLayoutWrapper>
  )
}
