import { useEffect } from 'react'

export const useActionAtEnterKey = () => {
  return (handler: (() => void) | undefined, dependencies: any[] = []) => {
    const setTaskSubmitAtEnterKey = (event: KeyboardEvent): void => {
      if (event.key === 'Enter' && handler) {
        handler()
      }
    }

    useEffect(() => {
      window.addEventListener('keydown', setTaskSubmitAtEnterKey)

      return () => {
        window.removeEventListener('keydown', setTaskSubmitAtEnterKey)
      }
    }, dependencies)
  }
}
