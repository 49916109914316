/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable import/no-unresolved */
import { ActivityType } from 'generated/graphql'

export interface IPreparedNotification {
  id: string
  date: any | null
  isRead: boolean | null
  technical: boolean
  selectable: boolean
  data: {
    avatarUrl: string
    projectId: string
    projectName: string
    clientName: string | undefined | null
    projectColor: string
    activityId: string
    activityType: ActivityType
    activityName: string
    purpose: string | null | undefined
    notificationText: string
    message: string | null | undefined
    sender: [
      {
        avatarUrl: string
        name: string | null | undefined
      }
    ]
    recipients: Array<{
      __typename?: 'User'
      id: string
      name?: string | null
      avatarUrl: string
    }>
  }
}

export interface INotificationsSortedByAge {
  unread: {
    today: (IPreparedNotification | undefined)[]
    lastWeek: (IPreparedNotification | undefined)[]
    older: (IPreparedNotification | undefined)[]
  }
  read: {
    today: (IPreparedNotification | undefined)[]
    lastWeek: (IPreparedNotification | undefined)[]
  }
}

export enum NotificationAge {
  TODAY = 'today',
  LAST_WEEK = 'lastWeek',
  OLDER = 'older',
}
