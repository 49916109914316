import { FC } from 'react'
import { DateTime } from 'luxon'
import { DeadlineLabelWrapper } from './bt-deadline-label.presets'
import type { IDeadlineLabelProps } from './bt-deadline-label.props'
import { ReactComponent as DueIcon } from 'assets/images/dueIcon.svg'

export const BTDeadlineLabel: FC<IDeadlineLabelProps> = ({ deadlineTime }: IDeadlineLabelProps) => {
  const formattedTime = DateTime.fromJSDate(new Date(deadlineTime)).toFormat('HH:mma, d LLL')

  return (
    <DeadlineLabelWrapper>
      <div className="due">
        <DueIcon />
      </div>
      <div className="due-time">{formattedTime}</div>
    </DeadlineLabelWrapper>
  )
}
