import styled from 'styled-components'
import ScrollContainer from 'react-indiana-drag-scroll'

const Wrapper = styled.div`
  height: 82vh;
  width: 19%;
  padding: 40px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  border-right: none;
  transition: all 250ms ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Heading = styled.h1`
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  padding: 0;
  color: #333333;
  align-self: flex-start;
`
const Block = styled.div`
  display: flex;
  gap: 30px;
  flex-direction: column;
  justify-content: space-between;
`

const ButtonInnerText = styled.div`
  text-transform: capitalize;
  color: #ffffff;
  ${({ theme }) => ({ ...theme.typography?.subtitle1 })};
`
const CustomScrollableContainer = styled(ScrollContainer)`
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 90%;
  max-height: 60vh;
`
export { Wrapper, Block, Heading, ButtonInnerText, CustomScrollableContainer }
