/* eslint-disable max-len */
import { FC, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { EMPTY_ARRAY_LENGTH } from '../../../../../../../helpers/constants'
import { useUpdateProject } from '../../../../../../../hooks/project/useUpdateProject'
import { useAppSelector } from '../../../../../../../redux/store'
import { BTAddMemberStep } from '../../../../../../shared/bt-project-add-members-step'
import { IMemberListItem } from '../../../../../../shared/bt-project-add-members-step/components/bt-member-list-item'
import { BTAddMembersWrapper } from './bt-add-members.presets'

export const BTAddMembers: FC = () => {
  const [members, setMembers] = useState<IMemberListItem[]>([])
  const [updating, setUpdating] = useState(false)

  const { id } = useAppSelector((state) => state.login.whoami)
  const updateProject = useUpdateProject()

  const addMembersHandler = async () => {
    setUpdating(true)

    await updateProject(members)

    setUpdating(false)
  }

  return (
    <BTAddMembersWrapper>
      <div className="members-block">
        <BTAddMemberStep
          title="Add team members"
          membersData={{ members, setMembers }}
          placeholder="Search"
          excludeUserId={id}
        />
      </div>
      <LoadingButton
        loading={updating}
        disabled={members.length === EMPTY_ARRAY_LENGTH}
        onClick={addMembersHandler}
        className="save-button"
        variant="contained"
      >
        Done
      </LoadingButton>
    </BTAddMembersWrapper>
  )
}
