import styled from '@emotion/styled'
import { colors } from '../../../theme/Colors'

export const BTLoginFormWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  .MuiAlert-root {
    background-color: #ff5a78;
  }

  .wrapper {
    padding: 60px 80px;
    border-radius: 5px;
    gap: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${colors.white};

    .title {
      color: ${colors.projectColors.black};
    }

    button {
      text-transform: capitalize;
    }

    .inputs-block {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .input-block {
        display: flex;
        flex-direction: column;
        width: 270px;

        span {
          font-size: 12px;
          line-height: 18px;
          font-weight: 400;
          color: ${colors.navyGrey[400]};
          padding-bottom: 5px;
        }

        .Mui-error {
          fieldset {
            border: 1px solid #ff5a78;
          }
        }

        fieldset {
          border: 1px solid ${colors.navyGrey[400]};
        }
      }
    }
  }
  .forgot-pass-link {
    position: absolute;
    transform: translate(221px, 46px);

    text-decoration: none;
    font-weight: 700;
    font-size: 10px;
    line-height: 1.4;
    color: ${colors.projectColors.main};
  }
`
