import { FC } from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import type { IBTCollapseButtonProps } from './bt-collapse-button.props'

export const BTCollabseButton: FC<IBTCollapseButtonProps> = ({
  changeCollapseState,
  replyCommentsAmount,
}) => {
  const collapseHandler = () => changeCollapseState((prevState) => !prevState)

  return (
    <button type="button" className="collapse-indicator" onClick={collapseHandler}>
      <ArrowForwardIosIcon />
      {replyCommentsAmount} reply {replyCommentsAmount > 1 ? 'comments' : 'comment'}
    </button>
  )
}
