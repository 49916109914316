import { QueryResult } from '@apollo/client'
import { ApolloQueryResult } from '@apollo/client/core/types'
import { Exact, ProjectFragment, ProjectQuery, useProjectQuery } from '../../generated/graphql'

export function useProject(projectId?: string): QueryResult<
  ProjectQuery,
  Exact<{
    id: string
  }>
> & { project?: ProjectQuery['projects'][0] } {
  const result = useProjectQuery({
    variables: { id: projectId ?? '' },
    skip: !projectId,
  })

  const project = result.data?.projects[0]
  return { ...result, project }
}
