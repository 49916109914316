import styled from '@emotion/styled'
import { colors } from '../../theme/Colors'

export const BTProjectToolsModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;

  .title {
    margin-bottom: 40px;
  }

  .done-button {
    margin-top: 40px;
    width: 100%;
    text-transform: capitalize;
  }

  .tools-wrapper {
    display: flex;
    flex-direction: column;

    button {
      min-width: 240px;

      justify-content: space-between;
      width: 15vw;
      margin-bottom: 15px;
      border-radius: 5px;
      font-size: 10px;
      line-height: 10px;
      padding: 10px;
      color: ${colors.projectColors.black};
      background-color: ${colors.navyGrey[200]};

      text-transform: none;
      font-weight: 500;
      font-size: 12px;
      line-height: 1.25;
      color: ${colors.projectColors.black};

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`
