import { AppDeliverables, DeliverableWithSelection } from '../../../types/shared'

export function transformDeliverablesByCategory(
  deliverables: Array<DeliverableWithSelection>,
  appDeliverables: Array<AppDeliverables>
) {
  return deliverables.reduce((acc, deliverable) => {
    /**
     * @desc quick fix, to hide artwork from the brief board
     */
    if (deliverable.name === 'Packaging Artwork') {
      return acc
    }
    let flag = false
    appDeliverables.forEach((appDeliverable) => {
      if (appDeliverable.name === deliverable.name) flag = true
    })

    const transformedDeliverable = { ...deliverable, isSelected: flag }
    if (acc[deliverable.category.name]) {
      acc[deliverable.category.name] = acc[deliverable.category.name].concat(transformedDeliverable)
    } else {
      acc[deliverable.category.name] = [transformedDeliverable]
    }
    return acc
  }, {} as { [key: string]: Array<DeliverableWithSelection> })
}
