import { FC } from 'react'
import { IconButton } from '@mui/material'
import { BTAssignee } from '../bt-assignee'
import { useAppSelector } from 'redux/store'
import AddIcon from '@mui/icons-material/Add'
import { Typography } from '@material-ui/core'
import { BTIconButtons } from '../bt-icon-buttons'
import { HeaderWrapper } from './bt-header.presets'
import type { IHeaderProps } from './bt-header.props'
import { BTHeaderTabs } from './components/bt-header-tabs'
import { BTDeadlineLabel } from './components/bt-deadline-label'
import { CheckUserRoleAccess } from 'helpers/CheckUserRoleAccess'

export const BTHeader: FC<IHeaderProps> = ({
  title,
  clientName,
  iconButtonsProps,
  tabsProps,
  assignees,
  deadlineTime,
  refProp,
  assigneeActionButton,
  addToolsModalHandler,
  assigneeModalHandler,
}: IHeaderProps) => {
  const actionHeaderButtons = () => {
    if (iconButtonsProps) {
      return <BTIconButtons buttonsProps={iconButtonsProps} />
    }
    return null
  }

  const { isHide } = useAppSelector((state) => state.header)

  const headerAssignees = assignees !== undefined && (
    <BTAssignee
      assigneeModalHandler={assigneeModalHandler}
      avatarSX={{ height: 32, width: 32 }}
      assignees={assignees}
      actionButton={assigneeActionButton}
      isStacked
    />
  )

  const deadlineLabel =
    deadlineTime !== undefined && deadlineTime !== '' ? (
      <BTDeadlineLabel deadlineTime={deadlineTime} />
    ) : null

  return (
    <HeaderWrapper ref={refProp} isHide={isHide}>
      <div className="header-top-block">
        <div className="header-title-block">
          <div>
            <Typography component="span" className="header-title" variant="h5">
              {title}
            </Typography>
            <CheckUserRoleAccess>{actionHeaderButtons()}</CheckUserRoleAccess>
          </div>
          <Typography component="span" variant="h6" className="header-client-name">
            {clientName}
          </Typography>
        </div>
        <div className="assignees-block">{headerAssignees}</div>
      </div>
      <div className="header-tabs-block">
        <div className="tabs">
          <BTHeaderTabs labels={tabsProps} />
          {addToolsModalHandler && (
            <IconButton size="small" className="tools-button" onClick={addToolsModalHandler}>
              <AddIcon fontSize="small" />
            </IconButton>
          )}
        </div>
        {deadlineLabel}
      </div>
      <div className="bottom-line" />
    </HeaderWrapper>
  )
}

BTHeader.defaultProps = {
  iconButtonsProps: [],
  assignees: [],
}
