import { FC } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { Button, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { Draggable, Droppable, DroppableProvided } from 'react-beautiful-dnd'
import { PuffLoader } from 'react-spinners'
import type { IBTGridColumnProps, IBTCardData } from './bt-grid-column.props'
import { BTGridColumnWrapper } from './bt-grid-column.presets'

export const BTGridColumn: FC<IBTGridColumnProps> = ({
  cardsData,
  title,
  name,
  emptyColumnText,
  columnActionButton,
  isDataLoading,
}) => {
  const cards =
    cardsData.length > 0 ? (
      <>
        {cardsData.map(({ id, card }: IBTCardData, index: number) => (
          <Draggable draggableId={id} index={index} key={id}>
            {(provided) => (
              <div
                key={id}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
              >
                {card}
              </div>
            )}
          </Draggable>
        ))}
      </>
    ) : (
      <div className="empty-tasks-block">{emptyColumnText}</div>
    )

  const columnChild = isDataLoading ? (
    <div className="spinner">
      <PuffLoader color="#0066FF" loading={isDataLoading} size={50} />
    </div>
  ) : (
    cards
  )

  return (
    <BTGridColumnWrapper>
      <div className="column-top-block">
        <span className="column-title">{title}</span>
        <span className="tasks-counter">{cardsData.length}</span>
      </div>
      <ScrollContainer
        horizontal={false}
        vertical
        hideScrollbars={false}
        className="scroll-wrapper"
      >
        <Droppable droppableId={`${name}`}>
          {(provided: DroppableProvided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className="column-dropzone">
              {columnChild}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </ScrollContainer>
      {columnActionButton && (
        <Button
          variant="text"
          startIcon={<AddIcon />}
          className="add-task-button"
          onClick={columnActionButton?.actionHandler}
        >
          {columnActionButton?.title}
        </Button>
      )}
    </BTGridColumnWrapper>
  )
}
