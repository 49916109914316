/* eslint-disable import/no-unresolved */
import styled from 'styled-components'
import PublishIcon from '@mui/icons-material/Publish'
import { BTAppendButton } from 'components/shared/bt-append-button'
import ScrollContainer from 'react-indiana-drag-scroll'
import { colors } from '../../../../../theme/Colors'

const BTPublishIcon = styled(PublishIcon)`
  && {
    color: #ffffff;
  }
`
const BTAssetsPublish = styled(BTAppendButton)`
  && {
    cursor: pointer;
    padding: 5px 10px;
  }
`

const BTAssetsScrollableContainer = styled(ScrollContainer)`
  width: 400px;
  padding: 50px;
  height: auto;
  min-height: 300px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`

const BTLoadingButtonArea = styled.div`
  display: flex;
  justify-content: end;
`

const BTUploadButtonText = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 1.5;

  display: flex;
  align-items: center;
  text-align: center;
`

const BTChangeOrganization = styled.div`
  margin: auto;
  text-align: center;

  font-weight: 400;
  font-size: 10px;
  line-height: 14px;

  span {
    color: ${colors.projectColors.main};
  }

  .change-organization-button {
    padding: 0;

    background: transparent;
    border: none;
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: ${colors.projectColors.main};
    text-decoration: underline;
    cursor: pointer;
  }
`

export {
  BTPublishIcon,
  BTAssetsPublish,
  BTAssetsScrollableContainer,
  BTLoadingButtonArea,
  BTUploadButtonText,
  BTChangeOrganization,
}
