import { useEffect } from 'react'
import { Button, Typography } from '@mui/material'
import { Outlet, useNavigate } from 'react-router-dom'
import { ReactComponent as BrieftLogo } from 'assets/images/brieftLogo.svg'
import { client } from '../../../index'
import {
  BTInnerWrapper,
  BTIndexLayoutHeader,
  BTAuthComponentWrapper,
} from './bt-auth-wrapper.presets'

export const BTAuthWrapper = () => {
  const navigate = useNavigate()

  useEffect(() => {
    /** @desc cleaning notifications cache for other user */
    client.cache.evict({ fieldName: 'notifications' })
  }, [])

  return (
    <BTAuthComponentWrapper>
      <BTIndexLayoutHeader sx={{ boxShadow: 3, zIndex: 10 }}>
        <BrieftLogo onClick={() => navigate('/landing')} />
        <Button sx={{ boxShadow: 3 }} onClick={() => navigate('/login')}>
          <Typography variant="subtitle1">Log In</Typography>
        </Button>
      </BTIndexLayoutHeader>
      <BTInnerWrapper>
        <Outlet />
      </BTInnerWrapper>
    </BTAuthComponentWrapper>
  )
}
