import * as yup from 'yup'
import { ISendActivityMessage } from './types'

/* VISUAL */

export const SNACKBAR_TIMEOUT = 3000

export const POPOUT_DURATION = 1000

export const COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID!

export const COGNITO_USER_POOL_CLIENT_ID = process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID!

export const API_URL = process.env.REACT_APP_API_URL
export const SOCKET_URL = process.env.REACT_APP_SOCKET_URL || ''

export const ANONYMOUS_USER_NAME = 'Anonymous'

export const NEW_TASK_ROW_VERSION = 1

/* Form validation schemas */

const validEmail = yup
  .string()
  .email('Must be a valid email address')
  .required('Required')
  .max(100, '100 characters max')

const validPassword = yup
  .string()
  .required('Required')
  .min(8, 'Must be longer than 8 characters')
  .matches(/.*[0-9].*/, 'Must contain a number')
  .matches(/.*[A-Z].*/, 'Must contain a capital letter')
  .matches(/.*[a-z].*/, 'Must contain a lowercase letter')

function matchingPassword(passwordField: string) {
  return yup
    .string()
    .required('Required')
    .oneOf([yup.ref(passwordField), null], 'Passwords must match')
}

export const activityNameValidationSchema = yup.object({
  name: yup.string().required('Activity name is required'),
})

export const LOGIN_VALIDATION_SCHEMA = yup.object({
  email: validEmail,
  password: yup.string().required('Password required'),
})

export const INVITED_USER_LOGIN_SCHEMA = yup.object({
  fullName: yup.string().required('Required'),
  newPassword: validPassword,
  passwordConfirm: matchingPassword('newPassword'),
})

export const CHANGE_USER_DETAILS_SCHEMA = yup.object({
  name: yup.string().required('Required'),
  email: validEmail,
})

export const CHANGE_PASSWORD_SCHEMA = yup.object({
  currentPassword: yup.string().required('Required'),
  newPassword: validPassword,
  confirmNewPassword: matchingPassword('newPassword'),
})

export const ACCEPT_INVITATION_VALIDATION_SCHEMA = yup.object({
  newPassword: validPassword,
  newPasswordConfirm: matchingPassword('newPasword'),
})

export const FORGOT_PASSWORD_VALIDATION_SCHEMA = yup.object({
  email: validEmail,
})

export const REGISTER_SELF_VALIDATION_SCHEMA = yup.object({
  fullName: yup.string().required('Required'),
  email: validEmail,
  password: validPassword,
  passwordConfirm: matchingPassword('password'),
})

export const INVITE_MEMBER_VALIDATION_SCHEMA = yup.object({
  email: validEmail,
})

export const RESET_FORGOTTEN_PASSWORD_VALIDATION_SCHEMA = yup.object({
  newPassword: validPassword,
  newPasswordConfirm: matchingPassword('newPassword'),
})

export const CHANGE_PASSWORD_VALIDATION_SCHEMA = yup.object({
  oldPassword: validPassword,
  newPassword: validPassword,
  newPasswordConfirm: matchingPassword('newPassword'),
})

export const TIME_MATH_VALUES = {
  millisInSec: 1000,
  secInMin: 60,
  minInHour: 60,
  hoursInDay: 24,
}

export const SEND_ACTIVITY_MESSAGE_PURPOSE: ISendActivityMessage[] = [
  { label: 'Review and send back', value: 0 },
  { label: 'Fill in deliverable briefs and send back', value: 1 },
  { label: 'Check all comments', value: 2 },
  { label: 'Approve', value: 3 },
  { label: 'Complete assigned comments', value: 4 },
]

export const PAGINATION = {
  RENDER_CONDITION: 31,
  ASSETS_PER_PAGE: 30,
}

export const HIDE_MEMBERS_CONDITION = 5

export const GLOBAL_ASSETS_VALID_FILETYPES =
  '.doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .pdf, .psd, .ai, .jpg , .jpeg , .png, .gif, .svg, .img' /** @description .indd, - not working correctly */

export const EMPTY_ARRAY_LENGTH: number = 0

export const ERROR_PAGE_DEFAULT_DESCRIPTION = 'Error 404 Page Not Found'
export const ERROR_PAGE_DEFAULT_MESSAGE = 'Oops! Something Went Wrong'
export const ERROR_PAGE_DEFAULT_STATUS_CODE = 404

export const INITIAL_PAGE_NUMBER = 1

export const NOTIFICATIONS_PAGINATION_LIMIT = 30

export const USER_ROLES = {
  ADMIN: 'admin',
  MEMBER: 'member',
  EXTERNAL: 'external',
}
export const DOCUMENT_HEIGHT_SCALE = 35
export const DOCUMENT_WIDTH_SCALE = 20

export const DASHBOARD_SEARCH_OPTIONS = ['Activity', 'Project', 'Client']
