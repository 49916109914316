import { BTSearchIcon, BTSearchIconWrapper } from './bt-search.presets'
import { SearchPosition } from './bt-search.types'

export function getIcon(type?: SearchPosition) {
  const IconComponent = (
    <BTSearchIconWrapper position={type}>
      <BTSearchIcon />
    </BTSearchIconWrapper>
  )
  switch (type) {
    case 'start':
      return [IconComponent, null]
    case 'end':
      return [null, IconComponent]
    default:
      return [null, IconComponent]
  }
}
