import { colors } from 'theme/Colors'
import styled from 'styled-components'

export const BTSearchProjectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .search-input-wrapper {
    width: 90%;
    height: 18px;
  }

  .search-input {
    border: none;

    input {
      margin-left: 8px;
    }

    input::placeholder {
      ${({ theme }) => ({ ...theme.typography.regular })};
      color: ${colors.projectColors.iconDarkGrey};
    }
  }

  .options {
    display: flex;
    align-items: center;

    ${({ theme }) => ({ ...theme.typography.regular })};
    color: ${colors.projectColors.iconDarkGrey};

    .filter {
      margin-left: 10px;
      width: 100px;

      ${({ theme }) => ({ ...theme.typography.regular })};
      color: ${colors.projectColors.iconDarkGrey};
    }
  }
`
