import styled from 'styled-components'
import type { StyledProps } from './bt-append-button.props'
export const Wrapper = styled.div<StyledProps>`
  background: ${(props) => props.bgColor || '#e0e0e0'};
  color: ${(props) => props.titleColor || '#fff'};
  width: clamp(140px, 100%, 280px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'auto')};
`
export const AppendTitle = styled.div`
  ${({ theme }) => ({ ...theme.typography?.subtitle2 })};
`
