import React, { FC } from 'react'
// eslint-disable-next-line max-len
import { useArchiveMilestone } from '../../../../../hooks/activity/useArchiveMilestone'
import type { BTItemHeaderProps } from './bt-item-header.props'
import { Wrapper, Counter, Raw } from './bt-item-header.presets'
import { BTIconButtons } from '../../../../shared/bt-icon-buttons/index'
import { BTMoreButton } from '../../../../shared/bt-more-button'
import { BTEditableTitle } from './editable-title/bt-editable-title'

export const BTItemHeader: FC<BTItemHeaderProps> = ({ milestoneId, itemsCount, title }) => {
  const [archiveMilestone] = useArchiveMilestone()
  const archiveHandler = async () => {
    await archiveMilestone({
      variables: { id: milestoneId, archive: true },
    })
  }
  return (
    <Wrapper>
      <BTEditableTitle id={milestoneId}>{title}</BTEditableTitle>
      <Raw>
        <Counter>{itemsCount}</Counter>
        <BTIconButtons
          buttonsProps={[
            {
              iconComponent: (
                <BTMoreButton items={[{ line: 'Archive', handler: archiveHandler }]} isRounded />
              ),
            },
          ]}
        />
      </Raw>
    </Wrapper>
  )
}
