import styled from 'styled-components'

const BTSendStepperWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4vh;
`

export { BTSendStepperWrapper }
