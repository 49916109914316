/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { colors } from 'theme/Colors'
import { Button } from '@mui/material'
import { Document, Page } from 'react-pdf'
import { PuffLoader } from 'react-spinners'
import { useAppSelector } from 'redux/store'
import { BTPoint } from './components/bt-point'
import CreateIcon from '@mui/icons-material/Create'
import ScrollContainer from 'react-indiana-drag-scroll'
import { FC, useEffect, useRef, useState } from 'react'
import { PAGE_SCALE } from './bt-mark-up-layout.constants'
import { IPosition } from './components/bt-point/bt-point.props'
import { BTMarkUpLayoutWrapper } from './bt-mark-up-layout.presets'
import { LOADER_SIZE } from 'components/bt-artwork/bt-artwork.constants'
import { addPoint, isPdfFile, setRatio } from './bt-mark-up-layout.helper'
import { IBTMarkUpLayoutProps, IElementSize } from './bt-mark-up-layout.props'
import { DOCUMENT_HEIGHT_SCALE, DOCUMENT_WIDTH_SCALE } from 'helpers/constants'
import { FeedbackMessage, useGetArtworkFeedbackMessagesQuery } from 'generated/graphql'

export const BTMarkUpLayout: FC<IBTMarkUpLayoutProps> = ({ file, page, loading, feedbackId }) => {
  const markUpLayoutRef = useRef<HTMLDivElement>(null)
  const scrollRef = useRef<HTMLDivElement>(null)
  const [imageSize, setImageSize] = useState<IElementSize>({ height: 0, width: 0 })
  const [point, setPoint] = useState<IPosition | undefined>(undefined)
  const [isUsed, setIsUsed] = useState(false)
  const [canEdit, setCanEdit] = useState(false)

  const { assetId } = useAppSelector((state) => state.sidebarApps)

  const { data: points, refetch: feedbackRefetch } = useGetArtworkFeedbackMessagesQuery({
    variables: { input: { assetId } },
  })

  useEffect(() => {
    if (!isPdfFile(file?.type)) {
      const image = new Image()

      image.src = file?.data?.toString() ?? ''
      image.onload = () => {
        const documentMaxHeight = setRatio(window.innerHeight, DOCUMENT_HEIGHT_SCALE)
        const documentMaxWidth = setRatio(window.innerWidth, DOCUMENT_WIDTH_SCALE)

        if (image.width > documentMaxWidth || image.height > documentMaxHeight) {
          const widthRatio = documentMaxWidth / image.width
          const heightRatio = documentMaxHeight / image.height

          const lowestRatio = Math.min(widthRatio, heightRatio)

          setImageSize({
            width: image.width * lowestRatio,
            height: imageSize.height,
          })
        } else {
          setImageSize({
            width: image.width,
            height: imageSize.height,
          })
        }
      }
    }
  }, [file])

  useEffect(() => {
    feedbackRefetch({ input: { assetId } })
    setPoint(undefined)
    setCanEdit(false)
  }, [assetId])

  const renderMainBlock = () => {
    return isPdfFile(file?.type) ? (
      <Document file={file?.data}>
        <Page pageNumber={page} scale={PAGE_SCALE} />
      </Document>
    ) : (
      <img src={typeof file?.data === 'string' ? file?.data : ''} alt="" />
    )
  }

  const addPossibilityToAdd = () => {
    setCanEdit((prevState) => !prevState)
    setIsUsed(false)

    if (point) {
      setPoint(undefined)
    }
  }

  return (
    <BTMarkUpLayoutWrapper ref={markUpLayoutRef} width={imageSize.width}>
      {loading ? (
        <PuffLoader size={LOADER_SIZE} color={colors.projectColors.main} />
      ) : (
        <ScrollContainer
          className="scroll-container"
          hideScrollbars={false}
          vertical
          horizontal={false}
        >
          <div
            className="main-layout"
            ref={scrollRef}
            onClick={(event) =>
              !isUsed &&
              !point &&
              canEdit &&
              addPoint(
                scrollRef,
                event,
                setPoint,
                page,
                (points?.getArtworkFeedback as FeedbackMessage[]) ?? []
              )
            }
          >
            {renderMainBlock()}
            {points?.getArtworkFeedback
              .filter(({ position }) => position !== null)
              .sort((first, second) => first.createdAt - second.createdAt)
              .map(
                ({ position, content, messageId }, index) =>
                  position?.page === page && (
                    <BTPoint
                      position={{ page: position?.page!, x: position?.x!, y: position?.y! }}
                      number={index + 1}
                      setIsUsed={setIsUsed}
                      message={content ?? ''}
                      key={content}
                      messageId={messageId ?? ''}
                      feedbackId={feedbackId}
                      scrollBlockRef={markUpLayoutRef}
                      canEdit={canEdit}
                    />
                  )
              )}
            {point && (
              <BTPoint
                position={{ page: point.page, x: point.x, y: point.y }}
                number={
                  points?.getArtworkFeedback.length
                    ? points.getArtworkFeedback.filter(({ position }) => position !== null).length +
                      1
                    : 1
                }
                setIsUsed={setIsUsed}
                setPointData={setPoint}
                feedbackId={feedbackId}
                scrollBlockRef={markUpLayoutRef}
                canEdit={canEdit}
              />
            )}
          </div>
        </ScrollContainer>
      )}
      {!loading && (
        <Button
          className="markup-button"
          variant="contained"
          endIcon={<CreateIcon />}
          onClick={addPossibilityToAdd}
        >
          {canEdit ? 'Save' : 'Mark Up'}
        </Button>
      )}
    </BTMarkUpLayoutWrapper>
  )
}
