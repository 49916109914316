import styled from 'styled-components'

const BTQuestionsPadsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: #f2f2f2;
  border-radius: 5px;
  padding: 12px;
  gap: 7px;
`

export { BTQuestionsPadsWrapper }
