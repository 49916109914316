import { ProjectMemberFragment } from 'generated/graphql'

export const getUsersIds = (users: ProjectMemberFragment[]) => {
  const ids = users.map((userItem) => {
    const { user } = userItem
    return user.id
  })

  return ids
}
