import { Slider } from '@mui/material'
import { FC, useRef, useState } from 'react'
import { setImage } from 'redux/PostSignupFormSlice'
import { useDropzone } from 'react-dropzone'
import { useWizard } from 'react-use-wizard'
import AvatarEditor from 'react-avatar-editor'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { BTPostSignupFormContainer } from './bt-post-signup-form-wrapper'
import { BTAvatarUploadWrapper, BTAvatarDescription } from '../bt-post-signup-form.presets'

export const BTPostSignupFormEditAvatar: FC = () => {
  const { nextStep, activeStep, stepCount } = useWizard()
  const { image } = useAppSelector((state) => state.postSignupForm)
  const editorRef = useRef<AvatarEditor>(null)
  const [avatarScale, setAvatarScale] = useState(1)
  const dispatch = useAppDispatch()

  const changeAvatarScale = (event: Event, value: number | number[]) => {
    if (typeof value === 'number') {
      setAvatarScale(value)
    }
  }

  const saveImage = () => {
    if (editorRef.current && image) {
      const canvas: AvatarEditor = editorRef.current
      const resultImage = canvas?.getImage().toDataURL('image/png')
      dispatch(setImage(resultImage))
    }
  }

  const onDrop = (files: File[]) => {
    files.forEach((e) => {
      const reader = new FileReader()
      reader.onload = () => {
        dispatch(setImage(reader.result as string))
      }
      reader.readAsDataURL(e)
    })
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/jpeg', 'image/png'],
    onDrop,
    noClick: true,
  })

  const renderAvatarEdit = () => {
    return (
      <BTAvatarUploadWrapper>
        <AvatarEditor
          style={{ marginTop: '30px', marginBottom: '10px' }}
          image={image!}
          width={110}
          height={110}
          borderRadius={250}
          scale={avatarScale}
          ref={editorRef}
        />
        <div {...getRootProps({ className: 'avatar' })}>
          <label htmlFor="file">Change to another image</label>
          <input id="file" {...getInputProps()} />
        </div>
        <Slider
          style={{ marginTop: '20px' }}
          step={0.1}
          onChange={changeAvatarScale}
          min={1}
          max={10}
        />
      </BTAvatarUploadWrapper>
    )
  }
  return (
    <BTPostSignupFormContainer
      title="Edit Avatar"
      btnTitle="Next"
      goNext={() => {
        saveImage()
        nextStep()
      }}
      content={
        <div>
          <BTAvatarDescription>Centre and crop your avatar.</BTAvatarDescription>
          {renderAvatarEdit()}
        </div>
      }
      activeStep={activeStep}
      stepCount={stepCount}
    />
  )
}
