import styled from '@emotion/styled'
import { colors } from '../../../../../theme/Colors'

interface IBTChooseMessageWrapperProps {
  selected: boolean
}

// eslint-disable-next-line max-len
export const BTChooseMessageAssigneeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 30px 40px;

  .save-button {
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    width: 120px;
  }

  .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
  }

  .search {
    input {
      padding: 5px 10px;
      font-size: 10px;
      font-weight: 300;
      line-height: 17px;
      width: 240px;
    }

    svg {
      font-size: 14px;
      color: #dadada;
    }

    .clear-icon {
      color: ${colors.navyGrey[400]};
      cursor: pointer;
    }
  }

  .user-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: auto;
    max-height: 145px;
    height: 145px;
    width: 100%;

    .not-found {
      width: 100%;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }

    &::-webkit-scrollbar {
      background-color: transparent;
      height: 10px;
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${colors.navyGrey[200]};
      border-radius: 10px;
    }
  }
`

export const BTUserItem = styled.button<IBTChooseMessageWrapperProps>`
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  background-color: ${colors.navyGrey[300]};
  transition: all 0.2s ease-in-out;
  border: none;
  width: 100%;

  .MuiAvatar-root {
    border: 1px solid white;
  }

  &:hover:not([disabled]) {
    background-color: ${({ selected }) =>
      selected && selected ? colors.blue[400] : colors.navyGrey[200]};
  }
  span {
    margin-left: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  ${({ selected }) =>
    selected && selected ? `background-color: ${colors.projectColors.main}; color: white;` : ''}
`
