import { List, ListItemButton, ListItemIcon } from '@mui/material'
import { useEffect, useState } from 'react'
import { resetSidebarColors } from 'redux/SidebarAppsSlice'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { colors } from 'theme/Colors'
import { v4 } from 'uuid'
import { SidebarApplications, SidebarItemsType } from '../../bt-actions-sidebar.types'
import type { BTSidebarListProps } from './bt-sidebar-list.props'

// TODO: React.memo()
export const BTSidebarList: BTSidebarListProps = ({ Logos, externalHandler }) => {
  const dispatch = useAppDispatch()
  const [currentLogo, setCurrentLogo] = useState<number | null>(null)
  const { approval, resetColor, isOpened } = useAppSelector((state) => state.sidebarApps)
  const innerHandler = (apps: SidebarApplications, index: number) => () => {
    externalHandler(apps)
    /**
     * @description dispatch async
     * @todo find normal solution
     */
    setTimeout(() => {
      setCurrentLogo(index)
    }, 0)
  }
  const getCorrectColor = (index: number, name?: SidebarItemsType) => {
    if (name === 'approves' && approval.isApproved) {
      return approval.color
    }
    const correctColor =
      index === currentLogo && isOpened ? colors.projectColors.iconGrey : colors.projectColors.main

    return correctColor
  }
  useEffect(() => {
    if (resetColor) {
      setCurrentLogo(null)
      dispatch(resetSidebarColors(false))
    }
  }, [resetColor])
  return (
    <List sx={{ display: 'flex', flexDirection: 'column', gap: '18px' }}>
      {Array.isArray(Logos) ? (
        Logos.map(({ Logo, name, ...applications }, index) => (
          <ListItemButton
            key={v4()}
            sx={{
              minHeight: 48,
              px: 2.5,
              alignSelf: 'center',
            }}
            onClick={innerHandler(applications, index)}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                justifyContent: 'center',
              }}
            >
              <Logo sx={{ color: getCorrectColor(index, name) }} />
            </ListItemIcon>
          </ListItemButton>
        ))
      ) : (
        <Logos />
      )}
    </List>
  )
}
BTSidebarList.Top = BTSidebarList
BTSidebarList.Bottom = BTSidebarList
