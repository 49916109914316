import { useEffect } from 'react'
import { setHeaderTitle } from '../../../redux/HeaderSlice'
import { useSetupHeaderData } from '../../../hooks/header/useSetupHeaderData'
import { useAppDispatch } from '../../../redux/store'
import { SoonDescription, SoonHeading, SoonWrapper } from './bt-project-coming-soon.presets'

export const BTProjectComingSoon = ({ title }: { title?: string }) => {
  const dispatch = useAppDispatch()

  useSetupHeaderData()

  useEffect(() => {
    if (title) {
      dispatch(setHeaderTitle(title))
    }
  }, [title])

  return (
    <SoonWrapper>
      <SoonHeading>Coming Soon...</SoonHeading>
      <SoonDescription>
        Our developers are currently working on this feature for you.
      </SoonDescription>
    </SoonWrapper>
  )
}
