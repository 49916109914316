import { FC } from 'react'
import { BTNoAssetWrapper } from './bt-no-asset.preset'
import { ReactComponent as CurveArrow } from '../../../../assets/images/curveArrow.svg'

export const BTNoAsset: FC = () => {
  return (
    <BTNoAssetWrapper>
      <span>You don’t have any file uploaded.</span>
      <span>Please add file for review.</span>
      <CurveArrow className="arrow" />
    </BTNoAssetWrapper>
  )
}
