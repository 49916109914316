import styled from 'styled-components'
import { colors } from 'theme/Colors'

const BTFeedbackShareInput = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid ${colors.navyGrey[300]};

  input {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    padding: 5px 10px;
  }
`

export { BTFeedbackShareInput }
