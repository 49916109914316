import { client } from 'index'
import { useAppDispatch } from 'redux/store'
import { useNavigate } from 'react-router-dom'
import { userLoggedOut } from 'redux/LoginSlice'
import { cognitoUserPool } from 'helpers/cognito'
import { useMeWithOrganizationQuery, AllTasksDocument } from 'generated/graphql'

export function useLogout(): () => Promise<void> {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const meDetails = useMeWithOrganizationQuery()

  return async () => {
    const user = cognitoUserPool.getCurrentUser()

    if (user) {
      user.signOut()
      await meDetails.refetch()
    }

    // clean redux storage
    dispatch(userLoggedOut())

    // clean tasks
    client.writeQuery({
      query: AllTasksDocument,
      data: { tasks: [] },
    })

    navigate('/landing')
  }
}
