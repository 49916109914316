import { FC } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { BTMessage } from '../bt-last-step/bt-last-step.presets'
import { BTLabel } from '../bt-confirm-step/bt-confirm-step.presets'
import { BTPersonalMessageProps } from './bt-personal-message.props'

export const BTPersonalMessage: FC<BTPersonalMessageProps> = ({ message }) => {
  return (
    <>
      <BTLabel>Personalised message:</BTLabel>
      <BTMessage>
        <ScrollContainer className="message" hideScrollbars={false} horizontal={false} vertical>
          {message}
        </ScrollContainer>
      </BTMessage>
    </>
  )
}
