import { useFormik } from 'formik'
import { LoadingButton } from '@mui/lab'
import { useAppSelector } from 'redux/store'
import EditIcon from '@mui/icons-material/Edit'
import { useEffect, useState, ChangeEvent, FC } from 'react'
import { activityNameValidationSchema } from 'helpers/constants'
import { BTRenamableActivityTitleProps } from './bt-renamable-activity-title.props'
import { useShouldBeDisabledForRoles, useIsAuthor } from 'helpers/CheckUserRoleAccess'
import { BTRenamableActivityTitleWrapper } from './bt-renamable-activity-title.presets'
import { Button, ClickAwayListener, IconButton, TextField, Typography } from '@mui/material'
import { useGetCurrentActivityByIdQuery, useUpdateActivityMutation } from 'generated/graphql'

export const BTRenamableActivityTitle: FC<BTRenamableActivityTitleProps> = ({ activityId }) => {
  const [updateActivty] = useUpdateActivityMutation()
  const [activityNameEdit, setActivityNameEdit] = useState(false)
  const userId = useAppSelector((state) => state.login?.whoami?.id)

  const { data } = useGetCurrentActivityByIdQuery({
    variables: { id: activityId! },
  })

  const activityAuthor = data?.activities[0].author
  const isAuthor = useIsAuthor(activityId)

  const shouldBeDisabledFor = useShouldBeDisabledForRoles()
  const shouldBeHided = shouldBeDisabledFor(['external'], isAuthor)

  const handleActivityNameEditOpen = () => setActivityNameEdit(true)
  const handleActivityNameEditClose = () => setActivityNameEdit(false)

  const onSubmit = async (values: { name: string }) => {
    const { name } = values

    if (name && activityId) {
      await updateActivty({
        variables: {
          input: {
            id: activityId,
            name,
          },
        },
      })
      handleActivityNameEditClose()
    }
  }

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: activityNameValidationSchema,
    onSubmit,
  })

  useEffect(() => {
    if (data?.activities) {
      formik.setFieldValue('name', data.activities[0]?.name ?? '')
      formik.validateField('name')
    }
  }, [data])

  return (
    <ClickAwayListener onClickAway={handleActivityNameEditClose}>
      <BTRenamableActivityTitleWrapper className="rename-title-block">
        {!activityNameEdit ? (
          <div className="title-block">
            <Typography
              variant="h5"
              className="title"
              onDoubleClick={shouldBeHided ? undefined : handleActivityNameEditOpen}
            >
              {(data?.activities.length && data?.activities[0]?.name) || ''}
            </Typography>

            {!shouldBeHided && (
              <IconButton size="small" onClick={handleActivityNameEditOpen}>
                <EditIcon fontSize="small" />
              </IconButton>
            )}
          </div>
        ) : (
          <form onSubmit={formik.handleSubmit} className="change-name-block">
            <TextField
              value={formik.values.name}
              error={!!formik.errors.name}
              helperText={formik.errors.name}
              onChange={({ target }: ChangeEvent<HTMLInputElement>) => {
                const { value } = target

                formik.setFieldValue('name', value)
                formik.validateField('name')
              }}
            />
            <Button variant="outlined" onClick={handleActivityNameEditClose}>
              Cancel
            </Button>
            <LoadingButton
              loading={formik.isSubmitting}
              variant="contained"
              disabled={!!formik.errors.name}
              type="submit"
            >
              Save
            </LoadingButton>
          </form>
        )}
      </BTRenamableActivityTitleWrapper>
    </ClickAwayListener>
  )
}
