import styled from 'styled-components'
import { colors } from '../../../../../../theme/Colors'
const Title = styled.div`
  ${({ theme }) => ({ ...theme.typography?.h6 })}
  color: ${colors.navyGrey[800]};
  outline-offset: 2px;
  outline-color: #dadada;
  outline-width: 1px;
  text-overflow: ellipsis;
`
export { Title }
