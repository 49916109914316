/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable import/no-unresolved */
/* eslint-disable array-callback-return */
import Avatar from '@mui/material/Avatar'
import CloseIcon from '@mui/icons-material/Close'
import CancelIcon from '@mui/icons-material/Cancel'
import { BTSearch } from 'components/shared/bt-search'
import { useEffect, useState, MouseEvent } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { Badge, IconButton, Button } from '@mui/material'
import { BTModalWindow } from 'components/shared/bt-modal-window'
import { setCurrentOrganizationId } from 'redux/LoginSlice'
import { BTSendNextButton } from '../bt-change-step'
import { adjustUsers } from '../../../../../redux/SidebarAppsSlice'
import { BTSelectedMembersModal } from '../bt-selected-members-modal'
import { HIDE_MEMBERS_CONDITION } from '../../../../../helpers/constants'
import { useAppSelector, useAppDispatch } from '../../../../../redux/store'
import { useCurrentProject } from '../../../../../hooks/project/useCurrentProject'
import { BTMemberListItem } from '../../../../shared/bt-project-add-members-step/components/bt-member-list-item'
import {
  BTMembersList,
  BTNoProjectMembers,
  BTSeachInputWrapper,
  BTSelectedMembersList,
  BTSelectedMembersItem,
} from './bt-entry-step.presets'
import { BTAppsLabel, BTAppsWrapper, BTAppsDivider, BTAppsSubLabel } from '../../../shared'
import { IMember, setSelectedIcon, setMembersWithoutMe } from './bt-entry-step.helpers'

import { BTAddMembers } from './components/bt-add-members'

export const BTSendActivityEntryStep = () => {
  const [members, setMembers] = useState<IMember[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [searchedMembers, setSearchedMembers] = useState<IMember[]>([])
  const [selectedMembers, setSelectedMembers] = useState<IMember[]>([])
  const [isAddMembersModalOpen, setIsAddMembersModalOpen] = useState(false)
  const [selectedMembersModal, setSelectedMembersModal] = useState(false)

  const dispatch = useAppDispatch()
  const { data } = useCurrentProject()
  const { whoami } = useAppSelector((state) => state.login)
  const { users: adjustedUsers } = useAppSelector((state) => state.sidebarApps.sendTab)

  const allMembers = data?.projects[0].members
  const membersWithoutMe = setMembersWithoutMe(allMembers, whoami)

  const selectedMembersModalOpen = () => {
    setSelectedMembersModal(true)
  }

  const selectedMembersModalClose = () => {
    setSelectedMembersModal(false)
  }

  const openAddMembersModalHandler = () => {
    setIsAddMembersModalOpen(true)
  }
  const closeAddMembersModalHandler = () => {
    setIsAddMembersModalOpen(false)
  }

  const searchHandler = (value: string) => {
    setSearchedMembers([])

    members.forEach((member: IMember) => {
      const loverCasedValue = value.toLowerCase()
      const loverCassedName = member.user.name?.toLowerCase()

      if (loverCasedValue && loverCassedName && loverCassedName.includes(loverCasedValue)) {
        setSearchedMembers((state) => {
          const newState = [...state]
          newState.push(member)
          return newState
        })
      }
    })
  }

  const selectMembersHandler = (event: MouseEvent<HTMLButtonElement>) => {
    const neededMember = members.find((member) => member.user.id === event.currentTarget.id)

    if (neededMember) {
      if (!selectedMembers.includes(neededMember)) {
        setSelectedMembers((state) => [...state, neededMember])
        dispatch(adjustUsers([...selectedMembers, neededMember]))
      } else {
        const index = selectedMembers.indexOf(neededMember)
        const unselectedArray = [...selectedMembers]
        unselectedArray.splice(index, 1)

        setSelectedMembers(unselectedArray)
      }
    }
  }

  const unselectMembersHandler = (event: MouseEvent<HTMLButtonElement>) => {
    const unselectedMember = selectedMembers.find(
      (member) => member.user.id === event.currentTarget.id
    )

    if (unselectedMember) {
      const indexOfUnselectedMember = selectedMembers.indexOf(unselectedMember)

      const result = [...selectedMembers]
      result.splice(indexOfUnselectedMember, 1)
      setSelectedMembers(result)
      dispatch(adjustUsers(result))
    }
  }

  const searchResultsRendering = () => {
    if (searchValue && !searchedMembers.length) {
      return <BTAppsSubLabel>There is no members with such name</BTAppsSubLabel>
    }

    if (searchValue && searchedMembers.length) {
      return searchedMembers.map((member) => {
        const { user } = member
        return (
          <BTMemberListItem
            key={member.id}
            id={user.id}
            avatarUrl={user.avatarUrl}
            name={user.name!}
            onClick={selectMembersHandler}
            isSelected={setSelectedIcon(selectedMembers, member)}
          />
        )
      })
    }

    return <div />
  }

  const membersRenderWithModal = () => {
    return (
      <BTSelectedMembersList>
        {selectedMembers.map((member, index) => {
          if (index < HIDE_MEMBERS_CONDITION) {
            return (
              <BTSelectedMembersItem>
                <Badge
                  key={member.id}
                  overlap="circular"
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  badgeContent={
                    <IconButton
                      id={member.user.id}
                      className="remove-button"
                      onClick={unselectMembersHandler}
                    >
                      <CancelIcon fontSize="small" sx={{ color: '#DADADA' }} />
                    </IconButton>
                  }
                >
                  <Avatar src={member.user.avatarUrl} sx={{ height: 35, width: 35 }} />
                </Badge>
              </BTSelectedMembersItem>
            )
          }
        })}
        {selectedMembers.length > HIDE_MEMBERS_CONDITION && (
          <Button className="rest-members-amount" onClick={selectedMembersModalOpen}>
            +{selectedMembers.length - HIDE_MEMBERS_CONDITION}
          </Button>
        )}
      </BTSelectedMembersList>
    )
  }

  const noMembersRender = () => {
    return (
      <BTNoProjectMembers>
        <div className="text">You have no team mates!</div>
        <div className="text">
          To send this to someone, <br /> add them to the project
        </div>
        <button className="invite-button" type="button" onClick={openAddMembersModalHandler}>
          <div className="invite-button-icon">
            <CloseIcon />
          </div>
        </button>
        <BTModalWindow isOpened={isAddMembersModalOpen} handleClose={closeAddMembersModalHandler}>
          <BTAddMembers />
        </BTModalWindow>
      </BTNoProjectMembers>
    )
  }

  useEffect(() => {
    if (membersWithoutMe) {
      setMembers(membersWithoutMe)
    }

    if (data && data.projects.length > 0) {
      const organizationId = data.projects[0].organization.id

      dispatch(setCurrentOrganizationId(organizationId))
    }
  }, [data])

  useEffect(() => {
    searchHandler(searchValue)
  }, [searchValue])

  useEffect(() => {
    if (adjustedUsers.length) {
      setSelectedMembers(adjustedUsers)
    }
  }, [adjustedUsers])

  return (
    <BTAppsWrapper>
      <BTAppsWrapper>
        <BTAppsLabel>Send</BTAppsLabel>
        {!members.length ? (
          noMembersRender()
        ) : (
          <BTAppsWrapper gap={16}>
            <BTAppsSubLabel>Who do you want to send this to?</BTAppsSubLabel>
            {membersRenderWithModal()}

            <BTSeachInputWrapper>
              <BTSearch placeholder="Search" changeEvent={setSearchValue} debounceDelay={800} />
            </BTSeachInputWrapper>

            <BTMembersList>
              <ScrollContainer
                className="members-list"
                hideScrollbars={false}
                horizontal={false}
                vertical
              >
                {searchResultsRendering()}
              </ScrollContainer>
            </BTMembersList>
          </BTAppsWrapper>
        )}
      </BTAppsWrapper>
      <BTAppsDivider isBottom />
      <BTSendNextButton disabled={!selectedMembers.length || !members.length}>
        Next
      </BTSendNextButton>

      <BTSelectedMembersModal
        selectedMembers={selectedMembers}
        isOpen={selectedMembersModal}
        unselectHandler={unselectMembersHandler}
        handleCloseModal={selectedMembersModalClose}
      />
    </BTAppsWrapper>
  )
}
