export const TutorialArrow = () => {
  return (
    <svg
      width="257"
      height="311"
      viewBox="0 0 257 311"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_8091_48122)">
        <path
          d="M3.50035 306.5C2.67192 306.5 2.00034 305.829 2.00034 305C2.00034 304.172 2.67191 303.5 3.50033 303.5L3.50035 306.5ZM244.083 0.813003C244.739 0.306539 245.681 0.427403 246.187 1.08296L254.441 11.7661C254.947 12.4217 254.826 13.3637 254.171 13.8702C253.515 14.3766 252.573 14.2558 252.067 13.6002L244.73 4.10409L235.234 11.4406C234.578 11.947 233.636 11.8262 233.13 11.1706C232.623 10.515 232.744 9.57302 233.4 9.06653L244.083 0.813003ZM3.50033 303.5L8.46241 303.5L8.46243 306.5L3.50035 306.5L3.50033 303.5ZM98.0848 223.851L98.7757 218.008L101.755 218.36L101.064 224.203L98.0848 223.851ZM234.272 73.8322L243.512 1.80913L246.488 2.19092L237.247 74.214L234.272 73.8322ZM167.788 144.217C202.873 137.666 229.729 109.234 234.272 73.8322L237.247 74.214C232.539 110.907 204.704 140.376 168.338 147.166L167.788 144.217ZM98.7757 218.008C103.156 180.96 131.116 151.065 167.788 144.217L168.338 147.166C132.957 153.773 105.981 182.616 101.755 218.36L98.7757 218.008ZM8.46241 303.5C54.2051 303.5 92.7135 269.277 98.0848 223.851L101.064 224.203C95.5142 271.139 55.7257 306.5 8.46243 306.5L8.46241 303.5Z"
          fill="#0066FF"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_8091_48122"
          x="0.000488281"
          y="0.5"
          width="256.753"
          height="310"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8091_48122" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8091_48122"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
