import styled from 'styled-components'
import { typography } from 'theme/Typography'

const BTMainPageWrapper = styled.div`
  display: flex;
`

const BTBriefDocumentWrapper = styled.div`
  padding-bottom: 15px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  position: relative;

  .activity-title {
    margin-top: 20px;

    .default {
      margin-bottom: 20px;
      ${() => ({ ...typography.h5 })};
    }
  }

  .text-editor div {
    border: none;
  }
  .ql-toolbar {
    margin-bottom: 20px;

    height: 56px;
    width: 738px;

    display: flex;
    align-items: center;
    border-radius: 5px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    background: #ffffff;
  }

  .ql-container {
    width: 738px;
    height: 460px;

    border-radius: 5px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    background: #ffffff;
  }
`

const BTButtonsWrapper = styled.div`
  margin-left: 70px;
  margin-top: 80px;

  div {
    margin-bottom: 10px;
  }
`

export { BTBriefDocumentWrapper, BTButtonsWrapper, BTMainPageWrapper }
