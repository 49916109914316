import { forwardRef } from 'react'
import type { BTNavigationLinkProps } from './bt-nav-link.props'
import { Link, useLocation } from 'react-router-dom'

export const BTNavigationLink = forwardRef<HTMLAnchorElement, BTNavigationLinkProps>(
  (props, ref) => {
    const { className, to, children } = props
    const location = useLocation()
    const isMatch = location.pathname.includes(to)
    const extendedClassName = `${className} ${isMatch && 'active'}`

    return (
      <>
        <Link {...props} ref={ref} className={extendedClassName}>
          {children}
        </Link>
      </>
    )
  }
)
