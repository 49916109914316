import styled from '@emotion/styled'
import { SxProps } from '@mui/material'
import { colors } from '../../../theme/Colors'
import AddIcon from '@mui/icons-material/Add'
interface IAssigneeWrapper {
  isStacked: boolean | undefined
  sx: SxProps | undefined
}

const AssigneeWrapper = styled.div<IAssigneeWrapper>`
  display: flex;
  ${({ isStacked }: IAssigneeWrapper) =>
    !isStacked &&
    `.MuiAvatarGroup-root {
    gap: 0 5px;

    .MuiAvatar-root {
      margin: 0;
      font-size: 1rem;
    }
  }`}

  .MuiAvatarGroup-avatar {
    ${({ sx }: any) => sx && `height: ${sx?.height}px; width: ${sx?.width}px;`}
  }

  .MuiAvatarGroup-root {
    button {
      margin-left: -8px;
      background-color: ${colors.navyGrey[300]};
      width: 30px;
      height: 30px;
    }

    .MuiAvatar-root {
      box-sizing: border-box;
    }

    .owner {
      border-color: ${colors.projectColors.main};
    }
  }
`

const AddAssignee = styled(AddIcon)`
  cursor: pointer;
`

export { AssigneeWrapper, AddAssignee }
