import { FC, useState } from 'react'
import { Button } from '@mui/material'
import { formatBytes } from 'helpers/file'
import { useParams } from 'react-router-dom'
import { useAppSelector } from 'redux/store'
import { USER_ROLES } from 'helpers/constants'
import { fileLoad } from 'helpers/file/fileLoad'
import PublishIcon from '@mui/icons-material/Publish'
import { useRemoveAsset } from 'hooks/file/useRemoveAsset'
import { BTChooseFileModal } from '../bt-choose-file-modal'
import { fileDownloadHelper } from 'helpers/file/fileDownload'
import { BTModalWindow } from 'components/shared/bt-modal-window'
import { getFilesDataToReorder } from './bt-artwork-header.helper'
import { BTArtworkHeaderWrapper } from './bt-artwork-header.presets'
import type { IBTArtworkHeaderProps } from './bt-artwork-header.props'
import { useReorderAssetsVersion } from 'hooks/file/useReorderAssetsVerison'
import { CheckUserRoleAccess, useIsAuthor } from 'helpers/CheckUserRoleAccess'
import { BTConfirmationModalWindow } from '../../../shared/bt-confirmation-modal'
import { BTFileItemInformation } from 'components/shared/bt-file-item-information'
import { BTRenamableActivityTitle } from 'components/shared/bt-renamed-activity-title'
import { useCreateModalWindowHandlers } from 'hooks/shared/useCreateModalWindowHandlers'
import {
  FILE_ITEM_WIDTH,
  DELETE_MODAL_WINDOW_TEXT,
  REPLACE_MODAL_WINDOW_TEXT,
} from './bt-artwork-header.constants'

export const BTArtworkHeader: FC<IBTArtworkHeaderProps> = ({
  fileData,
  fileName,
  className,
  assetsNumber,
  setVersion,
  getCurrentAsset,
  apolloClient,
  version,
  loading,
}) => {
  const { artworkId } = useParams()
  const [isFileModalWindowOpen, openFileModalWindow, closeFileModalWindow] =
    useCreateModalWindowHandlers()
  const [isDeleteModalWindowOpen, openDeleteModalWindow, closeDeleteModalWindow] =
    useCreateModalWindowHandlers()
  const [isReplaceModalWindowOpen, openReplaceModalWindow, closeReplaceModalWindow] =
    useCreateModalWindowHandlers()

  const { assetId } = useAppSelector((state) => state.sidebarApps)

  const [isLoading, setIsLoading] = useState(false)
  const [oldVersion, setOldVersion] = useState<number | null>(null)

  const removeArtworkAsset = useRemoveAsset()
  const reorderAssetsVersion = useReorderAssetsVersion()

  const { whoami } = useAppSelector((state) => state.login)
  const userRole = whoami?.organizationMember?.role

  const isAuthor = useIsAuthor(artworkId)

  const moveToPrevVersion = () => {
    setVersion((prevState) => {
      if (prevState === 1) {
        return 1
      }
      return prevState - 1
    })
  }

  const removeAsset = async () => {
    if (artworkId && assetId) {
      setIsLoading(true)

      const reorderingList = getFilesDataToReorder(assetId, artworkId, apolloClient, false)

      await removeArtworkAsset(assetId, artworkId)

      if (reorderingList.length > 0) {
        await reorderAssetsVersion(reorderingList, artworkId)
      }

      moveToPrevVersion()
      setIsLoading(false)
    }
    closeDeleteModalWindow()
  }

  const replaceAction = async () => {
    if (assetId && artworkId) {
      const reorderingList = getFilesDataToReorder(assetId, artworkId, apolloClient, false)
      await removeArtworkAsset(assetId, artworkId)

      if (reorderingList.length > 0) {
        await reorderAssetsVersion(reorderingList, artworkId)
      }
    }
  }

  const confirmReplaceAction = () => {
    setOldVersion(version)
    openFileModalWindow()
    closeReplaceModalWindow()
  }

  const downloadFile = async () => {
    if (fileData && typeof fileData.data === 'string') {
      const file = await fileLoad(fileData.data)
      fileDownloadHelper(file, fileData.name)
    }
  }

  const checkActionsForUserRole = () => {
    const externalUserActions = [{ title: 'Download', action: downloadFile }]

    if (userRole === USER_ROLES.EXTERNAL && !isAuthor) {
      return externalUserActions
    }

    const adminUsersActions = [
      ...externalUserActions,
      { title: 'Replace', action: openReplaceModalWindow },
      { title: 'Remove', action: openDeleteModalWindow },
    ]

    return adminUsersActions
  }

  const actions = checkActionsForUserRole()

  return (
    <BTArtworkHeaderWrapper className={className}>
      <div className="row">
        {assetsNumber !== 0 ? (
          <BTRenamableActivityTitle activityId={artworkId} />
        ) : (
          <span className="no-artwork-title">Upload</span>
        )}
        {!loading && (
          <div className="file-block">
            {assetsNumber !== 0 && fileData ? (
              <>
                <BTFileItemInformation
                  buttonItems={actions}
                  fileName={fileName}
                  fileSize={formatBytes(fileData?.size ?? 0)}
                  fileType={fileData?.type?.split('/')[1] ?? ''}
                  itemWidth={FILE_ITEM_WIDTH}
                />
                <CheckUserRoleAccess>
                  <PublishIcon className="upload-icon" onClick={openFileModalWindow} />
                </CheckUserRoleAccess>
              </>
            ) : (
              <Button variant="contained" className="upload-button" onClick={openFileModalWindow}>
                Upload File
              </Button>
            )}
          </div>
        )}
      </div>
      <BTChooseFileModal
        isOpened={isFileModalWindowOpen}
        closeModal={closeFileModalWindow}
        assetsNumber={assetsNumber}
        setVersion={setVersion}
        oldVersion={oldVersion}
        setOldVersion={setOldVersion}
        getCurrentAsset={getCurrentAsset}
        apolloClient={apolloClient}
        replaceAction={oldVersion !== null ? replaceAction : undefined}
      />
      <BTModalWindow isOpened={isDeleteModalWindowOpen} handleClose={() => {}}>
        <BTConfirmationModalWindow
          description={DELETE_MODAL_WINDOW_TEXT}
          acceptButtonText="Delete"
          acceptAction={removeAsset}
          declineAction={closeDeleteModalWindow}
          isLoading={isLoading}
        />
      </BTModalWindow>
      <BTModalWindow isOpened={isReplaceModalWindowOpen} handleClose={() => {}}>
        <BTConfirmationModalWindow
          description={REPLACE_MODAL_WINDOW_TEXT}
          acceptButtonText="Replace"
          acceptAction={confirmReplaceAction}
          declineAction={closeReplaceModalWindow}
          isLoading={isLoading}
        />
      </BTModalWindow>
    </BTArtworkHeaderWrapper>
  )
}
