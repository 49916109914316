/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Button } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { Document, Page } from 'react-pdf'
import { PuffLoader } from 'react-spinners'
import { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { fileDownloadHelper } from 'helpers/file/fileDownload'
import { colors } from '../../../theme/Colors'
import { BTMoreButton } from '../bt-more-button'
import { BTModalWindow } from '../bt-modal-window'
import { formatBytes } from '../../../helpers/file'
import { GlobalAssetFilter } from '../bt-assets/utils'
import { fileLoad } from '../../../helpers/file/fileLoad'
import { ActivityType } from '../../../generated/graphql'
import { getFileAge } from '../../../helpers/file/fileAge'
import type { BTFileBlockProps } from './bt-file-block.props'
import { useDeleteAsset } from '../../../hooks/useDeleteAsset'
import { getFileName } from '../../../helpers/file/getFileName'
import defaultImage from '../../../assets/images/defaultImage.jpg'
import { toastNotifications } from '../../../helpers/toastNotifications'
import { BTFileIcon } from '../../Brief/bt-brief-upload/bt-files-table/bt-file-icon/bt-file-icon'
import {
  BTFileNameWrapper,
  BTDeleteModalText,
  BTFileBlockWrapper,
  BTDeleteModalWrapper,
  BTDeleteButtonsWrapper,
} from './bt-file-block.presets'
import { fileConvert, IFileFromConvertor } from '../../../helpers/file/fileConvert'

export const BTFileBlock: FC<BTFileBlockProps> = ({
  page,
  index,
  getUrl,
  fileId,
  filter,
  setPage,
  refetch,
  assetId,
  fileType,
  fileName,
  sortOrder,
  createdAt,
  projectId,
  activityId,
  totalPages,
  currentPage,
  searchValue,
  activityType,
  organizationsIds,
  projectName = '',
  activityName = '',
  version,
  projectColor,
}) => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [fileSize, setFileSize] = useState<string>()
  const [preview, setPreview] = useState<IFileFromConvertor[] | null>(null)
  const [fileForDownload, setFileForDownload] = useState<Blob | null>(null)
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [deletingLoading, setDeletingLoading] = useState<boolean>(false)
  const [dataLoading, setDataLoading] = useState<boolean>(false)
  const deleteAsset = useDeleteAsset()

  const name = getFileName(fileName)
  const age = getFileAge(createdAt)

  const getFile = async () => {
    setDataLoading(true)

    try {
      const file = await fileLoad(getUrl)

      if (file) {
        setFileForDownload(file)
      }

      if (file?.size) {
        setFileSize(formatBytes(file?.size))
      }

      const fileToConvert = {
        name,
        content: file,
      }

      fileConvert([fileToConvert], setPreview)
      setDataLoading(false)
    } catch (err) {
      const error = err as Error
      toastNotifications(error.message).error()
    }
  }

  const downloadHandler = () => {
    fileDownloadHelper(fileForDownload, fileName)
  }

  const deleteModalHandlerOpen = () => {
    setDeleteModalOpen(true)
  }

  const deleteModalHandlerClose = () => {
    setDeleteModalOpen(false)
  }

  const deleteAssetHandler = async () => {
    setDeletingLoading(true)

    try {
      await deleteAsset(
        assetId,
        activityType,
        activityId,
        totalPages,
        currentPage,
        filter,
        searchValue,
        sortOrder
      )
      setDeletingLoading(false)
      setDeleteModalOpen(false)

      if (index === 0 && page > 1) {
        setPage(page - 1)

        refetch({
          input: {
            organizations: organizationsIds,
            type: GlobalAssetFilter[filter].value,
            searchValue,
          },
          page: page - 1,
          sortOrder,
        })
      }
    } catch (err) {
      const error = err as Error

      setDeletingLoading(false)
      setDeleteModalOpen(false)

      toastNotifications(error.message).error()
    }
  }

  const clickPreviewHandler = () => {
    if (activityType === ActivityType.Default || activityType === ActivityType.Workshop) {
      navigate(pathname.replace('global-assets', `projects/${projectId}/activity/${activityId}/`))
    } else if (activityType === ActivityType.Brief) {
      navigate(pathname.replace('global-assets', `projects/${projectId}/brief`))
    } else if (activityType === ActivityType.Artwork) {
      let artworkPath = `projects/${projectId}/artwork/${activityId}`

      if (version) {
        artworkPath = artworkPath.concat(`?version=${version}`)
      }

      navigate(pathname.replace('global-assets', artworkPath))
    } else if (!activityType && fileType === 'pdf') {
      navigate(pathname.replace('global-assets', `global-assets/asset/${fileId}`))
    }
  }

  const moreButtonOptions = [
    {
      line: 'Download',
      handler: downloadHandler,
    },
    {
      line: 'Delete',
      handler: deleteModalHandlerOpen,
    },
  ]

  const acceptedImageTypesForPreview =
    fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png'

  const checkForPreview = () => {
    if (fileType === 'pdf') {
      return (
        <div className="preview-area" onClick={clickPreviewHandler}>
          {dataLoading ? (
            <div className="loader">
              <PuffLoader color={colors.projectColors.main} size={50} />
            </div>
          ) : (
            <Document renderMode="svg" file={preview && preview[0].data} className="document">
              <div className="preview-area-content">
                <Page pageNumber={1} />
              </div>
            </Document>
          )}
          {version && <span className="version">V{version}</span>}
        </div>
      )
    }
    if (acceptedImageTypesForPreview) {
      return (
        <div
          className={
            activityType === ActivityType.Artwork ? 'preview-area' : 'preview-area no-click'
          }
          onClick={clickPreviewHandler}
        >
          <img src={dataLoading ? defaultImage : getUrl} alt="default" className="image-preview" />
          {version && <span className="version">V{version}</span>}
        </div>
      )
    }
    return <div className="no-preview-area" />
  }

  const checkForDeleteType = () => {
    if (activityType === ActivityType.Brief) {
      return <BTFileNameWrapper>{projectName}</BTFileNameWrapper>
    }

    if (!activityType) {
      return <BTFileNameWrapper>Global Assets</BTFileNameWrapper>
    }

    return <BTFileNameWrapper>{activityName}</BTFileNameWrapper>
  }

  useEffect(() => {
    getFile()
  }, [])

  return (
    <BTFileBlockWrapper projectColor={projectColor}>
      {checkForPreview()}
      <div className="upper-block">
        <div className="file-name-block">
          <BTFileIcon fileType={fileType} />
          <div className="file-name">{name}</div>
        </div>
        {!fileSize ? (
          <PuffLoader color={colors.projectColors.main} size={15} />
        ) : (
          <div className="file-size">{fileSize}</div>
        )}
      </div>
      <div className="lower-block">
        <div className="age">{age} ago</div>
        <div className="options">
          <BTMoreButton items={moreButtonOptions} />
        </div>
      </div>

      <BTModalWindow isOpened={deleteModalOpen} handleClose={deleteModalHandlerClose}>
        <BTDeleteModalWrapper>
          <BTDeleteModalText>
            Do you want to delete this file:{' '}
            <BTFileNameWrapper>{getFileName(fileName)}</BTFileNameWrapper>
          </BTDeleteModalText>

          <BTDeleteModalText>from: {checkForDeleteType()} ?</BTDeleteModalText>

          <BTDeleteButtonsWrapper>
            <Button
              variant="contained"
              onClick={() => {
                setDeleteModalOpen(false)
              }}
              style={{ width: '120px' }}
            >
              CANCEL
            </Button>

            <LoadingButton
              loading={deletingLoading}
              variant="contained"
              onClick={deleteAssetHandler}
              style={{ width: '120px' }}
            >
              OK
            </LoadingButton>
          </BTDeleteButtonsWrapper>
        </BTDeleteModalWrapper>
      </BTModalWindow>
    </BTFileBlockWrapper>
  )
}
