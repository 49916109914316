import { ApolloClient } from '@apollo/client'
import {
  FileData,
  GetAllActivityAssetsDocument,
  GetAllActivityAssetsQuery,
} from 'generated/graphql'

export const getFilesDataToReorder = (
  fileId: string,
  artworkId: string,
  client: ApolloClient<object>,
  isPlusAction: boolean,
  externalIndex?: number
): FileData[] => {
  const queryResult = client.cache.readQuery({
    query: GetAllActivityAssetsDocument,
    variables: { activityId: artworkId ?? '' },
  }) as GetAllActivityAssetsQuery

  const { getAllActivityAssets: assets } = queryResult

  const index = externalIndex ?? assets.findIndex((elem) => elem.fileId === fileId) + 1

  let elemsToReorder: FileData[] = []

  for (let i = index; i < assets.length; i++) {
    const version = assets[i]?.file?.version ?? 0
    const newVersion = isPlusAction ? version + 1 : version - 1

    elemsToReorder.push({
      fileId: assets[i].fileId ?? '',
      version: newVersion,
    })
  }

  elemsToReorder = elemsToReorder.filter(({ fileId: localFileId }) => localFileId !== '')

  return elemsToReorder
}
