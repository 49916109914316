import { useAppSelector } from 'redux/store'
import { sortByBoolean } from 'utils/sorting/sorting'
import {
  GetAllFeedbackMessagesQuery,
  GetAllFeedbackMessagesDocument,
  useRemoveFeedbackMemberMutation,
} from '../../generated/graphql'

export function useRemoveFeedbackMember() {
  const [removeMember] = useRemoveFeedbackMemberMutation()
  const { assetId } = useAppSelector((state) => state.sidebarApps)

  return async (
    feedbackId: string,
    parentFeedbackMessageId: string,
    userId: string,
    childFeedbackMessageId?: string
  ) => {
    await removeMember({
      variables: {
        input: {
          feedbackMessageId: childFeedbackMessageId ?? parentFeedbackMessageId,
          userId,
        },
      },
      update: (cache) => {
        const messages = cache.readQuery({
          query: GetAllFeedbackMessagesDocument,
          variables: {
            input: { feedbackId: assetId ? undefined : feedbackId, assetId },
          },
        }) as GetAllFeedbackMessagesQuery

        if (messages && messages.getFeedbackMessagesByFeedbackId.messages.length) {
          cache.writeQuery({
            query: GetAllFeedbackMessagesDocument,
            variables: {
              input: { feedbackId: assetId ? undefined : feedbackId, assetId },
            },
            data: {
              getFeedbackMessagesByFeedbackId: {
                __typename: 'FeedbackMessageResponse',
                messages: !childFeedbackMessageId
                  ? [
                      ...messages.getFeedbackMessagesByFeedbackId.messages.map((elem) =>
                        elem.feedbackMessageId === parentFeedbackMessageId
                          ? {
                              ...elem,
                              members: [],
                            }
                          : elem
                      ),
                    ].sort((first, second) => sortByBoolean(first.pinned!, second.pinned!))
                  : [
                      ...messages.getFeedbackMessagesByFeedbackId.messages.map((elem) =>
                        elem.feedbackMessageId === parentFeedbackMessageId
                          ? {
                              ...elem,
                              replyedComments: elem.replyedComments.map((reply) =>
                                reply.feedbackMessageId === childFeedbackMessageId
                                  ? {
                                      ...reply,
                                      members: [],
                                    }
                                  : reply
                              ),
                            }
                          : elem
                      ),
                    ].sort((first, second) => sortByBoolean(first.pinned!, second.pinned!)),
              },
            },
          })
        }
      },
    })
  }
}
