import { useAppSelector } from 'redux/store'
import CancelIcon from '@mui/icons-material/Cancel'
import { FC, useEffect, useRef, useState } from 'react'
import { BTTaskMembersStyled } from './bt-task-members.presets'
import { TaskRole, useGetProjectMembersByIdQuery } from 'generated/graphql'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import type { IBTTaskMembersProps, IBTTaskMember } from './bt-task-members.props'
import { Avatar, Badge, Button, IconButton, ListItemButton, TextField } from '@mui/material'

const filter = createFilterOptions<IBTTaskMember>()

export const BTTaskMembers: FC<IBTTaskMembersProps> = ({ taskBelongsToId, membersData }) => {
  const [users, setUsers] = useState<IBTTaskMember[]>([])
  const { data, loading, refetch } = useGetProjectMembersByIdQuery({
    variables: { projectId: taskBelongsToId! },
  })
  const { currentOrganizationId, organizations } = useAppSelector((store) => store.login)
  const { whoami } = useAppSelector((store) => store.login)

  const inputRef = useRef<HTMLInputElement>(null)

  const { members, setMembers, isEditing } = membersData

  const projectMembers = data?.getProjectMembersById
  const organizationMembers = organizations.find(
    (organization) => organization.id === currentOrganizationId
  )?.members
  let taskMembers: IBTTaskMember[] = []

  useEffect(() => {
    if (taskBelongsToId === whoami?.id) {
      taskMembers = [
        {
          id: whoami?.id,
          name: whoami?.name || '',
          avatarUrl: whoami?.avatarUrl,
          role: TaskRole.None,
        },
      ]
    } else if (taskBelongsToId === currentOrganizationId && organizationMembers) {
      taskMembers = organizationMembers.map(({ user }) => ({
        id: user.id,
        name: user.name || '',
        avatarUrl: user.avatarUrl,
        role: TaskRole.None,
      }))
    } else if (projectMembers && projectMembers.length) {
      taskMembers = projectMembers.map(({ user }) => ({
        id: user.id,
        name: user.name || '',
        avatarUrl: user.avatarUrl,
        role: TaskRole.None,
      }))
    }

    setUsers(taskMembers)
  }, [data])

  useEffect(() => {
    if (taskBelongsToId && taskBelongsToId !== '') {
      refetch({ projectId: taskBelongsToId })
    }
  }, [taskBelongsToId])

  const removeMember = (userId: string) => {
    let filteredMembers: IBTTaskMember[] = []

    if (isEditing) {
      filteredMembers = members.map((user) =>
        user.id === userId ? { ...user, role: TaskRole.None } : user
      )
    } else {
      filteredMembers = members.filter(({ id }) => id !== userId)
    }

    setMembers(filteredMembers)
  }

  let assigned = members

  if (isEditing) {
    assigned = members.filter(({ role }) => role !== TaskRole.None)
  }

  const assignedListPreview = assigned.map(
    ({ id, avatarUrl }, index) =>
      index <= 2 && (
        <Badge
          key={id}
          overlap="circular"
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          badgeContent={
            <IconButton className="remove-button" onClick={() => removeMember(id)}>
              <CancelIcon fontSize="small" sx={{ color: '#DADADA' }} />
            </IconButton>
          }
        >
          <Avatar src={avatarUrl} sx={{ height: 35, width: 35 }} />
        </Badge>
      )
  )

  const isUserExist = (userId: string): boolean => {
    const indexOfUser = members.findIndex(({ id }) => id === userId)
    return indexOfUser !== -1
  }

  const addMember = (user: IBTTaskMember) => {
    if (isEditing) {
      if (isUserExist(user.id)) {
        const filteredMembers = members.map((elem) =>
          elem.id === user.id ? { ...elem, role: TaskRole.Assigned } : elem
        )

        setMembers(filteredMembers)
      } else {
        setMembers((prevState) => [...prevState, { ...user, role: TaskRole.Assigned }])
      }
    } else {
      setMembers((prevState) => [...prevState, { ...user, role: TaskRole.Assigned }])
    }
  }

  return (
    <BTTaskMembersStyled>
      {members.length > 0 && (
        <div className="members">
          {assignedListPreview}
          {membersData.members.length > 3 && (
            <Button className="rest-members-amount" onClick={() => { }}>
              +{membersData.members.length - 3}
            </Button>
          )}
        </div>
      )}
      <Autocomplete
        className="search"
        onChange={(event, newValue) => {
          if (typeof newValue !== 'string' && newValue) {
            addMember(newValue)
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params)

          return filtered
        }}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option
          }
          if (option.name) {
            return option.name
          }
          return option.name
        }}
        getOptionDisabled={(option) =>
          members.some(({ id, role }) => id === option.id && role === TaskRole.Assigned)
        }
        id="free-solo-dialog-demo"
        options={users}
        loading={loading}
        selectOnFocus
        clearOnBlur
        ListboxProps={{ className: 'options-list' }}
        renderOption={(props, option) => (
          <ListItemButton {...props} component="li" sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              src={option.avatarUrl}
              alt={option.name}
              sx={{ height: 20, width: 20, marginRight: '10px' }}
            />
            <span>{option.name}</span>
          </ListItemButton>
        )}
        freeSolo
        /** @description params: any - to resolve pipelines error */
        renderInput={(params: any) => <TextField {...params} fullWidth inputRef={inputRef} />}
      />
    </BTTaskMembersStyled>
  )
}
