/* eslint-disable import/no-unresolved */
import { ActivityType, File } from 'generated/graphql'

export const getNavigationLink = (
  composedDocument: File,
  uploadedDocument: File,
  type: ActivityType,
  id: string,
  projectId: string,
  isFullPath?: boolean
): string => {
  let navigatePath = `/activity/${id}`

  if (type !== ActivityType.Artwork) {
    navigatePath = `/activity/${id}`
  } else {
    navigatePath = `/artwork/${id}`
  }

  const projectPath = `/projects/${projectId}`

  navigatePath = isFullPath ? projectPath + navigatePath : navigatePath

  return navigatePath
}
