import styled from 'styled-components'
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress'
import { styled as MUIstyled } from '@mui/material/styles'

export const BorderLinearProgress = MUIstyled(LinearProgress)(({ theme }) => ({
  height: 6,
  width: '100%',
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#EEEEEE',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}))

export const Wrapper = styled.div<{ isDragging?: boolean }>`
  width: clamp(135px, 100%, 275px);
  padding: 15px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  justify-content: space-around;
  margin: ${({ isDragging }) => isDragging && '-24px 0'};

  &:hover {
    width: clamp(140px, 100%, 280px);
    border-top: 2px solid white;
    border-bottom: 2px solid white;
  }
`
export const ItemsContainer = styled.div`
  display: flex;
  align-items: center;
  & .MuiSvgIcon-root {
    height: 16px;
    width: 16px;
  }
`
export const Raw = styled.div`
  display: flex;
  justify-content: space-between;
`
interface InlineDividerProps {
  divideWidth: number
}
export const InlineDivider = styled.div<InlineDividerProps>`
  width: 0px;
  height: 0px;
  margin: ${({ divideWidth }) => `0 ${divideWidth / 2}px`};
`
export const Title = styled.span`
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ theme }) => ({ ...theme.typography?.subtitle3 })};
`

export const Label = styled.span`
  ${({ theme }) => ({ ...theme.typography?.subtitle3 })};
  font-size: 12px;
  color: #5f6974;
`
