import styled from 'styled-components'
const SoonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 6px;
`
const SoonHeading = styled.div`
  ${({ theme }) => ({ ...theme.typography?.h5 })};
  color: #0066ff;
`
const SoonDescription = styled.div`
  ${({ theme }) => ({ ...theme.typography?.regular })};
  color: #333333;
  text-align: center;
  width: 178px;
`

export { SoonDescription, SoonHeading, SoonWrapper }
