import { FC, SyntheticEvent } from 'react'
import DeleteIcon from '@mui/icons-material/Delete'
import type { BTRemoveDeliverablesProps } from './bt-remove-deliverables.props'
import { useDeleteDeliverables } from 'hooks/brief/useDeleteDeliverables'
export const BTRemoveDeliverables: FC<BTRemoveDeliverablesProps> = ({ name, id }) => {
  const deleteDeliverable = useDeleteDeliverables()
  const handleClick = async (event: SyntheticEvent<SVGSVGElement>) => {
    event.stopPropagation()
    await deleteDeliverable(name, id)
  }

  return <DeleteIcon htmlColor="#DADADA" onClick={handleClick} />
}
