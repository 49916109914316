/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
import { BTGetStarted, BTLogin, BTSignUp } from 'components/bt-auth-elements'
import { RouteObject, Navigate } from 'react-router-dom'
import { BTRecoverPassword } from '../../components/bt-auth-elements/bt-recover-password'
import { BTForgotPass } from '../../components/bt-auth-elements/bt-forgot-password'
import { BTSetNewPassword } from '../../components/bt-auth-elements/bt-set-new-password'

const AuthRoutes: RouteObject[] = [
  { index: true, element: <Navigate to="landing" /> },
  { path: 'login', element: <BTLogin /> },
  { path: 'forgot', element: <BTForgotPass /> },
  { path: 'recover', element: <BTRecoverPassword /> },
  { path: 'new-pass', element: <BTSetNewPassword /> },
  { path: 'register', element: <BTSignUp /> },
  { path: 'landing', element: <BTGetStarted /> },
  { path: '*', element: <Navigate to="/" /> },
]
export { AuthRoutes }
