import Avatar from '@mui/material/Avatar'
import { useAppSelector } from 'redux/store'
import { BTAppsLabel, BTAppsSubLabel } from 'components/SidebarApps/shared'
import { BTPersonalMessage } from '../bt-personal-message'
import { BTLabel } from '../bt-confirm-step/bt-confirm-step.presets'
import { HIDE_MEMBERS_CONDITION } from '../../../../../helpers/constants'
import {
  BTWrapper,
  BTSendPurpose,
  BTLastStepWrapper,
  BTLastStepCentered,
} from './bt-last-step.presets'
import {
  BTSelectedMembersItem,
  BTSelectedMembersList,
} from '../bt-entry-step/bt-entry-step.presets'

export const BTLastStep = () => {
  const { users, purpose, message } = useAppSelector((state) => state.sidebarApps.sendTab)

  const membersRender = () => {
    return (
      <BTSelectedMembersList>
        {users.map((member, index) => {
          if (index < HIDE_MEMBERS_CONDITION) {
            return (
              <BTSelectedMembersItem>
                <Avatar src={member.user.avatarUrl} sx={{ height: 35, width: 35 }} />
              </BTSelectedMembersItem>
            )
          }
        })}
        {users.length >= 6 && (
          <div className="rest-members-amount">+{users.length - HIDE_MEMBERS_CONDITION}</div>
        )}
      </BTSelectedMembersList>
    )
  }

  return (
    <BTWrapper>
      <BTAppsLabel>Send</BTAppsLabel>

      <BTLastStepWrapper>
        <BTLastStepCentered>
          <BTAppsSubLabel>Sent to {users.length} team members!</BTAppsSubLabel>
          {membersRender()}
        </BTLastStepCentered>

        <BTLabel>Purpose for sending:</BTLabel>
        <BTSendPurpose>{purpose}</BTSendPurpose>

        {message && <BTPersonalMessage message={message} />}
      </BTLastStepWrapper>
    </BTWrapper>
  )
}
