/* eslint-disable no-nested-ternary */
import { propEq } from 'ramda'
import { useMoveTask } from 'hooks'
import { colors } from 'theme/Colors'
import { useAppSelector } from 'redux/store'
import { FC, useEffect, useState } from 'react'
import { BTTaskCard } from '../shared/bt-task-card'
import { IAssigneeData } from '../shared/bt-assignee'
import ScrollContainer from 'react-indiana-drag-scroll'
import { NEW_TASK_ROW_VERSION } from 'helpers/constants'
import { BTModalWindow } from '../shared/bt-modal-window'
import { BTCreateTaskForm } from '../shared/bt-task-create-form'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { BTTaskPageLayoutWrapper } from './bt-task-page-layout.presets'
import { useGetCurrentOrganization } from 'hooks/useGetCurrentOrganization'
import { AllTasksDocument, TaskStatus, useAllTasksQuery } from 'generated/graphql'
import { BTGridColumn, BTGridsWrapper, IColumnData } from '../shared/bt-grid-column'

const STATUS_LABELS: Record<TaskStatus, string> = {
  [TaskStatus.Todo]: 'To do',
  [TaskStatus.InProgress]: 'In progress',
  [TaskStatus.Review]: 'Review',
  [TaskStatus.Complete]: 'Complete',
}

export const BTTaskPageLayout: FC = () => {
  const moveTask = useMoveTask()
  const { sizes } = useAppSelector((state) => state.header)
  const [isOpen, setIsOpen] = useState(false)
  const { data, loading, refetch, client } = useAllTasksQuery()
  const userName = useAppSelector((store) => store.login.whoami?.name) || 'User'

  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)

  const currentOrganization = useGetCurrentOrganization()

  const columnData: Array<IColumnData> = Object.entries(STATUS_LABELS).map(([name, title]) => ({
    name,
    title,
  }))

  const onDragEnd = async (result: DropResult): Promise<void> => {
    if (result.destination) {
      const task = data?.tasks.find(propEq('id', result.draggableId))!
      await moveTask(task, result.destination.droppableId as TaskStatus, task.rowVersion!)
    }
  }

  const addTaskButton = {
    title: 'Add task',
    actionHandler: handleOpen,
  }

  useEffect(() => {
    refetch()
    if (data?.tasks.length! > 0) {
      client.writeQuery({
        query: AllTasksDocument,
        data: { tasks: data?.tasks },
      })
    }
  }, [loading])

  return (
    <BTTaskPageLayoutWrapper>
      <BTGridsWrapper scrollWidth={sizes.width - 80}>
        <ScrollContainer
          vertical={false}
          horizontal
          hideScrollbars={false}
          className="horizontal-scroll-block"
        >
          <DragDropContext onDragEnd={onDragEnd}>
            {columnData.map((elem, columnIndex) => {
              const notArchivedTasks = data?.tasks?.filter(
                ({ status, archivedAt }) => status === elem.name && archivedAt === null
              )

              return (
                <BTGridColumn
                  title={elem.title}
                  emptyColumnText="No tasks"
                  name={elem.name}
                  key={elem.name}
                  isDataLoading={loading}
                  cardsData={
                    notArchivedTasks?.map((task) => {
                      const labelTitleCondition = task?.organizationId
                        ? 'Organization: '
                        : task?.userId
                        ? 'User: '
                        : 'Project: '

                      const labelDescriptionCondition = task?.organizationId
                        ? currentOrganization?.name
                        : task?.userId
                        ? userName
                        : task.project?.name

                      const labelBgColorCondition = task?.organizationId
                        ? 'green'
                        : task?.userId
                        ? 'blue'
                        : task.project?.color

                      const taskBelongsToCondition =
                        task.organizationId || task.userId || task.project?.id

                      return {
                        id: task.id,
                        card: (
                          <BTTaskCard
                            id={task.id}
                            taskBelongsToId={taskBelongsToCondition}
                            description={task.description}
                            title={task.name}
                            labelData={{
                              title: labelTitleCondition,
                              description: labelDescriptionCondition,
                              bgColor: labelBgColorCondition,
                            }}
                            bgColor={colors.white}
                            assignees={task.assignedTo.map(
                              (user): IAssigneeData => ({
                                name: user.name ?? '',
                                imageUrl: user.avatarUrl,
                                id: user.id,
                              })
                            )}
                            isNew={task?.rowVersion! <= NEW_TASK_ROW_VERSION}
                            checklist={task.checklist}
                            dueDate={task.dueDate}
                          />
                        ),
                      }
                    }) || []
                  }
                  columnActionButton={!columnIndex ? addTaskButton : null}
                />
              )
            })}
          </DragDropContext>
        </ScrollContainer>
      </BTGridsWrapper>
      <BTModalWindow isOpened={isOpen} handleClose={handleClose}>
        <BTCreateTaskForm taskStatus={TaskStatus.Todo} handleClose={handleClose} />
      </BTModalWindow>
    </BTTaskPageLayoutWrapper>
  )
}
