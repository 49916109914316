import styled from '@emotion/styled'
import { colors } from '../../../../../theme/Colors'

const MemberListItemWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-radius: 5px;
  background-color: ${colors.navyGrey[200]};
  width: 100%;
  border: none;
  color: ${colors.navyGrey[400]};
  cursor: pointer;

  .avatar-block {
    display: flex;
    align-items: center;
    gap: 0 10px;

    span {
      color: ${colors.projectColors.black};
      font-size: 10px;
      line-height: 10px;
    }
  }
`

export { MemberListItemWrapper }
