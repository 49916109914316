import { colors } from 'theme/Colors'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;

  display: flex;
  flex-direction: column;

  background-color: white;

  & div:last-child {
    border: none;
  }
`
const Item = styled.div`
  padding-left: 14px;
  padding-right: 14px;

  height: 38px;

  display: flex;
  align-items: center;

  ${({ theme }) => ({ ...theme.typography?.small })};
  color: ${colors.projectColors.iconDarkGrey};
  cursor: pointer;

  &:hover {
    background: rgba(0, 102, 255, 0.12);
  }
`

export { Wrapper, Item }
