import { DateTime, Interval } from 'luxon'

export const diffInDays = (date: Date | string): number | string => {
  let diff: string | number = Interval.fromDateTimes(
    DateTime.fromISO(new Date(date).toISOString()),
    DateTime.fromISO(new Date().toISOString())
  )
    .length('days')
    .toFixed(0)

  diff = Number.isNaN(Number.parseInt(diff, 10)) ? 0 : diff

  return diff
}
