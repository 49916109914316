import styled from '@emotion/styled'
import { colors } from 'theme/Colors'

export const BTChangeCurrentOrganizationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;

  legend {
    width: 0;
  }

  .title {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    color: ${colors.black};
  }

  .input-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 240px;

    .title {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      padding: 5px;
      color: ${colors.navyGrey[400]};
    }

    span {
      align-self: center;
    }

    .selecet-block {
      display: flex;
      width: 100%;
      align-items: center;
      gap: 10px;

      .plus-icon {
        color: ${colors.projectColors.main};
        cursor: pointer;
      }
    }

    .MuiSelect-select {
      width: 150px;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      padding: 10px 14px;
    }
  }

  button {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    text-transform: capitalize;
  }
`
