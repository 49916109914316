/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable import/no-unresolved */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import { useState } from 'react'
import { LoadingButton } from '@mui/lab'
import Avatar from '@mui/material/Avatar'
import { useAppSelector } from 'redux/store'
import { CircularProgress } from '@mui/material'
import { useMachineContext } from 'services/StateMachines/bt-apps-send/bt-apps-send'
import { useSendActivity } from 'hooks/notifications/useSendActivity'
import { getUsersIds } from './helpers'
import { Option } from './bt-confirm-step.props'
import { BTSelect } from './components/bt-select'
import { BTSendPrevButton } from '../bt-change-step'
import { useAppDispatch } from '../../../../../redux/store'
import { sendPurpose, sendMessage } from '../../../../../redux/SidebarAppsSlice'
import { BTConfirmTextArea } from './components/bt-confirm-step-textarea'
import { BTAppsLabel, BTAppsDivider, BTAppsWrapper } from '../../../shared'
import {
  HIDE_MEMBERS_CONDITION,
  SEND_ACTIVITY_MESSAGE_PURPOSE,
} from '../../../../../helpers/constants'
import { BTLabel, BTButtonsBorder, BTNavigationButtonsWrapper } from './bt-confirm-step.presets'
import {
  BTSelectedMembersItem,
  BTSelectedMembersList,
} from '../bt-entry-step/bt-entry-step.presets'

export const BTConfirmStep = () => {
  const { users } = useAppSelector((state) => state.sidebarApps.sendTab)
  const [purpose, setPurpose] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const [_, send] = useMachineContext()
  const dispatch = useAppDispatch()

  const sendActivity = useSendActivity()

  const conditionOfSendButton = !purpose

  const selectHandler = (external: unknown) => {
    const selectedOption = external as Option
    setPurpose(selectedOption.label)
  }

  const messageHandler = (value: string) => {
    setMessage(value)
  }

  const sendMessageHandler = async () => {
    setLoading(true)
    dispatch(sendPurpose(purpose))
    dispatch(sendMessage(message))

    const usersIds = getUsersIds(users)

    await sendActivity(usersIds, purpose, message)

    setLoading(false)
    send && send('next')
  }

  const membersRender = () => {
    return (
      <BTSelectedMembersList>
        {users.map((member, index) => {
          if (index < HIDE_MEMBERS_CONDITION) {
            return (
              <BTSelectedMembersItem>
                <Avatar src={member.user.avatarUrl} sx={{ height: 35, width: 35 }} />
              </BTSelectedMembersItem>
            )
          }
        })}
        {users.length > HIDE_MEMBERS_CONDITION && (
          <div className="rest-members-amount">+{users.length - HIDE_MEMBERS_CONDITION}</div>
        )}
      </BTSelectedMembersList>
    )
  }

  return (
    <BTAppsWrapper>
      <BTAppsWrapper>
        <BTAppsLabel>Send</BTAppsLabel>
        {membersRender()}

        <BTLabel>Purpose for sending:</BTLabel>
        <BTSelect onChange={selectHandler} options={SEND_ACTIVITY_MESSAGE_PURPOSE} />

        <BTAppsWrapper>
          <BTLabel>Personalised message:</BTLabel>
          <BTConfirmTextArea callback={messageHandler} />
        </BTAppsWrapper>
      </BTAppsWrapper>

      <BTAppsDivider isTop isBottom />

      <BTNavigationButtonsWrapper>
        <BTSendPrevButton />
        <BTButtonsBorder />
        <LoadingButton
          variant="text"
          className={conditionOfSendButton ? 'send-button disabled' : 'send-button'}
          onClick={sendMessageHandler}
          disabled={conditionOfSendButton}
        >
          {loading ? (
            <CircularProgress color="inherit" size={16} />
          ) : (
            `Send to ${users.length} team members`
          )}
        </LoadingButton>
      </BTNavigationButtonsWrapper>
    </BTAppsWrapper>
  )
}
