/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unsafe-optional-chaining */
import { useParams } from 'react-router-dom'
import { IUser } from '../../components/SidebarApps/bt-approve/bt-approve.props'
import {
  ApprovalStatus,
  useGetBriefQuery,
  GetApprovalsQuery,
  GetApprovalsDocument,
  useUpdateApprovalMutation,
} from '../../generated/graphql'

export const useUpdateApproval = () => {
  const { id: projectId, activityId, artworkId } = useParams()
  const briefResponse = useGetBriefQuery({ variables: { id: projectId } })
  const briefId = briefResponse.data?.getBrief.id
  const [updateApproval] = useUpdateApprovalMutation()

  return async (userId: string, assignees: IUser[]) => {
    const id = activityId || artworkId || briefId
    const isMemberSelected = assignees.some((assignee) => assignee.id === userId)

    const result = await updateApproval({
      variables: {
        input: {
          id: id!,
          userIds: [userId],
        },
      },
      update(cache, { data }) {
        const readedQuery = cache.readQuery({
          query: GetApprovalsDocument,
          variables: {
            id: id!,
          },
        }) as GetApprovalsQuery

        const updateCondition = () => {
          const cacheForChange = data?.updateApproval.concat() || []
          if (isMemberSelected) {
            const updatedCache = cacheForChange.filter((item) => {
              return item.userId !== userId
            })
            return updatedCache
          }
          return cacheForChange
        }

        const newApproversList = updateCondition()

        const globalApprove =
          newApproversList.length &&
          newApproversList.every((item) => item.status === ApprovalStatus.Approved)

        cache.writeQuery({
          query: GetApprovalsDocument,
          variables: {
            id: id!,
          },
          data: {
            approvals: {
              ...readedQuery.approvals,
              approve: updateCondition(),
              isApproved: globalApprove,
            },
          },
        })
      },
    })

    return result
  }
}
