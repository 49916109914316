import { FC, useEffect } from 'react'
import { hideHeader } from 'redux/HeaderSlice'
import { useAppDispatch } from 'redux/store'
import { BTErrorTemplatePageWrapper } from './bt-error-page.presets'
import {
  ERROR_PAGE_DEFAULT_STATUS_CODE,
  ERROR_PAGE_DEFAULT_DESCRIPTION,
  ERROR_PAGE_DEFAULT_MESSAGE,
} from '../../../helpers/constants'

import type { BTErrorTemplatePageProps } from './bt-error-page.props'

export const BTErrorTemplatePage: FC<BTErrorTemplatePageProps> = ({
  description = ERROR_PAGE_DEFAULT_DESCRIPTION,
  message = ERROR_PAGE_DEFAULT_MESSAGE,
  statusCode = ERROR_PAGE_DEFAULT_STATUS_CODE,
}) => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(hideHeader())

    const body = document.querySelector('body')

    if (body) {
      body.style.overflow = 'hidden'
    }

    return () => {
      if (body) {
        body.style.overflow = 'auto'
      }
    }
  }, [])

  return (
    <BTErrorTemplatePageWrapper data-status-code={statusCode}>
      <div className="main-block">
        <span className="title">{statusCode}</span>
        <span className="main-text">{message}</span>
        <span className="description">{description}</span>
      </div>
    </BTErrorTemplatePageWrapper>
  )
}
