import { Collapse, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React, { useState, FC } from 'react'
import { SideList } from './sub-buttons-container.presets'
import { ReactComponent as LessButtonLogo } from '../../../../assets/images/lessButton.svg'
import { ReactComponent as MoreButtonLogo } from '../../../../assets/images/moreButton.svg'

import { SubButtonsContainerProps, EventCallback } from './sub-buttons-container.props'

export const SubButtonsContainer: FC<SubButtonsContainerProps> = ({
  open,
  logo: LogoComponent,
  name,
  children,
  renderActionButton,
}) => {
  const [isSubButtonsVisible, setSubButtonsVisible] = useState(false)

  const toggleList: EventCallback = (event) => {
    event.stopPropagation()
    setSubButtonsVisible((prev) => !prev)
  }
  return (
    <SideList open={open}>
      <ListItemButton onClick={toggleList}>
        <ListItemIcon>
          <LogoComponent />
        </ListItemIcon>
        {open && <ListItemText primary={name} />}
        {open && renderActionButton && renderActionButton(toggleList)}
        {isSubButtonsVisible && open ? <LessButtonLogo /> : <MoreButtonLogo />}
      </ListItemButton>
      <Collapse in={isSubButtonsVisible && open} timeout="auto" unmountOnExit>
        <SideList
          //@ts-ignore
          component="div"
          disablePadding
          open={open}
        >
          {children}
        </SideList>
      </Collapse>
    </SideList>
  )
}
