import { FC } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import PublishIcon from '@mui/icons-material/Publish'
import type { BTActivityItemProps } from './bt-activity-item.props'
import { ACTIVITY_TYPES } from '../../../bt-add-activity-button.props'
import { Block, Title, Wrapper, SubTitle } from '../bt-deliverable-item.presets'

const BTActivityItem: FC<BTActivityItemProps> = ({ activityType, onClick }) => {
  const onInnerClick = () => {
    onClick(activityType)
  }

  return (
    <Wrapper onClick={onInnerClick}>
      <Block>
        {activityType.name === ACTIVITY_TYPES.UPLOAD ? <PublishIcon /> : <EditIcon />}
        <Title>{activityType.name}</Title>
      </Block>
      <SubTitle>{activityType.description}</SubTitle>
    </Wrapper>
  )
}

export default BTActivityItem
