/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
import { Button } from '@mui/material'
import { BTAppendButton } from 'components/shared/bt-append-button'
import { BTRemoveDeliverables } from 'components/SidebarApps/bt-brief-deliverables/bt-remove-deliverables'
import { useCreateBrief, useActionAtEnterKey } from 'hooks'
import { useNavLocation } from 'hooks/shared/useNavLocation'
import { setupBriefId } from 'redux/BriefCreationSlice'
import { setupDeliverables, setupDeliverable } from 'redux/SidebarAppsSlice'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import {
  Wrapper,
  Block,
  Heading,
  ButtonInnerText,
  CustomScrollableContainer,
} from './bt-brief-right-panel.presets'

export const BTBriefRightPanel = () => {
  const { deliverables } = useAppSelector((state) => state.sidebarApps)
  const { id } = useAppSelector((state) => state.login.whoami)
  const dispatch = useAppDispatch()
  const createBrief = useCreateBrief()
  const [{ pathname }, navigation] = useNavLocation()
  const onEnterKey = useActionAtEnterKey()

  const briefNextHandler = async () => {
    const modifiedDeliverables = deliverables.map((deliverable) => {
      return { ...deliverable, relationId: '' }
    })
    try {
      const { data } = await createBrief('Brief', id, undefined, modifiedDeliverables)
      if (data) {
        dispatch(setupDeliverables(deliverables))
        dispatch(setupDeliverable(deliverables[0]))
        dispatch(setupBriefId(data.createBrief.activity.id))

        navigation(pathname.replace('/brief/create/compose/compose-template', '/brief'))
      }
    } catch (e) {
      console.log(e, 'error')
    }
  }

  onEnterKey(briefNextHandler, [deliverables])

  return (
    <Wrapper>
      <Block>
        <Heading>My Brief</Heading>
        <CustomScrollableContainer horizontal={false} vertical hideScrollbars={false}>
          {deliverables.map(({ id, name }) => (
            <BTAppendButton
              Button={BTRemoveDeliverables}
              index={id}
              title={name}
              bgColor="#F2F2F2"
              titleColor="#333333"
              key={id}
            />
          ))}
        </CustomScrollableContainer>
      </Block>
      <Button
        variant="contained"
        sx={{ boxShadow: 3, padding: '8px 10px', minWidth: '160px' }}
        disabled={!deliverables.length}
        onClick={briefNextHandler}
      >
        <ButtonInnerText>Next</ButtonInnerText>
      </Button>
    </Wrapper>
  )
}
