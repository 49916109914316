import { colors } from 'theme/Colors'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: #f7f7f7;
  cursor: pointer;
`

const Block = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

const Title = styled.div`
  ${({ theme }) => ({ ...theme.typography?.subtitle3 })};
  color: ${colors.navyGrey[800]};
  text-transform: capitalize;
`

const SubTitle = styled.div`
  ${({ theme }) => ({ ...theme.typography?.regular })};
  color: ${colors.navyGrey[600]};

  margin-top: 6px;
`
export { Wrapper, SubTitle, Title, Block }
