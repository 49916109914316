import Select from 'react-select'
import { styled } from '@mui/system'
const BTSelect = styled(Select)`
  width: 100%;
  & span[class*='indicatorSeparator'] {
    display: none;
  }
  & div[class*='indicatorContainer'] {
    cursor: pointer;
    color: #828282;
  }
  & div[class*='control'] {
    background: #f2f2f2;
    border: none;
    box-shadow: none;
  }
  & div[id*='react-select'][id$='listbox'] {
    position: sticky;
    z-index: 9999;
    width: 100%;
    background: #fff;
    & div[class*='option'] {
      cursor: pointer;
      background: #f2f2f2;
      color: #333333;
      &:hover {
        background: #fafafa;
      }
      &:not(&:last-child) {
        margin-bottom: 5px;
      }
    }
    & div[class*='MenuList'] {
      padding: 5px 0;
    }
  }
`

export { BTSelect }
