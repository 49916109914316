/* eslint-disable max-len */
import { FC, useState } from 'react'
import { Transition } from 'react-transition-group'
import { ButtonWrapper, DeleteButtonIcon } from './bt-delete-button.presets'
import { toastNotifications } from '../../../helpers/toastNotifications'
import { BTDeleteButtonProps } from './bt-delete-button.props'

export const BTDeleteButton: FC<BTDeleteButtonProps> = ({ handler, loading = false }) => {
  const [saved, setSaved] = useState<boolean>(false)

  const onClick = async () => {
    try {
      await handler()
      setSaved(true)
      setTimeout(setSaved, 2000, false)
    } catch (err) {
      const error = err as Error
      toastNotifications(error.message).error()
    }
  }

  return (
    <ButtonWrapper>
      <button type="button" className="save-button-general" onClick={onClick} disabled={loading}>
        <div className="save-icon">
          <DeleteButtonIcon />
        </div>
      </button>
    </ButtonWrapper>
  )
}
