import { BTTaskPageLayout } from 'components/bt-task-page-layout'
import { BTComingSoon } from 'components/shared/bt-coming-soon'
import { RouteObject } from 'react-router-dom'

const TasksRoutes: RouteObject[] = [
  { path: 'global-tasks', element: <BTTaskPageLayout /> },
  { path: 'global-tasks/calendar', element: <BTComingSoon /> },
  { path: 'global-tasks/archived', element: <BTComingSoon /> },
]
export { TasksRoutes }
