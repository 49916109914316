import { colors } from 'theme/Colors'
import styled from 'styled-components'

export const BTAddActivityPopupWrapper = styled.div`
  width: 278px;

  .popup-title {
    ${({ theme }) => ({ ...theme.typography.subtitle1 })};

    margin-bottom: 10px;
  }

  .popup-subtitle {
    ${({ theme }) => ({ ...theme.typography.regular })};
    color: ${colors.projectColors.iconDarkGrey};

    margin-bottom: 20px;
  }

  .create-activity-options-item:not(:last-child) {
    margin-bottom: 20px;
  }
`
