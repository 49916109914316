/* eslint-disable no-nested-ternary */
import { useParams, useLocation, Navigate } from 'react-router-dom'
import { useEffect } from 'react'
import { BTSetBrief } from '../bt-set-brief'
import { useGetBriefQuery } from '../../../generated/graphql'
import { BTLoader } from '../../shared/bt-loader'

export const BTBrief = () => {
  const { pathname } = useLocation()
  const { id: projectId } = useParams()
  const {
    data: briefData,
    loading: briefLoading,
    refetch,
  } = useGetBriefQuery({
    variables: { id: projectId },
  })

  useEffect(() => {
    refetch({ id: projectId })
  }, [pathname])

  return briefLoading ? (
    <BTLoader />
  ) : briefData ? (
    <BTSetBrief briefData={briefData} />
  ) : (
    <Navigate to={pathname.replace('/brief', '/brief/create')} />
  )
}
