import { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { getHeaderTabs } from '../../helpers/header'
import { useProject } from '..'
import { sortByBoolean } from '../../utils/sorting/sorting'
import {
  ActionButtonIconType,
  hideHeader,
  setHeaderActionButtons,
  setHeaderAssignees,
  setHeaderClientName,
  setHeaderDeadlineTime,
  setHeaderModalShow,
  setHeaderTitle,
  setupHeaderTabs,
  showHeader,
} from '../../redux/HeaderSlice'
import { ProjectRole } from '../../generated/graphql'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { useHeaderSetup } from '.'

export const useSetupHeaderData = () => {
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const { project: data, loading, error } = useProject(id)
  const [routes, isVisible] = useHeaderSetup()
  const { tabs } = useAppSelector((state) => state.header)
  const { pathname } = useLocation()
  const navigation = useNavigate()

  useEffect(() => {
    if (error && id) {
      navigation('/404')
    }
  }, [error])

  useEffect(() => {
    if (routes) {
      if (routes.name) {
        dispatch(setHeaderTitle(routes.name || data?.name))
      }

      if (routes.actionButtons) {
        dispatch(setHeaderModalShow(!routes.actionButtons))
      } else {
        dispatch(setHeaderModalShow(true))
      }

      // eslint-disable-next-line no-unused-expressions
      isVisible ? dispatch(showHeader()) : dispatch(hideHeader())
    }
  }, [routes])

  useEffect(() => {
    if (routes) {
      dispatch(setupHeaderTabs(routes.tabs))

      if (pathname.includes('projects')) {
        dispatch(setupHeaderTabs(tabs))
      }
    }
  }, [pathname])

  useEffect(() => {
    if (data) {
      dispatch(setHeaderModalShow(false))
      dispatch(setHeaderTitle(data?.name || ''))
      dispatch(setHeaderClientName(data?.client?.name || ''))
      dispatch(setHeaderDeadlineTime(data?.dueDate))
      dispatch(
        setHeaderAssignees(
          data?.members
            .filter(({ role }) => role !== ProjectRole.None)
            .map(({ user, role }) => ({
              name: user.name!,
              imageUrl: user.avatarUrl!,
              isOwner: role === ProjectRole.Owner!,
            }))
            .sort((firstItem, secondItem) =>
              sortByBoolean(firstItem.isOwner, secondItem.isOwner)
            ) || []
        )
      )
      dispatch(
        setHeaderActionButtons([
          {
            iconType: ActionButtonIconType.ADD,
          },
          {
            iconType: ActionButtonIconType.SETTINGS,
          },
        ])
      )
      dispatch(showHeader())
    }

    return () => {
      dispatch(setHeaderClientName(''))
      dispatch(setHeaderDeadlineTime(''))
      dispatch(setHeaderAssignees([]))
      dispatch(setHeaderActionButtons([]))
    }
  }, [data, loading, routes])

  useEffect(() => {
    const storedItems = getHeaderTabs('project', id || '')

    if (storedItems.length) {
      dispatch(setupHeaderTabs(storedItems))
    } else if (routes) {
      dispatch(setupHeaderTabs(routes.tabs))
    }
  }, [id])
}
