import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
const defaultOptions = {} as const
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any
}

export type Activity = {
  answers: Array<AnswerMainType>
  /** The approvals, both requested and given */
  approvals: Array<Approval>
  /** When the activity was archived. Null means it's not archived */
  archivedAt?: Maybe<Scalars['DateTime']>
  /** The assets attached to the activity */
  assets: Array<Asset>
  author: Scalars['String']
  composedDocument?: Maybe<File>
  createdAt?: Maybe<Scalars['DateTime']>
  deliverables?: Maybe<Array<DeliverableMainType>>
  /** The feedback received for this activity */
  feedback: Feedback
  /** The ID of the activity */
  id: Scalars['ID']
  /** The milestone the activity belongs to */
  milestone?: Maybe<Milestone>
  /** The name of the activity */
  name: Scalars['String']
  /** The position (relative to other activities) the activity should be displayed in */
  position: Scalars['Int']
  /** The project the activity belongs to */
  project: Project
  /** The status of the activity */
  status?: Maybe<ActivityStatus>
  /** The tasks attached to the activity */
  tasks: Array<Task>
  type: ActivityType
  /** When the activity was last updated */
  updatedAt: Scalars['DateTime']
  uploadedDocument?: Maybe<File>
  /** When the current user last viewed this activity. Null means the user has never viewed this activity */
  viewedAt?: Maybe<Scalars['DateTime']>
}

export enum ActivityStatus {
  Doing = 'DOING',
  Done = 'DONE',
  NewActivites = 'NEW_ACTIVITES',
  Todo = 'TODO',
}

export enum ActivityType {
  Artwork = 'ARTWORK',
  Brief = 'BRIEF',
  Contract = 'CONTRACT',
  Custom = 'CUSTOM',
  Default = 'DEFAULT',
  Proposal = 'PROPOSAL',
  Quote = 'QUOTE',
  Workshop = 'WORKSHOP',
}

export type ActivityTypesResponse = {
  __typename?: 'ActivityTypesResponse'
  /** Description of Activity type  */
  description: Scalars['String']
  id: Scalars['String']
  /** Name of Activity type  */
  name: Scalars['String']
}

export type ActivityUpdateDocumentsResponse = {
  __typename?: 'ActivityUpdateDocumentsResponse'
  /** The activity that was directly acted upon */
  activity: Activity
  /** Pre sign url for getting file */
  getUrl: Scalars['String']
  /** Pre sign url for file upload */
  url: Scalars['String']
}

export type ActivityViewedInput = {
  /** The ID of the activity that was viewed */
  id: Scalars['ID']
}

export type ActivityViewedResponse = {
  __typename?: 'ActivityViewedResponse'
  /** The activity that was viewed */
  activity: Activity
}

export type AddAssetToArtworkInput = {
  /** The id of the artwork */
  artworkId: Scalars['ID']
  /** Provide filename which will be signed with uuid */
  name?: InputMaybe<Scalars['String']>
  /** Path to folder in a storage, such as avatar or docs */
  path: Scalars['String']
  /** File type such as png, jpeg or pdf */
  type: FileTypes
  /** The number of the asset verion */
  version: Scalars['Int']
}

export type AddAssetToArtworkResponse = {
  __typename?: 'AddAssetToArtworkResponse'
  file: File
  id: Scalars['String']
  name: Scalars['String']
  path: Scalars['String']
  /** The url for uploading file to cloud service */
  putUrl: Scalars['String']
  url: Scalars['String']
}

export type AddFeedbackInput = {
  /** The ID of the activity the feedback relates to */
  activityId?: InputMaybe<Scalars['ID']>
  /** The ID of the file the feedback relates to */
  assetId?: InputMaybe<Scalars['ID']>
  /** The content of the message */
  content: Scalars['String']
  /** The type of the feedback message */
  type?: InputMaybe<FeedbackMessageType>
  /** The id of the user who create feedback */
  userId: Scalars['ID']
}

export type AddFeedbackResponse = {
  __typename?: 'AddFeedbackResponse'
  /** The id of the feedback message */
  feedbackMessageId: Scalars['ID']
  /** The message that was added */
  message: Message
}

export type AddImageFeedbackInput = {
  /** The ID of the activity the feedback relates to */
  activityId?: InputMaybe<Scalars['ID']>
  /** The ID of the file the feedback relates to */
  assetId?: InputMaybe<Scalars['ID']>
  /** The content of the message */
  content: Scalars['String']
  /** The number of the page a feedback message */
  page?: InputMaybe<Scalars['Float']>
  /** The user id who add a feedback message */
  userId: Scalars['ID']
  /** The x coordinate of the feedback */
  x: Scalars['Float']
  /** The y coordinate of the feedback */
  y: Scalars['Float']
}

export type AddMemberToOrganizationResponse = {
  __typename?: 'AddMemberToOrganizationResponse'
  /** The member that was added to the organization */
  member: OrganizationMember
}

export type AddOrganazationMember = {
  /** The organization id */
  organizationId: Scalars['String']
  /** The role of the user to invite to the organization */
  role: OrganizationRole
  /** The user id to add to orzanization */
  userId: Scalars['String']
}

export type AddReplyFeedbackMessageInput = {
  /** The ID of the activity the feedback relates to */
  activityId?: InputMaybe<Scalars['ID']>
  /** The ID of the file the feedback relates to */
  assetId?: InputMaybe<Scalars['ID']>
  /** The content of the message */
  content: Scalars['String']
  /** The id of the parent feedback message */
  parentFeedbackMessageId: Scalars['ID']
  /** The id of the user that replied child comment */
  replyedUserId?: InputMaybe<Scalars['ID']>
  /** The type of the feedback message */
  type?: InputMaybe<FeedbackMessageType>
  /** The id of the user who create feedback message */
  userId: Scalars['ID']
}

export type AddReplyFeedbackMessageResponse = {
  __typename?: 'AddReplyFeedbackMessageResponse'
  /** The id of the parent feedback message */
  childFeedbackMessageId: Scalars['ID']
  /** The message that was added */
  message: Message
  /** The id of the user that replied child comment */
  replyedUserId?: Maybe<Scalars['ID']>
}

export type AdditionalNotificationDataType = {
  activityId?: InputMaybe<Scalars['ID']>
  projectId?: InputMaybe<Scalars['ID']>
  senderId?: InputMaybe<Scalars['ID']>
  targetUsersIds?: InputMaybe<Array<Scalars['ID']>>
}

export type AnswerCommentType = {
  __typename?: 'AnswerCommentType'
  answerId?: Maybe<Scalars['ID']>
  content: Scalars['String']
  id: Scalars['ID']
  type: QuestionTypeId
}

export type AnswerDeliverableType = {
  __typename?: 'AnswerDeliverableType'
  data: Array<AnswerWithQuestion>
  id: Scalars['ID']
  name: Scalars['String']
}

export type AnswerInput = {
  responses: Array<AnswerResponseInput>
}

export type AnswerMainType = {
  __typename?: 'AnswerMainType'
  brief: Activity
  comment: AnswerCommentType
  id: Scalars['ID']
  question: QuestionMain
  questionId: Scalars['ID']
  userId?: Maybe<Scalars['ID']>
}

export type AnswerResponseInput = {
  data?: InputMaybe<Scalars['String']>
  date?: InputMaybe<Scalars['DateTime']>
  number?: InputMaybe<Scalars['Float']>
  questionId: Scalars['String']
  text?: InputMaybe<Scalars['String']>
  type: QuestionTypeId
}

export type AnswerTypesResponse = {
  __typename?: 'AnswerTypesResponse'
  brief: Activity
}

export type AnswerWithQuestion = {
  __typename?: 'AnswerWithQuestion'
  comment: AnswerCommentType
  question: QuestionMain
}

export type AnswersResponse = {
  __typename?: 'AnswersResponse'
  answer: AnswerMainType
  question: QuestionMain
}

export type AnswersResponses = {
  __typename?: 'AnswersResponses'
  mappedAnswer: Array<AnswersResponse>
  mappedDeliverables: Array<AnswerDeliverableType>
}

export type Approval = {
  __typename?: 'Approval'
  activityId: Scalars['String']
  /** The ID of the approval */
  id: Scalars['ID']
  /** The status of the approval */
  status?: Maybe<ApprovalStatus>
  /** The user that has been given or requested approval */
  user: User
  userId: Scalars['String']
}

export type ApprovalInput = {
  /** Activity or Brief id */
  id: Scalars['String']
  user: Scalars['String']
  userIds: Array<Scalars['String']>
}

export type ApprovalResolverInput = {
  /** Activity or Brief id */
  id: Scalars['String']
  userIds: Array<Scalars['String']>
}

export type ApprovalResponse = {
  __typename?: 'ApprovalResponse'
  approve: Array<Approval>
  isApproved: Scalars['Boolean']
}

export enum ApprovalStatus {
  Approved = 'APPROVED',
  Requested = 'REQUESTED',
}

export type ArchiveProjectInput = {
  /** The ID of the project to be archived */
  projectId: Scalars['String']
}

export type ArchiveProjectResponse = {
  __typename?: 'ArchiveProjectResponse'
  isArchived: Scalars['Boolean']
}

export type Artwork = Activity & {
  __typename?: 'Artwork'
  answers: Array<AnswerMainType>
  /** The approvals, both requested and given */
  approvals: Array<Approval>
  /** When the activity was archived. Null means it's not archived */
  archivedAt?: Maybe<Scalars['DateTime']>
  /** The artwork's asset */
  asset?: Maybe<Asset>
  /** The assets attached to the activity */
  assets: Array<Asset>
  author: Scalars['String']
  composedDocument?: Maybe<File>
  createdAt?: Maybe<Scalars['DateTime']>
  deliverables?: Maybe<Array<DeliverableMainType>>
  /** The feedback received for this activity */
  feedback: Feedback
  /** The ID of the activity */
  id: Scalars['ID']
  /** The milestone the activity belongs to */
  milestone?: Maybe<Milestone>
  /** The name of the activity */
  name: Scalars['String']
  /** The position (relative to other activities) the activity should be displayed in */
  position: Scalars['Int']
  /** The project the activity belongs to */
  project: Project
  /** The status of the activity */
  status?: Maybe<ActivityStatus>
  /** The tasks belonging to the activity */
  tasks: Array<Task>
  type: ActivityType
  /** When the activity was last updated */
  updatedAt: Scalars['DateTime']
  uploadedDocument?: Maybe<File>
  /** When the current user last viewed this activity. Null means the user has never viewed this activity */
  viewedAt?: Maybe<Scalars['DateTime']>
}

export type Asset = {
  /** The ID of the asset */
  id: Scalars['ID']
  /** The name of the asset */
  name: Scalars['String']
  /** The type of the asset, eg image/png */
  type: Scalars['String']
}

export type AssetByFileIdResponse = {
  __typename?: 'AssetByFileIdResponse'
  /** The asset entity data */
  asset?: Maybe<GlobalAsset>
}

/** An attached asset */
export type AssetResponse = QuestionResponse & {
  __typename?: 'AssetResponse'
  id: Scalars['ID']
}

export enum AssetTypes {
  ActivityAsset = 'activityAsset',
  BriefAsset = 'briefAsset',
  GlobalAsset = 'globalAsset',
  OrganizationAsset = 'organizationAsset',
}

export type AssetUploadInput = {
  /** Provide filename which will be signed with uuid */
  name?: InputMaybe<Scalars['String']>
  /** Project type such as activity or brief */
  projectType: AssetTypes
}

export type AssetUploadResponse = {
  __typename?: 'AssetUploadResponse'
  name: Scalars['String']
  path: Scalars['String']
  url: Scalars['String']
}

export type AttachAssetToActivityInput = {
  /** The activity to attach the file to */
  activityId: Scalars['ID']
  /** The ID of the asset to attach */
  assetId: Scalars['ID']
}

export type AttachAssetToActivityResponse = {
  __typename?: 'AttachAssetToActivityResponse'
  /** The activity the asset was attached to */
  activity: Activity
}

export type AttachAssetToProjectInput = {
  /** The ID of the asset to attach */
  assetId: Scalars['ID']
  /** The project to attach the file to */
  projectId: Scalars['ID']
}

export type AttachAssetToProjectResponse = {
  __typename?: 'AttachAssetToProjectResponse'
  /** The project the file was attached to */
  project: Project
}

export type AttachFileToActivityInput = {
  /** The activity to attach the file to */
  activityId: Scalars['ID']
  /** The ID of the file to attach */
  fileId: Scalars['ID']
}

export type AttachFileToProjectInput = {
  /** The ID of the file to attach */
  fileId: Scalars['ID']
  /** The project to attach the file to */
  projectId: Scalars['ID']
}

export type Brief = Activity & {
  __typename?: 'Brief'
  answers: Array<AnswerMainType>
  /** The approvals, both requested and given */
  approvals: Array<Approval>
  /** When the activity was archived. Null means it's not archived */
  archivedAt?: Maybe<Scalars['DateTime']>
  /** The assets attached to the activity */
  assets: Array<Asset>
  author: Scalars['String']
  composedDocument?: Maybe<File>
  createdAt?: Maybe<Scalars['DateTime']>
  deliverables?: Maybe<Array<DeliverableMainType>>
  /** The feedback received for this activity */
  feedback: Feedback
  /** The brief's form */
  form?: Maybe<Form>
  /** The ID of the activity */
  id: Scalars['ID']
  /** The milestone the activity belongs to */
  milestone?: Maybe<Milestone>
  /** The name of the activity */
  name: Scalars['String']
  /** The position (relative to other activities) the activity should be displayed in */
  position: Scalars['Int']
  /** The project the activity belongs to */
  project: Project
  /** The status of the activity */
  status?: Maybe<ActivityStatus>
  /** The tasks belonging to the activity */
  tasks: Array<Task>
  type: ActivityType
  /** When the activity was last updated */
  updatedAt: Scalars['DateTime']
  uploadedDocument?: Maybe<File>
  /** When the current user last viewed this activity. Null means the user has never viewed this activity */
  viewedAt?: Maybe<Scalars['DateTime']>
}

export type BriefUpdateUploadedDocumentResponse = {
  __typename?: 'BriefUpdateUploadedDocumentResponse'
  /** The brief that was directly acted upon */
  brief: Activity
  /** Pre sign url for getting file */
  getUrl: Scalars['String']
  /** Pre sign url for file upload */
  url: Scalars['String']
}

export type ChangeOrganizationMemberRoleInput = {
  /** The ID of the organization to change member role */
  organizationId: Scalars['ID']
  /** The role of member in the organization */
  role: OrganizationRole
  /** The ID of the user to change role */
  userId: Scalars['ID']
}

export type CheckboxQuestionPart = QuestionPart & {
  __typename?: 'CheckboxQuestionPart'
  id: Scalars['ID']
  /** The label of the checkbox */
  label: Scalars['String']
  /** The relative order of the part in the question */
  position: Scalars['Int']
  /** Controls when the part should be visible. No entries means the part is always visible */
  visibleWhen: Array<QuestionPartCondition>
}

export type ChecklistItem = {
  __typename?: 'ChecklistItem'
  /** When the item was completed (ISO 8601). Null means the item hasn't been completed yet */
  completedAt?: Maybe<Scalars['DateTime']>
  /** The ID of the task */
  id: Scalars['ID']
  /** The 'title' of the item */
  name: Scalars['String']
}

export type Client = {
  __typename?: 'Client'
  /** The ID of the client */
  id: Scalars['ID']
  /** The name of the client */
  name: Scalars['String']
}

/** The common fields for creating an activity */
export type CreateActivityInput = {
  deliverables?: InputMaybe<Array<DeliverableMainInput>>
  /** The milestone to place the activity under */
  milestoneId?: InputMaybe<Scalars['ID']>
  /** The name of the activity */
  name: Scalars['String']
  /** The project the activity will belong to */
  projectId: Scalars['ID']
  status?: InputMaybe<ActivityStatus>
}

export type CreateApprovalNotificationInput = {
  activityId: Scalars['ID']
  data: AdditionalNotificationDataType
  personalizedMessage?: InputMaybe<Scalars['String']>
  purpose?: InputMaybe<Scalars['String']>
  type: NotificationApprovalTypes
  userIds: Array<Scalars['ID']>
}

export type CreateArtworkInput = {
  /** The ID of the artwork's asset */
  assetId?: InputMaybe<Scalars['ID']>
  deliverables?: InputMaybe<Array<DeliverableMainInput>>
  /** The milestone to place the activity under */
  milestoneId?: InputMaybe<Scalars['ID']>
  /** The name of the activity */
  name: Scalars['String']
  /** The project the activity will belong to */
  projectId: Scalars['ID']
  status?: InputMaybe<ActivityStatus>
}

export type CreateArtworkResponse = {
  __typename?: 'CreateArtworkResponse'
  /** The artwork that was created */
  activity: Artwork
}

export type CreateBriefInput = {
  authorId: Scalars['String']
  deliverables?: InputMaybe<Array<DeliverableMainInput>>
  /** The ID of the form for this brief */
  formId?: InputMaybe<Scalars['ID']>
  /** The milestone to place the activity under */
  milestoneId?: InputMaybe<Scalars['ID']>
  /** The name of the activity */
  name: Scalars['String']
  /** The project the activity will belong to */
  projectId: Scalars['ID']
  status?: InputMaybe<ActivityStatus>
}

export type CreateBriefResolverInput = {
  deliverables?: InputMaybe<Array<DeliverableMainInput>>
  /** The ID of the form for this brief */
  formId?: InputMaybe<Scalars['ID']>
  /** The milestone to place the activity under */
  milestoneId?: InputMaybe<Scalars['ID']>
  /** The name of the activity */
  name: Scalars['String']
  /** The project the activity will belong to */
  projectId: Scalars['ID']
  status?: InputMaybe<ActivityStatus>
}

export type CreateBriefResponse = {
  __typename?: 'CreateBriefResponse'
  /** The brief that was created */
  activity: Brief
}

export type CreateChecklistItemInput = {
  /** Whether the item is complete or not */
  complete?: InputMaybe<Scalars['Boolean']>
  /** The 'title' of the item */
  name: Scalars['String']
}

export type CreateDefaultActivityInput = {
  milestoneId?: InputMaybe<Scalars['ID']>
  name: Scalars['String']
  projectId: Scalars['ID']
}

export type CreateDefaultActivityResponse = {
  __typename?: 'CreateDefaultActivityResponse'
  /** The default activity that was created */
  activity: DefaultActivity
}

export type CreateDocumentInput = {
  /** The content of the document */
  content: Scalars['String']
  /** The name of the document */
  name: Scalars['String']
}

export type CreateDocumentResponse = {
  __typename?: 'CreateDocumentResponse'
  /** The document that was created */
  document: Document
}

export type CreateFormAssetInput = {
  /** The name of the asset */
  name: Scalars['String']
  /** The ID of the form template */
  templateId: Scalars['ID']
}

export type CreateFormAssetResponse = {
  __typename?: 'CreateFormAssetResponse'
  /** The form asset that was created */
  form: Form
}

export type CreateMilestoneInput = {
  /** The name of the milestone */
  name: Scalars['String']
  /** The ID of the project */
  projectId: Scalars['ID']
}

export type CreateMilestoneResponse = {
  __typename?: 'CreateMilestoneResponse'
  /** The milestone that was created */
  milestone: Milestone
}

export type CreateNotificationInput = {
  data: AdditionalNotificationDataType
  personalizedMessage?: InputMaybe<Scalars['String']>
  purpose?: InputMaybe<Scalars['String']>
  userIds: Array<Scalars['ID']>
}

export type CreateOrganizationInput = {
  name: Scalars['String']
}

export type CreateOrganizationResponse = {
  __typename?: 'CreateOrganizationResponse'
  /** The organisation that was created */
  organization: Organization
}

export type CreateProjectInput = {
  /** The name of the client the project is for */
  clientName?: InputMaybe<Scalars['String']>
  /** The color of the project is random color from PROJECT_COLORS */
  color?: InputMaybe<Scalars['String']>
  /** When the project is due */
  dueDate?: InputMaybe<Scalars['DateTime']>
  /** The users that should be assigned to the project */
  members: Array<UpdateProjectMemberInput>
  name: Scalars['String']
  organizationId: Scalars['ID']
}

export type CreateProjectResponse = {
  __typename?: 'CreateProjectResponse'
  /** The project that was created */
  project: Project
}

export type CreateTaskInput = {
  /** The activity the task belongs to. */
  activityId?: InputMaybe<Scalars['ID']>
  /** The items in the checklist */
  checklist?: InputMaybe<Array<CreateChecklistItemInput>>
  /** Whether the task has been completed. Defaults to false */
  completed?: InputMaybe<Scalars['Boolean']>
  /** Further details of the task. For now this is plain text. Defaults to an empty string */
  description?: InputMaybe<Scalars['String']>
  /** The tasks due date, represented in ISO 8601 format. Null means the task currently doesn't have a due date */
  dueDate?: InputMaybe<Scalars['DateTime']>
  /** The users that should be assigned to the task */
  members: Array<UpdateTaskMemberInput>
  /** The 'title' of the task, displayed in the tasks's summary view */
  name: Scalars['String']
  /** Organization that the task belongs to. */
  organizationId?: InputMaybe<Scalars['ID']>
  /** The ID of the project the task belongs to */
  projectId?: InputMaybe<Scalars['ID']>
  /** The initial status of the task. Defaults to TODO */
  status?: InputMaybe<TaskStatus>
  /** User that the task belongs to. */
  userId?: InputMaybe<Scalars['ID']>
}

export type CreateTaskResponse = {
  __typename?: 'CreateTaskResponse'
  /** The task that was created */
  task: Task
}

export type CreateWorkshopInput = {
  milestoneId?: InputMaybe<Scalars['ID']>
  name: Scalars['String']
  projectId: Scalars['ID']
}

export type CreateWorkshopResponse = {
  __typename?: 'CreateWorkshopResponse'
  /** The workshop that was created */
  activity: Workshop
}

export type DateQuestionPart = QuestionPart & {
  __typename?: 'DateQuestionPart'
  /** If present, the label to display to the allow the user to add an additional entry. If absent, the user cannot add multiple values */
  addMoreLabel?: Maybe<Scalars['String']>
  id: Scalars['ID']
  /** The relative order of the part in the question */
  position: Scalars['Int']
  /** Controls when the part should be visible. No entries means the part is always visible */
  visibleWhen: Array<QuestionPartCondition>
}

export type DateType = {
  __typename?: 'DateType'
  createdAt?: Maybe<Scalars['DateTime']>
  updatedAt: Scalars['DateTime']
}

export type DefaultActivity = Activity & {
  __typename?: 'DefaultActivity'
  answers: Array<AnswerMainType>
  /** The approvals, both requested and given */
  approvals: Array<Approval>
  /** When the activity was archived. Null means it's not archived */
  archivedAt?: Maybe<Scalars['DateTime']>
  /** The assets attached to the activity */
  assets: Array<Asset>
  author: Scalars['String']
  composedDocument?: Maybe<File>
  createdAt?: Maybe<Scalars['DateTime']>
  deliverables?: Maybe<Array<DeliverableMainType>>
  /** The feedback received for this activity */
  feedback: Feedback
  /** The ID of the activity */
  id: Scalars['ID']
  /** The milestone the activity belongs to */
  milestone?: Maybe<Milestone>
  /** The name of the activity */
  name: Scalars['String']
  /** The position (relative to other activities) the activity should be displayed in */
  position: Scalars['Int']
  /** The project the activity belongs to */
  project: Project
  /** The status of the activity */
  status?: Maybe<ActivityStatus>
  /** The tasks belonging to the activity */
  tasks: Array<Task>
  type: ActivityType
  /** When the activity was last updated */
  updatedAt: Scalars['DateTime']
  uploadedDocument?: Maybe<File>
  /** When the current user last viewed this activity. Null means the user has never viewed this activity */
  viewedAt?: Maybe<Scalars['DateTime']>
}

export type DefaultFileInput = {
  /** Provide filename which will be signed with uuid */
  name?: InputMaybe<Scalars['String']>
}

export type DeleteArtworkAssetInput = {
  /** The id of the file to remove */
  fileId: Scalars['ID']
}

export type DeleteChecklistItemInput = {
  /** The ID of the checklist item to delete */
  id: Scalars['ID']
}

export type DeleteChecklistItemResponse = {
  __typename?: 'DeleteChecklistItemResponse'
  /** Always true */
  success: Scalars['Boolean']
}

export type DeleteResponse = {
  __typename?: 'DeleteResponse'
  isDeleted: Scalars['Boolean']
}

export type DeliverableCategory = {
  __typename?: 'DeliverableCategory'
  items: Array<DeliverableType>
  name: Scalars['String']
  order: Scalars['Float']
}

export type DeliverableMainInput = {
  id: Scalars['ID']
  name: Scalars['String']
  relationId: Scalars['String']
}

export type DeliverableMainType = {
  __typename?: 'DeliverableMainType'
  id: Scalars['ID']
  name: Scalars['String']
  relationId: Scalars['String']
}

export type DeliverableType = {
  __typename?: 'DeliverableType'
  category: DeliverableCategory
  id: Scalars['String']
  name: Scalars['String']
  order: Scalars['Float']
  template: FormTemplate
}

export type Document = Asset & {
  __typename?: 'Document'
  /** The content of the document */
  content: Scalars['String']
  /** The ID of the asset */
  id: Scalars['ID']
  /** The name of the asset */
  name: Scalars['String']
  /** The type of the asset, eg image/png */
  type: Scalars['String']
}

export type DocumentInput = {
  /** Activity or Brief id */
  id: Scalars['ID']
  /** File name, if not provided it should be brief */
  name?: InputMaybe<Scalars['String']>
}

export type DuplicateProjectInput = {
  projectId: Scalars['ID']
}

export type DuplicateProjectResponse = {
  __typename?: 'DuplicateProjectResponse'
  /** The duplicate of the project */
  project: Project
}

export type Feedback = {
  __typename?: 'Feedback'
  activityId?: Maybe<Scalars['ID']>
  assetId?: Maybe<Scalars['ID']>
  id?: Maybe<Scalars['ID']>
  /** The messages that have been sent */
  messages: Array<Message>
  requests: Array<FeedbackRequest>
}

export type FeedbackMemberInput = {
  /** The id of the feedback message */
  feedbackMessageId: Scalars['ID']
  /** The id of the user */
  userId: Scalars['ID']
}

export type FeedbackMessage = {
  __typename?: 'FeedbackMessage'
  assetId?: Maybe<Scalars['ID']>
  /** The date when feedback message completed */
  completedAt?: Maybe<Scalars['DateTime']>
  content?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['DateTime']>
  feedbackId?: Maybe<Scalars['ID']>
  /** The id of the  */
  feedbackMessageId?: Maybe<Scalars['ID']>
  /** The array of the user who assigned to this message */
  members?: Maybe<Array<User>>
  messageId?: Maybe<Scalars['ID']>
  /** It show that this feedback message is pinned or not */
  pinned?: Maybe<Scalars['Boolean']>
  position?: Maybe<FeedbackPosition>
  /** The user who replied message in the thread */
  repliedUser?: Maybe<User>
  /** The replyed comments */
  replyedComments: Array<FeedbackMessage>
  /** The type of the feedback message */
  type?: Maybe<FeedbackMessageType>
  user?: Maybe<User>
  userId?: Maybe<Scalars['ID']>
}

export type FeedbackMessageInput = {
  /** The ID of the activity item to search */
  activityId?: InputMaybe<Scalars['ID']>
  /** The optional field of the asset to search */
  assetId?: InputMaybe<Scalars['ID']>
}

export type FeedbackMessageMemberResponse = {
  __typename?: 'FeedbackMessageMemberResponse'
  /** The id of the feedback message */
  feedbackMessageId: Scalars['ID']
  /** The id of the user */
  userId: Scalars['ID']
}

export type FeedbackMessageResponse = {
  __typename?: 'FeedbackMessageResponse'
  /** The array of feedback messages */
  messages: Array<FeedbackMessage>
  /** Pagination metadata */
  paginationData: Pagination
}

/** Type of the feedback message */
export enum FeedbackMessageType {
  Child = 'CHILD',
  Parent = 'PARENT',
}

export type FeedbackPosition = {
  __typename?: 'FeedbackPosition'
  /** The number of the page */
  page: Scalars['Float']
  /** The coordinate of x axis */
  x: Scalars['Float']
  /** The coordinate of y axis */
  y: Scalars['Float']
}

export type FeedbackRequest = {
  __typename?: 'FeedbackRequest'
  /** The ID of the feedback request */
  id: Scalars['ID']
  /** The user the request is for */
  user: User
}

export type File = Asset & {
  __typename?: 'File'
  getUrl: Scalars['String']
  /** The ID of the asset */
  id: Scalars['ID']
  /** The name of the asset */
  name: Scalars['String']
  path: Scalars['String']
  /** The type of the asset, eg image/png */
  type: Scalars['String']
  version?: Maybe<Scalars['Float']>
}

export type FileComposeInput = {
  /** Provide filename which will be signed with uuid */
  name?: InputMaybe<Scalars['String']>
  /** Project type such as activity or brief */
  projectType: Scalars['String']
}

export type FileData = {
  /** The id of the file to change version */
  fileId: Scalars['ID']
  /** The number of the version to change */
  version: Scalars['Int']
}

export type FileInput = {
  id: Scalars['String']
}

/** Allows attachment of files to a question */
export type FileQuestionPart = QuestionPart & {
  __typename?: 'FileQuestionPart'
  /** The text that should appear in the drop area */
  dropAreaLabel: Scalars['String']
  id: Scalars['ID']
  /** The relative order of the part in the question */
  position: Scalars['Int']
  /** Controls when the part should be visible. No entries means the part is always visible */
  visibleWhen: Array<QuestionPartCondition>
}

export enum FileTypes {
  Ai = 'ai',
  Doc = 'doc',
  Docx = 'docx',
  Gif = 'gif',
  Html = 'html',
  Img = 'img',
  Indd = 'indd',
  Jpeg = 'jpeg',
  Pdf = 'pdf',
  Png = 'png',
  Ppt = 'ppt',
  Pptx = 'pptx',
  Psd = 'psd',
  Svg = 'svg',
  Xls = 'xls',
  Xlsx = 'xlsx',
}

export type FileUploadInput = {
  /** Provide filename which will be signed with uuid */
  name?: InputMaybe<Scalars['String']>
  /** Path to folder in a storage, such as avatar or docs */
  path: Scalars['String']
  /** File type such as png, jpeg or pdf */
  type: FileTypes
}

export type FileUploadResponse = {
  __typename?: 'FileUploadResponse'
  /** @deprecated duplicated by file.id */
  createdId: Scalars['String']
  file: File
  getUrl: Scalars['String']
  url: Scalars['String']
}

export type ForgotTokenResponse = {
  __typename?: 'ForgotTokenResponse'
  /** Cognito code to forgot */
  code: Scalars['String']
}

export type Form = Asset & {
  __typename?: 'Form'
  /** The ID of the asset */
  id: Scalars['ID']
  /** The name of the asset */
  name: Scalars['String']
  responses: Array<QuestionResponse>
  template: FormTemplate
  type: Scalars['String']
}

export type FormPage = {
  __typename?: 'FormPage'
  id: Scalars['ID']
  /** The name of the page */
  name: Scalars['String']
  /** The position of the page relative to other pages in the form */
  position: Scalars['Int']
  sections: Array<FormSection>
  /** Controls when the page should be visible. No entries means the page is always visible */
  visibleWhen: Array<QuestionPartCondition>
}

export type FormSection = {
  __typename?: 'FormSection'
  id: Scalars['ID']
  /** The position of the section relative to the other sections on the page */
  position: Scalars['Int']
  questions: Array<Question>
  /** Controls when the section should be visible. No entries means the section is always visible */
  visibleWhen: Array<QuestionPartCondition>
}

export type FormTemplate = {
  __typename?: 'FormTemplate'
  id: Scalars['ID']
  /** The name of the template */
  name: Scalars['String']
  pages: Array<FormPage>
}

export type GetActivitiesWhere = {
  activityNames?: InputMaybe<Array<Scalars['String']>>
  ids?: InputMaybe<Array<Scalars['ID']>>
  organizations?: InputMaybe<Array<Scalars['ID']>>
  projects?: InputMaybe<Array<Scalars['ID']>>
}

export type GetAssetsWhere = {
  activities?: InputMaybe<Array<Scalars['ID']>>
  activityNames?: InputMaybe<Array<Scalars['String']>>
  clientNames?: InputMaybe<Array<Scalars['String']>>
  ids?: InputMaybe<Array<Scalars['ID']>>
  organizations?: InputMaybe<Array<Scalars['ID']>>
  projectNames?: InputMaybe<Array<Scalars['String']>>
  projects?: InputMaybe<Array<Scalars['ID']>>
}

export type GetBriefFeedbackResonse = {
  __typename?: 'GetBriefFeedbackResonse'
  /** The id of the brief */
  briefId?: Maybe<Scalars['String']>
  /** The id of the brief feedback */
  feedbackId?: Maybe<Scalars['String']>
}

export type GetFeedbackInput = {
  /** The ID of the activity the feedback relates to */
  activityId?: InputMaybe<Scalars['ID']>
  /** The ID of the file the feedback relates to */
  assetId?: InputMaybe<Scalars['ID']>
}

export type GetFeedbackMessageWhere = {
  /** The id of the asset */
  assetId?: InputMaybe<Scalars['ID']>
  /** The id of the feedback entity */
  feedbackId?: InputMaybe<Scalars['ID']>
}

export type GetGlobalAssetsWhere = {
  /** The array of id of the organizations */
  organizations: Array<Scalars['ID']>
  /** The value to search by text */
  searchValue?: InputMaybe<Scalars['String']>
  /** The type of the assets */
  type?: InputMaybe<GlobalAssetType>
}

export type GetNotificationInputArgs = {
  limit?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
  /** The order for sorting data */
  sortOrder?: InputMaybe<SortOrder>
}

export type GetProjectsWhere = {
  clientNames?: InputMaybe<Array<Scalars['String']>>
  ids?: InputMaybe<Array<Scalars['ID']>>
  organizations?: InputMaybe<Array<Scalars['ID']>>
  projectNames?: InputMaybe<Array<Scalars['String']>>
}

export type GetTasksWhere = {
  ids?: InputMaybe<Array<Scalars['ID']>>
  projects?: InputMaybe<Array<Scalars['ID']>>
  users?: InputMaybe<Array<Scalars['ID']>>
}

export type GetUsersWhere = {
  /** Only return users with these IDs */
  ids?: InputMaybe<Array<Scalars['ID']>>
}

export type GlobalAsset = {
  __typename?: 'GlobalAsset'
  /** The activity entity data */
  activity?: Maybe<Activity>
  /** The if of the activity */
  activityId?: Maybe<Scalars['ID']>
  /** The date when asset was created */
  createdAt?: Maybe<Scalars['DateTime']>
  /** The file entity data */
  file?: Maybe<File>
  /** The id of the asset file */
  fileId?: Maybe<Scalars['ID']>
  id: Scalars['ID']
  /** The organization entity data */
  organization?: Maybe<Organization>
  /** The id of the organization */
  organizationId?: Maybe<Scalars['ID']>
  /** The project entity data */
  project?: Maybe<Project>
  /** The of the project. */
  projectId?: Maybe<Scalars['ID']>
  /** The number of times the asset was updated */
  rowVersion?: Maybe<Scalars['Float']>
  /** The date when asset was updated */
  updatedAt?: Maybe<Scalars['DateTime']>
}

/** The type of the assets. */
export enum GlobalAssetType {
  Activity = 'ACTIVITY',
  Brief = 'BRIEF',
  Global = 'GLOBAL',
  Organization = 'ORGANIZATION',
  Project = 'PROJECT',
}

export type GlobalAssetsResponse = {
  __typename?: 'GlobalAssetsResponse'
  /** The array ot the global assset items */
  assets: Array<GlobalAsset>
  /** Pagination metadata */
  paginationData: Pagination
}

export type ImageReference = Reference & {
  __typename?: 'ImageReference'
  /** The ID of the reference */
  id: Scalars['ID']
  x: Scalars['Int']
  y: Scalars['Int']
}

export type InviteMemberToOrganizationInput = {
  /** The email address of the user to invite */
  email: Scalars['String']
  /** The ID of the organisation to invide the user to */
  organizationId: Scalars['ID']
  /** The role of the user to invite */
  role: OrganizationRole
}

export type InviteMemberVerifyResponse = {
  __typename?: 'InviteMemberVerifyResponse'
  /** Token payload as JSON string */
  payload: Scalars['String']
}

export type InviteMemberVerifyTokenInput = {
  /** The token of ivited member to verify */
  token: Scalars['String']
}

export type InviteMembersInput = {
  /** The email addresses of the people to invite */
  emails: Array<Scalars['String']>
  /** The ID of the organisation to invide the people to */
  organizationId: Scalars['ID']
}

export type InviteMembersResponse = {
  __typename?: 'InviteMembersResponse'
  /** Always true */
  success: Scalars['Boolean']
}

export type InviteOrganizationMemberResponse = {
  __typename?: 'InviteOrganizationMemberResponse'
  isInvited: Scalars['Boolean']
}

export type KeywordSelectQuestionPart = QuestionPart & {
  __typename?: 'KeywordSelectQuestionPart'
  id: Scalars['ID']
  /** The options that are available to choose from */
  options: Array<SelectOption>
  /** The relative order of the part in the question */
  position: Scalars['Int']
  /** Controls when the part should be visible. No entries means the part is always visible */
  visibleWhen: Array<QuestionPartCondition>
}

export type Message = {
  __typename?: 'Message'
  /** The content of the message */
  content: Scalars['String']
  /** When the message was sent */
  createdAt: Scalars['DateTime']
  id: Scalars['ID']
}

export type Milestone = {
  __typename?: 'Milestone'
  /** The activities directly attached to the project */
  activities: Array<Activity>
  /** When the milestone was archived */
  archivedAt?: Maybe<Scalars['DateTime']>
  /** The ID of the milestone */
  id: Scalars['ID']
  /** The name of the milestone */
  name: Scalars['String']
  project: Project
}

export type Mutation = {
  __typename?: 'Mutation'
  activityViewed: ActivityViewedResponse
  /** Adds asset to the artwork by id */
  addAssetToArtwork: AddAssetToArtworkResponse
  addFeedback: AddFeedbackResponse
  addImageFeedback: AddFeedbackResponse
  /** The mutation for adding member to the feedback message assignees */
  addMemberToFeedbackMessage: FeedbackMessageMemberResponse
  addMemberToOrganization: AddMemberToOrganizationResponse
  /** The mutation for creating reply feedback message */
  addReplyFeedbackMessage: AddReplyFeedbackMessageResponse
  archiveProject: ArchiveProjectResponse
  changeApproval: Array<Approval>
  changeOrganizatonMemberRole: Scalars['Boolean']
  createAnswers: AnswerTypesResponse
  createArtwork: CreateArtworkResponse
  createBrief: CreateBriefResponse
  createDefaultActivity: CreateDefaultActivityResponse
  createFixtures: Scalars['Boolean']
  /** Creates a new form asset */
  createFormAsset: CreateFormAssetResponse
  /** Creates a new milestone */
  createMilestone: CreateMilestoneResponse
  createOrganization: CreateOrganizationResponse
  createProject: CreateProjectResponse
  createTask: CreateTaskResponse
  createWorkshop: CreateWorkshopResponse
  /** Removes asset from artwork by id, and reorder assets verions */
  deleteArtworkAsset: DeleteResponse
  deleteAsset: DeleteResponse
  deleteBrief: DeleteResponse
  deleteDeliverables: DeleteResponse
  deleteFile: DeleteResponse
  duplicateProject: DuplicateProjectResponse
  getObjectUrl: Scalars['String']
  inviteMember: InviteOrganizationMemberResponse
  makeAllNotificationsRead: Scalars['Boolean']
  reminderNotification: NotificationTypesResponse
  removeChecklistItems: DeleteResponse
  removeComposedDocument: DeleteResponse
  removeFeedbackMessage: RemoveFeedbackMessageResponse
  /** The mutation for removing member from feedback message assignees */
  removeMemberFromFeedbackMessage: DeleteResponse
  removeMemberFromOrganization: DeleteResponse
  removeUploadedDocument: DeleteResponse
  /** Reorder file versions by the file id */
  reorderFileVersions: Scalars['Boolean']
  saveAsset: SaveAssetResponse
  sentNotification: NotificationTypesResponse
  setQuestionPartResponses: SetQuestionPartResponsesResponse
  toggleNotificationStatus: Array<Scalars['Boolean']>
  updateActivity: UpdateActivityResponse
  updateActivityComposeDocument: ActivityUpdateDocumentsResponse
  updateActivityUploadedDocument: ActivityUpdateDocumentsResponse
  updateApproval: Array<Approval>
  updateArtwork: UpdateActivityResponse
  updateBrief: Activity
  updateBriefComposeDocument: BriefUpdateUploadedDocumentResponse
  updateBriefUploadedDocument: BriefUpdateUploadedDocumentResponse
  updateFeedbackMessage: Scalars['Boolean']
  /** Updates the given milestone */
  updateMilestone: UpdateMilestoneResponse
  updateOrganization: UpdateOrganizationResponse
  updateOrganizationMemberRole: UpdateOrganizationMemberResponse
  updateProject: UpdateProjectResponse
  updateTask: UpdateTaskResponse
  updateUser: UpdateUserResponse
  uploadAssets: Array<AssetUploadResponse>
  uploadDocument: Array<AssetUploadResponse>
  uploadPrivateFile: FileUploadResponse
  uploadPublicFile: FileUploadResponse
}

export type MutationActivityViewedArgs = {
  input: ActivityViewedInput
}

export type MutationAddAssetToArtworkArgs = {
  input: AddAssetToArtworkInput
}

export type MutationAddFeedbackArgs = {
  input: AddFeedbackInput
}

export type MutationAddImageFeedbackArgs = {
  input: AddImageFeedbackInput
}

export type MutationAddMemberToFeedbackMessageArgs = {
  input: FeedbackMemberInput
}

export type MutationAddMemberToOrganizationArgs = {
  input: AddOrganazationMember
}

export type MutationAddReplyFeedbackMessageArgs = {
  input: AddReplyFeedbackMessageInput
}

export type MutationArchiveProjectArgs = {
  input: ArchiveProjectInput
}

export type MutationChangeApprovalArgs = {
  input: ApprovalResolverInput
}

export type MutationChangeOrganizatonMemberRoleArgs = {
  input: ChangeOrganizationMemberRoleInput
}

export type MutationCreateAnswersArgs = {
  input: UpdateAnswerInput
}

export type MutationCreateArtworkArgs = {
  input: CreateArtworkInput
}

export type MutationCreateBriefArgs = {
  input: CreateBriefResolverInput
}

export type MutationCreateDefaultActivityArgs = {
  input: CreateDefaultActivityInput
}

export type MutationCreateFormAssetArgs = {
  input: CreateFormAssetInput
}

export type MutationCreateMilestoneArgs = {
  input: CreateMilestoneInput
}

export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput
}

export type MutationCreateProjectArgs = {
  input: CreateProjectInput
}

export type MutationCreateTaskArgs = {
  input: CreateTaskInput
}

export type MutationCreateWorkshopArgs = {
  input: CreateWorkshopInput
}

export type MutationDeleteArtworkAssetArgs = {
  input: DeleteArtworkAssetInput
}

export type MutationDeleteAssetArgs = {
  id: Scalars['String']
}

export type MutationDeleteBriefArgs = {
  id: Scalars['String']
}

export type MutationDeleteDeliverablesArgs = {
  deliverableIds: Array<Scalars['String']>
}

export type MutationDeleteFileArgs = {
  id: Scalars['String']
}

export type MutationDuplicateProjectArgs = {
  input: DuplicateProjectInput
}

export type MutationGetObjectUrlArgs = {
  file: FileInput
}

export type MutationInviteMemberArgs = {
  input: InviteMemberToOrganizationInput
}

export type MutationMakeAllNotificationsReadArgs = {
  userId: Scalars['String']
}

export type MutationReminderNotificationArgs = {
  input: ReminderNotificationInput
}

export type MutationRemoveChecklistItemsArgs = {
  input: RemoveChecklistItemsInput
}

export type MutationRemoveComposedDocumentArgs = {
  input: RemoveComposeDocumentInput
}

export type MutationRemoveFeedbackMessageArgs = {
  input: RemoveFeedbackMessageResolverInput
}

export type MutationRemoveMemberFromFeedbackMessageArgs = {
  input: FeedbackMemberInput
}

export type MutationRemoveMemberFromOrganizationArgs = {
  input: RemoveMemberFromOrganization
}

export type MutationRemoveUploadedDocumentArgs = {
  input: RemoveUploadDocumentInput
}

export type MutationReorderFileVersionsArgs = {
  input: ReorderFileVersionInput
}

export type MutationSaveAssetArgs = {
  input: SaveAssetInput
}

export type MutationSentNotificationArgs = {
  input: SentNotificationInput
}

export type MutationSetQuestionPartResponsesArgs = {
  input: SetQuestionPartResponsesInput
}

export type MutationToggleNotificationStatusArgs = {
  input: ToggleMembersStatusInput
}

export type MutationUpdateActivityArgs = {
  input: UpdateActivityInput
}

export type MutationUpdateActivityComposeDocumentArgs = {
  input: UpdateComposedDocumentInput
}

export type MutationUpdateActivityUploadedDocumentArgs = {
  input: UpdateUploadedDocumentInput
}

export type MutationUpdateApprovalArgs = {
  input: ApprovalResolverInput
}

export type MutationUpdateArtworkArgs = {
  input: UpdateArtworkInput
}

export type MutationUpdateBriefArgs = {
  input: UpdateBriefInput
}

export type MutationUpdateBriefComposeDocumentArgs = {
  input: UpdateComposedDocumentInput
}

export type MutationUpdateBriefUploadedDocumentArgs = {
  input: UpdateUploadedDocumentInput
}

export type MutationUpdateFeedbackMessageArgs = {
  input: UpdateFeedbackMessageResolverInput
}

export type MutationUpdateMilestoneArgs = {
  input: UpdateMilestoneInput
}

export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput
}

export type MutationUpdateOrganizationMemberRoleArgs = {
  input: UpdateOrganizationMemberInput
}

export type MutationUpdateProjectArgs = {
  input: UpdateProjectInput
}

export type MutationUpdateTaskArgs = {
  input: UpdateTaskInput
}

export type MutationUpdateUserArgs = {
  input: UpdateUserInput
}

export type MutationUploadAssetsArgs = {
  input: Array<AssetUploadInput>
}

export type MutationUploadDocumentArgs = {
  input: FileComposeInput
}

export type MutationUploadPrivateFileArgs = {
  input: FileUploadInput
}

export type MutationUploadPublicFileArgs = {
  input: FileUploadInput
}

export type NavigationDataType = {
  __typename?: 'NavigationDataType'
  activityId: Scalars['ID']
  projectId: Scalars['ID']
}

export type NotificationGeneralType = {
  __typename?: 'NotificationGeneralType'
  createdAt?: Maybe<Scalars['DateTime']>
  data?: Maybe<NavigationDataType>
  id: Scalars['ID']
  isTechnical: Scalars['Boolean']
  notificationMembers?: Maybe<Array<NotificationMemberResponse>>
  notificationMessage: Scalars['String']
  personalizedMessage?: Maybe<Scalars['String']>
  purpose?: Maybe<Scalars['String']>
  senderId: Scalars['ID']
  updatedAt: Scalars['DateTime']
}

export type NotificationGetType = {
  activityId: Scalars['ID']
}

export type NotificationManyTypesResponse = {
  __typename?: 'NotificationManyTypesResponse'
  read: NotificationResponseWithPagination
  unread: NotificationResponseWithPagination
}

export type NotificationMemberResponse = {
  __typename?: 'NotificationMemberResponse'
  id: Scalars['ID']
  isRead?: Maybe<Scalars['Boolean']>
  notificationId: Scalars['ID']
  userId: Scalars['ID']
}

export type NotificationResponse = {
  __typename?: 'NotificationResponse'
  activityData: Activity
  isRead: Scalars['Boolean']
  membersData: Array<User>
  notification: NotificationGeneralType
  projectData: Project
  senderData: User
}

export type NotificationResponseWithPagination = {
  __typename?: 'NotificationResponseWithPagination'
  notifications: Array<NotificationResponse>
  pagination?: Maybe<NullablePagination>
}

export type NotificationTypesResponse = {
  __typename?: 'NotificationTypesResponse'
  notification: NotificationGeneralType
}

export type NullablePagination = {
  __typename?: 'NullablePagination'
  /** The current page */
  currentPage?: Maybe<Scalars['Float']>
  /** Number of the items per page */
  perPage?: Maybe<Scalars['Float']>
  /** Total amount of the items */
  totalAmount?: Maybe<Scalars['Float']>
  /** Total amout of the pagination pages */
  totalPages?: Maybe<Scalars['Float']>
}

export type NumberFormat = {
  __typename?: 'NumberFormat'
  /** If set, the currency code of the currency to display in. Eg: AUD */
  currency?: Maybe<Scalars['String']>
  /** The number of decimal places to display. */
  decimalPlaces?: Maybe<Scalars['Int']>
}

export type NumericQuestionPart = QuestionPart & {
  __typename?: 'NumericQuestionPart'
  /** If present, the label to display to the allow the user to add an additional entry. */
  addMoreLabel?: Maybe<Scalars['String']>
  /** If true, the user is allowed to enter multiple values */
  allowMultiple: Scalars['Boolean']
  id: Scalars['ID']
  /** The format to display values in */
  numberFormat: NumberFormat
  /** The relative order of the part in the question */
  position: Scalars['Int']
  /** Controls when the part should be visible. No entries means the part is always visible */
  visibleWhen: Array<QuestionPartCondition>
}

export type Organization = {
  __typename?: 'Organization'
  /** An internal ID for the organisation */
  id: Scalars['ID']
  /** The members that belong to the organisation */
  members: Array<OrganizationMember>
  /** The organisation's displayed name */
  name: Scalars['String']
  projects: Array<Project>
  /** The organisation's range of members */
  range?: Maybe<Scalars['String']>
  /** The organisation's type */
  type?: Maybe<OrganizationType>
}

export type OrganizationProjectsArgs = {
  archivedType?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<GetProjectsWhere>
}

export type OrganizationMember = {
  __typename?: 'OrganizationMember'
  /** The date when user was added to the organization */
  createdAt: Scalars['DateTime']
  /** An internal ID for the OrganizationMember */
  id: Scalars['ID']
  /** The organisation the user belongs to */
  organization: Organization
  /** The user's role in the organisation */
  role: OrganizationRole
  /** The user's details */
  user: User
}

export type OrganizationRangesResponse = {
  __typename?: 'OrganizationRangesResponse'
  /** Organization range started */
  from: Scalars['String']
  id: Scalars['String']
  /** Organization range finished */
  to: Scalars['String']
}

export enum OrganizationRole {
  Admin = 'ADMIN',
  External = 'EXTERNAL',
  Member = 'MEMBER',
  None = 'NONE',
}

/** The type of the organisation. */
export enum OrganizationType {
  DesignAgency = 'DESIGN_AGENCY',
  Enterprise = 'ENTERPRISE',
  Freelancer = 'FREELANCER',
  ScaleUp = 'SCALE_UP',
  Smb = 'SMB',
  Startup = 'STARTUP',
}

export type Pagination = {
  __typename?: 'Pagination'
  /** The current page */
  currentPage: Scalars['Float']
  /** Number of the items per page */
  perPage?: Maybe<Scalars['Float']>
  /** Total amount of the items */
  totalAmount: Scalars['Float']
  /** Total amout of the pagination pages */
  totalPages: Scalars['Float']
}

export type Project = {
  __typename?: 'Project'
  /** The activities directly attached to the project */
  activities: Array<Activity>
  /** When the project was archived */
  archivedAt?: Maybe<Scalars['DateTime']>
  /** The project's client */
  client?: Maybe<Client>
  /** The hex code for the project's colour, eg #ffaa88 */
  color: Scalars['String']
  /** When the project is due */
  dueDate?: Maybe<Scalars['DateTime']>
  /** Whether this project is favourited by the user */
  favorite: Scalars['Boolean']
  /** The ID of the project */
  id: Scalars['ID']
  /** The members of the project */
  members: Array<ProjectMember>
  /** The project's milestones */
  milestones: Array<Milestone>
  /** The name of the project */
  name: Scalars['String']
  /** The organisation the project belongs to */
  organization: Organization
}

export type ProjectMember = {
  __typename?: 'ProjectMember'
  /** An internal ID for the ProjectMember */
  id: Scalars['ID']
  /** The project the user is a member of */
  project: Project
  /** The user's role in the project */
  role: ProjectRole
  /** The user's details */
  user: User
}

/** The role of a user with a project. NONE means the user is not a member */
export enum ProjectRole {
  Member = 'MEMBER',
  None = 'NONE',
  Owner = 'OWNER',
}

export type Query = {
  __typename?: 'Query'
  /** Returns all of the activities visible to the user */
  activities: Array<Activity>
  activityTypes: Array<ActivityTypesResponse>
  answers: AnswersResponses
  approvals: ApprovalResponse
  /** Retrieves the existing client details */
  clients: Array<Client>
  /** The available deliverable categories */
  deliverableCategories: Array<DeliverableCategory>
  deliverableTypes: Array<DeliverableType>
  deliverablesQuestions: Array<QuestionResponseMain>
  getActivityById: Activity
  /** Returns array of assets related to activity by id */
  getAllActivityAssets: Array<GlobalAsset>
  /** Returns all assets by organization ids, relation type and text search value */
  getAllAssets: GlobalAssetsResponse
  getArtworkFeedback: Array<FeedbackMessage>
  /** Returns Asset entity data by file id */
  getAssetByFileId: AssetByFileIdResponse
  getBrief: Activity
  getDeliverables: Array<DeliverableMainType>
  /** The array of the feedback messages fetching by feedback id */
  getFeedbackMessagesByFeedbackId: FeedbackMessageResponse
  getProjectBriefFeedbackId: GetBriefFeedbackResonse
  getProjectMembersById: Array<ProjectMember>
  inviteMemberVerify: InviteMemberVerifyResponse
  /** Check is user exsist by email */
  isUserExist: UserExistResponse
  matchForgotPassword: ForgotTokenResponse
  me?: Maybe<User>
  meWithOrganization?: Maybe<UserWithOrganization>
  notifications: NotificationManyTypesResponse
  organizationRanges: Array<OrganizationRangesResponse>
  organizations: Array<Organization>
  /** Returns the archived or unarchived projects the user has access to */
  projects: Array<Project>
  /** Returns the tasks the user has access to */
  tasks: Array<Task>
  /** Returns the matching users */
  users: Array<User>
}

export type QueryActivitiesArgs = {
  limit?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<GetActivitiesWhere>
}

export type QueryAnswersArgs = {
  briefId: Scalars['String']
}

export type QueryApprovalsArgs = {
  id: Scalars['String']
}

export type QueryDeliverablesQuestionsArgs = {
  input: QuestionsFilter
}

export type QueryGetActivityByIdArgs = {
  activityId: Scalars['String']
}

export type QueryGetAllActivityAssetsArgs = {
  activityId: Scalars['String']
}

export type QueryGetAllAssetsArgs = {
  limit?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Scalars['Int']>
  sortOrder?: InputMaybe<SortOrder>
  where: GetGlobalAssetsWhere
}

export type QueryGetArtworkFeedbackArgs = {
  input: FeedbackMessageInput
}

export type QueryGetAssetByFileIdArgs = {
  fileId: Scalars['String']
}

export type QueryGetBriefArgs = {
  projectId?: InputMaybe<Scalars['String']>
}

export type QueryGetDeliverablesArgs = {
  briefId: Scalars['String']
}

export type QueryGetFeedbackMessagesByFeedbackIdArgs = {
  limit?: InputMaybe<Scalars['Int']>
  page?: InputMaybe<Scalars['Int']>
  sortOrder?: InputMaybe<SortOrder>
  where: GetFeedbackMessageWhere
}

export type QueryGetProjectBriefFeedbackIdArgs = {
  projectId: Scalars['String']
}

export type QueryGetProjectMembersByIdArgs = {
  projectId: Scalars['String']
}

export type QueryInviteMemberVerifyArgs = {
  input: InviteMemberVerifyTokenInput
}

export type QueryIsUserExistArgs = {
  input: UserExistInput
}

export type QueryMatchForgotPasswordArgs = {
  token: Scalars['String']
}

export type QueryNotificationsArgs = {
  readArgs?: InputMaybe<GetNotificationInputArgs>
  unreadArgs?: InputMaybe<GetNotificationInputArgs>
}

export type QueryProjectsArgs = {
  archivedType?: InputMaybe<Scalars['String']>
  limit?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<GetProjectsWhere>
}

export type QueryTasksArgs = {
  limit?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<GetTasksWhere>
}

export type QueryUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>
  skip?: InputMaybe<Scalars['Int']>
  where?: InputMaybe<GetUsersWhere>
}

export type Question = {
  __typename?: 'Question'
  id: Scalars['ID']
  /** The text of the question, eg "What is your favourite colour?" */
  name: Scalars['String']
  /** The position of the question relative to other questions in the section */
  position: Scalars['Int']
  /** The question should only be shown when all of the conditions are true */
  visibleWhen: Array<QuestionPartCondition>
}

export type QuestionMain = {
  __typename?: 'QuestionMain'
  deletedAt?: Maybe<Scalars['DateTime']>
  deliverableTypeId: Scalars['String']
  id: Scalars['ID']
  options?: Maybe<Array<Scalars['String']>>
  questionTypeId: QuestionTypeId
  sliderProps?: Maybe<SliderProps>
  text: Scalars['String']
}

export type QuestionPart = {
  id: Scalars['ID']
  /** The relative order of the part in the question */
  position: Scalars['Int']
  /** Controls when the part should be visible. No entries means the part is always visible */
  visibleWhen: Array<QuestionPartCondition>
}

/** Describes a condition that must be true for something to happen. A condition is considered true if all of its criteria are true. A condition without any criteria is false. */
export type QuestionPartCondition = {
  __typename?: 'QuestionPartCondition'
  /** If present, the condition is only met if one of the question's responses matches this value */
  contains?: Maybe<Scalars['String']>
  /** If true, the condition is negated - ie a condition that would otherwise be true is false and vice-versa */
  negate: Scalars['Boolean']
  /** The ID of the question to check the responses of */
  questionPartId: Scalars['ID']
}

export type QuestionResponse = {
  id: Scalars['ID']
}

export type QuestionResponseInput = {
  /** The ID of an asset. Should not be set if text is set */
  assetId?: InputMaybe<Scalars['ID']>
  /** The text for textual and numeric responses. Should not be set if assetId is set */
  text?: InputMaybe<Scalars['String']>
}

export type QuestionResponseMain = {
  __typename?: 'QuestionResponseMain'
  deliverable: Scalars['String']
  questions: Array<QuestionMain>
}

export enum QuestionTypeId {
  ConditionalFileUpload = 'CONDITIONAL_FILE_UPLOAD',
  Currency = 'CURRENCY',
  DatePicker = 'DATE_PICKER',
  FileUpload = 'FILE_UPLOAD',
  KeywordSelect = 'KEYWORD_SELECT',
  LinkField = 'LINK_FIELD',
  MultiChoice = 'MULTI_CHOICE',
  NumberField = 'NUMBER_FIELD',
  SingleChoice = 'SINGLE_CHOICE',
  Slider = 'SLIDER',
  TextArea = 'TEXT_AREA',
  TextField = 'TEXT_FIELD',
}

export type QuestionsFilter = {
  deliverables: Array<Scalars['String']>
}

export type Reference = {
  /** The ID of the reference */
  id: Scalars['ID']
}

export type ReminderNotificationInput = {
  activityId: Scalars['ID']
  data: AdditionalNotificationDataType
  personalizedMessage?: InputMaybe<Scalars['String']>
  purpose?: InputMaybe<Scalars['String']>
  userIds: Array<Scalars['ID']>
}

export type RemoveChecklistItemsInput = {
  /** The checklist items ids for removing */
  itemsIds: Array<Scalars['ID']>
}

export type RemoveComposeDocumentInput = {
  /** The id of the activity */
  activityId: Scalars['ID']
  /** The id of the File(upload or compose document) to remove */
  composeDocumentId: Scalars['ID']
}

export type RemoveFeedbackMessageInput = {
  /** The id of feedback message to remove */
  messageId: Scalars['ID']
  userId: Scalars['ID']
}

export type RemoveFeedbackMessageResolverInput = {
  /** The id of feedback message to remove */
  messageId: Scalars['ID']
}

export type RemoveFeedbackMessageResponse = {
  __typename?: 'RemoveFeedbackMessageResponse'
  /** Flag to show that message was deleted */
  isDelete: Scalars['Boolean']
}

export type RemoveMemberFromOrganization = {
  /** The organization id */
  organizationId: Scalars['String']
  /** The user id to remove from orzanization */
  userId: Scalars['String']
}

export type RemoveUploadDocumentInput = {
  /** The id of the activity */
  activityId: Scalars['ID']
  /** The id of the File(upload document) to remove */
  uploadDocumentId: Scalars['ID']
}

export type ReorderFileVersionInput = {
  /** The list of the files to update version by the file id */
  fileList: Array<FileData>
}

export type SaveAssetInput = {
  /** Provide filename which will be signed with uuid */
  name?: InputMaybe<Scalars['String']>
  /** The id of the organization */
  organizationId: Scalars['ID']
  /** Path to folder in a storage, such as avatar or docs */
  path: Scalars['String']
  /** File type such as png, jpeg or pdf */
  type: FileTypes
}

export type SaveAssetResponse = {
  __typename?: 'SaveAssetResponse'
  file: File
  id: Scalars['String']
  name: Scalars['String']
  path: Scalars['String']
  url: Scalars['String']
}

/** An individual option for a SelectQuestionPart */
export type SelectOption = {
  __typename?: 'SelectOption'
  /** The text that's displayed to the user */
  label: Scalars['String']
  /** The value that will be added to the response if this option is selected */
  value: Scalars['String']
}

export type SelectQuestionPart = QuestionPart & {
  __typename?: 'SelectQuestionPart'
  /** If true, the user is allowed to choose multiple options */
  allowMultiple: Scalars['Boolean']
  id: Scalars['ID']
  /** The options that are available to choose from */
  options: Array<SelectOption>
  /** The relative order of the part in the question */
  position: Scalars['Int']
  /** Controls when the part should be visible. No entries means the part is always visible */
  visibleWhen: Array<QuestionPartCondition>
}

export type SentNotificationInput = {
  activityId: Scalars['ID']
  data: AdditionalNotificationDataType
  personalizedMessage?: InputMaybe<Scalars['String']>
  purpose: Scalars['String']
  userIds: Array<Scalars['ID']>
}

export type SetProjectFavoriteInput = {
  /** Whether the project should be favourited */
  favorite: Scalars['Boolean']
  /** The ID of the project to favourite or unfavourite */
  id: Scalars['ID']
}

export type SetProjectFavoriteResponse = {
  __typename?: 'SetProjectFavoriteResponse'
  /** The project that was updated */
  project: Project
}

export type SetQuestionPartResponsesInput = {
  /** The ID of the form the response is for */
  formId: Scalars['ID']
  /** The ID of the question part response is for */
  questionPartId: Scalars['ID']
  /** The responses to set for the question part. Any existing responses for this question part will be removed */
  responses: Array<QuestionResponseInput>
}

export type SetQuestionPartResponsesResponse = {
  __typename?: 'SetQuestionPartResponsesResponse'
  /** The responses for the question part */
  responses: Array<QuestionResponse>
}

export type SignedTokenType = {
  __typename?: 'SignedTokenType'
  /** Expires days count */
  expireDays: Scalars['Float']
  id: Scalars['ID']
  /** Token payload */
  payload: Scalars['String']
  /** Generated token to match */
  token: Scalars['String']
  type: Scalars['String']
}

export type SliderProps = {
  __typename?: 'SliderProps'
  maxLabel: Scalars['String']
  maxValue: Scalars['Float']
  minLabel: Scalars['String']
  minValue: Scalars['Float']
  showValue: Scalars['Boolean']
}

export type SliderQuestionPart = QuestionPart & {
  __typename?: 'SliderQuestionPart'
  id: Scalars['ID']
  /** The default value of the slider */
  initialValue: Scalars['Float']
  /** The maximum allowed value of the slider */
  maximumValue: Scalars['Float']
  /** The minimum allowed value of the slider */
  minimumValue: Scalars['Float']
  /** How to format the numbers */
  numberFormat: NumberFormat
  /** The relative order of the part in the question */
  position: Scalars['Int']
  /** Controls when the part should be visible. No entries means the part is always visible */
  visibleWhen: Array<QuestionPartCondition>
}

/** Sort order type */
export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type Task = {
  __typename?: 'Task'
  /** The activity the task belongs to. Right now user and organisation-level tasks are not supported */
  activity?: Maybe<Activity>
  activityId?: Maybe<Scalars['ID']>
  /** When the task was archived, in ISO 8601 format. Null means the task has not been archived */
  archivedAt?: Maybe<Scalars['DateTime']>
  /** The users that have been assigned to the task */
  assignedTo: Array<User>
  /** The checklist items (sub-tasks) that have been attached to the task */
  checklist: Array<ChecklistItem>
  /** When the task was completed. Null means the task has not been completed yet */
  completedAt?: Maybe<Scalars['DateTime']>
  /** Further details of the task. Tasks that do not have a description have an empty string */
  description: Scalars['String']
  /** The tasks due date, represented in ISO 8601 format. Null means the task currently doesn't have a due date */
  dueDate?: Maybe<Scalars['DateTime']>
  id: Scalars['ID']
  /** The 'title' of the task, displayed in the tasks's summary view */
  name: Scalars['String']
  organizationId?: Maybe<Scalars['ID']>
  /** The project the task belongs to */
  project?: Maybe<Project>
  projectId?: Maybe<Scalars['ID']>
  rowVersion?: Maybe<Scalars['Float']>
  /** The current status of the task */
  status: TaskStatus
  userId?: Maybe<Scalars['ID']>
}

export type TaskMember = {
  __typename?: 'TaskMember'
  /** An internal ID for the TaskMember */
  id: Scalars['ID']
  /** The user's role in the task */
  role: TaskRole
  /** The task the user is a member of */
  task: Task
  /** The user's details */
  user: User
}

/** The role of a user with a task. NONE means the user is not a member */
export enum TaskRole {
  Assigned = 'ASSIGNED',
  None = 'NONE',
}

export enum TaskStatus {
  Complete = 'COMPLETE',
  InProgress = 'IN_PROGRESS',
  Review = 'REVIEW',
  Todo = 'TODO',
}

export type TextQuestionPart = QuestionPart & {
  __typename?: 'TextQuestionPart'
  /** If present, the label to display to the allow the user to add an additional entry. */
  addMoreLabel?: Maybe<Scalars['String']>
  /** If true, the user is allowed to enter multiple values */
  allowMultiple: Scalars['Boolean']
  id: Scalars['ID']
  /** If true, the user should be able enter multiple lines of text */
  multiline: Scalars['Boolean']
  /** If not null, indicates that values should be numeric and displayed in the given format */
  numberFormat?: Maybe<NumberFormat>
  /** The relative order of the part in the question */
  position: Scalars['Int']
  /** Controls when the part should be visible. No entries means the part is always visible */
  visibleWhen: Array<QuestionPartCondition>
}

/** A textual response, including for numbers */
export type TextResponse = QuestionResponse & {
  __typename?: 'TextResponse'
  id: Scalars['ID']
  /** The response of the user */
  text: Scalars['String']
}

export type ToggleMembersStatusInput = {
  notificationId: Scalars['ID']
  userIds: Array<Scalars['ID']>
}

export type UpdateActivityInput = {
  /** If set, sets whether the activity is archived */
  archived?: InputMaybe<Scalars['Boolean']>
  /** Moves the activity before the activity with this ID. Must be the ID of an activity in the same milestone, or if milestone is also specified, the milestone the activity is being moved to. An empty string places this activity after every other activity in the milestone */
  beforeId?: InputMaybe<Scalars['ID']>
  id: Scalars['ID']
  /** Moves the activity to the given milestone. An empty string moves the activityto the project milestones. If beforeId is not also specified, the activity will be placed as the last activity in the new milestone */
  milestoneId?: InputMaybe<Scalars['ID']>
  /** Sets the name of the activity */
  name?: InputMaybe<Scalars['String']>
  /** The status of the activity */
  status?: InputMaybe<ActivityStatus>
}

export type UpdateActivityResponse = {
  __typename?: 'UpdateActivityResponse'
  /** The activity that was directly acted upon */
  activity: Activity
  /** All activities that were affected by the change. Changing the position or milestone of an activity can cause the position field of other activities to change */
  updatedActivities: Array<Activity>
}

export type UpdateAnswerInput = {
  briefId: Scalars['String']
  deliverableId: Scalars['String']
  responses: Array<AnswerResponseInput>
}

export type UpdateArtworkInput = {
  /** If set, sets whether the activity is archived */
  archived?: InputMaybe<Scalars['Boolean']>
  /** If set, sets the asset of the artwork */
  assetId?: InputMaybe<Scalars['ID']>
  /** Moves the activity before the activity with this ID. Must be the ID of an activity in the same milestone, or if milestone is also specified, the milestone the activity is being moved to. An empty string places this activity after every other activity in the milestone */
  beforeId?: InputMaybe<Scalars['ID']>
  id: Scalars['ID']
  /** Moves the activity to the given milestone. An empty string moves the activityto the project milestones. If beforeId is not also specified, the activity will be placed as the last activity in the new milestone */
  milestoneId?: InputMaybe<Scalars['ID']>
  /** Sets the name of the activity */
  name?: InputMaybe<Scalars['String']>
  /** The status of the activity */
  status?: InputMaybe<ActivityStatus>
}

export type UpdateBriefInput = {
  /** If set, sets whether the activity is archived */
  archived?: InputMaybe<Scalars['Boolean']>
  /** Moves the activity before the activity with this ID. Must be the ID of an activity in the same milestone, or if milestone is also specified, the milestone the activity is being moved to. An empty string places this activity after every other activity in the milestone */
  beforeId?: InputMaybe<Scalars['ID']>
  deliverable?: InputMaybe<DeliverableMainInput>
  /** If set, sets the form of the brief */
  formId?: InputMaybe<Scalars['ID']>
  id: Scalars['ID']
  /** Moves the activity to the given milestone. An empty string moves the activityto the project milestones. If beforeId is not also specified, the activity will be placed as the last activity in the new milestone */
  milestoneId?: InputMaybe<Scalars['ID']>
  /** Sets the name of the activity */
  name?: InputMaybe<Scalars['String']>
  /** The status of the activity */
  status?: InputMaybe<ActivityStatus>
}

export type UpdateChecklistItemInput = {
  /** Whether the item is complete or not */
  complete?: InputMaybe<Scalars['Boolean']>
  /** The ID of the checklist item to update */
  id: Scalars['ID']
  /** The 'title' of the item */
  name?: InputMaybe<Scalars['String']>
}

export type UpdateComposedDocumentInput = {
  /** Activity or Brief id */
  id: Scalars['ID']
  /** File name, if not provided it should be brief */
  name?: InputMaybe<Scalars['String']>
}

export type UpdateFeedbackMessageData = {
  /** If set, the message is completed */
  complete?: InputMaybe<Scalars['Boolean']>
  /** The id of the feedback message entity */
  feedbackMessageId: Scalars['ID']
  /** If set, the message is pinned */
  pinned?: InputMaybe<Scalars['Boolean']>
}

export type UpdateFeedbackMessageInput = {
  /** The message data to update */
  content?: InputMaybe<Scalars['String']>
  /** The data for the feedback message entity */
  feedbackMessage?: InputMaybe<UpdateFeedbackMessageData>
  /** The id of feedback message to update comment */
  messageId: Scalars['ID']
  userId: Scalars['ID']
}

export type UpdateFeedbackMessageResolverInput = {
  /** The message data to update */
  content?: InputMaybe<Scalars['String']>
  /** The data for the feedback message entity */
  feedbackMessage?: InputMaybe<UpdateFeedbackMessageData>
  /** The id of feedback message to update comment */
  messageId: Scalars['ID']
}

export type UpdateMilestoneInput = {
  /** If set, sets whether the milestone is archived */
  archived?: InputMaybe<Scalars['Boolean']>
  id: Scalars['ID']
  /** Sets the name of the milestone */
  name?: InputMaybe<Scalars['String']>
}

export type UpdateMilestoneResponse = {
  __typename?: 'UpdateMilestoneResponse'
  /** The milestone that was updated */
  milestone: Milestone
}

export type UpdateOrganizationInput = {
  /** The id of the organization you wish to update */
  id: Scalars['ID']
  /** The new name of the organization. If null the name will not be updated */
  name?: InputMaybe<Scalars['String']>
  /** The range of the organization. */
  range?: InputMaybe<Scalars['String']>
  /** The type of the organization */
  type?: InputMaybe<OrganizationType>
}

export type UpdateOrganizationMemberInput = {
  /** The ID of the organization where need user to update */
  organizationId: Scalars['String']
  /** New role for the user to update */
  role: Scalars['String']
  /** The ID of the user to update */
  userId: Scalars['String']
}

export type UpdateOrganizationMemberResponse = {
  __typename?: 'UpdateOrganizationMemberResponse'
  isSuccess: Scalars['Boolean']
  updatedRole: OrganizationRole
}

export type UpdateOrganizationResponse = {
  __typename?: 'UpdateOrganizationResponse'
  /** The organization that was updated */
  organization: Organization
}

export type UpdateProjectInput = {
  /** If set, sets whether the project is archived */
  archived?: InputMaybe<Scalars['Boolean']>
  /** Sets the name of the client the project is for */
  clientName?: InputMaybe<Scalars['String']>
  /** Sets the colour of the project, eg #aabb88 */
  color?: InputMaybe<Scalars['String']>
  /** Sets when the project is due. An empty string clears the date */
  dueDate?: InputMaybe<Scalars['DateTime']>
  id: Scalars['ID']
  /** Updates the users that should be assigned to the project. To remove a user from the project, set their role to NONE */
  members?: InputMaybe<Array<UpdateProjectMemberInput>>
  /** Sets the name of the project */
  name?: InputMaybe<Scalars['String']>
  /** The id of organization to change in the project */
  organizationId?: InputMaybe<Scalars['ID']>
}

export type UpdateProjectMemberInput = {
  /** The user's role in the project */
  role: ProjectRole
  /** The ID of the user to update */
  userId: Scalars['ID']
}

export type UpdateProjectResponse = {
  __typename?: 'UpdateProjectResponse'
  /** The project that was updated */
  project: Project
}

export type UpdateTaskInput = {
  /** If set, sets whether the task is archived */
  archived?: InputMaybe<Scalars['Boolean']>
  /** The checklist items to create */
  checklistItems?: InputMaybe<Array<UpdateChecklistItemInput>>
  /** If set, completes or uncompletes the task */
  completed?: InputMaybe<Scalars['Boolean']>
  /** If set, updates the task's description */
  description?: InputMaybe<Scalars['String']>
  /** If set, updates the task's due date. */
  dueDate?: InputMaybe<Scalars['DateTime']>
  /** The ID of the task to update */
  id: Scalars['ID']
  /** The users that should be assigned/unassigned to the task */
  members?: InputMaybe<Array<UpdateTaskMemberInput>>
  /** If set, updates the task's name */
  name?: InputMaybe<Scalars['String']>
  /** If set, updates the status of a task */
  status?: InputMaybe<TaskStatus>
}

export type UpdateTaskMemberInput = {
  /** The user's role in the task */
  role: TaskRole
  /** The ID of the user to update */
  userId: Scalars['ID']
}

export type UpdateTaskResponse = {
  __typename?: 'UpdateTaskResponse'
  task: Task
}

export type UpdateUploadedDocumentInput = {
  /** Activity or Brief id */
  id: Scalars['ID']
  /** File name, if not provided it should be brief */
  name?: InputMaybe<Scalars['String']>
  /** File type such as png, jpeg, pdf or html */
  type?: InputMaybe<FileTypes>
}

export type UpdateUserInput = {
  /** If set, updates the user's avatar */
  avatar?: InputMaybe<Scalars['String']>
  /** If set, updates the user's email */
  email?: InputMaybe<Scalars['String']>
  /** The ID of the user to update */
  id: Scalars['String']
  /** If set, updates the user's wizard completion flag */
  isPostSignUpFormCompleted?: InputMaybe<Scalars['Boolean']>
  /** If set, updates the user's name */
  name?: InputMaybe<Scalars['String']>
  /** If set, updates the user's position */
  position?: InputMaybe<Scalars['String']>
  /** If set, updates the user's pronouns */
  pronouns?: InputMaybe<Scalars['String']>
}

export type UpdateUserResponse = {
  __typename?: 'UpdateUserResponse'
  user: User
}

export type User = {
  __typename?: 'User'
  avatarUrl: Scalars['String']
  /** The user's email address. This will only be set for the user making the request - for all other users it will be null */
  email?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isPostSignUpFormCompleted?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  position?: Maybe<Scalars['String']>
}

export type UserExistInput = {
  /** User email */
  email: Scalars['String']
}

export type UserExistResponse = {
  __typename?: 'UserExistResponse'
  isExist: Scalars['Boolean']
}

export type UserOrganization = {
  __typename?: 'UserOrganization'
  id: Scalars['ID']
  role?: Maybe<Scalars['ID']>
  userId?: Maybe<Scalars['ID']>
}

export type UserWithOrganization = {
  __typename?: 'UserWithOrganization'
  avatarUrl: Scalars['String']
  /** The user's email address. This will only be set for the user making the request - for all other users it will be null */
  email?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isPostSignUpFormCompleted?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  organizationMember?: Maybe<UserOrganization>
  position?: Maybe<Scalars['String']>
}

export type Workshop = Activity & {
  __typename?: 'Workshop'
  answers: Array<AnswerMainType>
  /** The approvals, both requested and given */
  approvals: Array<Approval>
  /** When the activity was archived. Null means it's not archived */
  archivedAt?: Maybe<Scalars['DateTime']>
  /** The assets attached to the activity */
  assets: Array<Asset>
  author: Scalars['String']
  composedDocument?: Maybe<File>
  createdAt?: Maybe<Scalars['DateTime']>
  deliverables?: Maybe<Array<DeliverableMainType>>
  /** The feedback received for this activity */
  feedback: Feedback
  /** The ID of the activity */
  id: Scalars['ID']
  /** The milestone the activity belongs to */
  milestone?: Maybe<Milestone>
  /** The name of the activity */
  name: Scalars['String']
  /** The position (relative to other activities) the activity should be displayed in */
  position: Scalars['Int']
  /** The project the activity belongs to */
  project: Project
  /** The status of the activity */
  status?: Maybe<ActivityStatus>
  /** The tasks belonging to the activity */
  tasks: Array<Task>
  type: ActivityType
  /** When the activity was last updated */
  updatedAt: Scalars['DateTime']
  uploadedDocument?: Maybe<File>
  /** When the current user last viewed this activity. Null means the user has never viewed this activity */
  viewedAt?: Maybe<Scalars['DateTime']>
}

export enum NotificationApprovalTypes {
  ApprovalApprove = 'ApprovalApprove',
  ApprovalAssign = 'ApprovalAssign',
  ApproveReminder = 'ApproveReminder',
}

type Activity_Artwork_Fragment = {
  __typename?: 'Artwork'
  id: string
  name: string
  viewedAt?: any | null
  updatedAt: any
  archivedAt?: any | null
  status?: ActivityStatus | null
  author: string
  position: number
  type: ActivityType
  feedback: {
    __typename?: 'Feedback'
    id?: string | null
    messages: Array<{ __typename?: 'Message'; id: string; content: string; createdAt: any }>
    requests: Array<{
      __typename?: 'FeedbackRequest'
      id: string
      user: {
        __typename?: 'User'
        id: string
        name?: string | null
        email?: string | null
        avatarUrl: string
        position?: string | null
        isPostSignUpFormCompleted?: boolean | null
      }
    }>
  }
  milestone?: { __typename?: 'Milestone'; id: string } | null
  tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
  composedDocument?: {
    __typename?: 'File'
    id: string
    name: string
    type: string
    path: string
    getUrl: string
  } | null
  uploadedDocument?: {
    __typename?: 'File'
    id: string
    name: string
    type: string
    path: string
    getUrl: string
  } | null
}

type Activity_Brief_Fragment = {
  __typename?: 'Brief'
  id: string
  name: string
  viewedAt?: any | null
  updatedAt: any
  archivedAt?: any | null
  status?: ActivityStatus | null
  author: string
  position: number
  type: ActivityType
  feedback: {
    __typename?: 'Feedback'
    id?: string | null
    messages: Array<{ __typename?: 'Message'; id: string; content: string; createdAt: any }>
    requests: Array<{
      __typename?: 'FeedbackRequest'
      id: string
      user: {
        __typename?: 'User'
        id: string
        name?: string | null
        email?: string | null
        avatarUrl: string
        position?: string | null
        isPostSignUpFormCompleted?: boolean | null
      }
    }>
  }
  milestone?: { __typename?: 'Milestone'; id: string } | null
  tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
  composedDocument?: {
    __typename?: 'File'
    id: string
    name: string
    type: string
    path: string
    getUrl: string
  } | null
  uploadedDocument?: {
    __typename?: 'File'
    id: string
    name: string
    type: string
    path: string
    getUrl: string
  } | null
}

type Activity_DefaultActivity_Fragment = {
  __typename?: 'DefaultActivity'
  id: string
  name: string
  viewedAt?: any | null
  updatedAt: any
  archivedAt?: any | null
  status?: ActivityStatus | null
  author: string
  position: number
  type: ActivityType
  feedback: {
    __typename?: 'Feedback'
    id?: string | null
    messages: Array<{ __typename?: 'Message'; id: string; content: string; createdAt: any }>
    requests: Array<{
      __typename?: 'FeedbackRequest'
      id: string
      user: {
        __typename?: 'User'
        id: string
        name?: string | null
        email?: string | null
        avatarUrl: string
        position?: string | null
        isPostSignUpFormCompleted?: boolean | null
      }
    }>
  }
  milestone?: { __typename?: 'Milestone'; id: string } | null
  tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
  composedDocument?: {
    __typename?: 'File'
    id: string
    name: string
    type: string
    path: string
    getUrl: string
  } | null
  uploadedDocument?: {
    __typename?: 'File'
    id: string
    name: string
    type: string
    path: string
    getUrl: string
  } | null
}

type Activity_Workshop_Fragment = {
  __typename?: 'Workshop'
  id: string
  name: string
  viewedAt?: any | null
  updatedAt: any
  archivedAt?: any | null
  status?: ActivityStatus | null
  author: string
  position: number
  type: ActivityType
  feedback: {
    __typename?: 'Feedback'
    id?: string | null
    messages: Array<{ __typename?: 'Message'; id: string; content: string; createdAt: any }>
    requests: Array<{
      __typename?: 'FeedbackRequest'
      id: string
      user: {
        __typename?: 'User'
        id: string
        name?: string | null
        email?: string | null
        avatarUrl: string
        position?: string | null
        isPostSignUpFormCompleted?: boolean | null
      }
    }>
  }
  milestone?: { __typename?: 'Milestone'; id: string } | null
  tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
  composedDocument?: {
    __typename?: 'File'
    id: string
    name: string
    type: string
    path: string
    getUrl: string
  } | null
  uploadedDocument?: {
    __typename?: 'File'
    id: string
    name: string
    type: string
    path: string
    getUrl: string
  } | null
}

export type ActivityFragment =
  | Activity_Artwork_Fragment
  | Activity_Brief_Fragment
  | Activity_DefaultActivity_Fragment
  | Activity_Workshop_Fragment

type ActivitySummary_Artwork_Fragment = {
  __typename?: 'Artwork'
  id: string
  name: string
  viewedAt?: any | null
  updatedAt: any
  archivedAt?: any | null
  status?: ActivityStatus | null
  author: string
  position: number
  type: ActivityType
  milestone?: { __typename?: 'Milestone'; id: string } | null
  tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
  composedDocument?: {
    __typename?: 'File'
    id: string
    name: string
    type: string
    path: string
    getUrl: string
  } | null
  uploadedDocument?: {
    __typename?: 'File'
    id: string
    name: string
    type: string
    path: string
    getUrl: string
  } | null
}

type ActivitySummary_Brief_Fragment = {
  __typename?: 'Brief'
  id: string
  name: string
  viewedAt?: any | null
  updatedAt: any
  archivedAt?: any | null
  status?: ActivityStatus | null
  author: string
  position: number
  type: ActivityType
  milestone?: { __typename?: 'Milestone'; id: string } | null
  tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
  composedDocument?: {
    __typename?: 'File'
    id: string
    name: string
    type: string
    path: string
    getUrl: string
  } | null
  uploadedDocument?: {
    __typename?: 'File'
    id: string
    name: string
    type: string
    path: string
    getUrl: string
  } | null
}

type ActivitySummary_DefaultActivity_Fragment = {
  __typename?: 'DefaultActivity'
  id: string
  name: string
  viewedAt?: any | null
  updatedAt: any
  archivedAt?: any | null
  status?: ActivityStatus | null
  author: string
  position: number
  type: ActivityType
  milestone?: { __typename?: 'Milestone'; id: string } | null
  tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
  composedDocument?: {
    __typename?: 'File'
    id: string
    name: string
    type: string
    path: string
    getUrl: string
  } | null
  uploadedDocument?: {
    __typename?: 'File'
    id: string
    name: string
    type: string
    path: string
    getUrl: string
  } | null
}

type ActivitySummary_Workshop_Fragment = {
  __typename?: 'Workshop'
  id: string
  name: string
  viewedAt?: any | null
  updatedAt: any
  archivedAt?: any | null
  status?: ActivityStatus | null
  author: string
  position: number
  type: ActivityType
  milestone?: { __typename?: 'Milestone'; id: string } | null
  tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
  composedDocument?: {
    __typename?: 'File'
    id: string
    name: string
    type: string
    path: string
    getUrl: string
  } | null
  uploadedDocument?: {
    __typename?: 'File'
    id: string
    name: string
    type: string
    path: string
    getUrl: string
  } | null
}

export type ActivitySummaryFragment =
  | ActivitySummary_Artwork_Fragment
  | ActivitySummary_Brief_Fragment
  | ActivitySummary_DefaultActivity_Fragment
  | ActivitySummary_Workshop_Fragment

export type AnswerFragment = {
  __typename?: 'AnswerMainType'
  id: string
  questionId: string
  userId?: string | null
  comment: {
    __typename?: 'AnswerCommentType'
    id: string
    content: string
    answerId?: string | null
    type: QuestionTypeId
  }
}

type AssetSummary_Document_Fragment = {
  __typename?: 'Document'
  id: string
  name: string
  type: string
}

type AssetSummary_File_Fragment = { __typename?: 'File'; id: string; name: string; type: string }

type AssetSummary_Form_Fragment = { __typename?: 'Form'; id: string; name: string; type: string }

export type AssetSummaryFragment =
  | AssetSummary_Document_Fragment
  | AssetSummary_File_Fragment
  | AssetSummary_Form_Fragment

type Brief_Artwork_Fragment = {
  __typename?: 'Artwork'
  id: string
  name: string
  viewedAt?: any | null
  updatedAt: any
  archivedAt?: any | null
  status?: ActivityStatus | null
  author: string
  position: number
  type: ActivityType
  deliverables?: Array<{ __typename?: 'DeliverableMainType'; id: string; name: string }> | null
  milestone?: { __typename?: 'Milestone'; id: string } | null
  tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
  composedDocument?: {
    __typename?: 'File'
    id: string
    name: string
    type: string
    path: string
    getUrl: string
  } | null
  uploadedDocument?: {
    __typename?: 'File'
    id: string
    name: string
    type: string
    path: string
    getUrl: string
  } | null
}

type Brief_Brief_Fragment = {
  __typename?: 'Brief'
  id: string
  name: string
  viewedAt?: any | null
  updatedAt: any
  archivedAt?: any | null
  status?: ActivityStatus | null
  author: string
  position: number
  type: ActivityType
  deliverables?: Array<{ __typename?: 'DeliverableMainType'; id: string; name: string }> | null
  milestone?: { __typename?: 'Milestone'; id: string } | null
  tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
  composedDocument?: {
    __typename?: 'File'
    id: string
    name: string
    type: string
    path: string
    getUrl: string
  } | null
  uploadedDocument?: {
    __typename?: 'File'
    id: string
    name: string
    type: string
    path: string
    getUrl: string
  } | null
}

type Brief_DefaultActivity_Fragment = {
  __typename?: 'DefaultActivity'
  id: string
  name: string
  viewedAt?: any | null
  updatedAt: any
  archivedAt?: any | null
  status?: ActivityStatus | null
  author: string
  position: number
  type: ActivityType
  deliverables?: Array<{ __typename?: 'DeliverableMainType'; id: string; name: string }> | null
  milestone?: { __typename?: 'Milestone'; id: string } | null
  tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
  composedDocument?: {
    __typename?: 'File'
    id: string
    name: string
    type: string
    path: string
    getUrl: string
  } | null
  uploadedDocument?: {
    __typename?: 'File'
    id: string
    name: string
    type: string
    path: string
    getUrl: string
  } | null
}

type Brief_Workshop_Fragment = {
  __typename?: 'Workshop'
  id: string
  name: string
  viewedAt?: any | null
  updatedAt: any
  archivedAt?: any | null
  status?: ActivityStatus | null
  author: string
  position: number
  type: ActivityType
  deliverables?: Array<{ __typename?: 'DeliverableMainType'; id: string; name: string }> | null
  milestone?: { __typename?: 'Milestone'; id: string } | null
  tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
  composedDocument?: {
    __typename?: 'File'
    id: string
    name: string
    type: string
    path: string
    getUrl: string
  } | null
  uploadedDocument?: {
    __typename?: 'File'
    id: string
    name: string
    type: string
    path: string
    getUrl: string
  } | null
}

export type BriefFragment =
  | Brief_Artwork_Fragment
  | Brief_Brief_Fragment
  | Brief_DefaultActivity_Fragment
  | Brief_Workshop_Fragment

export type ChecklistItemFragment = {
  __typename?: 'ChecklistItem'
  id: string
  name: string
  completedAt?: any | null
}

export type CommentFragment = {
  __typename?: 'AnswerCommentType'
  content: string
  id: string
  type: QuestionTypeId
  answerId?: string | null
}

export type DeliverableCategoryFragment = { __typename?: 'DeliverableCategory'; name: string }

export type DeliverableTypeFragment = {
  __typename?: 'DeliverableType'
  id: string
  name: string
  category: { __typename?: 'DeliverableCategory'; name: string }
  template: { __typename?: 'FormTemplate'; id: string; name: string }
}

export type FeedbackMessageFragment = {
  __typename?: 'FeedbackMessage'
  feedbackMessageId?: string | null
  feedbackId?: string | null
  messageId?: string | null
  createdAt?: any | null
  userId?: string | null
  content?: string | null
  assetId?: string | null
  type?: FeedbackMessageType | null
  pinned?: boolean | null
  completedAt?: any | null
  position?: { __typename?: 'FeedbackPosition'; x: number; y: number; page: number } | null
  user?: {
    __typename?: 'User'
    id: string
    name?: string | null
    email?: string | null
    avatarUrl: string
  } | null
  members?: Array<{
    __typename?: 'User'
    id: string
    name?: string | null
    email?: string | null
    avatarUrl: string
  }> | null
  replyedComments: Array<{
    __typename?: 'FeedbackMessage'
    feedbackMessageId?: string | null
    feedbackId?: string | null
    messageId?: string | null
    createdAt?: any | null
    userId?: string | null
    content?: string | null
    assetId?: string | null
    type?: FeedbackMessageType | null
    pinned?: boolean | null
    completedAt?: any | null
    repliedUser?: {
      __typename?: 'User'
      id: string
      name?: string | null
      email?: string | null
      avatarUrl: string
    } | null
    user?: {
      __typename?: 'User'
      id: string
      name?: string | null
      email?: string | null
      avatarUrl: string
    } | null
    members?: Array<{
      __typename?: 'User'
      id: string
      name?: string | null
      email?: string | null
      avatarUrl: string
    }> | null
  }>
}

export type FeedbackRequestFragment = {
  __typename?: 'FeedbackRequest'
  id: string
  user: {
    __typename?: 'User'
    id: string
    name?: string | null
    email?: string | null
    avatarUrl: string
    position?: string | null
    isPostSignUpFormCompleted?: boolean | null
  }
}

export type FileFragment = {
  __typename?: 'File'
  path: string
  getUrl: string
  id: string
  name: string
  type: string
}

export type MeFragment = {
  __typename?: 'User'
  id: string
  name?: string | null
  email?: string | null
  avatarUrl: string
  position?: string | null
  isPostSignUpFormCompleted?: boolean | null
}

export type MessageFragment = {
  __typename?: 'Message'
  id: string
  content: string
  createdAt: any
}

export type MilestoneFragment = {
  __typename?: 'Milestone'
  id: string
  name: string
  archivedAt?: any | null
  activities: Array<
    | {
        __typename?: 'Artwork'
        id: string
        name: string
        viewedAt?: any | null
        updatedAt: any
        archivedAt?: any | null
        status?: ActivityStatus | null
        author: string
        position: number
        type: ActivityType
        milestone?: { __typename?: 'Milestone'; id: string } | null
        tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
        composedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
        uploadedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
      }
    | {
        __typename?: 'Brief'
        id: string
        name: string
        viewedAt?: any | null
        updatedAt: any
        archivedAt?: any | null
        status?: ActivityStatus | null
        author: string
        position: number
        type: ActivityType
        milestone?: { __typename?: 'Milestone'; id: string } | null
        tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
        composedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
        uploadedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
      }
    | {
        __typename?: 'DefaultActivity'
        id: string
        name: string
        viewedAt?: any | null
        updatedAt: any
        archivedAt?: any | null
        status?: ActivityStatus | null
        author: string
        position: number
        type: ActivityType
        milestone?: { __typename?: 'Milestone'; id: string } | null
        tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
        composedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
        uploadedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
      }
    | {
        __typename?: 'Workshop'
        id: string
        name: string
        viewedAt?: any | null
        updatedAt: any
        archivedAt?: any | null
        status?: ActivityStatus | null
        author: string
        position: number
        type: ActivityType
        milestone?: { __typename?: 'Milestone'; id: string } | null
        tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
        composedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
        uploadedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
      }
  >
}

export type OrganizationFragment = {
  __typename?: 'Organization'
  id: string
  name: string
  range?: string | null
  type?: OrganizationType | null
  members: Array<{
    __typename?: 'OrganizationMember'
    role: OrganizationRole
    createdAt: any
    user: {
      __typename?: 'User'
      name?: string | null
      email?: string | null
      avatarUrl: string
      id: string
    }
  }>
}

export type ProjectFragment = {
  __typename?: 'Project'
  dueDate?: any | null
  archivedAt?: any | null
  id: string
  name: string
  color: string
  favorite: boolean
  members: Array<{
    __typename?: 'ProjectMember'
    id: string
    role: ProjectRole
    user: {
      __typename?: 'User'
      id: string
      name?: string | null
      email?: string | null
      avatarUrl: string
    }
  }>
  client?: { __typename?: 'Client'; name: string } | null
  milestones: Array<{
    __typename?: 'Milestone'
    id: string
    name: string
    archivedAt?: any | null
    activities: Array<
      | {
          __typename?: 'Artwork'
          id: string
          name: string
          viewedAt?: any | null
          updatedAt: any
          archivedAt?: any | null
          status?: ActivityStatus | null
          author: string
          position: number
          type: ActivityType
          milestone?: { __typename?: 'Milestone'; id: string } | null
          tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
          composedDocument?: {
            __typename?: 'File'
            id: string
            name: string
            type: string
            path: string
            getUrl: string
          } | null
          uploadedDocument?: {
            __typename?: 'File'
            id: string
            name: string
            type: string
            path: string
            getUrl: string
          } | null
        }
      | {
          __typename?: 'Brief'
          id: string
          name: string
          viewedAt?: any | null
          updatedAt: any
          archivedAt?: any | null
          status?: ActivityStatus | null
          author: string
          position: number
          type: ActivityType
          milestone?: { __typename?: 'Milestone'; id: string } | null
          tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
          composedDocument?: {
            __typename?: 'File'
            id: string
            name: string
            type: string
            path: string
            getUrl: string
          } | null
          uploadedDocument?: {
            __typename?: 'File'
            id: string
            name: string
            type: string
            path: string
            getUrl: string
          } | null
        }
      | {
          __typename?: 'DefaultActivity'
          id: string
          name: string
          viewedAt?: any | null
          updatedAt: any
          archivedAt?: any | null
          status?: ActivityStatus | null
          author: string
          position: number
          type: ActivityType
          milestone?: { __typename?: 'Milestone'; id: string } | null
          tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
          composedDocument?: {
            __typename?: 'File'
            id: string
            name: string
            type: string
            path: string
            getUrl: string
          } | null
          uploadedDocument?: {
            __typename?: 'File'
            id: string
            name: string
            type: string
            path: string
            getUrl: string
          } | null
        }
      | {
          __typename?: 'Workshop'
          id: string
          name: string
          viewedAt?: any | null
          updatedAt: any
          archivedAt?: any | null
          status?: ActivityStatus | null
          author: string
          position: number
          type: ActivityType
          milestone?: { __typename?: 'Milestone'; id: string } | null
          tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
          composedDocument?: {
            __typename?: 'File'
            id: string
            name: string
            type: string
            path: string
            getUrl: string
          } | null
          uploadedDocument?: {
            __typename?: 'File'
            id: string
            name: string
            type: string
            path: string
            getUrl: string
          } | null
        }
    >
  }>
  activities: Array<
    | {
        __typename?: 'Artwork'
        id: string
        name: string
        viewedAt?: any | null
        updatedAt: any
        archivedAt?: any | null
        status?: ActivityStatus | null
        author: string
        position: number
        type: ActivityType
        milestone?: { __typename?: 'Milestone'; id: string } | null
        tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
        composedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
        uploadedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
      }
    | {
        __typename?: 'Brief'
        id: string
        name: string
        viewedAt?: any | null
        updatedAt: any
        archivedAt?: any | null
        status?: ActivityStatus | null
        author: string
        position: number
        type: ActivityType
        milestone?: { __typename?: 'Milestone'; id: string } | null
        tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
        composedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
        uploadedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
      }
    | {
        __typename?: 'DefaultActivity'
        id: string
        name: string
        viewedAt?: any | null
        updatedAt: any
        archivedAt?: any | null
        status?: ActivityStatus | null
        author: string
        position: number
        type: ActivityType
        milestone?: { __typename?: 'Milestone'; id: string } | null
        tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
        composedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
        uploadedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
      }
    | {
        __typename?: 'Workshop'
        id: string
        name: string
        viewedAt?: any | null
        updatedAt: any
        archivedAt?: any | null
        status?: ActivityStatus | null
        author: string
        position: number
        type: ActivityType
        milestone?: { __typename?: 'Milestone'; id: string } | null
        tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
        composedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
        uploadedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
      }
  >
  organization: { __typename?: 'Organization'; id: string; name: string }
}

export type ProjectMemberFragment = {
  __typename?: 'ProjectMember'
  id: string
  role: ProjectRole
  user: {
    __typename?: 'User'
    id: string
    name?: string | null
    email?: string | null
    avatarUrl: string
  }
}

export type ProjectSummaryFragment = {
  __typename?: 'Project'
  id: string
  name: string
  color: string
  favorite: boolean
  organization: { __typename?: 'Organization'; id: string; name: string }
  client?: { __typename?: 'Client'; name: string } | null
}

export type QuestionFragment = {
  __typename?: 'QuestionMain'
  id: string
  text: string
  questionTypeId: QuestionTypeId
  options?: Array<string> | null
  deliverableTypeId: string
  deletedAt?: any | null
  sliderProps?: {
    __typename?: 'SliderProps'
    minValue: number
    maxValue: number
    minLabel: string
    maxLabel: string
    showValue: boolean
  } | null
}

export type ReplyFeedbackMessageFragment = {
  __typename?: 'FeedbackMessage'
  feedbackMessageId?: string | null
  feedbackId?: string | null
  messageId?: string | null
  createdAt?: any | null
  userId?: string | null
  content?: string | null
  assetId?: string | null
  type?: FeedbackMessageType | null
  pinned?: boolean | null
  completedAt?: any | null
  repliedUser?: {
    __typename?: 'User'
    id: string
    name?: string | null
    email?: string | null
    avatarUrl: string
  } | null
  user?: {
    __typename?: 'User'
    id: string
    name?: string | null
    email?: string | null
    avatarUrl: string
  } | null
  members?: Array<{
    __typename?: 'User'
    id: string
    name?: string | null
    email?: string | null
    avatarUrl: string
  }> | null
}

export type TaskFragment = {
  __typename?: 'Task'
  description: string
  id: string
  name: string
  organizationId?: string | null
  userId?: string | null
  dueDate?: any | null
  completedAt?: any | null
  status: TaskStatus
  archivedAt?: any | null
  rowVersion?: number | null
  checklist: Array<{
    __typename?: 'ChecklistItem'
    id: string
    name: string
    completedAt?: any | null
  }>
  project?: {
    __typename?: 'Project'
    id: string
    name: string
    color: string
    favorite: boolean
    organization: { __typename?: 'Organization'; id: string; name: string }
    client?: { __typename?: 'Client'; name: string } | null
  } | null
  assignedTo: Array<{ __typename?: 'User'; id: string; name?: string | null; avatarUrl: string }>
}

export type TaskSummaryFragment = {
  __typename?: 'Task'
  id: string
  name: string
  organizationId?: string | null
  userId?: string | null
  dueDate?: any | null
  completedAt?: any | null
  status: TaskStatus
  archivedAt?: any | null
  rowVersion?: number | null
  project?: {
    __typename?: 'Project'
    id: string
    name: string
    color: string
    favorite: boolean
    organization: { __typename?: 'Organization'; id: string; name: string }
    client?: { __typename?: 'Client'; name: string } | null
  } | null
  assignedTo: Array<{ __typename?: 'User'; id: string; name?: string | null; avatarUrl: string }>
}

export type UserFragment = {
  __typename?: 'User'
  id: string
  name?: string | null
  email?: string | null
  avatarUrl: string
  position?: string | null
  isPostSignUpFormCompleted?: boolean | null
}

export type UserOrganizationFragment = {
  __typename?: 'UserWithOrganization'
  id: string
  name?: string | null
  email?: string | null
  avatarUrl: string
  position?: string | null
  isPostSignUpFormCompleted?: boolean | null
  organizationMember?: { __typename?: 'UserOrganization'; role?: string | null } | null
}

export type AddAssetToArtworkMutationVariables = Exact<{
  input: AddAssetToArtworkInput
}>

export type AddAssetToArtworkMutation = {
  __typename?: 'Mutation'
  addAssetToArtwork: {
    __typename?: 'AddAssetToArtworkResponse'
    putUrl: string
    file: {
      __typename?: 'File'
      id: string
      name: string
      type: string
      getUrl: string
      version?: number | null
    }
  }
}

export type AddFeedbackMemberMutationVariables = Exact<{
  input: FeedbackMemberInput
}>

export type AddFeedbackMemberMutation = {
  __typename?: 'Mutation'
  addMemberToFeedbackMessage: {
    __typename?: 'FeedbackMessageMemberResponse'
    userId: string
    feedbackMessageId: string
  }
}

export type AddFileLinkToActivityMutationVariables = Exact<{
  input: UpdateUploadedDocumentInput
}>

export type AddFileLinkToActivityMutation = {
  __typename?: 'Mutation'
  updateActivityUploadedDocument: { __typename?: 'ActivityUpdateDocumentsResponse'; url: string }
}

export type AddImageFeedbackMutationVariables = Exact<{
  input: AddImageFeedbackInput
}>

export type AddImageFeedbackMutation = {
  __typename?: 'Mutation'
  addImageFeedback: {
    __typename?: 'AddFeedbackResponse'
    feedbackMessageId: string
    message: { __typename?: 'Message'; id: string; content: string; createdAt: any }
  }
}

export type AddMemberToOrganizationMutationVariables = Exact<{
  input: AddOrganazationMember
}>

export type AddMemberToOrganizationMutation = {
  __typename?: 'Mutation'
  addMemberToOrganization: {
    __typename?: 'AddMemberToOrganizationResponse'
    member: {
      __typename?: 'OrganizationMember'
      id: string
      role: OrganizationRole
      createdAt: any
      organization: { __typename?: 'Organization'; id: string }
      user: {
        __typename?: 'User'
        id: string
        name?: string | null
        email?: string | null
        avatarUrl: string
      }
    }
  }
}

export type ArchiveProjectMutationVariables = Exact<{
  input: ArchiveProjectInput
}>

export type ArchiveProjectMutation = {
  __typename?: 'Mutation'
  archiveProject: { __typename?: 'ArchiveProjectResponse'; isArchived: boolean }
}

export type ChangeApprovalMutationVariables = Exact<{
  input: ApprovalResolverInput
}>

export type ChangeApprovalMutation = {
  __typename?: 'Mutation'
  changeApproval: Array<{ __typename?: 'Approval'; userId: string; status?: ApprovalStatus | null }>
}

export type CreateAnswerMutationVariables = Exact<{
  input: UpdateAnswerInput
}>

export type CreateAnswerMutation = {
  __typename?: 'Mutation'
  createAnswers: {
    __typename?: 'AnswerTypesResponse'
    brief:
      | {
          __typename?: 'Artwork'
          answers: Array<{
            __typename?: 'AnswerMainType'
            id: string
            questionId: string
            userId?: string | null
            comment: {
              __typename?: 'AnswerCommentType'
              id: string
              content: string
              answerId?: string | null
              type: QuestionTypeId
            }
          }>
        }
      | {
          __typename?: 'Brief'
          answers: Array<{
            __typename?: 'AnswerMainType'
            id: string
            questionId: string
            userId?: string | null
            comment: {
              __typename?: 'AnswerCommentType'
              id: string
              content: string
              answerId?: string | null
              type: QuestionTypeId
            }
          }>
        }
      | {
          __typename?: 'DefaultActivity'
          answers: Array<{
            __typename?: 'AnswerMainType'
            id: string
            questionId: string
            userId?: string | null
            comment: {
              __typename?: 'AnswerCommentType'
              id: string
              content: string
              answerId?: string | null
              type: QuestionTypeId
            }
          }>
        }
      | {
          __typename?: 'Workshop'
          answers: Array<{
            __typename?: 'AnswerMainType'
            id: string
            questionId: string
            userId?: string | null
            comment: {
              __typename?: 'AnswerCommentType'
              id: string
              content: string
              answerId?: string | null
              type: QuestionTypeId
            }
          }>
        }
  }
}

export type CreateArtworkMutationVariables = Exact<{
  input: CreateArtworkInput
}>

export type CreateArtworkMutation = {
  __typename?: 'Mutation'
  createArtwork: {
    __typename?: 'CreateArtworkResponse'
    activity: {
      __typename?: 'Artwork'
      id: string
      name: string
      viewedAt?: any | null
      updatedAt: any
      archivedAt?: any | null
      status?: ActivityStatus | null
      author: string
      position: number
      type: ActivityType
      project: {
        __typename?: 'Project'
        id: string
        activities: Array<
          | { __typename?: 'Artwork'; id: string }
          | { __typename?: 'Brief'; id: string }
          | { __typename?: 'DefaultActivity'; id: string }
          | { __typename?: 'Workshop'; id: string }
        >
        milestones: Array<{
          __typename?: 'Milestone'
          id: string
          activities: Array<
            | { __typename?: 'Artwork'; id: string }
            | { __typename?: 'Brief'; id: string }
            | { __typename?: 'DefaultActivity'; id: string }
            | { __typename?: 'Workshop'; id: string }
          >
        }>
      }
      milestone?: { __typename?: 'Milestone'; id: string } | null
      tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
      composedDocument?: {
        __typename?: 'File'
        id: string
        name: string
        type: string
        path: string
        getUrl: string
      } | null
      uploadedDocument?: {
        __typename?: 'File'
        id: string
        name: string
        type: string
        path: string
        getUrl: string
      } | null
    }
  }
}

export type CreateAssetMutationVariables = Exact<{
  input: CreateFormAssetInput
}>

export type CreateAssetMutation = {
  __typename?: 'Mutation'
  createFormAsset: {
    __typename?: 'CreateFormAssetResponse'
    form: {
      __typename?: 'Form'
      id: string
      name: string
      type: string
      template: { __typename?: 'FormTemplate'; id: string; name: string }
    }
  }
}

export type CreateBriefMutationVariables = Exact<{
  input: CreateBriefResolverInput
}>

export type CreateBriefMutation = {
  __typename?: 'Mutation'
  createBrief: {
    __typename?: 'CreateBriefResponse'
    activity: {
      __typename?: 'Brief'
      id: string
      name: string
      viewedAt?: any | null
      updatedAt: any
      archivedAt?: any | null
      status?: ActivityStatus | null
      author: string
      position: number
      type: ActivityType
      project: {
        __typename?: 'Project'
        id: string
        activities: Array<
          | { __typename?: 'Artwork'; id: string }
          | { __typename?: 'Brief'; id: string }
          | { __typename?: 'DefaultActivity'; id: string }
          | { __typename?: 'Workshop'; id: string }
        >
        milestones: Array<{
          __typename?: 'Milestone'
          id: string
          activities: Array<
            | { __typename?: 'Artwork'; id: string }
            | { __typename?: 'Brief'; id: string }
            | { __typename?: 'DefaultActivity'; id: string }
            | { __typename?: 'Workshop'; id: string }
          >
        }>
      }
      milestone?: { __typename?: 'Milestone'; id: string } | null
      tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
      composedDocument?: {
        __typename?: 'File'
        id: string
        name: string
        type: string
        path: string
        getUrl: string
      } | null
      uploadedDocument?: {
        __typename?: 'File'
        id: string
        name: string
        type: string
        path: string
        getUrl: string
      } | null
    }
  }
}

export type CreateBriefNoMilestonesMutationVariables = Exact<{
  input: CreateBriefResolverInput
}>

export type CreateBriefNoMilestonesMutation = {
  __typename?: 'Mutation'
  createBrief: {
    __typename?: 'CreateBriefResponse'
    activity: { __typename?: 'Brief'; id: string }
  }
}

export type CreateDefaultActivityMutationVariables = Exact<{
  input: CreateDefaultActivityInput
}>

export type CreateDefaultActivityMutation = {
  __typename?: 'Mutation'
  createDefaultActivity: {
    __typename?: 'CreateDefaultActivityResponse'
    activity: {
      __typename?: 'DefaultActivity'
      id: string
      name: string
      viewedAt?: any | null
      updatedAt: any
      archivedAt?: any | null
      status?: ActivityStatus | null
      author: string
      position: number
      type: ActivityType
      project: {
        __typename?: 'Project'
        id: string
        activities: Array<
          | { __typename?: 'Artwork'; id: string }
          | { __typename?: 'Brief'; id: string }
          | { __typename?: 'DefaultActivity'; id: string }
          | { __typename?: 'Workshop'; id: string }
        >
        milestones: Array<{
          __typename?: 'Milestone'
          id: string
          activities: Array<
            | { __typename?: 'Artwork'; id: string }
            | { __typename?: 'Brief'; id: string }
            | { __typename?: 'DefaultActivity'; id: string }
            | { __typename?: 'Workshop'; id: string }
          >
        }>
      }
      milestone?: { __typename?: 'Milestone'; id: string } | null
      tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
      composedDocument?: {
        __typename?: 'File'
        id: string
        name: string
        type: string
        path: string
        getUrl: string
      } | null
      uploadedDocument?: {
        __typename?: 'File'
        id: string
        name: string
        type: string
        path: string
        getUrl: string
      } | null
    }
  }
}

export type CreateFeedbackMessageMutationVariables = Exact<{
  input: AddFeedbackInput
}>

export type CreateFeedbackMessageMutation = {
  __typename?: 'Mutation'
  addFeedback: {
    __typename?: 'AddFeedbackResponse'
    feedbackMessageId: string
    message: { __typename?: 'Message'; id: string; content: string }
  }
}

export type CreateOrganizationMutationVariables = Exact<{
  input: CreateOrganizationInput
}>

export type CreateOrganizationMutation = {
  __typename?: 'Mutation'
  createOrganization: {
    __typename?: 'CreateOrganizationResponse'
    organization: {
      __typename?: 'Organization'
      id: string
      name: string
      range?: string | null
      type?: OrganizationType | null
      members: Array<{
        __typename?: 'OrganizationMember'
        role: OrganizationRole
        createdAt: any
        user: {
          __typename?: 'User'
          name?: string | null
          email?: string | null
          avatarUrl: string
          id: string
        }
      }>
    }
  }
}

export type CreateProjectMutationVariables = Exact<{
  input: CreateProjectInput
}>

export type CreateProjectMutation = {
  __typename?: 'Mutation'
  createProject: {
    __typename?: 'CreateProjectResponse'
    project: {
      __typename?: 'Project'
      dueDate?: any | null
      archivedAt?: any | null
      id: string
      name: string
      color: string
      favorite: boolean
      members: Array<{
        __typename?: 'ProjectMember'
        id: string
        role: ProjectRole
        user: {
          __typename?: 'User'
          id: string
          name?: string | null
          email?: string | null
          avatarUrl: string
        }
      }>
      client?: { __typename?: 'Client'; name: string } | null
      milestones: Array<{
        __typename?: 'Milestone'
        id: string
        name: string
        archivedAt?: any | null
        activities: Array<
          | {
              __typename?: 'Artwork'
              id: string
              name: string
              viewedAt?: any | null
              updatedAt: any
              archivedAt?: any | null
              status?: ActivityStatus | null
              author: string
              position: number
              type: ActivityType
              milestone?: { __typename?: 'Milestone'; id: string } | null
              tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
              composedDocument?: {
                __typename?: 'File'
                id: string
                name: string
                type: string
                path: string
                getUrl: string
              } | null
              uploadedDocument?: {
                __typename?: 'File'
                id: string
                name: string
                type: string
                path: string
                getUrl: string
              } | null
            }
          | {
              __typename?: 'Brief'
              id: string
              name: string
              viewedAt?: any | null
              updatedAt: any
              archivedAt?: any | null
              status?: ActivityStatus | null
              author: string
              position: number
              type: ActivityType
              milestone?: { __typename?: 'Milestone'; id: string } | null
              tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
              composedDocument?: {
                __typename?: 'File'
                id: string
                name: string
                type: string
                path: string
                getUrl: string
              } | null
              uploadedDocument?: {
                __typename?: 'File'
                id: string
                name: string
                type: string
                path: string
                getUrl: string
              } | null
            }
          | {
              __typename?: 'DefaultActivity'
              id: string
              name: string
              viewedAt?: any | null
              updatedAt: any
              archivedAt?: any | null
              status?: ActivityStatus | null
              author: string
              position: number
              type: ActivityType
              milestone?: { __typename?: 'Milestone'; id: string } | null
              tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
              composedDocument?: {
                __typename?: 'File'
                id: string
                name: string
                type: string
                path: string
                getUrl: string
              } | null
              uploadedDocument?: {
                __typename?: 'File'
                id: string
                name: string
                type: string
                path: string
                getUrl: string
              } | null
            }
          | {
              __typename?: 'Workshop'
              id: string
              name: string
              viewedAt?: any | null
              updatedAt: any
              archivedAt?: any | null
              status?: ActivityStatus | null
              author: string
              position: number
              type: ActivityType
              milestone?: { __typename?: 'Milestone'; id: string } | null
              tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
              composedDocument?: {
                __typename?: 'File'
                id: string
                name: string
                type: string
                path: string
                getUrl: string
              } | null
              uploadedDocument?: {
                __typename?: 'File'
                id: string
                name: string
                type: string
                path: string
                getUrl: string
              } | null
            }
        >
      }>
      activities: Array<
        | {
            __typename?: 'Artwork'
            id: string
            name: string
            viewedAt?: any | null
            updatedAt: any
            archivedAt?: any | null
            status?: ActivityStatus | null
            author: string
            position: number
            type: ActivityType
            milestone?: { __typename?: 'Milestone'; id: string } | null
            tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
            composedDocument?: {
              __typename?: 'File'
              id: string
              name: string
              type: string
              path: string
              getUrl: string
            } | null
            uploadedDocument?: {
              __typename?: 'File'
              id: string
              name: string
              type: string
              path: string
              getUrl: string
            } | null
          }
        | {
            __typename?: 'Brief'
            id: string
            name: string
            viewedAt?: any | null
            updatedAt: any
            archivedAt?: any | null
            status?: ActivityStatus | null
            author: string
            position: number
            type: ActivityType
            milestone?: { __typename?: 'Milestone'; id: string } | null
            tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
            composedDocument?: {
              __typename?: 'File'
              id: string
              name: string
              type: string
              path: string
              getUrl: string
            } | null
            uploadedDocument?: {
              __typename?: 'File'
              id: string
              name: string
              type: string
              path: string
              getUrl: string
            } | null
          }
        | {
            __typename?: 'DefaultActivity'
            id: string
            name: string
            viewedAt?: any | null
            updatedAt: any
            archivedAt?: any | null
            status?: ActivityStatus | null
            author: string
            position: number
            type: ActivityType
            milestone?: { __typename?: 'Milestone'; id: string } | null
            tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
            composedDocument?: {
              __typename?: 'File'
              id: string
              name: string
              type: string
              path: string
              getUrl: string
            } | null
            uploadedDocument?: {
              __typename?: 'File'
              id: string
              name: string
              type: string
              path: string
              getUrl: string
            } | null
          }
        | {
            __typename?: 'Workshop'
            id: string
            name: string
            viewedAt?: any | null
            updatedAt: any
            archivedAt?: any | null
            status?: ActivityStatus | null
            author: string
            position: number
            type: ActivityType
            milestone?: { __typename?: 'Milestone'; id: string } | null
            tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
            composedDocument?: {
              __typename?: 'File'
              id: string
              name: string
              type: string
              path: string
              getUrl: string
            } | null
            uploadedDocument?: {
              __typename?: 'File'
              id: string
              name: string
              type: string
              path: string
              getUrl: string
            } | null
          }
      >
      organization: { __typename?: 'Organization'; id: string; name: string }
    }
  }
}

export type CreateReplyFeedbackMessageMutationVariables = Exact<{
  input: AddReplyFeedbackMessageInput
}>

export type CreateReplyFeedbackMessageMutation = {
  __typename?: 'Mutation'
  addReplyFeedbackMessage: {
    __typename?: 'AddReplyFeedbackMessageResponse'
    childFeedbackMessageId: string
    replyedUserId?: string | null
    message: { __typename?: 'Message'; id: string; content: string; createdAt: any }
  }
}

export type CreateTaskMutationVariables = Exact<{
  input: CreateTaskInput
}>

export type CreateTaskMutation = {
  __typename?: 'Mutation'
  createTask: {
    __typename?: 'CreateTaskResponse'
    task: {
      __typename?: 'Task'
      id: string
      name: string
      status: TaskStatus
      description: string
      dueDate?: any | null
      completedAt?: any | null
      archivedAt?: any | null
      checklist: Array<{
        __typename?: 'ChecklistItem'
        id: string
        name: string
        completedAt?: any | null
      }>
      project?: {
        __typename?: 'Project'
        id: string
        name: string
        client?: { __typename?: 'Client'; name: string } | null
      } | null
      assignedTo: Array<{
        __typename?: 'User'
        id: string
        name?: string | null
        avatarUrl: string
      }>
    }
  }
}

export type DeleteAssetMutationVariables = Exact<{
  input: Scalars['String']
}>

export type DeleteAssetMutation = {
  __typename?: 'Mutation'
  deleteAsset: { __typename?: 'DeleteResponse'; isDeleted: boolean }
}

export type DeleteBriefMutationVariables = Exact<{
  id: Scalars['String']
}>

export type DeleteBriefMutation = {
  __typename?: 'Mutation'
  deleteBrief: { __typename?: 'DeleteResponse'; isDeleted: boolean }
}

export type DeleteDeliverablesMutationVariables = Exact<{
  ids: Array<Scalars['String']> | Scalars['String']
}>

export type DeleteDeliverablesMutation = {
  __typename?: 'Mutation'
  deleteDeliverables: { __typename?: 'DeleteResponse'; isDeleted: boolean }
}

export type DuplicateProjectMutationVariables = Exact<{
  input: DuplicateProjectInput
}>

export type DuplicateProjectMutation = {
  __typename?: 'Mutation'
  duplicateProject: {
    __typename?: 'DuplicateProjectResponse'
    project: { __typename?: 'Project'; id: string }
  }
}

export type GetFileLinkByIdMutationVariables = Exact<{
  id: Scalars['String']
}>

export type GetFileLinkByIdMutation = { __typename?: 'Mutation'; getObjectUrl: string }

export type GetObjectUrlMutationVariables = Exact<{
  file: FileInput
}>

export type GetObjectUrlMutation = { __typename?: 'Mutation'; getObjectUrl: string }

export type InviteMemberToOrganizationMutationVariables = Exact<{
  input: InviteMemberToOrganizationInput
}>

export type InviteMemberToOrganizationMutation = {
  __typename?: 'Mutation'
  inviteMember: { __typename?: 'InviteOrganizationMemberResponse'; isInvited: boolean }
}

export type RemoveActivityAssetMutationVariables = Exact<{
  input: DeleteArtworkAssetInput
}>

export type RemoveActivityAssetMutation = {
  __typename?: 'Mutation'
  deleteArtworkAsset: { __typename?: 'DeleteResponse'; isDeleted: boolean }
}

export type RemoveChecklistItemsMutationVariables = Exact<{
  input: RemoveChecklistItemsInput
}>

export type RemoveChecklistItemsMutation = {
  __typename?: 'Mutation'
  removeChecklistItems: { __typename?: 'DeleteResponse'; isDeleted: boolean }
}

export type RemoveComposedDocumentMutationVariables = Exact<{
  input: RemoveComposeDocumentInput
}>

export type RemoveComposedDocumentMutation = {
  __typename?: 'Mutation'
  removeComposedDocument: { __typename?: 'DeleteResponse'; isDeleted: boolean }
}

export type RemoveFeedbackMemberMutationVariables = Exact<{
  input: FeedbackMemberInput
}>

export type RemoveFeedbackMemberMutation = {
  __typename?: 'Mutation'
  removeMemberFromFeedbackMessage: { __typename?: 'DeleteResponse'; isDeleted: boolean }
}

export type RemoveFeedbackMessageMutationVariables = Exact<{
  input: RemoveFeedbackMessageResolverInput
}>

export type RemoveFeedbackMessageMutation = {
  __typename?: 'Mutation'
  removeFeedbackMessage: { __typename?: 'RemoveFeedbackMessageResponse'; isDelete: boolean }
}

export type RemoveOrganizationMemeberMutationVariables = Exact<{
  input: RemoveMemberFromOrganization
}>

export type RemoveOrganizationMemeberMutation = {
  __typename?: 'Mutation'
  removeMemberFromOrganization: { __typename?: 'DeleteResponse'; isDeleted: boolean }
}

export type RemoveUploadedDocumentMutationVariables = Exact<{
  input: RemoveUploadDocumentInput
}>

export type RemoveUploadedDocumentMutation = {
  __typename?: 'Mutation'
  removeUploadedDocument: { __typename?: 'DeleteResponse'; isDeleted: boolean }
}

export type ReorderFileVersionsMutationVariables = Exact<{
  input: ReorderFileVersionInput
}>

export type ReorderFileVersionsMutation = { __typename?: 'Mutation'; reorderFileVersions: boolean }

export type SaveAssetMutationVariables = Exact<{
  input: SaveAssetInput
}>

export type SaveAssetMutation = {
  __typename?: 'Mutation'
  saveAsset: {
    __typename?: 'SaveAssetResponse'
    id: string
    name: string
    url: string
    path: string
    file: {
      __typename?: 'File'
      getUrl: string
      id: string
      name: string
      path: string
      type: string
    }
  }
}

export type SendApproveReminderMutationVariables = Exact<{
  input: ReminderNotificationInput
}>

export type SendApproveReminderMutation = {
  __typename?: 'Mutation'
  reminderNotification: {
    __typename?: 'NotificationTypesResponse'
    notification: { __typename?: 'NotificationGeneralType'; id: string }
  }
}

export type SentNotificationMutationVariables = Exact<{
  input: SentNotificationInput
}>

export type SentNotificationMutation = {
  __typename?: 'Mutation'
  sentNotification: {
    __typename?: 'NotificationTypesResponse'
    notification: { __typename?: 'NotificationGeneralType'; id: string }
  }
}

export type SetAllNotificationAsReadMutationVariables = Exact<{
  input: Scalars['String']
}>

export type SetAllNotificationAsReadMutation = {
  __typename?: 'Mutation'
  makeAllNotificationsRead: boolean
}

export type SetNotificationAsReadMutationVariables = Exact<{
  input: ToggleMembersStatusInput
}>

export type SetNotificationAsReadMutation = {
  __typename?: 'Mutation'
  toggleNotificationStatus: Array<boolean>
}

export type UpdateActivityMutationVariables = Exact<{
  input: UpdateActivityInput
}>

export type UpdateActivityMutation = {
  __typename?: 'Mutation'
  updateActivity: {
    __typename?: 'UpdateActivityResponse'
    updatedActivities: Array<
      | {
          __typename?: 'Artwork'
          id: string
          name: string
          viewedAt?: any | null
          updatedAt: any
          archivedAt?: any | null
          status?: ActivityStatus | null
          author: string
          position: number
          type: ActivityType
          milestone?: { __typename?: 'Milestone'; id: string } | null
          tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
          composedDocument?: {
            __typename?: 'File'
            id: string
            name: string
            type: string
            path: string
            getUrl: string
          } | null
          uploadedDocument?: {
            __typename?: 'File'
            id: string
            name: string
            type: string
            path: string
            getUrl: string
          } | null
        }
      | {
          __typename?: 'Brief'
          id: string
          name: string
          viewedAt?: any | null
          updatedAt: any
          archivedAt?: any | null
          status?: ActivityStatus | null
          author: string
          position: number
          type: ActivityType
          milestone?: { __typename?: 'Milestone'; id: string } | null
          tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
          composedDocument?: {
            __typename?: 'File'
            id: string
            name: string
            type: string
            path: string
            getUrl: string
          } | null
          uploadedDocument?: {
            __typename?: 'File'
            id: string
            name: string
            type: string
            path: string
            getUrl: string
          } | null
        }
      | {
          __typename?: 'DefaultActivity'
          id: string
          name: string
          viewedAt?: any | null
          updatedAt: any
          archivedAt?: any | null
          status?: ActivityStatus | null
          author: string
          position: number
          type: ActivityType
          milestone?: { __typename?: 'Milestone'; id: string } | null
          tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
          composedDocument?: {
            __typename?: 'File'
            id: string
            name: string
            type: string
            path: string
            getUrl: string
          } | null
          uploadedDocument?: {
            __typename?: 'File'
            id: string
            name: string
            type: string
            path: string
            getUrl: string
          } | null
        }
      | {
          __typename?: 'Workshop'
          id: string
          name: string
          viewedAt?: any | null
          updatedAt: any
          archivedAt?: any | null
          status?: ActivityStatus | null
          author: string
          position: number
          type: ActivityType
          milestone?: { __typename?: 'Milestone'; id: string } | null
          tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
          composedDocument?: {
            __typename?: 'File'
            id: string
            name: string
            type: string
            path: string
            getUrl: string
          } | null
          uploadedDocument?: {
            __typename?: 'File'
            id: string
            name: string
            type: string
            path: string
            getUrl: string
          } | null
        }
    >
  }
}

export type UpdateApprovalMutationVariables = Exact<{
  input: ApprovalResolverInput
}>

export type UpdateApprovalMutation = {
  __typename?: 'Mutation'
  updateApproval: Array<{ __typename?: 'Approval'; userId: string; status?: ApprovalStatus | null }>
}

export type UpdateBriefMutationVariables = Exact<{
  input: UpdateBriefInput
}>

export type UpdateBriefMutation = {
  __typename?: 'Mutation'
  updateBrief:
    | {
        __typename?: 'Artwork'
        id: string
        name: string
        viewedAt?: any | null
        updatedAt: any
        archivedAt?: any | null
        status?: ActivityStatus | null
        author: string
        position: number
        type: ActivityType
        deliverables?: Array<{
          __typename?: 'DeliverableMainType'
          id: string
          name: string
        }> | null
        milestone?: { __typename?: 'Milestone'; id: string } | null
        tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
        composedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
        uploadedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
      }
    | {
        __typename?: 'Brief'
        id: string
        name: string
        viewedAt?: any | null
        updatedAt: any
        archivedAt?: any | null
        status?: ActivityStatus | null
        author: string
        position: number
        type: ActivityType
        deliverables?: Array<{
          __typename?: 'DeliverableMainType'
          id: string
          name: string
        }> | null
        milestone?: { __typename?: 'Milestone'; id: string } | null
        tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
        composedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
        uploadedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
      }
    | {
        __typename?: 'DefaultActivity'
        id: string
        name: string
        viewedAt?: any | null
        updatedAt: any
        archivedAt?: any | null
        status?: ActivityStatus | null
        author: string
        position: number
        type: ActivityType
        deliverables?: Array<{
          __typename?: 'DeliverableMainType'
          id: string
          name: string
        }> | null
        milestone?: { __typename?: 'Milestone'; id: string } | null
        tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
        composedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
        uploadedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
      }
    | {
        __typename?: 'Workshop'
        id: string
        name: string
        viewedAt?: any | null
        updatedAt: any
        archivedAt?: any | null
        status?: ActivityStatus | null
        author: string
        position: number
        type: ActivityType
        deliverables?: Array<{
          __typename?: 'DeliverableMainType'
          id: string
          name: string
        }> | null
        milestone?: { __typename?: 'Milestone'; id: string } | null
        tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
        composedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
        uploadedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
      }
}

export type UpdateFeedbackMessageMutationVariables = Exact<{
  input: UpdateFeedbackMessageResolverInput
}>

export type UpdateFeedbackMessageMutation = {
  __typename?: 'Mutation'
  updateFeedbackMessage: boolean
}

export type UpdateOrganizationMutationVariables = Exact<{
  input: UpdateOrganizationInput
}>

export type UpdateOrganizationMutation = {
  __typename?: 'Mutation'
  updateOrganization: {
    __typename?: 'UpdateOrganizationResponse'
    organization: {
      __typename?: 'Organization'
      id: string
      name: string
      range?: string | null
      type?: OrganizationType | null
      members: Array<{
        __typename?: 'OrganizationMember'
        role: OrganizationRole
        createdAt: any
        user: {
          __typename?: 'User'
          name?: string | null
          email?: string | null
          avatarUrl: string
          id: string
        }
      }>
    }
  }
}

export type UpdateOrganizationMemberRoleMutationVariables = Exact<{
  input: UpdateOrganizationMemberInput
}>

export type UpdateOrganizationMemberRoleMutation = {
  __typename?: 'Mutation'
  updateOrganizationMemberRole: {
    __typename?: 'UpdateOrganizationMemberResponse'
    isSuccess: boolean
    updatedRole: OrganizationRole
  }
}

export type UpdateProjectMutationVariables = Exact<{
  input: UpdateProjectInput
}>

export type UpdateProjectMutation = {
  __typename?: 'Mutation'
  updateProject: {
    __typename?: 'UpdateProjectResponse'
    project: {
      __typename?: 'Project'
      name: string
      dueDate?: any | null
      client?: { __typename?: 'Client'; name: string } | null
      members: Array<{ __typename?: 'ProjectMember'; id: string; role: ProjectRole }>
    }
  }
}

export type UpdateTaskMutationVariables = Exact<{
  input: UpdateTaskInput
}>

export type UpdateTaskMutation = {
  __typename?: 'Mutation'
  updateTask: {
    __typename?: 'UpdateTaskResponse'
    task: {
      __typename?: 'Task'
      id: string
      name: string
      status: TaskStatus
      description: string
      dueDate?: any | null
      completedAt?: any | null
      archivedAt?: any | null
      rowVersion?: number | null
      checklist: Array<{
        __typename?: 'ChecklistItem'
        id: string
        name: string
        completedAt?: any | null
      }>
      project?: {
        __typename?: 'Project'
        id: string
        name: string
        color: string
        client?: { __typename?: 'Client'; name: string } | null
      } | null
      assignedTo: Array<{
        __typename?: 'User'
        id: string
        name?: string | null
        avatarUrl: string
      }>
    }
  }
}

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput
}>

export type UpdateUserMutation = {
  __typename?: 'Mutation'
  updateUser: {
    __typename?: 'UpdateUserResponse'
    user: {
      __typename?: 'User'
      id: string
      name?: string | null
      email?: string | null
      avatarUrl: string
      position?: string | null
      isPostSignUpFormCompleted?: boolean | null
    }
  }
}

export type UploadAssetsMutationVariables = Exact<{
  input: Array<AssetUploadInput> | AssetUploadInput
}>

export type UploadAssetsMutation = {
  __typename?: 'Mutation'
  uploadAssets: Array<{
    __typename?: 'AssetUploadResponse'
    name: string
    url: string
    path: string
  }>
}

export type UploadPrivateFileMutationVariables = Exact<{
  input: FileUploadInput
}>

export type UploadPrivateFileMutation = {
  __typename?: 'Mutation'
  uploadPrivateFile: {
    __typename?: 'FileUploadResponse'
    url: string
    file: { __typename?: 'File'; id: string; name: string; type: string; getUrl: string }
  }
}

export type BoardTempMutationVariables = Exact<{
  archived?: InputMaybe<Scalars['Boolean']>
  id: Scalars['ID']
}>

export type BoardTempMutation = {
  __typename?: 'Mutation'
  updateActivity: {
    __typename?: 'UpdateActivityResponse'
    activity:
      | {
          __typename?: 'Artwork'
          milestone?: { __typename?: 'Milestone'; name: string; id: string } | null
        }
      | {
          __typename?: 'Brief'
          milestone?: { __typename?: 'Milestone'; name: string; id: string } | null
        }
      | {
          __typename?: 'DefaultActivity'
          milestone?: { __typename?: 'Milestone'; name: string; id: string } | null
        }
      | {
          __typename?: 'Workshop'
          milestone?: { __typename?: 'Milestone'; name: string; id: string } | null
        }
  }
}

export type CreateMilestonesMutationVariables = Exact<{
  name: Scalars['String']
  id: Scalars['ID']
}>

export type CreateMilestonesMutation = {
  __typename?: 'Mutation'
  createMilestone: {
    __typename?: 'CreateMilestoneResponse'
    milestone: {
      __typename?: 'Milestone'
      id: string
      name: string
      archivedAt?: any | null
      project: { __typename?: 'Project'; id: string }
      activities: Array<
        | { __typename?: 'Artwork'; id: string }
        | { __typename?: 'Brief'; id: string }
        | { __typename?: 'DefaultActivity'; id: string }
        | { __typename?: 'Workshop'; id: string }
      >
    }
  }
}

export type CreateWorkshopMutationVariables = Exact<{
  input: CreateWorkshopInput
}>

export type CreateWorkshopMutation = {
  __typename?: 'Mutation'
  createWorkshop: {
    __typename?: 'CreateWorkshopResponse'
    activity: {
      __typename?: 'Workshop'
      id: string
      name: string
      viewedAt?: any | null
      updatedAt: any
      archivedAt?: any | null
      status?: ActivityStatus | null
      author: string
      position: number
      type: ActivityType
      project: {
        __typename?: 'Project'
        id: string
        activities: Array<
          | { __typename?: 'Artwork'; id: string }
          | { __typename?: 'Brief'; id: string }
          | { __typename?: 'DefaultActivity'; id: string }
          | { __typename?: 'Workshop'; id: string }
        >
        milestones: Array<{
          __typename?: 'Milestone'
          id: string
          activities: Array<
            | { __typename?: 'Artwork'; id: string }
            | { __typename?: 'Brief'; id: string }
            | { __typename?: 'DefaultActivity'; id: string }
            | { __typename?: 'Workshop'; id: string }
          >
        }>
      }
      milestone?: { __typename?: 'Milestone'; id: string } | null
      tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
      composedDocument?: {
        __typename?: 'File'
        id: string
        name: string
        type: string
        path: string
        getUrl: string
      } | null
      uploadedDocument?: {
        __typename?: 'File'
        id: string
        name: string
        type: string
        path: string
        getUrl: string
      } | null
    }
  }
}

export type UpdateActivityComposeDocumentMutationVariables = Exact<{
  input: UpdateComposedDocumentInput
}>

export type UpdateActivityComposeDocumentMutation = {
  __typename?: 'Mutation'
  updateActivityComposeDocument: {
    __typename?: 'ActivityUpdateDocumentsResponse'
    url: string
    activity:
      | {
          __typename?: 'Artwork'
          composedDocument?: {
            __typename?: 'File'
            id: string
            name: string
            getUrl: string
          } | null
        }
      | {
          __typename?: 'Brief'
          composedDocument?: {
            __typename?: 'File'
            id: string
            name: string
            getUrl: string
          } | null
        }
      | {
          __typename?: 'DefaultActivity'
          composedDocument?: {
            __typename?: 'File'
            id: string
            name: string
            getUrl: string
          } | null
        }
      | {
          __typename?: 'Workshop'
          composedDocument?: {
            __typename?: 'File'
            id: string
            name: string
            getUrl: string
          } | null
        }
  }
}

export type UpdateActivityUploadedDocumentMutationVariables = Exact<{
  input: UpdateUploadedDocumentInput
}>

export type UpdateActivityUploadedDocumentMutation = {
  __typename?: 'Mutation'
  updateActivityUploadedDocument: {
    __typename?: 'ActivityUpdateDocumentsResponse'
    url: string
    activity:
      | {
          __typename?: 'Artwork'
          uploadedDocument?: { __typename?: 'File'; id: string; name: string } | null
        }
      | {
          __typename?: 'Brief'
          uploadedDocument?: { __typename?: 'File'; id: string; name: string } | null
        }
      | {
          __typename?: 'DefaultActivity'
          uploadedDocument?: { __typename?: 'File'; id: string; name: string } | null
        }
      | {
          __typename?: 'Workshop'
          uploadedDocument?: { __typename?: 'File'; id: string; name: string } | null
        }
  }
}

export type UpdateBriefComposeDocumentMutationVariables = Exact<{
  input: UpdateComposedDocumentInput
}>

export type UpdateBriefComposeDocumentMutation = {
  __typename?: 'Mutation'
  updateBriefComposeDocument: {
    __typename?: 'BriefUpdateUploadedDocumentResponse'
    url: string
    brief:
      | {
          __typename?: 'Artwork'
          composedDocument?: { __typename?: 'File'; id: string; name: string } | null
        }
      | {
          __typename?: 'Brief'
          composedDocument?: { __typename?: 'File'; id: string; name: string } | null
        }
      | {
          __typename?: 'DefaultActivity'
          composedDocument?: { __typename?: 'File'; id: string; name: string } | null
        }
      | {
          __typename?: 'Workshop'
          composedDocument?: { __typename?: 'File'; id: string; name: string } | null
        }
  }
}

export type UpdateBriefUploadedDocumentMutationVariables = Exact<{
  input: UpdateUploadedDocumentInput
}>

export type UpdateBriefUploadedDocumentMutation = {
  __typename?: 'Mutation'
  updateBriefUploadedDocument: {
    __typename?: 'BriefUpdateUploadedDocumentResponse'
    url: string
    brief:
      | {
          __typename?: 'Artwork'
          uploadedDocument?: { __typename?: 'File'; id: string; name: string } | null
        }
      | {
          __typename?: 'Brief'
          uploadedDocument?: { __typename?: 'File'; id: string; name: string } | null
        }
      | {
          __typename?: 'DefaultActivity'
          uploadedDocument?: { __typename?: 'File'; id: string; name: string } | null
        }
      | {
          __typename?: 'Workshop'
          uploadedDocument?: { __typename?: 'File'; id: string; name: string } | null
        }
  }
}

export type UpdateMilestoneMutationVariables = Exact<{
  id: Scalars['ID']
  archive?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
}>

export type UpdateMilestoneMutation = {
  __typename?: 'Mutation'
  updateMilestone: {
    __typename?: 'UpdateMilestoneResponse'
    milestone: {
      __typename?: 'Milestone'
      id: string
      name: string
      archivedAt?: any | null
      activities: Array<
        | {
            __typename?: 'Artwork'
            id: string
            name: string
            viewedAt?: any | null
            updatedAt: any
            archivedAt?: any | null
            status?: ActivityStatus | null
            author: string
            position: number
            type: ActivityType
            milestone?: { __typename?: 'Milestone'; id: string } | null
            tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
            composedDocument?: {
              __typename?: 'File'
              id: string
              name: string
              type: string
              path: string
              getUrl: string
            } | null
            uploadedDocument?: {
              __typename?: 'File'
              id: string
              name: string
              type: string
              path: string
              getUrl: string
            } | null
          }
        | {
            __typename?: 'Brief'
            id: string
            name: string
            viewedAt?: any | null
            updatedAt: any
            archivedAt?: any | null
            status?: ActivityStatus | null
            author: string
            position: number
            type: ActivityType
            milestone?: { __typename?: 'Milestone'; id: string } | null
            tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
            composedDocument?: {
              __typename?: 'File'
              id: string
              name: string
              type: string
              path: string
              getUrl: string
            } | null
            uploadedDocument?: {
              __typename?: 'File'
              id: string
              name: string
              type: string
              path: string
              getUrl: string
            } | null
          }
        | {
            __typename?: 'DefaultActivity'
            id: string
            name: string
            viewedAt?: any | null
            updatedAt: any
            archivedAt?: any | null
            status?: ActivityStatus | null
            author: string
            position: number
            type: ActivityType
            milestone?: { __typename?: 'Milestone'; id: string } | null
            tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
            composedDocument?: {
              __typename?: 'File'
              id: string
              name: string
              type: string
              path: string
              getUrl: string
            } | null
            uploadedDocument?: {
              __typename?: 'File'
              id: string
              name: string
              type: string
              path: string
              getUrl: string
            } | null
          }
        | {
            __typename?: 'Workshop'
            id: string
            name: string
            viewedAt?: any | null
            updatedAt: any
            archivedAt?: any | null
            status?: ActivityStatus | null
            author: string
            position: number
            type: ActivityType
            milestone?: { __typename?: 'Milestone'; id: string } | null
            tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
            composedDocument?: {
              __typename?: 'File'
              id: string
              name: string
              type: string
              path: string
              getUrl: string
            } | null
            uploadedDocument?: {
              __typename?: 'File'
              id: string
              name: string
              type: string
              path: string
              getUrl: string
            } | null
          }
      >
    }
  }
}

export type ActivityTypesQueryVariables = Exact<{ [key: string]: never }>

export type ActivityTypesQuery = {
  __typename?: 'Query'
  activityTypes: Array<{
    __typename?: 'ActivityTypesResponse'
    name: string
    description: string
    id: string
  }>
}

export type AllActivitiesQueryVariables = Exact<{ [key: string]: never }>

export type AllActivitiesQuery = {
  __typename?: 'Query'
  activities: Array<
    | {
        __typename?: 'Artwork'
        createdAt?: any | null
        id: string
        name: string
        viewedAt?: any | null
        updatedAt: any
        archivedAt?: any | null
        status?: ActivityStatus | null
        author: string
        position: number
        type: ActivityType
        project: {
          __typename?: 'Project'
          id: string
          name: string
          color: string
          client?: { __typename?: 'Client'; name: string } | null
        }
        milestone?: { __typename?: 'Milestone'; id: string } | null
        tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
        composedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
        uploadedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
      }
    | {
        __typename?: 'Brief'
        createdAt?: any | null
        id: string
        name: string
        viewedAt?: any | null
        updatedAt: any
        archivedAt?: any | null
        status?: ActivityStatus | null
        author: string
        position: number
        type: ActivityType
        project: {
          __typename?: 'Project'
          id: string
          name: string
          color: string
          client?: { __typename?: 'Client'; name: string } | null
        }
        milestone?: { __typename?: 'Milestone'; id: string } | null
        tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
        composedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
        uploadedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
      }
    | {
        __typename?: 'DefaultActivity'
        createdAt?: any | null
        id: string
        name: string
        viewedAt?: any | null
        updatedAt: any
        archivedAt?: any | null
        status?: ActivityStatus | null
        author: string
        position: number
        type: ActivityType
        project: {
          __typename?: 'Project'
          id: string
          name: string
          color: string
          client?: { __typename?: 'Client'; name: string } | null
        }
        milestone?: { __typename?: 'Milestone'; id: string } | null
        tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
        composedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
        uploadedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
      }
    | {
        __typename?: 'Workshop'
        createdAt?: any | null
        id: string
        name: string
        viewedAt?: any | null
        updatedAt: any
        archivedAt?: any | null
        status?: ActivityStatus | null
        author: string
        position: number
        type: ActivityType
        project: {
          __typename?: 'Project'
          id: string
          name: string
          color: string
          client?: { __typename?: 'Client'; name: string } | null
        }
        milestone?: { __typename?: 'Milestone'; id: string } | null
        tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
        composedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
        uploadedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
      }
  >
}

export type AllTasksQueryVariables = Exact<{ [key: string]: never }>

export type AllTasksQuery = {
  __typename?: 'Query'
  tasks: Array<{
    __typename?: 'Task'
    description: string
    id: string
    name: string
    organizationId?: string | null
    userId?: string | null
    dueDate?: any | null
    completedAt?: any | null
    status: TaskStatus
    archivedAt?: any | null
    rowVersion?: number | null
    checklist: Array<{
      __typename?: 'ChecklistItem'
      id: string
      name: string
      completedAt?: any | null
    }>
    project?: {
      __typename?: 'Project'
      id: string
      name: string
      color: string
      favorite: boolean
      organization: { __typename?: 'Organization'; id: string; name: string }
      client?: { __typename?: 'Client'; name: string } | null
    } | null
    assignedTo: Array<{ __typename?: 'User'; id: string; name?: string | null; avatarUrl: string }>
  }>
}

export type AllUsersQueryVariables = Exact<{ [key: string]: never }>

export type AllUsersQuery = {
  __typename?: 'Query'
  users: Array<{
    __typename?: 'User'
    id: string
    name?: string | null
    email?: string | null
    avatarUrl: string
  }>
}

export type GetAnswersQueryVariables = Exact<{
  briefId: Scalars['String']
}>

export type GetAnswersQuery = {
  __typename?: 'Query'
  answers: {
    __typename?: 'AnswersResponses'
    mappedAnswer: Array<{
      __typename?: 'AnswersResponse'
      question: {
        __typename?: 'QuestionMain'
        id: string
        text: string
        questionTypeId: QuestionTypeId
        options?: Array<string> | null
        deliverableTypeId: string
        deletedAt?: any | null
        sliderProps?: {
          __typename?: 'SliderProps'
          minValue: number
          maxValue: number
          minLabel: string
          maxLabel: string
          showValue: boolean
        } | null
      }
      answer: {
        __typename?: 'AnswerMainType'
        id: string
        questionId: string
        userId?: string | null
        comment: {
          __typename?: 'AnswerCommentType'
          id: string
          content: string
          answerId?: string | null
          type: QuestionTypeId
        }
      }
    }>
    mappedDeliverables: Array<{
      __typename?: 'AnswerDeliverableType'
      id: string
      name: string
      data: Array<{
        __typename?: 'AnswerWithQuestion'
        question: {
          __typename?: 'QuestionMain'
          id: string
          text: string
          questionTypeId: QuestionTypeId
          options?: Array<string> | null
          deliverableTypeId: string
          deletedAt?: any | null
          sliderProps?: {
            __typename?: 'SliderProps'
            minValue: number
            maxValue: number
            minLabel: string
            maxLabel: string
            showValue: boolean
          } | null
        }
        comment: {
          __typename?: 'AnswerCommentType'
          content: string
          id: string
          type: QuestionTypeId
          answerId?: string | null
        }
      }>
    }>
  }
}

export type GetDeliverablesQueryVariables = Exact<{
  briefId: Scalars['String']
}>

export type GetDeliverablesQuery = {
  __typename?: 'Query'
  getDeliverables: Array<{
    __typename?: 'DeliverableMainType'
    id: string
    name: string
    relationId: string
  }>
}

export type DeliverableTypesQueryVariables = Exact<{ [key: string]: never }>

export type DeliverableTypesQuery = {
  __typename?: 'Query'
  deliverableTypes: Array<{
    __typename?: 'DeliverableType'
    id: string
    name: string
    category: { __typename?: 'DeliverableCategory'; name: string }
    template: { __typename?: 'FormTemplate'; id: string; name: string }
  }>
}

export type DeliverablesQuestionQueryVariables = Exact<{
  deliverables: Array<Scalars['String']> | Scalars['String']
}>

export type DeliverablesQuestionQuery = {
  __typename?: 'Query'
  deliverablesQuestions: Array<{
    __typename?: 'QuestionResponseMain'
    deliverable: string
    questions: Array<{
      __typename?: 'QuestionMain'
      id: string
      text: string
      questionTypeId: QuestionTypeId
      options?: Array<string> | null
      deliverableTypeId: string
      deletedAt?: any | null
      sliderProps?: {
        __typename?: 'SliderProps'
        minValue: number
        maxValue: number
        minLabel: string
        maxLabel: string
        showValue: boolean
      } | null
    }>
  }>
}

export type AllFeedbackQueryVariables = Exact<{ [key: string]: never }>

export type AllFeedbackQuery = {
  __typename?: 'Query'
  projects: Array<{
    __typename?: 'Project'
    activities: Array<
      | {
          __typename?: 'Artwork'
          feedback: {
            __typename?: 'Feedback'
            messages: Array<{ __typename?: 'Message'; content: string }>
          }
        }
      | {
          __typename?: 'Brief'
          feedback: {
            __typename?: 'Feedback'
            messages: Array<{ __typename?: 'Message'; content: string }>
          }
        }
      | {
          __typename?: 'DefaultActivity'
          feedback: {
            __typename?: 'Feedback'
            messages: Array<{ __typename?: 'Message'; content: string }>
          }
        }
      | {
          __typename?: 'Workshop'
          feedback: {
            __typename?: 'Feedback'
            messages: Array<{ __typename?: 'Message'; content: string }>
          }
        }
    >
  }>
}

export type GetActivityByIdQueryVariables = Exact<{
  input: Scalars['String']
}>

export type GetActivityByIdQuery = {
  __typename?: 'Query'
  getActivityById:
    | {
        __typename?: 'Artwork'
        feedback: { __typename?: 'Feedback'; id?: string | null }
        uploadedDocument?: { __typename?: 'File'; id: string; name: string } | null
        composedDocument?: { __typename?: 'File'; id: string; name: string } | null
      }
    | {
        __typename?: 'Brief'
        feedback: { __typename?: 'Feedback'; id?: string | null }
        uploadedDocument?: { __typename?: 'File'; id: string; name: string } | null
        composedDocument?: { __typename?: 'File'; id: string; name: string } | null
      }
    | {
        __typename?: 'DefaultActivity'
        feedback: { __typename?: 'Feedback'; id?: string | null }
        uploadedDocument?: { __typename?: 'File'; id: string; name: string } | null
        composedDocument?: { __typename?: 'File'; id: string; name: string } | null
      }
    | {
        __typename?: 'Workshop'
        feedback: { __typename?: 'Feedback'; id?: string | null }
        uploadedDocument?: { __typename?: 'File'; id: string; name: string } | null
        composedDocument?: { __typename?: 'File'; id: string; name: string } | null
      }
}

export type GetAllActivityAssetsQueryVariables = Exact<{
  activityId: Scalars['String']
}>

export type GetAllActivityAssetsQuery = {
  __typename?: 'Query'
  getAllActivityAssets: Array<{
    __typename?: 'GlobalAsset'
    fileId?: string | null
    file?: {
      __typename?: 'File'
      name: string
      type: string
      getUrl: string
      version?: number | null
    } | null
  }>
}

export type GetAllAssetsQueryVariables = Exact<{
  input: GetGlobalAssetsWhere
  page?: InputMaybe<Scalars['Int']>
  sortOrder?: InputMaybe<SortOrder>
  limit?: InputMaybe<Scalars['Int']>
}>

export type GetAllAssetsQuery = {
  __typename?: 'Query'
  getAllAssets: {
    __typename?: 'GlobalAssetsResponse'
    assets: Array<{
      __typename?: 'GlobalAsset'
      id: string
      fileId?: string | null
      organizationId?: string | null
      projectId?: string | null
      activityId?: string | null
      createdAt?: any | null
      updatedAt?: any | null
      project?: {
        __typename?: 'Project'
        name: string
        color: string
        client?: { __typename?: 'Client'; name: string } | null
      } | null
      organization?: { __typename?: 'Organization'; name: string } | null
      activity?:
        | { __typename?: 'Artwork'; name: string; type: ActivityType }
        | { __typename?: 'Brief'; name: string; type: ActivityType }
        | { __typename?: 'DefaultActivity'; name: string; type: ActivityType }
        | { __typename?: 'Workshop'; name: string; type: ActivityType }
        | null
      file?: {
        __typename?: 'File'
        id: string
        name: string
        type: string
        path: string
        getUrl: string
        version?: number | null
      } | null
    }>
    paginationData: {
      __typename?: 'Pagination'
      totalAmount: number
      perPage?: number | null
      totalPages: number
      currentPage: number
    }
  }
}

export type GetAllFeedbackMessagesQueryVariables = Exact<{
  input: GetFeedbackMessageWhere
}>

export type GetAllFeedbackMessagesQuery = {
  __typename?: 'Query'
  getFeedbackMessagesByFeedbackId: {
    __typename?: 'FeedbackMessageResponse'
    messages: Array<{
      __typename?: 'FeedbackMessage'
      feedbackMessageId?: string | null
      feedbackId?: string | null
      messageId?: string | null
      createdAt?: any | null
      userId?: string | null
      content?: string | null
      assetId?: string | null
      type?: FeedbackMessageType | null
      pinned?: boolean | null
      completedAt?: any | null
      position?: { __typename?: 'FeedbackPosition'; x: number; y: number; page: number } | null
      user?: {
        __typename?: 'User'
        id: string
        name?: string | null
        email?: string | null
        avatarUrl: string
      } | null
      members?: Array<{
        __typename?: 'User'
        id: string
        name?: string | null
        email?: string | null
        avatarUrl: string
      }> | null
      replyedComments: Array<{
        __typename?: 'FeedbackMessage'
        feedbackMessageId?: string | null
        feedbackId?: string | null
        messageId?: string | null
        createdAt?: any | null
        userId?: string | null
        content?: string | null
        assetId?: string | null
        type?: FeedbackMessageType | null
        pinned?: boolean | null
        completedAt?: any | null
        repliedUser?: {
          __typename?: 'User'
          id: string
          name?: string | null
          email?: string | null
          avatarUrl: string
        } | null
        user?: {
          __typename?: 'User'
          id: string
          name?: string | null
          email?: string | null
          avatarUrl: string
        } | null
        members?: Array<{
          __typename?: 'User'
          id: string
          name?: string | null
          email?: string | null
          avatarUrl: string
        }> | null
      }>
    }>
  }
}

export type GetAllNotificationsQueryVariables = Exact<{
  unreadArgs?: InputMaybe<GetNotificationInputArgs>
  readArgs?: InputMaybe<GetNotificationInputArgs>
}>

export type GetAllNotificationsQuery = {
  __typename?: 'Query'
  notifications: {
    __typename?: 'NotificationManyTypesResponse'
    unread: {
      __typename?: 'NotificationResponseWithPagination'
      pagination?: {
        __typename?: 'NullablePagination'
        currentPage?: number | null
        perPage?: number | null
        totalPages?: number | null
        totalAmount?: number | null
      } | null
      notifications: Array<{
        __typename?: 'NotificationResponse'
        isRead: boolean
        projectData: {
          __typename?: 'Project'
          id: string
          name: string
          color: string
          client?: { __typename?: 'Client'; name: string } | null
        }
        activityData:
          | { __typename?: 'Artwork'; id: string; name: string; type: ActivityType }
          | { __typename?: 'Brief'; id: string; name: string; type: ActivityType }
          | { __typename?: 'DefaultActivity'; id: string; name: string; type: ActivityType }
          | { __typename?: 'Workshop'; id: string; name: string; type: ActivityType }
        senderData: { __typename?: 'User'; avatarUrl: string; name?: string | null }
        membersData: Array<{
          __typename?: 'User'
          id: string
          name?: string | null
          avatarUrl: string
        }>
        notification: {
          __typename?: 'NotificationGeneralType'
          updatedAt: any
          isTechnical: boolean
          createdAt?: any | null
          id: string
          senderId: string
          notificationMessage: string
          personalizedMessage?: string | null
          purpose?: string | null
          notificationMembers?: Array<{
            __typename?: 'NotificationMemberResponse'
            id: string
            notificationId: string
            userId: string
            isRead?: boolean | null
          }> | null
        }
      }>
    }
    read: {
      __typename?: 'NotificationResponseWithPagination'
      pagination?: {
        __typename?: 'NullablePagination'
        currentPage?: number | null
        perPage?: number | null
        totalPages?: number | null
        totalAmount?: number | null
      } | null
      notifications: Array<{
        __typename?: 'NotificationResponse'
        isRead: boolean
        projectData: {
          __typename?: 'Project'
          id: string
          name: string
          color: string
          client?: { __typename?: 'Client'; name: string } | null
        }
        activityData:
          | { __typename?: 'Artwork'; id: string; name: string; type: ActivityType }
          | { __typename?: 'Brief'; id: string; name: string; type: ActivityType }
          | { __typename?: 'DefaultActivity'; id: string; name: string; type: ActivityType }
          | { __typename?: 'Workshop'; id: string; name: string; type: ActivityType }
        senderData: { __typename?: 'User'; avatarUrl: string; name?: string | null }
        membersData: Array<{
          __typename?: 'User'
          id: string
          name?: string | null
          avatarUrl: string
        }>
        notification: {
          __typename?: 'NotificationGeneralType'
          isTechnical: boolean
          createdAt?: any | null
          id: string
          senderId: string
          notificationMessage: string
          personalizedMessage?: string | null
          purpose?: string | null
          notificationMembers?: Array<{
            __typename?: 'NotificationMemberResponse'
            id: string
            notificationId: string
            userId: string
            isRead?: boolean | null
          }> | null
        }
      }>
    }
  }
}

export type GetArtworkFeedbackMessagesQueryVariables = Exact<{
  input: FeedbackMessageInput
}>

export type GetArtworkFeedbackMessagesQuery = {
  __typename?: 'Query'
  getArtworkFeedback: Array<{
    __typename?: 'FeedbackMessage'
    feedbackId?: string | null
    messageId?: string | null
    createdAt?: any | null
    userId?: string | null
    content?: string | null
    assetId?: string | null
    position?: { __typename?: 'FeedbackPosition'; x: number; y: number; page: number } | null
  }>
}

export type GetAssetByFileIdQueryVariables = Exact<{
  input: Scalars['String']
}>

export type GetAssetByFileIdQuery = {
  __typename?: 'Query'
  getAssetByFileId: {
    __typename?: 'AssetByFileIdResponse'
    asset?: {
      __typename?: 'GlobalAsset'
      id: string
      file?: {
        __typename?: 'File'
        id: string
        name: string
        type: string
        path: string
        getUrl: string
      } | null
    } | null
  }
}

export type GetBriefFeedbackIdQueryVariables = Exact<{
  projectId: Scalars['String']
}>

export type GetBriefFeedbackIdQuery = {
  __typename?: 'Query'
  getProjectBriefFeedbackId: {
    __typename?: 'GetBriefFeedbackResonse'
    feedbackId?: string | null
    briefId?: string | null
  }
}

export type GetCurrentActivityByIdQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetCurrentActivityByIdQuery = {
  __typename?: 'Query'
  activities: Array<
    | {
        __typename?: 'Artwork'
        id: string
        name: string
        author: string
        tasks: Array<{
          __typename?: 'Task'
          description: string
          id: string
          name: string
          organizationId?: string | null
          userId?: string | null
          dueDate?: any | null
          completedAt?: any | null
          status: TaskStatus
          archivedAt?: any | null
          rowVersion?: number | null
          checklist: Array<{
            __typename?: 'ChecklistItem'
            id: string
            name: string
            completedAt?: any | null
          }>
          project?: {
            __typename?: 'Project'
            id: string
            name: string
            color: string
            favorite: boolean
            organization: { __typename?: 'Organization'; id: string; name: string }
            client?: { __typename?: 'Client'; name: string } | null
          } | null
          assignedTo: Array<{
            __typename?: 'User'
            id: string
            name?: string | null
            avatarUrl: string
          }>
        }>
        feedback: { __typename?: 'Feedback'; id?: string | null }
        uploadedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          getUrl: string
        } | null
        composedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          getUrl: string
        } | null
      }
    | {
        __typename?: 'Brief'
        id: string
        name: string
        author: string
        tasks: Array<{
          __typename?: 'Task'
          description: string
          id: string
          name: string
          organizationId?: string | null
          userId?: string | null
          dueDate?: any | null
          completedAt?: any | null
          status: TaskStatus
          archivedAt?: any | null
          rowVersion?: number | null
          checklist: Array<{
            __typename?: 'ChecklistItem'
            id: string
            name: string
            completedAt?: any | null
          }>
          project?: {
            __typename?: 'Project'
            id: string
            name: string
            color: string
            favorite: boolean
            organization: { __typename?: 'Organization'; id: string; name: string }
            client?: { __typename?: 'Client'; name: string } | null
          } | null
          assignedTo: Array<{
            __typename?: 'User'
            id: string
            name?: string | null
            avatarUrl: string
          }>
        }>
        feedback: { __typename?: 'Feedback'; id?: string | null }
        uploadedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          getUrl: string
        } | null
        composedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          getUrl: string
        } | null
      }
    | {
        __typename?: 'DefaultActivity'
        id: string
        name: string
        author: string
        tasks: Array<{
          __typename?: 'Task'
          description: string
          id: string
          name: string
          organizationId?: string | null
          userId?: string | null
          dueDate?: any | null
          completedAt?: any | null
          status: TaskStatus
          archivedAt?: any | null
          rowVersion?: number | null
          checklist: Array<{
            __typename?: 'ChecklistItem'
            id: string
            name: string
            completedAt?: any | null
          }>
          project?: {
            __typename?: 'Project'
            id: string
            name: string
            color: string
            favorite: boolean
            organization: { __typename?: 'Organization'; id: string; name: string }
            client?: { __typename?: 'Client'; name: string } | null
          } | null
          assignedTo: Array<{
            __typename?: 'User'
            id: string
            name?: string | null
            avatarUrl: string
          }>
        }>
        feedback: { __typename?: 'Feedback'; id?: string | null }
        uploadedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          getUrl: string
        } | null
        composedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          getUrl: string
        } | null
      }
    | {
        __typename?: 'Workshop'
        id: string
        name: string
        author: string
        tasks: Array<{
          __typename?: 'Task'
          description: string
          id: string
          name: string
          organizationId?: string | null
          userId?: string | null
          dueDate?: any | null
          completedAt?: any | null
          status: TaskStatus
          archivedAt?: any | null
          rowVersion?: number | null
          checklist: Array<{
            __typename?: 'ChecklistItem'
            id: string
            name: string
            completedAt?: any | null
          }>
          project?: {
            __typename?: 'Project'
            id: string
            name: string
            color: string
            favorite: boolean
            organization: { __typename?: 'Organization'; id: string; name: string }
            client?: { __typename?: 'Client'; name: string } | null
          } | null
          assignedTo: Array<{
            __typename?: 'User'
            id: string
            name?: string | null
            avatarUrl: string
          }>
        }>
        feedback: { __typename?: 'Feedback'; id?: string | null }
        uploadedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          getUrl: string
        } | null
        composedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          getUrl: string
        } | null
      }
  >
}

export type GetOrganizationRangesQueryVariables = Exact<{ [key: string]: never }>

export type GetOrganizationRangesQuery = {
  __typename?: 'Query'
  organizationRanges: Array<{
    __typename?: 'OrganizationRangesResponse'
    id: string
    from: string
    to: string
  }>
}

export type GetProjectMembersByIdQueryVariables = Exact<{
  projectId: Scalars['String']
}>

export type GetProjectMembersByIdQuery = {
  __typename?: 'Query'
  getProjectMembersById: Array<{
    __typename?: 'ProjectMember'
    id: string
    role: ProjectRole
    user: { __typename?: 'User'; id: string; name?: string | null; avatarUrl: string }
  }>
}

export type IsUserExistQueryVariables = Exact<{
  input: UserExistInput
}>

export type IsUserExistQuery = {
  __typename?: 'Query'
  isUserExist: { __typename?: 'UserExistResponse'; isExist: boolean }
}

export type MatchForgotPasswordQueryVariables = Exact<{
  input: Scalars['String']
}>

export type MatchForgotPasswordQuery = {
  __typename?: 'Query'
  matchForgotPassword: { __typename?: 'ForgotTokenResponse'; code: string }
}

export type MeQueryVariables = Exact<{ [key: string]: never }>

export type MeQuery = {
  __typename?: 'Query'
  me?: {
    __typename?: 'User'
    id: string
    name?: string | null
    email?: string | null
    avatarUrl: string
    position?: string | null
    isPostSignUpFormCompleted?: boolean | null
  } | null
}

export type MeWithOrganizationQueryVariables = Exact<{ [key: string]: never }>

export type MeWithOrganizationQuery = {
  __typename?: 'Query'
  meWithOrganization?: {
    __typename?: 'UserWithOrganization'
    id: string
    name?: string | null
    email?: string | null
    avatarUrl: string
    position?: string | null
    isPostSignUpFormCompleted?: boolean | null
    organizationMember?: { __typename?: 'UserOrganization'; role?: string | null } | null
  } | null
}

export type OrganizationsQueryVariables = Exact<{ [key: string]: never }>

export type OrganizationsQuery = {
  __typename?: 'Query'
  organizations: Array<{
    __typename?: 'Organization'
    id: string
    name: string
    range?: string | null
    type?: OrganizationType | null
    members: Array<{
      __typename?: 'OrganizationMember'
      role: OrganizationRole
      createdAt: any
      user: {
        __typename?: 'User'
        name?: string | null
        email?: string | null
        avatarUrl: string
        id: string
      }
    }>
  }>
}

export type ProjectQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type ProjectQuery = {
  __typename?: 'Query'
  projects: Array<{
    __typename?: 'Project'
    dueDate?: any | null
    archivedAt?: any | null
    id: string
    name: string
    color: string
    favorite: boolean
    members: Array<{
      __typename?: 'ProjectMember'
      id: string
      role: ProjectRole
      user: {
        __typename?: 'User'
        id: string
        name?: string | null
        email?: string | null
        avatarUrl: string
      }
    }>
    client?: { __typename?: 'Client'; name: string } | null
    milestones: Array<{
      __typename?: 'Milestone'
      id: string
      name: string
      archivedAt?: any | null
      activities: Array<
        | {
            __typename?: 'Artwork'
            id: string
            name: string
            viewedAt?: any | null
            updatedAt: any
            archivedAt?: any | null
            status?: ActivityStatus | null
            author: string
            position: number
            type: ActivityType
            milestone?: { __typename?: 'Milestone'; id: string } | null
            tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
            composedDocument?: {
              __typename?: 'File'
              id: string
              name: string
              type: string
              path: string
              getUrl: string
            } | null
            uploadedDocument?: {
              __typename?: 'File'
              id: string
              name: string
              type: string
              path: string
              getUrl: string
            } | null
          }
        | {
            __typename?: 'Brief'
            id: string
            name: string
            viewedAt?: any | null
            updatedAt: any
            archivedAt?: any | null
            status?: ActivityStatus | null
            author: string
            position: number
            type: ActivityType
            milestone?: { __typename?: 'Milestone'; id: string } | null
            tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
            composedDocument?: {
              __typename?: 'File'
              id: string
              name: string
              type: string
              path: string
              getUrl: string
            } | null
            uploadedDocument?: {
              __typename?: 'File'
              id: string
              name: string
              type: string
              path: string
              getUrl: string
            } | null
          }
        | {
            __typename?: 'DefaultActivity'
            id: string
            name: string
            viewedAt?: any | null
            updatedAt: any
            archivedAt?: any | null
            status?: ActivityStatus | null
            author: string
            position: number
            type: ActivityType
            milestone?: { __typename?: 'Milestone'; id: string } | null
            tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
            composedDocument?: {
              __typename?: 'File'
              id: string
              name: string
              type: string
              path: string
              getUrl: string
            } | null
            uploadedDocument?: {
              __typename?: 'File'
              id: string
              name: string
              type: string
              path: string
              getUrl: string
            } | null
          }
        | {
            __typename?: 'Workshop'
            id: string
            name: string
            viewedAt?: any | null
            updatedAt: any
            archivedAt?: any | null
            status?: ActivityStatus | null
            author: string
            position: number
            type: ActivityType
            milestone?: { __typename?: 'Milestone'; id: string } | null
            tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
            composedDocument?: {
              __typename?: 'File'
              id: string
              name: string
              type: string
              path: string
              getUrl: string
            } | null
            uploadedDocument?: {
              __typename?: 'File'
              id: string
              name: string
              type: string
              path: string
              getUrl: string
            } | null
          }
      >
    }>
    activities: Array<
      | {
          __typename?: 'Artwork'
          id: string
          name: string
          viewedAt?: any | null
          updatedAt: any
          archivedAt?: any | null
          status?: ActivityStatus | null
          author: string
          position: number
          type: ActivityType
          milestone?: { __typename?: 'Milestone'; id: string } | null
          tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
          composedDocument?: {
            __typename?: 'File'
            id: string
            name: string
            type: string
            path: string
            getUrl: string
          } | null
          uploadedDocument?: {
            __typename?: 'File'
            id: string
            name: string
            type: string
            path: string
            getUrl: string
          } | null
        }
      | {
          __typename?: 'Brief'
          id: string
          name: string
          viewedAt?: any | null
          updatedAt: any
          archivedAt?: any | null
          status?: ActivityStatus | null
          author: string
          position: number
          type: ActivityType
          milestone?: { __typename?: 'Milestone'; id: string } | null
          tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
          composedDocument?: {
            __typename?: 'File'
            id: string
            name: string
            type: string
            path: string
            getUrl: string
          } | null
          uploadedDocument?: {
            __typename?: 'File'
            id: string
            name: string
            type: string
            path: string
            getUrl: string
          } | null
        }
      | {
          __typename?: 'DefaultActivity'
          id: string
          name: string
          viewedAt?: any | null
          updatedAt: any
          archivedAt?: any | null
          status?: ActivityStatus | null
          author: string
          position: number
          type: ActivityType
          milestone?: { __typename?: 'Milestone'; id: string } | null
          tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
          composedDocument?: {
            __typename?: 'File'
            id: string
            name: string
            type: string
            path: string
            getUrl: string
          } | null
          uploadedDocument?: {
            __typename?: 'File'
            id: string
            name: string
            type: string
            path: string
            getUrl: string
          } | null
        }
      | {
          __typename?: 'Workshop'
          id: string
          name: string
          viewedAt?: any | null
          updatedAt: any
          archivedAt?: any | null
          status?: ActivityStatus | null
          author: string
          position: number
          type: ActivityType
          milestone?: { __typename?: 'Milestone'; id: string } | null
          tasks: Array<{ __typename?: 'Task'; completedAt?: any | null }>
          composedDocument?: {
            __typename?: 'File'
            id: string
            name: string
            type: string
            path: string
            getUrl: string
          } | null
          uploadedDocument?: {
            __typename?: 'File'
            id: string
            name: string
            type: string
            path: string
            getUrl: string
          } | null
        }
    >
    organization: { __typename?: 'Organization'; id: string; name: string }
  }>
}

export type ProjectSummariesQueryVariables = Exact<{
  archivedType?: InputMaybe<Scalars['String']>
}>

export type ProjectSummariesQuery = {
  __typename?: 'Query'
  projects: Array<{
    __typename?: 'Project'
    dueDate?: any | null
    archivedAt?: any | null
    id: string
    name: string
    color: string
    favorite: boolean
    organization: { __typename?: 'Organization'; id: string; name: string }
    client?: { __typename?: 'Client'; name: string } | null
  }>
}

export type ProjectTasksQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type ProjectTasksQuery = {
  __typename?: 'Query'
  tasks: Array<{
    __typename?: 'Task'
    description: string
    id: string
    name: string
    organizationId?: string | null
    userId?: string | null
    dueDate?: any | null
    completedAt?: any | null
    status: TaskStatus
    archivedAt?: any | null
    rowVersion?: number | null
    checklist: Array<{
      __typename?: 'ChecklistItem'
      id: string
      name: string
      completedAt?: any | null
    }>
    project?: {
      __typename?: 'Project'
      id: string
      name: string
      color: string
      favorite: boolean
      organization: { __typename?: 'Organization'; id: string; name: string }
      client?: { __typename?: 'Client'; name: string } | null
    } | null
    assignedTo: Array<{ __typename?: 'User'; id: string; name?: string | null; avatarUrl: string }>
  }>
}

export type VerifyInviteTokenQueryVariables = Exact<{
  input: InviteMemberVerifyTokenInput
}>

export type VerifyInviteTokenQuery = {
  __typename?: 'Query'
  inviteMemberVerify: { __typename?: 'InviteMemberVerifyResponse'; payload: string }
}

export type GetApprovalsQueryVariables = Exact<{
  id: Scalars['String']
}>

export type GetApprovalsQuery = {
  __typename?: 'Query'
  approvals: {
    __typename?: 'ApprovalResponse'
    isApproved: boolean
    approve: Array<{ __typename?: 'Approval'; status?: ApprovalStatus | null; userId: string }>
  }
}

export type GetBriefQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>
}>

export type GetBriefQuery = {
  __typename?: 'Query'
  getBrief:
    | {
        __typename?: 'Artwork'
        id: string
        name: string
        feedback: { __typename?: 'Feedback'; id?: string | null }
        composedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
        uploadedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
      }
    | {
        __typename?: 'Brief'
        id: string
        name: string
        feedback: { __typename?: 'Feedback'; id?: string | null }
        composedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
        uploadedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
      }
    | {
        __typename?: 'DefaultActivity'
        id: string
        name: string
        feedback: { __typename?: 'Feedback'; id?: string | null }
        composedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
        uploadedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
      }
    | {
        __typename?: 'Workshop'
        id: string
        name: string
        feedback: { __typename?: 'Feedback'; id?: string | null }
        composedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
        uploadedDocument?: {
          __typename?: 'File'
          id: string
          name: string
          type: string
          path: string
          getUrl: string
        } | null
      }
}

export const ActivitySummaryFragmentDoc = gql`
  fragment ActivitySummary on Activity {
    id
    name
    viewedAt
    updatedAt
    archivedAt
    status
    author
    milestone {
      id
    }
    position
    type
    tasks {
      completedAt
    }
    composedDocument {
      id
      name
      type
      path
      getUrl
    }
    uploadedDocument {
      id
      name
      type
      path
      getUrl
    }
  }
`
export const MessageFragmentDoc = gql`
  fragment Message on Message {
    id
    content
    createdAt
  }
`
export const UserFragmentDoc = gql`
  fragment User on User {
    id
    name
    email
    avatarUrl
    email
    position
    isPostSignUpFormCompleted
  }
`
export const FeedbackRequestFragmentDoc = gql`
  fragment FeedbackRequest on FeedbackRequest {
    id
    user {
      ...User
    }
  }
  ${UserFragmentDoc}
`
export const ActivityFragmentDoc = gql`
  fragment Activity on Activity {
    ...ActivitySummary
    feedback {
      id
      messages {
        ...Message
      }
      requests {
        ...FeedbackRequest
      }
    }
  }
  ${ActivitySummaryFragmentDoc}
  ${MessageFragmentDoc}
  ${FeedbackRequestFragmentDoc}
`
export const AnswerFragmentDoc = gql`
  fragment Answer on AnswerMainType {
    id
    questionId
    userId
    comment {
      id
      content
      answerId
      type
    }
  }
`
export const BriefFragmentDoc = gql`
  fragment Brief on Activity {
    ...ActivitySummary
    deliverables {
      id
      name
    }
  }
  ${ActivitySummaryFragmentDoc}
`
export const CommentFragmentDoc = gql`
  fragment Comment on AnswerCommentType {
    content
    id
    type
    answerId
  }
`
export const DeliverableCategoryFragmentDoc = gql`
  fragment DeliverableCategory on DeliverableCategory {
    name
  }
`
export const DeliverableTypeFragmentDoc = gql`
  fragment DeliverableType on DeliverableType {
    id
    name
    category {
      ...DeliverableCategory
    }
    template {
      id
      name
    }
  }
  ${DeliverableCategoryFragmentDoc}
`
export const ReplyFeedbackMessageFragmentDoc = gql`
  fragment ReplyFeedbackMessage on FeedbackMessage {
    repliedUser {
      id
      name
      email
      avatarUrl
      email
    }
    user {
      id
      name
      email
      avatarUrl
      email
    }
    feedbackMessageId
    feedbackId
    messageId
    createdAt
    userId
    content
    assetId
    type
    pinned
    completedAt
    members {
      id
      name
      email
      avatarUrl
      email
    }
  }
`
export const FeedbackMessageFragmentDoc = gql`
  fragment FeedbackMessage on FeedbackMessage {
    feedbackMessageId
    feedbackId
    messageId
    createdAt
    userId
    content
    assetId
    position {
      x
      y
      page
    }
    type
    user {
      id
      name
      email
      avatarUrl
      email
    }
    pinned
    completedAt
    members {
      id
      name
      email
      avatarUrl
      email
    }
    replyedComments {
      ...ReplyFeedbackMessage
    }
  }
  ${ReplyFeedbackMessageFragmentDoc}
`
export const AssetSummaryFragmentDoc = gql`
  fragment AssetSummary on Asset {
    id
    name
    type
  }
`
export const FileFragmentDoc = gql`
  fragment File on File {
    ...AssetSummary
    path
    getUrl
  }
  ${AssetSummaryFragmentDoc}
`
export const MeFragmentDoc = gql`
  fragment Me on User {
    ...User
  }
  ${UserFragmentDoc}
`
export const OrganizationFragmentDoc = gql`
  fragment Organization on Organization {
    id
    name
    range
    type
    members {
      role
      createdAt
      user {
        name
        email
        avatarUrl
        id
      }
    }
  }
`
export const ProjectSummaryFragmentDoc = gql`
  fragment ProjectSummary on Project {
    id
    name
    color
    favorite
    organization {
      id
      name
    }
    client {
      name
    }
  }
`
export const ProjectMemberFragmentDoc = gql`
  fragment ProjectMember on ProjectMember {
    id
    role
    user {
      id
      name
      email
      avatarUrl
    }
  }
`
export const MilestoneFragmentDoc = gql`
  fragment Milestone on Milestone {
    id
    name
    archivedAt
    activities {
      ...ActivitySummary
    }
  }
  ${ActivitySummaryFragmentDoc}
`
export const ProjectFragmentDoc = gql`
  fragment Project on Project {
    ...ProjectSummary
    members {
      ...ProjectMember
    }
    client {
      name
    }
    dueDate
    milestones {
      ...Milestone
    }
    activities {
      ...ActivitySummary
    }
    archivedAt
  }
  ${ProjectSummaryFragmentDoc}
  ${ProjectMemberFragmentDoc}
  ${MilestoneFragmentDoc}
  ${ActivitySummaryFragmentDoc}
`
export const QuestionFragmentDoc = gql`
  fragment Question on QuestionMain {
    id
    text
    questionTypeId
    options
    sliderProps {
      minValue
      maxValue
      minLabel
      maxLabel
      showValue
    }
    deliverableTypeId
    deletedAt
  }
`
export const TaskSummaryFragmentDoc = gql`
  fragment TaskSummary on Task {
    id
    name
    project {
      ...ProjectSummary
    }
    organizationId
    userId
    dueDate
    completedAt
    status
    archivedAt
    assignedTo {
      id
      name
      avatarUrl
    }
    rowVersion
  }
  ${ProjectSummaryFragmentDoc}
`
export const ChecklistItemFragmentDoc = gql`
  fragment ChecklistItem on ChecklistItem {
    id
    name
    completedAt
  }
`
export const TaskFragmentDoc = gql`
  fragment Task on Task {
    ...TaskSummary
    description
    checklist {
      ...ChecklistItem
    }
  }
  ${TaskSummaryFragmentDoc}
  ${ChecklistItemFragmentDoc}
`
export const UserOrganizationFragmentDoc = gql`
  fragment UserOrganization on UserWithOrganization {
    id
    name
    email
    avatarUrl
    email
    position
    isPostSignUpFormCompleted
    organizationMember {
      role
    }
  }
`
export const AddAssetToArtworkDocument = gql`
  mutation AddAssetToArtwork($input: AddAssetToArtworkInput!) {
    addAssetToArtwork(input: $input) {
      putUrl
      file {
        id
        name
        type
        getUrl
        version
      }
    }
  }
`
export type AddAssetToArtworkMutationFn = Apollo.MutationFunction<
  AddAssetToArtworkMutation,
  AddAssetToArtworkMutationVariables
>

/**
 * __useAddAssetToArtworkMutation__
 *
 * To run a mutation, you first call `useAddAssetToArtworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAssetToArtworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAssetToArtworkMutation, { data, loading, error }] = useAddAssetToArtworkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAssetToArtworkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAssetToArtworkMutation,
    AddAssetToArtworkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddAssetToArtworkMutation, AddAssetToArtworkMutationVariables>(
    AddAssetToArtworkDocument,
    options
  )
}
export type AddAssetToArtworkMutationHookResult = ReturnType<typeof useAddAssetToArtworkMutation>
export type AddAssetToArtworkMutationResult = Apollo.MutationResult<AddAssetToArtworkMutation>
export type AddAssetToArtworkMutationOptions = Apollo.BaseMutationOptions<
  AddAssetToArtworkMutation,
  AddAssetToArtworkMutationVariables
>
export const AddFeedbackMemberDocument = gql`
  mutation AddFeedbackMember($input: FeedbackMemberInput!) {
    addMemberToFeedbackMessage(input: $input) {
      userId
      feedbackMessageId
    }
  }
`
export type AddFeedbackMemberMutationFn = Apollo.MutationFunction<
  AddFeedbackMemberMutation,
  AddFeedbackMemberMutationVariables
>

/**
 * __useAddFeedbackMemberMutation__
 *
 * To run a mutation, you first call `useAddFeedbackMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFeedbackMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFeedbackMemberMutation, { data, loading, error }] = useAddFeedbackMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFeedbackMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddFeedbackMemberMutation,
    AddFeedbackMemberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddFeedbackMemberMutation, AddFeedbackMemberMutationVariables>(
    AddFeedbackMemberDocument,
    options
  )
}
export type AddFeedbackMemberMutationHookResult = ReturnType<typeof useAddFeedbackMemberMutation>
export type AddFeedbackMemberMutationResult = Apollo.MutationResult<AddFeedbackMemberMutation>
export type AddFeedbackMemberMutationOptions = Apollo.BaseMutationOptions<
  AddFeedbackMemberMutation,
  AddFeedbackMemberMutationVariables
>
export const AddFileLinkToActivityDocument = gql`
  mutation AddFileLinkToActivity($input: UpdateUploadedDocumentInput!) {
    updateActivityUploadedDocument(input: $input) {
      url
    }
  }
`
export type AddFileLinkToActivityMutationFn = Apollo.MutationFunction<
  AddFileLinkToActivityMutation,
  AddFileLinkToActivityMutationVariables
>

/**
 * __useAddFileLinkToActivityMutation__
 *
 * To run a mutation, you first call `useAddFileLinkToActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFileLinkToActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFileLinkToActivityMutation, { data, loading, error }] = useAddFileLinkToActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddFileLinkToActivityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddFileLinkToActivityMutation,
    AddFileLinkToActivityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddFileLinkToActivityMutation, AddFileLinkToActivityMutationVariables>(
    AddFileLinkToActivityDocument,
    options
  )
}
export type AddFileLinkToActivityMutationHookResult = ReturnType<
  typeof useAddFileLinkToActivityMutation
>
export type AddFileLinkToActivityMutationResult =
  Apollo.MutationResult<AddFileLinkToActivityMutation>
export type AddFileLinkToActivityMutationOptions = Apollo.BaseMutationOptions<
  AddFileLinkToActivityMutation,
  AddFileLinkToActivityMutationVariables
>
export const AddImageFeedbackDocument = gql`
  mutation AddImageFeedback($input: AddImageFeedbackInput!) {
    addImageFeedback(input: $input) {
      message {
        id
        content
        createdAt
      }
      feedbackMessageId
    }
  }
`
export type AddImageFeedbackMutationFn = Apollo.MutationFunction<
  AddImageFeedbackMutation,
  AddImageFeedbackMutationVariables
>

/**
 * __useAddImageFeedbackMutation__
 *
 * To run a mutation, you first call `useAddImageFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddImageFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addImageFeedbackMutation, { data, loading, error }] = useAddImageFeedbackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddImageFeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddImageFeedbackMutation,
    AddImageFeedbackMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<AddImageFeedbackMutation, AddImageFeedbackMutationVariables>(
    AddImageFeedbackDocument,
    options
  )
}
export type AddImageFeedbackMutationHookResult = ReturnType<typeof useAddImageFeedbackMutation>
export type AddImageFeedbackMutationResult = Apollo.MutationResult<AddImageFeedbackMutation>
export type AddImageFeedbackMutationOptions = Apollo.BaseMutationOptions<
  AddImageFeedbackMutation,
  AddImageFeedbackMutationVariables
>
export const AddMemberToOrganizationDocument = gql`
  mutation AddMemberToOrganization($input: AddOrganazationMember!) {
    addMemberToOrganization(input: $input) {
      member {
        id
        role
        createdAt
        organization {
          id
        }
        user {
          id
          name
          email
          avatarUrl
        }
      }
    }
  }
`
export type AddMemberToOrganizationMutationFn = Apollo.MutationFunction<
  AddMemberToOrganizationMutation,
  AddMemberToOrganizationMutationVariables
>

/**
 * __useAddMemberToOrganizationMutation__
 *
 * To run a mutation, you first call `useAddMemberToOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMemberToOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMemberToOrganizationMutation, { data, loading, error }] = useAddMemberToOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMemberToOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddMemberToOrganizationMutation,
    AddMemberToOrganizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    AddMemberToOrganizationMutation,
    AddMemberToOrganizationMutationVariables
  >(AddMemberToOrganizationDocument, options)
}
export type AddMemberToOrganizationMutationHookResult = ReturnType<
  typeof useAddMemberToOrganizationMutation
>
export type AddMemberToOrganizationMutationResult =
  Apollo.MutationResult<AddMemberToOrganizationMutation>
export type AddMemberToOrganizationMutationOptions = Apollo.BaseMutationOptions<
  AddMemberToOrganizationMutation,
  AddMemberToOrganizationMutationVariables
>
export const ArchiveProjectDocument = gql`
  mutation ArchiveProject($input: ArchiveProjectInput!) {
    archiveProject(input: $input) {
      isArchived
    }
  }
`
export type ArchiveProjectMutationFn = Apollo.MutationFunction<
  ArchiveProjectMutation,
  ArchiveProjectMutationVariables
>

/**
 * __useArchiveProjectMutation__
 *
 * To run a mutation, you first call `useArchiveProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveProjectMutation, { data, loading, error }] = useArchiveProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<ArchiveProjectMutation, ArchiveProjectMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ArchiveProjectMutation, ArchiveProjectMutationVariables>(
    ArchiveProjectDocument,
    options
  )
}
export type ArchiveProjectMutationHookResult = ReturnType<typeof useArchiveProjectMutation>
export type ArchiveProjectMutationResult = Apollo.MutationResult<ArchiveProjectMutation>
export type ArchiveProjectMutationOptions = Apollo.BaseMutationOptions<
  ArchiveProjectMutation,
  ArchiveProjectMutationVariables
>
export const ChangeApprovalDocument = gql`
  mutation ChangeApproval($input: ApprovalResolverInput!) {
    changeApproval(input: $input) {
      userId
      status
    }
  }
`
export type ChangeApprovalMutationFn = Apollo.MutationFunction<
  ChangeApprovalMutation,
  ChangeApprovalMutationVariables
>

/**
 * __useChangeApprovalMutation__
 *
 * To run a mutation, you first call `useChangeApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeApprovalMutation, { data, loading, error }] = useChangeApprovalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeApprovalMutation(
  baseOptions?: Apollo.MutationHookOptions<ChangeApprovalMutation, ChangeApprovalMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ChangeApprovalMutation, ChangeApprovalMutationVariables>(
    ChangeApprovalDocument,
    options
  )
}
export type ChangeApprovalMutationHookResult = ReturnType<typeof useChangeApprovalMutation>
export type ChangeApprovalMutationResult = Apollo.MutationResult<ChangeApprovalMutation>
export type ChangeApprovalMutationOptions = Apollo.BaseMutationOptions<
  ChangeApprovalMutation,
  ChangeApprovalMutationVariables
>
export const CreateAnswerDocument = gql`
  mutation CreateAnswer($input: UpdateAnswerInput!) {
    createAnswers(input: $input) {
      brief {
        answers {
          ...Answer
        }
      }
    }
  }
  ${AnswerFragmentDoc}
`
export type CreateAnswerMutationFn = Apollo.MutationFunction<
  CreateAnswerMutation,
  CreateAnswerMutationVariables
>

/**
 * __useCreateAnswerMutation__
 *
 * To run a mutation, you first call `useCreateAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnswerMutation, { data, loading, error }] = useCreateAnswerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAnswerMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAnswerMutation, CreateAnswerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateAnswerMutation, CreateAnswerMutationVariables>(
    CreateAnswerDocument,
    options
  )
}
export type CreateAnswerMutationHookResult = ReturnType<typeof useCreateAnswerMutation>
export type CreateAnswerMutationResult = Apollo.MutationResult<CreateAnswerMutation>
export type CreateAnswerMutationOptions = Apollo.BaseMutationOptions<
  CreateAnswerMutation,
  CreateAnswerMutationVariables
>
export const CreateArtworkDocument = gql`
  mutation CreateArtwork($input: CreateArtworkInput!) {
    createArtwork(input: $input) {
      activity {
        ...ActivitySummary
        project {
          id
          activities {
            id
          }
          milestones {
            id
            activities {
              id
            }
          }
        }
      }
    }
  }
  ${ActivitySummaryFragmentDoc}
`
export type CreateArtworkMutationFn = Apollo.MutationFunction<
  CreateArtworkMutation,
  CreateArtworkMutationVariables
>

/**
 * __useCreateArtworkMutation__
 *
 * To run a mutation, you first call `useCreateArtworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArtworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArtworkMutation, { data, loading, error }] = useCreateArtworkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateArtworkMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateArtworkMutation, CreateArtworkMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateArtworkMutation, CreateArtworkMutationVariables>(
    CreateArtworkDocument,
    options
  )
}
export type CreateArtworkMutationHookResult = ReturnType<typeof useCreateArtworkMutation>
export type CreateArtworkMutationResult = Apollo.MutationResult<CreateArtworkMutation>
export type CreateArtworkMutationOptions = Apollo.BaseMutationOptions<
  CreateArtworkMutation,
  CreateArtworkMutationVariables
>
export const CreateAssetDocument = gql`
  mutation CreateAsset($input: CreateFormAssetInput!) {
    createFormAsset(input: $input) {
      form {
        id
        name
        type
        template {
          id
          name
        }
      }
    }
  }
`
export type CreateAssetMutationFn = Apollo.MutationFunction<
  CreateAssetMutation,
  CreateAssetMutationVariables
>

/**
 * __useCreateAssetMutation__
 *
 * To run a mutation, you first call `useCreateAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssetMutation, { data, loading, error }] = useCreateAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAssetMutation, CreateAssetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateAssetMutation, CreateAssetMutationVariables>(
    CreateAssetDocument,
    options
  )
}
export type CreateAssetMutationHookResult = ReturnType<typeof useCreateAssetMutation>
export type CreateAssetMutationResult = Apollo.MutationResult<CreateAssetMutation>
export type CreateAssetMutationOptions = Apollo.BaseMutationOptions<
  CreateAssetMutation,
  CreateAssetMutationVariables
>
export const CreateBriefDocument = gql`
  mutation CreateBrief($input: CreateBriefResolverInput!) {
    createBrief(input: $input) {
      activity {
        ...ActivitySummary
        project {
          id
          activities {
            id
          }
          milestones {
            id
            activities {
              id
            }
          }
        }
      }
    }
  }
  ${ActivitySummaryFragmentDoc}
`
export type CreateBriefMutationFn = Apollo.MutationFunction<
  CreateBriefMutation,
  CreateBriefMutationVariables
>

/**
 * __useCreateBriefMutation__
 *
 * To run a mutation, you first call `useCreateBriefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBriefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBriefMutation, { data, loading, error }] = useCreateBriefMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBriefMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateBriefMutation, CreateBriefMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateBriefMutation, CreateBriefMutationVariables>(
    CreateBriefDocument,
    options
  )
}
export type CreateBriefMutationHookResult = ReturnType<typeof useCreateBriefMutation>
export type CreateBriefMutationResult = Apollo.MutationResult<CreateBriefMutation>
export type CreateBriefMutationOptions = Apollo.BaseMutationOptions<
  CreateBriefMutation,
  CreateBriefMutationVariables
>
export const CreateBriefNoMilestonesDocument = gql`
  mutation CreateBriefNoMilestones($input: CreateBriefResolverInput!) {
    createBrief(input: $input) {
      activity {
        id
      }
    }
  }
`
export type CreateBriefNoMilestonesMutationFn = Apollo.MutationFunction<
  CreateBriefNoMilestonesMutation,
  CreateBriefNoMilestonesMutationVariables
>

/**
 * __useCreateBriefNoMilestonesMutation__
 *
 * To run a mutation, you first call `useCreateBriefNoMilestonesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBriefNoMilestonesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBriefNoMilestonesMutation, { data, loading, error }] = useCreateBriefNoMilestonesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBriefNoMilestonesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBriefNoMilestonesMutation,
    CreateBriefNoMilestonesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateBriefNoMilestonesMutation,
    CreateBriefNoMilestonesMutationVariables
  >(CreateBriefNoMilestonesDocument, options)
}
export type CreateBriefNoMilestonesMutationHookResult = ReturnType<
  typeof useCreateBriefNoMilestonesMutation
>
export type CreateBriefNoMilestonesMutationResult =
  Apollo.MutationResult<CreateBriefNoMilestonesMutation>
export type CreateBriefNoMilestonesMutationOptions = Apollo.BaseMutationOptions<
  CreateBriefNoMilestonesMutation,
  CreateBriefNoMilestonesMutationVariables
>
export const CreateDefaultActivityDocument = gql`
  mutation CreateDefaultActivity($input: CreateDefaultActivityInput!) {
    createDefaultActivity(input: $input) {
      activity {
        ...ActivitySummary
        project {
          id
          activities {
            id
          }
          milestones {
            id
            activities {
              id
            }
          }
        }
      }
    }
  }
  ${ActivitySummaryFragmentDoc}
`
export type CreateDefaultActivityMutationFn = Apollo.MutationFunction<
  CreateDefaultActivityMutation,
  CreateDefaultActivityMutationVariables
>

/**
 * __useCreateDefaultActivityMutation__
 *
 * To run a mutation, you first call `useCreateDefaultActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDefaultActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDefaultActivityMutation, { data, loading, error }] = useCreateDefaultActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDefaultActivityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDefaultActivityMutation,
    CreateDefaultActivityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateDefaultActivityMutation, CreateDefaultActivityMutationVariables>(
    CreateDefaultActivityDocument,
    options
  )
}
export type CreateDefaultActivityMutationHookResult = ReturnType<
  typeof useCreateDefaultActivityMutation
>
export type CreateDefaultActivityMutationResult =
  Apollo.MutationResult<CreateDefaultActivityMutation>
export type CreateDefaultActivityMutationOptions = Apollo.BaseMutationOptions<
  CreateDefaultActivityMutation,
  CreateDefaultActivityMutationVariables
>
export const CreateFeedbackMessageDocument = gql`
  mutation CreateFeedbackMessage($input: AddFeedbackInput!) {
    addFeedback(input: $input) {
      message {
        id
        content
      }
      feedbackMessageId
    }
  }
`
export type CreateFeedbackMessageMutationFn = Apollo.MutationFunction<
  CreateFeedbackMessageMutation,
  CreateFeedbackMessageMutationVariables
>

/**
 * __useCreateFeedbackMessageMutation__
 *
 * To run a mutation, you first call `useCreateFeedbackMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFeedbackMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFeedbackMessageMutation, { data, loading, error }] = useCreateFeedbackMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFeedbackMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateFeedbackMessageMutation,
    CreateFeedbackMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateFeedbackMessageMutation, CreateFeedbackMessageMutationVariables>(
    CreateFeedbackMessageDocument,
    options
  )
}
export type CreateFeedbackMessageMutationHookResult = ReturnType<
  typeof useCreateFeedbackMessageMutation
>
export type CreateFeedbackMessageMutationResult =
  Apollo.MutationResult<CreateFeedbackMessageMutation>
export type CreateFeedbackMessageMutationOptions = Apollo.BaseMutationOptions<
  CreateFeedbackMessageMutation,
  CreateFeedbackMessageMutationVariables
>
export const CreateOrganizationDocument = gql`
  mutation CreateOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      organization {
        ...Organization
      }
    }
  }
  ${OrganizationFragmentDoc}
`
export type CreateOrganizationMutationFn = Apollo.MutationFunction<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrganizationMutation,
    CreateOrganizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(
    CreateOrganizationDocument,
    options
  )
}
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>
export type CreateOrganizationMutationResult = Apollo.MutationResult<CreateOrganizationMutation>
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  CreateOrganizationMutation,
  CreateOrganizationMutationVariables
>
export const CreateProjectDocument = gql`
  mutation CreateProject($input: CreateProjectInput!) {
    createProject(input: $input) {
      project {
        ...Project
      }
    }
  }
  ${ProjectFragmentDoc}
`
export type CreateProjectMutationFn = Apollo.MutationFunction<
  CreateProjectMutation,
  CreateProjectMutationVariables
>

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(
    CreateProjectDocument,
    options
  )
}
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<
  CreateProjectMutation,
  CreateProjectMutationVariables
>
export const CreateReplyFeedbackMessageDocument = gql`
  mutation CreateReplyFeedbackMessage($input: AddReplyFeedbackMessageInput!) {
    addReplyFeedbackMessage(input: $input) {
      message {
        id
        content
        createdAt
      }
      childFeedbackMessageId
      replyedUserId
    }
  }
`
export type CreateReplyFeedbackMessageMutationFn = Apollo.MutationFunction<
  CreateReplyFeedbackMessageMutation,
  CreateReplyFeedbackMessageMutationVariables
>

/**
 * __useCreateReplyFeedbackMessageMutation__
 *
 * To run a mutation, you first call `useCreateReplyFeedbackMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReplyFeedbackMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReplyFeedbackMessageMutation, { data, loading, error }] = useCreateReplyFeedbackMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReplyFeedbackMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateReplyFeedbackMessageMutation,
    CreateReplyFeedbackMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    CreateReplyFeedbackMessageMutation,
    CreateReplyFeedbackMessageMutationVariables
  >(CreateReplyFeedbackMessageDocument, options)
}
export type CreateReplyFeedbackMessageMutationHookResult = ReturnType<
  typeof useCreateReplyFeedbackMessageMutation
>
export type CreateReplyFeedbackMessageMutationResult =
  Apollo.MutationResult<CreateReplyFeedbackMessageMutation>
export type CreateReplyFeedbackMessageMutationOptions = Apollo.BaseMutationOptions<
  CreateReplyFeedbackMessageMutation,
  CreateReplyFeedbackMessageMutationVariables
>
export const CreateTaskDocument = gql`
  mutation CreateTask($input: CreateTaskInput!) {
    createTask(input: $input) {
      task {
        id
        checklist {
          id
          name
          completedAt
        }
        name
        status
        description
        dueDate
        completedAt
        archivedAt
        project {
          id
          name
          client {
            name
          }
        }
        assignedTo {
          id
          name
          avatarUrl
        }
      }
    }
  }
`
export type CreateTaskMutationFn = Apollo.MutationFunction<
  CreateTaskMutation,
  CreateTaskMutationVariables
>

/**
 * __useCreateTaskMutation__
 *
 * To run a mutation, you first call `useCreateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskMutation, { data, loading, error }] = useCreateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTaskMutation, CreateTaskMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateTaskMutation, CreateTaskMutationVariables>(
    CreateTaskDocument,
    options
  )
}
export type CreateTaskMutationHookResult = ReturnType<typeof useCreateTaskMutation>
export type CreateTaskMutationResult = Apollo.MutationResult<CreateTaskMutation>
export type CreateTaskMutationOptions = Apollo.BaseMutationOptions<
  CreateTaskMutation,
  CreateTaskMutationVariables
>
export const DeleteAssetDocument = gql`
  mutation DeleteAsset($input: String!) {
    deleteAsset(id: $input) {
      isDeleted
    }
  }
`
export type DeleteAssetMutationFn = Apollo.MutationFunction<
  DeleteAssetMutation,
  DeleteAssetMutationVariables
>

/**
 * __useDeleteAssetMutation__
 *
 * To run a mutation, you first call `useDeleteAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssetMutation, { data, loading, error }] = useDeleteAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAssetMutation, DeleteAssetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteAssetMutation, DeleteAssetMutationVariables>(
    DeleteAssetDocument,
    options
  )
}
export type DeleteAssetMutationHookResult = ReturnType<typeof useDeleteAssetMutation>
export type DeleteAssetMutationResult = Apollo.MutationResult<DeleteAssetMutation>
export type DeleteAssetMutationOptions = Apollo.BaseMutationOptions<
  DeleteAssetMutation,
  DeleteAssetMutationVariables
>
export const DeleteBriefDocument = gql`
  mutation DeleteBrief($id: String!) {
    deleteBrief(id: $id) {
      isDeleted
    }
  }
`
export type DeleteBriefMutationFn = Apollo.MutationFunction<
  DeleteBriefMutation,
  DeleteBriefMutationVariables
>

/**
 * __useDeleteBriefMutation__
 *
 * To run a mutation, you first call `useDeleteBriefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBriefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBriefMutation, { data, loading, error }] = useDeleteBriefMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBriefMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteBriefMutation, DeleteBriefMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteBriefMutation, DeleteBriefMutationVariables>(
    DeleteBriefDocument,
    options
  )
}
export type DeleteBriefMutationHookResult = ReturnType<typeof useDeleteBriefMutation>
export type DeleteBriefMutationResult = Apollo.MutationResult<DeleteBriefMutation>
export type DeleteBriefMutationOptions = Apollo.BaseMutationOptions<
  DeleteBriefMutation,
  DeleteBriefMutationVariables
>
export const DeleteDeliverablesDocument = gql`
  mutation deleteDeliverables($ids: [String!]!) {
    deleteDeliverables(deliverableIds: $ids) {
      isDeleted
    }
  }
`
export type DeleteDeliverablesMutationFn = Apollo.MutationFunction<
  DeleteDeliverablesMutation,
  DeleteDeliverablesMutationVariables
>

/**
 * __useDeleteDeliverablesMutation__
 *
 * To run a mutation, you first call `useDeleteDeliverablesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDeliverablesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDeliverablesMutation, { data, loading, error }] = useDeleteDeliverablesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteDeliverablesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteDeliverablesMutation,
    DeleteDeliverablesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DeleteDeliverablesMutation, DeleteDeliverablesMutationVariables>(
    DeleteDeliverablesDocument,
    options
  )
}
export type DeleteDeliverablesMutationHookResult = ReturnType<typeof useDeleteDeliverablesMutation>
export type DeleteDeliverablesMutationResult = Apollo.MutationResult<DeleteDeliverablesMutation>
export type DeleteDeliverablesMutationOptions = Apollo.BaseMutationOptions<
  DeleteDeliverablesMutation,
  DeleteDeliverablesMutationVariables
>
export const DuplicateProjectDocument = gql`
  mutation DuplicateProject($input: DuplicateProjectInput!) {
    duplicateProject(input: $input) {
      project {
        id
      }
    }
  }
`
export type DuplicateProjectMutationFn = Apollo.MutationFunction<
  DuplicateProjectMutation,
  DuplicateProjectMutationVariables
>

/**
 * __useDuplicateProjectMutation__
 *
 * To run a mutation, you first call `useDuplicateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateProjectMutation, { data, loading, error }] = useDuplicateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDuplicateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DuplicateProjectMutation,
    DuplicateProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<DuplicateProjectMutation, DuplicateProjectMutationVariables>(
    DuplicateProjectDocument,
    options
  )
}
export type DuplicateProjectMutationHookResult = ReturnType<typeof useDuplicateProjectMutation>
export type DuplicateProjectMutationResult = Apollo.MutationResult<DuplicateProjectMutation>
export type DuplicateProjectMutationOptions = Apollo.BaseMutationOptions<
  DuplicateProjectMutation,
  DuplicateProjectMutationVariables
>
export const GetFileLinkByIdDocument = gql`
  mutation GetFileLinkById($id: String!) {
    getObjectUrl(file: { id: $id })
  }
`
export type GetFileLinkByIdMutationFn = Apollo.MutationFunction<
  GetFileLinkByIdMutation,
  GetFileLinkByIdMutationVariables
>

/**
 * __useGetFileLinkByIdMutation__
 *
 * To run a mutation, you first call `useGetFileLinkByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetFileLinkByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getFileLinkByIdMutation, { data, loading, error }] = useGetFileLinkByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetFileLinkByIdMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetFileLinkByIdMutation,
    GetFileLinkByIdMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<GetFileLinkByIdMutation, GetFileLinkByIdMutationVariables>(
    GetFileLinkByIdDocument,
    options
  )
}
export type GetFileLinkByIdMutationHookResult = ReturnType<typeof useGetFileLinkByIdMutation>
export type GetFileLinkByIdMutationResult = Apollo.MutationResult<GetFileLinkByIdMutation>
export type GetFileLinkByIdMutationOptions = Apollo.BaseMutationOptions<
  GetFileLinkByIdMutation,
  GetFileLinkByIdMutationVariables
>
export const GetObjectUrlDocument = gql`
  mutation GetObjectUrl($file: FileInput!) {
    getObjectUrl(file: $file)
  }
`
export type GetObjectUrlMutationFn = Apollo.MutationFunction<
  GetObjectUrlMutation,
  GetObjectUrlMutationVariables
>

/**
 * __useGetObjectUrlMutation__
 *
 * To run a mutation, you first call `useGetObjectUrlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetObjectUrlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getObjectUrlMutation, { data, loading, error }] = useGetObjectUrlMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useGetObjectUrlMutation(
  baseOptions?: Apollo.MutationHookOptions<GetObjectUrlMutation, GetObjectUrlMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<GetObjectUrlMutation, GetObjectUrlMutationVariables>(
    GetObjectUrlDocument,
    options
  )
}
export type GetObjectUrlMutationHookResult = ReturnType<typeof useGetObjectUrlMutation>
export type GetObjectUrlMutationResult = Apollo.MutationResult<GetObjectUrlMutation>
export type GetObjectUrlMutationOptions = Apollo.BaseMutationOptions<
  GetObjectUrlMutation,
  GetObjectUrlMutationVariables
>
export const InviteMemberToOrganizationDocument = gql`
  mutation InviteMemberToOrganization($input: InviteMemberToOrganizationInput!) {
    inviteMember(input: $input) {
      isInvited
    }
  }
`
export type InviteMemberToOrganizationMutationFn = Apollo.MutationFunction<
  InviteMemberToOrganizationMutation,
  InviteMemberToOrganizationMutationVariables
>

/**
 * __useInviteMemberToOrganizationMutation__
 *
 * To run a mutation, you first call `useInviteMemberToOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteMemberToOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteMemberToOrganizationMutation, { data, loading, error }] = useInviteMemberToOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useInviteMemberToOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteMemberToOrganizationMutation,
    InviteMemberToOrganizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    InviteMemberToOrganizationMutation,
    InviteMemberToOrganizationMutationVariables
  >(InviteMemberToOrganizationDocument, options)
}
export type InviteMemberToOrganizationMutationHookResult = ReturnType<
  typeof useInviteMemberToOrganizationMutation
>
export type InviteMemberToOrganizationMutationResult =
  Apollo.MutationResult<InviteMemberToOrganizationMutation>
export type InviteMemberToOrganizationMutationOptions = Apollo.BaseMutationOptions<
  InviteMemberToOrganizationMutation,
  InviteMemberToOrganizationMutationVariables
>
export const RemoveActivityAssetDocument = gql`
  mutation RemoveActivityAsset($input: DeleteArtworkAssetInput!) {
    deleteArtworkAsset(input: $input) {
      isDeleted
    }
  }
`
export type RemoveActivityAssetMutationFn = Apollo.MutationFunction<
  RemoveActivityAssetMutation,
  RemoveActivityAssetMutationVariables
>

/**
 * __useRemoveActivityAssetMutation__
 *
 * To run a mutation, you first call `useRemoveActivityAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveActivityAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeActivityAssetMutation, { data, loading, error }] = useRemoveActivityAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveActivityAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveActivityAssetMutation,
    RemoveActivityAssetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RemoveActivityAssetMutation, RemoveActivityAssetMutationVariables>(
    RemoveActivityAssetDocument,
    options
  )
}
export type RemoveActivityAssetMutationHookResult = ReturnType<
  typeof useRemoveActivityAssetMutation
>
export type RemoveActivityAssetMutationResult = Apollo.MutationResult<RemoveActivityAssetMutation>
export type RemoveActivityAssetMutationOptions = Apollo.BaseMutationOptions<
  RemoveActivityAssetMutation,
  RemoveActivityAssetMutationVariables
>
export const RemoveChecklistItemsDocument = gql`
  mutation RemoveChecklistItems($input: RemoveChecklistItemsInput!) {
    removeChecklistItems(input: $input) {
      isDeleted
    }
  }
`
export type RemoveChecklistItemsMutationFn = Apollo.MutationFunction<
  RemoveChecklistItemsMutation,
  RemoveChecklistItemsMutationVariables
>

/**
 * __useRemoveChecklistItemsMutation__
 *
 * To run a mutation, you first call `useRemoveChecklistItemsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveChecklistItemsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeChecklistItemsMutation, { data, loading, error }] = useRemoveChecklistItemsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveChecklistItemsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveChecklistItemsMutation,
    RemoveChecklistItemsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RemoveChecklistItemsMutation, RemoveChecklistItemsMutationVariables>(
    RemoveChecklistItemsDocument,
    options
  )
}
export type RemoveChecklistItemsMutationHookResult = ReturnType<
  typeof useRemoveChecklistItemsMutation
>
export type RemoveChecklistItemsMutationResult = Apollo.MutationResult<RemoveChecklistItemsMutation>
export type RemoveChecklistItemsMutationOptions = Apollo.BaseMutationOptions<
  RemoveChecklistItemsMutation,
  RemoveChecklistItemsMutationVariables
>
export const RemoveComposedDocumentDocument = gql`
  mutation RemoveComposedDocument($input: RemoveComposeDocumentInput!) {
    removeComposedDocument(input: $input) {
      isDeleted
    }
  }
`
export type RemoveComposedDocumentMutationFn = Apollo.MutationFunction<
  RemoveComposedDocumentMutation,
  RemoveComposedDocumentMutationVariables
>

/**
 * __useRemoveComposedDocumentMutation__
 *
 * To run a mutation, you first call `useRemoveComposedDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveComposedDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeComposedDocumentMutation, { data, loading, error }] = useRemoveComposedDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveComposedDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveComposedDocumentMutation,
    RemoveComposedDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveComposedDocumentMutation,
    RemoveComposedDocumentMutationVariables
  >(RemoveComposedDocumentDocument, options)
}
export type RemoveComposedDocumentMutationHookResult = ReturnType<
  typeof useRemoveComposedDocumentMutation
>
export type RemoveComposedDocumentMutationResult =
  Apollo.MutationResult<RemoveComposedDocumentMutation>
export type RemoveComposedDocumentMutationOptions = Apollo.BaseMutationOptions<
  RemoveComposedDocumentMutation,
  RemoveComposedDocumentMutationVariables
>
export const RemoveFeedbackMemberDocument = gql`
  mutation RemoveFeedbackMember($input: FeedbackMemberInput!) {
    removeMemberFromFeedbackMessage(input: $input) {
      isDeleted
    }
  }
`
export type RemoveFeedbackMemberMutationFn = Apollo.MutationFunction<
  RemoveFeedbackMemberMutation,
  RemoveFeedbackMemberMutationVariables
>

/**
 * __useRemoveFeedbackMemberMutation__
 *
 * To run a mutation, you first call `useRemoveFeedbackMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFeedbackMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFeedbackMemberMutation, { data, loading, error }] = useRemoveFeedbackMemberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveFeedbackMemberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveFeedbackMemberMutation,
    RemoveFeedbackMemberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RemoveFeedbackMemberMutation, RemoveFeedbackMemberMutationVariables>(
    RemoveFeedbackMemberDocument,
    options
  )
}
export type RemoveFeedbackMemberMutationHookResult = ReturnType<
  typeof useRemoveFeedbackMemberMutation
>
export type RemoveFeedbackMemberMutationResult = Apollo.MutationResult<RemoveFeedbackMemberMutation>
export type RemoveFeedbackMemberMutationOptions = Apollo.BaseMutationOptions<
  RemoveFeedbackMemberMutation,
  RemoveFeedbackMemberMutationVariables
>
export const RemoveFeedbackMessageDocument = gql`
  mutation RemoveFeedbackMessage($input: RemoveFeedbackMessageResolverInput!) {
    removeFeedbackMessage(input: $input) {
      isDelete
    }
  }
`
export type RemoveFeedbackMessageMutationFn = Apollo.MutationFunction<
  RemoveFeedbackMessageMutation,
  RemoveFeedbackMessageMutationVariables
>

/**
 * __useRemoveFeedbackMessageMutation__
 *
 * To run a mutation, you first call `useRemoveFeedbackMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFeedbackMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFeedbackMessageMutation, { data, loading, error }] = useRemoveFeedbackMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveFeedbackMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveFeedbackMessageMutation,
    RemoveFeedbackMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<RemoveFeedbackMessageMutation, RemoveFeedbackMessageMutationVariables>(
    RemoveFeedbackMessageDocument,
    options
  )
}
export type RemoveFeedbackMessageMutationHookResult = ReturnType<
  typeof useRemoveFeedbackMessageMutation
>
export type RemoveFeedbackMessageMutationResult =
  Apollo.MutationResult<RemoveFeedbackMessageMutation>
export type RemoveFeedbackMessageMutationOptions = Apollo.BaseMutationOptions<
  RemoveFeedbackMessageMutation,
  RemoveFeedbackMessageMutationVariables
>
export const RemoveOrganizationMemeberDocument = gql`
  mutation RemoveOrganizationMemeber($input: RemoveMemberFromOrganization!) {
    removeMemberFromOrganization(input: $input) {
      isDeleted
    }
  }
`
export type RemoveOrganizationMemeberMutationFn = Apollo.MutationFunction<
  RemoveOrganizationMemeberMutation,
  RemoveOrganizationMemeberMutationVariables
>

/**
 * __useRemoveOrganizationMemeberMutation__
 *
 * To run a mutation, you first call `useRemoveOrganizationMemeberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOrganizationMemeberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOrganizationMemeberMutation, { data, loading, error }] = useRemoveOrganizationMemeberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveOrganizationMemeberMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveOrganizationMemeberMutation,
    RemoveOrganizationMemeberMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveOrganizationMemeberMutation,
    RemoveOrganizationMemeberMutationVariables
  >(RemoveOrganizationMemeberDocument, options)
}
export type RemoveOrganizationMemeberMutationHookResult = ReturnType<
  typeof useRemoveOrganizationMemeberMutation
>
export type RemoveOrganizationMemeberMutationResult =
  Apollo.MutationResult<RemoveOrganizationMemeberMutation>
export type RemoveOrganizationMemeberMutationOptions = Apollo.BaseMutationOptions<
  RemoveOrganizationMemeberMutation,
  RemoveOrganizationMemeberMutationVariables
>
export const RemoveUploadedDocumentDocument = gql`
  mutation RemoveUploadedDocument($input: RemoveUploadDocumentInput!) {
    removeUploadedDocument(input: $input) {
      isDeleted
    }
  }
`
export type RemoveUploadedDocumentMutationFn = Apollo.MutationFunction<
  RemoveUploadedDocumentMutation,
  RemoveUploadedDocumentMutationVariables
>

/**
 * __useRemoveUploadedDocumentMutation__
 *
 * To run a mutation, you first call `useRemoveUploadedDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUploadedDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUploadedDocumentMutation, { data, loading, error }] = useRemoveUploadedDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveUploadedDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveUploadedDocumentMutation,
    RemoveUploadedDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    RemoveUploadedDocumentMutation,
    RemoveUploadedDocumentMutationVariables
  >(RemoveUploadedDocumentDocument, options)
}
export type RemoveUploadedDocumentMutationHookResult = ReturnType<
  typeof useRemoveUploadedDocumentMutation
>
export type RemoveUploadedDocumentMutationResult =
  Apollo.MutationResult<RemoveUploadedDocumentMutation>
export type RemoveUploadedDocumentMutationOptions = Apollo.BaseMutationOptions<
  RemoveUploadedDocumentMutation,
  RemoveUploadedDocumentMutationVariables
>
export const ReorderFileVersionsDocument = gql`
  mutation ReorderFileVersions($input: ReorderFileVersionInput!) {
    reorderFileVersions(input: $input)
  }
`
export type ReorderFileVersionsMutationFn = Apollo.MutationFunction<
  ReorderFileVersionsMutation,
  ReorderFileVersionsMutationVariables
>

/**
 * __useReorderFileVersionsMutation__
 *
 * To run a mutation, you first call `useReorderFileVersionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReorderFileVersionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reorderFileVersionsMutation, { data, loading, error }] = useReorderFileVersionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReorderFileVersionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReorderFileVersionsMutation,
    ReorderFileVersionsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ReorderFileVersionsMutation, ReorderFileVersionsMutationVariables>(
    ReorderFileVersionsDocument,
    options
  )
}
export type ReorderFileVersionsMutationHookResult = ReturnType<
  typeof useReorderFileVersionsMutation
>
export type ReorderFileVersionsMutationResult = Apollo.MutationResult<ReorderFileVersionsMutation>
export type ReorderFileVersionsMutationOptions = Apollo.BaseMutationOptions<
  ReorderFileVersionsMutation,
  ReorderFileVersionsMutationVariables
>
export const SaveAssetDocument = gql`
  mutation SaveAsset($input: SaveAssetInput!) {
    saveAsset(input: $input) {
      id
      name
      url
      path
      file {
        getUrl
        id
        name
        path
        type
      }
    }
  }
`
export type SaveAssetMutationFn = Apollo.MutationFunction<
  SaveAssetMutation,
  SaveAssetMutationVariables
>

/**
 * __useSaveAssetMutation__
 *
 * To run a mutation, you first call `useSaveAssetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAssetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAssetMutation, { data, loading, error }] = useSaveAssetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSaveAssetMutation(
  baseOptions?: Apollo.MutationHookOptions<SaveAssetMutation, SaveAssetMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SaveAssetMutation, SaveAssetMutationVariables>(
    SaveAssetDocument,
    options
  )
}
export type SaveAssetMutationHookResult = ReturnType<typeof useSaveAssetMutation>
export type SaveAssetMutationResult = Apollo.MutationResult<SaveAssetMutation>
export type SaveAssetMutationOptions = Apollo.BaseMutationOptions<
  SaveAssetMutation,
  SaveAssetMutationVariables
>
export const SendApproveReminderDocument = gql`
  mutation SendApproveReminder($input: ReminderNotificationInput!) {
    reminderNotification(input: $input) {
      notification {
        id
      }
    }
  }
`
export type SendApproveReminderMutationFn = Apollo.MutationFunction<
  SendApproveReminderMutation,
  SendApproveReminderMutationVariables
>

/**
 * __useSendApproveReminderMutation__
 *
 * To run a mutation, you first call `useSendApproveReminderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendApproveReminderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendApproveReminderMutation, { data, loading, error }] = useSendApproveReminderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendApproveReminderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendApproveReminderMutation,
    SendApproveReminderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendApproveReminderMutation, SendApproveReminderMutationVariables>(
    SendApproveReminderDocument,
    options
  )
}
export type SendApproveReminderMutationHookResult = ReturnType<
  typeof useSendApproveReminderMutation
>
export type SendApproveReminderMutationResult = Apollo.MutationResult<SendApproveReminderMutation>
export type SendApproveReminderMutationOptions = Apollo.BaseMutationOptions<
  SendApproveReminderMutation,
  SendApproveReminderMutationVariables
>
export const SentNotificationDocument = gql`
  mutation SentNotification($input: SentNotificationInput!) {
    sentNotification(input: $input) {
      notification {
        id
      }
    }
  }
`
export type SentNotificationMutationFn = Apollo.MutationFunction<
  SentNotificationMutation,
  SentNotificationMutationVariables
>

/**
 * __useSentNotificationMutation__
 *
 * To run a mutation, you first call `useSentNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSentNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sentNotificationMutation, { data, loading, error }] = useSentNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSentNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SentNotificationMutation,
    SentNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SentNotificationMutation, SentNotificationMutationVariables>(
    SentNotificationDocument,
    options
  )
}
export type SentNotificationMutationHookResult = ReturnType<typeof useSentNotificationMutation>
export type SentNotificationMutationResult = Apollo.MutationResult<SentNotificationMutation>
export type SentNotificationMutationOptions = Apollo.BaseMutationOptions<
  SentNotificationMutation,
  SentNotificationMutationVariables
>
export const SetAllNotificationAsReadDocument = gql`
  mutation SetAllNotificationAsRead($input: String!) {
    makeAllNotificationsRead(userId: $input)
  }
`
export type SetAllNotificationAsReadMutationFn = Apollo.MutationFunction<
  SetAllNotificationAsReadMutation,
  SetAllNotificationAsReadMutationVariables
>

/**
 * __useSetAllNotificationAsReadMutation__
 *
 * To run a mutation, you first call `useSetAllNotificationAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAllNotificationAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAllNotificationAsReadMutation, { data, loading, error }] = useSetAllNotificationAsReadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetAllNotificationAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetAllNotificationAsReadMutation,
    SetAllNotificationAsReadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    SetAllNotificationAsReadMutation,
    SetAllNotificationAsReadMutationVariables
  >(SetAllNotificationAsReadDocument, options)
}
export type SetAllNotificationAsReadMutationHookResult = ReturnType<
  typeof useSetAllNotificationAsReadMutation
>
export type SetAllNotificationAsReadMutationResult =
  Apollo.MutationResult<SetAllNotificationAsReadMutation>
export type SetAllNotificationAsReadMutationOptions = Apollo.BaseMutationOptions<
  SetAllNotificationAsReadMutation,
  SetAllNotificationAsReadMutationVariables
>
export const SetNotificationAsReadDocument = gql`
  mutation SetNotificationAsRead($input: ToggleMembersStatusInput!) {
    toggleNotificationStatus(input: $input)
  }
`
export type SetNotificationAsReadMutationFn = Apollo.MutationFunction<
  SetNotificationAsReadMutation,
  SetNotificationAsReadMutationVariables
>

/**
 * __useSetNotificationAsReadMutation__
 *
 * To run a mutation, you first call `useSetNotificationAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNotificationAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNotificationAsReadMutation, { data, loading, error }] = useSetNotificationAsReadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetNotificationAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetNotificationAsReadMutation,
    SetNotificationAsReadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SetNotificationAsReadMutation, SetNotificationAsReadMutationVariables>(
    SetNotificationAsReadDocument,
    options
  )
}
export type SetNotificationAsReadMutationHookResult = ReturnType<
  typeof useSetNotificationAsReadMutation
>
export type SetNotificationAsReadMutationResult =
  Apollo.MutationResult<SetNotificationAsReadMutation>
export type SetNotificationAsReadMutationOptions = Apollo.BaseMutationOptions<
  SetNotificationAsReadMutation,
  SetNotificationAsReadMutationVariables
>
export const UpdateActivityDocument = gql`
  mutation UpdateActivity($input: UpdateActivityInput!) {
    updateActivity(input: $input) {
      updatedActivities {
        ...ActivitySummary
        milestone {
          id
        }
      }
    }
  }
  ${ActivitySummaryFragmentDoc}
`
export type UpdateActivityMutationFn = Apollo.MutationFunction<
  UpdateActivityMutation,
  UpdateActivityMutationVariables
>

/**
 * __useUpdateActivityMutation__
 *
 * To run a mutation, you first call `useUpdateActivityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActivityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActivityMutation, { data, loading, error }] = useUpdateActivityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateActivityMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateActivityMutation, UpdateActivityMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateActivityMutation, UpdateActivityMutationVariables>(
    UpdateActivityDocument,
    options
  )
}
export type UpdateActivityMutationHookResult = ReturnType<typeof useUpdateActivityMutation>
export type UpdateActivityMutationResult = Apollo.MutationResult<UpdateActivityMutation>
export type UpdateActivityMutationOptions = Apollo.BaseMutationOptions<
  UpdateActivityMutation,
  UpdateActivityMutationVariables
>
export const UpdateApprovalDocument = gql`
  mutation UpdateApproval($input: ApprovalResolverInput!) {
    updateApproval(input: $input) {
      userId
      status
    }
  }
`
export type UpdateApprovalMutationFn = Apollo.MutationFunction<
  UpdateApprovalMutation,
  UpdateApprovalMutationVariables
>

/**
 * __useUpdateApprovalMutation__
 *
 * To run a mutation, you first call `useUpdateApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApprovalMutation, { data, loading, error }] = useUpdateApprovalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApprovalMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateApprovalMutation, UpdateApprovalMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateApprovalMutation, UpdateApprovalMutationVariables>(
    UpdateApprovalDocument,
    options
  )
}
export type UpdateApprovalMutationHookResult = ReturnType<typeof useUpdateApprovalMutation>
export type UpdateApprovalMutationResult = Apollo.MutationResult<UpdateApprovalMutation>
export type UpdateApprovalMutationOptions = Apollo.BaseMutationOptions<
  UpdateApprovalMutation,
  UpdateApprovalMutationVariables
>
export const UpdateBriefDocument = gql`
  mutation UpdateBrief($input: UpdateBriefInput!) {
    updateBrief(input: $input) {
      ...Brief
    }
  }
  ${BriefFragmentDoc}
`
export type UpdateBriefMutationFn = Apollo.MutationFunction<
  UpdateBriefMutation,
  UpdateBriefMutationVariables
>

/**
 * __useUpdateBriefMutation__
 *
 * To run a mutation, you first call `useUpdateBriefMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBriefMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBriefMutation, { data, loading, error }] = useUpdateBriefMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBriefMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateBriefMutation, UpdateBriefMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateBriefMutation, UpdateBriefMutationVariables>(
    UpdateBriefDocument,
    options
  )
}
export type UpdateBriefMutationHookResult = ReturnType<typeof useUpdateBriefMutation>
export type UpdateBriefMutationResult = Apollo.MutationResult<UpdateBriefMutation>
export type UpdateBriefMutationOptions = Apollo.BaseMutationOptions<
  UpdateBriefMutation,
  UpdateBriefMutationVariables
>
export const UpdateFeedbackMessageDocument = gql`
  mutation UpdateFeedbackMessage($input: UpdateFeedbackMessageResolverInput!) {
    updateFeedbackMessage(input: $input)
  }
`
export type UpdateFeedbackMessageMutationFn = Apollo.MutationFunction<
  UpdateFeedbackMessageMutation,
  UpdateFeedbackMessageMutationVariables
>

/**
 * __useUpdateFeedbackMessageMutation__
 *
 * To run a mutation, you first call `useUpdateFeedbackMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeedbackMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeedbackMessageMutation, { data, loading, error }] = useUpdateFeedbackMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateFeedbackMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateFeedbackMessageMutation,
    UpdateFeedbackMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateFeedbackMessageMutation, UpdateFeedbackMessageMutationVariables>(
    UpdateFeedbackMessageDocument,
    options
  )
}
export type UpdateFeedbackMessageMutationHookResult = ReturnType<
  typeof useUpdateFeedbackMessageMutation
>
export type UpdateFeedbackMessageMutationResult =
  Apollo.MutationResult<UpdateFeedbackMessageMutation>
export type UpdateFeedbackMessageMutationOptions = Apollo.BaseMutationOptions<
  UpdateFeedbackMessageMutation,
  UpdateFeedbackMessageMutationVariables
>
export const UpdateOrganizationDocument = gql`
  mutation UpdateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organization {
        id
        name
        range
        type
        members {
          role
          createdAt
          user {
            name
            email
            avatarUrl
            id
          }
        }
      }
    }
  }
`
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationMutation,
    UpdateOrganizationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(
    UpdateOrganizationDocument,
    options
  )
}
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>
export type UpdateOrganizationMutationResult = Apollo.MutationResult<UpdateOrganizationMutation>
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>
export const UpdateOrganizationMemberRoleDocument = gql`
  mutation UpdateOrganizationMemberRole($input: UpdateOrganizationMemberInput!) {
    updateOrganizationMemberRole(input: $input) {
      isSuccess
      updatedRole
    }
  }
`
export type UpdateOrganizationMemberRoleMutationFn = Apollo.MutationFunction<
  UpdateOrganizationMemberRoleMutation,
  UpdateOrganizationMemberRoleMutationVariables
>

/**
 * __useUpdateOrganizationMemberRoleMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMemberRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMemberRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMemberRoleMutation, { data, loading, error }] = useUpdateOrganizationMemberRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationMemberRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOrganizationMemberRoleMutation,
    UpdateOrganizationMemberRoleMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateOrganizationMemberRoleMutation,
    UpdateOrganizationMemberRoleMutationVariables
  >(UpdateOrganizationMemberRoleDocument, options)
}
export type UpdateOrganizationMemberRoleMutationHookResult = ReturnType<
  typeof useUpdateOrganizationMemberRoleMutation
>
export type UpdateOrganizationMemberRoleMutationResult =
  Apollo.MutationResult<UpdateOrganizationMemberRoleMutation>
export type UpdateOrganizationMemberRoleMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationMemberRoleMutation,
  UpdateOrganizationMemberRoleMutationVariables
>
export const UpdateProjectDocument = gql`
  mutation UpdateProject($input: UpdateProjectInput!) {
    updateProject(input: $input) {
      project {
        name
        dueDate
        client {
          name
        }
        members {
          id
          role
        }
      }
    }
  }
`
export type UpdateProjectMutationFn = Apollo.MutationFunction<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(
    UpdateProjectDocument,
    options
  )
}
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>
export type UpdateProjectMutationResult = Apollo.MutationResult<UpdateProjectMutation>
export type UpdateProjectMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectMutation,
  UpdateProjectMutationVariables
>
export const UpdateTaskDocument = gql`
  mutation UpdateTask($input: UpdateTaskInput!) {
    updateTask(input: $input) {
      task {
        id
        checklist {
          id
          name
          completedAt
        }
        name
        status
        description
        dueDate
        completedAt
        archivedAt
        rowVersion
        project {
          id
          name
          client {
            name
          }
          color
        }
        assignedTo {
          id
          name
          avatarUrl
        }
      }
    }
  }
`
export type UpdateTaskMutationFn = Apollo.MutationFunction<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(
    UpdateTaskDocument,
    options
  )
}
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>
export type UpdateTaskMutationResult = Apollo.MutationResult<UpdateTaskMutation>
export type UpdateTaskMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskMutation,
  UpdateTaskMutationVariables
>
export const UpdateUserDocument = gql`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        ...Me
      }
    }
  }
  ${MeFragmentDoc}
`
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options
  )
}
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>
export const UploadAssetsDocument = gql`
  mutation UploadAssets($input: [AssetUploadInput!]!) {
    uploadAssets(input: $input) {
      name
      url
      path
    }
  }
`
export type UploadAssetsMutationFn = Apollo.MutationFunction<
  UploadAssetsMutation,
  UploadAssetsMutationVariables
>

/**
 * __useUploadAssetsMutation__
 *
 * To run a mutation, you first call `useUploadAssetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAssetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAssetsMutation, { data, loading, error }] = useUploadAssetsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadAssetsMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadAssetsMutation, UploadAssetsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UploadAssetsMutation, UploadAssetsMutationVariables>(
    UploadAssetsDocument,
    options
  )
}
export type UploadAssetsMutationHookResult = ReturnType<typeof useUploadAssetsMutation>
export type UploadAssetsMutationResult = Apollo.MutationResult<UploadAssetsMutation>
export type UploadAssetsMutationOptions = Apollo.BaseMutationOptions<
  UploadAssetsMutation,
  UploadAssetsMutationVariables
>
export const UploadPrivateFileDocument = gql`
  mutation UploadPrivateFile($input: FileUploadInput!) {
    uploadPrivateFile(input: $input) {
      url
      file {
        id
        name
        type
        getUrl
      }
    }
  }
`
export type UploadPrivateFileMutationFn = Apollo.MutationFunction<
  UploadPrivateFileMutation,
  UploadPrivateFileMutationVariables
>

/**
 * __useUploadPrivateFileMutation__
 *
 * To run a mutation, you first call `useUploadPrivateFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPrivateFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPrivateFileMutation, { data, loading, error }] = useUploadPrivateFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadPrivateFileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadPrivateFileMutation,
    UploadPrivateFileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UploadPrivateFileMutation, UploadPrivateFileMutationVariables>(
    UploadPrivateFileDocument,
    options
  )
}
export type UploadPrivateFileMutationHookResult = ReturnType<typeof useUploadPrivateFileMutation>
export type UploadPrivateFileMutationResult = Apollo.MutationResult<UploadPrivateFileMutation>
export type UploadPrivateFileMutationOptions = Apollo.BaseMutationOptions<
  UploadPrivateFileMutation,
  UploadPrivateFileMutationVariables
>
export const BoardTempDocument = gql`
  mutation boardTemp($archived: Boolean, $id: ID!) {
    updateActivity(input: { archived: $archived, id: $id }) {
      activity {
        milestone {
          name
          id
        }
      }
    }
  }
`
export type BoardTempMutationFn = Apollo.MutationFunction<
  BoardTempMutation,
  BoardTempMutationVariables
>

/**
 * __useBoardTempMutation__
 *
 * To run a mutation, you first call `useBoardTempMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBoardTempMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [boardTempMutation, { data, loading, error }] = useBoardTempMutation({
 *   variables: {
 *      archived: // value for 'archived'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBoardTempMutation(
  baseOptions?: Apollo.MutationHookOptions<BoardTempMutation, BoardTempMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<BoardTempMutation, BoardTempMutationVariables>(
    BoardTempDocument,
    options
  )
}
export type BoardTempMutationHookResult = ReturnType<typeof useBoardTempMutation>
export type BoardTempMutationResult = Apollo.MutationResult<BoardTempMutation>
export type BoardTempMutationOptions = Apollo.BaseMutationOptions<
  BoardTempMutation,
  BoardTempMutationVariables
>
export const CreateMilestonesDocument = gql`
  mutation createMilestones($name: String!, $id: ID!) {
    createMilestone(input: { projectId: $id, name: $name }) {
      milestone {
        id
        name
        project {
          id
        }
        archivedAt
        activities {
          id
        }
      }
    }
  }
`
export type CreateMilestonesMutationFn = Apollo.MutationFunction<
  CreateMilestonesMutation,
  CreateMilestonesMutationVariables
>

/**
 * __useCreateMilestonesMutation__
 *
 * To run a mutation, you first call `useCreateMilestonesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMilestonesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMilestonesMutation, { data, loading, error }] = useCreateMilestonesMutation({
 *   variables: {
 *      name: // value for 'name'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateMilestonesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateMilestonesMutation,
    CreateMilestonesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateMilestonesMutation, CreateMilestonesMutationVariables>(
    CreateMilestonesDocument,
    options
  )
}
export type CreateMilestonesMutationHookResult = ReturnType<typeof useCreateMilestonesMutation>
export type CreateMilestonesMutationResult = Apollo.MutationResult<CreateMilestonesMutation>
export type CreateMilestonesMutationOptions = Apollo.BaseMutationOptions<
  CreateMilestonesMutation,
  CreateMilestonesMutationVariables
>
export const CreateWorkshopDocument = gql`
  mutation CreateWorkshop($input: CreateWorkshopInput!) {
    createWorkshop(input: $input) {
      activity {
        ...ActivitySummary
        project {
          id
          activities {
            id
          }
          milestones {
            id
            activities {
              id
            }
          }
        }
      }
    }
  }
  ${ActivitySummaryFragmentDoc}
`
export type CreateWorkshopMutationFn = Apollo.MutationFunction<
  CreateWorkshopMutation,
  CreateWorkshopMutationVariables
>

/**
 * __useCreateWorkshopMutation__
 *
 * To run a mutation, you first call `useCreateWorkshopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkshopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkshopMutation, { data, loading, error }] = useCreateWorkshopMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkshopMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateWorkshopMutation, CreateWorkshopMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CreateWorkshopMutation, CreateWorkshopMutationVariables>(
    CreateWorkshopDocument,
    options
  )
}
export type CreateWorkshopMutationHookResult = ReturnType<typeof useCreateWorkshopMutation>
export type CreateWorkshopMutationResult = Apollo.MutationResult<CreateWorkshopMutation>
export type CreateWorkshopMutationOptions = Apollo.BaseMutationOptions<
  CreateWorkshopMutation,
  CreateWorkshopMutationVariables
>
export const UpdateActivityComposeDocumentDocument = gql`
  mutation updateActivityComposeDocument($input: UpdateComposedDocumentInput!) {
    updateActivityComposeDocument(input: $input) {
      url
      activity {
        composedDocument {
          id
          name
          getUrl
        }
      }
    }
  }
`
export type UpdateActivityComposeDocumentMutationFn = Apollo.MutationFunction<
  UpdateActivityComposeDocumentMutation,
  UpdateActivityComposeDocumentMutationVariables
>

/**
 * __useUpdateActivityComposeDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateActivityComposeDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActivityComposeDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActivityComposeDocumentMutation, { data, loading, error }] = useUpdateActivityComposeDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateActivityComposeDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateActivityComposeDocumentMutation,
    UpdateActivityComposeDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateActivityComposeDocumentMutation,
    UpdateActivityComposeDocumentMutationVariables
  >(UpdateActivityComposeDocumentDocument, options)
}
export type UpdateActivityComposeDocumentMutationHookResult = ReturnType<
  typeof useUpdateActivityComposeDocumentMutation
>
export type UpdateActivityComposeDocumentMutationResult =
  Apollo.MutationResult<UpdateActivityComposeDocumentMutation>
export type UpdateActivityComposeDocumentMutationOptions = Apollo.BaseMutationOptions<
  UpdateActivityComposeDocumentMutation,
  UpdateActivityComposeDocumentMutationVariables
>
export const UpdateActivityUploadedDocumentDocument = gql`
  mutation updateActivityUploadedDocument($input: UpdateUploadedDocumentInput!) {
    updateActivityUploadedDocument(input: $input) {
      url
      activity {
        uploadedDocument {
          id
          name
        }
      }
    }
  }
`
export type UpdateActivityUploadedDocumentMutationFn = Apollo.MutationFunction<
  UpdateActivityUploadedDocumentMutation,
  UpdateActivityUploadedDocumentMutationVariables
>

/**
 * __useUpdateActivityUploadedDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateActivityUploadedDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateActivityUploadedDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateActivityUploadedDocumentMutation, { data, loading, error }] = useUpdateActivityUploadedDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateActivityUploadedDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateActivityUploadedDocumentMutation,
    UpdateActivityUploadedDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateActivityUploadedDocumentMutation,
    UpdateActivityUploadedDocumentMutationVariables
  >(UpdateActivityUploadedDocumentDocument, options)
}
export type UpdateActivityUploadedDocumentMutationHookResult = ReturnType<
  typeof useUpdateActivityUploadedDocumentMutation
>
export type UpdateActivityUploadedDocumentMutationResult =
  Apollo.MutationResult<UpdateActivityUploadedDocumentMutation>
export type UpdateActivityUploadedDocumentMutationOptions = Apollo.BaseMutationOptions<
  UpdateActivityUploadedDocumentMutation,
  UpdateActivityUploadedDocumentMutationVariables
>
export const UpdateBriefComposeDocumentDocument = gql`
  mutation updateBriefComposeDocument($input: UpdateComposedDocumentInput!) {
    updateBriefComposeDocument(input: $input) {
      url
      brief {
        composedDocument {
          id
          name
        }
      }
    }
  }
`
export type UpdateBriefComposeDocumentMutationFn = Apollo.MutationFunction<
  UpdateBriefComposeDocumentMutation,
  UpdateBriefComposeDocumentMutationVariables
>

/**
 * __useUpdateBriefComposeDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateBriefComposeDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBriefComposeDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBriefComposeDocumentMutation, { data, loading, error }] = useUpdateBriefComposeDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBriefComposeDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBriefComposeDocumentMutation,
    UpdateBriefComposeDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateBriefComposeDocumentMutation,
    UpdateBriefComposeDocumentMutationVariables
  >(UpdateBriefComposeDocumentDocument, options)
}
export type UpdateBriefComposeDocumentMutationHookResult = ReturnType<
  typeof useUpdateBriefComposeDocumentMutation
>
export type UpdateBriefComposeDocumentMutationResult =
  Apollo.MutationResult<UpdateBriefComposeDocumentMutation>
export type UpdateBriefComposeDocumentMutationOptions = Apollo.BaseMutationOptions<
  UpdateBriefComposeDocumentMutation,
  UpdateBriefComposeDocumentMutationVariables
>
export const UpdateBriefUploadedDocumentDocument = gql`
  mutation updateBriefUploadedDocument($input: UpdateUploadedDocumentInput!) {
    updateBriefUploadedDocument(input: $input) {
      url
      brief {
        uploadedDocument {
          id
          name
        }
      }
    }
  }
`
export type UpdateBriefUploadedDocumentMutationFn = Apollo.MutationFunction<
  UpdateBriefUploadedDocumentMutation,
  UpdateBriefUploadedDocumentMutationVariables
>

/**
 * __useUpdateBriefUploadedDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateBriefUploadedDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBriefUploadedDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBriefUploadedDocumentMutation, { data, loading, error }] = useUpdateBriefUploadedDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBriefUploadedDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBriefUploadedDocumentMutation,
    UpdateBriefUploadedDocumentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateBriefUploadedDocumentMutation,
    UpdateBriefUploadedDocumentMutationVariables
  >(UpdateBriefUploadedDocumentDocument, options)
}
export type UpdateBriefUploadedDocumentMutationHookResult = ReturnType<
  typeof useUpdateBriefUploadedDocumentMutation
>
export type UpdateBriefUploadedDocumentMutationResult =
  Apollo.MutationResult<UpdateBriefUploadedDocumentMutation>
export type UpdateBriefUploadedDocumentMutationOptions = Apollo.BaseMutationOptions<
  UpdateBriefUploadedDocumentMutation,
  UpdateBriefUploadedDocumentMutationVariables
>
export const UpdateMilestoneDocument = gql`
  mutation updateMilestone($id: ID!, $archive: Boolean, $name: String) {
    updateMilestone(input: { archived: $archive, id: $id, name: $name }) {
      milestone {
        ...Milestone
      }
    }
  }
  ${MilestoneFragmentDoc}
`
export type UpdateMilestoneMutationFn = Apollo.MutationFunction<
  UpdateMilestoneMutation,
  UpdateMilestoneMutationVariables
>

/**
 * __useUpdateMilestoneMutation__
 *
 * To run a mutation, you first call `useUpdateMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMilestoneMutation, { data, loading, error }] = useUpdateMilestoneMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archive: // value for 'archive'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateMilestoneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMilestoneMutation,
    UpdateMilestoneMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<UpdateMilestoneMutation, UpdateMilestoneMutationVariables>(
    UpdateMilestoneDocument,
    options
  )
}
export type UpdateMilestoneMutationHookResult = ReturnType<typeof useUpdateMilestoneMutation>
export type UpdateMilestoneMutationResult = Apollo.MutationResult<UpdateMilestoneMutation>
export type UpdateMilestoneMutationOptions = Apollo.BaseMutationOptions<
  UpdateMilestoneMutation,
  UpdateMilestoneMutationVariables
>
export const ActivityTypesDocument = gql`
  query ActivityTypes {
    activityTypes {
      name
      description
      id
    }
  }
`

/**
 * __useActivityTypesQuery__
 *
 * To run a query within a React component, call `useActivityTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useActivityTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<ActivityTypesQuery, ActivityTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ActivityTypesQuery, ActivityTypesQueryVariables>(
    ActivityTypesDocument,
    options
  )
}
export function useActivityTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ActivityTypesQuery, ActivityTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ActivityTypesQuery, ActivityTypesQueryVariables>(
    ActivityTypesDocument,
    options
  )
}
export type ActivityTypesQueryHookResult = ReturnType<typeof useActivityTypesQuery>
export type ActivityTypesLazyQueryHookResult = ReturnType<typeof useActivityTypesLazyQuery>
export type ActivityTypesQueryResult = Apollo.QueryResult<
  ActivityTypesQuery,
  ActivityTypesQueryVariables
>
export const AllActivitiesDocument = gql`
  query AllActivities {
    activities {
      project {
        id
        name
        color
        client {
          name
        }
      }
      createdAt
      ...ActivitySummary
    }
  }
  ${ActivitySummaryFragmentDoc}
`

/**
 * __useAllActivitiesQuery__
 *
 * To run a query within a React component, call `useAllActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllActivitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllActivitiesQuery(
  baseOptions?: Apollo.QueryHookOptions<AllActivitiesQuery, AllActivitiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllActivitiesQuery, AllActivitiesQueryVariables>(
    AllActivitiesDocument,
    options
  )
}
export function useAllActivitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllActivitiesQuery, AllActivitiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllActivitiesQuery, AllActivitiesQueryVariables>(
    AllActivitiesDocument,
    options
  )
}
export type AllActivitiesQueryHookResult = ReturnType<typeof useAllActivitiesQuery>
export type AllActivitiesLazyQueryHookResult = ReturnType<typeof useAllActivitiesLazyQuery>
export type AllActivitiesQueryResult = Apollo.QueryResult<
  AllActivitiesQuery,
  AllActivitiesQueryVariables
>
export const AllTasksDocument = gql`
  query AllTasks {
    tasks {
      ...Task
    }
  }
  ${TaskFragmentDoc}
`

/**
 * __useAllTasksQuery__
 *
 * To run a query within a React component, call `useAllTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTasksQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<AllTasksQuery, AllTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllTasksQuery, AllTasksQueryVariables>(AllTasksDocument, options)
}
export function useAllTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllTasksQuery, AllTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllTasksQuery, AllTasksQueryVariables>(AllTasksDocument, options)
}
export type AllTasksQueryHookResult = ReturnType<typeof useAllTasksQuery>
export type AllTasksLazyQueryHookResult = ReturnType<typeof useAllTasksLazyQuery>
export type AllTasksQueryResult = Apollo.QueryResult<AllTasksQuery, AllTasksQueryVariables>
export const AllUsersDocument = gql`
  query AllUsers {
    users {
      id
      name
      email
      avatarUrl
    }
  }
`

/**
 * __useAllUsersQuery__
 *
 * To run a query within a React component, call `useAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<AllUsersQuery, AllUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options)
}
export function useAllUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllUsersQuery, AllUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options)
}
export type AllUsersQueryHookResult = ReturnType<typeof useAllUsersQuery>
export type AllUsersLazyQueryHookResult = ReturnType<typeof useAllUsersLazyQuery>
export type AllUsersQueryResult = Apollo.QueryResult<AllUsersQuery, AllUsersQueryVariables>
export const GetAnswersDocument = gql`
  query GetAnswers($briefId: String!) {
    answers(briefId: $briefId) {
      mappedAnswer {
        question {
          ...Question
        }
        answer {
          ...Answer
        }
      }
      mappedDeliverables {
        id
        name
        data {
          question {
            ...Question
          }
          comment {
            ...Comment
          }
        }
      }
    }
  }
  ${QuestionFragmentDoc}
  ${AnswerFragmentDoc}
  ${CommentFragmentDoc}
`

/**
 * __useGetAnswersQuery__
 *
 * To run a query within a React component, call `useGetAnswersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnswersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnswersQuery({
 *   variables: {
 *      briefId: // value for 'briefId'
 *   },
 * });
 */
export function useGetAnswersQuery(
  baseOptions: Apollo.QueryHookOptions<GetAnswersQuery, GetAnswersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAnswersQuery, GetAnswersQueryVariables>(GetAnswersDocument, options)
}
export function useGetAnswersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAnswersQuery, GetAnswersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAnswersQuery, GetAnswersQueryVariables>(GetAnswersDocument, options)
}
export type GetAnswersQueryHookResult = ReturnType<typeof useGetAnswersQuery>
export type GetAnswersLazyQueryHookResult = ReturnType<typeof useGetAnswersLazyQuery>
export type GetAnswersQueryResult = Apollo.QueryResult<GetAnswersQuery, GetAnswersQueryVariables>
export const GetDeliverablesDocument = gql`
  query GetDeliverables($briefId: String!) {
    getDeliverables(briefId: $briefId) {
      id
      name
      relationId
    }
  }
`

/**
 * __useGetDeliverablesQuery__
 *
 * To run a query within a React component, call `useGetDeliverablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliverablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliverablesQuery({
 *   variables: {
 *      briefId: // value for 'briefId'
 *   },
 * });
 */
export function useGetDeliverablesQuery(
  baseOptions: Apollo.QueryHookOptions<GetDeliverablesQuery, GetDeliverablesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetDeliverablesQuery, GetDeliverablesQueryVariables>(
    GetDeliverablesDocument,
    options
  )
}
export function useGetDeliverablesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetDeliverablesQuery, GetDeliverablesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetDeliverablesQuery, GetDeliverablesQueryVariables>(
    GetDeliverablesDocument,
    options
  )
}
export type GetDeliverablesQueryHookResult = ReturnType<typeof useGetDeliverablesQuery>
export type GetDeliverablesLazyQueryHookResult = ReturnType<typeof useGetDeliverablesLazyQuery>
export type GetDeliverablesQueryResult = Apollo.QueryResult<
  GetDeliverablesQuery,
  GetDeliverablesQueryVariables
>
export const DeliverableTypesDocument = gql`
  query DeliverableTypes {
    deliverableTypes {
      ...DeliverableType
    }
  }
  ${DeliverableTypeFragmentDoc}
`

/**
 * __useDeliverableTypesQuery__
 *
 * To run a query within a React component, call `useDeliverableTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliverableTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliverableTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useDeliverableTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<DeliverableTypesQuery, DeliverableTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DeliverableTypesQuery, DeliverableTypesQueryVariables>(
    DeliverableTypesDocument,
    options
  )
}
export function useDeliverableTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DeliverableTypesQuery, DeliverableTypesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DeliverableTypesQuery, DeliverableTypesQueryVariables>(
    DeliverableTypesDocument,
    options
  )
}
export type DeliverableTypesQueryHookResult = ReturnType<typeof useDeliverableTypesQuery>
export type DeliverableTypesLazyQueryHookResult = ReturnType<typeof useDeliverableTypesLazyQuery>
export type DeliverableTypesQueryResult = Apollo.QueryResult<
  DeliverableTypesQuery,
  DeliverableTypesQueryVariables
>
export const DeliverablesQuestionDocument = gql`
  query DeliverablesQuestion($deliverables: [String!]!) {
    deliverablesQuestions(input: { deliverables: $deliverables }) {
      deliverable
      questions {
        ...Question
      }
    }
  }
  ${QuestionFragmentDoc}
`

/**
 * __useDeliverablesQuestionQuery__
 *
 * To run a query within a React component, call `useDeliverablesQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeliverablesQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeliverablesQuestionQuery({
 *   variables: {
 *      deliverables: // value for 'deliverables'
 *   },
 * });
 */
export function useDeliverablesQuestionQuery(
  baseOptions: Apollo.QueryHookOptions<
    DeliverablesQuestionQuery,
    DeliverablesQuestionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<DeliverablesQuestionQuery, DeliverablesQuestionQueryVariables>(
    DeliverablesQuestionDocument,
    options
  )
}
export function useDeliverablesQuestionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DeliverablesQuestionQuery,
    DeliverablesQuestionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<DeliverablesQuestionQuery, DeliverablesQuestionQueryVariables>(
    DeliverablesQuestionDocument,
    options
  )
}
export type DeliverablesQuestionQueryHookResult = ReturnType<typeof useDeliverablesQuestionQuery>
export type DeliverablesQuestionLazyQueryHookResult = ReturnType<
  typeof useDeliverablesQuestionLazyQuery
>
export type DeliverablesQuestionQueryResult = Apollo.QueryResult<
  DeliverablesQuestionQuery,
  DeliverablesQuestionQueryVariables
>
export const AllFeedbackDocument = gql`
  query AllFeedback {
    projects {
      activities {
        feedback {
          messages {
            content
          }
        }
      }
    }
  }
`

/**
 * __useAllFeedbackQuery__
 *
 * To run a query within a React component, call `useAllFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllFeedbackQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllFeedbackQuery(
  baseOptions?: Apollo.QueryHookOptions<AllFeedbackQuery, AllFeedbackQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<AllFeedbackQuery, AllFeedbackQueryVariables>(AllFeedbackDocument, options)
}
export function useAllFeedbackLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllFeedbackQuery, AllFeedbackQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<AllFeedbackQuery, AllFeedbackQueryVariables>(
    AllFeedbackDocument,
    options
  )
}
export type AllFeedbackQueryHookResult = ReturnType<typeof useAllFeedbackQuery>
export type AllFeedbackLazyQueryHookResult = ReturnType<typeof useAllFeedbackLazyQuery>
export type AllFeedbackQueryResult = Apollo.QueryResult<AllFeedbackQuery, AllFeedbackQueryVariables>
export const GetActivityByIdDocument = gql`
  query GetActivityById($input: String!) {
    getActivityById(activityId: $input) {
      feedback {
        id
      }
      uploadedDocument {
        id
        name
      }
      composedDocument {
        id
        name
      }
    }
  }
`

/**
 * __useGetActivityByIdQuery__
 *
 * To run a query within a React component, call `useGetActivityByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActivityByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActivityByIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetActivityByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetActivityByIdQuery, GetActivityByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetActivityByIdQuery, GetActivityByIdQueryVariables>(
    GetActivityByIdDocument,
    options
  )
}
export function useGetActivityByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetActivityByIdQuery, GetActivityByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetActivityByIdQuery, GetActivityByIdQueryVariables>(
    GetActivityByIdDocument,
    options
  )
}
export type GetActivityByIdQueryHookResult = ReturnType<typeof useGetActivityByIdQuery>
export type GetActivityByIdLazyQueryHookResult = ReturnType<typeof useGetActivityByIdLazyQuery>
export type GetActivityByIdQueryResult = Apollo.QueryResult<
  GetActivityByIdQuery,
  GetActivityByIdQueryVariables
>
export const GetAllActivityAssetsDocument = gql`
  query GetAllActivityAssets($activityId: String!) {
    getAllActivityAssets(activityId: $activityId) {
      fileId
      file {
        name
        type
        getUrl
        version
      }
    }
  }
`

/**
 * __useGetAllActivityAssetsQuery__
 *
 * To run a query within a React component, call `useGetAllActivityAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllActivityAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllActivityAssetsQuery({
 *   variables: {
 *      activityId: // value for 'activityId'
 *   },
 * });
 */
export function useGetAllActivityAssetsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllActivityAssetsQuery,
    GetAllActivityAssetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAllActivityAssetsQuery, GetAllActivityAssetsQueryVariables>(
    GetAllActivityAssetsDocument,
    options
  )
}
export function useGetAllActivityAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllActivityAssetsQuery,
    GetAllActivityAssetsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAllActivityAssetsQuery, GetAllActivityAssetsQueryVariables>(
    GetAllActivityAssetsDocument,
    options
  )
}
export type GetAllActivityAssetsQueryHookResult = ReturnType<typeof useGetAllActivityAssetsQuery>
export type GetAllActivityAssetsLazyQueryHookResult = ReturnType<
  typeof useGetAllActivityAssetsLazyQuery
>
export type GetAllActivityAssetsQueryResult = Apollo.QueryResult<
  GetAllActivityAssetsQuery,
  GetAllActivityAssetsQueryVariables
>
export const GetAllAssetsDocument = gql`
  query GetAllAssets(
    $input: GetGlobalAssetsWhere!
    $page: Int
    $sortOrder: SortOrder
    $limit: Int
  ) {
    getAllAssets(where: $input, page: $page, sortOrder: $sortOrder, limit: $limit) {
      assets {
        id
        fileId
        organizationId
        projectId
        activityId
        project {
          name
          client {
            name
          }
          color
        }
        organization {
          name
        }
        activity {
          name
          type
        }
        file {
          id
          name
          type
          path
          getUrl
          version
        }
        createdAt
        updatedAt
      }
      paginationData {
        totalAmount
        perPage
        totalPages
        currentPage
      }
    }
  }
`

/**
 * __useGetAllAssetsQuery__
 *
 * To run a query within a React component, call `useGetAllAssetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAssetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAssetsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      page: // value for 'page'
 *      sortOrder: // value for 'sortOrder'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetAllAssetsQuery(
  baseOptions: Apollo.QueryHookOptions<GetAllAssetsQuery, GetAllAssetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAllAssetsQuery, GetAllAssetsQueryVariables>(
    GetAllAssetsDocument,
    options
  )
}
export function useGetAllAssetsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllAssetsQuery, GetAllAssetsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAllAssetsQuery, GetAllAssetsQueryVariables>(
    GetAllAssetsDocument,
    options
  )
}
export type GetAllAssetsQueryHookResult = ReturnType<typeof useGetAllAssetsQuery>
export type GetAllAssetsLazyQueryHookResult = ReturnType<typeof useGetAllAssetsLazyQuery>
export type GetAllAssetsQueryResult = Apollo.QueryResult<
  GetAllAssetsQuery,
  GetAllAssetsQueryVariables
>
export const GetAllFeedbackMessagesDocument = gql`
  query GetAllFeedbackMessages($input: GetFeedbackMessageWhere!) {
    getFeedbackMessagesByFeedbackId(where: $input, limit: 0) {
      messages {
        ...FeedbackMessage
      }
    }
  }
  ${FeedbackMessageFragmentDoc}
`

/**
 * __useGetAllFeedbackMessagesQuery__
 *
 * To run a query within a React component, call `useGetAllFeedbackMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFeedbackMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFeedbackMessagesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAllFeedbackMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAllFeedbackMessagesQuery,
    GetAllFeedbackMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAllFeedbackMessagesQuery, GetAllFeedbackMessagesQueryVariables>(
    GetAllFeedbackMessagesDocument,
    options
  )
}
export function useGetAllFeedbackMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllFeedbackMessagesQuery,
    GetAllFeedbackMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAllFeedbackMessagesQuery, GetAllFeedbackMessagesQueryVariables>(
    GetAllFeedbackMessagesDocument,
    options
  )
}
export type GetAllFeedbackMessagesQueryHookResult = ReturnType<
  typeof useGetAllFeedbackMessagesQuery
>
export type GetAllFeedbackMessagesLazyQueryHookResult = ReturnType<
  typeof useGetAllFeedbackMessagesLazyQuery
>
export type GetAllFeedbackMessagesQueryResult = Apollo.QueryResult<
  GetAllFeedbackMessagesQuery,
  GetAllFeedbackMessagesQueryVariables
>
export const GetAllNotificationsDocument = gql`
  query GetAllNotifications(
    $unreadArgs: GetNotificationInputArgs
    $readArgs: GetNotificationInputArgs
  ) {
    notifications(unreadArgs: $unreadArgs, readArgs: $readArgs) {
      unread {
        pagination {
          currentPage
          perPage
          totalPages
          totalAmount
        }
        notifications {
          isRead
          projectData {
            id
            name
            color
            client {
              name
            }
          }
          activityData {
            id
            name
            type
          }
          senderData {
            avatarUrl
            name
          }
          membersData {
            id
            name
            avatarUrl
          }
          notification {
            updatedAt
            isTechnical
            createdAt
            id
            senderId
            notificationMessage
            personalizedMessage
            purpose
            notificationMembers {
              id
              notificationId
              userId
              isRead
            }
          }
        }
      }
      read {
        pagination {
          currentPage
          perPage
          totalPages
          totalAmount
        }
        notifications {
          isRead
          projectData {
            id
            name
            color
            client {
              name
            }
          }
          activityData {
            id
            name
            type
          }
          senderData {
            avatarUrl
            name
          }
          membersData {
            id
            name
            avatarUrl
          }
          notification {
            isTechnical
            createdAt
            id
            senderId
            notificationMessage
            personalizedMessage
            purpose
            notificationMembers {
              id
              notificationId
              userId
              isRead
            }
          }
        }
      }
    }
  }
`

/**
 * __useGetAllNotificationsQuery__
 *
 * To run a query within a React component, call `useGetAllNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllNotificationsQuery({
 *   variables: {
 *      unreadArgs: // value for 'unreadArgs'
 *      readArgs: // value for 'readArgs'
 *   },
 * });
 */
export function useGetAllNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllNotificationsQuery, GetAllNotificationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAllNotificationsQuery, GetAllNotificationsQueryVariables>(
    GetAllNotificationsDocument,
    options
  )
}
export function useGetAllNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllNotificationsQuery,
    GetAllNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAllNotificationsQuery, GetAllNotificationsQueryVariables>(
    GetAllNotificationsDocument,
    options
  )
}
export type GetAllNotificationsQueryHookResult = ReturnType<typeof useGetAllNotificationsQuery>
export type GetAllNotificationsLazyQueryHookResult = ReturnType<
  typeof useGetAllNotificationsLazyQuery
>
export type GetAllNotificationsQueryResult = Apollo.QueryResult<
  GetAllNotificationsQuery,
  GetAllNotificationsQueryVariables
>
export const GetArtworkFeedbackMessagesDocument = gql`
  query GetArtworkFeedbackMessages($input: FeedbackMessageInput!) {
    getArtworkFeedback(input: $input) {
      feedbackId
      messageId
      createdAt
      userId
      content
      assetId
      position {
        x
        y
        page
      }
    }
  }
`

/**
 * __useGetArtworkFeedbackMessagesQuery__
 *
 * To run a query within a React component, call `useGetArtworkFeedbackMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArtworkFeedbackMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArtworkFeedbackMessagesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetArtworkFeedbackMessagesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetArtworkFeedbackMessagesQuery,
    GetArtworkFeedbackMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetArtworkFeedbackMessagesQuery, GetArtworkFeedbackMessagesQueryVariables>(
    GetArtworkFeedbackMessagesDocument,
    options
  )
}
export function useGetArtworkFeedbackMessagesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetArtworkFeedbackMessagesQuery,
    GetArtworkFeedbackMessagesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GetArtworkFeedbackMessagesQuery,
    GetArtworkFeedbackMessagesQueryVariables
  >(GetArtworkFeedbackMessagesDocument, options)
}
export type GetArtworkFeedbackMessagesQueryHookResult = ReturnType<
  typeof useGetArtworkFeedbackMessagesQuery
>
export type GetArtworkFeedbackMessagesLazyQueryHookResult = ReturnType<
  typeof useGetArtworkFeedbackMessagesLazyQuery
>
export type GetArtworkFeedbackMessagesQueryResult = Apollo.QueryResult<
  GetArtworkFeedbackMessagesQuery,
  GetArtworkFeedbackMessagesQueryVariables
>
export const GetAssetByFileIdDocument = gql`
  query GetAssetByFileId($input: String!) {
    getAssetByFileId(fileId: $input) {
      asset {
        id
        file {
          id
          name
          type
          path
          getUrl
        }
      }
    }
  }
`

/**
 * __useGetAssetByFileIdQuery__
 *
 * To run a query within a React component, call `useGetAssetByFileIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssetByFileIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssetByFileIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAssetByFileIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetAssetByFileIdQuery, GetAssetByFileIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetAssetByFileIdQuery, GetAssetByFileIdQueryVariables>(
    GetAssetByFileIdDocument,
    options
  )
}
export function useGetAssetByFileIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAssetByFileIdQuery, GetAssetByFileIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetAssetByFileIdQuery, GetAssetByFileIdQueryVariables>(
    GetAssetByFileIdDocument,
    options
  )
}
export type GetAssetByFileIdQueryHookResult = ReturnType<typeof useGetAssetByFileIdQuery>
export type GetAssetByFileIdLazyQueryHookResult = ReturnType<typeof useGetAssetByFileIdLazyQuery>
export type GetAssetByFileIdQueryResult = Apollo.QueryResult<
  GetAssetByFileIdQuery,
  GetAssetByFileIdQueryVariables
>
export const GetBriefFeedbackIdDocument = gql`
  query GetBriefFeedbackId($projectId: String!) {
    getProjectBriefFeedbackId(projectId: $projectId) {
      feedbackId
      briefId
    }
  }
`

/**
 * __useGetBriefFeedbackIdQuery__
 *
 * To run a query within a React component, call `useGetBriefFeedbackIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBriefFeedbackIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBriefFeedbackIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetBriefFeedbackIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetBriefFeedbackIdQuery, GetBriefFeedbackIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetBriefFeedbackIdQuery, GetBriefFeedbackIdQueryVariables>(
    GetBriefFeedbackIdDocument,
    options
  )
}
export function useGetBriefFeedbackIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBriefFeedbackIdQuery,
    GetBriefFeedbackIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetBriefFeedbackIdQuery, GetBriefFeedbackIdQueryVariables>(
    GetBriefFeedbackIdDocument,
    options
  )
}
export type GetBriefFeedbackIdQueryHookResult = ReturnType<typeof useGetBriefFeedbackIdQuery>
export type GetBriefFeedbackIdLazyQueryHookResult = ReturnType<
  typeof useGetBriefFeedbackIdLazyQuery
>
export type GetBriefFeedbackIdQueryResult = Apollo.QueryResult<
  GetBriefFeedbackIdQuery,
  GetBriefFeedbackIdQueryVariables
>
export const GetCurrentActivityByIdDocument = gql`
  query GetCurrentActivityById($id: ID!) {
    activities(where: { ids: [$id] }) {
      id
      name
      author
      tasks {
        ...Task
      }
      feedback {
        id
      }
      uploadedDocument {
        id
        name
        type
        getUrl
      }
      composedDocument {
        id
        name
        type
        getUrl
      }
    }
  }
  ${TaskFragmentDoc}
`

/**
 * __useGetCurrentActivityByIdQuery__
 *
 * To run a query within a React component, call `useGetCurrentActivityByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentActivityByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentActivityByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCurrentActivityByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCurrentActivityByIdQuery,
    GetCurrentActivityByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetCurrentActivityByIdQuery, GetCurrentActivityByIdQueryVariables>(
    GetCurrentActivityByIdDocument,
    options
  )
}
export function useGetCurrentActivityByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentActivityByIdQuery,
    GetCurrentActivityByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetCurrentActivityByIdQuery, GetCurrentActivityByIdQueryVariables>(
    GetCurrentActivityByIdDocument,
    options
  )
}
export type GetCurrentActivityByIdQueryHookResult = ReturnType<
  typeof useGetCurrentActivityByIdQuery
>
export type GetCurrentActivityByIdLazyQueryHookResult = ReturnType<
  typeof useGetCurrentActivityByIdLazyQuery
>
export type GetCurrentActivityByIdQueryResult = Apollo.QueryResult<
  GetCurrentActivityByIdQuery,
  GetCurrentActivityByIdQueryVariables
>
export const GetOrganizationRangesDocument = gql`
  query GetOrganizationRanges {
    organizationRanges {
      id
      from
      to
    }
  }
`

/**
 * __useGetOrganizationRangesQuery__
 *
 * To run a query within a React component, call `useGetOrganizationRangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationRangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationRangesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationRangesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrganizationRangesQuery,
    GetOrganizationRangesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetOrganizationRangesQuery, GetOrganizationRangesQueryVariables>(
    GetOrganizationRangesDocument,
    options
  )
}
export function useGetOrganizationRangesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationRangesQuery,
    GetOrganizationRangesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetOrganizationRangesQuery, GetOrganizationRangesQueryVariables>(
    GetOrganizationRangesDocument,
    options
  )
}
export type GetOrganizationRangesQueryHookResult = ReturnType<typeof useGetOrganizationRangesQuery>
export type GetOrganizationRangesLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationRangesLazyQuery
>
export type GetOrganizationRangesQueryResult = Apollo.QueryResult<
  GetOrganizationRangesQuery,
  GetOrganizationRangesQueryVariables
>
export const GetProjectMembersByIdDocument = gql`
  query GetProjectMembersById($projectId: String!) {
    getProjectMembersById(projectId: $projectId) {
      id
      role
      user {
        id
        name
        avatarUrl
      }
    }
  }
`

/**
 * __useGetProjectMembersByIdQuery__
 *
 * To run a query within a React component, call `useGetProjectMembersByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectMembersByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectMembersByIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectMembersByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectMembersByIdQuery,
    GetProjectMembersByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetProjectMembersByIdQuery, GetProjectMembersByIdQueryVariables>(
    GetProjectMembersByIdDocument,
    options
  )
}
export function useGetProjectMembersByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectMembersByIdQuery,
    GetProjectMembersByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetProjectMembersByIdQuery, GetProjectMembersByIdQueryVariables>(
    GetProjectMembersByIdDocument,
    options
  )
}
export type GetProjectMembersByIdQueryHookResult = ReturnType<typeof useGetProjectMembersByIdQuery>
export type GetProjectMembersByIdLazyQueryHookResult = ReturnType<
  typeof useGetProjectMembersByIdLazyQuery
>
export type GetProjectMembersByIdQueryResult = Apollo.QueryResult<
  GetProjectMembersByIdQuery,
  GetProjectMembersByIdQueryVariables
>
export const IsUserExistDocument = gql`
  query IsUserExist($input: UserExistInput!) {
    isUserExist(input: $input) {
      isExist
    }
  }
`

/**
 * __useIsUserExistQuery__
 *
 * To run a query within a React component, call `useIsUserExistQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsUserExistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsUserExistQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useIsUserExistQuery(
  baseOptions: Apollo.QueryHookOptions<IsUserExistQuery, IsUserExistQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<IsUserExistQuery, IsUserExistQueryVariables>(IsUserExistDocument, options)
}
export function useIsUserExistLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<IsUserExistQuery, IsUserExistQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<IsUserExistQuery, IsUserExistQueryVariables>(
    IsUserExistDocument,
    options
  )
}
export type IsUserExistQueryHookResult = ReturnType<typeof useIsUserExistQuery>
export type IsUserExistLazyQueryHookResult = ReturnType<typeof useIsUserExistLazyQuery>
export type IsUserExistQueryResult = Apollo.QueryResult<IsUserExistQuery, IsUserExistQueryVariables>
export const MatchForgotPasswordDocument = gql`
  query matchForgotPassword($input: String!) {
    matchForgotPassword(token: $input) {
      code
    }
  }
`

/**
 * __useMatchForgotPasswordQuery__
 *
 * To run a query within a React component, call `useMatchForgotPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useMatchForgotPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMatchForgotPasswordQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMatchForgotPasswordQuery(
  baseOptions: Apollo.QueryHookOptions<MatchForgotPasswordQuery, MatchForgotPasswordQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MatchForgotPasswordQuery, MatchForgotPasswordQueryVariables>(
    MatchForgotPasswordDocument,
    options
  )
}
export function useMatchForgotPasswordLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MatchForgotPasswordQuery,
    MatchForgotPasswordQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MatchForgotPasswordQuery, MatchForgotPasswordQueryVariables>(
    MatchForgotPasswordDocument,
    options
  )
}
export type MatchForgotPasswordQueryHookResult = ReturnType<typeof useMatchForgotPasswordQuery>
export type MatchForgotPasswordLazyQueryHookResult = ReturnType<
  typeof useMatchForgotPasswordLazyQuery
>
export type MatchForgotPasswordQueryResult = Apollo.QueryResult<
  MatchForgotPasswordQuery,
  MatchForgotPasswordQueryVariables
>
export const MeDocument = gql`
  query Me {
    me {
      ...Me
    }
  }
  ${MeFragmentDoc}
`

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export function useMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>
export const MeWithOrganizationDocument = gql`
  query MeWithOrganization {
    meWithOrganization {
      ...UserOrganization
    }
  }
  ${UserOrganizationFragmentDoc}
`

/**
 * __useMeWithOrganizationQuery__
 *
 * To run a query within a React component, call `useMeWithOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeWithOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeWithOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeWithOrganizationQuery(
  baseOptions?: Apollo.QueryHookOptions<MeWithOrganizationQuery, MeWithOrganizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MeWithOrganizationQuery, MeWithOrganizationQueryVariables>(
    MeWithOrganizationDocument,
    options
  )
}
export function useMeWithOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MeWithOrganizationQuery,
    MeWithOrganizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MeWithOrganizationQuery, MeWithOrganizationQueryVariables>(
    MeWithOrganizationDocument,
    options
  )
}
export type MeWithOrganizationQueryHookResult = ReturnType<typeof useMeWithOrganizationQuery>
export type MeWithOrganizationLazyQueryHookResult = ReturnType<
  typeof useMeWithOrganizationLazyQuery
>
export type MeWithOrganizationQueryResult = Apollo.QueryResult<
  MeWithOrganizationQuery,
  MeWithOrganizationQueryVariables
>
export const OrganizationsDocument = gql`
  query Organizations {
    organizations {
      ...Organization
    }
  }
  ${OrganizationFragmentDoc}
`

/**
 * __useOrganizationsQuery__
 *
 * To run a query within a React component, call `useOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationsQuery(
  baseOptions?: Apollo.QueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OrganizationsQuery, OrganizationsQueryVariables>(
    OrganizationsDocument,
    options
  )
}
export function useOrganizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<OrganizationsQuery, OrganizationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<OrganizationsQuery, OrganizationsQueryVariables>(
    OrganizationsDocument,
    options
  )
}
export type OrganizationsQueryHookResult = ReturnType<typeof useOrganizationsQuery>
export type OrganizationsLazyQueryHookResult = ReturnType<typeof useOrganizationsLazyQuery>
export type OrganizationsQueryResult = Apollo.QueryResult<
  OrganizationsQuery,
  OrganizationsQueryVariables
>
export const ProjectDocument = gql`
  query Project($id: ID!) {
    projects(where: { ids: [$id] }) {
      ...Project
    }
  }
  ${ProjectFragmentDoc}
`

/**
 * __useProjectQuery__
 *
 * To run a query within a React component, call `useProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectQuery(
  baseOptions: Apollo.QueryHookOptions<ProjectQuery, ProjectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options)
}
export function useProjectLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectQuery, ProjectQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProjectQuery, ProjectQueryVariables>(ProjectDocument, options)
}
export type ProjectQueryHookResult = ReturnType<typeof useProjectQuery>
export type ProjectLazyQueryHookResult = ReturnType<typeof useProjectLazyQuery>
export type ProjectQueryResult = Apollo.QueryResult<ProjectQuery, ProjectQueryVariables>
export const ProjectSummariesDocument = gql`
  query ProjectSummaries($archivedType: String) {
    projects(archivedType: $archivedType) {
      ...ProjectSummary
      dueDate
      archivedAt
    }
  }
  ${ProjectSummaryFragmentDoc}
`

/**
 * __useProjectSummariesQuery__
 *
 * To run a query within a React component, call `useProjectSummariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectSummariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectSummariesQuery({
 *   variables: {
 *      archivedType: // value for 'archivedType'
 *   },
 * });
 */
export function useProjectSummariesQuery(
  baseOptions?: Apollo.QueryHookOptions<ProjectSummariesQuery, ProjectSummariesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProjectSummariesQuery, ProjectSummariesQueryVariables>(
    ProjectSummariesDocument,
    options
  )
}
export function useProjectSummariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectSummariesQuery, ProjectSummariesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProjectSummariesQuery, ProjectSummariesQueryVariables>(
    ProjectSummariesDocument,
    options
  )
}
export type ProjectSummariesQueryHookResult = ReturnType<typeof useProjectSummariesQuery>
export type ProjectSummariesLazyQueryHookResult = ReturnType<typeof useProjectSummariesLazyQuery>
export type ProjectSummariesQueryResult = Apollo.QueryResult<
  ProjectSummariesQuery,
  ProjectSummariesQueryVariables
>
export const ProjectTasksDocument = gql`
  query ProjectTasks($id: ID!) {
    tasks(where: { projects: [$id] }) {
      ...Task
    }
  }
  ${TaskFragmentDoc}
`

/**
 * __useProjectTasksQuery__
 *
 * To run a query within a React component, call `useProjectTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTasksQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectTasksQuery(
  baseOptions: Apollo.QueryHookOptions<ProjectTasksQuery, ProjectTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ProjectTasksQuery, ProjectTasksQueryVariables>(
    ProjectTasksDocument,
    options
  )
}
export function useProjectTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ProjectTasksQuery, ProjectTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ProjectTasksQuery, ProjectTasksQueryVariables>(
    ProjectTasksDocument,
    options
  )
}
export type ProjectTasksQueryHookResult = ReturnType<typeof useProjectTasksQuery>
export type ProjectTasksLazyQueryHookResult = ReturnType<typeof useProjectTasksLazyQuery>
export type ProjectTasksQueryResult = Apollo.QueryResult<
  ProjectTasksQuery,
  ProjectTasksQueryVariables
>
export const VerifyInviteTokenDocument = gql`
  query VerifyInviteToken($input: InviteMemberVerifyTokenInput!) {
    inviteMemberVerify(input: $input) {
      payload
    }
  }
`

/**
 * __useVerifyInviteTokenQuery__
 *
 * To run a query within a React component, call `useVerifyInviteTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyInviteTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyInviteTokenQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyInviteTokenQuery(
  baseOptions: Apollo.QueryHookOptions<VerifyInviteTokenQuery, VerifyInviteTokenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<VerifyInviteTokenQuery, VerifyInviteTokenQueryVariables>(
    VerifyInviteTokenDocument,
    options
  )
}
export function useVerifyInviteTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<VerifyInviteTokenQuery, VerifyInviteTokenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<VerifyInviteTokenQuery, VerifyInviteTokenQueryVariables>(
    VerifyInviteTokenDocument,
    options
  )
}
export type VerifyInviteTokenQueryHookResult = ReturnType<typeof useVerifyInviteTokenQuery>
export type VerifyInviteTokenLazyQueryHookResult = ReturnType<typeof useVerifyInviteTokenLazyQuery>
export type VerifyInviteTokenQueryResult = Apollo.QueryResult<
  VerifyInviteTokenQuery,
  VerifyInviteTokenQueryVariables
>
export const GetApprovalsDocument = gql`
  query getApprovals($id: String!) {
    approvals(id: $id) {
      approve {
        status
        userId
      }
      isApproved
    }
  }
`

/**
 * __useGetApprovalsQuery__
 *
 * To run a query within a React component, call `useGetApprovalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetApprovalsQuery(
  baseOptions: Apollo.QueryHookOptions<GetApprovalsQuery, GetApprovalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetApprovalsQuery, GetApprovalsQueryVariables>(
    GetApprovalsDocument,
    options
  )
}
export function useGetApprovalsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetApprovalsQuery, GetApprovalsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetApprovalsQuery, GetApprovalsQueryVariables>(
    GetApprovalsDocument,
    options
  )
}
export type GetApprovalsQueryHookResult = ReturnType<typeof useGetApprovalsQuery>
export type GetApprovalsLazyQueryHookResult = ReturnType<typeof useGetApprovalsLazyQuery>
export type GetApprovalsQueryResult = Apollo.QueryResult<
  GetApprovalsQuery,
  GetApprovalsQueryVariables
>
export const GetBriefDocument = gql`
  query getBrief($id: String) {
    getBrief(projectId: $id) {
      id
      name
      feedback {
        id
      }
      composedDocument {
        id
        name
        type
        path
        getUrl
      }
      uploadedDocument {
        id
        name
        type
        path
        getUrl
      }
    }
  }
`

/**
 * __useGetBriefQuery__
 *
 * To run a query within a React component, call `useGetBriefQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBriefQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBriefQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBriefQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBriefQuery, GetBriefQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<GetBriefQuery, GetBriefQueryVariables>(GetBriefDocument, options)
}
export function useGetBriefLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBriefQuery, GetBriefQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<GetBriefQuery, GetBriefQueryVariables>(GetBriefDocument, options)
}
export type GetBriefQueryHookResult = ReturnType<typeof useGetBriefQuery>
export type GetBriefLazyQueryHookResult = ReturnType<typeof useGetBriefLazyQuery>
export type GetBriefQueryResult = Apollo.QueryResult<GetBriefQuery, GetBriefQueryVariables>
