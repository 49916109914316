import styled from '@emotion/styled'
import { Box } from '@mui/material'

const CreateFormWrapper = styled(Box)`
  border-radius: 5px;
  padding: 60px 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  .title {
    padding-bottom: 20px;
    width: 100%;
    text-align: center;
  }

  .MuiMobileStepper-root {
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding-top: 20px;
  }
`

export { CreateFormWrapper }
