import styled from 'styled-components'
import { colors } from '../../../../../theme/Colors'

export const Wrapper = styled.div`
  background: ${colors.navyGrey[300]};
  width: clamp(140px, 100%, 280px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-radius: 5px;
`
export const AppendTitle = styled.div`
  ${({ theme }) => ({ ...theme.typography?.subtitle2 })};
`
