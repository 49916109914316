/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
/* eslint-disable no-return-await */
/* eslint-disable import/no-unresolved */
import { API_URL } from './constants'
import { cognitoUserPool } from './cognito'
import possibleTypes from 'generated/possibleTypes'
import { setContext } from '@apollo/client/link/context'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'

export interface ApolloNextContext {
  ctx: any
  apolloClient: any
  apolloState: any
  Component: any
}

const httpLink = new HttpLink({
  uri: API_URL,
  credentials: 'include',
})

async function getCurrentUserSession() {
  const cognitoUser = cognitoUserPool.getCurrentUser()
  if (!cognitoUser) {
    return null
  }

  return await new Promise<CognitoUserSession>((resolve, reject) => {
    cognitoUser.getSession((err: Error | null, session: CognitoUserSession) => {
      if (!err) {
        resolve(session)
      } else {
        reject(err)
      }
    })
  })
}

async function getAccessToken() {
  const session = await getCurrentUserSession()
  if (!session) {
    return null
  }
  return session.getAccessToken().getJwtToken()
}

const authLink = setContext(async (_, { headers }) => {
  const accessToken = await getAccessToken()
  const authorization = accessToken ? `Bearer ${accessToken}` : ''

  return {
    headers: { ...headers, authorization },
  }
})

export default function createApolloClient(
  initialState: any,
  ctx?: ApolloNextContext
): ApolloClient<any> {
  // The `ctx` (NextPageContext) will only be present on the server.
  // use it to extract auth headers (ctx.req) or similar.
  // const isServer = Boolean(ctx)

  return new ApolloClient({
    ssrMode: false,
    link: authLink.concat(httpLink),
    cache: createCache().restore(initialState),
    connectToDevTools: true,
  })
}

function createCache() {
  return new InMemoryCache({
    possibleTypes: possibleTypes.possibleTypes,
    typePolicies: {
      AnswerResponse: {
        fields: {
          date: {
            merge(_, date) {
              // The actual date entered by the user doesn't have a timezone
              // component, so we have to drop it here to prevent unexpected
              // timezone issues.
              return date && new Date(date.replace(/Z$/, ''))
            },
          },
        },
      },
    },
  })
}
