import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import { ColumnWrapperProps } from './bt-activity-board.props'
import { colors } from '../../../theme/Colors'

interface IScrollWrapperProps {
  scrollWidth: number
}

/* TEMP */
const GridContainer = styled(Grid)`
  && {
    flex-wrap: nowrap;
    gap: 24px;
    height: 100%;
  }
`
const GridItem = styled(Grid)`
  && {
    min-width: 300px;
    height: 100%;
  }
`
/* TEMP */
const Root = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 40px;
`

const HorizontalScrollContainer = styled.div`
  display: flex;
  gap: 24px;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 90%;
  overflow: auto;
  margin-left: 80px;

  &::-webkit-scrollbar {
    border-radius: 10px;
    height: 10px;
    background-color: ${colors.navyGrey[300]};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.navyGrey[200]};
    border-radius: 10px;
  }

  .append-button {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-transform: capitalize;
  }
`

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .scroll-wrapper {
    height: max-content;
    max-height: 65vh;
    -ms-overflow-style: none;
    scrollbar-width: none;
    background-color: #e5e5e5;
    border-radius: 5px;
  }

  .scroll-wrapper::-webkit-scrollbar {
    display: none;
  }
`

const ColumnWrapper = styled.div`
  display: flex;
  height: auto;
  min-height: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  height: 55vh;
  width: '100%';
  padding: 10px 0;
  flex: 1;
  gap: 15px;
`

const BTActivityBoardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`

export {
  GridContainer,
  GridItem,
  ColumnWrapper,
  ItemWrapper,
  HorizontalScrollContainer,
  Root,
  BTActivityBoardWrapper,
}
