import styled from '@emotion/styled'
import { colors } from 'theme/Colors'

export const BTNavigationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  align-items: center;
  justify-content: center;
  gap: 2px;
  padding-top: 26px;

  input {
    padding: 0;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    width: 22px;
    height: 22px;
    text-align: center;
    background-color: ${colors.white};
    color: ${colors.projectColors.main};
  }

  svg {
    color: ${colors.navyGrey[400]};
    font-size: 16px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      color: ${colors.navyGrey[500]};
    }
  }
`
