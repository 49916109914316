import { TextField } from '@mui/material'
import { ChangeEvent, FC } from 'react'
import { BTFeedbackShareInput } from './bt-feedback-share.presets'
import type { BTFeedbackShareProps } from './bt-feedback-share.props'

export const BTFeedbackShare: FC<BTFeedbackShareProps> = ({ setValue, value, keyDownHandler }) => {
  const inputHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
  }
  return (
    <BTFeedbackShareInput>
      <TextField
        value={value}
        onChange={inputHandler}
        placeholder="Share feedback"
        onKeyDown={keyDownHandler}
        fullWidth
        autoComplete="off"
      />
    </BTFeedbackShareInput>
  )
}
