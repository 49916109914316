import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User } from 'generated/graphql'

interface GlobalState {
  members: User[]
}
const initialState: GlobalState = {
  members: [],
}

const globalSlice = createSlice({
  name: 'globalSlice',
  initialState,
  reducers: {
    setupUsers(state, { payload }: PayloadAction<User[] | undefined>) {
      if (payload) {
        state.members = payload
      }
    },
  },
})

export const { setupUsers } = globalSlice.actions

export default globalSlice.reducer
