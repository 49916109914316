import { FC, useEffect, useState } from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { useDebounce } from 'hooks/shared/useDebounce'
import { BTVersionNavigationWrapper } from './bt-version-navigation.presets'

import type { IBTVersionNavigationProps } from './bt-version-navigation.props'

export const BTVersionNavigation: FC<IBTVersionNavigationProps> = ({
  currentVersion,
  numberOfVersions,
  setCurrentVersion,
}) => {
  const [version, setVersion] = useState(1)
  const prevVersion = () => setVersion(version - 1)
  const nextVersion = () => setVersion(version + 1)

  const debouncedValue = useDebounce(version.toString(), 500)

  useEffect(() => {
    if (debouncedValue) {
      setCurrentVersion(Number.parseInt(debouncedValue, 10))
    }
  }, [debouncedValue])

  useEffect(() => {
    setVersion(currentVersion)
  }, [])

  useEffect(() => {
    setVersion(currentVersion)
  }, [currentVersion])

  return (
    <BTVersionNavigationWrapper>
      {version > 1 && <ArrowBackIosNewIcon onClick={prevVersion} />}
      <span className="text">Version {version}</span>
      {version < numberOfVersions && <ArrowForwardIosIcon onClick={nextVersion} />}
    </BTVersionNavigationWrapper>
  )
}
