/* eslint-disable import/no-extraneous-dependencies */
import Select from 'react-select'
import { styled } from '@mui/system'
import { colors } from '../../../../../../theme/Colors'

export const BTSelect = styled(Select)`
  margin-bottom: 20px;
  width: 100%;

  & div[class*='placeholder'] {
    font-size: 12px;
    line-height: 1.5;
    color: ${colors.projectColors.black};
  }

  & div[class*='singleValue'] {
    font-size: 12px;
    line-height: 1.5;
    color: ${colors.projectColors.black};
  }

  & span[class*='indicatorSeparator'] {
    display: none;
  }
  & div[class*='indicatorContainer'] {
    color: ${colors.projectColors.iconDarkGrey};
    cursor: pointer;
  }
  & div[class*='control'] {
    height: 38px;
    background: ${colors.projectColors.lightGrey};
    border: none;
    box-shadow: none;
  }
  & div[id*='react-select'][id$='listbox'] {
    width: 100%;
    background: ${colors.white};

    & div[class*='option'] {
      font-size: 12px;
      line-height: 1.5;
      color: ${colors.projectColors.black};
      background: ${colors.projectColors.lightGrey};
      cursor: pointer;

      &:hover {
        background: ${colors.navyGrey['200']};
      }
      &:not(&:last-child) {
        margin-bottom: 5px;
      }
    }
    & div[class*='MenuList'] {
      padding: 5px 0;
    }
  }
`
