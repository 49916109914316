/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
import { BTOrganizationSettings } from 'components/bt-organization-settings'
import { BTTeamSettings } from 'components/bt-team-settings/bt-team-settings'
import { RouteObject } from 'react-router-dom'
import { BTUserSettings } from '../../components/bt-user-settings/bt-user-settings'

const UserRoutes: RouteObject[] = [
  { path: 'settings/user-details', element: <BTUserSettings /> },
  { path: 'settings/team', element: <BTTeamSettings /> },
  {
    path: 'settings/organization',
    element: <BTOrganizationSettings />,
  },
]
export { UserRoutes }
