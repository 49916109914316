import { Button } from '@mui/material'
import { memo } from 'react'
import styled from 'styled-components'

const BTFeedbackSendBtnWrapper = styled(Button)`
  && {
    ${({ theme }) => ({ ...theme.typography?.subtitle1 })};
    padding: 10px;
  }
`
const MemoizedBTFeedbackSendBtnWrapper = memo(BTFeedbackSendBtnWrapper)

const BTFeedbackWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
export { BTFeedbackWrapper, BTFeedbackSendBtnWrapper, MemoizedBTFeedbackSendBtnWrapper }
