import { ReactComponent as SearchIcon } from 'assets/images/searchIcon.svg'
import { FC, SyntheticEvent } from 'react'
import { BTProjectSearchWrapper, BTProjectSearchInput } from './project-search.presets'
import { IProjectSearch } from './project-search.props'

export const BTProjectSearch: FC<IProjectSearch> = ({ placeholder, handledValue, handler }) => {
  const onChange = (evt: SyntheticEvent<HTMLInputElement>) => {
    handler(evt.currentTarget.value)
  }

  return (
    <BTProjectSearchWrapper>
      <SearchIcon />
      <BTProjectSearchInput placeholder={placeholder} onChange={onChange} value={handledValue} />
    </BTProjectSearchWrapper>
  )
}
