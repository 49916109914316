import { PDFDocumentProxy } from 'pdfjs-dist'
import { fileConvert, IFileFromConvertor } from 'helpers/file/fileConvert'
import { Dispatch, SetStateAction } from 'react'
import { GlobalAsset } from 'generated/graphql'

export const convertFileToAsset = (
  file: File,
  setCurrentAssetFile: Dispatch<SetStateAction<IFileFromConvertor[] | null>>,
  fileName?: string
) => {
  const fileToconvert = {
    name: fileName ?? file?.name,
    content: file,
  }

  fileConvert([fileToconvert], setCurrentAssetFile)
}

export const setPdfPages = (
  { numPages }: PDFDocumentProxy,
  setPages: Dispatch<SetStateAction<number[]>>
) => {
  const allPages: number[] = []

  for (let i = 1; i <= numPages; i++) {
    allPages.push(i)
  }

  setPages(allPages)
}

export const sortFileByVersionsClb = (
  { file: firstFile }: GlobalAsset,
  { file: secondFile }: GlobalAsset
) => {
  if (
    firstFile &&
    secondFile &&
    typeof firstFile.version === 'number' &&
    typeof secondFile.version === 'number'
  ) {
    return firstFile.version - secondFile.version
  }

  return 0
}
