/* eslint-disable import/extensions */
import axios from 'axios'
import { toastMessage } from '../../utils/ToastErrorMessage'

export const composedUpload = async (
  content: string,
  signedUrl: string | undefined
): Promise<void> => {
  if (content && signedUrl) {
    try {
      const options = { headers: { 'Content-Type': 'text/html' } }
      await axios.put(signedUrl, content, options)
    } catch (err) {
      const error = err as Error
      toastMessage({ message: error.message })

      throw err
    }
  }
}
