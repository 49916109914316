type SortinigBooleanParamType = null | undefined | boolean
type SortinigDateParamType = null | undefined | Date

export const sortByAsc = <T>(first: T, second: T) => {
  if (first > second) {
    return -1
  }

  if (first < second) {
    return 1
  }

  return 0
}

export const sortByDesc = <T>(first: T, second: T) => {
  if (first < second) {
    return -1
  }

  if (first > second) {
    return 1
  }

  return 0
}

export const sortByBoolean = (
  firstItem: SortinigBooleanParamType,
  secondItem: SortinigBooleanParamType
) => {
  if (typeof firstItem === 'boolean' && typeof secondItem === 'boolean') {
    if (firstItem === secondItem) return 0
    if (firstItem) return -1
    if (secondItem) return 1
  }
  return 0
}

export const feedbackSortCallback = (
  firstItem: SortinigBooleanParamType,
  secondItem: SortinigBooleanParamType,
  firstDate: SortinigDateParamType,
  secondDate: SortinigDateParamType
) => {
  if (typeof firstItem === 'boolean' && typeof secondItem === 'boolean') {
    if (firstItem === secondItem) {
      if (firstDate && secondDate) {
        return new Date(firstDate) > new Date(secondDate) ? 1 : -1
      }
    }
    if (firstItem) return -1
    if (secondItem) return 1
  }
  return 0
}
