/* eslint-disable no-underscore-dangle */
import { TaskFragment, AllTasksDocument, useCreateTaskMutation } from 'generated/graphql'

export function useCreateTask() {
  return useCreateTaskMutation({
    errorPolicy: 'ignore',
    update: (cache, { data }) => {
      if (data) {
        const exsistedTask = cache.readQuery({
          query: AllTasksDocument,
        }) as { tasks: TaskFragment[] }

        cache.writeQuery({
          query: AllTasksDocument,
          data: {
            tasks: [
              ...exsistedTask.tasks,
              {
                ...data.createTask.task,

                rowVersion: 1,
              },
            ],
          },
        })
      }
    },
  })
}
