import { FC } from 'react'
import { Radio } from '@mui/material'
import { useWizard } from 'react-use-wizard'
import { setPronouns } from 'redux/PostSignupFormSlice'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { BTPostSignupFormContainer } from './bt-post-signup-form-wrapper'
import { BTPronounsText, BTPronounsWrapper } from '../bt-post-signup-form.presets'

export const BTPostSignupFormPronouns: FC = () => {
  const { nextStep, activeStep, stepCount } = useWizard()
  const dispatch = useAppDispatch()
  const { pronouns } = useAppSelector((state) => state.postSignupForm)

  const checkBoxMap = [
    'She / her / hers',
    'He / him / his',
    'They / them / theirs',
    'Ze / hir / hirs',
    'Ze / zir / zirs',
    'Xe / xem / xyrs',
  ]

  return (
    <BTPostSignupFormContainer
      title="Choose your pronouns"
      btnTitle="Next"
      goNext={nextStep}
      content={
        <div>
          {checkBoxMap.map((item) => (
            <BTPronounsWrapper>
              <Radio
                id={item}
                checked={item === pronouns}
                onChange={() => dispatch(setPronouns(item))}
              />
              <BTPronounsText htmlFor={item}>{item}</BTPronounsText>
            </BTPronounsWrapper>
          ))}
        </div>
      }
      activeStep={activeStep}
      stepCount={stepCount}
    />
  )
}
