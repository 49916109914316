import { Box } from '@mui/material'
import styled from '@emotion/styled'
import { colors } from '../../theme/Colors'

export const BTOrganizationSettingsWrapper = styled(Box)`
  form {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 60px 80px;
  }

  .head-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-family: Poppins;
      font-size: 18px;
      font-weight: 700;
      line-height: 27px;
      color: ${colors.black};
    }

    button {
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-transform: capitalize;

      svg {
        font-size: 15px;
      }
    }
  }

  .main-block {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .input-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 240px;

    .Mui-focused {
      fieldset {
        border: 1px solid ${colors.projectColors.main};
      }
    }

    legend {
      width: 0;
    }

    fieldset {
      border: 1px solid ${colors.navyGrey[400]};
    }

    span {
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      padding: 5px;
      color: ${colors.navyGrey[400]};
    }

    .MuiSelect-select {
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      padding: 6px 10px;
    }

    input {
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      padding: 10px 14px;
    }
  }
  .save-button {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    text-transform: capitalize;
  }
`
