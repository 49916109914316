import { TabsState } from '../redux/HeaderSlice'

const getFragmentId = (itemName: string, itemId: string): string => {
  const fragmentId = `${itemName}:${itemId}`

  return fragmentId
}

export const saveHeaderTabs = (itemName: string, itemId: string, tabs: TabsState[]) => {
  localStorage.setItem(getFragmentId(itemName, itemId), JSON.stringify(tabs))
}

export const getHeaderTabs = (itemName: string, itemId: string): TabsState[] => {
  const id = getFragmentId(itemName, itemId)

  const item = localStorage.getItem(id)

  if (item) {
    return JSON.parse(item)
  }

  return []
}
