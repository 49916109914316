/* eslint-disable max-len */
import styled from '@emotion/styled'
import { Box } from '@mui/material'
import { colors } from '../../theme/Colors'

export const BTUserSettingWrapper = styled(Box)`
  border-radius: 5px;
  width: fit-content;
  padding: 60px 80px;

  .main-block {
    display: flex;
    flex-direction: column;

    .top-block {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 30px;

      .title {
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
        color: ${colors.black};
      }

      button {
        font-size: 12px;
        line-height: 18px;
        text-transform: capitalize;

        svg {
          font-size: 15px;
        }
      }
    }

    .settings-block {
      flex-direction: row;
      display: flex;
      gap: 0 50px;

      .inputs-block {
        display: flex;
        flex-direction: column;
        gap: 30px;

        .Mui-focused {
          fieldset {
            border: 1px solid ${colors.projectColors.main};
          }
        }

        fieldset {
          border: 1px solid ${colors.navyGrey[400]};
          legend {
            width: 0;
          }
        }

        span,
        input {
          font-size: 12px;
          line-height: 18px;
          color: ${colors.projectColors.black};
          padding: 10px 14px;
        }

        span {
          color: ${colors.navyGrey[400]};
          padding-bottom: 5px;
        }

        .input-block {
          flex-direction: column;
          width: 240px;
          display: flex;
          justify-content: flex-start;

          .input-label {
            padding: 0;
          }
        }

        .save-button {
          text-transform: capitalize;
          font-family: Poppins;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
        }
      }

      .avatar-block {
        display: flex;
        flex-direction: column;

        .avatar-title {
          font-size: 12px;
          line-height: 18px;
          color: ${colors.navyGrey[400]};
          padding-bottom: 5px;
        }

        .avatar {
          position: relative;

          .holder {
            position: absolute;
            width: 160px;
            height: 160px;
            top: 0;
          }
        }

        .file-label {
          max-width: 160px;
          background-color: ${colors.navyGrey[300]};
          padding: 10px;
          border-radius: 5px;
          margin-top: 4px;
          color: ${colors.navyGrey[600]};

          &:hover {
            cursor: pointer;
          }

          .inner-wrapper {
            display: flex;

            .text {
              margin-left: 10px;

              div {
                font-size: 10px;
                line-height: 10px;
                text-align: center;

                &:first-of-type {
                  font-weight: 700;
                }
              }
            }
          }
        }

        .MuiSlider-root {
          .MuiSlider-rail,
          .MuiSlider-track {
            height: 10px;
          }

          .MuiSlider-thumb {
            height: 16px;
            width: 16px;
            border: 2px solid #fff;
            box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%),
              0px 1px 8px 0px rgb(0 0 0 / 12%);
          }
        }
      }
    }
  }
`
