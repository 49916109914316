/* eslint-disable no-nested-ternary */
import { useParams, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState, MouseEvent } from 'react'
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api'
import { ActivityComposePage } from 'layouts/activity-compose-page'
import { useGetActivityByIdQuery } from '../../../generated/graphql'
import { BTLoader } from '../../shared/bt-loader'
import { useFileDownload } from '../../../hooks/file/useFileDownload'
import { useGetComposeDocument } from '../../../hooks/compose/useGetComposeDocument'
import { BTBriefDocument } from '../../Brief/bt-brief-document/bt-brief-document'
import { toastNotifications } from '../../../helpers/toastNotifications'
import { BTFileView } from '../../Brief/bt-set-brief/components/bt-file-view'
import { fileConvert, IFileFromConvertor } from '../../../helpers/file/fileConvert'

export const ActivityPage = () => {
  const { activityId } = useParams()
  const { pathname } = useLocation()
  const {
    data: activityData,
    loading,
    refetch,
    error: activityError,
  } = useGetActivityByIdQuery({
    variables: { input: activityId! || '' },
  })
  const [dataLoading, setDataLoading] = useState<boolean>(false)
  const [activityFile, setActivityFile] = useState<IFileFromConvertor[] | null>(null)
  const [activityFileForDownload, setActivityFileForDownload] = useState<Blob | undefined>()
  const [pageNumber, setPageNumber] = useState(1)
  const [numPages, setNumPages] = useState<number | null>(null)
  const [activityCompose, setActivityCompose] = useState<string | null>()
  const fileDownload = useFileDownload()
  const composeDownload = useGetComposeDocument()

  const navigate = useNavigate()

  const uploadedDocument = activityData?.getActivityById.uploadedDocument
  const composedDocument = activityData?.getActivityById.composedDocument

  /** File logic */
  const getFile = async () => {
    try {
      setDataLoading(true)
      const uploadedDocumentId = activityData?.getActivityById?.uploadedDocument?.id
      const response = await fileDownload(uploadedDocumentId)

      const fileToconvert = {
        name: activityData?.getActivityById.uploadedDocument?.name,
        content: response,
      }
      fileConvert([fileToconvert], setActivityFile)
      setActivityFileForDownload(response)
      setDataLoading(false)
    } catch (err) {
      setDataLoading(false)
      const error = err as Error
      toastNotifications(error.message).error()
    }
  }

  const pagesHandler = (e: MouseEvent<HTMLButtonElement>) => {
    switch (e.currentTarget.id) {
      case 'next':
        setPageNumber(pageNumber + 1)
        break
      case 'prev':
        setPageNumber(pageNumber - 1)
        break

      default:
        break
    }
  }
  const onDocumentLoadSuccess = (pdf: PDFDocumentProxy) => {
    setNumPages(pdf.numPages)
  }

  /** Compose document logic */
  const getComposedDocument = async () => {
    try {
      const activityComposedDocumentId = activityData?.getActivityById.composedDocument?.id
      const response = await composeDownload(activityComposedDocumentId)
      if (response) {
        const text = `${response}`
        setActivityCompose(text)
      }
    } catch (err) {
      const error = err as Error
      toastNotifications(error.message).error()
    }
  }

  const renderActivity = () => {
    if (uploadedDocument) {
      return (
        <ActivityComposePage>
          <BTFileView
            file={activityFile}
            pageNumber={pageNumber}
            numPages={numPages}
            onLoadSuccess={onDocumentLoadSuccess}
            pagesHandler={pagesHandler}
            fileForDownload={activityFileForDownload}
          />
        </ActivityComposePage>
      )
    }

    if (composedDocument) {
      if (activityCompose) {
        return (
          <ActivityComposePage>
            <BTBriefDocument initialText={activityCompose} />
          </ActivityComposePage>
        )
      }
    }

    return <BTLoader />
  }

  useEffect(() => {
    if (uploadedDocument) {
      getFile()
    }
  }, [uploadedDocument])

  useEffect(() => {
    if (composedDocument?.id) {
      setDataLoading(true)
      refetch({ input: activityId! })
      getComposedDocument()
      setDataLoading(false)
    }
  }, [composedDocument?.id])

  useEffect(() => {
    if (activityError) {
      navigate('/404')
    }
  }, [activityError])

  return dataLoading || loading ? (
    <BTLoader />
  ) : uploadedDocument || composedDocument ? (
    renderActivity()
  ) : (
    <Navigate to={pathname.replace(`${activityId}`, `${activityId}/create/compose`)} />
  )
}
