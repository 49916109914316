/* eslint-disable consistent-return */
/* eslint-disable import/extensions */
import axios from 'axios'
import { toastMessage } from '../../utils/ToastErrorMessage'

export const fileLoad = async (signedUrl: string | undefined): Promise<Blob | undefined> => {
  if (signedUrl) {
    try {
      const response = await axios.get<Blob>(signedUrl, {
        responseType: 'blob',
      })

      return response?.data
    } catch (err) {
      const error = err as Error
      toastMessage({ message: error.message })

      throw err
    }
  }
}
