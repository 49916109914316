import { ProjectFragment, ProjectFragmentDoc, useCreateMilestonesMutation } from 'generated/graphql'

export function useCreateMilestone(project: ProjectFragment) {
  return useCreateMilestonesMutation({
    update: (cache, { data }) => {
      if (data) {
        const newProjRef = cache.readFragment<ProjectFragment>({
          id: cache.identify(project),
          fragment: ProjectFragmentDoc,
          fragmentName: 'Project',
        })
        if (newProjRef) {
          cache.writeFragment({
            id: cache.identify(project),
            fragment: ProjectFragmentDoc,
            fragmentName: 'Project',
            data: {
              ...newProjRef,
              milestones: [...newProjRef?.milestones, data.createMilestone.milestone],
            },
          })
        }
      }
    },
  })
}
