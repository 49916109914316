import { colors } from 'theme/Colors'
import styled from 'styled-components'

const drawerWidth = 100

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  position: relative;
`
const BTActionsBar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 52px 40px;
  background: ${colors.white};
  border: 1px solid ${colors.projectColors.iconGrey};
  border-top: none;
  width: ${drawerWidth}px;
  align-self: flex-end;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
`
export { BTActionsBar, Wrapper }
