import styled from '@emotion/styled'
import { colors } from '../../../theme/Colors'

export const BTModalWindowWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  background-color: ${colors.white};
  border-radius: 5px;
  outline: none;
`
