import { GetBriefQuery } from '../../../generated/graphql'

export interface BTSetBriefProps {
  briefData: GetBriefQuery
}

export enum BRIEF_TYPES {
  UPLOAD = 'upload',
  COMPOSE = 'compose',
  TEMPLATE = 'template',
}
