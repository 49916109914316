/* eslint-disable max-len */
import { assign } from 'xstate'
import { Context, Event, View } from './bt-apps-send.types'
import { BTSendActivityEntryStep } from '../../../components/SidebarApps/bt-activity-send/components/bt-entry-step'
import { BTConfirmStep } from '../../../components/SidebarApps/bt-activity-send/components/bt-confirm-step'
import { BTLastStep } from '../../../components/SidebarApps/bt-activity-send/components/bt-last-step'

export const nameToView: Record<string, View> = {
  entry: {
    Component: <BTSendActivityEntryStep />,
    step: 0,
  },
  confirm: {
    Component: <BTConfirmStep />,
    step: 1,
  },
  last: {
    Component: <BTLastStep />,
    step: 2,
  },
}

export const changeView = assign<Context, Event>({
  currentView: (_, __, { action }) => {
    if (typeof action.payload !== 'string') {
      throw new Error('Action payload should be string')
    }

    return nameToView[action.payload]
  },
})
