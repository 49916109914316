import styled from 'styled-components'

const ComposePageWrapper = styled.div<{ marginTop?: number }>`
  display: flex;
  width: 100%;
  height: inherit;
  justify-content: space-between;
  @media screen and (max-height: 780px) {
    height: calc(90vh - ${({ marginTop }) => `${marginTop}px`});
  }
`
const ComposePageWrapperSub = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;

  .scrol-wrapper-block {
    height: 82vh;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`

export { ComposePageWrapper, ComposePageWrapperSub }
