import { FileTypes } from '../../../generated/graphql'

export interface IPrepareForUploadObj {
  file: File
  url: string
  path: string
  name: string
}

export const chooseAllowedFileTypes = (key: string) => {
  switch (key) {
    case 'ai':
      return FileTypes.Ai
    case 'doc':
      return FileTypes.Doc
    case 'docx':
      return FileTypes.Docx
    case 'gif':
      return FileTypes.Gif
    case 'html':
      return FileTypes.Html
    case 'img':
      return FileTypes.Img
    case 'indd':
      return FileTypes.Indd
    case 'jpeg':
      return FileTypes.Jpeg
    case 'pdf':
      return FileTypes.Pdf
    case 'png':
      return FileTypes.Png
    case 'ppt':
      return FileTypes.Ppt
    case 'pptx':
      return FileTypes.Pptx
    case 'psd':
      return FileTypes.Psd
    case 'svg':
      return FileTypes.Svg
    case 'xlsx':
      return FileTypes.Xlsx

    default:
      return FileTypes.Xls
  }
}
