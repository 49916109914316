import React, { useState, FC, useEffect } from 'react'
import { BTQuestionsPadsWrapper } from './bt-questions-page-pads.presets'
import { BTQuestionsPad } from './components/bt-questions-page-pad'
import { v4 } from 'uuid'
import type { BTQuestionPadsProps } from './bt-questions-page-pads.props'
import { mapQuestionsPads } from './bt-questions-page-pads.helpers'
import { DataTransform } from 'services/dataTransform'
import { useAppSelector } from 'redux/store'

export const BTQuestionPads: FC<BTQuestionPadsProps> = ({
  pads = [],
  question,
  handler,
  defaultPads,
}) => {
  const { currentDeliverable } = useAppSelector((state) => state.sidebarApps)
  const [state, setState] = useState(defaultPads || mapQuestionsPads(pads))
  const selectHandler = (name: string) => {
    setState((prev) => {
      if (!prev) return prev
      const result = prev.map((element) => {
        if (element.name === name) {
          return { name, isSelected: !element.isSelected }
        }
        return element
      })
      return result
    })
  }
  if (!state) {
    return null
  }
  useEffect(() => {
    const { questionTypeId, id } = question
    handler({
      type: questionTypeId,
      questionId: id,
      content: DataTransform.serialize(state),
      deliverableId: currentDeliverable.relationId || currentDeliverable.id,
    })
  }, [state])
  return (
    <BTQuestionsPadsWrapper>
      {state.map(({ isSelected, name }) => (
        <BTQuestionsPad
          key={v4()}
          isSelected={isSelected}
          name={name}
          selectHandler={selectHandler}
        />
      ))}
    </BTQuestionsPadsWrapper>
  )
}
