import { forwardRef } from 'react'
import { useAppSelector } from 'redux/store'
import { useParams } from 'react-router-dom'
import { getNavigationLink } from 'helpers/navigation'
import { BTCardLabel } from '../../shared/bt-card-label'
import { BTMoreButton } from '../../shared/bt-more-button'
import { BTIconButtons } from '../../shared/bt-icon-buttons'
import { BTCopyLinkButton } from './components/copy-link-button'
import type { ActivityCardProps } from './bt-activity-card.props'
import { useArchiveActivityCard } from 'hooks/activity/useArchiveActivityCard'
import { useShouldBeDisabledForRoles } from 'helpers/CheckUserRoleAccess/CheckUserRoleAccess'
import { Raw, Title, Wrapper, InlineDivider, ItemsContainer } from './bt-activity-card.presets'

export const BTActivityCard = forwardRef<HTMLDivElement, ActivityCardProps>(
  ({ activity, isDragging, ...rest }, ref) => {
    const [activeActivity] = useArchiveActivityCard()
    const { id: projectId } = useParams()
    const isNew = !activity.viewedAt || activity.viewedAt < activity.updatedAt
    const archiveHandler = async () => {
      await activeActivity({
        variables: { input: { id: activity.id, archived: true } },
      })
    }

    const { id, composedDocument, uploadedDocument, type } = activity

    const navigateLink = getNavigationLink(
      composedDocument!,
      uploadedDocument!,
      type,
      id,
      projectId!
    )

    const userId = useAppSelector((state) => state.login?.whoami?.id)
    const activityAuthor = activity?.author
    const isAuthor = userId === activityAuthor

    const shouldBeDisabledFor = useShouldBeDisabledForRoles()
    const shouldBeHided = shouldBeDisabledFor(['external'], isAuthor)

    return (
      <Wrapper isDragging={isDragging} ref={ref} {...rest}>
        <Raw>
          <ItemsContainer>
            <Title>{activity.name}</Title>
            <InlineDivider divideWidth={10} />
            {isNew ? <BTCardLabel title="NEW" isTransparent /> : null}
          </ItemsContainer>
          <ItemsContainer>
            <BTIconButtons
              buttonsProps={[
                {
                  iconComponent: <BTCopyLinkButton path={navigateLink} />,
                },
              ]}
            />
            {shouldBeHided ? null : (
              <BTIconButtons
                buttonsProps={[
                  {
                    color: '#BDBDBD',
                    actionCallback: () => { },
                    iconComponent: (
                      <BTMoreButton items={[{ line: 'Archive', handler: archiveHandler }]} />
                    ),
                  },
                ]}
              />
            )}
          </ItemsContainer>
        </Raw>
      </Wrapper>
    )
  }
)
