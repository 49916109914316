import { FC } from 'react'
import { Box, Pagination } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import type { IBTActivityListViewProps } from './bt-activity-list-view.props'
import { BTActivityListViewWrapper } from './bt-activity-list-view.presets'
import { BTHeaderSectionItem } from './bt-header-section-item'
import { BTCardLabel } from '../bt-card-label'
import { colors } from '../../../theme/Colors'
import { BTMoreButton } from '../bt-more-button'

export const BTActivityListView: FC<IBTActivityListViewProps> = ({
  headerSections,
  itemsList,
  pageCount,
  isNewListItem,
  currentPage,
  paginationHandler,
  headerSectionBackground,
}) => {
  const MAIN_SECTION_WIDTH_IN_PERCENT = isNewListItem ? 95 : 100
  const sectionWidth = MAIN_SECTION_WIDTH_IN_PERCENT / headerSections.length

  return (
    <BTActivityListViewWrapper
      headerSectionWidth={sectionWidth}
      isNewListItem={isNewListItem}
      headerSectionBackground={headerSectionBackground}
    >
      <div className="main-wrapper">
        <div className="header-sections-block">
          {isNewListItem && <div className="isNew" />}
          {headerSections.map(({ title, sectionLeftAdorment }) => (
            <BTHeaderSectionItem
              title={title}
              sectionLeftAdorment={sectionLeftAdorment}
              key={uuidv4()}
            />
          ))}
        </div>
        <div className="main-layout">
          {itemsList.map(({ itemSections, handlerActions, isNew }) => (
            <Box sx={{ boxShadow: 3 }} className="list-item" key={uuidv4()}>
              {isNewListItem && (
                <div className="isNew">
                  {isNew && (
                    <BTCardLabel
                      bgColor={colors.projectColors.main}
                      isTransparent
                      textColor={colors.projectColors.main}
                      title="NEW"
                    />
                  )}
                </div>
              )}
              {itemSections.map((data) => (
                <div className="list-item-section" key={uuidv4()}>
                  {data}
                </div>
              ))}
              {handlerActions ? (
                <BTMoreButton
                  items={handlerActions?.map(({ action, label }) => ({
                    handler: action,
                    line: label,
                  }))}
                />
              ) : (
                <div className="fake-button" />
              )}
            </Box>
          ))}
        </div>
      </div>

      {pageCount && pageCount > 1 && (
        <Pagination
          className="pagination-block"
          count={pageCount}
          page={currentPage}
          onChange={paginationHandler}
        />
      )}
    </BTActivityListViewWrapper>
  )
}
