import { Button, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { FC } from 'react'
import type { BTAppendColumnProps } from './bt-append-column.props'
// eslint-disable-next-line max-len
import { useCreateMilestone } from '../../../../../hooks/activity/useCreateMilestone'

export const BTAppendColumn: FC<BTAppendColumnProps> = ({ columnCounter, projectId, project }) => {
  const [createMilestone, state] = useCreateMilestone(project)
  const appendMilestone = async () => {
    const milestone = { name: `Milestone ${columnCounter}`, id: projectId }
    await createMilestone({
      variables: milestone,
    })
  }
  return (
    <Button
      variant="contained"
      sx={{
        boxShadow: 3,
        padding: '8px 10px',
        minWidth: '160px',
        justifyContent: 'space-between',
      }}
      className="append-button"
      onClick={appendMilestone}
    >
      Add Milestone
      <AddIcon />
    </Button>
  )
}
