import { Deliverable, DeliverableFromQuery } from 'types/shared'

export function mapDeliverables(
  source: Array<DeliverableFromQuery>,
  target: Deliverable[]
): Array<DeliverableFromQuery> {
  return source.reduce((acc, deliverable) => {
    let flag = true
    if (deliverable.name === 'Packaging Artwork') {
      return acc
    }
    target.forEach((targetDeliverable) => {
      if (targetDeliverable.id === deliverable.id) {
        flag = false
      }
    })
    flag ? acc.push(deliverable) : acc
    return acc
  }, [] as DeliverableFromQuery[])
}
