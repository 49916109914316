/* eslint-disable prettier/prettier */
import { FC } from 'react'
import { Box } from '@material-ui/core'
import LoadingButton from '@mui/lab/LoadingButton'
import { BTPostSignupFormContainerProps } from './bt-post-signup-form-wrapper.props'
import {
  BTDot,
  BTDotsWrapper,
  BTPostSignupFormTitle,
  BTPostSignupFormWrapper,
  BTPostSignupFormBtnText,
} from '../../bt-post-signup-form.presets'

export const BTPostSignupFormContainer: FC<BTPostSignupFormContainerProps> = ({
  title,
  goNext,
  loading,
  content,
  disabled,
  btnTitle,
  stepCount,
  activeStep,
}) => {
  return (
    <BTPostSignupFormWrapper>
      <Box className="wrapper" sx={{ boxShadow: 3 }}>
        <BTPostSignupFormTitle>{title}</BTPostSignupFormTitle>
        {content}
        <LoadingButton
          variant="contained"
          fullWidth
          onClick={goNext}
          disabled={disabled}
          loading={loading}
        >
          <BTPostSignupFormBtnText>{btnTitle}</BTPostSignupFormBtnText>
        </LoadingButton>
        <BTDotsWrapper>
          {[...Array(stepCount)].map((_wizard, index) => (
            <BTDot
              style={{
                backgroundColor:
                  activeStep === index ? 'rgba(0, 102, 255, 1)' : 'rgba(226, 226, 226, 1)',
              }}
            />
          ))}
        </BTDotsWrapper>
      </Box>
    </BTPostSignupFormWrapper>
  )
}
