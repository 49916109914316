import { useMilestoneName } from 'hooks/activity/useMilestoneName'
import {
  FC,
  FocusEvent,
  KeyboardEvent,
  KeyboardEventHandler,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react'
import { getEvent } from './bt-editable-title.helpers'
import { Title } from './bt-editable-title.presets'
import type { BTEditableTitleProps } from './bt-editable-title.props'
export const BTEditableTitle: FC<BTEditableTitleProps> = ({ children, id }) => {
  const [updateName] = useMilestoneName()
  const [state, setState] = useState(false)
  const ref = useRef<HTMLDivElement | null>(null)
  const editableHandler = () => {
    setState(true)
  }
  const checkAndUpdateName = async (name: string = '') => {
    setState(false)
    if (children?.toString() !== name) {
      await updateName({ variables: { id, name } })
    }
  }
  const handleName = async (event: FocusEvent<HTMLDivElement> | KeyboardEvent<HTMLDivElement>) => {
    const name = ref.current?.innerText
    if (getEvent(event) && event.code === 'Enter') {
      return checkAndUpdateName(name)
    }
    if (!getEvent(event)) {
      return checkAndUpdateName(name)
    }
  }

  useEffect(() => {
    if (state && ref.current) {
      ref.current.focus()
    }
  }, [state])
  return (
    <Title
      suppressContentEditableWarning={true}
      contentEditable={state}
      onClick={editableHandler}
      onBlur={handleName}
      onKeyDown={handleName}
      ref={ref}
    >
      {children}
    </Title>
  )
}
