import styled from '@emotion/styled'
import { colors } from '../../../../theme/Colors'

export const BTHeaderSectionItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  color: ${colors.navyGrey[400]};

  span {
    font-size: 10px;
    line-height: 10px;
    padding-left: 15px;
  }

  div {
    width: 20px !important;
    display: flex;
    flex-direction: column;

    svg {
      height: 20px;
      width: 20px;
      margin-bottom: -8px;
      transition: all 0.2s ease-in-out;
    }

    svg:hover {
      color: ${colors.projectColors.black};
    }

    svg:nth-child(2) {
      margin-bottom: 0;
    }
  }
`
