import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TabType } from 'router/bt-header-routes/bt-header-routes.types'

export enum ActionButtonIconType {
  HORIZONTAL_MORE,
  VERTICAL_MORE,
  FAVOURITE,
  ADD,
  SETTINGS,
}

export interface TabsState {
  path: string
  name: string
  added?: boolean
  type?: TabType
}

export interface AssigneeState {
  imageUrl: string
  name: string
  isOwner: boolean
}

export interface SizesState {
  width: number
  height: number
}

export interface ActionButtonsState {
  iconType: ActionButtonIconType
}

export interface HeaderModalsState {
  isHide: boolean
  isProjectToolsModalOpened: boolean
  isEditSettingsModalOpened: boolean
  isEditAssigneeModalOpened: boolean
}

const initialState: {
  tabs: Array<TabsState>
  title: string
  clientName: string
  deadlineTime: string
  assignees: Array<AssigneeState>
  sizes: SizesState
  isHide: boolean
  modals: HeaderModalsState
  actionButtons?: Array<ActionButtonsState>
} = {
  tabs: [],
  title: '',
  clientName: '',
  deadlineTime: '',
  assignees: [],
  sizes: {
    width: 0,
    height: 0,
  },
  modals: {
    isHide: true,
    isProjectToolsModalOpened: false,
    isEditSettingsModalOpened: false,
    isEditAssigneeModalOpened: false,
  },
  isHide: false,
}

const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    setupHeaderTabs(state, { payload }: PayloadAction<Array<TabsState>>) {
      state.tabs = payload
    },
    setHeaderTitle(state, { payload }: PayloadAction<string>) {
      state.title = payload
    },
    setHeaderClientName(state, { payload }: PayloadAction<string>) {
      state.clientName = payload
    },
    setHeaderDeadlineTime(state, { payload }: PayloadAction<string>) {
      state.deadlineTime = payload
    },
    setHeaderAssignees(state, { payload }: PayloadAction<Array<AssigneeState>>) {
      state.assignees = payload
    },
    setHeaderSizes(state, { payload }: PayloadAction<SizesState>) {
      state.sizes = payload
    },
    setHeaderModalsState(state, { payload }: PayloadAction<HeaderModalsState>) {
      state.modals = payload
    },
    setHeaderModalShow(state, { payload }: PayloadAction<boolean>) {
      state.modals.isHide = payload
    },
    hideHeader(state) {
      state.isHide = true
    },
    showHeader(state) {
      state.isHide = false
    },
    setHeaderActionButtons(state, { payload }: PayloadAction<Array<ActionButtonsState>>) {
      state.actionButtons = payload
    },
    toggleHeaderVisibility(state, { payload }: PayloadAction<boolean>) {
      state.isHide = !payload
    },
    addSingleTab(state, { payload }: PayloadAction<TabsState>) {
      state.tabs.push(payload)
    },
  },
})

export { headerSlice }
export const {
  setupHeaderTabs,
  setHeaderDeadlineTime,
  setHeaderAssignees,
  setHeaderClientName,
  setHeaderSizes,
  setHeaderTitle,
  hideHeader,
  showHeader,
  toggleHeaderVisibility,
  setHeaderActionButtons,
  addSingleTab,
  setHeaderModalsState,
  setHeaderModalShow,
} = headerSlice.actions
