import styled from 'styled-components'
import DeleteIcon from '@mui/icons-material/Delete'
import { colors } from '../../../theme/Colors'

export const LoaderWrapper = styled.div`
  position: absolute;
  top: 5%;
  left: 45%;
`
export const DeleteButtonIcon = styled(DeleteIcon)`
  fill: ${colors.projectColors.iconGrey};
  &:hover {
    fill: ${colors.projectColors.iconDarkGrey};
  }
`
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  .save-button-general {
    margin: 0px;
    padding: 0px;

    width: 23px;
    height: 23px;

    display: flex;
    justify-content: center;
    align-items: center;

    background: none;
    border: none;
    cursor: pointer;
  }

  .save-icon {
    width: 100%;
    height: 100%;
    fill: ${colors.projectColors.iconGrey};
    &:hover {
      fill: ${colors.projectColors.iconDarkGrey};
    }
  }

  .saved-message-area {
    height: 20px;
    width: 56px;
    position: absolute;
    left: -50px;

    .saved-message {
      display: flex;
      align-items: center;
      opacity: 0;

      .saved-message-text {
        width: 43px;
        height: 17px;
        border-radius: 2px;
        background: ${colors.projectColors.main};

        display: flex;
        justify-content: center;
        align-items: center;

        font-size: 10px;
        line-height: 1.4;
        letter-spacing: -0.24px;
        color: ${colors.white};
      }
      .saved-message-anchor {
        height: 17px;
        display: flex;
        align-items: center;
      }
    }

    .saved-message.entered {
      opacity: 1;
      transition: opacity 500ms linear;
    }
  }
`
