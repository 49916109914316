import styled from '@emotion/styled'
import { colors } from 'theme/Colors'

interface IBTGridColumnsLayoutWrapperProps {
  scrollWidth: number
}

export const BTGridColumnWrapper = styled.div`
  padding: 10px;
  border-radius: 5px;
  background-color: ${colors.projectColors.fileBorder};
  margin: 15px;
  display: flex;
  flex-direction: column;
  width: 20vw;

  .add-task-button {
    margin-top: 15px;
    color: ${colors.navyGrey[600]};
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-transform: capitalize;
  }

  .column-top-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;

    .column-title {
      font-family: Poppins;
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
    }

    .tasks-counter {
      border-radius: 50%;
      background-color: ${colors.white};
      color: ${colors.projectColors.black};
      font-size: 10px;
      line-height: 10px;
      height: 18px;
      width: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .scroll-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: max-content;
    max-height: 60vh;
    -ms-overflow-style: none;
    scrollbar-width: none;

    .card-container {
      margin-bottom: 15px;
    }

    .empty-tasks-block {
      font-size: 12px;
      line-height: 12px;
      color: ${colors.projectColors.black};
      padding-top: 30px;
      padding-bottom: 30px;
      border: 1px dashed ${colors.projectColors.main};
      border-radius: 5px;
      width: 100%;
      text-align: center;
      background-color: ${colors.navyGrey[100]};
    }

    .column-dropzone {
      width: 100%;
      height: 100%;

      .spinner {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .scroll-wrapper::-webkit-scrollbar {
    display: none;
  }
`

export const BTGridsWrapper = styled.div<IBTGridColumnsLayoutWrapperProps>`
  width: 100%;
  user-select: none;

  .horizontal-scroll-block {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    width: ${({ scrollWidth }) => scrollWidth && `${scrollWidth}px`};
    height: 100%;
  }

  .horizontal-scroll-block::-webkit-scrollbar {
    background-color: transparent;
    height: 10px;
  }

  .horizontal-scroll-block::-webkit-scrollbar-thumb {
    background-color: ${colors.navyGrey[200]};
    border-radius: 10px;
  }
`
