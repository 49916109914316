import { NOTIFICATIONS_PAGINATION_LIMIT } from 'helpers/constants'
import { useGetAllNotificationsLazyQuery } from 'generated/graphql'

export const useGetNotifications = () => {
  const [getNotifications] = useGetAllNotificationsLazyQuery()

  return async () => {
    await getNotifications({
      variables: {
        unreadArgs: { limit: NOTIFICATIONS_PAGINATION_LIMIT },
        readArgs: { limit: NOTIFICATIONS_PAGINATION_LIMIT },
      },
    })
  }
}
