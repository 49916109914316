import { useParams } from 'react-router-dom'
import { useAppSelector } from 'redux/store'
import {
  GetAllFeedbackMessagesDocument,
  GetAllFeedbackMessagesQuery,
  GetArtworkFeedbackMessagesDocument,
  GetArtworkFeedbackMessagesQuery,
  useRemoveFeedbackMessageMutation,
} from '../../generated/graphql'

export function useRemoveFeedbackMessage() {
  const [removeFeedback] = useRemoveFeedbackMessageMutation()

  const { artworkId } = useParams()
  const { assetId } = useAppSelector((state) => state.sidebarApps)

  return async (messageId: string, feedbackId: string, childMessageId?: string) => {
    await removeFeedback({
      variables: {
        input: { messageId: childMessageId ?? messageId },
      },
      update: (cache) => {
        if (artworkId) {
          const artwork = cache.readQuery({
            query: GetArtworkFeedbackMessagesDocument,
            variables: {
              input: { assetId },
            },
          }) as GetArtworkFeedbackMessagesQuery

          const artworkMessages = artwork === null ? [] : artwork.getArtworkFeedback

          cache.writeQuery({
            query: GetArtworkFeedbackMessagesDocument,
            variables: {
              input: { assetId },
            },
            data: {
              getArtworkFeedback: [
                ...artworkMessages.filter((elem) => elem.messageId !== messageId),
              ],
            },
          })
        }

        const messages = cache.readQuery({
          query: GetAllFeedbackMessagesDocument,
          variables: {
            input: { feedbackId: assetId ? undefined : feedbackId, assetId },
          },
        }) as GetAllFeedbackMessagesQuery

        const oldMessages =
          messages === null ? [] : messages.getFeedbackMessagesByFeedbackId.messages

        cache.writeQuery({
          query: GetAllFeedbackMessagesDocument,
          variables: {
            input: { feedbackId: assetId ? undefined : feedbackId, assetId },
          },
          data: {
            getFeedbackMessagesByFeedbackId: {
              __typename: 'FeedbackMessageResponse',
              messages: !childMessageId
                ? [...oldMessages.filter((elem) => elem.messageId !== messageId)]
                : [
                    ...messages.getFeedbackMessagesByFeedbackId.messages.map((elem) =>
                      elem.messageId === messageId
                        ? {
                            ...elem,
                            replyedComments: elem.replyedComments.filter(
                              (reply) => reply.messageId !== childMessageId
                            ),
                          }
                        : elem
                    ),
                  ],
            },
          },
        })
      },
    })
  }
}
