/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable import/no-unresolved */
import { NavigationItems } from 'router/items'
import type { TabType, UrlParams, HeaderRoutesProps } from './bt-header-routes.types'

export function getCorrectPath(path: string): string {
  const regex = '/?(?<word>.*?)/'
  const parsed = new RegExp(regex).exec(path)

  return parsed?.groups?.word || ''
}
export function transformParamsInUrl(externalParams: UrlParams, path: string) {
  const globalRegex = /\:\w+/gm
  const localRegex = /\:\w+/m
  const params = path.match(globalRegex)
  if (!params) return path

  return params.reduce((acc, param) => {
    const formattedParam = param.replace(':', '')
    const formattedExternalParam = externalParams[formattedParam] || ''
    return acc.replace(localRegex, (match) => formattedExternalParam)
  }, path)
}
/**
 * @desc If you want to use dynamic param pass a dynamic param like this:
 * @param {string} :id or :name or something else
 * @requires param should match with param in the Router!
 */
export const getRoutes = (
  route: string
): {
  tabs: [] | { path: string; name: string; type?: TabType }[]
  name: NavigationItems | null
  actionButtons?: boolean
} | null => {
  switch (route as HeaderRoutesProps) {
    case 'create-project':
      return { tabs: [], name: null }
    case 'projects':
      return {
        name: NavigationItems.Projects,
        actionButtons: true,
        tabs: [
          {
            path: '/projects/:id',
            name: 'overview',
          },
          {
            path: '/projects/:id/brief',
            name: 'brief',
          },
          {
            path: '/projects/:id/tasks',
            name: 'tasks',
          },
          {
            path: '/projects/:id/chat',
            name: 'chat',
          },
          {
            path: '/projects/:id/archived',
            name: 'archived',
          },
        ],
      }
    case 'workspace':
      return {
        name: NavigationItems.Dashboard,
        tabs: [
          { path: '/workspace', name: 'Summary' },
          { path: '/workspace/archived-projects', name: 'Archived Projects' },
        ],
      }
    case 'global-tasks':
      return {
        name: NavigationItems.Tasks,
        tabs: [
          { path: '/global-tasks', name: 'Summary' },
          { path: '/global-tasks/calendar', name: 'Calendar' },
          { path: '/global-tasks/archived', name: 'Archived' },
        ],
      }
    case 'global-assets':
      return {
        name: NavigationItems.GlobalAssets,
        tabs: [
          { path: '/global-assets', name: 'Overview' },
          { path: '/global-assets/archived', name: 'Archived' },
        ],
      }
    case 'notifications':
      return {
        name: NavigationItems.Inbox,
        tabs: [],
      }
    case 'insights':
      return {
        name: NavigationItems.Insights,
        tabs: [
          { path: '/insights', name: 'Summary' },
          { path: '/insights/archived', name: 'Archived' },
        ],
      }
    case 'favorites':
      return {
        name: NavigationItems.Favorites,
        tabs: [],
      }
    case 'settings':
      return {
        name: NavigationItems.Settings,
        tabs: [
          { path: 'settings/user-details', name: 'My Profile' },
          { path: 'settings/team', name: 'Team', type: 'settings' },
          {
            path: 'settings/organization',
            name: 'Organization',
            type: 'settings',
          },
        ],
      }
    default:
      return null
  }
}
