import styled from 'styled-components'
import { colors } from '../../../theme/Colors'

interface IBTFileBlockWrapperProps {
  projectColor?: string | null
}

export const BTFileBlockWrapper = styled.div<IBTFileBlockWrapperProps>`
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  height: 159px;

  .preview-area {
    margin: auto;
    margin-top: 5px;
    margin-bottom: 15px;
    position: relative;

    width: 90%;
    height: 76px;

    border: 1px solid ${colors.projectColors.fileBorder};
    border-radius: 5px;

    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    background: #c3c3c3;

    .loader {
      margin-right: 50px;
      margin-bottom: 50px;
    }

    .document {
      width: 100px;
      transform: scale(80%);
      margin: auto;
    }

    .preview-area-content {
      position: absolute;
      max-width: 595px;
      max-height: 842px;
      overflow: hidden;

      transform: translate(-245px, -422px) scale(10%);
      background: grey;
    }

    .image-preview {
      max-width: 188px;
      max-height: 70px;
    }

    .version {
      font-size: 10px;
      font-weight: 700;
      line-height: 14px;
      background-color: ${({ projectColor }) =>
        (projectColor && projectColor) || colors.projectColors.main};
      border-radius: 10px;
      padding: 5px;
      color: ${colors.white};
      position: absolute;
      right: 5px;
      bottom: 5px;
      height: 24px;
      min-width: 24px;
      text-align: center;
    }
  }

  .no-click {
    cursor: auto;
  }

  .no-preview-area {
    margin: auto;
    margin-top: 5px;
    margin-bottom: 15px;

    width: 90%;
    height: 76px;

    border: 1px solid ${colors.projectColors.fileBorder};
    border-radius: 5px;

    background: #c3c3c3;
  }

  .upper-block {
    margin: auto;
    padding-left: 10px;
    padding-right: 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .file-name-block {
      display: flex;
      align-items: center;
    }

    .file-name {
      margin-left: 5px;

      display: inline-block;
      width: 90px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      font-weight: 400;
      font-size: 12px;
      line-height: 1.5;
      color: ${colors.black};
    }
    .file-size {
      font-size: 10px;
      line-height: 1.4;
      letter-spacing: -0.24px;
      color: ${colors.projectColors.iconGrey};
    }
  }

  .lower-block {
    margin: auto;
    margin-top: 4px;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .age {
      font-size: 10px;
      line-height: 1.4;
      letter-spacing: -0.24px;
      color: ${colors.projectColors.iconGrey};
    }

    .options {
      transform: rotate(90deg);
      color: #dadada;

      cursor: pointer;
    }
  }
`

export const BTDeleteModalWrapper = styled.div`
  width: 400px;
`

export const BTDeleteButtonsWrapper = styled.div`
  margin: auto;
  margin-top: 30px;

  width: 300px;

  display: flex;
  justify-content: space-between;
`

export const BTDeleteModalText = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 1.5;
  color: ${colors.projectColors.black};

  display: flex;
  align-items: center;
`

export const BTFileNameWrapper = styled.div`
  display: inline-block;

  margin-left: 5px;
  margin-right: 5px;

  max-width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;

  color: ${colors.projectColors.main};
`
