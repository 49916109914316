import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface postSignupFormState {
  image: string | null
  pronouns: string | null
}

const initialState: postSignupFormState = {
  image: null,
  pronouns: null,
}

const postSignupFormSlice = createSlice({
  name: 'postSignupFormSliceSlice',
  initialState,
  reducers: {
    setImage(state, { payload }: PayloadAction<string>) {
      state.image = payload
    },
    setPronouns(state, { payload }: PayloadAction<string>) {
      state.pronouns = payload
    },
  },
})

export const { setImage, setPronouns } = postSignupFormSlice.actions

export default postSignupFormSlice.reducer
