import { useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { getOrganizationMemberRole } from 'utils/getOrganizationMemberRole'
import { toastNotifications } from 'helpers/toastNotifications'
import { setupWhoami, setupOrganizations, setCurrentOrganizationId } from '../redux/LoginSlice'
import { useAppSelector } from '../redux/store'
import { ITokenPayload } from '../components/bt-auth-elements/bt-signup/bt-signup.props'
import { setupDeliverables } from '../redux/DeliverablesSlice'
import {
  useAddMemberToOrganizationMutation,
  useDeliverableTypesQuery,
  useOrganizationsQuery,
  useVerifyInviteTokenLazyQuery,
  useMeWithOrganizationQuery,
} from '../generated/graphql'

export function useSetupGlobalVariables() {
  const dispatch = useDispatch()
  const { data: deliverableData, loading: deliverablesLoading } = useDeliverableTypesQuery()
  const { data: whoami, loading: meLoading } = useMeWithOrganizationQuery()
  const { data: orgData, loading: orgLoading, refetch: orgRefetch } = useOrganizationsQuery()
  const { isUserFullyRegistered } = useAppSelector((state) => state.login)

  const [payload, setPayload] = useState<ITokenPayload>({})

  const [searchParams] = useSearchParams()

  const [addUserToOrganization] = useAddMemberToOrganizationMutation()

  const [verify] = useVerifyInviteTokenLazyQuery()

  const verifyToken = async (token: string): Promise<ITokenPayload> => {
    const response = await verify({ variables: { input: { token } } })

    if (response.data) {
      const data = response.data.inviteMemberVerify.payload
      const { organizationId, userId, role } = JSON.parse(data)

      return { organizationId, userId, role }
    }

    return {}
  }

  useEffect(() => {
    const tokenFromQuery = searchParams.get('token')

    if (tokenFromQuery) {
      verifyToken(tokenFromQuery).then((data) => setPayload(data))
    }
  }, [searchParams])

  useEffect(() => {
    if (payload.organizationId && payload.userId) {
      const { organizationId, userId, role } = payload

      if (organizationId && userId) {
        addUserToOrganization({
          variables: {
            input: { organizationId, userId, role: getOrganizationMemberRole(role) },
          },
        }).then(() => {
          refetchOrganizations()
          toastNotifications('You successfully added to organization.').success()
        })
      }
    }
  }, [payload])

  const loading = deliverablesLoading || meLoading || orgLoading

  useEffect(() => {
    if (!loading) {
      const organizations = orgData?.organizations
      dispatch(setupDeliverables(deliverableData))

      if (organizations && organizations.length) {
        dispatch(setupOrganizations(organizations))
        dispatch(setCurrentOrganizationId(organizations[0].id))
      }

      if (whoami) {
        dispatch(setupWhoami(whoami))
      }
    }
  }, [loading, meLoading, orgLoading, whoami, orgData])

  function refetchOrganizations() {
    orgRefetch().then(({ data }) => {
      if (data.organizations) {
        const { organizations } = data

        dispatch(setupOrganizations(organizations))
        dispatch(setCurrentOrganizationId(organizations[0].id))
      }
    })
  }

  useEffect(() => {
    const organizationId = localStorage.getItem('invite_organizationId')
    const inviteRole = localStorage.getItem('invite_role')

    if (isUserFullyRegistered) {
      if (organizationId && whoami) {
        const userId = whoami.meWithOrganization?.id || ''

        addUserToOrganization({
          variables: {
            input: { userId, organizationId, role: getOrganizationMemberRole(inviteRole) },
          },
        }).then(() => {
          refetchOrganizations()
          localStorage.removeItem('invite_organizationId')

          toastNotifications('You successfully added to organization.').success()
        })
      } else {
        localStorage.removeItem('invite_organizationId')
      }
    }
  }, [isUserFullyRegistered])

  return [loading]
}
