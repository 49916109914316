/* eslint-disable max-len */
import { useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { composedUpload } from '../../helpers/compose/composedUpload'
import { toastNotifications } from '../../helpers/toastNotifications'
import {
  useUpdateBriefComposeDocumentMutation,
  useGetBriefQuery,
  GetBriefQuery,
  GetBriefDocument,
  useUpdateActivityComposeDocumentMutation,
} from '../../generated/graphql'

export const useReplaceComposeDocument = () => {
  const { id: projectId, activityId } = useParams()
  const { data: briefData } = useGetBriefQuery({
    variables: { id: projectId },
  })
  const [getSignedUrlResponse] = useUpdateBriefComposeDocumentMutation()
  const [getSignedUrlResponseForActivity] = useUpdateActivityComposeDocumentMutation()

  return async (content: string | null): Promise<void> => {
    try {
      if (content) {
        if (activityId) {
          const activityName = uuidv4()
          const signedUrlResponse = await getSignedUrlResponseForActivity({
            variables: {
              input: {
                id: activityId,
                name: activityName,
              },
            },
          })
          const signedUrl = signedUrlResponse.data?.updateActivityComposeDocument.url
          await composedUpload(content, signedUrl)
        } else {
          const briefId = briefData?.getBrief.id
          const newName = uuidv4()
          if (briefId) {
            const signedUrlResponse = await getSignedUrlResponse({
              variables: {
                input: {
                  id: briefId,
                  name: newName,
                },
              },
              update: (cache, { data }) => {
                const readedQuery = cache.readQuery({
                  query: GetBriefDocument,
                  variables: {
                    id: projectId,
                  },
                }) as GetBriefQuery

                cache.writeQuery({
                  query: GetBriefDocument,
                  variables: {
                    id: projectId,
                  },
                  data: {
                    getBrief: {
                      ...readedQuery.getBrief,
                      composedDocument: {
                        ...readedQuery.getBrief.composedDocument,
                        id: data?.updateBriefComposeDocument.brief.composedDocument?.id,
                        name: newName,
                      },
                    },
                  },
                })
              },
            })
            const signedUrl = signedUrlResponse.data?.updateBriefComposeDocument.url

            await composedUpload(content, signedUrl)
          }
        }
      }
    } catch (err) {
      const error = err as Error
      toastNotifications(error.message).error()
    }
  }
}
