import { ApolloCache } from '@apollo/client'
import { prop, propEq } from 'ramda'
import {
  ActivitySummaryFragment,
  MilestoneFragment,
  MilestoneFragmentDoc,
  ProjectFragment,
  ProjectFragmentDoc,
  useUpdateActivityMutation,
} from '../../generated/graphql'

// GraphQL Ints cannot exceed 2^31 - 1
const POSITION_MAX = 2 ** 31 - 1

export function useMoveActivity(): (
  project: ProjectFragment,
  activity: ActivitySummaryFragment,
  milestoneId: string | undefined,
  before?: ActivitySummaryFragment
) => Promise<void> {
  const [updateActivity, v] = useUpdateActivityMutation()

  return async (project, activity, milestoneId, before) => {
    const activities = milestoneId
      ? project.milestones.find(propEq('id', milestoneId))!.activities
      : project.activities
    const beforeIdx = before ? activities.findIndex(propEq('id', before.id)) : activities.length
    const beforePosition = before?.position ?? POSITION_MAX
    const afterPosition = activities[beforeIdx - 1]?.position ?? -POSITION_MAX
    const position = (beforePosition + afterPosition) / 2

    const updatedActivity: ActivitySummaryFragment = {
      ...activity,
      milestone: milestoneId ? { id: milestoneId } : null,
      position,
    }

    await updateActivity({
      variables: {
        input: {
          id: activity.id,
          milestoneId: milestoneId ?? '',
          beforeId: before?.id,
        },
      },
      optimisticResponse: {
        updateActivity: { updatedActivities: [updatedActivity] },
      },
      update(cache, { data }) {
        // The project that was given to us could be stale, so we want to
        // update the cache based on the most recent version
        const cachedProject: ProjectFragment | null = cache.readFragment({
          id: cache.identify(project),
          fragment: ProjectFragmentDoc,
          fragmentName: 'Project',
          optimistic: true,
        })
        if (data?.updateActivity && cachedProject) {
          updateActivityParents(cache, cachedProject)
        }
      },
    })
  }
}

function updateActivityParents(cache: ApolloCache<Object>, project: ProjectFragment) {
  const activityRefs = [
    ...project.activities,
    ...project.milestones.flatMap(prop('activities')),
  ].sort((a, b) => a.position - b.position)

  updateProjectActivities(cache, project, activityRefs)
  project.milestones.forEach((milestone) =>
    updateMilestoneActivities(cache, milestone, activityRefs)
  )
}

function updateProjectActivities(
  cache: ApolloCache<Object>,
  project: ProjectFragment,
  activities: ActivitySummaryFragment[]
) {
  const projectActivities = activities.filter(({ milestone }) => !milestone)

  if (
    JSON.stringify(project.activities.map(prop('id'))) !==
    JSON.stringify(projectActivities.map(prop('id')))
  ) {
    cache.writeFragment({
      id: cache.identify(project),
      data: {
        ...project,
        activities: projectActivities,
      },
      fragment: ProjectFragmentDoc,
      fragmentName: 'Project',
    })
  }
}

function updateMilestoneActivities(
  cache: ApolloCache<Object>,
  milestone: MilestoneFragment,
  activities: ActivitySummaryFragment[]
) {
  const milestoneActivities = activities.filter(
    (activity) => activity.milestone?.id === milestone.id
  )

  if (
    JSON.stringify(milestone.activities.map(prop('id'))) !==
    JSON.stringify(milestoneActivities.map(prop('id')))
  ) {
    cache.writeFragment({
      id: cache.identify(milestone),
      data: {
        ...milestone,
        activities: milestoneActivities,
      },
      fragment: MilestoneFragmentDoc,
      fragmentName: 'Milestone',
    })
  }
}
