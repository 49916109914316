import styled from 'styled-components'
import { colors } from '../../../../../theme/Colors'

export const BTFileItemInformationWrapper = styled.li`
  margin: auto;
  margin-bottom: 20px;
  padding: 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border: 1px solid ${colors.projectColors.fileBorder};
  border-radius: 5px;
  background: white;
  box-shadow: 2px 2px 0px 0px rgba(34, 60, 80, 0.2);

  .file-icon-left-side {
    display: flex;
    align-items: center;
    .file-icon {
      margin-right: 10px;
      display: flex;
      align-items: center;
    }

    .file-name {
      display: inline-block;
      max-width: 130px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      font-weight: 400;
      font-size: 12px;
      line-height: 1.5;

      color: ${colors.black};
    }

    .file-name-large {
      max-width: 80%;
      font-weight: 400;
      font-size: 12px;
      line-height: 1.5;

      color: ${colors.black};
    }
  }

  .file-icon-right-side {
    display: flex;
    align-items: center;

    .file-size {
      margin-left: 15px;
      margin-right: 40px;

      font-weight: 400;
      font-size: 10px;
      line-height: 1.4;
      letter-spacing: -0.24px;

      color: ${colors.projectColors.fileSize};
    }

    .file-button {
      display: flex;
      align-items: center;
      color: ${colors.projectColors.iconDarkGrey};
      cursor: pointer;
    }
  }
`
