import { useEffect } from 'react'
import { useAppDispatch } from 'redux/store'
import { useWizard } from 'react-use-wizard'
import { useMeWithOrganizationQuery } from 'generated/graphql'
import { setImage, setPronouns } from 'redux/PostSignupFormSlice'
import { BTPostSignupFormContainer } from './bt-post-signup-form-wrapper/bt-post-signup-form-wrapper'

export const BTPostSignupFormStart = () => {
  const { nextStep, activeStep, stepCount } = useWizard()
  const dispatch = useAppDispatch()
  const { data } = useMeWithOrganizationQuery()

  useEffect(() => {
    dispatch(setImage(''))
    dispatch(setPronouns(''))
  }, [])

  return (
    <BTPostSignupFormContainer
      title={`Welcome ${data?.meWithOrganization?.name}`}
      btnTitle="Begin"
      goNext={nextStep}
      content={<div>Let’s set up your workspace, this will only take a minute</div>}
      activeStep={activeStep}
      stepCount={stepCount}
    />
  )
}
