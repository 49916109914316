import styled from '@emotion/styled'
import { colors } from 'theme/Colors'

export const BTErrorTemplatePageWrapper = styled.div`
  padding-left: 73px;

  &[data-status-code]:before {
    content: attr(data-status-code);
    position: absolute;
    font-size: 900px;
    font-weight: 700;
    line-height: 150px;
    top: 60%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -60%);
    color: ${colors.projectColors.main};
    opacity: 0.3;
    z-index: 1;
  }

  .main-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    z-index: 2;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url('/main-background.png');
      background-repeat: no-repeat;
      background-size: cover;
      filter: brightness(6) opacity(20%);
      z-index: 2;
    }
  }

  .title {
    font-size: 200px;
    font-weight: 700;
    line-height: 150px;
    color: ${colors.projectColors.main};
  }

  .main-text {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    color: ${colors.projectColors.main};
  }

  .description: {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    color: ${colors.black};
  }

  .title,
  .main-text,
  .description {
    z-index: 10;
  }
`
