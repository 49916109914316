import { FC } from 'react'
import Avatar from '@mui/material/Avatar'
import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import type { IMemberListItemProps } from './bt-member-list-item.props'
import { MemberListItemWrapper } from './bt-member-list-item.presets'

export const BTMemberListItem: FC<IMemberListItemProps> = ({
  id,
  avatarUrl,
  name = '',
  onClick,
  isSelected,
}) => {
  const currentListItemIcon = isSelected ? <ClearIcon /> : <AddIcon />

  return (
    <MemberListItemWrapper id={id} onClick={onClick} type="button">
      <div className="avatar-block">
        <Avatar src={avatarUrl} alt={name || ''} sx={{ height: 25, width: 25 }} />
        <span>{name}</span>
      </div>
      {currentListItemIcon}
    </MemberListItemWrapper>
  )
}
