import styled from '@emotion/styled'

const IconButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0 5px;

  button {
    padding: 2px;
  }
`

export { IconButtonsWrapper }
