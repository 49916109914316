import styled from '@emotion/styled'
import { colors } from '../../../theme/Colors'

interface IBTGetStartedWrapperProps {
  error: boolean
}

export const BTGetStartedWrapper = styled.div<IBTGetStartedWrapperProps>`
  form {
    justify-self: flex-start;
    padding-left: 80px;
    display: flex;
    flex-direction: column;
    gap: 30px;

    .title {
      font-size: 50px;
      line-height: 50px;
      font-weight: 700;
      color: ${colors.projectColors.main};
    }

    .description {
      font-size: 18px;
      line-height: 27px;
      font-weight: 400;
      color: ${colors.projectColors.main};
    }

    .input-block {
      display: flex;
      flex-direction: row;
      gap: 20px;

      p {
        position: absolute;
        top: 45px;
        display: ${({ error }) => (error && error ? 'block' : 'none')};
      }

      input {
        background-color: ${colors.white};
        border-radius: 5px;
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        color: ${colors.navyGrey[400]};
        width: 520px;
        padding: 12px 10px;
      }

      button {
        font-size: 14px;
        line-height: 21px;
        font-weight: 500;
        padding: 10px;
        text-transform: capitalize;
      }
    }
  }
`
