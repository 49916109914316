import styled from 'styled-components'
import { colors } from '../../../../../../../theme/Colors'

const BTConfirmTextAreaStyled = styled.textarea.attrs({
  placeholder: 'Type here',
})`
  width: 100%;
  height: 100%;

  border: 0.5px solid ${colors.projectColors.iconDarkGrey};
  border-radius: 5px;
  resize: none;

  outline: none;
  padding: 5px;
  &:focus {
    border: 1px solid ${colors.projectColors.iconDarkGrey};
  }
  &::placeholder {
    color: ${colors.projectColors.iconDarkGrey};
    font-weight: 300;
    font-size: 10px;
    line-height: 17px;
  }
`

export { BTConfirmTextAreaStyled }
