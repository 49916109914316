import { feedbackSortCallback } from '../../utils/sorting/sorting'
import { useAppSelector } from '../../redux/store'
import {
  GetAllFeedbackMessagesDocument,
  GetAllFeedbackMessagesQuery,
  useCreateReplyFeedbackMessageMutation,
  UserFragment,
} from '../../generated/graphql'

export function useAddReplyFeedback() {
  const user = useAppSelector((state) => state.login.whoami)
  const [addFeedback] = useCreateReplyFeedbackMessageMutation()

  const { assetId } = useAppSelector((state) => state.sidebarApps)

  return async (
    activityId: string,
    value: string,
    feedbackId: string,
    parentFeedbackMessageId: string,
    relatedToUser?: UserFragment
  ) => {
    await addFeedback({
      variables: {
        input: {
          content: value,
          userId: user.id,
          activityId,
          assetId,
          parentFeedbackMessageId,
          replyedUserId: relatedToUser?.id,
        },
      },
      update: (cache, { data: newMessage }) => {
        const messages = cache.readQuery({
          query: GetAllFeedbackMessagesDocument,
          variables: {
            input: { feedbackId: assetId ? undefined : feedbackId, assetId },
          },
        }) as GetAllFeedbackMessagesQuery

        const oldMessages =
          messages === null ? [] : messages.getFeedbackMessagesByFeedbackId.messages

        cache.writeQuery({
          query: GetAllFeedbackMessagesDocument,
          variables: {
            input: { feedbackId: assetId ? undefined : feedbackId, assetId },
          },
          data: {
            getFeedbackMessagesByFeedbackId: {
              __typename: 'FeedbackMessageResponse',
              messages: [
                ...oldMessages.map((elem) =>
                  elem.feedbackMessageId === parentFeedbackMessageId
                    ? {
                        ...elem,
                        replyedComments: [
                          ...elem.replyedComments,
                          {
                            __typename: 'FeedbackMessage',
                            user: {
                              id: user.id,
                              name: user.name,
                              avatarUrl: user.avatarUrl,
                            },
                            content: newMessage?.addReplyFeedbackMessage.message.content || '',
                            createdAt: newMessage?.addReplyFeedbackMessage.message.createdAt,
                            replyedComments: [],
                            repliedUser: relatedToUser,
                            feedbackMessageId:
                              newMessage?.addReplyFeedbackMessage.childFeedbackMessageId || '',
                            pinned: false,
                            assetId,
                          },
                        ].sort((first, second) =>
                          feedbackSortCallback(
                            first?.pinned,
                            second?.pinned,
                            first?.createdAt,
                            second?.createdAt
                          )
                        ),
                      }
                    : elem
                ),
              ],
            },
          },
        })
      },
    })
  }
}
