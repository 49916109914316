import styled from '@emotion/styled'
import { Box } from '@material-ui/core'
import { colors } from '../../../theme/Colors'

export const BTAuthComponentWrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  font-family: Poppins, sans-serif;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('/main-background.png');
    background-repeat: no-repeat;
    background-size: cover;
    filter: brightness(6) opacity(20%);
    z-index: 1;
  }
`

export const BTIndexLayoutHeader = styled(Box)`
  background-color: ${colors.projectColors.main};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 24px 20px;

  svg {
    height: 30px;
    width: 54px;

    &:hover {
      cursor: pointer;
    }
  }

  button {
    background-color: ${colors.white};
    text-transform: capitalize;
    padding: 10px;

    &:hover {
      background-color: ${colors.navyGrey[300]};
    }
  }
`

export const BTInnerWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 10;
`
