// @ts-nocheck
/* eslint-disable import/order */
/* eslint-disable import/no-cycle */
import { RootState } from './store'
import { cognitoUserPool } from 'helpers/cognito'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  OrganizationFragment,
  MeWithOrganizationQuery,
  UserOrganizationFragment,
} from 'generated/graphql'

interface LoginState {
  cognitoUser: CognitoUser | null
  isUserFullyRegistered: boolean
  whoami: UserOrganizationFragment
  organizations: Array<OrganizationFragment>
  currentOrganizationId: string
}

export interface UserAuthenticatedPayload {
  cognitoUser: CognitoUser
}

export interface UserRegistrationPayload {
  isFullyRegistered: boolean
}
interface Whoami {
  meWithOrganization?: MeWithOrganizationQuery['meWithOrganization']
}

const initialState: LoginState = {
  cognitoUser: cognitoUserPool.getCurrentUser()?.getUsername(),
  isUserFullyRegistered: false,
  whoami: null,
  organizations: [],
  currentOrganizationId: '',
}

const loginSlice = createSlice({
  name: 'loginSlice',
  initialState,
  reducers: {
    userAuthenticated: (state, { payload }: PayloadAction<UserAuthenticatedPayload>) => {
      state.cognitoUser = payload.cognitoUser.getUsername()
    },
    userOrganizationFromRegisterForm: (state, { payload }) => {
      state.currentOrganizationId = payload
    },
    userLoggedOut: (state) => {
      state.cognitoUser = null
      state.whoami = null
      state.organizations = []
      state.currentOrganizationId = ''
    },
    userFullRegister: (state, { payload }: PayloadAction<UserRegistrationPayload>) => {
      state.isUserFullyRegistered = payload.isFullyRegistered
    },
    setupWhoami(state, { payload }: PayloadAction<Whoami | undefined>) {
      if (payload) {
        state.whoami = payload.meWithOrganization
      }
    },
    setupOrganizations(state, { payload }: PayloadAction<OrganizationFragment[]>) {
      state.organizations = payload
    },
    setCurrentOrganizationId(state, { payload }: PayloadAction<string>) {
      state.currentOrganizationId = payload
    },
    pushNewOrganization(state, { payload }: PayloadAction<OrganizationFragment>) {
      state.organizations.push(payload)
    },
  },
})
export const cognitoUserSelector = (state: RootState) => state.login

export const {
  userAuthenticated,
  userOrganizationFromRegisterForm,
  userLoggedOut,
  userFullRegister,
  setupWhoami,
  setupOrganizations,
  setCurrentOrganizationId,
  pushNewOrganization,
} = loginSlice.actions

export default loginSlice.reducer
