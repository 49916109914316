/* eslint-disable no-shadow */
/* eslint-disable no-undef */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-constructed-context-values */
import { Navigate } from 'react-router-dom'
import { useAppSelector } from 'redux/store'
import { USER_ROLES } from 'helpers/constants'
import { UserRoleAccessProps } from './CheckUserRoleAccess.props'
import { useGetCurrentActivityByIdQuery } from 'generated/graphql'
import { ComponentProps, createContext, FC, FunctionComponent, useContext } from 'react'

type UserRoleType = string | null | undefined

type RoleCheckAccessHelper = <T extends object>(
  Component: FunctionComponent<T>,
  redirect?: string
) => FC<T> | JSX.Element | null

type RoleContextType = {
  userRole: UserRoleType
  withCheckRoleAccess: RoleCheckAccessHelper
}

const RoleContext = createContext<RoleContextType | null>(null)

export const useRoleContext = () => useContext(RoleContext) as RoleContextType

export const RoleProvider: FC = ({ children }) => {
  const { whoami } = useAppSelector((state) => state.login)
  const userRole = whoami?.organizationMember?.role

  const withCheckRoleAccess: RoleCheckAccessHelper = (Component, redirect) => {
    const ComponentWithRole = (props: ComponentProps<typeof Component>) => {
      return <Component {...props} />
    }

    return roleHelper(userRole, ComponentWithRole, redirect)
  }

  return (
    <RoleContext.Provider value={{ userRole, withCheckRoleAccess }}>
      {children}
    </RoleContext.Provider>
  )
}

function roleHelper<ComponentGeneric>(
  userRole: UserRoleType,
  Component: ComponentGeneric | null,
  redirect?: string,
  isAuthor?: boolean
) {
  switch (userRole) {
    case USER_ROLES.EXTERNAL:
      if (redirect) {
        return <Navigate to={redirect} />
      }

      if (isAuthor) {
        return Component
      }

      return null

    default:
      return Component
  }
}

export const useShouldBeDisabledForRoles = () => {
  const { whoami } = useAppSelector((state) => state.login)
  const userRole = whoami?.organizationMember?.role

  return (roles: string[], isAuthor: boolean = true) => {
    const hasResctrictedRole = roles.some((role) => role === userRole)

    return hasResctrictedRole && !isAuthor
  }
}

export const useIsAuthor = (id: string = '') => {
  const { whoami } = useAppSelector((state) => state.login)
  const activityResponse = useGetCurrentActivityByIdQuery({ variables: { id } })
  const authorId = activityResponse?.data?.activities[0]?.author

  return whoami?.id === authorId
}

export const CheckUserRoleAccess: FC<UserRoleAccessProps> = ({
  redirect,
  children,
  activityId,
}) => {
  const { whoami } = useAppSelector((state) => state.login)
  const userRole = whoami?.organizationMember?.role
  const isAuthor = useIsAuthor(activityId)

  return roleHelper(userRole, children, redirect, isAuthor)
}
