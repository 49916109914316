/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-expressions */
import * as yup from 'yup'
import { useFormik } from 'formik'
import { FC, useState, ChangeEvent } from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import { FormValues } from './bt-set-new-password.types'
import { Box, TextField, Typography } from '@mui/material'
import { useResetPassword } from 'hooks/user/useResetPassword'
import { BTLoginFormWrapper } from '../bt-login/bt-login.presets'

export const BTSetNewPassword: FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const resetPassword = useResetPassword()

  const LoginValidationSchema = yup.object({
    email: yup.string().email('Wrong email format').required('Email required'),
    password: yup.string().required('Password required'),
  })

  const onSubmit = async ({ email, password }: FormValues) => {
    setLoading(true)
    await resetPassword(email, password)
    setLoading(false)
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginValidationSchema,
    onSubmit,
  })

  const onChange = (value: string, fieldName: string) => {
    formik.setFieldValue(fieldName, value)
    formik.validateField(fieldName)
  }

  return (
    <BTLoginFormWrapper>
      <Box className="wrapper" sx={{ boxShadow: 3 }}>
        <Typography variant="h5">Set New Password</Typography>
        <form className="inputs-block" onSubmit={formik.handleSubmit}>
          <div className="input-block">
            <span>Email</span>
            <TextField
              placeholder="Email"
              helperText={formik.errors.email}
              onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
                onChange(target.value, 'email')
              }
              error={!!formik.errors.email}
              autoComplete="off"
            />
          </div>

          <div className="input-block">
            <span>New Password</span>
            <TextField
              placeholder="Password"
              type="password"
              helperText={formik.errors.password}
              error={!!formik.errors.password}
              onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
                onChange(target.value, 'password')
              }
              autoComplete="off"
            />
          </div>
          <LoadingButton
            type="submit"
            variant="contained"
            fullWidth
            onClick={() => formik.handleSubmit()}
            loading={loading}
          >
            <Typography variant="subtitle1">Save Password & Log in</Typography>
          </LoadingButton>
        </form>
      </Box>
    </BTLoginFormWrapper>
  )
}
