import { useDebounce } from 'hooks/shared/useDebounce'
import React, { FC, ChangeEvent, useState, useEffect } from 'react'
import { useAppSelector } from 'redux/store'
import { BTQuestionsTextInput } from './bt-questions-page-text.presets'
import { BTQuestionsPageTextProps } from './bt-questions-page-text.props'

export const BTQuestionsPageText: FC<BTQuestionsPageTextProps> = ({
  placeholder,
  handler,
  question,
  defaultValue = '',
  ...rest
}) => {
  const [state, setState] = useState(defaultValue)
  const { currentDeliverable } = useAppSelector((state) => state.sidebarApps)
  const debouncedValue = useDebounce(state, 250)
  const inputHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setState(event.target.value)
  }
  useEffect(() => {
    const { questionTypeId, id } = question
    handler({
      content: debouncedValue,
      questionId: id,
      type: questionTypeId,
      deliverableId: currentDeliverable.relationId || currentDeliverable.id,
    })
  }, [debouncedValue])
  return <BTQuestionsTextInput placeholder={placeholder} value={state} onChange={inputHandler} />
}
