/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
import { FC, useState, ChangeEvent, useEffect } from 'react'
import { Button, IconButton, MenuItem, TextField } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useOrganizationsQuery } from 'generated/graphql'
import { PuffLoader } from 'react-spinners'
import { colors } from 'theme/Colors'
import { useLocation } from 'react-router-dom'
import { BTModalWindow } from '../shared/bt-modal-window'
import { setCurrentOrganizationId, setupOrganizations } from '../../redux/LoginSlice'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { BTChangeCurrentOrganizationWrapper } from './bt-change-current-organization.presets'
import type { IBTChangeCurrentOrganization } from './bt-change-current-organization.props'
import { BTCreateOrganizationModal } from 'components/bt-team-settings/components/bt-create-organization'

const ORGANIZATION_ROUTE_NAME = 'organization'

export const BTChangeCurrentOrganization: FC<IBTChangeCurrentOrganization> = ({
  handleModalClose,
  isOpened,
}) => {
  const dispatch = useAppDispatch()
  const [organizationId, setOrganizationId] = useState('')
  const [isModalOpened, setIsOpened] = useState(false)

  const openCreateOrganizationModal = () => setIsOpened(true)
  const closeCreateOrganizationModal = () => setIsOpened(false)

  const { pathname } = useLocation()

  const { organizations, currentOrganizationId } = useAppSelector((state) => state.login)

  const { loading, refetch } = useOrganizationsQuery()

  const changeValue = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target

    if (value) {
      setOrganizationId(value)
    }
  }

  const changeCurrentOrganizationId = () => {
    dispatch(setCurrentOrganizationId(organizationId))
    handleModalClose()
  }

  useEffect(() => {
    refetch().then((elems) => {
      if (elems.data.organizations) {
        dispatch(setupOrganizations(elems.data.organizations))
      }
    })
  }, [isOpened])

  useEffect(() => {
    if (organizations && currentOrganizationId && organizations.length > 0) {
      const organzation = organizations.filter(({ id }) => id === currentOrganizationId)

      if (organzation.length) {
        setOrganizationId(organzation[0].id)
      }
    }
  }, [organizations])

  return (
    <BTModalWindow isOpened={isOpened} handleClose={handleModalClose}>
      <BTChangeCurrentOrganizationWrapper>
        <span className="title">Organization</span>
        <div className="input-block">
          <div className="title">Choose organization</div>
          {loading ? (
            <PuffLoader loading={loading} color={colors.projectColors.main} size={50} />
          ) : (
            <div className="selecet-block">
              <TextField required select fullWidth value={organizationId} onChange={changeValue}>
                {organizations.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </TextField>
              {pathname.includes(ORGANIZATION_ROUTE_NAME) && (
                <AddIcon
                  fontSize="large"
                  className="plus-icon"
                  onClick={openCreateOrganizationModal}
                />
              )}
            </div>
          )}
        </div>
        <Button onClick={changeCurrentOrganizationId} fullWidth variant="contained">
          Done
        </Button>
      </BTChangeCurrentOrganizationWrapper>
      <BTCreateOrganizationModal
        isOpened={isModalOpened}
        closeHandler={closeCreateOrganizationModal}
      />
    </BTModalWindow>
  )
}
