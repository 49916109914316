import { FC, useCallback } from 'react'
import { useAppSelector } from 'redux/store'
import { capitalizeFirstLetter } from 'helpers/string'
import { ActivityTypesResponse } from 'generated/graphql'
import { useCreateArtwork, useCreateActivity } from 'hooks'
import BTActivityItem from '../deliverable-item/activity-item'
import { ACTIVITY_TYPES } from '../../bt-add-activity-button.props'
import { BTAddActivityPopupProps } from './bt-add-activity-popup.props'
import { BTAddActivityPopupWrapper } from './bt-add-activity-popup.presets'

export const BTAddActivityPopup: FC<BTAddActivityPopupProps> = ({ milestone }) => {
  const createArtwork = useCreateArtwork()
  const createActivity = useCreateActivity()

  const { id } = useAppSelector((state) => state.login.whoami)

  const activitiesOptions = [
    {
      id: '1',
      name: ACTIVITY_TYPES.UPLOAD,
      description: 'Upload an existing document from your computer.',
    },
    {
      id: '2',
      name: ACTIVITY_TYPES.COMPOSE,
      description: 'Create a document within the Brieft app.',
    },
  ]

  const appendHandler = useCallback(async (activityType: ActivityTypesResponse) => {
    const activityName = capitalizeFirstLetter(activityType.name)

    if (activityType.name === ACTIVITY_TYPES.UPLOAD) {
      await createArtwork(activityName, id, milestone?.id)
    } else {
      await createActivity(activityName, id, milestone?.id)
    }
  }, [])

  return (
    <BTAddActivityPopupWrapper>
      <div className="popup-title">Add document</div>
      <div className="popup-subtitle">
        Eg. Artwork, Proposal, Quote, Invoice, Client Documentation
      </div>

      <div>
        {activitiesOptions.map((activityOption) => (
          <div className="create-activity-options-item">
            <BTActivityItem
              key={activityOption.id}
              activityType={activityOption}
              onClick={appendHandler}
            />
          </div>
        ))}
      </div>
    </BTAddActivityPopupWrapper>
  )
}
