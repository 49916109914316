import { Divider } from '@mui/material'
import ScrollContainer from 'react-indiana-drag-scroll'
import styled from 'styled-components'

const BTQuestionsPageWrapper = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 50px;
  gap: 40px;
  width: 700px;
`

const BTQuestionsPageHeading = styled.div`
  ${({ theme }) => ({ ...theme.typography?.h5 })};
  color: #333333;
`
const BTQuestionsPageDivider = styled(Divider)`
  && {
    border-color: #bdbdbd;
    width: 100%;
  }
`
const BTQuestionsContainer = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.gap || '5px'};
`
const BTQuestionsScrollContainer = styled(ScrollContainer)`
  && {
    overflow: auto;
    height: 80vh;
  }
`

export {
  BTQuestionsPageWrapper,
  BTQuestionsPageHeading,
  BTQuestionsPageDivider,
  BTQuestionsContainer,
  BTQuestionsScrollContainer,
}
