/* eslint-disable no-unused-vars */
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

export const usePagination = <T>(
  perPage: number,
  data: Array<T>
): [
  T[],
  number,
  number,
  Dispatch<SetStateAction<T[]>>,
  (page: number) => void,
  (sortCallback: (firstValue: T, secondValue: T) => number) => void
] => {
  const [allData, setData] = useState<T[]>(data)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [currentData, setCurrentData] = useState<T[]>(allData.slice(0, perPage))
  const [maxPage, setMaxPage] = useState(Math.ceil(allData.length / perPage))

  useEffect(() => {
    if (allData) {
      setCurrentData(allData.slice(0, perPage))
      setMaxPage(Math.ceil(allData.length / perPage))
    }
  }, [allData])

  /*  useEffect(() => {
    if (currentData) {
      setMaxPage(Math.ceil(currentData.length / perPage))
    }
  }, [currentData])

  useEffect(() => {
    setCurrentData(currentData.slice(0, perPage))
  }, [setMaxPage]) */

  const changeCurrentPageData = (page: number) => {
    if (page !== currentPage) {
      setCurrentPage(page)

      const begin = (page - 1) * perPage
      const end = begin + perPage

      const newData: Array<T> = allData.slice(begin, end)

      setCurrentData(newData)
    }
  }

  const sort = (sortCallback: (firstValue: T, secondValue: T) => number) => {
    setCurrentPage(1)
    const sortedArray = data.sort(sortCallback)

    setCurrentData(sortedArray.slice(0, perPage))
  }

  return [currentData, currentPage, maxPage, setData, changeCurrentPageData, sort]
}
