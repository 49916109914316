import styled from '@emotion/styled'
import { colors } from '../../../theme/Colors'

interface IBTActivityListViewWrapper {
  headerSectionWidth: number
  isNewListItem?: boolean
  headerSectionBackground?: boolean
}

export const BTActivityListViewWrapper = styled.div<IBTActivityListViewWrapper>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  flex-direction: column;
  padding: 30px 40px 40px;

  .main-wrapper {
    width: 100%;
    height: 100%;
    display: contents;
  }

  .header-sections-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px 40px 15px 10px;
    ${({ headerSectionBackground }) =>
      headerSectionBackground && `background-color: ${colors.navyGrey[200]};`}
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .isNew {
      width: 5% !important;
    }

    div {
      width: ${({ headerSectionWidth }) => headerSectionWidth && headerSectionWidth}%;
    }
  }

  .main-layout {
    width: 100%;
    height: 100%;
    background-color: ${colors.navyGrey[200]};
    border-radius: 5px;
    padding: 10px;
    ${({ headerSectionBackground }) =>
      headerSectionBackground && `border-top-left-radius: 0; border-top-right-radius: 0;`}
    ${({ headerSectionBackground }) => headerSectionBackground && `padding-top: 0;`}

    .list-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 40px;
      padding: 5px 0 5px 0;
      border-radius: 5px;
      background-color: ${colors.white};
      margin-bottom: 15px;

      .fake-button {
        width: 24px;
        height: 24px;
      }

      .approved-by {
        display: flex;
        align-items: center;

        span {
          margin-left: 5px;
        }
      }

      .isNew {
        width: 5%;
        display: flex;
        justify-content: center;
      }

      .list-item-section {
        width: ${({ headerSectionWidth }: IBTActivityListViewWrapper) =>
          headerSectionWidth && headerSectionWidth}%;
        border-left: 1px solid ${colors.navyGrey[400]};
        padding-left: 15px;

        span {
          font-size: 10px;
          line-height: 10px;
          color: ${colors.projectColors.black};
        }
      }

      &:last-child {
        margin-bottom: 0;
      }

      & > div:nth-child(1) {
        border-left: none;
      }
    }
  }

  .pagination-block {
    margin-top: 12px;
    button {
      background-color: ${colors.navyGrey[200]};

      &:hover {
        background-color: ${colors.navyGrey[300]};
      }
    }

    .Mui-selected {
      background-color: ${colors.projectColors.main};
      color: ${colors.white};

      &:hover {
        background-color: ${colors.projectColors.main};
      }
    }
  }
`
