import styled from '@emotion/styled'

export const BTAddTaskButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  min-width: fit-content;

  button {
    text-transform: capitalize;
    padding: 8px 10px;
    min-width: fit-content;
  }
`
