/* eslint-disable no-nested-ternary */
/* eslint-disable import/order */
/* eslint-disable prettier/prettier */
import { FC } from 'react'
import * as ExternalRoutes from './routes'
import { AppWrapper } from 'components/AppWrapper'
import { BTLoader } from 'components/shared/bt-loader'
import { RouteObject, useRoutes } from 'react-router-dom'
import { useMeWithOrganizationQuery } from 'generated/graphql'
import { BTAuthWrapper } from 'components/shared/bt-auth-wrapper'
import { useSetupGlobalVariables } from 'hooks/useSetupGlobalState'

export const BTRouter: FC = () => {
  const {
    AuthRoutes,
    DevelopmentRoutes,
    ErrorRoutes,
    PostSignupFormRoutes,
    GlobalAssetsRoutes,
    ProjectsRoutes,
    TasksRoutes,
    InsightsRoutes,
    WorkspaceRoutes,
    FavoritesRoutes,
    UserRoutes,
  } = {
    ...ExternalRoutes,
  }

  const [globalLoading] = useSetupGlobalVariables()
  const { data, loading } = useMeWithOrganizationQuery()

  const me = data?.meWithOrganization
  const isPostSignUpFormCompleted = me?.isPostSignUpFormCompleted

  const MiddleRoutes: RouteObject[] = [PostSignupFormRoutes].flat()

  const MainRoutes: RouteObject[] = [
    InsightsRoutes,
    DevelopmentRoutes,
    ErrorRoutes,
    GlobalAssetsRoutes,
    ProjectsRoutes,
    TasksRoutes,
    WorkspaceRoutes,
    FavoritesRoutes,
    UserRoutes,
  ].flat()

  const AuthGateRoutes: RouteObject[] = [AuthRoutes].flat()

  const routes = () => [
    {
      path: '/',
      element: me && isPostSignUpFormCompleted ? <AppWrapper /> : <BTAuthWrapper />,
      children: !me ? AuthGateRoutes : isPostSignUpFormCompleted ? MainRoutes : MiddleRoutes,
    },
  ]

  const ROUTES = useRoutes(routes())

  if (loading || globalLoading) return <BTLoader />

  return ROUTES
}
