import { ClickAwayListener, Tooltip } from '@mui/material'
import { useCreateModalWindowHandlers } from 'hooks/shared/useCreateModalWindowHandlers'
import { FC, useEffect } from 'react'
import { BTPointWrapper } from './bt-point.presets'

import type { IBTPointProps } from './bt-point.props'
import { BTMessageTooltip } from './components/bt-message-tooltip'

export const BTPoint: FC<IBTPointProps> = ({
  number,
  position,
  setIsUsed,
  setPointData,
  feedbackId,
  message,
  messageId,
  scrollBlockRef,
  canEdit,
}) => {
  const [isOpen, openTooltip, closeTooltip] = useCreateModalWindowHandlers(message === undefined)

  const openHandler = () => {
    if (canEdit) {
      setIsUsed(true)
      openTooltip()
    }
  }

  const closeHandler = () => {
    setIsUsed(false)
    closeTooltip()
  }

  useEffect(() => {
    if (isOpen) {
      setIsUsed(true)
    }

    return () => {
      setIsUsed(false)
    }
  }, [isOpen])

  useEffect(() => {
    if (!canEdit) {
      closeHandler()
    }
  }, [canEdit])

  useEffect(() => {
    if (scrollBlockRef && scrollBlockRef.current) {
      scrollBlockRef.current.addEventListener(
        'scroll',
        () => {
          closeHandler()
        },
        { capture: true }
      )
    }

    return () => {
      if (scrollBlockRef && scrollBlockRef.current) {
        scrollBlockRef.current.removeEventListener(
          'scroll',
          () => {
            closeHandler()
          },
          { capture: true }
        )
      }
    }
  }, [])

  return (
    <Tooltip
      open={isOpen}
      onClose={undefined}
      title={
        <BTMessageTooltip
          closeHandler={closeHandler}
          feedbackId={feedbackId}
          setPointData={setPointData}
          position={position}
          message={message}
          messageId={messageId}
        />
      }
      arrow
    >
      <BTPointWrapper offsetX={position.x} offsetY={position.y} onClick={openHandler}>
        {number}
      </BTPointWrapper>
    </Tooltip>
  )
}
