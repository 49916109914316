import { ChangeEvent, FC, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import { Button, ClickAwayListener, TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { BTEditMessageWrapper } from './bt-edit-message.presets'

import type { IBTEditMessageProps } from './bt-edit-message.props'

export const BTEditMessage: FC<IBTEditMessageProps> = ({
  content,
  closeHandler,
  editMessageHandler,
}) => {
  const [value, setValue] = useState(content ?? '')
  const [loading, setLoading] = useState(false)

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value: inputValue } = event.target

    setValue(inputValue)
  }

  const saveHandler = async () => {
    if (value && value.trim() !== '') {
      setLoading(true)

      await editMessageHandler(value)

      setLoading(false)
      closeHandler()
    }
  }

  return (
    <ClickAwayListener onClickAway={closeHandler}>
      <BTEditMessageWrapper>
        <TextField
          value={value}
          onChange={onChangeHandler}
          placeholder="Write message text"
          autoFocus
          autoComplete="off"
          fullWidth
        />
        <Button endIcon={<ClearIcon />} variant="outlined" onClick={closeHandler} />
        <LoadingButton
          endIcon={<CheckIcon />}
          loading={loading}
          disabled={value === '' || value.trim() === ''}
          onClick={saveHandler}
          variant="contained"
        />
      </BTEditMessageWrapper>
    </ClickAwayListener>
  )
}
