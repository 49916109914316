import { DropzoneOptions, FileError } from 'react-dropzone'
import { OnlyOne } from 'types/shared'
import { SizesObject, PartialSizesObject, Sizes } from './bt-files-drop.types'

export function typeToBytes<T extends PartialSizesObject>(type: OnlyOne<T>) {
  if (Object.keys(type).length <= 0) return undefined
  const sizes: SizesObject = {
    kb: 1e3,
    mb: 1e6,
  }
  const key: Sizes = Object.keys(type)[0] as Sizes

  return sizes[key] * (type[key] as number)
}

// export const validator: DropzoneOptions['validator'] = (file) => {
//   const error: FileError = { code: '1', message: 'cmon' }
//   return error
// }
