import styled from 'styled-components'
import { colors } from '../../../../../theme/Colors'

const BTUserBlockWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px 5px 5px;
  background: #f2f2f2;
  border-radius: 5px;

  &&.approved {
    background: ${colors.projectColors.approvedBackground};
  }
`
const BTUserBlockText = styled.div`
  ${({ theme }) => ({ ...theme.typography?.caption })};
`
const BTUserWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

const BTMoreButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  color: ${colors.projectColors.fileSize};
  cursor: pointer;
`

export { BTUserBlockWrapper, BTUserBlockText, BTUserWrapper, BTMoreButtonWrapper }
