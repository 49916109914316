import { FC, MouseEvent, useEffect, useMemo, useRef } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { Page, Document } from 'react-pdf'
import { v4 as uuidv4 } from 'uuid'
import { PuffLoader } from 'react-spinners'
import { colors } from 'theme/Colors'
import { LOADER_SIZE } from 'components/bt-artwork/bt-artwork.constants'
import { PDFPageProxy } from 'pdfjs-dist'
import { INITIAL_PAGE_NUMBER } from 'helpers/constants'
import { BTFilePreviewWrapper } from './bt-file-preview.presets'
import { PAGE_HEIGHT, PAGE_SCALE, PAGE_WIDTH } from './bt-file-preview.constants'

import type { IBTFilePreviewProps } from './bt-file-preview.props'
import { scrollTo } from './bt-file-preview.helper'

export const BTFilePreview: FC<IBTFilePreviewProps> = ({
  arrayOfPages,
  currentPage,
  setCurrentPage,
  onDocumentLoadSuccess,
  file,
  isLoading,
  type,
}) => {
  const scrollRef = useRef<ScrollContainer>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  const pageClickHandler = (_event: MouseEvent<Element>, { pageNumber }: PDFPageProxy) => {
    /** Condtion for avoiding click event if presented only one page */
    if (arrayOfPages.length > INITIAL_PAGE_NUMBER) {
      scrollTo(pageNumber, scrollRef, wrapperRef)
      setCurrentPage(pageNumber)
    }
  }

  useEffect(() => {
    scrollTo(currentPage, scrollRef, wrapperRef)
  }, [currentPage])

  const pages = useMemo(
    () =>
      arrayOfPages.length > 0 &&
      arrayOfPages.map((page) => (
        <Page
          height={PAGE_HEIGHT}
          width={PAGE_WIDTH}
          scale={PAGE_SCALE}
          pageNumber={page}
          key={uuidv4()}
          loading={<PuffLoader size={LOADER_SIZE} color={colors.projectColors.main} loading />}
          className={page === INITIAL_PAGE_NUMBER ? 'page current-page' : 'page'}
          onClick={pageClickHandler}
          renderMode="canvas"
        />
      )),
    [arrayOfPages]
  )

  return (
    <BTFilePreviewWrapper ref={wrapperRef}>
      {isLoading ? (
        <PuffLoader color={colors.projectColors.main} size={LOADER_SIZE} />
      ) : (
        <ScrollContainer
          hideScrollbars={false}
          vertical
          horizontal={false}
          className="scroll-wrapper"
          ref={scrollRef}
        >
          {type === 'application/pdf' ? (
            <Document
              onLoadSuccess={onDocumentLoadSuccess}
              file={file}
              loading={<PuffLoader size={LOADER_SIZE} color={colors.projectColors.main} loading />}
            >
              {pages}
            </Document>
          ) : (
            <img
              src={typeof file === 'string' ? file : undefined}
              alt="artwork"
              className="current-page page image"
            />
          )}
        </ScrollContainer>
      )}
    </BTFilePreviewWrapper>
  )
}
