/* eslint-disable consistent-return */
import { fileLoad } from '../../helpers/file/fileLoad'
import { useGetObjectUrlMutation } from '../../generated/graphql'

export const useFileDownload = () => {
  const [getFileUrl] = useGetObjectUrlMutation()
  return async (briefUploadDocumentId: string | undefined) => {
    if (briefUploadDocumentId) {
      const fileUrlResponse = await getFileUrl({
        variables: { file: { id: briefUploadDocumentId } },
      })

      const fileUrl = fileUrlResponse.data?.getObjectUrl
      const file = await fileLoad(fileUrl)

      return file
    }
  }
}
