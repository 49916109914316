export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Activity: ['Artwork', 'Brief', 'DefaultActivity', 'Workshop'],
    Asset: ['Document', 'File', 'Form'],
    QuestionPart: [
      'CheckboxQuestionPart',
      'DateQuestionPart',
      'FileQuestionPart',
      'KeywordSelectQuestionPart',
      'NumericQuestionPart',
      'SelectQuestionPart',
      'SliderQuestionPart',
      'TextQuestionPart',
    ],
    QuestionResponse: ['AssetResponse', 'TextResponse'],
    Reference: ['ImageReference'],
  },
}
export default result
