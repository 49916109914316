import { useParams } from 'react-router-dom'
import { useAppSelector } from 'redux/store'
import { useSentNotificationMutation, useGetBriefQuery } from 'generated/graphql'

export const useSendActivity = () => {
  const [sendNotification] = useSentNotificationMutation()
  const { id: projectId, activityId } = useParams()
  const senderId = useAppSelector((state) => state.login.whoami.id)
  const briefResponse = useGetBriefQuery({ variables: { id: projectId } })
  const briefId = briefResponse.data?.getBrief.id

  const id = activityId || briefId

  return async (userIds: string[], purpose: string, personalizedMessage: string) => {
    if (id) {
      await sendNotification({
        variables: {
          input: {
            activityId: id,
            purpose,
            personalizedMessage,
            userIds,
            data: {
              activityId,
              projectId,
              senderId,
              targetUsersIds: userIds,
            },
          },
        },
      })
    }
  }
}
