import { cognitoUserPool } from 'helpers/cognito'
import { useMeWithOrganizationQuery } from 'generated/graphql'
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js'

export function useLogin(): (
  email: string,
  password: string,
  successPage?: string
) => Promise<CognitoUser> {
  const meDetails = useMeWithOrganizationQuery()

  return async (email, password): Promise<CognitoUser> => {
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    })

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: cognitoUserPool,
    })

    await new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        async onSuccess() {
          resolve(cognitoUser)
        },

        onFailure(err) {
          reject(err)
        },

        newPasswordRequired() {
          resolve(cognitoUser)
        },
      })
    })

    await meDetails.refetch()

    return cognitoUser
  }
}
