/* eslint-disable max-len */
/* eslint-disable consistent-return */
import { DateTime } from 'luxon'
import { TIME_MATH_VALUES } from '../constants'

export const getFileAge = (fileTime: string) => {
  const { millisInSec, secInMin, minInHour, hoursInDay } = TIME_MATH_VALUES

  const fileUploadTime = DateTime.fromISO(fileTime).toMillis()
  const now = DateTime.now().toMillis()

  const fileAge = now - fileUploadTime

  const seconds = fileAge / millisInSec
  const minutes = seconds / secInMin
  const hours = minutes / minInHour
  const days = hours / hoursInDay

  const fullDays = days.toString().split('.')[0]
  const fullHours = hours.toString().split('.')[0]
  const fullMinutes = minutes.toString().split('.')[0]
  const fullSeconds = seconds.toString().split('.')[0]

  if (Number(fullDays)) {
    return Number(fullDays) === 1 ? `${fullDays} day` : `${fullDays} days`
  }

  if (Number(fullHours)) {
    return Number(fullHours) === 1 ? `${fullHours} hour` : `${fullHours} hours`
  }
  if (Number(fullMinutes)) {
    return Number(fullMinutes) === 1 ? `${fullMinutes} minute` : `${fullMinutes} minutes`
  }
  if (Number(fullSeconds)) {
    return Number(fullSeconds) === 1 ? `${fullSeconds} second` : `${fullSeconds} seconds`
  }
  return '1 second'
}
