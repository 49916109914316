import styled from '@emotion/styled'
import { colors } from '../../theme/Colors'

interface IBTTaskPageLayoutWrapperProps {
  scrollWidth: number
  topOffset?: number
}

export const BTTaskPageLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-left: 80px;
`

export const BTTaskGridsWrapper = styled.div`
  user-select: none;
  margin-left: 80px;
  margin-top: ${({ topOffset }) => topOffset && `${topOffset}px`};

  .horizontal-scroll-block {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    width: ${({ scrollWidth }: IBTTaskPageLayoutWrapperProps) => scrollWidth && `${scrollWidth}px`};
    height: 100%;
  }

  .horizontal-scroll-block::-webkit-scrollbar {
    background-color: transparent;
    height: 10px;
  }

  .horizontal-scroll-block::-webkit-scrollbar-thumb {
    background-color: ${colors.navyGrey[200]};
    border-radius: 10px;
  }
`
