import { useEffect } from 'react'
import {
  File,
  FileData,
  GetAllActivityAssetsDocument,
  GetAllActivityAssetsQuery,
  GlobalAsset,
  useReorderFileVersionsMutation,
} from 'generated/graphql'
import { toastNotifications } from 'helpers/toastNotifications'
import { sortFileByVersionsClb } from 'components/bt-artwork/bt-artwork.helper'

export function useReorderAssetsVersion() {
  const [reorderAssetsVersion, { error }] = useReorderFileVersionsMutation()

  useEffect(() => {
    if (error) {
      toastNotifications(error.message).error()
    }
  }, [error])

  return async (fileList: FileData[], artworkId: string) => {
    await reorderAssetsVersion({
      variables: { input: { fileList } },
      update: (cache) => {
        const oldAssets = cache.readQuery({
          query: GetAllActivityAssetsDocument,
          variables: { activityId: artworkId ?? '' },
        }) as GetAllActivityAssetsQuery

        const { getAllActivityAssets: assets } = oldAssets

        if (assets) {
          const newAssets: GlobalAsset[] = assets.slice() as GlobalAsset[]

          fileList.forEach(({ fileId, version }) => {
            const index = newAssets.findIndex((elem) => elem.fileId === fileId)

            if (index !== -1) {
              const oldAsset = newAssets[index]
              const newAsset = {
                ...oldAsset,
                file: { ...oldAsset.file, version } as unknown as File,
              } as unknown as GlobalAsset

              newAssets[index] = newAsset
            }
          })

          newAssets.sort((first, second) =>
            sortFileByVersionsClb(first as GlobalAsset, second as GlobalAsset)
          ) as GlobalAsset[]

          cache.writeQuery({
            query: GetAllActivityAssetsDocument,
            variables: { activityId: artworkId ?? '' },
            data: {
              getAllActivityAssets: newAssets,
            },
          })
        }
      },
    })
  }
}
