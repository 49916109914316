import {
  useUpdateActivityMutation,
  ProjectFragment,
  ProjectFragmentDoc,
  MilestoneFragment,
  ActivityFragment,
} from 'generated/graphql'
export function useArchiveActivityCard() {
  return useUpdateActivityMutation()
}
