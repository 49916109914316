import React, { FC } from 'react'
import { BTPadWrapper } from './bt-questions-page-pad.presets'
import { BTQuestionsPadProps } from './bt-questions-page-pad.props'
export const BTQuestionsPad: FC<BTQuestionsPadProps> = ({ isSelected, name, selectHandler }) => {
  const innerHandler = () => {
    selectHandler(name)
  }
  return (
    <BTPadWrapper onClick={innerHandler} isSelected={isSelected}>
      {name}
    </BTPadWrapper>
  )
}
