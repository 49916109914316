import { FC } from 'react'
import { useWizard } from 'react-use-wizard'
import { useDropzone } from 'react-dropzone'
import { setImage } from 'redux/PostSignupFormSlice'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { ReactComponent as FileUploadIcon } from './upload.svg'
import { BTPostSignupFormContainer } from './bt-post-signup-form-wrapper'
import {
  BTAvatarIcon,
  BTAvatarImage,
  BTAvatarDescription,
  BTAvatarUploadWrapper,
  BTAvatarDescriptionTitle,
  BTAvatarDescriptionSubTitle,
} from '../bt-post-signup-form.presets'

export const BTPostSignupFormUploadAvatar: FC = () => {
  const { nextStep, activeStep, stepCount } = useWizard()
  const { image } = useAppSelector((state) => state.postSignupForm)
  const dispatch = useAppDispatch()

  const onDrop = (files: File[]) => {
    files.forEach((e) => {
      const reader = new FileReader()
      reader.onload = () => {
        dispatch(setImage(reader.result as string))
      }
      reader.readAsDataURL(e)
    })
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/jpeg', 'image/png'],
    onDrop,
    noClick: true,
  })

  const renderAvatarEdit = () => {
    return (
      <div className="avatar-block">
        <div {...getRootProps({ className: 'avatar' })}>
          <label htmlFor="file" className="file-label">
            <BTAvatarUploadWrapper>
              <BTAvatarIcon>
                {image ? <BTAvatarImage src={image} /> : <FileUploadIcon />}
              </BTAvatarIcon>
              <div className="text">
                <BTAvatarDescriptionTitle>
                  Drag and Drop or Click to Upload
                </BTAvatarDescriptionTitle>
                <BTAvatarDescriptionSubTitle>Dimensions 123 x 123</BTAvatarDescriptionSubTitle>
              </div>
            </BTAvatarUploadWrapper>
          </label>
          <input id="file" {...getInputProps()} />
        </div>
      </div>
    )
  }
  return (
    <BTPostSignupFormContainer
      title="Upload an Avatar"
      btnTitle="Next"
      goNext={nextStep}
      content={
        <div>
          <BTAvatarDescription>
            Upload a professional profile picture, this will be used to identify you throughout the
            briefing process.
          </BTAvatarDescription>
          {renderAvatarEdit()}
        </div>
      }
      activeStep={activeStep}
      stepCount={stepCount}
    />
  )
}
