/* eslint-disable consistent-return */
import { composedDownload } from '../../helpers/compose/composedDownload'
import { useGetObjectUrlMutation } from '../../generated/graphql'

export const useGetComposeDocument = () => {
  const [getDocumentUrl] = useGetObjectUrlMutation()
  return async (composedDocumentId: string | undefined) => {
    if (composedDocumentId) {
      const documentUrlResponse = await getDocumentUrl({
        variables: { file: { id: composedDocumentId } },
      })
      const documentUrl = documentUrlResponse.data?.getObjectUrl
      const file = await composedDownload(documentUrl)
      return file
    }
  }
}
