import styled from '@emotion/styled'
import { colors } from '../../../../../theme/Colors'

interface IBTMessageWrapperProps {
  pinned: boolean
  completed: boolean
  focus: boolean
}

export const BTMessageWrapper = styled.div<IBTMessageWrapperProps>`
  position: relative;
  padding: 10px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${colors.navyGrey[200]};
    background-color: ${({ pinned, completed }) => pinned && !completed && `#B0D0E1`};

    background-color: ${({ completed }) => completed && `#A9CCB9`};

    .action-buttons {
      display: block;
      background-color: ${colors.white};
    }
  }

  background-color: ${({ focus }) => focus && `${colors.navyGrey[200]}`};
  background-color: ${({ pinned }) => pinned && `${colors.blue[200]}`};
  background-color: ${({ completed }) => completed && `#A9D9BD`};

  .show-buttons {
    display: block !important;
    background-color: ${colors.white} !important;
  }

  .top-block {
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .name {
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
      text-overflow: ellipsis;
      max-width: 120px;
      white-space: nowrap;
      overflow: hidden;
    }

    .time {
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;
      color: ${colors.navyGrey[400]};
    }

    .info {
      display: flex;
      flex-direction: column;

      .reply-user {
        font-size: 10px;
        font-weight: 500;
        line-height: 14px;

        span {
          font-size: 10px;
          font-weight: 700;
          line-height: 14px;
          text-overflow: ellipsis;
          max-width: 80px;
          white-space: nowrap;
          overflow: hidden;
        }
      }

      .created-user {
        display: flex;
        gap: 10px;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
      }
    }

    .position {
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;
      color: #fff;
      background-color: ${colors.projectColors.main};
      border-radius: 100%;
    }
  }

  .main-block {
    margin-top: 5px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    max-width: 100%;
    word-wrap: break-word;
  }

  .input-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 5px;

    input {
      padding: 5px 10px;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
  }

  .icons-block {
    position: absolute;
    display: flex;
    flex-direction: row;
    gap: 5px;
    top: 8px;
    right: 10px;
    z-index: 1;

    svg {
      font-size: 16px;
    }

    .pinned-icon {
      color: ${colors.projectColors.main};
    }

    .complete-icon {
      color: #27ae60;
    }
  }

  .action-buttons {
    position: absolute;
    top: 2px;
    right: 2px;
    display: none;
    border-radius: 5px;
    z-index: 10;

    .button-wrapper {
      display: flex;
      flex-direction: row;

      svg {
        font-size: 16px;
      }

      .pin-icon {
        ${({ pinned }) => (pinned && pinned ? `color: ${colors.projectColors.main};` : '')}
      }

      .complete-icon {
        ${({ completed }) => (completed && completed ? `color: #27ae60;` : '')}
      }
    }
  }
`
