import { FC, KeyboardEvent, useCallback, useState } from 'react'
import { BTFeedbackWrapper } from './bt-feedback-send-block.presets'
import type { BTFeedbackSendBtn } from './bt-feedback-send-block.props'
import { BTFeedbackShare } from './bt-feedback-share'

export const BTFeedbackSendBlock: FC<BTFeedbackSendBtn> = ({ externalHandler }) => {
  const [inputValue, setInputValue] = useState('')
  const sendHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key !== 'Enter') return
      externalHandler(inputValue)
      setInputValue('')
    },
    [inputValue]
  )
  return (
    <BTFeedbackWrapper>
      <BTFeedbackShare setValue={setInputValue} value={inputValue} keyDownHandler={sendHandler} />
    </BTFeedbackWrapper>
  )
}
