import { FC } from 'react'
import type { BTDeliverableItemProps } from './bt-deliverable-item.props'
import { Block, Title, Wrapper } from './bt-deliverable-item.presets'

export const BTDeliverableItem: FC<BTDeliverableItemProps> = ({ deliverableType, onClick }) => {
  const onInnerClick = () => {
    onClick(deliverableType)
  }

  return (
    <Wrapper onClick={onInnerClick}>
      <Block>
        <Title>{deliverableType.name}</Title>
      </Block>
    </Wrapper>
  )
}
