import styled from 'styled-components'
import { colors } from 'theme/Colors'

const BTDropWrapper = styled.div`
  padding: 40px 100px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${colors.projectColors.main}0D;
  border: 1.5px dashed ${colors.projectColors.main};
  border-radius: 5px;
`
const BTDropText = styled.div`
  pointer-events: none;
  color: #4f4f4f;
  ${({ theme }) => ({ ...theme.typography?.regular })};
`

const BTDropDescription = styled.div`
  font-family: Poppins;
  font-size: 8px;
  font-weight: 400;
  line-height: 8px;
  letter-spacing: -0.23999999463558197px;
  text-align: left;
`

export { BTDropText, BTDropWrapper, BTDropDescription }
