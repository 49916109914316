/* eslint-disable no-unused-vars */
export interface IFileToConvertor {
  name: string | undefined
  content: Blob | undefined
}

export interface IFileFromConvertor {
  data: string | ArrayBuffer | null
  type: string | undefined
  size: number | undefined
  name: string | undefined
}

export const fileConvert = (
  files: IFileToConvertor[],
  setToState: (decoded: IFileFromConvertor[]) => void
) => {
  return files?.forEach((file: IFileToConvertor) => {
    const reader = new FileReader()

    if (file.content) {
      reader.readAsDataURL(file.content)
      reader.onloadend = () => {
        setToState([
          {
            data: reader.result,
            type: file.content?.type,
            size: file.content?.size,
            name: file.name,
          },
        ])
      }
    }
  })
}
