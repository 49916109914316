import { ErrorPage } from 'components/ErrorPage'
import { BTErrorTemplatePage } from 'components/shared/bt-error-page'
import { RouteObject } from 'react-router-dom'

const ErrorRoutes: RouteObject[] = [
  { path: 'error', element: <ErrorPage /> },
  { path: '404', element: <BTErrorTemplatePage /> },
]

export { ErrorRoutes }
