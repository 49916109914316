/* eslint-disable import/no-unresolved */
import { FC } from 'react'
import { List, MenuItem } from '@mui/material'
import { useLogout } from 'hooks/user/useLogout'
import type { AccountDetailsMenuProps } from './account-details-menu.props'

export const BTAccountDetailsMenu: FC<AccountDetailsMenuProps> = ({ loading }) => {
  const logout = useLogout()

  /**
   * @todo add loader
   */
  if (loading) return <div>loading</div>
  return (
    <List aria-labelledby="nested-list-subheader" dense>
      <MenuItem onClick={logout}>Log out</MenuItem>
    </List>
  )
}
