import { IconButton } from '@mui/material'
import { FC, memo } from 'react'
import { DrawerHeader } from './bt-drawer-header.presets'
import { ReactComponent as BrieftLogo } from '../../../../assets/images/brieftLogo.svg'

export const BTDrawerHeaderBlock = memo(() => {
  return (
    <DrawerHeader>
      <IconButton>
        <BrieftLogo />
      </IconButton>
    </DrawerHeader>
  )
})
