import { FC } from 'react'
import type { ColumnProps } from './bt-brief-column.props'
import { BTDeliverableItem } from 'components/shared/deliverable-item'
import { Wrapper } from './bt-brief-column.presets'
import { useAppDispatch } from '../../../../../redux/store'
import { appendDeliverable, removeDeliverable } from '../../../../../redux/SidebarAppsSlice'
export const BTBriefColumn: FC<ColumnProps> = ({ items }) => {
  const dispatch = useAppDispatch()
  const handleSelect = (name: string, id: string, isSelected?: boolean) => () => {
    if (isSelected) {
      dispatch(removeDeliverable({ id, name }))
    } else {
      dispatch(appendDeliverable({ id, name }))
    }
  }
  return (
    <Wrapper>
      {items && items.length
        ? items.map(({ name, id, isSelected }, index) => {
            return (
              <BTDeliverableItem
                id={id}
                key={id}
                name={name}
                isSelected={isSelected}
                onClick={handleSelect(name, id, isSelected)}
                styles={{ borderRadius: '8px' }}
                /** @todo add @property description */
              />
            )
          })
        : null}
    </Wrapper>
  )
}
