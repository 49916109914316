/* eslint-disable no-unused-expressions */
import { FC, ChangeEvent, useState, useEffect } from 'react'
import { Box, TextField, Typography } from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'
import LoadingButton from '@mui/lab/LoadingButton'
import { useNavigate } from 'react-router-dom'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { BTLoginFormWrapper } from '../bt-login/bt-login.presets'
import { FormValues } from './bt-forgot-password.types'
import { cognitoUserPool } from '../../../helpers/cognito'
import { toastNotifications } from '../../../helpers/toastNotifications'

export const BTForgotPass: FC = () => {
  const navigate = useNavigate()
  const [error, setError] = useState<string | null | boolean>(null)
  const [submitting, setSubmitting] = useState<boolean>(false)

  const emailValidationSchema = yup.object({
    email: yup.string().email('Wrong email format').required('Email required'),
  })

  const onChange = (value: string, fieldName: string) => {
    formik.setFieldValue(fieldName, value)
    formik.validateField(fieldName)
  }

  const onSubmit = async ({ email }: FormValues) => {
    setSubmitting(true)

    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: cognitoUserPool,
    })

    setError(null)

    cognitoUser.forgotPassword({
      onSuccess: () => {
        setSubmitting(false)
        navigate('/recover')
      },
      onFailure: (err) => {
        setError(err.message)
        setSubmitting(false)
      },
    })
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      email: '',
    },
    validationSchema: emailValidationSchema,
    onSubmit,
  })

  useEffect(() => {
    toastNotifications(error, setError).error()
  }, [error])

  return (
    <BTLoginFormWrapper>
      <Box className="wrapper" sx={{ boxShadow: 3 }}>
        <Typography variant="h5">Forgot Password</Typography>
        <form className="inputs-block" onSubmit={formik.handleSubmit}>
          <div className="input-block">
            <span>Email</span>
            <TextField
              placeholder="name@company.com"
              helperText={formik.errors.email}
              onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
                onChange(target.value, 'email')
              }
              error={!!formik.errors.email}
              autoComplete="off"
            />
          </div>
        </form>
        <LoadingButton
          variant="contained"
          fullWidth
          onClick={() => formik.handleSubmit()}
          loading={submitting}
        >
          <Typography variant="subtitle1">Send Link</Typography>
        </LoadingButton>
      </Box>
    </BTLoginFormWrapper>
  )
}
