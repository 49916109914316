import React, { FC, Fragment } from 'react'
import type { BTWordHighlighterProps } from './bt-word-highlighter.props'
import { BTHighlightedLabel } from './bt-word-highlighter.presets'
import { v4 } from 'uuid'
/**
 * @example 'some text %HIGHLIGHTED text% some text'
 */
export const BTWordHighlighter: FC<BTWordHighlighterProps> = ({ children }) => {
  if (typeof children !== 'string') return <></>
  const regex = /(?<word>(\%+\w+\%)+)/gm
  const childrenToMap = children.split(' ')
  const mappedChildren = childrenToMap.map((child) => {
    const matched = child.match(regex)
    const wordToHighlight = matched && matched[0].replace(/\%/gi, '')
    return wordToHighlight ? <BTHighlightedLabel>{wordToHighlight}</BTHighlightedLabel> : child
  })
  return (
    <>
      {mappedChildren.map((node) => {
        return <Fragment key={v4()}>{node}&nbsp;</Fragment>
      })}
    </>
  )
}
