/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
import { v4 } from 'uuid'
import { FilterIcon } from './filterIcon'
import { Pagination } from '@mui/material'
import { useAppSelector } from 'redux/store'
import MenuItem from '@mui/material/MenuItem'
import { PAGINATION } from 'helpers/constants'
import { Transition } from 'react-transition-group'
import type { BTAssetsProps } from './bt-assets.props'
import { BTSearch } from 'components/shared/bt-search'
import ScrollContainer from 'react-indiana-drag-scroll'
import { BTFileBlock } from '../bt-file-block/bt-file-block'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { GlobalAssetFilter, getOrganizationsIds } from './utils'
import { getFileExtention } from 'helpers/file/getFileExtention'
import { useState, FC, ChangeEvent, useEffect, useRef } from 'react'
import { BTAssetsUploadButton } from './components/bt-assets-upload'
import { BTNoAssetsTutorial } from './components/bt-no-assest-tutorial'
import { SortOrder, useGetAllAssetsQuery, useGetAllAssetsLazyQuery } from 'generated/graphql'
import {
  BTAssetsRow,
  BTMainWrapper,
  BTFilterWrapper,
  PaginationWrapper,
  BTFilterButtonContent,
  BTAssetsContentWrapper,
  BTLastUpdatedFilterTitle,
  BTLastUpdatedFilterButton,
  BTAssetsFilesBlockWrapper,
  BTLastUpdatedFilterWrapper,
} from './bt-assets.presets'

export const BTAssets: FC<BTAssetsProps> = () => {
  const [filter, setFilter] = useState<number>(0)
  const [sorting, setSorting] = useState<SortOrder>(SortOrder.Desc)
  const [search, setSearch] = useState<string>('')
  const { organizations } = useAppSelector((state) => state.login)
  const [uploadedFilterActivated, setUploadedFilterActivated] = useState(false)
  const [page, setPage] = useState<number>(1)

  const organizationsIds = getOrganizationsIds(organizations)
  const isTheFirstRender = useRef(true)
  const [getAllAssets] = useGetAllAssetsLazyQuery()
  const { data: allAssets, refetch } = useGetAllAssetsQuery({
    variables: {
      input: {
        organizations: organizationsIds,
        type: GlobalAssetFilter[filter].value,
        searchValue: search,
      },
      page,
      sortOrder: sorting,
    },
  })

  const showfilterCondition = !!allAssets?.getAllAssets.assets.length
  const showTutorialCondition = !allAssets?.getAllAssets.assets.length && !search
  const totalPages = allAssets?.getAllAssets.paginationData.totalPages
  const currentPage = allAssets?.getAllAssets.paginationData.currentPage
  const totalAmount = allAssets?.getAllAssets.paginationData.totalAmount
  const renderPaginationCondition =
    allAssets && allAssets.getAllAssets.paginationData.totalAmount >= PAGINATION.RENDER_CONDITION

  const filterHandler = ({ target }: SelectChangeEvent) => {
    const { value } = target
    setFilter(Number(value))
    setPage(1)
  }

  const sortHandler = () => {
    if (sorting === SortOrder.Desc) {
      setSorting(SortOrder.Asc)
      setUploadedFilterActivated(true)
    } else {
      setSorting(SortOrder.Desc)
      setUploadedFilterActivated(false)
    }
  }

  const paginationHandler = (e: ChangeEvent<unknown>, page: number) => {
    setPage(page)
  }

  const searchHandler = (arg: string) => {
    setSearch(arg)
    setPage(1)
  }

  const searchResultsRender = () => {
    if (allAssets) {
      if (search && search.length < 3) {
        return <p className="no-assets">Please type at least three symbols</p>
      }
      if (allAssets.getAllAssets.assets.length > 0) {
        return allAssets.getAllAssets.assets.map((asset, index) => {
          const fileExtinstion = getFileExtention(asset.file?.name)
          return (
            asset &&
            totalPages &&
            asset.file && (
              <BTFileBlock
                key={v4()}
                fileId={asset.file.id}
                fileName={asset.file.name}
                fileType={fileExtinstion!}
                createdAt={asset.updatedAt}
                activityId={asset.activityId}
                activityName={asset.activity?.name}
                activityType={asset.activity?.type}
                organizationsIds={organizationsIds}
                projectId={asset.projectId}
                projectName={asset.project?.name}
                getUrl={asset.file.getUrl}
                filter={filter}
                refetch={refetch}
                assetId={asset.id}
                searchValue={search}
                page={page}
                sortOrder={sorting}
                index={index}
                setPage={(arg: number) => setPage(arg)}
                totalPages={totalPages}
                currentPage={page}
                version={asset.file.version}
                projectColor={asset?.project?.color}
              />
            )
          )
        })
      }
      if (!allAssets.getAllAssets.assets.length && search) {
        return <p className="no-assets">There is no assets this type</p>
      }
    }
  }

  useEffect(() => {
    if (isTheFirstRender.current) {
      refetch({
        input: {
          organizations: organizationsIds,
          type: GlobalAssetFilter[filter].value,
          searchValue: search,
        },
      })
      isTheFirstRender.current = false
    }
  }, [])

  useEffect(() => {
    refetch({
      input: {
        organizations: organizationsIds,
        type: GlobalAssetFilter[filter].value,
        searchValue: search,
      },
      page,
      sortOrder: sorting,
    })
  }, [page])

  useEffect(() => {
    if (totalPages && totalPages > 1) {
      getAllAssets({
        variables: {
          input: {
            organizations: organizationsIds,
            type: GlobalAssetFilter[filter].value,
            searchValue: search,
          },
          page: page + 1,
          sortOrder: sorting,
        },
      })
    }
  }, [totalPages, totalAmount])

  return (
    <BTMainWrapper>
      <BTAssetsRow>
        <BTSearch
          searchSize="large"
          borderStyle="slightly rounded"
          borderColor="light"
          changeEvent={searchHandler}
          debounceDelay={800}
          placeholder="Search client, project or activity"
          value={search}
        />
        by
        <BTFilterWrapper>
          <Select
            onChange={filterHandler}
            defaultValue={GlobalAssetFilter[0].id.toString()}
            className="filter"
          >
            {GlobalAssetFilter.map((item) => {
              return (
                <MenuItem key={item.id} value={item.id.toString()}>
                  {item.name}
                </MenuItem>
              )
            })}
          </Select>
        </BTFilterWrapper>
        <BTAssetsUploadButton
          filter={filter}
          search={search}
          setPage={(arg: number) => {
            setPage(arg)
          }}
        />
      </BTAssetsRow>

      <BTLastUpdatedFilterWrapper>
        <BTLastUpdatedFilterTitle>All Assets</BTLastUpdatedFilterTitle>

        {showfilterCondition && (
          <BTLastUpdatedFilterButton onClick={sortHandler}>
            <BTFilterButtonContent>
              <Transition in={uploadedFilterActivated} timeout={10}>
                {(state) => (
                  <div className={`filter-icon-area ${state}`}>
                    <FilterIcon />
                  </div>
                )}
              </Transition>
              Last Uploaded
            </BTFilterButtonContent>
          </BTLastUpdatedFilterButton>
        )}
      </BTLastUpdatedFilterWrapper>

      <BTAssetsContentWrapper gap={20}>
        <ScrollContainer className="scroll" hideScrollbars={false} horizontal={false} vertical>
          {/* Tutorial */}
          {showTutorialCondition && <BTNoAssetsTutorial />}
          {/* Assets Rendering */}
          <BTAssetsFilesBlockWrapper>{searchResultsRender()}</BTAssetsFilesBlockWrapper>
        </ScrollContainer>
      </BTAssetsContentWrapper>

      {renderPaginationCondition && (
        <PaginationWrapper>
          <Pagination
            color="primary"
            count={totalPages}
            page={currentPage}
            onChange={(event: ChangeEvent<unknown>, page: number) => paginationHandler(event, page)}
          />
        </PaginationWrapper>
      )}
    </BTMainWrapper>
  )
}
