/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { IUser, Approvals } from './bt-approve.props'
import { GetApprovalsQuery, ApprovalStatus } from '../../../generated/graphql'
import { IMember } from '../bt-activity-send/components/bt-entry-step/bt-entry-step.helpers'

export const getApprovals = (
  approvalsResponse: GetApprovalsQuery,
  allMembers: IMember[] | undefined
) => {
  const approvals = approvalsResponse?.approvals.approve

  const result: IUser[] = []

  if (allMembers) {
    approvals?.map((approvalResponse) => {
      const approvalMember = allMembers.find((member) => {
        return member.user.id === approvalResponse.userId
      })
      const approvalObj = {
        ...approvalMember?.user,
        status: approvalResponse.status,
      }
      result.push(approvalObj)
    })
  }

  return result
}

export const findCurrentUserApprove = (
  approvals: Approvals[] | undefined,
  currentUserId: string
) => {
  if (approvals) {
    const currentUserAppraval = approvals.find((approval) => approval.userId === currentUserId)
    return currentUserAppraval?.status === ApprovalStatus.Approved
  }
}

export const disableApproveButton = (assignees: IUser[], currentUserId: string) => {
  const currentUser = assignees.find((item) => item.id === currentUserId)
  if (currentUser) {
    return false
  }
  return true
}
