import styled from '@emotion/styled'
import { colors } from '../../../../theme/Colors'

export const BTInviteMemberModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 80px;
  width: 400px;
  background-color: white;
  z-index: 1000;
  border-radius: 5px;

  .title {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    color: ${colors.black};
    padding-bottom: 30px;
  }

  .input-block {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 30px;

    .label {
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      color: ${colors.navyGrey[400]};
      padding-bottom: 5px;
    }

    input {
      font-family: Poppins;
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;
      padding: 8px 10px;
      width: 220px;
    }

    .select {
      width: 240px;

      .MuiSelect-select {
        padding: 6px 10px;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
      }
    }
  }

  button {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-transform: capitalize;
    width: 240px;
  }

  .description {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    width: 240px;
    color: ${colors.navyGrey[400]};
  }

  .email {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    color: ${colors.black};
    background-color: ${colors.navyGrey[200]};
    border-radius: 5px;
    padding: 6px 10px;
    width: 240px;
    margin-bottom: 30px;
  }
`
