// eslint-disable-next-line max-len
import { IBTToolsData } from '../components/bt-project-tools-modal/bt-project-tools-modal.props'

const getProjectTools = (projectId: string = ''): Array<IBTToolsData> => {
  return [
    {
      name: 'Gantt',
      path: `projects/${projectId}/gantt`,
      type: 'gantt',
    },
    {
      name: 'Time tracking',
      path: `projects/${projectId}/time-tracking`,
      type: 'time_tracking',
    },
    {
      name: 'Scheduling',
      path: `projects/${projectId}/scheduling`,
      type: 'scheduling',
    },
    {
      name: 'Calendar',
      path: `projects/${projectId}/calendar`,
      type: 'calendar',
    },
  ]
}

export default getProjectTools
