import { FC, ChangeEvent, useEffect } from 'react'
import { Button, TextField } from '@mui/material'
import { useFormik } from 'formik'
import { LoadingButton } from '@mui/lab'
import * as Yup from 'yup'
import { useCreateOrganization } from 'hooks/organization/useCreateOrganization'
import { BTCreateOrganizationWrapper } from './bt-create-organization.presets'
import { BTModalWindow } from '../../../shared/bt-modal-window'

import type { IBTInviteMemberProps } from '../bt-invite-member-modal'
import type { IBTCreateOrganizationFormikValues } from './bt-create-organization.props'

const CreateOrganizationValidationSchema = Yup.object({
  organizationName: Yup.string().required('Organization name is required'),
})

export const BTCreateOrganizationModal: FC<IBTInviteMemberProps> = ({ closeHandler, isOpened }) => {
  const createOrganization = useCreateOrganization()

  const onSubmit = async ({ organizationName }: IBTCreateOrganizationFormikValues) => {
    await createOrganization(organizationName, closeHandler)
  }

  const formik = useFormik<IBTCreateOrganizationFormikValues>({
    initialValues: {
      organizationName: '',
    },
    validationSchema: CreateOrganizationValidationSchema,
    onSubmit,
  })

  const onChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = target
    formik.setFieldValue('organizationName', value)
  }

  useEffect(() => {
    return () => {
      formik.setFieldValue('organizationName', '', false)
    }
  }, [closeHandler])

  return (
    <BTModalWindow isOpened={isOpened} handleClose={closeHandler}>
      <BTCreateOrganizationWrapper onSubmit={formik.handleSubmit}>
        <span className="title">Organization</span>
        <div className="input-block">
          <span className="label">Create organization</span>
          <TextField
            placeholder="Name"
            value={formik.values.organizationName}
            onChange={(event) => onChange(event)}
            fullWidth
            autoComplete="off"
            error={!!formik.errors.organizationName}
            helperText={formik.errors.organizationName}
          />
        </div>
        <div className="buttons-block">
          <Button variant="contained" color="secondary" type="button" onClick={closeHandler}>
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            loading={formik.isSubmitting}
            type="submit"
            disabled={
              !!formik.errors.organizationName || formik.values.organizationName.trim() === ''
            }
          >
            Save
          </LoadingButton>
        </div>
      </BTCreateOrganizationWrapper>
    </BTModalWindow>
  )
}
