import { HTMLProps } from 'react'
import styled from 'styled-components'

const BTQuestionsTextInput = styled.input<HTMLProps<HTMLInputElement>>`
  color: #333333;
  width: 100%;
  padding: 10px;
  background: #f2f2f2;
  outline: none;
  border: none;
  ${({ theme }) => ({ ...theme.typography?.regular })};
  &::placeholder {
    color: #333333;
    ${({ theme }) => ({ ...theme.typography?.regular })};
  }
`

export { BTQuestionsTextInput }
