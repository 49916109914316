import styled from '@emotion/styled'
import { colors } from 'theme/Colors'

interface IBTPointProps {
  offsetX: number
  offsetY: number
}

export const BTPointWrapper = styled.button<IBTPointProps>`
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  color: ${colors.white};
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  background-color: ${colors.projectColors.main};
  top: ${({ offsetY }) => offsetY && offsetY}px;
  left: ${({ offsetX }) => offsetX && offsetX}px;
  position: absolute;
  cursor: pointer;
  border: none;
`
