/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable array-callback-return */
import { v4 as uuidv4 } from 'uuid'
import Box from '@mui/material/Box'
import { colors } from 'theme/Colors'
import { IUser } from './bt-approve.props'
import { useEffect, useState } from 'react'
import { PuffLoader } from 'react-spinners'
import { useAppSelector } from 'redux/store'
import { useParams } from 'react-router-dom'
import { USER_ROLES } from 'helpers/constants'
import { BTSearch } from 'components/shared/bt-search'
import ScrollContainer from 'react-indiana-drag-scroll'
import { useIsAuthor } from 'helpers/CheckUserRoleAccess'
import { useUpdateApproval } from 'hooks/approve/useUpdateApproval'
import { useCurrentProject } from 'hooks/project/useCurrentProject'
import { BTAssignedUserBlock } from './components/bt-assigned-user-block'
import { BTSearchedUserBlock } from './components/bt-searched-user-block'
import { useGetApprovalsQuery, useGetBriefQuery } from 'generated/graphql'
import { useChangeApproveStatus } from 'hooks/approve/useChangeApproveStatus'
import { BTAppsLabel, BTAppsWrapper, BTAppsDivider, BTAppsSubLabel } from '../shared'
import { IMember } from '../bt-activity-send/components/bt-entry-step/bt-entry-step.helpers'
import { getApprovals, disableApproveButton, findCurrentUserApprove } from './bt-approve.helpers'
import {
  BTAssigneeItem,
  BTApproveButton,
  BTScrollWrapper,
  BTDividerWrapper,
  BTSeachInputWrapper,
  BTMembersListWrapper,
  BTApproveTextTopLabel,
  BTApproveTextBottomLabel,
} from './bt-approve.presets'

export const BTApprove = () => {
  const [members, setMembers] = useState<IMember[]>([])
  const [searchValue, setSearchValue] = useState('')
  const [searchedMembers, setSearchedMembers] = useState<IMember[]>([])
  const [assignees, setAssignees] = useState<IUser[]>([])
  const [approveLoading, setApproveLoading] = useState(false)

  const currentUserId = useAppSelector((state) => state.login.whoami.id)
  const { id: projectId, activityId, artworkId } = useParams()
  const { data: project } = useCurrentProject()
  const briefResponse = useGetBriefQuery({ variables: { id: projectId } })
  const briefId = briefResponse.data?.getBrief.id

  const update = useUpdateApproval()
  const changeApproveStatus = useChangeApproveStatus()

  const id = activityId || artworkId || briefId
  const { data: approvalResponse, loading } = useGetApprovalsQuery({
    variables: {
      id: id!,
    },
  })
  const { whoami } = useAppSelector((state) => state.login)
  const userRole = whoami?.organizationMember?.role
  const isAuthor = useIsAuthor(id)
  const isExternal = userRole === USER_ROLES.EXTERNAL

  const allMembers = project?.projects[0].members
  const disabledApprove = disableApproveButton(assignees, currentUserId)
  const approved = findCurrentUserApprove(approvalResponse?.approvals.approve, currentUserId)

  const searchHandler = (value: string) => {
    setSearchedMembers([])

    members.forEach((member) => {
      const lowerCasedValue = value.toLowerCase()
      const lowerCasedName = member.user.name?.toLowerCase()

      if (lowerCasedValue && lowerCasedValue && lowerCasedName?.includes(lowerCasedValue)) {
        setSearchedMembers((state) => {
          const newState = [...state]
          newState.push(member)
          return newState
        })
      }
    })
  }

  const setApproverHandler = async (userId: string) => {
    await update(userId, assignees)
  }

  const approveHandler = async () => {
    if (currentUserId) {
      setApproveLoading(true)

      await changeApproveStatus(currentUserId)

      setApproveLoading(false)
    }
  }

  const searchResultsRendering = () => {
    if (searchValue && !searchedMembers.length) {
      return <BTAppsSubLabel>There is no members with such name</BTAppsSubLabel>
    }

    if (searchValue && searchedMembers.length) {
      return searchedMembers.map((member) => {
        const { user } = member

        const isMemberSelected = assignees.some((assignee) => assignee.id === user.id)

        return (
          <BTSearchedUserBlock
            key={member.id}
            id={user.id}
            avatarUrl={user.avatarUrl}
            name={user.name}
            onClick={setApproverHandler}
            isSelected={isMemberSelected}
          />
        )
      })
    }

    return <div />
  }

  useEffect(() => {
    if (approvalResponse?.approvals) {
      const approvals = getApprovals(approvalResponse, allMembers)
      setAssignees(approvals)
    }
  }, [approvalResponse, loading, project])

  useEffect(() => {
    if (allMembers) {
      setMembers(allMembers)
    }
  }, [project])

  useEffect(() => {
    searchHandler(searchValue)
  }, [searchValue])

  const searchMembersRenderCondition = isAuthor || !isExternal

  return (
    <BTAppsWrapper>
      <BTAppsWrapper>
        <BTAppsLabel>Approve</BTAppsLabel>
        <BTAppsWrapper>
          <BTApproveTextTopLabel>Final approval is assigned to:</BTApproveTextTopLabel>

          <BTScrollWrapper>
            {loading ? (
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <PuffLoader color={colors.projectColors.main} size={50} />
              </Box>
            ) : (
              <ScrollContainer
                className="scroll"
                hideScrollbars={false}
                horizontal={false}
                vertical
              >
                {assignees.map((assignee) => {
                  if (assignee) {
                    return (
                      <BTAssigneeItem key={uuidv4()}>
                        <BTAssignedUserBlock
                          assignee={assignee}
                          id={assignee.id}
                          approveStatus={assignee.status}
                          removeHandler={setApproverHandler}
                        />
                      </BTAssigneeItem>
                    )
                  }
                })}
              </ScrollContainer>
            )}
          </BTScrollWrapper>
          {searchMembersRenderCondition && (
            <>
              <BTDividerWrapper>
                <BTAppsDivider />
              </BTDividerWrapper>
              <BTApproveTextBottomLabel>Add assignee:</BTApproveTextBottomLabel>
              <BTSeachInputWrapper>
                <BTSearch placeholder="Search" changeEvent={setSearchValue} debounceDelay={800} />
              </BTSeachInputWrapper>
              <BTMembersListWrapper>
                <ScrollContainer
                  className="scroll"
                  hideScrollbars={false}
                  horizontal={false}
                  vertical
                >
                  {searchResultsRendering()}
                </ScrollContainer>
              </BTMembersListWrapper>
            </>
          )}
        </BTAppsWrapper>
      </BTAppsWrapper>
      <BTApproveButton
        variant="contained"
        color="primary"
        onClick={approveHandler}
        disabled={disabledApprove || approveLoading}
        className={approved ? 'approved' : ''}
      >
        {approved ? 'Approved' : 'Approve'}
      </BTApproveButton>
    </BTAppsWrapper>
  )
}
