/* eslint-disable max-len */
export const SaveButtonIcon = () => {
  return (
    <svg width="21" height="21" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.125 0.375H2.625C1.37625 0.375 0.375 1.3875 0.375 2.625V18.375C0.375 19.6125 1.37625 20.625 2.625 20.625H18.375C19.6125 20.625 20.625 19.6125 20.625 18.375V4.875L16.125 0.375ZM10.5 18.375C8.6325 18.375 7.125 16.8675 7.125 15C7.125 13.1325 8.6325 11.625 10.5 11.625C12.3675 11.625 13.875 13.1325 13.875 15C13.875 16.8675 12.3675 18.375 10.5 18.375ZM2.625 7.125H13.875V2.625H2.625V7.125Z"
      />
    </svg>
  )
}
