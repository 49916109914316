import styled from 'styled-components'
import { colors } from '../../../theme/Colors'

export const ButtonWrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  width: 34px;
  height: 34px;
  background: ${colors.white};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 2px;
`
