/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import { useAppSelector } from 'redux/store'
import { getFileExtention } from '../../../helpers/file/getFileExtention'
import { fileUploadInGlobalAsset } from '../../../helpers/file/fileUpload'
import { Files } from '../../../components/shared/bt-files-drop/bt-files-drop.types'
import { PAGINATION } from '../../../helpers/constants'
import { useGetCurrentOrganization } from '../../useGetCurrentOrganization'
import { GlobalAssetFilter, getOrganizationsIds } from '../../../components/shared/bt-assets/utils'
import {
  SortOrder,
  AssetTypes,
  AssetUploadInput,
  GetAllAssetsQuery,
  useSaveAssetMutation,
  GetAllAssetsDocument,
  useUploadAssetsMutation,
} from '../../../generated/graphql'
import { IPrepareForUploadObj, chooseAllowedFileTypes } from './useGlobalAssetsUpload.types'

const useGlobalAssetsUpload = () => {
  const { currentOrganizationId } = useAppSelector((state) => state.login)
  const { organizations } = useAppSelector((state) => state.login)
  const organizationsIds = getOrganizationsIds(organizations)
  const currentOrganization = useGetCurrentOrganization()
  const [uploadAssetsMutation] = useUploadAssetsMutation()
  const [saveAsset] = useSaveAssetMutation()

  return async (
    uploadedFiles: Files,
    loader: (arg: boolean) => void,
    modalCloser: (arg: null) => void,
    filesState: (arg: File[]) => void,
    filter: number,
    search: string
  ): Promise<void> => {
    loader(true)

    const filesToUpload: AssetUploadInput[] = []

    try {
      if (uploadedFiles) {
        uploadedFiles?.forEach((file) => {
          filesToUpload.push({
            name: file.name,
            projectType: AssetTypes.GlobalAsset,
          })
        })
      }

      const signedUrlsResponse = await uploadAssetsMutation({
        variables: {
          input: filesToUpload,
        },
      })

      const signedUrls = signedUrlsResponse.data?.uploadAssets

      if (signedUrls) {
        const prepareForUploadArray: IPrepareForUploadObj[] = []

        uploadedFiles.forEach((file, index) => {
          prepareForUploadArray.push({
            file,
            url: signedUrls[index].url,
            path: signedUrls[index].path,
            name: signedUrls[index].name,
          })
        })

        const resultsArray: number[] = []

        prepareForUploadArray.forEach(async (item) => {
          const result = await fileUploadInGlobalAsset(item.file, item.url)
          const fileType = getFileExtention(item.file.name)

          if (fileType) {
            if (result) {
              resultsArray.push(result)

              if (result === 200) {
                await saveAsset({
                  variables: {
                    input: {
                      name: item.name,
                      type: chooseAllowedFileTypes(fileType),
                      path: item.path,
                      organizationId: currentOrganizationId,
                    },
                  },
                  errorPolicy: 'ignore',
                  update: (cache, { data }) => {
                    const readedQuery = cache.readQuery({
                      query: GetAllAssetsDocument,
                      variables: {
                        input: {
                          organizations: organizationsIds,
                          type: GlobalAssetFilter[filter].value,
                          searchValue: search,
                        },
                        page: 1,
                        sortOrder: SortOrder.Desc,
                      },
                    }) as GetAllAssetsQuery

                    const { id } = data!.saveAsset.file

                    /** New asset */
                    const assetToAdd = {
                      activity: null,
                      activityId: null,
                      createdAt: new Date(),
                      file: {
                        ...data!.saveAsset.file,
                      },
                      fileId: id,
                      id: data!.saveAsset.id,
                      organization: {
                        __typename: 'Organization',
                        name: currentOrganization!.name,
                      },
                      organizationId: currentOrganizationId,
                      project: null,
                      projectId: null,
                      updatedAt: new Date(),
                      __typename: 'GlobalAsset',
                    }

                    /** Pagination */
                    const prevAssets = [...readedQuery.getAllAssets.assets]
                    const totalLength = prevAssets.length + prepareForUploadArray.length
                    if (totalLength > 30) {
                      prevAssets.splice(29, totalLength - 30)
                    }

                    cache.writeQuery({
                      query: GetAllAssetsDocument,
                      variables: {
                        input: {
                          organizations: organizationsIds,
                          type: GlobalAssetFilter[filter].value,
                          searchValue: search,
                        },
                        page: 1,
                        sortOrder: SortOrder.Desc,
                      },
                      data: {
                        getAllAssets: {
                          ...readedQuery.getAllAssets,
                          assets: [assetToAdd, ...prevAssets],
                          paginationData: {
                            ...readedQuery.getAllAssets.paginationData,
                            totalAmount: totalLength,
                            totalPages: Math.ceil(totalLength / PAGINATION.ASSETS_PER_PAGE),
                          },
                        },
                      },
                    })
                  },
                })
              }
            }

            /** Switching off the loader after the last upload */
            if (resultsArray.length === prepareForUploadArray.length) {
              loader(false)
              modalCloser(null)
              filesState([])
            }
          }
        })
      }
    } catch (error) {
      loader(false)
    }
  }
}

export { useGlobalAssetsUpload }
