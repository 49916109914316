import styled from '@emotion/styled'
import { colors } from 'theme/Colors'

export const BTTaskMembersStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;

  .search {
    width: 100%;
  }

  .members {
    flex-direction: row;
    display: flex;
    gap: 10px;

    .remove-button {
      padding: 0;
      background-color: transparent;
    }

    .rest-members-amount {
      background-color: ${colors.navyGrey[300]};
      border-radius: 50px;
      min-width: auto;
      height: 35px;
      width: 35px;
      color: ${colors.white};
      font-size: 18px;
      line-height: 27px;
    }
  }

  .MuiOutlinedInput-root {
    padding: 0;
    font-size: 12px;
    line-height: 12px;
    color: ${colors.navyGrey[500]};
    border-radius: 5px;
    padding-right: 10px !important;

    .MuiAutocomplete-endAdornment {
      position: static;
    }

    svg {
      font-size: 10px;
    }

    input {
      padding: 5px !important;
    }
  }
`
