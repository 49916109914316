import { FC, useState } from 'react'
import { BTCard } from '../bt-card'
import type { IBTTaskCardProps } from './bt-task-card.props'
// eslint-disable-next-line max-len
import { BTDueDateLabel } from '../bt-card/components/bt-due-date'
import { BTTaskDetails } from '../bt-task-details'

export const BTTaskCard: FC<IBTTaskCardProps> = ({
  id,
  assignees,
  checklist,
  createdDate,
  description,
  dueDate,
  isNew,
  labelData,
  title,
  bgColor,
  taskBelongsToId,
}) => {
  const [isOpened, setIsOpened] = useState<boolean>(false)

  const handleClose = () => setIsOpened(false)
  const handleOpen = () => setIsOpened(true)

  return (
    <>
      <BTCard
        id={id}
        assignees={assignees}
        labelData={labelData}
        title={title}
        description={description}
        isNew={isNew}
        createdDate={createdDate}
        clickHandler={handleOpen}
        bgColor={bgColor}
      >
        {dueDate && <BTDueDateLabel dueDate={dueDate} />}
      </BTCard>
      <BTTaskDetails
        id={id}
        taskBelongsToId={taskBelongsToId}
        assigneesList={assignees}
        dueDate={dueDate}
        isOpened={isOpened}
        description={description}
        checklistItems={checklist}
        labelData={labelData}
        taskName={title}
        handleClose={handleClose}
      />
    </>
  )
}
