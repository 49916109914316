/* eslint-disable max-len */
import Box from '@mui/material/Box'
import React, { useState } from 'react'
import { CssBaseline } from '@mui/material'
import { useSearchParams } from 'react-router-dom'
import { BTInbox } from '../bt-inbox'
import { Container, Drawer } from './bt-sidebar.presets'
import { BTSidebarFooter } from './components/sidebar-footer'
import { BTDrawerHeaderBlock } from './components/drawer-header'
import { BTMainButtonsList } from './components/main-button-list'

export const BTSidebar: React.FC = () => {
  const [open, setOpen] = useState(false)
  const [queryParams] = useSearchParams()

  const inboxModalOpenCondition = !!queryParams.get('inbox-modal-open')

  const handleEnter = () => {
    setOpen(true)
  }
  const handleLeave = () => {
    setOpen(false)
  }

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Drawer
          variant="permanent"
          open={open}
          onMouseEnter={handleEnter}
          onMouseLeave={handleLeave}
        >
          <Container>
            <BTDrawerHeaderBlock />
            <BTMainButtonsList open={open} />
          </Container>
          <Container>
            <BTSidebarFooter open={open} />
          </Container>
        </Drawer>
      </Box>
      {inboxModalOpenCondition && <BTInbox />}
    </>
  )
}
