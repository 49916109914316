import styled from '@emotion/styled'
import { colors } from 'theme/Colors'

export const BTArtworkHeaderWrapper = styled.div`
  border-bottom: 1px solid ${colors.navyGrey[400]};
  display: flex;
  align-items: center;

  .no-artwork-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    margin-left: 30px;
  }

  .title-block {
    margin-bottom: 0 !important;
    padding-left: 30px;
  }

  .row {
    align-items: center;
    height: auto;
    justify-content: space-between;

    .rename-title-block {
      align-self: auto;
    }

    form {
      margin-bottom: 0;
      margin-left: 30px;
    }
  }

  .file-information {
    margin-bottom: 0 !important;
    width: 300px;
    padding: 8px 10px;

    h6 {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
  }

  .file-block {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    margin-right: 30px;

    .upload-icon {
      color: ${colors.projectColors.main};
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: ${colors.blue[600]};
      }
    }
  }

  .upload-button {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    text-transform: capitalize;
  }
`
