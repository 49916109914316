/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/require-default-props */
import { FC } from 'react'
import { v4 as uuidv4 } from 'uuid'
import ScrollContainer from 'react-indiana-drag-scroll'
import { formatBytes } from '../../../../helpers/file'
import FilesTableWrapper from './bt-files-table.presets'
import { BTFilesTableProps } from './bt-files-table.props'
import { BTFileItemInformation } from './bt-file-item-information'
import { getFileExtention, getFileName } from '../../../../helpers/file/'

export const BTFilesTable: FC<BTFilesTableProps> = ({ files, buttonItems }) => {
  return (
    <FilesTableWrapper>
      <ScrollContainer className="files-list" hideScrollbars={false} horizontal={false} vertical>
        {files &&
          files.map((file, index) => {
            if (file?.size) {
              const { size } = file
              const normalizeSize = formatBytes(size)
              const fileName = getFileName(file?.name)
              const fileType = getFileExtention(file?.name)
              const id = uuidv4()

              return (
                <BTFileItemInformation
                  name={fileName}
                  size={normalizeSize}
                  fileType={fileType}
                  buttonItems={buttonItems}
                  key={id}
                  index={index.toString()}
                />
              )
            }
          })}
      </ScrollContainer>
    </FilesTableWrapper>
  )
}

export const BTFilesTableWithoutScroll: FC<BTFilesTableProps> = ({ files, buttonItems }) => {
  return (
    <FilesTableWrapper>
      {files &&
        files.map((file, index) => {
          if (file?.size) {
            const { size } = file
            const normalizeSize = formatBytes(size)
            const fileName = getFileName(file?.name)
            const fileType = getFileExtention(file?.name)
            const id = uuidv4()

            return (
              <BTFileItemInformation
                name={fileName}
                size={normalizeSize}
                fileType={fileType}
                buttonItems={buttonItems}
                key={id}
                index={index.toString()}
              />
            )
          }
        })}
    </FilesTableWrapper>
  )
}
