import { FC } from 'react'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { Typography } from '@mui/material'
import { DateTime } from 'luxon'
import { DueDateWrapper } from './bt-due-date.presets'
import type { IDueDateProps } from './bt-due-date.props'

export const BTDueDateLabel: FC<IDueDateProps> = ({ dueDate }: IDueDateProps) => {
  const formattedDueDate = DateTime.fromJSDate(new Date(dueDate)).toFormat('HH:mm, EEEE')
  return (
    <DueDateWrapper>
      <AccessTimeIcon />
      <Typography component="span">{formattedDueDate}</Typography>
    </DueDateWrapper>
  )
}
