/* eslint-disable prettier/prettier */
/* eslint-disable no-empty-pattern */
import React, { FC, useEffect } from 'react'
import { useDeliverablesQuestionQuery, useGetAnswersQuery } from 'generated/graphql'
import { useNavLocation } from 'hooks/shared/useNavLocation'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { setupBriefQuestion, addQuestionWithAnswer } from 'redux/BriefCreationSlice'
import { LocationService } from 'services/LocationService'
import { getComponentByType, mapQuestionsAndAnswers } from './bt-page-with-questions.helpers'
import type {
  BTPageWithQuestionsHandlerExtended,
  BTPageWithQuestionsProps,
} from './bt-page-with-questions.props'
import {
  BTQuestionsPageWrapper,
  BTQuestionsPageDivider,
  BTQuestionsPageHeading,
  BTQuestionsScrollContainer,
} from './bt-page-with-questions.presets'
import { setupDeliverables } from 'redux/SidebarAppsSlice'
import { v4 } from 'uuid'
import { useIsAuthor } from 'helpers/CheckUserRoleAccess'
import { BTPointerEventsSwitcher } from 'components/shared/bt-point-event-switcher'

export const BTPageWithQuestions: FC<BTPageWithQuestionsProps> = ({ deliverable, briefId }) => {
  const { currentDeliverable } = useAppSelector((state) => state.sidebarApps)
  const { deliverableWithQuestionsAndAnswers } = useAppSelector((state) => state.briefCreation)
  const dispatch = useAppDispatch()
  const [{ pathname }, navigation] = useNavLocation()
  const { data: questionsData, loading: questionsLoading } = useDeliverablesQuestionQuery({
    variables: {
      deliverables: deliverable?.relationId || deliverable?.id,
    },
  })
  const {
    data: answersData,
    loading: answersLoading,
    refetch,
  } = useGetAnswersQuery({
    variables: { briefId },
  })
  const isAuthor = useIsAuthor(briefId)

  useEffect(() => {
    return () => {
      dispatch(setupDeliverables([]))
    }
  }, [])

  useEffect(() => {
    refetch({ briefId })
  }, [pathname, currentDeliverable])

  if (questionsLoading || answersLoading) {
    return null
  }
  if (!questionsData) {
    // navigation(LocationService.goBack(pathname))
    return null
  }
  const setupQuestionHandler: BTPageWithQuestionsHandlerExtended = (answer) => (options) => {
    const { questionId, content, type } = options
    dispatch(setupBriefQuestion(options))
    if (content) {
      const transformedAnswer = Object.keys(answer).length
        ? { ...answer, content }
        : { content, type, id: v4() }
      dispatch(addQuestionWithAnswer({ id: questionId, answer: transformedAnswer }))
    }
  }
  const transformedQuestionsData = mapQuestionsAndAnswers(
    deliverable.relationId || deliverable.id,
    questionsData,
    answersData,
    deliverableWithQuestionsAndAnswers
  )
  const components = transformedQuestionsData.map(({ question, comment }) => {
    const Component = getComponentByType(question, setupQuestionHandler, comment)
    return { Component, id: question.id }
  })

  return (
    <BTQuestionsScrollContainer hideScrollbars vertical ignoreElements="*">
      <BTPointerEventsSwitcher isAuthor={isAuthor}>
        <BTQuestionsPageWrapper>
          <BTQuestionsPageHeading>{deliverable.name}</BTQuestionsPageHeading>
          {components.map(({ Component, id }, index) => (
            <React.Fragment key={id}>
              {Component}
              {index !== components.length - 1 && <BTQuestionsPageDivider />}
            </React.Fragment>
          ))}
        </BTQuestionsPageWrapper>
      </BTPointerEventsSwitcher>
    </BTQuestionsScrollContainer>
  )
}
