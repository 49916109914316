import { FC } from 'react'
import { BTHeaderSectionItemWrapper } from './bt-header-section-item.presets'
import type { IBTHeaderSectionProps } from './bt-header-section-item.props'

export const BTHeaderSectionItem: FC<IBTHeaderSectionProps> = ({ sectionLeftAdorment, title }) => {
  return (
    <BTHeaderSectionItemWrapper>
      {sectionLeftAdorment}
      <span>{title}</span>
    </BTHeaderSectionItemWrapper>
  )
}
