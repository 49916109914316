/* eslint-disable no-shadow */
import { v4 } from 'uuid'
import { useEffect, useMemo, FC } from 'react'
import { useGetAnswersQuery } from 'generated/graphql'
import { BTLoader } from 'components/shared/bt-loader'
import ScrollContainer from 'react-indiana-drag-scroll'
import { DeliverableWithSelection } from 'types/shared'
import { BTBriefRightPanel } from '../bt-brief-right-panel'
import { useNavLocation } from 'hooks/shared/useNavLocation'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { BTBriefColumn } from './components/brief-column/bt-brief-column'
import { transformDeliverablesByCategory } from './bt-brief-board.helpers'
import { setupDeliverable, setupDeliverables } from 'redux/SidebarAppsSlice'
import {
  Root,
  Heading,
  ItemWrapper,
  ColumnWrapper,
  BTActivityBoardWrapper,
} from './bt-brief-dnd.presets'

export const BTBriefBoard: FC = () => {
  const { deliverables } = useAppSelector((state) => state.deliverables)
  const { briefId } = useAppSelector((state) => state.briefCreation)
  const [{ pathname }, navigation] = useNavLocation()
  const dispatch = useAppDispatch()
  const { deliverables: appDeliverables } = useAppSelector((state) => state.sidebarApps)
  const { data, loading } = useGetAnswersQuery({
    variables: { briefId },
  })
  const transformedDeliverables = useMemo(
    () =>
      transformDeliverablesByCategory(
        deliverables as Array<DeliverableWithSelection>,
        appDeliverables
      ),
    [deliverables, appDeliverables]
  )
  useEffect(() => {
    if (data) {
      const deliverables = data.answers.mappedDeliverables
      dispatch(setupDeliverables(deliverables.map(({ id, name }) => ({ id, name }))))
      const { id, name } = deliverables[0] || {}

      dispatch(setupDeliverable({ id, name }))

      navigation(pathname.replace('brief/create/compose-template', 'brief'))
    }
  }, [data])
  useEffect(() => {
    return () => {
      dispatch(setupDeliverables([]))
    }
  }, [])
  if (loading) {
    return <BTLoader />
  }

  return (
    <BTActivityBoardWrapper>
      <Root>
        <ScrollContainer
          className="horizontal-scroll-container"
          hideScrollbars
          horizontal={false}
          vertical
        >
          {Object.keys(transformedDeliverables).map((key) => {
            return (
              <ItemWrapper key={v4()}>
                <ScrollContainer
                  horizontal={false}
                  vertical
                  hideScrollbars={false}
                  style={{
                    height: '100%',
                    overflow: 'overlay',
                  }}
                  ignoreElements="*[data-rbd-draggable-context-id]"
                >
                  <ColumnWrapper>
                    <Heading>{key}</Heading>
                    <BTBriefColumn items={transformedDeliverables[key]} isDraggingOver={false} />
                  </ColumnWrapper>
                </ScrollContainer>
              </ItemWrapper>
            )
          })}
        </ScrollContainer>
        <BTBriefRightPanel />
      </Root>
    </BTActivityBoardWrapper>
  )
}
