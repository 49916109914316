import styled from 'styled-components'
import { colors } from '../../../../../theme/Colors'

export const BTMembersList = styled.div`
  button:not(:last-child) {
    margin-bottom: 10px;
  }

  .members-list {
    max-height: 310px;

    &::-webkit-scrollbar {
      background-color: transparent;
      height: 10px;
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${colors.navyGrey[200]};
      border-radius: 10px;
    }
  }
`

export const BTSelectedMembersList = styled.div`
  margin-bottom: 20px;

  display: flex;
  align-items: center;

  .rest-members-amount {
    background-color: ${colors.navyGrey[300]};
    border-radius: 50px;
    min-width: auto;
    height: 35px;
    width: 35px;
    color: ${colors.white};
    font-size: 18px;
    line-height: 27px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
`
export const BTSelectedMembersItem = styled.div`
  margin-right: 8px;
`
export const BTNoProjectMembers = styled.div`
  margin-top: 120px;

  text-align: center;

  .text {
    margin-bottom: 20px;

    font-weight: 600;
    font-size: 12px;
    line-height: 1.5;

    color: ${colors.projectColors.main};
  }

  .invite-button {
    background: none;
    border: none;
    cursor: pointer;

    .invite-button-icon {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: rgba(0, 102, 255, 0.1);

      color: ${colors.projectColors.main};

      transform: rotate(45deg);
    }
  }
`

export const BTSeachInputWrapper = styled.div`
  input {
    height: 28px;
    padding-top: 6px;
    padding-bottom: 6px;

    ${({ theme }) => ({ ...theme.typography?.regular })};
  }
`
