/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import { useMachineContext } from 'services/StateMachines/bt-apps-send/bt-apps-send'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { MemoizedBTAppsButton } from '../../../shared'

export const BTSendPrevButton = () => {
  const [_, send] = useMachineContext()

  const handlePrev = () => {
    if (send) {
      send('prev')
    }
  }

  return (
    <MemoizedBTAppsButton
      variant="contained"
      color="primary"
      /** @desc we need to use lowercase, because uppercase cause errors with MUI */
      texttransform="initial"
      onClick={handlePrev}
      className="prev-button"
    >
      <ArrowBackIcon />
    </MemoizedBTAppsButton>
  )
}
