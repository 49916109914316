import { Popover } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { useState, FC, MouseEvent } from 'react'
import { BTActivityList } from './bt-add-activity-button.presets'
import { BTAddActivityPopup } from './components/bt-add-activity-popup'
import type { BTAddActivityButtonProps } from './bt-add-activity-button.props'

export const BTAddActivityButton: FC<BTAddActivityButtonProps> = ({ milestone }) => {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null)

  const handleClick = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const anchorId = open ? 'simple-popover' : undefined

  return (
    <>
      <AddIcon onClick={handleClick} />
      <Popover
        id={anchorId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <BTActivityList>
          <BTAddActivityPopup milestone={milestone} />
        </BTActivityList>
      </Popover>
    </>
  )
}
