/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable operator-assignment */
/* eslint-disable import/no-unresolved */
import { Deliverable } from 'types/shared'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SidebarTypes } from 'components/shared/bt-actions-sidebar/bt-actions-sidebar.types'
import { Scalars, DeliverableType, DeliverableTypesQuery } from '../generated/graphql'

type SendTab = {
  step: number
  users: any[]
  maxSteps: 2
  purpose: string
  message: string
}
type SendTabControls = 'prev' | 'next'
type SendTabUsers = any[]

interface initState {
  deliverables: Array<Deliverable>
  isExtended: boolean
  sendTab: SendTab
  type: SidebarTypes
  isOpened: boolean
  existDeliverables: Deliverable[]
  currentDeliverable: Deliverable
  approval: { isApproved: boolean; color: string }
  resetColor: boolean
  assetId?: string
}
interface IsExtended {
  isExtended: boolean
}
interface DeliverableByName {
  name: Scalars['String']
  id: Scalars['String']
}

interface ApprovalsChange {
  color: string
  isApproved: boolean
}

const initialState: initState = {
  deliverables: [],
  isExtended: false,
  /** @todo remove this after xstate was merged */
  sendTab: { step: 0, users: [], maxSteps: 2, purpose: '', message: '' },
  type: 'BRIEF',
  isOpened: false,
  existDeliverables: [],
  currentDeliverable: { id: '', name: '' },
  approval: { color: '', isApproved: false },
  resetColor: false,
  assetId: undefined,
}

const SidebarAppsSlice = createSlice({
  name: 'sidebarSlice',
  initialState,
  reducers: {
    appendDeliverable(state, { payload }: PayloadAction<DeliverableByName>) {
      state.deliverables.push({ name: payload.name, id: payload.id })
    },
    removeDeliverable(state, { payload }: PayloadAction<DeliverableByName>) {
      state.deliverables = state.deliverables.filter((deliverable) => deliverable.id !== payload.id)
    },
    toggleExtend(state, { payload }: PayloadAction<IsExtended>) {
      state.isExtended = payload.isExtended
    },
    changeStep(state, { payload }: PayloadAction<SendTabControls>) {
      if (payload === 'next') {
        state.sendTab.step = state.sendTab.step + 1
      }
      if (payload === 'prev' && state.sendTab.step >= 1) {
        state.sendTab.step = state.sendTab.step - 1
      }
    },
    adjustUsers(state, { payload }: PayloadAction<SendTabUsers>) {
      state.sendTab.users = payload
    },
    sendPurpose(state, { payload }: PayloadAction<string>) {
      state.sendTab.purpose = payload
    },
    sendMessage(state, { payload }: PayloadAction<string>) {
      state.sendTab.message = payload
    },
    setupType(state, { payload }: PayloadAction<SidebarTypes>) {
      state.type = payload
    },
    toggleOpen(state, { payload }: PayloadAction<boolean>) {
      state.isOpened = payload
    },
    setupDeliverables(state, { payload }: PayloadAction<DeliverableByName[]>) {
      state.deliverables = payload
    },
    setupDeliverable(state, { payload }: PayloadAction<DeliverableByName>) {
      state.currentDeliverable = payload
    },
    setupApprovals(state, { payload }: PayloadAction<ApprovalsChange>) {
      state.approval = payload
    },
    resetSidebarColors(state, { payload }: PayloadAction<boolean>) {
      state.resetColor = payload
    },
    setAssetId(state, { payload }: PayloadAction<string>) {
      state.assetId = payload
    },
  },
})

export default SidebarAppsSlice.reducer
export const {
  appendDeliverable,
  removeDeliverable,
  toggleExtend,
  changeStep,
  adjustUsers,
  sendPurpose,
  sendMessage,
  setupType,
  toggleOpen,
  setupDeliverables,
  setupDeliverable,
  setupApprovals,
  resetSidebarColors,
  setAssetId,
} = SidebarAppsSlice.actions
