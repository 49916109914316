import List from '@mui/material/List'
import { styled as MUIStyled } from '@mui/material'
import type { SideListProps } from './sub-buttons-container.props'
import { colors } from '../../../../theme/Colors'

const SideList = MUIStyled(List)<SideListProps>`
    ${({ theme, open }) => ({
      '& .MuiListItem-root': {
        justifyContent: 'center',
        padding: '12px 0',
      },
      '& .MuiListItemIcon-root': {
        justifyContent: 'center',
        minWidth: '32px',
        minHeight: '32px',
        alignItems: 'center',
        margin: open && '0 0 0 20px',
      },
      '& .MuiListItemText-root': {
        //@ts-ignore
        ...theme.typography.regular,
        color: '#FFFFFF',
        padding: '4px 0 4px 10px',
      },
      '& .MuiListItemButton-root': {
        padding: '12px 16px',
        paddingLeft: open && 0,
        justifyContent: 'center',
        margin: !open && '0 20px',
      },
      '& div .MuiListItemButton-root': {
        justifyContent: 'flex-start',
        padding: '0 0 0 28px',
      },
      '& .active .MuiListItemIcon-root': {
        background: colors.projectColors.activeTab,
        borderRadius: open ? '5px 0 0 5px' : '5px',
        fontWeight: 'bold',
      },
      '& .active .MuiListItemText-root': {
        background: colors.projectColors.activeTab,
        borderRadius: '4px',
        padding: '5px 8px',
        '& span': {
          fontWeight: 'bold',
        },
      },
    })}
`
export { SideList }
