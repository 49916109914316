/* eslint-disable no-unused-expressions */
/* eslint-disable max-len */
import { useParams } from 'react-router-dom'
import { useCreateBriefNoMilestones } from '../activity/useCreateBriefNoMilestones'
import { fileUpload } from '../../helpers/file/fileUpload'
import { Files } from '../../components/shared/bt-files-drop/bt-files-drop.types'
import { toastNotifications } from '../../helpers/toastNotifications'
import {
  useUpdateBriefUploadedDocumentMutation,
  useUpdateActivityUploadedDocumentMutation,
  GetActivityByIdQuery,
  GetActivityByIdDocument,
} from '../../generated/graphql'
import { useAppSelector } from 'redux/store'

export const useFileUpload = () => {
  const { activityId } = useParams()
  const { id } = useAppSelector((state) => state.login.whoami)
  const createBrief = useCreateBriefNoMilestones()
  const [getSignedUrlResponse] = useUpdateBriefUploadedDocumentMutation()
  const [getSignedUrlResponseForActivity] = useUpdateActivityUploadedDocumentMutation()

  return async (uploadedfiles: Files | null): Promise<void> => {
    try {
      if (uploadedfiles) {
        const file = uploadedfiles[0]
        const { name: fileName } = file
        const splittedFile = fileName.split('.')
        const fileExtention = splittedFile[splittedFile.length - 1]

        if (fileExtention === 'pdf') {
          if (activityId) {
            const signedUrlResponse = await getSignedUrlResponseForActivity({
              variables: {
                input: {
                  id: activityId,
                  name: fileName,
                },
              },
              errorPolicy: 'ignore',
              update: (cache, { data }) => {
                const readedQuery = cache.readQuery({
                  query: GetActivityByIdDocument,
                  variables: {
                    input: activityId,
                  },
                }) as GetActivityByIdQuery

                cache.writeQuery({
                  query: GetActivityByIdDocument,
                  variables: {
                    input: activityId,
                  },
                  data: {
                    getActivityById: {
                      ...readedQuery,
                      uploadedDocument: {
                        ...data?.updateActivityUploadedDocument.activity.uploadedDocument,
                      },
                    },
                  },
                })
              },
            })
            const signedUrl = signedUrlResponse.data?.updateActivityUploadedDocument.url

            await fileUpload(file, signedUrl)
          } else {
            const newBrief = await createBrief('brief', id)
            const briefId = newBrief.data?.createBrief.activity.id

            if (briefId) {
              const signedUrlResponse = await getSignedUrlResponse({
                variables: {
                  input: {
                    id: briefId,
                    name: fileName,
                  },
                },
              })
              const signedUrl = signedUrlResponse.data?.updateBriefUploadedDocument.url

              await fileUpload(file, signedUrl)
            }
          }
        } else {
          toastNotifications('Only PDF files can be uploaded').warning()
        }
      }
    } catch (err) {
      const error = err as Error
      toastNotifications(error.message).error()
    }
  }
}
