/* eslint-disable max-len */
import { FC } from 'react'
import { IBTFileItemInformation } from './bt-file-item-information.props'
import { BTFileItemInformationWrapper } from './bt-file-item-information.presets'
import { BTFileIcon } from '../bt-file-icon/bt-file-icon'
import { BTMoreButton } from '../../../../shared/bt-more-button'

export const BTFileItemInformation: FC<IBTFileItemInformation> = ({
  name,
  fileType,
  size,
  buttonItems,
  index,
}) => {
  const type = fileType?.split('_')[0]

  return (
    <BTFileItemInformationWrapper className="files-item">
      <div className="file-icon-left-side">
        <span className="file-icon">
          <BTFileIcon fileType={type} />
        </span>
        <span className="file-name">{name}</span>
      </div>

      <div className="file-icon-right-side">
        <span className="file-size">{size}</span>
        <span className="file-button">
          <BTMoreButton isRounded items={buttonItems} index={index} />
        </span>
      </div>
    </BTFileItemInformationWrapper>
  )
}
