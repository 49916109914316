/* eslint-disable prettier/prettier */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-underscore-dangle */
import { ActivitySummaryFragment } from 'generated/graphql'
import { getNavigationLink } from 'helpers/navigation'
import { FC, SyntheticEvent } from 'react'
import { Draggable, DraggableProvided } from 'react-beautiful-dnd'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 } from 'uuid'
import { BTActivityCard } from '../../../activity-card'
import { BTAppendActivity } from '../append-activity'
import type { ColumnProps } from './bt-activity-column.props'

export const BTActivityColumn: FC<ColumnProps> = ({
  activities = [],
  isDraggingOver,
  milestone,
}) => {
  const navigate = useNavigate()
  const { id: projectId } = useParams()

  const handleCreate = (activity: ActivitySummaryFragment) => (event: SyntheticEvent) => {
    event.stopPropagation()

    const { composedDocument, uploadedDocument, type, id } = activity
    const navigatePath = getNavigationLink(
      composedDocument!,
      uploadedDocument!,
      type,
      id,
      projectId!,
      true
    )

    navigate(navigatePath)
  }

  const formattedActivities = activities.filter((activity) => !activity.archivedAt)
  return (
    <>
      {formattedActivities.length > 0
        ? formattedActivities.map((activity, index) => {
            return (
              <Draggable draggableId={activity.id || v4()} key={activity.id || v4()} index={index}>
                {(provided: DraggableProvided, snapshot) => (
                  <BTActivityCard
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    isDragging={snapshot.isDragging && isDraggingOver}
                    activity={activity}
                    onClick={handleCreate(activity)}
                  />
                )}
              </Draggable>
            )
          })
        : null}
      {!isDraggingOver && <BTAppendActivity milestone={milestone} />}
    </>
  )
}
