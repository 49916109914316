import { useState } from 'react'

export const useCreateModalWindowHandlers = (
  value?: boolean
): [boolean, () => void, () => void] => {
  const [isOpened, setIsOpened] = useState(value ?? false)

  const openModalWindow = () => setIsOpened(true)
  const closeModalWindow = () => setIsOpened(false)

  return [isOpened, openModalWindow, closeModalWindow]
}
