import { IconButton } from '@mui/material'
import { FC } from 'react'
import { v4 as uuidv4 } from 'uuid'
import type { IIconButtonsProps, IIconButtonData } from './bt-icon-buttons.props'
import { IconButtonsWrapper } from './bt-icon-buttons.presets'

export const BTIconButtons: FC<IIconButtonsProps> = ({ buttonsProps }) => {
  return (
    <IconButtonsWrapper>
      {buttonsProps.map((buttonProps: IIconButtonData) => (
        <IconButton
          key={uuidv4()}
          sx={{ color: buttonProps.color }}
          onClick={buttonProps.actionCallback}
          size="small"
        >
          {buttonProps.iconComponent}
        </IconButton>
      ))}
    </IconButtonsWrapper>
  )
}
