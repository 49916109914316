/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable prettier/prettier */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/require-default-props */
import { useMemo, FC, useEffect, useState } from 'react'
import { BTBriefDeliverables } from 'components/SidebarApps/bt-brief-deliverables'
import { BTBriefTasks } from 'components/SidebarApps/bt-brief-tasks'
import { BTActivityFeedback } from 'components/SidebarApps/bt-activity-feedback'
import { BTBriefAssets } from 'components/SidebarApps/bt-brief-assets/bt-brief-assets'
import { BTBriefHistory } from 'components/SidebarApps/bt-brief-history'
import { BTApprove } from 'components/SidebarApps/bt-approve/bt-approve'
import { BTActivitySend } from 'components/SidebarApps/bt-activity-send'
import { BTActionsSidebar } from 'components/shared/bt-actions-sidebar'
import { BTPageWithQuestions } from 'components/Brief'
import { BTAssets } from 'components/shared/bt-assets/bt-assets'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { useNavLocation } from 'hooks/shared/useNavLocation'
import {
  useCreateAnswerMutation,
  useGetDeliverablesQuery,
  useDeleteBriefMutation,
} from 'generated/graphql'
import { getCorrectQuestionTypeToDispatch } from 'components/Brief/bt-page-with-questions/bt-page-with-questions.helpers'
import { BTLoader } from 'components/shared/bt-loader'
import { setupDeliverable, setupDeliverables } from 'redux/SidebarAppsSlice'
import { BTDeleteButton } from 'components/shared/bt-delete-button'
import { useParams } from 'react-router-dom'
import {
  Spellcheck,
  Send,
  FormatListBulleted,
  QuestionAnswer,
  Restore,
  Collections,
  Folder,
} from '@mui/icons-material'
import { SidebarProp } from 'components/shared/bt-actions-sidebar/bt-actions-sidebar.types'
import { clearQuestionsAndAnswers } from 'redux/BriefCreationSlice'
import { BTConfirmationModalWindow } from 'components/shared/bt-confirmation-modal'
import { BTModalWindow } from 'components/shared/bt-modal-window'
import { DELETE_BRIEF_MODAL_WINDOW_TEXT } from 'components/bt-artwork/components/bt-artwork-header/bt-artwork-header.constants'
import {
  ComposePageWrapper,
  ComposePageWrapperSub,
  ComposeButtonBlockWrapper,
  ComposePageButtonWrapper,
} from './brief-compose-page.presets'
import { BTSaveButton } from '../../components/shared/bt-save-button/bt-save-button'
import type { QuestionObjectType } from './brief-compose-page.types'
import { LocationService } from '../../services/LocationService'
import { BriefComposePageProps } from './brief-compose-page.props'
import { CheckUserRoleAccess } from 'helpers/CheckUserRoleAccess'

export const BriefComposePage: FC<BriefComposePageProps> = ({ dataFromProps }) => {
  const { isOpened, deliverables, currentDeliverable } = useAppSelector(
    (state) => state.sidebarApps
  )
  const dispatch = useAppDispatch()
  const { briefId, questions } = useAppSelector((state) => state.briefCreation)
  const [isOpen, setOpen] = useState<boolean>(false)
  const { sizes } = useAppSelector((state) => state.header)
  const [{ pathname }, navigation] = useNavLocation()
  const { id } = useParams()
  const [createAnswer] = useCreateAnswerMutation()
  const { data, loading, refetch } = useGetDeliverablesQuery({
    variables: { briefId },
  })
  const [deleteBrief] = useDeleteBriefMutation()
  const dataChecked = dataFromProps || data

  const top: SidebarProp = useMemo(
    () => [
      {
        Logo: Folder,
        application: BTBriefDeliverables,
      },

      { Logo: QuestionAnswer, application: BTActivityFeedback },
      /**
       * @desc commented by following ticket:
       * @link https://brieft-app.atlassian.net/browse/BRIEFT-499
       */
      // {
      //   Logo: Collections,
      //   application: BTBriefAssets,
      //   extendedApp: BTAssets,
      // },
      // { Logo: Restore, application: BTBriefHistory },
    ],
    []
  )
  const bottom: SidebarProp = useMemo(
    () => [
      { Logo: Spellcheck, application: BTApprove, name: 'approves' },
      { Logo: Send, application: BTActivitySend },
    ],
    []
  )

  useEffect(() => {
    return () => {
      dispatch(setupDeliverable({ id: '', name: '' }))
      dispatch(setupDeliverables([]))
    }
  }, [])

  useEffect(() => {
    ;(async () => {
      if (!deliverables.length || !currentDeliverable) {
        if (!dataChecked) {
          navigation(LocationService.goBack(pathname))
          return null
        }

        const fetchedDeliverables = (await refetch()).data.getDeliverables
        if (fetchedDeliverables.length) {
          dispatch(setupDeliverables(fetchedDeliverables))
          dispatch(setupDeliverable(fetchedDeliverables[0]))
        }
      }
    })()
  }, [currentDeliverable])

  if (loading) {
    return <BTLoader />
  }

  const deleteBriefQuestionsHandler = async () => {
    dispatch(clearQuestionsAndAnswers())

    if (!deliverables) {
      return
    }

    try {
      await deleteBrief({
        variables: { id: briefId },
        update(cache) {
          const id = cache.identify({
            id: briefId,
            __typename: 'Brief',
          })
          cache.evict({ id })
          cache.gc()
        },
      })
      navigation(`/projects/${id}/brief/create`)
    } catch (e) {
      const error = e as Error
      console.log(error.message)
    }
  }
  const saveBriefQuestionsHandler = async () => {
    dispatch(clearQuestionsAndAnswers())

    if (!deliverables) {
      return
    }

    const questionsObject = questions.reduce(
      (acc, { content, questionId, type, deliverableId }) => {
        const data = getCorrectQuestionTypeToDispatch(type, content)
        const isDataExist = !!Object.values(data).filter(Boolean).length
        if (!isDataExist) {
          return acc
        }
        const foundItem = acc.find((item) => item.deliverableId === deliverableId)
        if (foundItem) {
          foundItem.responses.push({ ...data, type, questionId })
          return acc
        }
        acc.push({
          deliverableId,
          responses: [{ ...data, type, questionId }],
        })
        return acc
      },
      [] as Array<QuestionObjectType>
    )

    if (briefId) {
      questionsObject.map(async (questionObject) => {
        await createAnswer({
          variables: {
            input: {
              briefId,
              ...questionObject,
            },
          },
        })
      })
    }
  }
  const closeModalHandler = () => setOpen(false)
  const openModalHandler = () => setOpen(true)
  return (
    <>
      <ComposePageWrapper marginTop={sizes.height}>
        <ComposePageWrapperSub>
          <BTPageWithQuestions deliverable={currentDeliverable} briefId={briefId} />
        </ComposePageWrapperSub>
        <ComposeButtonBlockWrapper>
          <CheckUserRoleAccess activityId={briefId}>
            <div>
              <ComposePageButtonWrapper isOpened={isOpened}>
                <BTSaveButton handler={saveBriefQuestionsHandler} />
              </ComposePageButtonWrapper>
              <ComposePageButtonWrapper isOpened={isOpened}>
                <BTDeleteButton handler={openModalHandler} />
              </ComposePageButtonWrapper>
            </div>
          </CheckUserRoleAccess>
        </ComposeButtonBlockWrapper>
      </ComposePageWrapper>

      <BTActionsSidebar type="BRIEF" top={top} bottom={bottom} />

      <BTModalWindow isOpened={isOpen} handleClose={closeModalHandler}>
        <BTConfirmationModalWindow
          acceptAction={deleteBriefQuestionsHandler}
          acceptButtonText="delete"
          description={DELETE_BRIEF_MODAL_WINDOW_TEXT}
          declineAction={closeModalHandler}
        />
      </BTModalWindow>
    </>
  )
}
