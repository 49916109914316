import styled from '@emotion/styled'
import { colors } from '../../../../../theme/Colors'

export const MembersListModalWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 60px 80px;
  background-color: ${colors.white};
  border-radius: 5px;

  .close-button {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .scroll-wrapper {
    height: 250px;

    &::-webkit-scrollbar {
      background-color: transparent;
      height: 10px;
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${colors.navyGrey[200]};
      border-radius: 10px;
    }
  }

  .transition-modal-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
  }

  .main-modal-block {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px 0;
    width: 350px;

    .members-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px 0;
    }
  }
`
