import { FC } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Avatar, AvatarGroup } from '@mui/material'
import type { IAssigneesProps } from './bt-assignee.props'
import { CheckUserRoleAccess } from 'helpers/CheckUserRoleAccess'
import { AddAssignee, AssigneeWrapper } from './bt-assignee.presets'

export const BTAssignee: FC<IAssigneesProps> = ({
  assignees,
  avatarSX,
  avatarGroupSX,
  isStacked,
  actionButton,
  assigneeModalHandler,
}: IAssigneesProps) => {
  const avatars = assignees.map(({ imageUrl, name, isOwner }) => {
    return (
      <Avatar
        key={uuidv4()}
        alt={name}
        src={imageUrl}
        sx={avatarSX}
        className={isOwner ? 'owner' : ''}
      />
    )
  })

  return (
    <AssigneeWrapper isStacked={isStacked} sx={avatarSX}>
      <AvatarGroup sx={avatarGroupSX} max={10} spacing="medium">
        {avatars}
        {actionButton && actionButton}
      </AvatarGroup>
      <AvatarGroup>
        {assignees.length && assigneeModalHandler && (
          <CheckUserRoleAccess>
            <Avatar key={uuidv4()} alt="+" sx={avatarSX} onClick={assigneeModalHandler}>
              <AddAssignee />
            </Avatar>
          </CheckUserRoleAccess>
        )}
      </AvatarGroup>
    </AssigneeWrapper>
  )
}

BTAssignee.defaultProps = {
  avatarSX: {},
  avatarGroupSX: {},
  isStacked: false,
}
