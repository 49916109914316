import { Dispatch, MouseEvent, RefObject, SetStateAction, useEffect } from 'react'
import { FeedbackMessage } from '../../../../generated/graphql'
import { IPosition } from './components/bt-point/bt-point.props'

export const isPdfFile = (type?: string): boolean => {
  if (type) {
    const isPdf = type.includes('pdf')
    return isPdf
  }

  return false
}

export const isNear = (
  currentX: number,
  currentY: number,
  points: FeedbackMessage[],
  distance: number
) => {
  let flag = false

  points.forEach((point) => {
    if (point?.position) {
      const { x, y } = point.position

      if (Math.abs(x - currentX) < distance && Math.abs(y - currentY) < distance) {
        flag = true
      }
    }
  })

  return flag
}

export const addPoint = (
  ref: RefObject<HTMLDivElement>,
  event: MouseEvent<Element | MouseEvent>,
  setPoint: Dispatch<SetStateAction<IPosition | undefined>>,
  page: number,
  points?: FeedbackMessage[]
) => {
  if (ref.current) {
    const rect = ref.current.getBoundingClientRect()

    const x = Math.abs(event.clientX - rect.left - 10)
    const y = Math.abs(event.clientY - rect.top - 10)

    if (points && !isNear(x, y, points, 30)) {
      setPoint({ page, x, y })
    } else if (points === undefined || points.length === 0) {
      setPoint({ page, x, y })
    }
  }
}

export const setRatio = (size: number, ratio: number) => {
  return size - (size / 100) * ratio
}
