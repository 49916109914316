/* eslint-disable max-len */
import { FC, useState } from 'react'
import { Button, Typography, TextField, Alert, AlertColor } from '@mui/material'
import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js'
import { useFormik } from 'formik'
import { cognitoUserPool } from '../../../../helpers/cognito'
import { CHANGE_PASSWORD_VALIDATION_SCHEMA } from '../../../../helpers/constants'
import { BTModalWindow } from '../../../shared/bt-modal-window'
import { BTChangePasswordModalWrapper } from './bt-change-password-modal.presets'
import type {
  IBTChangePasswordModalProps,
  ChangeFormValues,
  ISubmitMessage,
} from './bt-change-password-modal.props'

export const BTChangePassowrdModal: FC<IBTChangePasswordModalProps> = ({
  isOpened,
  handleClose,
  email,
}) => {
  const [toast, setToast] = useState<ISubmitMessage>()

  const showToast = (message: string, type: AlertColor, timeout: number) => {
    setToast({ type, message })

    setTimeout(() => {
      setToast(undefined)
    }, timeout)
  }

  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: cognitoUserPool,
  })

  const onSubmit = async ({ oldPassword, newPassword }: ChangeFormValues) => {
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: oldPassword,
    })

    const response = await new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        async onSuccess() {
          cognitoUser.changePassword(oldPassword, newPassword, (error, result) => {
            if (error) {
              showToast(error.message, 'error', 10000)
              reject(error)
            }
            resolve(result)
          })
        },

        onFailure(err) {
          reject(err)
          showToast(err.message, 'error', 10000)
        },
      })
    })
    if (response === 'SUCCESS') {
      showToast('Password was changed', 'success', 10000)
    }
  }

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      newPasswordConfirm: '',
    },
    validateOnChange: true,
    validationSchema: CHANGE_PASSWORD_VALIDATION_SCHEMA,
    onSubmit,
  })
  return (
    <BTModalWindow isOpened={isOpened} handleClose={handleClose}>
      <BTChangePasswordModalWrapper>
        <Typography variant="h5">Change Password</Typography>
        <div className="inputs-block">
          <div className="input-block">
            <span>Old Password</span>
            <TextField
              name="oldPassword"
              error={!!formik.errors.oldPassword}
              onChange={formik.handleChange}
              helperText={formik.errors.oldPassword}
              fullWidth
              type="password"
            />
          </div>
          <div className="input-block">
            <span>New Password</span>
            <TextField
              name="newPassword"
              error={!!formik.errors.newPassword}
              onChange={formik.handleChange}
              helperText={formik.errors.newPassword}
              fullWidth
              type="password"
            />
          </div>
          <div className="input-block">
            <span>Confirm Password</span>
            <TextField
              name="newPasswordConfirm"
              error={!!formik.errors.newPasswordConfirm}
              onChange={formik.handleChange}
              helperText={formik.errors.newPasswordConfirm}
              fullWidth
              type="password"
            />
          </div>
          {toast && (
            <Alert severity={toast.type} variant="filled">
              {toast.message}
            </Alert>
          )}
        </div>
        <Button
          disabled={!(formik.isValid && formik.dirty)}
          onClick={() => formik.handleSubmit()}
          sx={{ boxShadow: 3 }}
          fullWidth
        >
          <Typography variant="subtitle1">Save Changes</Typography>
        </Button>
      </BTChangePasswordModalWrapper>
    </BTModalWindow>
  )
}
