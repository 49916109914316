import styled from '@emotion/styled'
import { colors } from 'theme/Colors'

interface ICardWrapperProps {
  bgColor?: string | undefined
}

const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .card-container {
    display: grid;
    min-width: 10vw;
    width: 98%;
    padding: 15px;
    border-radius: 5px;
    grid-row-gap: 10px;
    ${({ bgColor }: ICardWrapperProps) => bgColor && `background-color: ${bgColor};`}

    &:hover {
      width: 100%;
      padding: 16px;
    }

    .top-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 10px;
      border-bottom: 1px solid ${colors.navyGrey[200]};
    }

    .main-block {
      display: grid;
      grid-row-gap: 5px;

      .text {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .title-block {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h4 {
          font-family: Poppins;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          margin: 0;
        }
      }

      span {
        font-size: 10px;
        line-height: 10px;
        font-weight: 400;
      }
    }

    .footer-block {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 10px;
        line-height: 10px;
      }
    }

    .title {
      max-width: 220px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`

export default CardWrapper
