import { colors } from 'theme/Colors'
import styled from 'styled-components'

const Wrapper = styled.div<{ isSelected?: boolean }>`
  padding: 10px;
  background: ${(props) => (props.isSelected ? colors.projectColors.main : '#f7f7f7')};
  cursor: pointer;
`
const Title = styled.div<{ isSelected?: boolean }>`
  ${({ theme }) => ({ ...theme.typography?.subtitle4 })}
  color: ${colors.navyGrey[800]};
  color: ${(props) => (props.isSelected ? colors.white : colors.navyGrey[800])};
`

export { Wrapper, Title }
