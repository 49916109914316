/* eslint-disable max-len */
import { FC, useState } from 'react'
import { Transition } from 'react-transition-group'
import { ButtonWrapper } from './bt-save-button.presets'
import { SaveButtonIcon } from './save-button-icon'
import { SaveButtonAnchorIcon } from './save-button-anchor-icon'
import { toastNotifications } from '../../../helpers/toastNotifications'
import { BTSaveButtonProps } from './bt-save-button.props'

export const BTSaveButton: FC<BTSaveButtonProps> = ({ handler, loading = false }) => {
  const [saved, setSaved] = useState<boolean>(false)

  const onClick = async () => {
    try {
      await handler()
      setSaved(true)
      setTimeout(setSaved, 2000, false)
    } catch (err) {
      const error = err as Error
      toastNotifications(error.message).error()
    }
  }

  return (
    <ButtonWrapper>
      <Transition in={saved} timeout={100} mountOnEnter unmountOnExit>
        {(state) => (
          <div className="saved-message-area">
            <div className={`saved-message ${state}`}>
              <div className="saved-message-text">Saved!</div>
              <div className="saved-message-anchor">
                <SaveButtonAnchorIcon />
              </div>
            </div>
          </div>
        )}
      </Transition>

      <button type="button" className="save-button-general" onClick={onClick} disabled={loading}>
        <div className="save-icon">
          <SaveButtonIcon />
        </div>
      </button>
    </ButtonWrapper>
  )
}
