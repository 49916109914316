import Snackbar from '@mui/material/Snackbar'
import styled from 'styled-components'
import type { TransitionProps } from './bt-copy-link-button.props'
import Slide from '@mui/material/Slide'

export function TransitionUp(props: TransitionProps) {
  return <Slide {...props} direction="up" />
}
export const CustomSnackbar = styled(Snackbar)`
  & .MuiPaper-root {
    ${({ theme }) => ({ ...theme.typography?.regular })};
    color: #5f6974;
    background-color: #fff;
    letter-spacing: 1px;
  }
`
