import styled from '@emotion/styled'

export const BTDashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-left: 80px;

  .current-tab-panel {
    height: 100%;

    .page-layout {
      height: 100%;
      display: flex;
      flex-direction: column;

      .list-view-block {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
      }

      .pagination-block {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
      }
    }
  }
`
