import styled from 'styled-components'
import { colors } from 'theme/Colors'

export const BTNoProjectsTutorialWrapper = styled.div`
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${({ theme }) => ({ ...theme.typography?.regular })};

  div {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
  }

  a {
    margin-left: 4px;
    color: ${colors.projectColors.main};
  }

  .curve-arrow {
    position: absolute;
    transform: translate(-400px, 75px) rotate(-30deg);
  }
`
