import { useParams } from 'react-router-dom'
import { useAppSelector } from 'redux/store'
import { feedbackSortCallback } from '../../utils/sorting/sorting'
import {
  GetAllFeedbackMessagesDocument,
  GetAllFeedbackMessagesQuery,
  GetArtworkFeedbackMessagesDocument,
  GetArtworkFeedbackMessagesQuery,
  useUpdateFeedbackMessageMutation,
} from '../../generated/graphql'

interface IFeedbackMessage {
  feedbackMessageId: string
  complete?: boolean
  pinned?: boolean
}

export function useUpdateFeedbackMessage() {
  const [updateFeedback] = useUpdateFeedbackMessageMutation()
  const { artworkId } = useParams()

  const { assetId } = useAppSelector((state) => state.sidebarApps)

  return async (
    feedbackId: string,
    messageId: string,
    content: string | undefined,
    feedbackMessage?: IFeedbackMessage,
    childMessageId?: string
  ) => {
    await updateFeedback({
      variables: {
        input: {
          messageId: childMessageId ?? messageId,
          content,
          feedbackMessage,
        },
      },
      update: (cache) => {
        if (artworkId && assetId) {
          const artwork = cache.readQuery({
            query: GetArtworkFeedbackMessagesDocument,
            variables: {
              input: { assetId },
            },
          }) as GetArtworkFeedbackMessagesQuery

          const artworkMessages = artwork === null ? [] : artwork.getArtworkFeedback

          cache.writeQuery({
            query: GetArtworkFeedbackMessagesDocument,
            variables: {
              input: { assetId },
            },
            data: {
              getArtworkFeedback: [
                ...artworkMessages.map((elem) =>
                  elem.messageId === messageId ? { ...elem, content } : elem
                ),
              ],
            },
          })
        }

        const messages = cache.readQuery({
          query: GetAllFeedbackMessagesDocument,
          variables: {
            input: { feedbackId: assetId ? undefined : feedbackId, assetId },
          },
        }) as GetAllFeedbackMessagesQuery

        if (messages && messages.getFeedbackMessagesByFeedbackId.messages.length) {
          cache.writeQuery({
            query: GetAllFeedbackMessagesDocument,
            variables: {
              input: { feedbackId: assetId ? undefined : feedbackId, assetId },
            },
            data: {
              getFeedbackMessagesByFeedbackId: {
                __typename: 'FeedbackMessageResponse',
                messages: !childMessageId
                  ? [
                      ...messages.getFeedbackMessagesByFeedbackId.messages
                        .map((elem) =>
                          elem.messageId === messageId
                            ? {
                                ...elem,
                                content: content ?? elem.content,
                                completedAt: feedbackMessage
                                  ? feedbackMessage?.complete
                                    ? new Date()
                                    : null
                                  : elem.completedAt,
                                pinned: feedbackMessage
                                  ? feedbackMessage?.pinned ?? null
                                  : elem.pinned,
                                members: feedbackMessage
                                  ? feedbackMessage.pinned
                                    ? elem.members
                                    : []
                                  : elem.members,
                              }
                            : elem
                        )
                        .sort((first, second) =>
                          feedbackSortCallback(
                            first?.pinned,
                            second?.pinned,
                            first?.createdAt,
                            second?.createdAt
                          )
                        ),
                    ]
                  : [
                      ...messages.getFeedbackMessagesByFeedbackId.messages.map((elem) =>
                        elem.messageId === messageId
                          ? {
                              ...elem,
                              replyedComments: elem.replyedComments
                                .map((reply) =>
                                  reply.messageId === childMessageId
                                    ? {
                                        ...reply,
                                        content: content ?? reply.content,
                                        completedAt: feedbackMessage?.complete ? new Date() : null,
                                        pinned: feedbackMessage?.pinned ?? null,
                                        members:
                                          feedbackMessage?.pinned && feedbackMessage.pinned
                                            ? reply.members
                                            : [],
                                      }
                                    : reply
                                )
                                .sort((first, second) =>
                                  feedbackSortCallback(
                                    first?.pinned,
                                    second?.pinned,
                                    first?.createdAt,
                                    second?.createdAt
                                  )
                                ),
                            }
                          : elem
                      ),
                    ],
              },
            },
          })
        }
      },
    })
  }
}
