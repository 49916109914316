import { createMachine, MachineConfig, MachineOptions } from 'xstate'
import { nameToView, changeView } from './bt-apps-send.helpers'
import { Context, Event, State } from './bt-apps-send.types'
import { createContext, FC, useContext } from 'react'
import { useMachine } from '@xstate/react'
const BTSendConfigOptions: Partial<MachineOptions<Context, Event>> = {
  actions: { changeView },
}
export const BTSendConfig: MachineConfig<Context, State, Event> = {
  id: 'BTSend',
  initial: 'entry',
  context: {
    currentView: nameToView['entry'],
  },
  states: {
    entry: {
      on: {
        next: {
          target: 'confirm',
          actions: { type: 'changeView', payload: 'confirm' },
        },
      },
    },
    confirm: {
      on: {
        prev: {
          target: 'entry',
          actions: { type: 'changeView', payload: 'entry' },
        },
        next: {
          target: 'last',
          actions: { type: 'changeView', payload: 'last' },
        },
      },
    },
    last: {
      on: {
        prev: {
          target: 'confirm',
          actions: { type: 'changeView', payload: 'confirm' },
        },
      },
    },
  },
}
const useSendMachine = () => useMachine(BTSendMachine)
type SendMachineType = ReturnType<typeof useSendMachine>
export const BTSendMachine = createMachine(BTSendConfig, BTSendConfigOptions)
export const BTSendMachineStepsCounts = 3
const BTSendMachineContext = createContext<SendMachineType | []>([])
export const useMachineContext = () => useContext(BTSendMachineContext)
export const BTSendMachineProvider: FC = ({ children }) => {
  const [current, send, actor] = useSendMachine()
  return (
    <BTSendMachineContext.Provider value={[current, send, actor]}>
      {children}
    </BTSendMachineContext.Provider>
  )
}
