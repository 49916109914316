import {
  useCreateProjectMutation,
  ProjectSummariesDocument,
  ProjectSummariesQuery,
  CreateProjectDocument,
} from '../../generated/graphql'

export function useCreateProject() {
  return useCreateProjectMutation({
    update: (cache, { data }) => {
      if (data) {
        const excitedProjects = cache.readQuery({
          query: ProjectSummariesDocument,
        }) as ProjectSummariesQuery

        const newProject = data.createProject.project

        cache.writeQuery({
          query: CreateProjectDocument,
          data: { createProject: { project: { ...newProject } } },
        })

        cache.writeQuery({
          query: ProjectSummariesDocument,
          data: { projects: [newProject, ...excitedProjects.projects] },
        })
      }
    },
  })
}
