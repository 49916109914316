import { FC } from 'react'
import { BTIconButtons } from '../bt-icon-buttons'
import { CheckUserRoleAccess } from 'helpers/CheckUserRoleAccess'
import { AppendTitle, Wrapper } from './bt-append-button.presets'
import type { BTAppendButtonProps } from './bt-append-button.props'

export const BTAppendButton: FC<BTAppendButtonProps> = ({
  Button,
  index,
  title,
  bgColor,
  titleColor,
  className,
  activityId,
  ...rest
}) => {
  return (
    <Wrapper className={className} bgColor={bgColor} titleColor={titleColor} {...rest}>
      <AppendTitle>{title}</AppendTitle>
      <CheckUserRoleAccess activityId={activityId}>
        <BTIconButtons
          buttonsProps={[
            {
              iconComponent: <Button name={title} id={index} />,
            },
          ]}
        />
      </CheckUserRoleAccess>
    </Wrapper>
  )
}
