import React, { FC } from 'react'
import { BTQuestionsRowContainer } from './bt-questions-page-row.presets'
import { BTQuestionsActionsRowProps } from './bt-questions-page-row.props'
export const BTQuestionsActionsRow: FC<BTQuestionsActionsRowProps> = ({ title }) => {
  return (
    <BTQuestionsRowContainer>
      {title}
      {/**
       *@desc Maybe in the future it would be needed
       */}
      {/* <BTIconButtons
        buttonsProps={[
          {
            iconComponent: <MoreVertIcon onClick={handler} />,
            color: '#BDBDBD',
          },
        ]}
      /> */}
    </BTQuestionsRowContainer>
  )
}
