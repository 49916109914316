/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-expressions */
import { FC } from 'react'
import { useMachineContext } from 'services/StateMachines/bt-apps-send/bt-apps-send'
import { MemoizedBTAppsButton } from '../../../shared'
import { BTSendNextButtonProps } from './bt-activity-send-next-btn.props'

export const BTSendNextButton: FC<BTSendNextButtonProps> = ({ children, disabled }) => {
  const [_, send] = useMachineContext()

  const handleNext = () => {
    if (send) {
      send('next')
    }
  }

  return (
    <MemoizedBTAppsButton
      variant="contained"
      color="primary"
      /** @desc we need to use lowercase, because uppercase cause errors with MUI */
      texttransform="initial"
      onClick={handleNext}
      disabled={disabled}
    >
      {children}
    </MemoizedBTAppsButton>
  )
}
