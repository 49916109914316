import { PaletteOptions } from '@material-ui/core/styles/createPalette'

export const colors = {
  projectColors: {
    approved: '#27AE60',
    approvedBackground: '#A9D9BD',
    approvedIcon: '#00B156',
    main: '#0066FF',
    teal: '#2FCEC5',
    purple: '#8A4CFD',
    pink: '#F07AFA',
    lightGrey: '#f2f2f2',
    black: '#333333',
    activeTab: '#005BE2',
    fileBorder: '#e0e0e0',
    fileSize: '#b9b9c3',
    iconGrey: '#bdbdbd',
    iconDarkGrey: '#828282',
    filePages: '#4f4f4f',
    secondaryFont: '#4f4f4f',
  },

  red: {
    '500': '#FF5A78',
    '700': '#F33D5E',
  },

  navyGrey: {
    '100': '#F5F7FA',
    '200': '#E6EAEF',
    '300': '#CCD3DA',
    '400': '#999999',
    '500': '#79838E',
    '600': '#5F6974',
    '700': '#454F5A',
    '800': '#2A313A',
    '900': '#333333',
  },

  blue: {
    '100': '#E6F3FF',
    '200': '#CAE2FF',
    '300': '#93C4FF',
    '400': '#4F92FE',
    '500': '#0066FF',
    '600': '#0553D6',
    '700': '#043D9D',
    '800': '#032A77',
    '900': '#081B4D',
  },

  white: '#ffffff',
  black: '#000000',
  orange: '#ffa469',
}

export const palette = {
  primary: {
    light: colors.blue['200'],
    main: colors.projectColors.main,
    hover: colors.blue['400'],
  },

  common: {
    white: colors.white,
  },

  secondary: {
    light: colors.navyGrey['200'],
    main: colors.navyGrey['400'],
    dark: colors.navyGrey['900'],
  },

  error: {
    main: colors.red['500'],
  },

  text: {
    primary: colors.navyGrey['900'],
    secondary: colors.white,
    disabled: colors.navyGrey['400'],
  },

  background: {
    default: colors.navyGrey['100'],
    paper: colors.navyGrey['300'],
  },
  shadows: {
    main: '2px 0px 4px rgba(0, 0, 0, 0.25)',
  },
} as PaletteOptions
