import { colors } from 'theme/Colors'
import styled from 'styled-components'

export const BTNoAssetsTutorialWrapper = styled.div`
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    text-align: center;

    .title {
      margin-bottom: 11px;

      ${({ theme }) => theme.typography.h5};
      color: ${colors.projectColors.main};
    }

    .info {
      ${({ theme }) => theme.typography.regular};
    }

    .tutorial-arrow {
      position: absolute;
      transform: translate(203px, -280px);
    }
  }
`
