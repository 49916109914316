import styled from '@emotion/styled'
import { colors } from 'theme/Colors'

export const ChooseFileModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  button {
    width: fit-content;
    align-self: flex-end;
    text-transform: capitalize;
  }

  .buttons-block {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
    width: 100%;
    align-self: flex-end;
    justify-content: space-between;

    button {
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
      color: ${colors.white};
      text-transform: capitalize;
      width: 120px;
    }
  }

  .title {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
  }
`
