import { BTDashboard } from 'components/bt-dashboard'
import { Navigate, RouteObject } from 'react-router-dom'
import { BTArchivedProjects } from 'components/bt-archived-projects'

const WorkspaceRoutes: RouteObject[] = [
  { index: true, element: <Navigate to="/workspace" /> },
  { path: 'workspace', element: <BTDashboard /> },
  { path: 'workspace/archived-projects', element: <BTArchivedProjects /> },
  { path: '*', element: <Navigate to="/workspace" /> },
]

export { WorkspaceRoutes }
