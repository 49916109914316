import { RefObject } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { TOP_PADDING_OFFSET } from './bt-file-preview.constants'

export const scrollTo = (
  pageNumber: number,
  scrollParentRef: RefObject<ScrollContainer>,
  mainParent: RefObject<HTMLDivElement>
): void => {
  const elem: HTMLDivElement | null = document.querySelector(
    `div[data-page-number='${pageNumber}']`
  )

  const oldElem = document.querySelector('.current-page')

  if (oldElem) {
    oldElem.classList.remove('current-page')
  }

  if (scrollParentRef && elem && mainParent && mainParent.current) {
    const top = mainParent.current?.offsetTop
    const offset = elem.offsetTop

    elem.classList.add('current-page')

    scrollParentRef.current
      ?.getElement()
      .scrollTo({ top: offset - top - TOP_PADDING_OFFSET, behavior: 'smooth' })
  }
}
