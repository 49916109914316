export class LocationService {
  public static goBack(pathname: string, deep: number = 1): string {
    const regex = /(\/[\w\-]+)$/gm
    if (deep === 0) {
      return pathname
    }
    return this.goBack(pathname.replace(regex, ''), deep - 1)
  }
  public static goForward(pathname: string, pathTo: string): string {
    const regex = /^[^\/]/gm
    const path = pathTo.replace(regex, (i) => '/' + i)
    return pathname.concat(path)
  }
}
