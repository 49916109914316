import styled from 'styled-components'
import { colors } from 'theme/Colors'

const BTHighlightedLabel = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: ${colors.projectColors['main']};
`

export { BTHighlightedLabel }
