import { toast } from 'react-toastify'
import type { ToastOptions } from 'react-toastify/dist/types'
import 'react-toastify/dist/ReactToastify.css'

interface NotifyMessage {
  message: string
  // progress?: number | string
  notifyType?: string
}

const NotifyTypes = {
  SUCCESS: 'success',
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error',
}

// const customId = "custom-id-yes"

const toastOptions: ToastOptions | undefined = {
  position: 'bottom-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  // toastId: customId, // avoid duplicate
}

export function toastMessage({ message, notifyType }: NotifyMessage) {
  let notifyMessage
  const defaultSetting = () => toast.error(message, toastOptions)

  switch (notifyType) {
    case NotifyTypes.ERROR:
      notifyMessage = () => toast.error(message, toastOptions)
      notifyMessage()
      break

    default:
      defaultSetting()
      break
  }
}
