/* eslint-disable import/no-unresolved */
/* eslint-disable prefer-const */
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { resetSidebarColors, setupType, toggleOpen, setupApprovals } from 'redux/SidebarAppsSlice'
import { useParams } from 'react-router-dom'
import { colors } from 'theme/Colors'
import { useGetApprovalsQuery, useGetBriefQuery } from 'generated/graphql'
import { BTActionsBar, Wrapper } from './bt-actions-sidebar.presets'
import { BTSidebarList } from './components/bt-sidebar-list'
import { useTransition } from '../../../hooks/shared/useTransition'
import type { BTActionsSidebarProps } from './bt-actions-sidebar.props'
import { BTSidebarBlock } from './components/bt-sidebar-block'
import { SidebarApplications } from './bt-actions-sidebar.types'

export const BTActionsSidebar: FC<BTActionsSidebarProps> = ({ top, bottom, type }) => {
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState(false)
  const [shouldRender] = useTransition(open)
  const { isExtended } = useAppSelector((state) => state.sidebarApps)
  // const dispatch = useAppDispatch()
  // FC<any> because we don't know future component props
  const [currentComponent, setCurrentComponent] = useState<null | SidebarApplications>(null)
  const mountHandler = useCallback(
    // FC<any> because we don't know future component props
    (apps: SidebarApplications) => {
      dispatch(resetSidebarColors(true))
      if (!open) {
        setCurrentComponent(apps)
        setOpen(true)
      } else if (apps.application === currentComponent?.application) {
        setOpen(false)
      } else {
        let timeout: ReturnType<typeof setTimeout>
        setOpen(false)
        setCurrentComponent(null)
        timeout = setTimeout(() => {
          setOpen(true)
          setCurrentComponent(apps)
          clearTimeout(timeout)
        }, 250)
      }
    },
    [open, shouldRender, currentComponent, isExtended]
  )

  /** Set approved status */
  const { id: projectId, activityId, artworkId } = useParams()
  const briefResponse = useGetBriefQuery({ variables: { id: projectId } })
  const briefId = briefResponse.data?.getBrief.id
  const id = activityId || artworkId || briefId
  const { data: approvalResponse } = useGetApprovalsQuery({
    variables: {
      id: id!,
    },
  })

  useEffect(() => {
    if (approvalResponse) {
      const { isApproved } = approvalResponse.approvals

      dispatch(
        setupApprovals({
          color: isApproved ? colors.projectColors.approvedIcon : '',
          isApproved,
        })
      )
    }
  }, [approvalResponse])
  // ----------------------------

  useEffect(() => {
    dispatch(setupType(type))
  }, [type])
  useEffect(() => {
    setCurrentComponent((prev) => {
      if (!prev) {
        return prev
      }
      return prev
    })
  }, [isExtended])
  useEffect(() => {
    if (open) {
      dispatch(toggleOpen(open))
    }

    return () => {
      dispatch(toggleOpen(false))
    }
  }, [open])
  const closeHandler = useCallback(() => {
    setOpen(false)
  }, [])
  const Component = useMemo(() => {
    if (isExtended) {
      const extendedComponent = currentComponent?.extendedApp || currentComponent?.application
      return extendedComponent
    }
    const component = currentComponent?.application
    return component
  }, [currentComponent, isExtended])

  return (
    <Wrapper>
      {shouldRender && Component && (
        <BTSidebarBlock mounted={open} closeHandler={closeHandler} isExtended={isExtended}>
          {Component ? <Component /> : null}
        </BTSidebarBlock>
      )}
      <CssBaseline />
      <BTActionsBar>
        <BTSidebarList.Top Logos={top} externalHandler={mountHandler} />
        <BTSidebarList.Bottom Logos={bottom} externalHandler={mountHandler} />
      </BTActionsBar>
    </Wrapper>
  )
}
