/* eslint-disable import/no-unresolved */
import { ActivityType } from 'generated/graphql'

export const setLinkUrl = (projectId: string, activityType: string, activityId: string) => {
  if (activityType === ActivityType.Brief) {
    return `/projects/${projectId}/brief`
  }

  if (activityType === ActivityType.Artwork) {
    return `/projects/${projectId}/artwork/${activityId} `
  }

  return `/projects/${projectId}/activity/${activityId}`
}
