import { UpdateTaskInput, useUpdateTaskMutation, TaskFragmentDoc } from '../../generated/graphql'

interface IChecklistItem {
  id: string
  name: string
  completedAt: Date | null
}

export function useArchiveTask(): (id: string, archived: boolean) => Promise<void> {
  const [updateTask] = useUpdateTaskMutation()
  return async (id, archived) => {
    await updateTask({
      variables: {
        input: {
          id,
          archived,
        },
      },
    })
  }
}
