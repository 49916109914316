/* eslint-disable import/no-unresolved */
import React from 'react'
import Box from '@mui/material/Box'
import { colors } from 'theme/Colors'
import { PuffLoader } from 'react-spinners'
import { BTLoaderProps } from './bt-loader.props'

const LoaderElement: React.FC = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <PuffLoader color={colors.projectColors.main} size={100} />
    </Box>
  )
}
export const BTLoader: React.FC<BTLoaderProps> = ({ isTextHeight }) => {
  if (isTextHeight) {
    return (
      <div className="container container-match-text-height">
        <LoaderElement />
      </div>
    )
  }
  return <LoaderElement />
}
