/* eslint-disable no-nested-ternary */
import { useParams } from 'react-router-dom'
import React, { useEffect, useState, MouseEvent } from 'react'
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api'
import { Box } from '@mui/material'
import { BTLoader } from '../shared/bt-loader'
import { useGetAssetByFileIdQuery } from '../../generated/graphql'
import { useFileDownload } from '../../hooks/file/useFileDownload'
import { toastNotifications } from '../../helpers/toastNotifications'
import { BTFileView } from '../Brief/bt-set-brief/components/bt-file-view'
import { fileConvert, IFileFromConvertor } from '../../helpers/file/fileConvert'

export const BTAssetView = () => {
  const { fileId } = useParams()
  const fileDownload = useFileDownload()
  const [pageNumber, setPageNumber] = useState(1)
  const [numPages, setNumPages] = useState<number | null>(null)
  const [dataLoading, setDataLoading] = useState<boolean>(false)
  const [assetFile, setAssetFile] = useState<IFileFromConvertor[] | null>(null)
  const [assetFileForDownload, setAssetFileForDownload] = useState<Blob | undefined>()
  const { data: asset, loading } = useGetAssetByFileIdQuery({
    variables: {
      input: fileId!,
    },
  })

  const file = asset?.getAssetByFileId.asset?.file
  const assetId = asset?.getAssetByFileId.asset?.id

  /** File logic */
  const getFile = async () => {
    try {
      setDataLoading(true)

      const response = await fileDownload(fileId)

      const fileToconvert = {
        name: file?.name,
        content: response,
      }

      fileConvert([fileToconvert], setAssetFile)
      setAssetFileForDownload(response)
      setDataLoading(false)
    } catch (err) {
      setDataLoading(false)
      const error = err as Error
      toastNotifications(error.message).error()
    }
  }

  const pagesHandler = (event: MouseEvent<HTMLButtonElement>) => {
    switch (event.currentTarget.id) {
      case 'next':
        setPageNumber(pageNumber + 1)
        break
      case 'prev':
        setPageNumber(pageNumber - 1)
        break
      default:
        break
    }
  }

  const onDocumentLoadSuccess = (pdf: PDFDocumentProxy) => {
    setNumPages(pdf.numPages)
  }

  const renderActivity = () => {
    if (file) {
      return (
        <Box sx={{ marginTop: '25%' }}>
          <BTFileView
            file={assetFile}
            pageNumber={pageNumber}
            numPages={numPages}
            onLoadSuccess={onDocumentLoadSuccess}
            pagesHandler={pagesHandler}
            assetId={assetId}
            fileId={file.id}
            fileForDownload={assetFileForDownload}
          />
        </Box>
      )
    }

    return <BTLoader />
  }

  useEffect(() => {
    if (file) {
      getFile()
    }
  }, [file])

  return dataLoading || loading ? <BTLoader /> : renderActivity()
}
