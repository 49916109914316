import {
  GlobalAsset,
  useGetAllActivityAssetsQuery,
  useGetCurrentActivityByIdQuery,
} from 'generated/graphql'
import { fileConvert, IFileFromConvertor } from 'helpers/file/fileConvert'
import { GlobalWorkerOptions, PDFDocumentProxy } from 'pdfjs-dist'
import { FC, useEffect, useState } from 'react'
import { pdfjs } from 'react-pdf'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import axios from 'axios'
import { ApolloClient, ApolloConsumer } from '@apollo/client'
import { useAppDispatch } from 'redux/store'
import { setAssetId } from 'redux/SidebarAppsSlice'
import { PuffLoader } from 'react-spinners'
import { colors } from 'theme/Colors'
import { BTArtworkWrapper } from './bt-artwork.presets'
import { BTNavigation } from './components/bt-navigation'
import { BTFilePreview } from './components/bt-preview'
import { BTVersionNavigation } from './components/bt-version-navigation'
import { BTArtworkHeader } from './components/bt-artwork-header'
import { BTNoAsset } from './components/bt-no-asset'
import { convertFileToAsset, setPdfPages, sortFileByVersionsClb } from './bt-artwork.helper'
import { BTMarkUpLayout } from './components/bt-mark-up-layout'
import { LOADER_SIZE } from './bt-artwork.constants'

GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax
/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export const BTArtwork: FC = () => {
  const [pages, setPages] = useState<number[]>([])
  const [pageNumber, setPageNumber] = useState(1)
  const [assetVersion, setAssetVersion] = useState(1)
  const [isFileLoading, setIsFileLoading] = useState(false)
  const [currentAssetFile, setCurrentAssetFile] = useState<IFileFromConvertor[] | null>(null)
  const [assets, setAssets] = useState<GlobalAsset[]>([])

  const dispatch = useAppDispatch()

  const [queryParams, setQueryParams] = useSearchParams()
  const { artworkId } = useParams()
  const navigate = useNavigate()

  const {
    data,
    loading,
    error: artworkError,
  } = useGetCurrentActivityByIdQuery({
    variables: { id: artworkId ?? '' },
  })

  const { loading: assetsLoading, refetch } = useGetAllActivityAssetsQuery({
    variables: { activityId: artworkId ?? '' },
    onCompleted: (elems) =>
      setAssets(
        elems.getAllActivityAssets
          .slice()
          .sort((first, second) =>
            sortFileByVersionsClb(first as GlobalAsset, second as GlobalAsset)
          ) as unknown as GlobalAsset[]
      ),
  })

  const onDocumentLoadSuccess = (pdfProxy: PDFDocumentProxy) => {
    setPdfPages(pdfProxy, setPages)
  }

  const setCurrentPage = (page: number) => setPageNumber(page)

  const getFile = async () => {
    setIsFileLoading(true)
    if (assets && assets.length > 0) {
      const asset = assets[assetVersion - 1]

      try {
        const file = await axios.get<Blob>(asset.file?.getUrl ?? '', {
          responseType: 'blob',
        })

        if (file) {
          const fileToconvert = {
            name: asset.file?.name,
            content: file.data,
          }

          fileConvert([fileToconvert], setCurrentAssetFile)
        }
      } catch (error) {
        await refetch({ activityId: artworkId })
      } finally {
        setIsFileLoading(false)
      }
    }
    setIsFileLoading(false)
  }

  const getCurrentAsset = (file: File) => {
    convertFileToAsset(file, setCurrentAssetFile)
  }

  useEffect(() => {
    if (assets && assets.length) {
      getFile()
    }
  }, [assets])

  useEffect(() => {
    setPageNumber(1)
    setFileIdByVersion()

    if (assets?.length === 0) {
      setCurrentAssetFile(null)
    }
  }, [assets, assetVersion])

  useEffect(() => {
    if (assetVersion !== 0) {
      getFile()
    }
    setPages([])

    if (assets.length > 0) {
      setQueryParams({ version: assetVersion.toString() })
    }
  }, [assetVersion])

  useEffect(() => {
    const queryVersion = queryParams.get('version')

    if (assets.length > 0 && queryVersion) {
      const version: number = Number.parseInt(queryVersion, 10)

      if (!Number.isNaN(version)) {
        if (version <= assets.length && version > 0) {
          setAssetVersion(version)
        } else {
          setAssetVersion(1)
          setQueryParams({})
        }
      }
    } else if (assets.length > 0 && queryVersion === null) {
      setQueryParams({ version: assetVersion.toString() })
    }

    if (!currentAssetFile) {
      setPages([])
    }
  }, [currentAssetFile])

  useEffect(() => {
    if (artworkError) {
      navigate('/404')
    }
  }, [artworkError])

  const setFileIdByVersion = () => {
    if (assets && assetVersion <= assets.length) {
      dispatch(setAssetId(assets[assetVersion - 1].fileId ?? ''))
    }
  }

  const fileName = !loading
    ? `${data?.activities.length && data?.activities[0]?.name}_v${assetVersion}`
    : 'File'

  if (assetsLoading || loading) {
    return (
      <div className="centered">
        <PuffLoader size={LOADER_SIZE} color={colors.projectColors.main} />
      </div>
    )
  }

  return (
    <BTArtworkWrapper loading={isFileLoading || assetsLoading}>
      <div className="top-row row">
        {currentAssetFile !== null && (
          <div className="navigation-column column">
            <BTVersionNavigation
              currentVersion={assetVersion}
              numberOfVersions={assets?.length ?? 1}
              setCurrentVersion={setAssetVersion}
            />
          </div>
        )}
        <ApolloConsumer>
          {(client: ApolloClient<object>) => (
            <BTArtworkHeader
              fileName={fileName}
              fileData={currentAssetFile && currentAssetFile[0]}
              className="column"
              assetsNumber={assets?.length ?? 0}
              setVersion={setAssetVersion}
              getCurrentAsset={getCurrentAsset}
              apolloClient={client}
              version={assetVersion}
              loading={isFileLoading}
            />
          )}
        </ApolloConsumer>
      </div>
      <div className="main-row row">
        {currentAssetFile !== null ? (
          <div className={pages.length > 0 ? 'preview-block-column column' : 'hide'}>
            {pages.length > 0 || !isFileLoading ? (
              <BTNavigation
                currentPage={pageNumber}
                numberOfPages={pages.length}
                setCurrentPage={setPageNumber}
              />
            ) : undefined}
            <BTFilePreview
              arrayOfPages={pages}
              currentPage={pageNumber}
              file={(currentAssetFile?.length && currentAssetFile[0].data) || ''}
              type={(currentAssetFile?.length && currentAssetFile[0].type) || ''}
              onDocumentLoadSuccess={onDocumentLoadSuccess}
              setCurrentPage={setCurrentPage}
              isLoading={assetsLoading || isFileLoading}
            />
          </div>
        ) : null}
        <div className="artwork-view-column column">
          {!assets?.length ? (
            <BTNoAsset />
          ) : (
            <BTMarkUpLayout
              file={currentAssetFile && currentAssetFile[0]}
              loading={isFileLoading || assetsLoading}
              page={pageNumber}
              feedbackId={
                data?.activities && data?.activities.length > 0
                  ? data?.activities[0].feedback.id ?? undefined
                  : undefined
              }
            />
          )}
        </div>
      </div>
    </BTArtworkWrapper>
  )
}
