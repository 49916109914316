import { FC } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import PublishIcon from '@mui/icons-material/Publish'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { Item, Wrapper, Heading } from '../../shared/bt-styled-components'

export const BTActivityCreateItem: FC = () => {
  const { name = 'Activity' } = useParams()
  const { pathname } = useLocation()
  const navigation = useNavigate()

  const handleComposeDocument = () => {
    navigation(pathname.replace('create', 'create/compose'))
  }

  const handleUploadDocument = () => {
    navigation(pathname.replace('create', 'create/upload'))
  }

  return (
    <Wrapper>
      <Heading>Create {name}</Heading>
      <Item onClick={handleComposeDocument}>
        <span>Compose Document</span>
        <EditIcon />
      </Item>
      <Item onClick={handleUploadDocument}>
        <span>Upload Document</span>
        <PublishIcon />
      </Item>
    </Wrapper>
  )
}
