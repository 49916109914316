/* eslint-disable import/order */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import List from '@mui/material/List'
import styled from 'styled-components'
import { colors } from '../../../../theme/Colors'
import { styled as MUIStyled } from '@mui/material'
import type { SideListProps } from './main-button-list.props'

const SideList = MUIStyled(List)<SideListProps>`
    ${({ theme, open }) => ({
      '& .MuiListItem-root': {
        justifyContent: 'center',
        padding: '12px 0',
      },
      '& .MuiListItemIcon-root': {
        justifyContent: 'center',
        minWidth: '32px',
        minHeight: '32px',
        alignItems: 'center',
        margin: open && '0 0 0 20px',
      },
      '& .MuiListItemText-root': {
        color: '#FFFFFF',
        padding: '4px 0 4px 10px',
      },
      '& .active .MuiListItemIcon-root': {
        background: colors.projectColors.activeTab,
        borderRadius: open ? '5px 0 0 5px' : '5px',
        fontWeight: 'bold',
      },
      '& .active .MuiListItemText-root': {
        background: colors.projectColors.activeTab,
        borderRadius: '0 5px 5px 0',
        marginRight: '20px',
        '& span': {
          fontWeight: 'bold',
        },
      },
    })}
`

const NewNotificationsIcon = styled.div`
  position: absolute;
  transform: translate(7.5px, -6.5px);

  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: ${colors.orange};
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
`

const SearchWrapper = styled.div`
  padding: 0px 20px 8px 21px;
`

const ProjectsScrollWrapper = styled.div`
  .projects-list {
    max-height: 250px;

    &::-webkit-scrollbar {
      background-color: transparent;
      height: 10px;
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${colors.navyGrey[200]};
      border-radius: 10px;
    }
  }
`

const NotFoundText = styled.div`
  ${({ theme }) => ({ ...theme.typography.regular })}
  margin-left: 75px;
  margin-top: 5px;
  color: ${colors.navyGrey[200]};
`

export { SideList, NewNotificationsIcon, SearchWrapper, ProjectsScrollWrapper, NotFoundText }
