/* eslint-disable no-unused-expressions */
import { useCallback, FC, useState, useEffect, useRef } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import { useLocation } from 'react-router-dom'
import { BTWordHighlighter } from '../bt-word-highlighter'
import { typeToBytes } from './bt-files-drop.helpers'
import { BTDropDescription, BTDropText, BTDropWrapper } from './bt-files-drop.presets'
import { BTFilesDropProps } from './bt-files-drop.props'
import { Files, NullableFiles } from './bt-files-drop.types'

export const BTFilesDrop: FC<BTFilesDropProps> = ({
  text = 'Drop your file here or %browse%',
  options,
  className,
  handleDrop,
  getRef,
  fileType = 'image/*',
  maxFileSize = { mb: 20 },
}) => {
  const formattedMaxSize = typeToBytes(maxFileSize)
  const maxSize = { maxSize: formattedMaxSize }
  const [state, setState] = useState<NullableFiles>(null)
  const ref = useRef<null | { files: NullableFiles }>(null)

  const { pathname } = useLocation()

  const onDrop = useCallback((acceptedFiles: Files) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const files = acceptedFiles

      if (pathname.includes('global-assets')) {
        setState(files)
        handleDrop && handleDrop(files)
      } else {
        setState([files[0]])
        handleDrop && handleDrop([files[0]])
      }
    }
  }, [])

  const dropOptions: DropzoneOptions = options
    ? { ...options, onDrop, ...maxSize }
    : { onDrop, ...maxSize, accept: fileType }

  const dropzoneOptions = dropOptions

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone(dropzoneOptions)
  useEffect(() => {
    ref.current = {
      files: state,
    }
    getRef && getRef(ref)
  }, [state])

  return (
    <BTDropWrapper className={className} {...getRootProps()}>
      <input {...getInputProps()} />
      <BTDropText>
        <BTWordHighlighter>{text}</BTWordHighlighter>
      </BTDropText>
    </BTDropWrapper>
  )
}
