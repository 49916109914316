import { useParams } from 'react-router-dom'
import { useArchiveProjectMutation, useProjectSummariesQuery } from 'generated/graphql'

export const useArchiveProject = () => {
  const { id: projectIdFromParam } = useParams()
  const [archiveProject] = useArchiveProjectMutation()
  const { data, refetch: refetchUnarchivedProjects } = useProjectSummariesQuery()
  const { data: archivedProjectsResponse, refetch: refetchArchivedProjects } =
    useProjectSummariesQuery({
      variables: {
        archivedType: 'Archived',
      },
    })

  return async (projectIdFromArguments: string = '') => {
    await archiveProject({
      variables: {
        input: { projectId: projectIdFromParam || projectIdFromArguments },
      },
    })
    refetchUnarchivedProjects()
    refetchArchivedProjects()
  }
}
