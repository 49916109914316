import { FC, useEffect, useState } from 'react'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import ScrollContainer from 'react-indiana-drag-scroll'
import { prop, propEq } from 'ramda'
import { useNavigate, useParams } from 'react-router-dom'
import { MilestoneFragment, ProjectDocument, ProjectFragment } from '../../../generated/graphql'
import { useMoveActivity, useProject } from '../../../hooks'
import {
  BTActivityBoardWrapper,
  ColumnWrapper,
  HorizontalScrollContainer,
  ItemWrapper,
  Root,
} from './bt-activity-board.presets'
import { BTActivityColumn } from './components/activity-column'
import { BTAppendColumn } from './components/append-column'
import { BTItemHeader } from './components/item-header'
import { IBTActivityBoard } from './bt-activity-board.props'

export const BTActivityBoard: FC<IBTActivityBoard> = ({ client }) => {
  const PROJECT_MILESTONE_ID = 'project activities'

  const [projectInternal, setInternalProject] = useState<ProjectFragment>()
  const { id } = useParams()
  const { project, loading, error } = useProject(id)
  const navigate = useNavigate()

  useEffect(() => {
    if (project !== undefined) {
      setInternalProject(project)
    } else {
      const newProject = client?.readQuery({
        query: ProjectDocument,
        variables: { id },
      })
      setInternalProject(newProject)
    }
  }, [loading, project])

  if (error) {
    navigate('/404')
  }

  const moveActivity = useMoveActivity()
  if (!projectInternal || loading || !projectInternal.milestones) {
    return null
  }
  const milestones: MilestoneFragment[] = [...projectInternal.milestones].map(sortActivities)
  const activities = milestones.flatMap(prop('activities'))

  const onDragEnd = async ({ source, destination, draggableId }: DropResult) => {
    if (!destination || !projectInternal) {
      return
    }

    const activity = activities.find(propEq('id', draggableId))!
    const milestone = milestones.find(propEq('id', destination.droppableId))!
    const isSameMilestone = source.droppableId === destination.droppableId
    const destinationIndex =
      isSameMilestone && source.index < destination.index
        ? destination.index + 1
        : destination.index
    const before = milestone.activities[destinationIndex]

    if (activity !== before) {
      // Only perform the operation if the activity has actually been moved
      await moveActivity(
        projectInternal,
        activity,
        milestone.id === PROJECT_MILESTONE_ID ? undefined : milestone.id,
        before
      )
    }
  }

  return (
    <BTActivityBoardWrapper>
      <DragDropContext onDragEnd={onDragEnd}>
        <Root>
          <HorizontalScrollContainer>
            {milestones
              .filter(({ archivedAt }) => !archivedAt)
              .map(({ id, activities, name }) => {
                const activitiesCount = activities.filter(({ archivedAt }) => !archivedAt).length

                return (
                  <ItemWrapper key={id}>
                    <BTItemHeader milestoneId={id} title={name} itemsCount={activitiesCount} />
                    <ScrollContainer
                      horizontal={false}
                      vertical
                      hideScrollbars={false}
                      style={{
                        minWidth: '300px',
                        height: '100%',
                        overflow: 'overlay',
                        maxHeight: '94%',
                      }}
                      className="scroll-wrapper"
                      ignoreElements="*[data-rbd-draggable-context-id]"
                    >
                      <Droppable droppableId={id}>
                        {(provided, { isDraggingOver }) => (
                          <ColumnWrapper ref={provided.innerRef} {...provided.droppableProps}>
                            <BTActivityColumn
                              activities={activities}
                              milestone={{ id, activities, name }}
                              isDraggingOver={isDraggingOver}
                            />
                            {provided.placeholder}
                          </ColumnWrapper>
                        )}
                      </Droppable>
                    </ScrollContainer>
                  </ItemWrapper>
                )
              })}
            <BTAppendColumn
              columnCounter={milestones.length}
              projectId={projectInternal.id}
              project={project}
            />
          </HorizontalScrollContainer>
        </Root>
      </DragDropContext>
    </BTActivityBoardWrapper>
  )
}

function sortActivities(milestone: MilestoneFragment): MilestoneFragment {
  return {
    ...milestone,
    activities: [...milestone.activities].sort((a, b) => a.position - b.position),
  }
}
