/* eslint-disable prettier/prettier */
/* eslint-disable import/no-unresolved */
import { useAppSelector } from 'redux/store'
import { PAGINATION } from 'helpers/constants'
import { GlobalAssetFilter, getOrganizationsIds } from '../components/shared/bt-assets/utils'
import {
  SortOrder,
  ActivityType,
  GetAllAssetsQuery,
  GetAllAssetsDocument,
  GetActivityByIdQuery,
  useDeleteAssetMutation,
  GetActivityByIdDocument,
  GetCurrentActivityByIdQuery,
  GetCurrentActivityByIdDocument,
} from '../generated/graphql'

export const useDeleteAsset = () => {
  const { organizations } = useAppSelector((state) => state.login)
  const organizationsIds = getOrganizationsIds(organizations)
  const [deleteAssetMutation] = useDeleteAssetMutation()

  return async (
    assetId: string,
    activityType: string | undefined,
    activityId: string | null | undefined,
    totalPages: number,
    currentPage: number,
    filter: number,
    searchValue: string,
    sortOrder: SortOrder
  ) => {
    if (!activityType) {
      await deleteAssetMutation({
        variables: {
          input: assetId,
        },
        update(cache) {
          const assetToDeleteId = cache.identify({
            id: assetId,
            __typename: 'GlobalAsset',
          })
          cache.evict({ id: assetToDeleteId })
          cache.gc()

          /** For displaying asset from next page at the current */
          if (currentPage < totalPages) {
            const currentPageAssetsQuery = cache.readQuery({
              query: GetAllAssetsDocument,
              variables: {
                input: {
                  organizations: organizationsIds,
                  type: GlobalAssetFilter[filter].value,
                  searchValue,
                },
                page: currentPage,
                sortOrder,
              },
            }) as GetAllAssetsQuery

            const assetsFromNextPage = cache.readQuery({
              query: GetAllAssetsDocument,
              variables: {
                input: {
                  organizations: organizationsIds,
                  type: GlobalAssetFilter[filter].value,
                  searchValue,
                },
                page: currentPage + 1,
                sortOrder,
              },
            }) as GetAllAssetsQuery

            const currentPageAssetsCache = [...currentPageAssetsQuery.getAllAssets.assets]
            let assetToReplace

            /** If next page with assets exist in the cache */
            if (assetsFromNextPage) {
              const nextPageAssetsCache = [...assetsFromNextPage.getAllAssets.assets]
              assetToReplace = nextPageAssetsCache.shift()

              /** Write current page query cache */
              cache.writeQuery({
                query: GetAllAssetsDocument,
                variables: {
                  input: {
                    organizations: organizationsIds,
                    type: GlobalAssetFilter[filter].value,
                    searchValue,
                  },
                  page: currentPage,
                  sortOrder,
                },
                data: {
                  getAllAssets: {
                    ...currentPageAssetsQuery.getAllAssets,
                    assets: [...currentPageAssetsCache, assetToReplace],
                    paginationData: {
                      ...currentPageAssetsQuery.getAllAssets.paginationData,
                      totalAmount:
                        currentPageAssetsQuery.getAllAssets.paginationData.totalAmount - 1,
                      totalPages: Math.ceil(
                        (currentPageAssetsQuery.getAllAssets.paginationData.totalAmount - 1) /
                          PAGINATION.ASSETS_PER_PAGE
                      ),
                    },
                  },
                },
              })

              /** Write next page query cache */
              cache.writeQuery({
                query: GetAllAssetsDocument,
                variables: {
                  input: {
                    organizations: organizationsIds,
                    type: GlobalAssetFilter[filter].value,
                    searchValue,
                  },
                  page: currentPage + 1,
                  sortOrder,
                },
                data: {
                  getAllAssets: {
                    ...assetsFromNextPage.getAllAssets,
                    assets: [...nextPageAssetsCache],
                    paginationData: {
                      ...assetsFromNextPage.getAllAssets.paginationData,
                      totalAmount: assetsFromNextPage.getAllAssets.paginationData.totalAmount - 1,
                    },
                  },
                },
              })
            }
          }
        },
      })
    } else if (activityType === ActivityType.Brief) {
      await deleteAssetMutation({
        variables: {
          input: assetId,
        },
        update(cache) {
          const normalizedId = cache.identify({
            id: activityId,
            __typename: 'Brief',
          })
          cache.evict({ id: normalizedId })
          cache.gc()
        },
      })
    } else if (activityType === ActivityType.Artwork) {
      await deleteAssetMutation({
        variables: {
          input: assetId,
        },
        update(cache) {
          const readedQuery: GetCurrentActivityByIdQuery | null = cache.readQuery({
            query: GetCurrentActivityByIdDocument,
            variables: {
              id: activityId!,
            },
          })

          if (readedQuery) {
            cache.writeQuery({
              query: GetCurrentActivityByIdDocument,
              variables: {
                id: activityId!,
              },
              data: {
                activities: [{ ...readedQuery.activities[0], uploadedDocument: null }],
              },
            })
          }
        },
      })
    } else {
      await deleteAssetMutation({
        variables: {
          input: assetId,
        },
        update(cache) {
          const readedQuery = cache.readQuery({
            query: GetActivityByIdDocument,
            variables: {
              input: activityId,
            },
          }) as GetActivityByIdQuery

          cache.writeQuery({
            query: GetActivityByIdDocument,
            variables: {
              input: activityId,
            },
            data: {
              getActivityById: {
                ...readedQuery,
                uploadedDocument: null,
              },
            },
          })
        },
      })
    }
  }
}
