/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-unresolved */
import { ChangeEvent, forwardRef, useEffect, useState } from 'react'
import { useDebounce } from 'hooks/shared/useDebounce'
import { BTSearchProps } from './bt-search.props'
import { BTSearchInput, BTSearchWrapper } from './bt-search.presets'
import { getIcon } from './bt-search.helpers'

export const BTSearch = forwardRef<HTMLInputElement, BTSearchProps>(
  ({ className, iconPosition, changeEvent, debounceDelay, placeholder, ...rest }, ref) => {
    const { searchSize, borderStyle, borderColor, ...inputProps } = rest
    const wrapperProps = { searchSize, borderStyle, borderColor }
    const [LeftIcon, RightIcon] = getIcon(iconPosition)
    const [state, setState] = useState('')
    const debouncedValue = useDebounce(state, debounceDelay)

    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
      setState(event.target.value)
    }

    useEffect(() => {
      changeEvent && changeEvent(debouncedValue)
    }, [debouncedValue])

    return (
      <BTSearchWrapper className={className} {...wrapperProps}>
        {LeftIcon}

        <BTSearchInput value={state} onChange={changeHandler} ref={ref} placeholder={placeholder} />

        {RightIcon}
      </BTSearchWrapper>
    )
  }
)
