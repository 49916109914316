/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import { DateTime } from 'luxon'
import { FC, useState } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { useArchiveProject } from 'hooks/project/useArchive'
import { BTSearchProject } from 'components/shared/bt-search-project'
import { ProjectSummariesQuery, useProjectSummariesQuery } from 'generated/graphql'
import { BTActivityListView, IListItem } from 'components/shared/bt-activity-list-view'
import { IBTHeaderSectionProps } from 'components/shared/bt-activity-list-view/bt-header-section-item'
import {
  BTSearchWrapper,
  BTArchivedProjectsWrapper,
  BTArchivedProjectsLayoutWrapper,
} from './bt-archived-projects.styles'

export const BTArchivedProjects: FC = () => {
  const [searchValue, setSearchValue] = useState<string>('')
  const [searchedProjects, setSearchedProjects] = useState<ProjectSummariesQuery['projects']>([])

  const archive = useArchiveProject()

  const { data: archivedProjectsResponse, refetch } = useProjectSummariesQuery({
    variables: {
      archivedType: 'Archived',
    },
  })

  const allArchivedProjects = archivedProjectsResponse?.projects

  const unarchive = async (projectId: string) => {
    await archive(projectId)
    refetch()
  }

  const searchHandler = (value: string) => {
    setSearchValue(value)
    setSearchedProjects([])

    const lowerCasedValue = value.toLowerCase()

    const filteredProjects = allArchivedProjects?.filter((project) => {
      const lowerCasedProjectName = project.name?.toLowerCase()
      const lowerCasedClientName = project.client?.name.toLowerCase()

      if (lowerCasedProjectName && lowerCasedClientName) {
        return (
          lowerCasedProjectName.includes(lowerCasedValue) ||
          lowerCasedClientName.includes(lowerCasedValue)
        )
      }
      return false
    })

    if (filteredProjects) {
      setSearchedProjects(filteredProjects)
    }
  }

  const tableHeaders: IBTHeaderSectionProps[] = [
    { title: 'Project Name' },
    { title: 'Client' },
    { title: 'Due Date' },
    { title: 'Date Archived' },
  ]

  const projectsRender = () => {
    let projects
    searchValue ? (projects = searchedProjects) : (projects = allArchivedProjects)

    return projects?.map((project) => {
      const projectId = project.id
      const projectName = project.name
      const clientName = project.client?.name
      const due = DateTime.fromJSDate(new Date(project.dueDate)).toFormat('d LLLL y')
      const archivedAt = DateTime.fromJSDate(new Date(project.archivedAt)).toFormat('d LLLL y')

      return {
        itemSections: [
          <span>{projectName}</span>,
          <span>{clientName}</span>,
          <span>{due}</span>,
          <span>{archivedAt}</span>,
        ],
        handlerActions: [
          {
            label: <div>Unarchive</div>,
            action: async () => {
              await unarchive(projectId)
            },
          },
        ],
      }
    })
  }

  const itemsList: IListItem[] = projectsRender() || []

  return (
    <BTArchivedProjectsWrapper>
      <BTSearchWrapper>
        <div className="search-input">
          <BTSearchProject inputHandler={searchHandler} placeholder="Search by project or client" />
        </div>
        <div className="border-bottom " />
      </BTSearchWrapper>

      {itemsList.length ? (
        <BTArchivedProjectsLayoutWrapper>
          <ScrollContainer
            className="projects-list"
            hideScrollbars={false}
            horizontal={false}
            vertical
          >
            <BTActivityListView
              headerSections={tableHeaders}
              itemsList={itemsList}
              headerSectionBackground
            />
          </ScrollContainer>
        </BTArchivedProjectsLayoutWrapper>
      ) : searchValue ? (
        <div className="no-projects">Not found</div>
      ) : (
        <div className="no-projects">You have no archived projects</div>
      )}
    </BTArchivedProjectsWrapper>
  )
}
