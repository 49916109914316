/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import { FC, useState, MouseEvent } from 'react'
import Avatar from '@mui/material/Avatar'
import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import CircularProgress from '@mui/material/CircularProgress'
import type { BTSearchedUserBlockProps } from './bt-searched-user-block.props'
import { MemberListItemWrapper } from '../../../../shared/bt-project-add-members-step/components/bt-member-list-item/bt-member-list-item.presets'

export const BTSearchedUserBlock: FC<BTSearchedUserBlockProps> = ({
  id,
  avatarUrl,
  name = '',
  onClick,
  isSelected,
}) => {
  const [loading, setLoading] = useState(false)

  const currentIcon = loading ? (
    <CircularProgress color="secondary" style={{ width: '24px', height: '24px' }} />
  ) : isSelected ? (
    <ClearIcon />
  ) : (
    <AddIcon />
  )

  const handler = async (event: MouseEvent<HTMLElement>) => {
    setLoading(true)
    await onClick(event.currentTarget.id)
    setLoading(false)
  }

  return (
    <MemberListItemWrapper id={id} onClick={handler} type="button" disabled={loading}>
      <div className="avatar-block">
        <Avatar src={avatarUrl} alt={name || ''} sx={{ height: 25, width: 25 }} />
        <span>{name}</span>
      </div>
      {currentIcon}
    </MemberListItemWrapper>
  )
}
