export const typography = {
  fontFamily: 'Poppins',

  button: {
    fontSize: '12px',
    lineHeight: '18px',
    fontWeight: 500,
  },

  h5: {
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '27px',
  },

  h6: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '21px',
  },

  subtitle1: {
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '18px',
  },

  subtitle2: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '18px',
  },
  subtitle3: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '18px',
  },
  subtitle4: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '18px',
  },
  regular: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '18px',
  },
  caption: {
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '10px',
  },
  time: {
    fontWeight: 400,
    fontSize: '8px',
    lineHeight: '8px',
  },

  small: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
  },
}
