/* eslint-disable max-len */
import { Typography } from '@material-ui/core'
import { FC } from 'react'
import { BTMoreButton } from '../bt-more-button'
import { BTFileIcon } from '../../Brief/bt-brief-upload/bt-files-table/bt-file-icon/bt-file-icon'
import { BTFileItemInformationWrapper } from './bt-file-item-information.presets'
import type { IBTFileItemInformation } from './bt-file-item-information.props'

export const BTFileItemInformation: FC<IBTFileItemInformation> = ({
  fileName = 'No file',
  fileSize,
  fileType,
  buttonItems,
  itemWidth,
}) => {
  return (
    <BTFileItemInformationWrapper
      sx={{ boxShadow: 3 }}
      itemWidth={itemWidth}
      className="file-information"
    >
      <div className="left-block">
        <BTFileIcon fileType={fileType} />
        <Typography variant="subtitle2">{fileName}</Typography>
      </div>
      <div className="right-block">
        <span>{fileSize}</span>
        <BTMoreButton
          items={buttonItems.map(({ title, action }) => ({
            line: title,
            handler: action,
          }))}
        />
      </div>
    </BTFileItemInformationWrapper>
  )
}
