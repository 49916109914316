/* eslint-disable no-unsafe-optional-chaining */
import { v4 as uuid } from 'uuid'
import AddIcon from '@mui/icons-material/Add'
import { Popover } from '@mui/material'
import React, { FC, MouseEvent } from 'react'
import { appendDeliverable, setupDeliverable } from 'redux/SidebarAppsSlice'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { CustomScrollContainer } from '../../../ActivitiesBoard/activity-board/components/append-activity/add-activity-button/bt-add-activity-button.presets'
import { BTDeliverableItem } from '../../../ActivitiesBoard/activity-board/components/append-activity/add-activity-button/components/deliverable-item'
import { BTLoader } from '../../../shared/bt-loader'
import { mapDeliverables } from './bt-append-deliverables.helpers'
import {
  DeliverableTypeFragment,
  useDeliverableTypesQuery,
  useUpdateBriefMutation,
  GetDeliverablesDocument,
} from '../../../../generated/graphql'

export const BTAppendDeliverables: FC = () => {
  const { deliverables } = useAppSelector((state) => state.sidebarApps)
  const { briefId } = useAppSelector((state) => state.briefCreation)
  const { data: deliverableTypesQuery } = useDeliverableTypesQuery()
  const dispatch = useAppDispatch()
  const [updateBrief] = useUpdateBriefMutation()

  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & Element) | null>(null)

  const handleClick = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const appendHandler = async (deliverableType: DeliverableTypeFragment) => {
    const deliverable = {
      name: deliverableType.name,
      id: uuid(),
      relationId: deliverableType.id,
    }

    dispatch(appendDeliverable(deliverable))
    dispatch(setupDeliverable(deliverable))
    await updateBrief({
      variables: { input: { id: briefId, deliverable } },
      update: (cache, { data }) => {
        cache.writeQuery({
          query: GetDeliverablesDocument,
          variables: {
            briefId,
          },
          data: {
            getDeliverables: [...data?.updateBrief.deliverables!],
          },
        })
      },
    })
  }
  if (!deliverableTypesQuery) {
    return <BTLoader />
  }
  const transformedDeliverables = mapDeliverables(
    deliverableTypesQuery.deliverableTypes,
    deliverables
  )
  return (
    <>
      <AddIcon htmlColor="#fff" onClick={handleClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <CustomScrollContainer hideScrollbars={false}>
          {transformedDeliverables.map((deliverableType) => (
            <BTDeliverableItem
              key={deliverableType.id}
              deliverableType={deliverableType}
              onClick={appendHandler}
            />
          ))}
        </CustomScrollContainer>
      </Popover>
    </>
  )
}
