/* eslint-disable import/no-unresolved */
import { getFileAge } from 'helpers/file/fileAge'

const setDayEnding = (day: number) => {
  switch (day) {
    case 1:
      return `${day}st`

    case 2:
      return `${day}nd`

    case 3:
      return `${day}rd`

    default:
      return `${day}th`
  }
}

export const setNotificationTime = (date: string) => {
  const now = new Date()
  const notificationTime = new Date(date)
  const isThisWeek = now.getDay() - notificationTime.getDay() >= 0

  if (isThisWeek) {
    return getFileAge(date)
  }

  const day = notificationTime.getDate()
  const options: Intl.DateTimeFormatOptions = {
    month: 'short',
  }
  const month = notificationTime.toLocaleString('eng', options)

  return `${setDayEnding(day)} ${month}`
}
