import { OrganizationType } from 'generated/graphql'

export const OrganizationTypes: Record<OrganizationType, string> = {
  [OrganizationType.DesignAgency]: 'Design Agency',
  [OrganizationType.Enterprise]: 'Enterprise',
  [OrganizationType.Freelancer]: 'Freelancer',
  [OrganizationType.ScaleUp]: 'Scale Up',
  [OrganizationType.Smb]: 'SMB - non design',
  [OrganizationType.Startup]: 'Startup',
}

interface OrganizationSettingsFormValues {
  name: string
  type: OrganizationType
  range: string
}

export type { OrganizationSettingsFormValues }
