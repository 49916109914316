import { FC, useEffect, useState } from 'react'
import { Button, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { addSingleTab, setupHeaderTabs } from 'redux/HeaderSlice'
import { useNavigate, useParams } from 'react-router-dom'
import getProjectTools from 'utils/getProjectTools'
import { saveHeaderTabs } from 'helpers/header'
import {
  IBTProjectToolsModalProps,
  IBTToolsData,
  IBTHeaderTags,
  IToolsItem,
} from './bt-project-tools-modal.props'
import { BTProjectToolsModalWrapper } from './bt-project-tools-modal.presets'
import { BTModalWindow } from '../shared/bt-modal-window'
import { useAppDispatch, useAppSelector } from '../../redux/store'

export const BTProjectToolsModal: FC<IBTProjectToolsModalProps> = ({ isOpened, handleClose }) => {
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const toolsArray = getProjectTools(id)
  const { tabs } = useAppSelector((state) => state.header)
  const [tools, setTools] = useState<IToolsItem[]>([])

  const navigate = useNavigate()

  const changeChoosedProps = (choosedFlag: boolean, name: string) => {
    setTools((prevState) => [
      ...prevState.map((elem) => (elem.name === name ? { ...elem, isChoosed: choosedFlag } : elem)),
    ])
  }

  const addProjectTools = (name: string, path: string) => {
    dispatch(addSingleTab({ name, path, added: true }))
    changeChoosedProps(true, name)
    saveHeaderTabs('project', id || '', [...tabs, { name, path, added: true }])
  }

  const removeProjectTools = (name: string) => {
    const newTabs = tabs.filter((elem) => elem.name !== name)

    dispatch(setupHeaderTabs(newTabs))
    changeChoosedProps(false, name)
    saveHeaderTabs('project', id || '', newTabs)
    navigate(`/projects/${id}`)
  }

  const checkForActiveTags = (
    headerTags: Array<IBTHeaderTags>,
    projectTools: Array<IBTToolsData>
  ): void => {
    projectTools.forEach((tool: IBTToolsData) => {
      headerTags.forEach((tag: IBTHeaderTags) => {
        if (tag.name === tool.name) {
          changeChoosedProps(true, tool.name)
        }
      })
    })
  }

  useEffect(() => {
    if (tabs && toolsArray) {
      checkForActiveTags(tabs, toolsArray)
    }
  }, [tabs])

  useEffect(() => {
    if (toolsArray) {
      setTools(toolsArray.map(({ name, path }) => ({ name, path, isChoosed: false })))
    }
  }, [id])

  return (
    <BTModalWindow isOpened={isOpened} handleClose={handleClose}>
      <BTProjectToolsModalWrapper>
        <Typography className="title" variant="h5">
          Project Tools
        </Typography>
        <div className="tools-wrapper">
          {tools.map(({ name, path, isChoosed }) => (
            <Button
              sx={{ boxShadow: 2 }}
              endIcon={isChoosed ? <RemoveIcon fontSize="small" /> : <AddIcon fontSize="small" />}
              key={name}
              onClick={() => {
                if (isChoosed) {
                  removeProjectTools(name)
                } else {
                  addProjectTools(name, path)
                }
              }}
            >
              {name}
            </Button>
          ))}
        </div>
        <Button className="done-button" variant="contained" onClick={handleClose}>
          Done
        </Button>
      </BTProjectToolsModalWrapper>
    </BTModalWindow>
  )
}
