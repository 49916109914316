import styled from 'styled-components'
import { colors } from '../../../../theme/Colors'

const FilesTableWrapper = styled.div`
  .files-list {
    max-height: 220px;

    &::-webkit-scrollbar {
      background-color: transparent;
      height: 10px;
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${colors.navyGrey[200]};
      border-radius: 10px;
    }
  }
`

export default FilesTableWrapper
