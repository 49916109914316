import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface Item {
  itemId: string
  name: string
  isNew?: boolean
}
interface Payload<T> {
  value: T
  index?: string
}
type Items = Array<Item>

interface Card {
  title: string
  id: string
  items: Items
}
type Cards = Array<Card>

interface ActivityState {
  cards: Cards
}

const initialState: ActivityState = {
  cards: [
    {
      title: 'Project Activities',
      id: '1',
      items: [],
    },
    {
      title: 'Milestone 1',
      id: '2',
      items: [{ itemId: '2', name: 'item2', isNew: true }],
    },
    { title: 'Milestone 2', id: '3', items: [{ itemId: '3', name: 'item3' }] },
    {
      title: 'Milestone 3',
      id: '4',
      items: [
        { itemId: '4', name: 'item4' },
        { itemId: '5', name: 'item5', isNew: true },
        { itemId: '6', name: 'item6', isNew: true },
        { itemId: '7', name: 'item7', isNew: true },
        { itemId: '8', name: 'item8', isNew: true },
        { itemId: '9', name: 'item9', isNew: true },
      ],
    },
    {
      title: 'Milestone 4',
      id: '5',
      items: [],
    },
    {
      title: 'Milestone 5',
      id: '6',
      items: [],
    },
  ],
}

const ActivityPageApiSLice = createSlice({
  name: 'activityPage',
  initialState,
  reducers: {
    appendActivity(state, { payload }: PayloadAction<Payload<Item>>) {
      if (payload.index) {
        const temp = state.cards.find((card) => card.id === payload.index)
        if (temp) {
          temp.items.push(payload.value)
        }
      }
    },
    updateActivities(state, { payload }: PayloadAction<Payload<Cards>>) {
      state.cards = payload.value
    },
    reorderActivities(state, { payload }: PayloadAction<Payload<Items>>) {
      const { index, value } = payload
      if (index) {
        state.cards.map((card) => {
          if (card.id === index) card.items = value

          return card
        })
      }
    },
    appendCard(state, { payload }: PayloadAction<Payload<Card>>) {
      state.cards.push(payload.value)
    },
  },
})

export const { appendActivity, updateActivities, reorderActivities, appendCard } =
  ActivityPageApiSLice.actions

export default ActivityPageApiSLice.reducer
