/* eslint-disable max-len */
import { FC } from 'react'
import type { IBTFileIconProps } from './bt-file-icon.props'
import {
  Doc,
  Docx,
  Xls,
  Xlsx,
  Ppt,
  Pptx,
  Psd,
  Ai,
  Pdf,
  Txt,
  Jpg,
  Jpeg,
  Png,
  Gif,
  Svg,
  Img,
  Indd,
  Default,
} from './components/BTFileTypeIcons'

export const BTFileIcon: FC<IBTFileIconProps> = ({ fileType }) => {
  switch (fileType) {
    case 'doc':
      return <Doc />
    case 'docx':
      return <Docx />
    case 'xls':
      return <Xls />
    case 'xlsx':
      return <Xlsx />
    case 'ppt':
      return <Ppt />
    case 'pptx':
      return <Pptx />
    case 'psd':
      return <Psd />
    case 'ai':
      return <Ai />
    case 'pdf':
      return <Pdf />
    case 'txt':
      return <Txt />
    case 'jpg':
      return <Jpg />
    case 'jpeg':
      return <Jpeg />
    case 'png':
      return <Png />
    case 'gif':
      return <Gif />
    case 'svg':
      return <Svg />
    case 'img':
      return <Img />
    case 'indd':
      return <Indd />

    default:
      return <Default />
  }
}
