/* eslint-disable default-case */
import { AnswerCommentType, QuestionTypeId } from 'generated/graphql'
import { Pad } from '../components/bt-questions-page-pads/bt-questions-page-pads.props'
import { Option } from '../components/bt-questions-page-select/bt-questions-page-select.props'

export function getAnswerByType(type: QuestionTypeId.DatePicker, answer: AnswerCommentType): Date
export function getAnswerByType(type: QuestionTypeId.LinkField, answer: AnswerCommentType): string

export function getAnswerByType(type: QuestionTypeId.TextArea, answer: AnswerCommentType): string

export function getAnswerByType(
  type: QuestionTypeId.KeywordSelect,
  answer: AnswerCommentType
): Array<Pad>

export function getAnswerByType(type: QuestionTypeId.TextField, answer: AnswerCommentType): string
export function getAnswerByType(type: QuestionTypeId.NumberField, answer: AnswerCommentType): number

export function getAnswerByType(
  type: QuestionTypeId.SingleChoice,
  answer: AnswerCommentType
): Option

export function getAnswerByType(
  type: QuestionTypeId.MultiChoice,
  answer: AnswerCommentType
): Option[]

export function getAnswerByType(
  type: QuestionTypeId,
  answer: AnswerCommentType
): Date | string | undefined | Option | Option[] | Array<Pad> | number {
  switch (type) {
    case QuestionTypeId.DatePicker:
      return new Date(answer.content)
    case QuestionTypeId.TextArea:
      return answer.content
    case QuestionTypeId.TextField:
      return answer.content
    case QuestionTypeId.NumberField:
      return +answer.content
    case QuestionTypeId.LinkField:
      return answer.content
    case QuestionTypeId.SingleChoice:
      return JSON.parse(answer.content || '{}') as Option
    case QuestionTypeId.MultiChoice:
      return JSON.parse(answer.content) as Option[]
    case QuestionTypeId.KeywordSelect:
      return JSON.parse(answer.content) as Array<Pad>
  }
}

export function checkAnswerExists(answer: Partial<AnswerCommentType>) {
  if (!answer.id) {
    return undefined
  }
  return answer as AnswerCommentType
}
