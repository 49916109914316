import styled from '@emotion/styled'
import { colors } from '../../../../../theme/Colors'

const TabsWrapper = styled.div`
  button {
    min-width: fit-content;
    padding: 0;
    background-color: transparent;
    max-height: 48px;
    min-height: 48px;

    span {
      text-transform: capitalize;
      color: ${colors.navyGrey[400]};
      font-family: Poppins;
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0em;
    }
  }

  .MuiTabs-flexContainer {
    gap: 0 40px;
  }

  .Mui-selected {
    span {
      color: ${colors.projectColors.main};
    }
  }

  .MuiTabs-indicator {
    height: 4px;
    border-radius: 10px;
    z-index: 1000;
  }
`

export { TabsWrapper }
