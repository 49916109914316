import { FC } from 'react'
// eslint-disable-next-line max-len
import { BTIconButtons } from '../../../../shared/bt-icon-buttons/bt-icon-buttons'
import { BTAddActivityButton } from './add-activity-button'
import { AppendTitle, Wrapper } from './bt-append-activity.presets'
import type { BTAppendActivityProps } from './bt-append-activity.props'

export const BTAppendActivity: FC<BTAppendActivityProps> = ({ milestone }) => (
  <Wrapper>
    <AppendTitle>Add Activity</AppendTitle>
    <BTIconButtons
      buttonsProps={[
        {
          iconComponent: <BTAddActivityButton milestone={milestone} />,
        },
      ]}
    />
  </Wrapper>
)
