import { OrganizationRole } from 'generated/graphql'

export const getOrganizationMemberRole = (role: string | undefined | null): OrganizationRole => {
  switch (role) {
    case 'member':
      return OrganizationRole.Member
    case 'admin':
      return OrganizationRole.Admin
    case 'external':
      return OrganizationRole.External

    default:
      return OrganizationRole.Member
  }
}
