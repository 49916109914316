/* eslint-disable consistent-return */
/* eslint-disable import/no-unresolved */
/* eslint-disable array-callback-return */
import { NotificationResponse } from 'generated/graphql'
import { IPreparedNotification, INotificationsSortedByAge } from './bt-inbox.props'

const preparedNotification = (
  notificationResponse: NotificationResponse
): IPreparedNotification => {
  return {
    id: notificationResponse.notification.id,
    date: notificationResponse.notification.createdAt,
    isRead: notificationResponse.isRead,
    technical: notificationResponse.notification.isTechnical,
    selectable: !!notificationResponse.notification.purpose,
    data: {
      avatarUrl: notificationResponse.senderData.avatarUrl,
      projectId: notificationResponse.projectData.id,
      projectName: notificationResponse.projectData.name,
      clientName: notificationResponse.projectData.client?.name,
      projectColor: notificationResponse.projectData.color,
      activityId: notificationResponse.activityData.id,
      activityType: notificationResponse.activityData.type,
      activityName: notificationResponse.activityData.name,
      purpose: notificationResponse.notification.purpose,
      notificationText: notificationResponse.notification.notificationMessage,
      message: notificationResponse.notification.personalizedMessage,
      sender: [
        {
          avatarUrl: notificationResponse.senderData.avatarUrl,
          name: notificationResponse.senderData.name,
        },
      ],
      recipients: notificationResponse.membersData,
    },
  }
}

const getIndexesByAge = (notifications: IPreparedNotification[]) => {
  const now = new Date()
  const todayIndexes: number[] = []
  const lastWeekIndexes: number[] = []
  const olderIndexes: number[] = []

  notifications.forEach((notification, index) => {
    const notificationTime = new Date(notification.date)

    if (now.getDay() - notificationTime.getDay() === 0) {
      todayIndexes.push(index)
    } else if (now.getDay() - notificationTime.getDay() > 0) {
      lastWeekIndexes.push(index)
    } else {
      olderIndexes.push(index)
    }
  })

  return { todayIndexes, lastWeekIndexes, olderIndexes }
}

const getNotificationByIndex = (indexes: number[], allNotifications: IPreparedNotification[]) => {
  const result: Array<IPreparedNotification[]> = []

  indexes.map((item) => {
    const arrayForMutation = [...allNotifications]
    const elementTofind = arrayForMutation.splice(item, 1)

    result.push(elementTofind)
  })

  return result.flat()
}

const getNotificationsByAge = (
  todayIndexes: number[],
  lastWeekIndexes: number[],
  olderIndexes: number[],
  notifications: IPreparedNotification[]
) => {
  const today = getNotificationByIndex(todayIndexes, notifications)
  const lastWeek = getNotificationByIndex(lastWeekIndexes, notifications)
  const older = getNotificationByIndex(olderIndexes, notifications)

  return {
    today,
    lastWeek,
    older,
  }
}

const findByNotificationAge = (preparedNotifications: IPreparedNotification[]) => {
  const { todayIndexes, lastWeekIndexes, olderIndexes } = getIndexesByAge(preparedNotifications)

  const result = getNotificationsByAge(
    todayIndexes,
    lastWeekIndexes,
    olderIndexes,
    preparedNotifications
  )

  return result
}

const sortNotificationsByTime = (
  preparedUnread: IPreparedNotification[] | undefined,
  preparedRead: IPreparedNotification[] | undefined
): INotificationsSortedByAge => {
  let result

  if (preparedUnread && preparedRead) {
    const {
      today: unreadToday,
      lastWeek: unreadLastWeek,
      older: unreadOlder,
    } = findByNotificationAge(preparedUnread)
    const { today: readToday, lastWeek: readLastWeek } = findByNotificationAge(preparedRead)
    result = {
      unread: {
        today: unreadToday,
        lastWeek: unreadLastWeek,
        older: unreadOlder,
      },

      read: {
        today: readToday,
        lastWeek: readLastWeek,
      },
    }

    return result
  }

  result = {
    unread: {
      today: [],
      lastWeek: [],
      older: [],
    },
    read: {
      today: [],
      lastWeek: [],
    },
  }

  return result
}

const prepareNotificationsAndSortByAge = (
  unread: NotificationResponse[],
  read: NotificationResponse[]
): INotificationsSortedByAge | undefined => {
  if (unread && read) {
    const preparedUnread = unread.map((notification) => preparedNotification(notification))
    const preparedRead = read.map((notification) => preparedNotification(notification))

    return sortNotificationsByTime(preparedUnread, preparedRead)
  }
}

const preparedAllNotifications = (unread: NotificationResponse[], read: NotificationResponse[]) => {
  if (unread && read) {
    const preparedUnread = unread.map((notification) => preparedNotification(notification))
    const preparedRead = read.map((notification) => preparedNotification(notification))

    return [...preparedUnread, ...preparedRead]
  }
}

const getPureNotificationId = (id: string) => {
  const splittedId = id.split('_')

  if (splittedId && splittedId.length > 1) {
    const pureId = splittedId[1]
    return pureId
  }

  return id
}

export { getPureNotificationId, preparedAllNotifications, prepareNotificationsAndSortByAge }
