import { ICreateProjectFormValues } from 'components/bt-create-project-form'
import { BTAddMemberStep } from 'components/shared/bt-project-add-members-step'
import { IBTAddMemberStepProps } from 'components/shared/bt-project-add-members-step/bt-create-project-add-members-step.props'
import { BTProjectInfoStep } from 'components/shared/bt-project-info-step'
import { FormikProps } from 'formik'
import { Fragment } from 'react'
import { BTTypeOfProjectSettings } from './bt-edit-project-settings.props'

export function getContentByType(
  type: BTTypeOfProjectSettings,
  formik: FormikProps<ICreateProjectFormValues>,
  membersData: IBTAddMemberStepProps['membersData'],
  changeMemberData: IBTAddMemberStepProps['changeMemberData']
) {
  switch (type) {
    case 'assignee':
      return getAssignee(formik, membersData, changeMemberData)
    case 'details':
      return getDetails(formik)
    case 'all':
      return (
        <>
          {getDetails(formik)}
          {getAssignee(formik, membersData, changeMemberData)}
        </>
      )
    default:
      return (
        <>
          {getDetails(formik)}
          {getAssignee(formik, membersData, changeMemberData)}
        </>
      )
  }
}

function getDetails(formik: FormikProps<ICreateProjectFormValues>) {
  return (
    <div className="form-block">
      <BTProjectInfoStep formik={formik} title="Project details" />
    </div>
  )
}

function getAssignee(
  formik: FormikProps<ICreateProjectFormValues>,
  membersData: IBTAddMemberStepProps['membersData'],
  changeMemberData: IBTAddMemberStepProps['changeMemberData']
) {
  return (
    <div className="assignee-block">
      <BTAddMemberStep
        formik={formik}
        title="Add team members"
        membersData={membersData}
        changeMemberData={changeMemberData}
      />
    </div>
  )
}
