import { useMemo } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { getRoutes, transformParamsInUrl } from '../../router/bt-header-routes'
import type { HeaderSetupOutput, PartialRoute, Tab } from './useHeaderSetup.types'

export function useHeaderSetup(): HeaderSetupOutput {
  const params = useParams()
  const location = useLocation()
  const path = location.pathname.split('/')[1]
  const routes = useMemo(() => getRoutes(path), [path, params])
  const isVisible = useMemo(() => !!routes?.name, [routes])

  const formattedRoutes = useMemo(() => {
    if (!routes) return routes
    return (routes.tabs as Array<any>).reduce(
      (acc, tab) => {
        const formattedTab: Tab = {
          ...tab,
          path: transformParamsInUrl(params, tab.path),
        }
        acc.tabs.push(formattedTab)
        return acc
      },
      {
        tabs: [],
        name: routes.name,
        actionButtons: routes.actionButtons,
      } as PartialRoute
    )
  }, [path, params])

  return [formattedRoutes, isVisible]
}
