import { useEffect } from 'react'
import {
  OrganizationsDocument,
  OrganizationsQuery,
  useCreateOrganizationMutation,
} from 'generated/graphql'
import { toastNotifications } from 'helpers/toastNotifications'
import { pushNewOrganization } from 'redux/LoginSlice'
import { useAppDispatch } from 'redux/store'

export function useCreateOrganization() {
  const [createOrganization, { error }] = useCreateOrganizationMutation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (error) {
      toastNotifications(error.message).error()
    }
  }, [error])

  return async (name: string, closeHandler: () => void) => {
    if (name) {
      const response = await createOrganization({
        variables: { input: { name } },
        update: (cache, { data }) => {
          const readedOrganization = cache.readQuery({
            query: OrganizationsDocument,
          }) as OrganizationsQuery

          cache.writeQuery({
            query: OrganizationsDocument,
            data: {
              organizations: [
                ...readedOrganization.organizations,
                data?.createOrganization.organization,
              ],
            },
          })
        },
      })

      if (response.data?.createOrganization.organization) {
        dispatch(pushNewOrganization(response.data.createOrganization.organization))

        toastNotifications('Organization successufully created!').success()
        closeHandler()
      }
    }
  }
}
