import { MilestoneFragment } from 'generated/graphql'

interface BTAddActivityButtonProps {
  milestone?: MilestoneFragment
}

export enum ACTIVITY_TYPES {
  UPLOAD = 'Upload Document',
  COMPOSE = 'Compose Document',
}
export type { BTAddActivityButtonProps }
