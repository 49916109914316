import { useParams } from 'react-router-dom'
import {
  ApprovalStatus,
  useGetBriefQuery,
  GetApprovalsQuery,
  GetApprovalsDocument,
  useChangeApprovalMutation,
} from '../../generated/graphql'

export const useChangeApproveStatus = () => {
  const { id: projectId, activityId, artworkId } = useParams()
  const briefResponse = useGetBriefQuery({ variables: { id: projectId } })
  const briefId = briefResponse.data?.getBrief.id
  const [changeApproval] = useChangeApprovalMutation()

  return async (currentUserId: string) => {
    const id = activityId || artworkId || briefId
    const result = await changeApproval({
      variables: {
        input: {
          id: id!,
          userIds: [currentUserId],
        },
      },

      update(cache) {
        const readedQuery = cache.readQuery({
          query: GetApprovalsDocument,
          variables: {
            id: id!,
          },
        }) as GetApprovalsQuery

        const allApprovals = readedQuery.approvals.approve.concat()

        const approverIndex = allApprovals.findIndex((item) => item.userId === currentUserId)
        const currentApproval = { ...allApprovals[approverIndex] }

        const changeStatus = () => {
          if (currentApproval.status === ApprovalStatus.Requested) {
            return ApprovalStatus.Approved
          }
          return ApprovalStatus.Requested
        }
        const approverNewStatus = {
          ...currentApproval,
          status: changeStatus(),
        }

        const newCache = allApprovals.map((item) => {
          if (item.userId === currentUserId) {
            return approverNewStatus
          }
          return item
        })

        const globalApprove = newCache.every((item) => item.status === ApprovalStatus.Approved)

        cache.writeQuery({
          query: GetApprovalsDocument,
          variables: {
            id: id!,
          },
          data: {
            approvals: {
              ...readedQuery.approvals,
              approve: newCache,
              isApproved: globalApprove,
            },
          },
        })
      },
    })
    return result
  }
}
