/* eslint-disable consistent-return */
/* eslint-disable import/no-unresolved */
import {
  Button,
  TextField,
  Typography,
  TextFieldProps as MuiTextFieldPropsType,
} from '@mui/material'
import { FC } from 'react'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { useCurrentProject } from 'hooks'
import { setCurrentOrganizationId } from 'redux/LoginSlice'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import type { IBTProjectInfoStepProps } from './bt-project-info-step.props'
import { ProjectInfoStepWrapper } from './bt-project-info-step.presets'

import { DateTime } from 'luxon'

export const BTProjectInfoStep: FC<IBTProjectInfoStepProps> = ({
  formik,
  setActiveStep,
  title,
}) => {
  const organizations = useAppSelector((state) => state.login.organizations)
  const { project } = useCurrentProject()
  const dispatch = useAppDispatch()

  const onSelectChange = ({ target }: SelectChangeEvent) => {
    const { value } = target

    if (value) {
      formik.setFieldValue('organization', value)
      dispatch(setCurrentOrganizationId(value))
    }
  }

  return (
    <ProjectInfoStepWrapper>
      <Typography className="title" component="span" variant="h5">
        {title}
      </Typography>
      <div className="inputs-wrapper">
        <TextField
          fullWidth
          id="clientName"
          name="clientName"
          label="Client name"
          value={formik.values.clientName}
          onChange={formik.handleChange}
          autoComplete="off"
          error={formik.touched.clientName && !!formik.errors.clientName}
          helperText={formik.touched.clientName && formik.errors.clientName}
          required
        />
        <TextField
          fullWidth
          id="projectName"
          name="projectName"
          label="Project name"
          value={formik.values.projectName}
          onChange={formik.handleChange}
          autoComplete="off"
          error={formik.touched.projectName && !!formik.errors.projectName}
          helperText={formik.touched.projectName && formik.errors.projectName}
          required
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label="Due date"
            minDate={new Date()}
            value={formik.values.dueDate || DateTime.now()}
            inputFormat="d/MM/yyyy h:m bbb"
            disableMaskedInput={true}
            onChange={(newValue: Date | null) => {
              formik.setFieldValue('dueDate', newValue)
            }}
            InputProps={{
              componentsProps: {
                input: {
                  placeholder: 'mm/dd/yyyy hh:mm (a|p)m',
                  required: true,
                },
              },
            }}
            renderInput={(params: MuiTextFieldPropsType) => (
              <TextField
                fullWidth
                id="dueDate"
                name="dueDate"
                autoComplete="off"
                error={!!formik.errors?.dueDate}
                helperText={formik.errors.dueDate}
                required
                {...params}
              />
            )}
          />
        </LocalizationProvider>
        <FormControl error={formik.touched.organization && !!formik.errors.organization}>
          <InputLabel id="select-label">Choose organization *</InputLabel>
          <Select
            labelId="select-label"
            label="Choose organization *"
            value={formik.values.organization}
            onChange={onSelectChange}
            defaultValue={project?.organization.id}
          >
            {organizations.map((organization) => {
              return (
                <MenuItem key={organization.id} value={organization.id}>
                  {organization.name}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>
      <Button
        variant="contained"
        sx={{ boxShadow: 3 }}
        onClick={setActiveStep}
        disabled={!(formik.isValid && formik.dirty)}
      >
        Next
      </Button>
    </ProjectInfoStepWrapper>
  )
}
