/* eslint-disable no-case-declarations */
import {
  AnswerCommentType,
  AnswerResponseInput,
  AnswerWithQuestion,
  DeliverablesQuestionQuery,
  GetAnswersQuery,
  InputMaybe,
  QuestionMain,
  QuestionTypeId,
} from 'generated/graphql'
import { DeliverableWithQuestionsAndAnswersType } from 'redux/BriefCreationSlice'
import {
  BTPageWithQuestionsHandler,
  BTPageWithQuestionsHandlerExtended,
} from './bt-page-with-questions.props'
import { BTQuestionPads } from './components/bt-questions-page-pads'
import { BTQuestionsPicker } from './components/bt-questions-page-picker'
import { BTQuestionsRange } from './components/bt-questions-page-range'
import {
  BTQuestionsActionsRow,
  BTQuestionsRow,
  BTQuestionsRowWrapper,
} from './components/bt-questions-page-row'
import { BTQuestionsPageSelect } from './components/bt-questions-page-select'
import { BTQuestionsPageText } from './components/bt-questions-page-text'
import type { QuestionTypes } from '../../../types/shared'
import { getAnswerByType, checkAnswerExists } from './helpers'
import { BTQuestionsPageLink } from './components/bt-questions-page-link'

export function getComponentByType(
  question: QuestionMain,
  handler: BTPageWithQuestionsHandlerExtended,
  answer: Partial<AnswerCommentType>
) {
  switch (question.questionTypeId) {
    case QuestionTypeId.DatePicker:
      const datePickerAnswer = checkAnswerExists(answer)

      const datePickerDefault = datePickerAnswer
        ? getAnswerByType(QuestionTypeId.DatePicker, datePickerAnswer)
        : datePickerAnswer

      return (
        <BTQuestionsRowWrapper>
          <BTQuestionsActionsRow title={question.text} />
          <BTQuestionsRow>
            <BTQuestionsPicker
              defaultValue={datePickerDefault}
              handler={handler(answer)}
              question={question}
            />
          </BTQuestionsRow>
        </BTQuestionsRowWrapper>
      )
    case QuestionTypeId.SingleChoice:
      const singleAnswer = checkAnswerExists(answer)
      const singleDefault = singleAnswer
        ? getAnswerByType(QuestionTypeId.SingleChoice, singleAnswer)
        : singleAnswer
      return (
        <BTQuestionsRowWrapper>
          <BTQuestionsActionsRow title={question.text} />
          <BTQuestionsRow>
            <BTQuestionsPageSelect
              options={question.options}
              handler={handler(answer)}
              question={question}
              defaultOption={singleDefault}
            />
          </BTQuestionsRow>
        </BTQuestionsRowWrapper>
      )

    case QuestionTypeId.MultiChoice:
      const multipleAnswer = checkAnswerExists(answer)

      const multipleDefault = multipleAnswer
        ? getAnswerByType(QuestionTypeId.MultiChoice, multipleAnswer)
        : multipleAnswer
      return (
        <BTQuestionsRowWrapper>
          <BTQuestionsActionsRow title={question.text} />
          <BTQuestionsRow>
            <BTQuestionsPageSelect
              options={question.options}
              handler={handler(answer)}
              question={question}
              defaultOption={multipleDefault}
              isMulti
            />
          </BTQuestionsRow>
        </BTQuestionsRowWrapper>
      )

    case QuestionTypeId.Slider:
      return (
        <BTQuestionsRowWrapper>
          <BTQuestionsActionsRow title={question.text} />
          <BTQuestionsRow>
            <BTQuestionsRange handler={handler(answer)} question={question} answer={answer} />
          </BTQuestionsRow>
        </BTQuestionsRowWrapper>
      )
    case QuestionTypeId.KeywordSelect:
      const choiceAnswer = checkAnswerExists(answer)

      const choiceDefault = choiceAnswer
        ? getAnswerByType(QuestionTypeId.KeywordSelect, choiceAnswer)
        : choiceAnswer
      return (
        <BTQuestionsRowWrapper>
          <BTQuestionsActionsRow title={question.text} />
          <BTQuestionPads
            pads={question.options}
            handler={handler(answer)}
            question={question}
            defaultPads={choiceDefault}
          />
        </BTQuestionsRowWrapper>
      )
    case QuestionTypeId.TextField:
      const textFieldAnswer = checkAnswerExists(answer)

      const textFieldDefault = textFieldAnswer
        ? getAnswerByType(QuestionTypeId.TextArea, textFieldAnswer)
        : textFieldAnswer
      return (
        <BTQuestionsRowWrapper>
          <BTQuestionsActionsRow title={question.text} />
          <BTQuestionsPageText
            placeholder="Answer text"
            handler={handler(answer)}
            question={question}
            defaultValue={textFieldDefault}
          />
        </BTQuestionsRowWrapper>
      )
    case QuestionTypeId.NumberField:
      const numberFieldAnswer = checkAnswerExists(answer)

      const numberFieldDefault = numberFieldAnswer
        ? getAnswerByType(QuestionTypeId.NumberField, numberFieldAnswer)
        : numberFieldAnswer
      return (
        <BTQuestionsRowWrapper>
          <BTQuestionsActionsRow title={question.text} />
          <BTQuestionsPageText
            placeholder="Answer text"
            handler={handler(answer)}
            question={question}
            defaultValue={numberFieldDefault}
            type="number"
          />
        </BTQuestionsRowWrapper>
      )
    case QuestionTypeId.LinkField:
      const linkAnswer = checkAnswerExists(answer)

      const linkDefault = linkAnswer
        ? getAnswerByType(QuestionTypeId.LinkField, linkAnswer)
        : linkAnswer

      return (
        <BTQuestionsRowWrapper>
          <BTQuestionsActionsRow title={question.text} />
          <BTQuestionsPageLink
            placeholder="Enter link"
            handler={handler(answer)}
            question={question}
            defaultValue={linkDefault}
          />
        </BTQuestionsRowWrapper>
      )
    case QuestionTypeId.TextArea:
      const textAreaAnswer = checkAnswerExists(answer)

      const textAreaDefault = textAreaAnswer
        ? getAnswerByType(QuestionTypeId.TextArea, textAreaAnswer)
        : textAreaAnswer
      return (
        <BTQuestionsRowWrapper>
          <BTQuestionsActionsRow title={question.text} />
          <BTQuestionsPageText
            placeholder="Answer text"
            handler={handler(answer)}
            question={question}
            defaultValue={textAreaDefault}
          />
        </BTQuestionsRowWrapper>
      )
    default:
      const defaultAnswer = checkAnswerExists(answer)

      const defaultValue = defaultAnswer
        ? getAnswerByType(QuestionTypeId.TextField, defaultAnswer)
        : defaultAnswer
      return (
        <BTQuestionsPageText
          placeholder={question.text}
          handler={handler(answer)}
          question={question}
          defaultValue={defaultValue}
        />
      )
  }
}
export function getCorrectQuestionTypeToDispatch(type: QuestionTypeId, content: QuestionTypes) {
  switch (type) {
    case QuestionTypeId.DatePicker:
      return { date: content }
    case QuestionTypeId.MultiChoice:
      return { data: content }
    case QuestionTypeId.Slider:
      return { number: content }
    case QuestionTypeId.KeywordSelect:
      return { data: content }
    case QuestionTypeId.LinkField:
      return { text: content }
    default:
      return { text: content }
  }
}

export function mapQuestionsAndAnswers(
  deliverableId: string,
  questions: DeliverablesQuestionQuery,
  answersWithQuestions?: GetAnswersQuery,
  existQuestionsWithAnswers?: Array<DeliverableWithQuestionsAndAnswersType>
): Array<{ question: QuestionMain; comment: Partial<AnswerCommentType> }> {
  const mappedQuestions = questions.deliverablesQuestions[0].questions
  const mappedQuestionsAnswers = answersWithQuestions?.answers.mappedDeliverables.find(
    (deliverable) => deliverable.id === deliverableId
  )?.data
  if (!mappedQuestionsAnswers) {
    return mappedQuestions.map((question) => {
      const locallyExistAnswer = existQuestionsWithAnswers?.find((questionAnswer) => {
        return questionAnswer.id === question.id
      })
      return { question, comment: locallyExistAnswer?.answer || {} }
    })
  }
  return mappedQuestions.map((question) => {
    const existAnswer = mappedQuestionsAnswers.find(
      (questionAnswer) => questionAnswer.question.id === question.id
    )
    const locallyExistAnswer = existQuestionsWithAnswers?.find((questionAnswer) => {
      return questionAnswer.id === question.id
    })

    if (existAnswer || locallyExistAnswer) {
      const comment = locallyExistAnswer?.answer || existAnswer?.comment || {}
      return { question, comment }
    }

    return { question, comment: {} }
  })
}
