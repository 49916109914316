import styled from 'styled-components'
import { colors } from '../../../../../theme/Colors'

const BTWrapper = styled.div`
  height: 100%;
`

const BTLastStepWrapper = styled.div`
  margin-top: 100px;
`

const BTLastStepCentered = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
`

const BTSendPurpose = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;

  width: 100%;
  height: 38px;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 12px;
  line-height: 1.5;

  color: ${colors.projectColors.black};

  background: ${colors.projectColors.lightGrey};
  border-radius: 5px;
`

const BTMessage = styled.div`
  margin-top: 10px;

  .message {
    max-height: 140px;

    border: 0.5px solid ${colors.projectColors.iconDarkGrey};
    border-radius: 5px;
    resize: none;

    outline: none;
    padding: 5px;

    font-weight: 300;
    font-size: 10px;
    line-height: 1.7;
    color: ${colors.projectColors.black};

    &::-webkit-scrollbar {
      background-color: transparent;
      height: 10px;
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${colors.navyGrey[200]};
      border-radius: 10px;
    }
  }
`

export { BTLastStepWrapper, BTLastStepCentered, BTSendPurpose, BTMessage, BTWrapper }
