import { propEq } from 'ramda'
import { diffInDays } from 'helpers/date'
import { useAppSelector } from 'redux/store'
import { useNavigate } from 'react-router-dom'
import { FC, useEffect, useState } from 'react'
import { BTCard } from '../../../shared/bt-card'
import { getNavigationLink } from 'helpers/navigation'
import ScrollContainer from 'react-indiana-drag-scroll'
import { DASHBOARD_SEARCH_OPTIONS } from 'helpers/constants'
import { BTMoreButton } from 'components/shared/bt-more-button'
import { DropResult, DragDropContext } from 'react-beautiful-dnd'
import { BTSearchProject } from 'components/shared/bt-search-project'
import type { IBTSummaryLayoutProps } from './bt-summary-layout.props'
import { useArchiveActivityCard } from 'hooks/activity/useArchiveActivityCard'
import { BTGridsWrapper, BTGridColumn, IColumnData } from '../../../shared/bt-grid-column'
import {
  File,
  ActivityType,
  ActivityStatus,
  useAllActivitiesQuery,
  useUpdateActivityMutation,
} from 'generated/graphql'
import {
  BTSearchWrapper,
  BTDashboardWrapper,
  BTSummaryLayoutWrapper,
} from './bt-summary-layout.presets'

const STATUS_LABELS: Record<ActivityStatus, string> = {
  [ActivityStatus.NewActivites]: 'New Activities',
  [ActivityStatus.Todo]: 'To Do',
  [ActivityStatus.Doing]: 'Doing',
  [ActivityStatus.Done]: 'Done',
}

export const BTSummaryLayout: FC<IBTSummaryLayoutProps> = ({ layoutMaxWidth }) => {
  const [searchValue, setSearchValue] = useState<string>('')
  const [options, setOptions] = useState<string>(DASHBOARD_SEARCH_OPTIONS[0].toLowerCase())

  const { data, loading, refetch } = useAllActivitiesQuery()
  const [updateActivity] = useUpdateActivityMutation()
  const [archiveActivity] = useArchiveActivityCard()

  const { cognitoUser } = useAppSelector((state) => state.login)

  const columnData: Array<IColumnData> = Object.entries(STATUS_LABELS).map(([name, title]) => ({
    name,
    title,
  }))

  const navigate = useNavigate()

  const searchResultRender = (elem: IColumnData) => {
    let preparedActivities

    if (searchValue) {
      preparedActivities = data?.activities
        .filter(
          ({ status, archivedAt, type }) =>
            status === elem.name && !archivedAt && type !== ActivityType.Brief
        )
        .filter(({ name, project }) => {
          // activity
          if (options === DASHBOARD_SEARCH_OPTIONS[0].toLowerCase()) {
            return name.toLowerCase().includes(searchValue.toLowerCase())
          }
          // project
          if (options === DASHBOARD_SEARCH_OPTIONS[1].toLowerCase()) {
            return project.name.toLowerCase().includes(searchValue.toLowerCase())
          }
          // client
          if (options === DASHBOARD_SEARCH_OPTIONS[2].toLowerCase()) {
            return project.client?.name.toLowerCase().includes(searchValue.toLowerCase())
          }
          return []
        })
    } else {
      preparedActivities = data?.activities.filter(
        ({ status, archivedAt, type }) =>
          status === elem.name && !archivedAt && type !== ActivityType.Brief
      )
    }

    return (
      <BTGridColumn
        title={elem.title}
        emptyColumnText="No activities"
        name={elem.name}
        key={elem.name}
        isDataLoading={loading}
        cardsData={
          preparedActivities?.map(
            ({
              id,
              name,
              type,
              status,
              project,
              createdAt,
              updatedAt,
              composedDocument,
              uploadedDocument,
            }) => ({
              id,
              card: (
                <BTCard
                  id={id}
                  title={name}
                  labelData={{
                    title: `${project.client?.name || ''}: `,
                    description: project.name,
                    bgColor: project.color,
                  }}
                  assignees={[]}
                  isNew={status === ActivityStatus.NewActivites}
                  bgColor="#fff"
                  isAssigneeStacked
                  key={id}
                  createdDate={createdAt}
                  clickHandler={() =>
                    handleCreate(composedDocument!, uploadedDocument!, type, id, project.id)
                  }
                  iconButtonsProps={[
                    {
                      iconComponent: (
                        <BTMoreButton
                          items={[
                            {
                              line: 'Archive',
                              handler: () => archiveHandler(id),
                            },
                          ]}
                        />
                      ),
                    },
                  ]}
                >
                  <span>
                    Updated {updatedAt ? `${diffInDays(updatedAt)} ` : ''}
                    days ago
                  </span>
                </BTCard>
              ),
            })
          ) || []
        }
      />
    )
  }

  useEffect(() => {
    if (cognitoUser !== undefined) {
      refetch()
    }
  }, [])

  const onDragEnd = async (result: DropResult): Promise<void> => {
    if (result.destination !== undefined) {
      const activity = data?.activities.find(propEq('id', result.draggableId))!

      if (activity && result.destination) {
        const { id } = activity
        const status = result.destination?.droppableId as ActivityStatus

        await updateActivity({
          variables: { input: { id, status } },
          optimisticResponse: {
            updateActivity: { updatedActivities: [{ ...activity, status }] },
          },
        })
      }
    }
  }

  const archiveHandler = async (id: string) => {
    await archiveActivity({ variables: { input: { id, archived: true } } })
  }

  const handleCreate = (
    composedDocument: File,
    uploadedDocument: File,
    type: ActivityType,
    id: string,
    projectId: string
  ) => {
    const navigatePath = getNavigationLink(
      composedDocument,
      uploadedDocument,
      type,
      id,
      projectId,
      true
    )

    navigate(navigatePath)
  }

  return (
    <BTDashboardWrapper>
      <BTSearchWrapper>
        <div className="search-input">
          <BTSearchProject
            options={DASHBOARD_SEARCH_OPTIONS}
            inputHandler={setSearchValue}
            optionsHandler={setOptions}
          />
        </div>
        <div className="border-bottom " />
      </BTSearchWrapper>

      <BTSummaryLayoutWrapper>
        <BTGridsWrapper scrollWidth={layoutMaxWidth}>
          <ScrollContainer
            vertical={false}
            horizontal
            hideScrollbars={false}
            className="horizontal-scroll-block"
          >
            <DragDropContext onDragEnd={onDragEnd}>
              {columnData.map((elem) => searchResultRender(elem))}
            </DragDropContext>
          </ScrollContainer>
        </BTGridsWrapper>
      </BTSummaryLayoutWrapper>
    </BTDashboardWrapper>
  )
}
