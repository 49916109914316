import fileDownload from 'js-file-download'

export const fileDownloadHelper = (
  fileForDownload: Blob | null | undefined,
  fileName: string | undefined
) => {
  if (fileForDownload && fileName) {
    const splittedFileName = fileName.split('.')

    if (splittedFileName.length) {
      const fileExtention = splittedFileName.pop()?.split('_')[0]
      const name = splittedFileName.join('.')

      fileDownload(fileForDownload, `${name}.${fileExtention}`)
    }
  }
}
