import { useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
// eslint-disable-next-line max-len
import { IMemberListItem } from '../../components/shared/bt-project-add-members-step/components/bt-member-list-item'
import {
  ProjectFragment,
  ProjectFragmentDoc,
  useUpdateProjectMutation,
} from '../../generated/graphql'

export function useUpdateProject() {
  const [updateProject] = useUpdateProjectMutation()
  const { id: currentProjectId } = useParams()

  return async (members: IMemberListItem[]) => {
    if (currentProjectId) {
      await updateProject({
        variables: {
          input: {
            id: currentProjectId,
            members: members.map(({ id: userId, projectRole: role }) => ({
              userId,
              role,
            })),
          },
        },
        update: (cache) => {
          const projectFragmentId = cache.identify({
            __typename: 'Project',
            id: currentProjectId,
          })

          const oldProject: ProjectFragment | null = cache.readFragment({
            fragment: ProjectFragmentDoc,
            id: projectFragmentId,
            fragmentName: 'Project',
          })

          if (oldProject) {
            const newFragmentValue = {
              ...oldProject,
              members: [
                ...oldProject.members,
                ...members.map((elem) => ({
                  __typename: 'ProjectMember',
                  id: uuidv4(),
                  role: elem.projectRole,
                  user: {
                    __typename: 'User',
                    id: elem.id,
                    name: elem.name,
                    avatarUrl: elem.avatarUrl,
                  },
                })),
              ],
            }

            cache.writeFragment({
              fragment: ProjectFragmentDoc,
              id: projectFragmentId,
              fragmentName: 'Project',
              data: newFragmentValue,
            })
          }
        },
      })
    }
  }
}
