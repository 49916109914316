import { GlobalAssetType, OrganizationFragment } from '../../../generated/graphql'

export const GlobalAssetFilter = [
  { id: 0, name: 'All', value: GlobalAssetType.Global },
  { id: 1, name: 'Organization', value: GlobalAssetType.Organization },
  { id: 2, name: 'Brief', value: GlobalAssetType.Brief },
  { id: 3, name: 'Activity', value: GlobalAssetType.Activity },
  { id: 4, name: 'Project', value: GlobalAssetType.Project },
]

export const getOrganizationsIds = (organizations: OrganizationFragment[]) => {
  const result: string[] = []
  organizations.forEach((organization) => {
    result.push(organization.id)
  })
  return result
}
