import styled from 'styled-components'
import { colors } from '../../../../../../../theme/Colors'
const BTPadWrapper = styled.div<{ isSelected: boolean }>`
  background: ${(props) => (props.isSelected ? colors.projectColors['main'] : '#ffffff')};
  border-radius: 5px;
  /* width: 109px; */
  flex: 1;
  flex-grow: 3;
  flex-basis: 18%;
  cursor: pointer;
  text-align: center;
  padding: 7px 0;
  color: ${(props) => (props.isSelected ? '#ffffff' : '#4f4f4f')};

  ${({ theme }) => ({ ...theme.typography?.caption })};
`

export { BTPadWrapper }
