/* eslint-disable import/no-unresolved */
import { useParams } from 'react-router-dom'
import { useAppSelector } from 'redux/store'
import { toastNotifications } from 'helpers/toastNotifications'
import { useGetBriefLazyQuery, useSendApproveReminderMutation } from 'generated/graphql'

export const useSendRemind = () => {
  const currentUserId = useAppSelector((state) => state.login.whoami)?.id
  const { id: projectId, activityId } = useParams()
  const [getBrief] = useGetBriefLazyQuery()
  const [sendRemind] = useSendApproveReminderMutation()

  return async (assigneeId: string) => {
    let id
    if (!activityId) {
      const briefResponse = await getBrief({
        variables: {
          id: projectId,
        },
      })
      const briefId = briefResponse.data?.getBrief.id
      id = briefId
    } else {
      id = activityId
    }

    if (id) {
      await sendRemind({
        variables: {
          input: {
            activityId: id,
            personalizedMessage: '',
            purpose: '',
            userIds: [assigneeId],
            data: {
              projectId,
              activityId: id,
              senderId: currentUserId,
              targetUsersIds: [assigneeId],
            },
          },
        },
      })
      toastNotifications('Remind sent').success()
    }
  }
}
