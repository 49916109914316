import { FC } from 'react'
import { PointerEventsSwitcher } from './bt-point-event-switcher.presets'
import { BTPointerEventsSwitcherProps } from './bt-point-event-switcher.props'

export const BTPointerEventsSwitcher: FC<BTPointerEventsSwitcherProps> = ({
  isAuthor,
  children,
}) => {
  return <PointerEventsSwitcher isAuthor={isAuthor}>{children}</PointerEventsSwitcher>
}
