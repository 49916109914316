import { useEffect, useRef, useState, MouseEvent } from 'react'
import styled from 'styled-components'
import { Outlet, useNavigate } from 'react-router-dom'
import { BTChangeCurrentOrganization } from 'components/bt-change-current-organization'
import { useGetNotifications } from 'hooks/notifications/useGetNotifications'
import { useGetUpdateNotifications } from 'hooks/notifications/useGetUpdateNotifications'
import { BTEditProjectSettings } from '../bt-edit-project-settings'
import { BTProjectToolsModal } from '../bt-project-tools-modal'
import { useSetupHeaderData } from '../../hooks/header/useSetupHeaderData'
import { BTSidebar } from '../bt-sidebar'
import { BTHeader } from '../shared/bt-header'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import {
  setHeaderSizes,
  TabsState,
  ActionButtonIconType,
  setHeaderModalsState,
} from '../../redux/HeaderSlice'
import { BTButtonIconByType } from './components/bt-button-icon-by-type'

export const AppWrapper = () => {
  const { tabs, assignees, deadlineTime, actionButtons, title, clientName, modals } =
    useAppSelector((state) => state.header)
  const navigate = useNavigate()
  const headerRef = useRef<HTMLDivElement>(null)
  const dispatch = useAppDispatch()
  const [isChangeOrganizationModalOpen, setIsChangeOrganizationModalOpen] = useState(false)

  const handleOpenEditSettings = () =>
    dispatch(setHeaderModalsState({ ...modals, isEditSettingsModalOpened: true }))

  const handleCloseEditSetting = () =>
    dispatch(setHeaderModalsState({ ...modals, isEditSettingsModalOpened: false }))

  const handleOpenAssigneeSettings = () =>
    dispatch(setHeaderModalsState({ ...modals, isEditAssigneeModalOpened: true }))

  const handleCloseAssigneeSetting = () =>
    dispatch(setHeaderModalsState({ ...modals, isEditAssigneeModalOpened: false }))

  const handleOpenProjectToolsModal = () =>
    dispatch(setHeaderModalsState({ ...modals, isProjectToolsModalOpened: true }))

  const handleCloseProjectToolsModal = () =>
    dispatch(setHeaderModalsState({ ...modals, isProjectToolsModalOpened: false }))

  const openChangeOrganizationModalHandler = () => setIsChangeOrganizationModalOpen(true)
  const closeChangeOrganizationModalHandler = () => setIsChangeOrganizationModalOpen(false)

  useSetupHeaderData()

  useEffect(() => {
    if (headerRef.current) {
      const { height, width } = headerRef.current.getBoundingClientRect()
      dispatch(setHeaderSizes({ width, height }))
    }
  }, [headerRef])

  /** Notifications */
  const getNotifications = useGetNotifications()

  useEffect(() => {
    getNotifications()
  }, [])
  /** */

  /** Socket updated notifications */
  useGetUpdateNotifications()
  /** */

  return (
    <Wrapper>
      <BTSidebar />
      <BTHeader
        refProp={headerRef}
        tabsProps={tabs.map(({ path, name, added = false, type }: TabsState, index: number) => ({
          title: name,
          index,
          actionCallback: () => {
            if (type === 'settings') {
              navigate(path)
            } else {
              navigate(path)
            }
          },
          secondaryCallback: (evt: MouseEvent<SVGSVGElement>) => {
            evt.stopPropagation()
            openChangeOrganizationModalHandler()
          },
          added,
          withIcon: type === 'settings',
        }))}
        title={title}
        clientName={clientName}
        deadlineTime={deadlineTime}
        assignees={assignees}
        iconButtonsProps={
          modals.isHide
            ? undefined
            : actionButtons
                ?.filter((elem) => elem.iconType !== ActionButtonIconType.ADD)
                .map(({ iconType }) => {
                  return {
                    iconComponent: <BTButtonIconByType type={iconType} />,
                    actionCallback: handleOpenEditSettings,
                  }
                })
        }
        addToolsModalHandler={modals.isHide ? undefined : handleOpenProjectToolsModal}
        assigneeModalHandler={handleOpenAssigneeSettings}
      />

      <InnerWrapper>
        <Outlet />
      </InnerWrapper>
      <BTEditProjectSettings
        isOpened={modals.isEditSettingsModalOpened}
        handleModalClose={handleCloseEditSetting}
        type="details"
      />
      <BTEditProjectSettings
        isOpened={modals.isEditAssigneeModalOpened}
        handleModalClose={handleCloseAssigneeSetting}
        type="assignee"
      />
      <BTProjectToolsModal
        isOpened={modals.isProjectToolsModalOpened}
        handleClose={handleCloseProjectToolsModal}
      />
      <BTChangeCurrentOrganization
        isOpened={isChangeOrganizationModalOpen}
        handleModalClose={closeChangeOrganizationModalHandler}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const InnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
