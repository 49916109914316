import { colors } from 'theme/Colors'
import styled from 'styled-components'

const DeadlineLabelWrapper = styled.div`
  display: flex;
  align-items: center;

  background-color: ${colors.blue[100]};
  padding: 4px 8px;
  border-radius: 5px;
  display: flex;
  align-items: center;

  .due {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .due-time {
    margin-left: 8px;

    display: flex;
    align-items: center;

    font-weight: 500;
    color: ${colors.blue[500]};
  }
`

export { DeadlineLabelWrapper }
