/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable import/extensions */
import axios from 'axios'
import { toastMessage } from '../../utils/ToastErrorMessage'

export const fileUpload = async (file: File, signedUrl: string | undefined): Promise<void> => {
  if (file && signedUrl) {
    try {
      const options = { headers: { 'Content-Type': file.type } }
      await axios.put(signedUrl, file, options)
    } catch (err) {
      const error = err as Error
      toastMessage({ message: error.message })
    }
  }
}

export const fileUploadInGlobalAsset = async (
  file: File,
  signedUrl: string | undefined
): Promise<number | undefined> => {
  if (file && signedUrl) {
    try {
      const result = await axios.put(signedUrl, file, {
        headers: { 'Content-Type': '' },
      })
      return result.status
    } catch (err) {
      const error = err as Error
      toastMessage({ message: error.message })
    }
  }
}
