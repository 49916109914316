/* eslint-disable max-len */
/* eslint-disable import/order */
/* eslint-disable prettier/prettier */
/* eslint-disable import/no-unresolved */
import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { colors } from '../../theme/Colors'
import { createGlobalStyle } from 'styled-components'
import { slideInRight, slideInLeft } from 'react-animations'
import poppinsBoldWoff2 from 'assets/fonts/Poppins/Poppins-Bold/poppinsBoldWoff2.woff2'
import poppinsLightWoff2 from 'assets/fonts/Poppins/Poppins-Light/poppinsLightWoff2.woff2'
import poppinsMediumWoff2 from 'assets/fonts/Poppins/Poppins-Medium/poppinsMediumWoff2.woff2'
import poppinsRegularWoff2 from 'assets/fonts/Poppins/Poppins-Regular/poppinsRegularWoff2.woff2'
import poppinsSemiBoldWoff2 from 'assets/fonts/Poppins/Poppins-SemiBold/poppinsSemiBoldWoff2.woff2'

interface ISlideAnimationProps {
  isLastStep: boolean
}

export const GlobalStyles = createGlobalStyle`
@font-face {
  font-family: "Poppins";
  src: url(${poppinsLightWoff2}) format("woff2");
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Poppins";
  src: url(${poppinsRegularWoff2}) format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Poppins";
  src: url(${poppinsMediumWoff2}) format("woff2");
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Poppins";
  src: url(${poppinsSemiBoldWoff2}) format("woff2");
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Poppins";
  src: url(${poppinsBoldWoff2}) format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: block;
}

  body {
    padding: 0;
    margin: 0;
    //This is global for local 
    .global_react_picker_questions_page {
      //Need it because this style adds dynamically
      inset: 10px auto auto -10px !important;
      & .react-datepicker {
        border: none;
        background: #F2F2F2;
        & .react-datepicker__triangle{
          &:before{
            display: none;
          }
          &:after{
            display: none;
          }
        }
      }
      & .react-datepicker__current-month{
        color: #828282;
        padding: 2px 0;
        letter-spacing: -0.24px;
        ${({ theme }: any) => ({ ...theme.typography?.caption })};
      }
      & .react-datepicker__week{
        ${({ theme }: any) => ({ ...theme.typography?.caption })};
        & .react-datepicker__day:not(.react-datepicker__day--outside-month):not(.react-datepicker__day--selected):not(.react-datepicker__day--keyboard-selected){
          color: #828282;
        }
        & .react-datepicker__day--outside-month{
          color: #E0E0E0;
        }
        & .react-datepicker__day--selected {
          background: ${colors.projectColors.main};
          border-radius: 50%;
          color: #fff;
        }
        & .react-datepicker__day--keyboard-selected{
          background: ${colors.projectColors.main};
          border-radius: 50%;
          color: #fff;
        }
      }
      & .react-datepicker__navigation{
        top: 4%;
        &-icon{
          &:before{
          border-color: #ccc;
          border-style: solid;
          border-width: 2px 2px 0px 0px;
          content: "";
          display: block;
          height: 6px;
          position: absolute;
          top: 6px;
          width: 6px;
        }
        }
      }
      & .react-datepicker__day-names{
        padding: 12px;
        & .react-datepicker__day-name{
          color: #4F4F4F;
          ${({ theme }: any) => ({ ...theme.typography?.caption })};
        }
      }
      & .react-datepicker__header{
        border: none;
        background: none;
        padding: 16px 0 0;
      }
    }


    [data-popper-placement] > div {
      margin-left: 45px;

      .MuiClockPicker-root {
          div:nth-of-type(2) {
            button.MuiButtonBase-root:nth-of-type(1) {
              left: 55px;
            }
          }
        }
      }
    }
      
    .MuiCalendarPicker-root {

      .MuiTypography-caption {
        color: ${colors.projectColors.black};
      }

      .MuiPickersDay-root {
        background-color: ${colors.navyGrey[200]};
        transition: all 0.2s ease-in-out;
      }

      .MuiPickersDay-root:hover {
        background-color: ${colors.navyGrey[300]};
      }

      .Mui-disabled {
        opacity: 0.5;
      }

      .Mui-selected {
        background-color: ${colors.projectColors.main};
      }
    }

    .MuiPaper-root {
      background-color: ${colors.white};
    }

    .MuiTabs-root {
      background-color: transparent;

      .MuiTab-root {
        color: ${colors.navyGrey[300]};
      }

      .Mui-selected {
        color: ${colors.blue[500]};
      }
    }

    .MuiTooltip-tooltip {
      background-color: ${colors.white};
      box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 
        0px 3px 4px 0px rgb(0 0 0 / 14%), 
        0px 1px 8px 0px rgb(0 0 0 / 12%);
      margin-left: 2px !important;
    }

    .MuiTooltip-arrow {
      color: ${colors.white};
    }
    .options-list {
      font-size: 10px;
      line-height: 10px;
      color: ${colors.projectColors.black};
    }

    .task-members {
      display: flex;
      flex-direction: column;

      .done-button {
        align-self: center;
        width: 100px;
      }
    }

    .data-loading-wrapper {
      div:nth-child(3) {
        background-color: transparent;
        width: 100px;
        height: 100px;
        padding: 0;
      }
    }

    .MuiTypography-subtitle1 {
      font-family: Poppins;
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
      letter-spacing: 0em;
    }

    .MuiMenuItem-root {
      font-family: Poppins;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      padding: 10px 14px;
    }

    #root {
      .MuiDrawer-paperAnchorDockedLeft {
        &::-webkit-scrollbar {
          background-color: transparent;
          height: 10px;
          width: 10px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: ${colors.navyGrey[200]};
          border-radius: 10px;
        }
      }
    }

    .member-list-item {
      display: flex;
      flex-direction: row;
      padding-left: 10px;
      align-items: center;
      transition: all 0.2s ease-in-out;

      &:hover {
        background-color: #E5F1FB;
      }

      img {
        margin-top: 5px;
        margin-bottom: 5px;
      }

      span {
        padding-left: 10px;
      }
    }

  .centered {
    align-self: center;
  }

  .menu-item {
    text-transform: capitalize;
  }

  textarea,button,a,input,select,body{
    font-family: Poppins, sans-serif !important;
    font-style: normal !important;
  }

  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const fadeInAnimation = keyframes`${slideInLeft}`
const fadeOutAnimation = keyframes`${slideInRight}`

export const SlideAnimation = styled.div`
  animation: 0.5s
    ${({ isLastStep }: ISlideAnimationProps) => (isLastStep ? fadeInAnimation : fadeOutAnimation)};
`
