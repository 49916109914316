import { Box } from '@mui/material'
import styled from '@emotion/styled'
import { colors } from '../../../theme/Colors'

interface IBTFileItemInformationWrapper {
  itemWidth?: number
}

export const BTFileItemInformationWrapper = styled(Box)`
  background-color: ${colors.white};
  padding: 16px;
  border-radius: 5px;
  margin-bottom: 20px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: ${({ itemWidth }: IBTFileItemInformationWrapper) => itemWidth && itemWidth}px;

  .left-block {
    display: flex;

    h6 {
      margin-left: 10px;
      max-width: 300px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .right-block {
    display: flex;
    align-items: center;

    span {
      font-size: 10px;
      line-height: 10px;
      color: ${colors.navyGrey[400]};
      margin-right: 10px;
    }
  }
`
