import { FC } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { Avatar } from '@mui/material'
import ScrollContainer from 'react-indiana-drag-scroll'
import { BTPersonalMessage } from 'components/SidebarApps/bt-activity-send/components/bt-personal-message'
import { BTAgeTitle } from '../../bt-inbox.presets'
import { BTLinkToActivity } from '../bt-link-to-activity'
import { BTInboxMessageProps } from './bt-inbox-message.props'
import {
  BTItemName,
  BTFromItem,
  BTSendPurpose,
  BTFSendersList,
  BTMessageAreaWrapper,
  BTMessageMainWrapper,
  BTMessageMainContentWrapper,
} from './bt-inbox-message.presets'

export const BTInboxMessage: FC<BTInboxMessageProps> = ({
  clientName,
  projectId,
  projectName,
  projectColor,
  activityType,
  activityId,
  activityName,
  senders,
  recipients,
  purpose,
  message,
}) => {
  return (
    <BTMessageMainWrapper>
      <BTMessageMainContentWrapper>
        <BTLinkToActivity
          clientName={clientName}
          projectId={projectId}
          projectName={projectName}
          projectColor={projectColor}
          activityType={activityType}
          activityId={activityId}
          activityName={activityName}
        />
        <BTFSendersList>
          <BTAgeTitle>From:</BTAgeTitle>
          <ScrollContainer
            className="senders-list"
            hideScrollbars={false}
            horizontal={false}
            vertical
          >
            {senders &&
              senders.map((sender) => (
                <BTFromItem key={uuidv4()}>
                  <Avatar alt="name" src={sender.avatarUrl} sx={{ width: 25, height: 25 }} />
                  <BTItemName>{sender.name}</BTItemName>
                </BTFromItem>
              ))}
          </ScrollContainer>
        </BTFSendersList>

        <BTFSendersList>
          <BTAgeTitle>To:</BTAgeTitle>
          <ScrollContainer
            className="senders-list"
            hideScrollbars={false}
            horizontal={false}
            vertical
          >
            {recipients &&
              recipients.map((recipient) => (
                <BTFromItem key={uuidv4()}>
                  <Avatar alt="name" src={recipient.avatarUrl} sx={{ width: 25, height: 25 }} />
                  <BTItemName>{recipient.name}</BTItemName>
                </BTFromItem>
              ))}
          </ScrollContainer>
        </BTFSendersList>

        <BTMessageAreaWrapper>
          <BTAgeTitle>Purpose for sending:</BTAgeTitle>
          <BTSendPurpose>{purpose}</BTSendPurpose>

          {message && <BTPersonalMessage message={message} />}
        </BTMessageAreaWrapper>
      </BTMessageMainContentWrapper>
    </BTMessageMainWrapper>
  )
}
