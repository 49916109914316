import { v4 } from 'uuid'
import { FC } from 'react'
import MenuItem from '@mui/material/MenuItem'
import { BTSearch } from 'components/shared/bt-search'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { BTSearchProjectProps } from './bt-search-project.props'
import { BTSearchProjectWrapper } from './bt-search-project.presets'

export const BTSearchProject: FC<BTSearchProjectProps> = ({
  placeholder = 'Search',
  options,
  inputHandler,
  optionsHandler = () => { },
}) => {
  return (
    <BTSearchProjectWrapper>
      <div className="search-input-wrapper">
        <BTSearch
          iconPosition="start"
          className="search-input"
          placeholder={placeholder}
          changeEvent={inputHandler}
        />
      </div>
      {options && (
        <div className="options">
          by
          <Select
            onChange={(event: SelectChangeEvent) => optionsHandler(event.target.value)}
            defaultValue={options[0].toLowerCase()}
            className="filter"
          >
            {options.map((option) => {
              return (
                <MenuItem key={v4()} value={option.toLowerCase()}>
                  {option}
                </MenuItem>
              )
            })}
          </Select>
        </div>
      )}
    </BTSearchProjectWrapper>
  )
}
