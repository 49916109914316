import { SoonDescription, SoonHeading, SoonWrapper } from './bt-coming-soon.presets'

export const BTComingSoon = () => {
  return (
    <SoonWrapper>
      <SoonHeading>Coming Soon...</SoonHeading>
      <SoonDescription>
        Our developers are currently working on this feature for you.
      </SoonDescription>
    </SoonWrapper>
  )
}
