import { useEffect } from 'react'
import { Deliverable } from 'types/shared'
import { useParams } from 'react-router-dom'
import { useGetBriefQuery } from 'generated/graphql'
import { BTAppendButton } from '../../shared/bt-append-button'
import { BTRemoveDeliverables } from './bt-remove-deliverables'
import { useAppDispatch, useAppSelector } from '../../../redux/store'
import { setupDeliverable, setupDeliverables } from 'redux/SidebarAppsSlice'
import { BTAppsLabel, BTAppsScrollableContainer, BTAppsWrapper } from '../shared'
import { BTAppendDeliverables } from './bt-append-deliverables/bt-append-deliverables'

export const BTBriefDeliverables = () => {
  const { deliverables, currentDeliverable } = useAppSelector((state) => state.sidebarApps)
  const dispatch = useAppDispatch()
  const setCurrentDeliverable = (deliverable: Deliverable) => () => {
    const { id, relationId, name } = deliverable
    const modifiedDeliverable = { id: relationId || id, name }

    dispatch(setupDeliverable(modifiedDeliverable))
  }
  const { id: projectId } = useParams()
  const { data: briefData } = useGetBriefQuery({
    variables: { id: projectId },
  })
  const briefId = briefData?.getBrief?.id

  useEffect(() => {
    if (deliverables.length && !currentDeliverable) {
      dispatch(setupDeliverable(deliverables[0]))
    }

    return () => {
      dispatch(setupDeliverable({ id: '', name: '' }))
      dispatch(setupDeliverables([]))
    }
  }, [])
  return (
    <BTAppsWrapper>
      <BTAppsWrapper>
        <BTAppsLabel>Title</BTAppsLabel>
        <BTAppsScrollableContainer hideScrollbars={false}>
          {deliverables.map((deliverable) => {
            const { name, id } = deliverable
            return (
              <BTAppendButton
                Button={BTRemoveDeliverables}
                index={id}
                title={name}
                bgColor="#F2F2F2"
                titleColor="#333333"
                key={id}
                onClick={setCurrentDeliverable(deliverable)}
                activityId={briefId}
              />
            )
          })}
        </BTAppsScrollableContainer>
      </BTAppsWrapper>

      <BTAppendButton
        index={Date.now().toString()}
        Button={BTAppendDeliverables}
        title="Add Deliverable"
        bgColor="#0066FF"
        activityId={briefId}
      />
    </BTAppsWrapper>
  )
}
