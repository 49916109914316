/* eslint-disable import/no-unresolved */
import styled from 'styled-components'

export const BTRenamableActivityTitleWrapper = styled.div`
  align-self: flex-start;

  .title-block {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .title {
      padding-right: 10px;
      font-size: 18px;
      font-weight: 700;
      line-height: 27px;
    }
  }

  .change-name-block {
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 20px;

    button {
      text-transform: capitalize;
      font-size: 12px;
      line-height: 20px;
      font-weight: 500;
    }

    input {
      padding: 6px 10px;
    }

    p {
      position: absolute;
      top: 35px;
    }
  }
`
