import { Slider } from '@mui/material'
import styled from 'styled-components'
import { colors } from '../../../../../theme/Colors'
const BTQuestionsRangeSlider = styled(Slider)`
  && {
    padding: 64px 0 32px 0;
    margin: 18px 8px 0 8px;
  }
  & .MuiSlider-rail {
    background: #bdbdbd;
  }
  & .MuiSlider-mark {
    background: #bdbdbd;
  }
  & .MuiSlider-valueLabelOpen {
    ${({ theme }) => ({ ...theme.typography?.regular })}
    background: ${colors.projectColors['main']};
    padding: 8px 14px;
    border-radius: 5px;
  }
  & .MuiSlider-markLabel {
    color: #333333;
    top: 70px !important;
    ${({ theme }) => ({ ...theme.typography?.caption })};
  }
  & .MuiSlider-markLabel[data-index='0'] {
    left: 2% !important;
  }
  & .MuiSlider-markLabel[data-index='1'] {
    left: 96% !important;
  }
`
export { BTQuestionsRangeSlider }
