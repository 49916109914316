import { FC } from 'react'
import { useAppSelector } from 'redux/store'
import { valueLabelFormat } from './bt-questions-page-range.helpers'
import { BTQuestionsRangeSlider } from './bt-questions-page-range.presets'
import type { BTQuestionsProps } from '../../bt-page-with-questions.props'

export const BTQuestionsRange: FC<BTQuestionsProps> = ({ question, handler, answer }) => {
  const { currentDeliverable } = useAppSelector((state) => state.sidebarApps)
  const { questionTypeId, id } = question
  const sliderDefaultValue = Number(answer?.content)

  const marks = [
    {
      value: 0,
      label: '$100',
    },
    {
      value: 100,
      label: '$1,000,000',
    },
  ]

  const rangeHandler = (_: Event, content: number | number[]) => {
    handler({
      content,
      type: questionTypeId,
      questionId: id,
      deliverableId: currentDeliverable.relationId || currentDeliverable.id,
    })
  }

  return (
    <BTQuestionsRangeSlider
      aria-label="Restricted values"
      defaultValue={sliderDefaultValue || 0}
      step={0.02}
      valueLabelFormat={valueLabelFormat}
      valueLabelDisplay="auto"
      marks={marks}
      onChange={rangeHandler}
    />
  )
}
