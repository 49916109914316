/* eslint-disable no-unused-expressions */
import * as yup from 'yup'
import { useFormik } from 'formik'
import { Box } from '@material-ui/core'
import { useAppDispatch } from 'redux/store'
import { ReactComponent as Eye } from './eye.svg'
import LoadingButton from '@mui/lab/LoadingButton'
import { useRegister } from 'hooks/user/useRegister'
import { capitalizeFirstLetter } from 'helpers/string'
import { toastNotifications } from 'helpers/toastNotifications'
import { useNavigate, useSearchParams } from 'react-router-dom'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { ITokenPayload, FormValues, HandleSubmit } from './bt-signup.props'
import { userAuthenticated, userOrganizationFromRegisterForm } from 'redux/LoginSlice'
import { BTSignupFormWrapper, BTSignupText, BTSignupTitle } from './bt-signup.presets'
import { useCreateOrganizationMutation, useVerifyInviteTokenLazyQuery } from 'generated/graphql'

const validEmail = yup
  .string()
  .email('Must be a valid email address')
  .required('Required')
  .max(100, '100 characters max')

const validPassword = yup
  .string()
  .required('Required')
  .min(8, 'Must be longer than 8 characters')
  .matches(/.*[0-9].*/, 'Must contain a number')
  .matches(/.*[A-Z].*/, 'Must contain a capital letter')
  .matches(/.*[a-z].*/, 'Must contain a lowercase letter')

export const REGISTER_SELF_VALIDATION_SCHEMA = yup.object({
  fullName: yup.string().required('Required'),
  role: yup.string().required('Required'),
  organisation: yup.string().required('Required'),
  email: validEmail,
  password: validPassword,
})

const initialValues = {
  fullName: '',
  email: '',
  password: '',
  organisation: '',
  role: '',
}

export const BTSignUp: React.FC = () => {
  const dispatch = useAppDispatch()
  const [error, setError] = useState<string | null | boolean>(null)
  const [isValidate, setValidate] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [organizationName, setOrganizationName] = useState<string>('')
  const [userRole, setUserRole] = useState<string>('')
  const [isOrganizationFieldDisabled, setIsOrganizationFieldDisabled] = useState(false)
  const [isRoleFieldDisabled, setIsRoleFieldDisabled] = useState(false)

  const navigate = useNavigate()
  const register = useRegister()
  const [searchParams] = useSearchParams()

  const [createOrganization] = useCreateOrganizationMutation()
  const [verifyTokenQuery] = useVerifyInviteTokenLazyQuery()

  const verifyToken = async (token: string): Promise<ITokenPayload> => {
    const response = await verifyTokenQuery({ variables: { input: { token } } })

    if (response.data) {
      const { payload } = response.data.inviteMemberVerify
      const { organizationId, organizationName: name, role } = JSON.parse(payload)

      return { organizationId, organizationName: name, role }
    }

    return { organizationId: undefined }
  }

  useEffect(() => {
    const tokenFromQuery = searchParams.get('token')

    if (tokenFromQuery) {
      verifyToken(tokenFromQuery).then((data) => {
        localStorage.setItem('invite_organizationId', data.organizationId ?? '')

        if (data.role) {
          localStorage.setItem('invite_role', data?.role)
          setUserRole(data.role ?? '')
        }

        setOrganizationName(data.organizationName ?? '')
      })
    }
  }, [searchParams])

  useEffect(() => {
    if (organizationName !== '') {
      setIsOrganizationFieldDisabled(true)

      formik.setFieldValue('organisation', organizationName)
      formik.validateField('organisation')
    }
  }, [organizationName])

  useEffect(() => {
    if (userRole !== '') {
      setIsRoleFieldDisabled(true)

      formik.setFieldValue('role', capitalizeFirstLetter(userRole))
      formik.validateField('role')
    }
  }, [userRole])

  const onSubmit: HandleSubmit<FormValues> = async ({
    email,
    password,
    fullName,
    organisation,
    role,
  }) => {
    formik.validateForm({ email, password, fullName, organisation, role }).then(async () => {
      try {
        setLoading(true)
        const cognitoUser = await register(email, password, fullName, role)
        dispatch(userAuthenticated({ cognitoUser }))

        if (organizationName === '' && organisation.length) {
          const organizationResponse = await createOrganization({
            variables: { input: { name: organisation } },
          })
          dispatch(
            userOrganizationFromRegisterForm(
              organizationResponse.data?.createOrganization.organization.id
            )
          )
        }
        navigate('/wizard')
        setLoading(false)
      } catch (err: any) {
        setError(err.message ?? err)
      }
    })
  }

  const formik = useFormik<FormValues>({
    initialValues,
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: REGISTER_SELF_VALIDATION_SCHEMA,
    onSubmit,
  })

  const onChange = (value: string, fieldName: string) => {
    formik.setFieldValue(fieldName, value)
    isValidate && formik.validateField(fieldName)
  }

  useEffect(() => {
    const prevEmail = sessionStorage.getItem('email')
    prevEmail && formik.setFieldValue('email', prevEmail)
  }, [])

  useEffect(() => {
    if (error) {
      toastNotifications(error, setError).error()
    }
  }, [error])

  const handleClickShowPassword = () => setShowPassword(!showPassword)

  return (
    <BTSignupFormWrapper>
      <Box className="wrapper" sx={{ boxShadow: 3 }}>
        <BTSignupTitle>Sign Up</BTSignupTitle>
        <form className="inputs-block" autoComplete="off" onSubmit={formik.handleSubmit}>
          <div className="input-block">
            <span>Full Name</span>
            <TextField
              placeholder="John Doe"
              type="text"
              helperText={isValidate && formik.errors.fullName}
              error={isValidate && !!formik.errors.fullName}
              onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
                onChange(target.value, 'fullName')
              }
              autoFocus
              required
              autoComplete="off"
            />
          </div>
          <div className="input-block">
            <span>Email address</span>
            <TextField
              value={formik.values.email}
              placeholder="name@company.com"
              helperText={isValidate && formik.errors.email}
              error={isValidate && !!formik.errors.email}
              onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
                onChange(target.value, 'email')
              }
              required
              autoComplete="off"
            />
          </div>
          <div className="input-block">
            <span>Password</span>
            <TextField
              placeholder="********"
              type={showPassword ? 'text' : 'password'}
              helperText={isValidate && formik.errors.password}
              error={isValidate && !!formik.errors.password}
              required
              onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
                onChange(target.value, 'password')
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      style={{ marginTop: '5px' }}
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      <Eye />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              autoComplete="off"
            />
          </div>
          <div className="input-block">
            <span>Organisation</span>
            <TextField
              value={formik.values.organisation}
              placeholder="Organisation"
              disabled={isOrganizationFieldDisabled}
              helperText={isValidate && formik.errors.organisation}
              error={isValidate && !!formik.errors.organisation}
              onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
                onChange(target.value, 'organisation')
              }
              required
              autoComplete="off"
            />
          </div>
          <div className="input-block">
            <span>Role</span>
            <TextField
              value={formik.values.role}
              placeholder="Role"
              disabled={isRoleFieldDisabled}
              helperText={isValidate && formik.errors.role}
              error={isValidate && !!formik.errors.role}
              onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
                onChange(target.value, 'role')
              }
              required
              autoComplete="off"
            />
          </div>
          <LoadingButton
            type="submit"
            variant="contained"
            fullWidth
            onClick={() => {
              setValidate(true)
              formik.handleSubmit()
            }}
            loading={isLoading}
            disabled={isLoading}
          >
            <BTSignupText>Sign me up!</BTSignupText>
          </LoadingButton>
        </form>
      </Box>
    </BTSignupFormWrapper>
  )
}
