import { TextField } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { ChangeEvent, KeyboardEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useIsUserExistLazyQuery } from '../../../generated/graphql'
import { BTGetStartedWrapper } from './bt-get-started.presets'

const ValidationSchema = Yup.object({
  email: Yup.string().required('Enter your email').email('Wrong email format'),
})

export const BTGetStarted = () => {
  const [userExsistCheck] = useIsUserExistLazyQuery()
  const navigate = useNavigate()

  const onSubmit = async (values: { email: string }) => {
    const isUserExsist = await userExsistCheck({
      variables: { input: { email: values.email } },
    })

    const redirectTo = isUserExsist.data?.isUserExist.isExist ? '/login' : '/register'

    sessionStorage.setItem('email', values.email)

    navigate(redirectTo)
  }

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit,
    validateOnChange: false,
    validationSchema: ValidationSchema,
  })

  const keyDownHandler = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      formik.submitForm()
    }
  }
  const submitHandler = () => {
    formik.handleSubmit()
  }

  return (
    <BTGetStartedWrapper error={!!formik.errors.email}>
      <form onSubmit={formik.handleSubmit}>
        <div className="title">Brief Right.</div>
        <div className="description">Concise briefs that halve your workflow. Brief, Right.</div>
        <div className="input-block">
          <TextField
            error={!!formik.errors.email}
            placeholder="Your email"
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue('email', event.target.value)
            }}
            onKeyDown={(event) => keyDownHandler(event)}
            helperText={formik.errors.email}
            autoComplete="off"
          />
          <LoadingButton
            loading={formik.isSubmitting}
            variant="contained"
            type="button"
            disabled={formik.isSubmitting}
            onClick={submitHandler}
          >
            Get Started
          </LoadingButton>
        </div>
      </form>
    </BTGetStartedWrapper>
  )
}
