import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DeliverableType, DeliverableTypesQuery } from 'generated/graphql'

interface initState {
  deliverables: Pick<DeliverableType, 'name' | 'id'>[]
}
const initialState: initState = {
  deliverables: [],
}

const DeliverablesSlice = createSlice({
  name: 'deliverables',
  initialState,
  reducers: {
    setupDeliverables(state, { payload }: PayloadAction<DeliverableTypesQuery | undefined>) {
      state.deliverables = payload?.deliverableTypes || []
    },
  },
})

export const { setupDeliverables } = DeliverablesSlice.actions
export default DeliverablesSlice.reducer
