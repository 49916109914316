import type { PersistConfig } from 'redux-persist/es/types'
import storage from 'redux-persist/lib/storage'
import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import briefCreationReducer from './BriefCreationSlice'
// eslint-disable-next-line import/no-cycle
import loginReducer from './LoginSlice'
import deliverablesReducer from './DeliverablesSlice'
import activityAPI from './api/ActivityPage'
import sidebarAppsSlice from './SidebarAppsSlice'
import globalSlice from './GlobalSlice'
import postSignupFormSlice from './PostSignupFormSlice'
import { headerSlice } from './HeaderSlice'

const rootReducer = combineReducers({
  briefCreation: briefCreationReducer,
  login: loginReducer,
  activity: activityAPI,
  deliverables: deliverablesReducer,
  sidebarApps: sidebarAppsSlice,
  header: headerSlice.reducer,
  global: globalSlice,
  postSignupForm: postSignupFormSlice,
})

const persistConfig: PersistConfig<any> = {
  key: 'root',
  storage,
  whitelist: [],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof rootReducer>

export type AppDispatch = typeof store.dispatch

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
