import styled from 'styled-components'
import { colors } from 'theme/Colors'

export const BTPaginationButtonWrapper = styled.button`
  display: block;
  margin: auto;

  background: none;
  border: none;
  color: ${colors.navyGrey[500]};
  cursor: pointer;
`
