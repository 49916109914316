import { useEffect } from 'react'
import { getFileType } from 'components/bt-artwork/components/bt-choose-file-modal/bt-choose-file.helper'
import { toastNotifications } from 'helpers/toastNotifications'
import axios from 'axios'
import {
  FileData,
  GetAllActivityAssetsDocument,
  GetAllActivityAssetsQuery,
  GlobalAsset,
  useAddAssetToArtworkMutation,
} from '../../generated/graphql'
import { useRemoveAsset } from './useRemoveAsset'
import { sortFileByVersionsClb } from 'components/bt-artwork/bt-artwork.helper'
import { useReorderAssetsVersion } from './useReorderAssetsVerison'

export function useUploadAsset() {
  const [addAssetToArtwork, { error }] = useAddAssetToArtworkMutation()
  const removeAsset = useRemoveAsset()
  const reorderFileVersions = useReorderAssetsVersion()

  useEffect(() => {
    if (error) {
      toastNotifications(error.message).error()
    }
  }, [error])

  return async (
    file: File,
    version: number,
    artworkId: string,
    pushIndex?: number,
    reorderList?: FileData[]
  ) => {
    const response = await addAssetToArtwork({
      variables: {
        input: {
          artworkId,
          path: '',
          type: getFileType(file.type),
          name: file.name.concat(`_v${version}`),
          version,
        },
      },
      update: (cache, { data }) => {
        const oldAssets = cache.readQuery({
          query: GetAllActivityAssetsDocument,
          variables: { activityId: artworkId ?? '' },
        }) as GetAllActivityAssetsQuery

        const newFile = {
          __typename: 'File',
          name: data?.addAssetToArtwork.file.name,
          getUrl: data?.addAssetToArtwork.file.getUrl,
          type: data?.addAssetToArtwork.file.type,
          version: data?.addAssetToArtwork.file.version,
        }

        const asset = {
          __typename: 'GlobalAsset',
          fileId: data?.addAssetToArtwork.file.id,
          file: {
            ...newFile,
            id: data?.addAssetToArtwork.file.id ?? '',
          },
        } as unknown as GlobalAsset

        const assets = [...oldAssets.getAllActivityAssets] as unknown as GlobalAsset[]

        if (pushIndex) {
          assets.splice(pushIndex - 1, 0, asset)
        } else {
          assets.push(asset)
        }

        assets.sort((first, second) =>
          sortFileByVersionsClb(first as GlobalAsset, second as GlobalAsset)
        ) as unknown as GlobalAsset[]

        cache.writeQuery({
          query: GetAllActivityAssetsDocument,
          variables: { activityId: artworkId ?? '' },
          data: {
            getAllActivityAssets: assets,
          },
        })
      },
    })

    if (response.data?.addAssetToArtwork) {
      const url = response.data.addAssetToArtwork.putUrl

      const uploaded = await axios.put(url, file, {
        headers: { 'Content-Type': file.type },
      })

      if (reorderList && pushIndex) {
        await reorderFileVersions(reorderList, artworkId)
      }

      if (uploaded.status === 200) {
        toastNotifications('File uploaded successfully!').success()
      } else {
        toastNotifications('An error occurred while uploading the file').error()
        await removeAsset(response.data.addAssetToArtwork.file.id, artworkId)
      }
    }
  }
}
