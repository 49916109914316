import styled from '@emotion/styled'
import { Badge } from '@material-ui/core'
import { NavigationItems } from 'router/items'
import { MouseEvent, useMemo, FC, useState } from 'react'
import { FooterSideList } from './bt-sidebar-footer.presets'
import { BTAccountDetailsMenu } from './account-details-menu'
import { BTNavigationLink } from 'components/shared/bt-nav-link'
import { SidebarFooterOptionsStructure } from './bt-sidebar-footer.types'
import { ReactComponent as SettingsIcon } from 'assets/images/settingsIcon.svg'
import { useMeWithOrganizationQuery, useOrganizationsQuery } from 'generated/graphql'
import { Avatar, Popover, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import type { MainButtonsListProps } from '../main-button-list/main-button-list.props'

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px #F0F0F0`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))

const Wrapper = styled.div`
  align-items: center;
  padding: 0 0 20px 20px;
  cursor: pointer;

  & .MuiListItemButton-root {
    padding: 0px 8px;
  }
`

const AvatarWrapper = styled.div`
  padding: 20px 0;
`

export const BTSidebarFooter: FC<MainButtonsListProps> = ({ open }) => {
  const { data } = useOrganizationsQuery()
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null)
  const { data: userData } = useMeWithOrganizationQuery()
  const sidebarMainOptions = useMemo(
    (): SidebarFooterOptionsStructure => [
      [NavigationItems.Settings, SettingsIcon, '/settings/user-details'],
    ],
    []
  )

  const handleClick = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const openPopover = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <FooterSideList open={open}>
        {sidebarMainOptions.map(([name, Component, path]) => (
          <ListItem button key={name} component={BTNavigationLink} to={path}>
            <ListItemIcon>
              <Component />
            </ListItemIcon>
            {open && <ListItemText primary={name} />}
          </ListItem>
        ))}
      </FooterSideList>

      <Wrapper>
        <AvatarWrapper>
          <StyledBadge
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant="dot"
            onClick={handleClick}
          >
            <Avatar alt="Avatar" src={userData?.meWithOrganization?.avatarUrl} />
          </StyledBadge>
        </AvatarWrapper>
        <Popover
          id={id}
          open={openPopover}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
        >
          <BTAccountDetailsMenu data={data} />
        </Popover>
      </Wrapper>
    </>
  )
}
