/* eslint-disable import/no-unresolved */
import { FC } from 'react'
import { Link } from 'react-router-dom'
import { ActivityType } from 'generated/graphql'
import { setLinkUrl } from './bt-link-to-activity.helpers'
import { BTLinkToActivityProps } from './bt-link-to-activity.tsx.props'
import { BTLinkToActivityWrapper } from './bt-link-to-activity.tsx.presets'

export const BTLinkToActivity: FC<BTLinkToActivityProps> = ({
  projectId,
  activityId,
  clientName,
  projectName,
  projectColor,
  activityType,
  activityName,
}) => {
  return (
    <BTLinkToActivityWrapper color={projectColor}>
      <Link to={setLinkUrl(projectId, activityType, activityId)} className="link">
        {activityType === ActivityType.Brief ? (
          <>
            <span className="project">{clientName}:</span>
            <span className="activity">{projectName}</span>
          </>
        ) : (
          <>
            <span className="project">{projectName}:</span>
            <span className="activity">{activityName}</span>
          </>
        )}
      </Link>
    </BTLinkToActivityWrapper>
  )
}
