import styled from 'styled-components'

export const BTProjectSearchWrapper = styled.div`
  height: 28px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  padding-left: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
`

export const BTProjectSearchInput = styled.input`
  margin-left: 15px;
  width: 100%;
  height: 28px;
  border: none;
  background: transparent;

  ${({ theme }) => ({ ...theme.typography.regular })};
  color: rgba(255, 255, 255, 0.75);

  &::placeholder {
    ${({ theme }) => ({ ...theme.typography.regular })};
    color: rgba(255, 255, 255, 0.75);
  }
`
