import styled from '@emotion/styled'
import { colors } from '../../../../../../../theme/Colors'

export const BTAddMembersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 40px;

  .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
  }

  input {
    font-size: 10px;
    font-weight: 300;
    line-height: 12px;
    width: 184px;
    padding: 8px 10px;
  }

  .members-block {
    height: 400px;
  }

  .save-button {
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    width: 240px;
  }

  .MuiFormControl-root {
    background-color: ${colors.white};

    input {
      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${colors.navyGrey[400]};
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${colors.navyGrey[400]};
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${colors.navyGrey[400]};
      }
    }

    svg {
      font-size: 14px;
    }
  }

  fieldset {
    top: 0px;
  }

  label,
  legend {
    display: none;
  }
`
