import ScrollContainer from 'react-indiana-drag-scroll'
import styled from 'styled-components'

const BTPdfScrollableContainer = styled(ScrollContainer)`
  width: 400px;
  padding: 50px;
  height: auto;
  min-height: 300px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  .done-button {
    margin-left: auto;

    button {
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
      text-transform: capitalize;
    }
  }
`

export { BTPdfScrollableContainer }
