import React, { FC } from 'react'
import { useAppSelector } from 'redux/store'
import { BTDatePicker, BTDatePickerIcon } from './bt-questions-page-picker.presets'
import { BTQuestionsPickerProps } from './bt-questions-page-picker.props'

export const BTQuestionsPicker: FC<BTQuestionsPickerProps> = ({
  handler,
  question,
  defaultValue = new Date(),
}) => {
  const { currentDeliverable } = useAppSelector((state) => state.sidebarApps)
  const [value, setValue] = React.useState<Date | null>(defaultValue)
  const [open, setOpen] = React.useState(false)
  const { questionTypeId, id } = question
  const toggleHandler = () => {
    setOpen((prev) => !prev)
  }
  const selectHandler = (date: Date) => {
    setValue(date)
    handler({
      content: date,
      type: questionTypeId,
      questionId: id,
      deliverableId: currentDeliverable.relationId || currentDeliverable.id,
    })
    setOpen((prev) => !prev)
  }
  return (
    <>
      <BTDatePicker
        // has global styles -> assets/styles/globalStyles
        open={open}
        onClickOutside={toggleHandler}
        popperClassName="global_react_picker_questions_page"
        dateFormat="eeee dd MMMM yyyy"
        selected={value}
        onChange={selectHandler}
      />
      <BTDatePickerIcon onClick={toggleHandler} sx={{ pointerEvents: `${open && 'none'}` }} />
    </>
  )
}
