import { LoadingButton } from '@mui/lab'
import { Backdrop, MenuItem, Modal, Select, SelectChangeEvent, TextField } from '@mui/material'
import { useFormik } from 'formik'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useActionAtEnterKey } from 'hooks'
import {
  OrganizationRole,
  useInviteMemberToOrganizationMutation,
} from '../../../../generated/graphql'
import { useAppSelector } from '../../../../redux/store'
import { BTModalWindow } from '../../../shared/bt-modal-window'
import { BTInviteMemberModalWrapper } from './bt-invite-member-modal.presets'
import type { IBTInviteMemberProps, IInviteFormValues, IRole } from './bt-invite-member-modal.props'

const InviteMemberValidationSchema = Yup.object({
  email: Yup.string().required('Email is required').email('Wrong email format'),
  role: Yup.string().oneOf(Object.values(OrganizationRole)),
})

export const BTInviteMemberModal: FC<IBTInviteMemberProps> = ({
  isOpened,
  closeHandler,
  organizationRole,
}) => {
  const organizationId = useAppSelector((state) => state.login.currentOrganizationId)

  const [isInvited, setIsInvited] = useState(false)

  const [inviteMember] = useInviteMemberToOrganizationMutation()

  const onEnterKey = useActionAtEnterKey()

  const onSubmit = async ({ email, role }: IInviteFormValues) => {
    const response = await inviteMember({
      variables: { input: { email, organizationId, role } },
    })

    setIsInvited(response.data?.inviteMember.isInvited || false)
  }

  const isAdmin = organizationRole === OrganizationRole.Admin

  const formik = useFormik<IInviteFormValues>({
    initialValues: {
      email: '',
      role: isAdmin ? OrganizationRole.Admin : OrganizationRole.Member,
    },
    onSubmit,
    validationSchema: InviteMemberValidationSchema,
    isInitialValid: true,
    validateOnChange: false,
  })
  onEnterKey(formik.handleSubmit)

  const onChangeHandler = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target

    formik.setFieldValue('email', value, true)
  }

  const handleClose = () => {
    closeHandler()
    setIsInvited(false)
  }

  const onRoleChangeHandler = ({ target }: SelectChangeEvent<OrganizationRole>) => {
    if (target.value) {
      formik.setFieldValue('role', target.value)
    }
  }

  const roles: IRole[] = [
    { roleName: 'Member', role: OrganizationRole.Member },
    { roleName: 'External', role: OrganizationRole.External },
  ]

  if (organizationRole && organizationRole === OrganizationRole.Admin) {
    roles.push({ roleName: 'Admin', role: OrganizationRole.Admin })
  }

  useEffect(() => {
    formik.setFieldValue('role', OrganizationRole.Member)
  }, [])

  useEffect(() => {
    if (!isOpened) {
      formik.resetForm({ values: { email: '', role: OrganizationRole.Member } })
    }
  }, [isOpened])

  return (
    <Modal
      open={isOpened}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropComponent={Backdrop}
      className="modal"
    >
      <BTInviteMemberModalWrapper>
        {!isInvited ? (
          <>
            <span className="title">Invite Team Member</span>
            <div className="input-block">
              <span className="label">Email</span>
              <TextField
                type="email"
                name="eamil"
                id="email"
                error={!!formik.errors.email}
                helperText={formik.errors.email}
                onChange={onChangeHandler}
                placeholder="name@company.com"
              />
            </div>
            <div className="input-block">
              <span className="label">Role</span>
              <Select
                fullWidth
                className="select"
                value={formik.values.role}
                onChange={onRoleChangeHandler}
              >
                {roles.map(({ roleName, role }) => (
                  <MenuItem value={role} className="menu-item">
                    {roleName}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <LoadingButton
              disabled={(formik.dirty && !formik.isValid) || formik.values.email === ''}
              fullWidth
              variant="contained"
              onClick={() => formik.handleSubmit()}
              loading={formik.isSubmitting}
            >
              Send Invite
            </LoadingButton>
          </>
        ) : (
          <>
            <span className="title">Invite Sent!</span>
            <div className="email">{formik.values.email}</div>
            <div className="description">
              User has been invited to your workspace. They will receive an email with instructions
              to sign up.
            </div>
          </>
        )}
      </BTInviteMemberModalWrapper>
    </Modal>
  )
}
