import { feedbackSortCallback } from '../../utils/sorting/sorting'
import { useAppSelector } from '../../redux/store'
import {
  FeedbackMessageType,
  GetAllFeedbackMessagesDocument,
  GetAllFeedbackMessagesQuery,
  useCreateFeedbackMessageMutation,
} from '../../generated/graphql'

export function useAddFeedback() {
  const user = useAppSelector((state) => state.login.whoami)
  const [addFeedback] = useCreateFeedbackMessageMutation()
  const { assetId } = useAppSelector((state) => state.sidebarApps)

  return async (activityId: string, value: string, feedbackId: string) => {
    const feedbackMessage = {
      __typename: 'FeedbackMessage',
      user: {
        id: user.id,
        name: user.name,
        avatarUrl: user.avatarUrl,
      },
      createdAt: new Date(),
      replyedComments: [],
      pinned: false,
      feedbackId,
      type: FeedbackMessageType.Parent,
      members: [],
      userId: user.id,
      assetId: assetId ?? null,
      position: null,
    }

    const assetIdValue = assetId === '' ? undefined : assetId

    await addFeedback({
      variables: {
        input: {
          content: value,
          userId: user.id,
          activityId,
          assetId: assetIdValue,
        },
      },
      update: (cache, { data: newMessage }) => {
        const messages = cache.readQuery({
          query: GetAllFeedbackMessagesDocument,
          variables: {
            input: { feedbackId: assetId ? undefined : feedbackId, assetId: assetIdValue },
          },
        }) as GetAllFeedbackMessagesQuery

        const oldMessages =
          messages === null ? [] : messages.getFeedbackMessagesByFeedbackId.messages

        cache.writeQuery({
          query: GetAllFeedbackMessagesDocument,
          variables: {
            input: { feedbackId: assetId ? undefined : feedbackId, assetId: assetIdValue },
          },
          data: {
            getFeedbackMessagesByFeedbackId: {
              __typename: 'FeedbackMessageResponse',
              messages: [
                ...oldMessages,
                {
                  ...feedbackMessage,
                  feedbackMessageId: newMessage?.addFeedback.feedbackMessageId || '',
                  content: value,
                  messageId: newMessage?.addFeedback.message.id,
                },
              ].sort((first, second) =>
                feedbackSortCallback(first.pinned, second.pinned, first.createdAt, second.createdAt)
              ),
            },
          },
        })
      },
    })
  }
}
