/* eslint-disable import/no-unresolved */
import {
  BTSendMachineStepsCounts,
  useMachineContext,
} from 'services/StateMachines/bt-apps-send/bt-apps-send'
import { MobileStepper } from '@mui/material'
import { BTSendStepperWrapper } from './bt-activity-send-wrapper.presets'

export const BTActivitySendWrapper = () => {
  const [current] = useMachineContext()

  const Component = current?.context.currentView.Component
  const step = current?.context.currentView.step

  return (
    <>
      {Component}
      <BTSendStepperWrapper>
        <MobileStepper
          variant="dots"
          steps={BTSendMachineStepsCounts - 1}
          position="static"
          activeStep={step}
          nextButton={
            null
          } /** * @desc Empty handler only for working stepper without prev button callback */
          backButton={
            null
          } /** * @desc Empty handler only for working stepper without prev button callback */
        />
      </BTSendStepperWrapper>
    </>
  )
}
