import styled from '@emotion/styled'
import { colors } from 'theme/Colors'
import { TOP_PADDING_OFFSET } from './bt-file-preview.constants'

export const BTFilePreviewWrapper = styled.div`
  display: flex;
  padding-top: ${TOP_PADDING_OFFSET}px;

  .scroll-wrapper {
    max-height: 440px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .react-pdf__Document {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .image {
    height: auto;
    width: 80px;
  }

  .current-page {
    border: 1px solid ${colors.projectColors.main} !important;
  }

  .page {
    cursor: pointer;
    border: 1px solid ${colors.white};
  }
`
