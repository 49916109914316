/* eslint-disable max-len */
import { MobileStepper } from '@mui/material'
import { useFormik, FormikProps } from 'formik'
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { useCreateMilestonesMutation } from '../../generated/graphql'
import { useAppDispatch } from '../../redux/store'
import { hideHeader } from '../../redux/HeaderSlice'
import type { ICreateProjectFormValues } from './bt-create-project-form.props'
import { CreateFormWrapper } from './bt-create-project-form.presets'
import { SlideAnimation } from '../../assets/styles/globalStyles'
import { BTProjectInfoStep } from '../shared/bt-project-info-step'
import { BTAddMemberStep } from '../shared/bt-project-add-members-step'
import type { IMemberListItem } from '../shared/bt-project-add-members-step/components/bt-member-list-item'
import { useCreateProject } from '../../hooks/project/useCreateProject'

export const CreateProjectSchema = Yup.object().shape({
  projectName: Yup.string().required('Project name is required!'),
  clientName: Yup.string().required('Client name is required!'),
  dueDate: Yup.date()
    .nullable()
    .required('Due date is required!')
    .typeError('Due date has wrong format!'),
  organization: Yup.string().required('Choose organization is required!'),
})

interface IActiveStep {
  title: string
}

interface IAssigneesData {
  members: Array<IMemberListItem>
  setMembers: Dispatch<SetStateAction<Array<IMemberListItem>>>
}

const getActiveStepComponent = (
  activeStep: number,
  formik: FormikProps<ICreateProjectFormValues>,
  actions: Array<() => void>,
  titles: Array<IActiveStep>,
  assigneesData: IAssigneesData
) => {
  switch (activeStep) {
    case 0: {
      return (
        <BTProjectInfoStep
          formik={formik}
          setActiveStep={actions[activeStep]}
          title={titles[activeStep].title}
        />
      )
    }
    case 1: {
      return (
        <BTAddMemberStep
          formik={formik}
          setActiveStep={actions[activeStep]}
          title={titles[activeStep].title}
          membersData={assigneesData}
          isCreatedMemberImportant
        />
      )
    }
    default: {
      return <>Something went wrong!(</>
    }
  }
}

export const BTCreateProjectForm: FC = () => {
  const [activeStep, setActiveStep] = useState<number>(0)
  const [members, setMembers] = useState<Array<IMemberListItem>>([])
  const dispatch = useAppDispatch()
  const [createProject] = useCreateProject()
  const [createMilestone] = useCreateMilestonesMutation()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(hideHeader())
  }, [])

  const onSubmitHandler = async ({
    clientName,
    dueDate,
    projectName,
    organization,
  }: ICreateProjectFormValues) => {
    if (organization) {
      const newProject = await createProject({
        variables: {
          input: {
            name: projectName,
            clientName,
            dueDate,
            members: members.map(({ id, projectRole }) => ({
              role: projectRole,
              userId: id,
            })),
            organizationId: organization,
          },
        },
      })
      if (newProject.data?.createProject.project.id) {
        await createMilestone({
          variables: {
            id: newProject.data?.createProject.project.id,
            name: 'Project Activities',
          },
        })
      }
      navigate(`/projects/${newProject.data?.createProject.project.id}`)
    }
  }

  const formik = useFormik<ICreateProjectFormValues>({
    initialValues: {
      projectName: '',
      clientName: '',
      dueDate: '',
      organization: '',
    },
    validationSchema: CreateProjectSchema,
    onSubmit: onSubmitHandler,
  })

  const handleNext = useCallback((): void => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }, [setActiveStep])

  const handleBack = useCallback((): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }, [activeStep])

  const stepTitles: Array<IActiveStep> = [
    { title: 'Project details' },
    { title: 'Add team members' },
  ]

  const actions: Array<() => void> = [handleNext, handleBack]

  const isLastStep = activeStep === stepTitles.length - 1

  return (
    <CreateFormWrapper sx={{ boxShadow: 3 }}>
      <form onSubmit={formik.handleSubmit}>
        <SlideAnimation isLastStep={isLastStep}>
          {getActiveStepComponent(activeStep, formik, actions, stepTitles, {
            members,
            setMembers,
          })}
        </SlideAnimation>
        <MobileStepper
          variant="dots"
          steps={stepTitles.length}
          position="static"
          activeStep={activeStep}
          nextButton={null} // Empty handler only for working stepper without next button callback
          backButton={null} // Empty handler only for working stepper without prev button callback
        />
      </form>
    </CreateFormWrapper>
  )
}
