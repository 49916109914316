import { styled as MUIStyled } from '@mui/material/styles'
import { Badge } from '@mui/material'
import styled from 'styled-components'
import List from '@mui/material/List'
import { colors } from '../../../../theme/Colors'
import type { SideListProps } from '../main-button-list/main-button-list.props'

export const FooterSideList = MUIStyled(List)<SideListProps>`
    ${({ theme, open }) => ({
      '& .MuiListItem-root': {
        justifyContent: 'center',
        padding: '0',
      },
      '& .MuiListItemIcon-root': {
        justifyContent: 'center',
        minWidth: '32px',
        minHeight: '32px',
        alignItems: 'center',
        margin: open && '0 0 0 20px',
      },
      '& .MuiListItemText-root': {
        color: '#FFFFFF',
        padding: '4px 0 4px 10px',
      },
      '& .active .MuiListItemIcon-root': {
        background: colors.projectColors.activeTab,
        borderRadius: open ? '5px 0 0 5px' : '5px',
        fontWeight: 'bold',
      },
      '& .active .MuiListItemText-root': {
        background: colors.projectColors.activeTab,
        borderRadius: '0 5px 5px 0',
        marginRight: '20px',
        '& span': {
          fontWeight: 'bold',
        },
      },
    })}
`

export const StyledBadge = MUIStyled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))

export const Wrapper = styled.div`
  align-items: center;
  padding: 0 0 20px 20px;
  cursor: pointer;

  & .MuiListItemButton-root {
    padding: 0px 8px;
  }
`

export const AvatarWrapper = styled.div`
  padding: 20px 0;
`
