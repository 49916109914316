import styled from '@emotion/styled'
import { colors } from 'theme/Colors'

export const BTConfirmationModalWindowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;

  .title {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    text-align: center;
  }

  .description {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    width: 400px;
  }

  .buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
      text-transform: capitalize;
      font-size: 12px;
      font-weight: 700;
      line-height: 18px;
      color: ${colors.white};
      width: 120px;
    }
  }
`
