import { colors } from 'theme/Colors'
import styled from 'styled-components'

const BTActivityBoardWrapper = styled.div`
  height: 100%;
  width: 100%;
`

const Root = styled.div`
  display: flex;

  .horizontal-scroll-container {
    padding-top: 15px;
    padding-bottom: 30px;
    padding-left: 90px;

    width: 81%;

    display: flex;
    gap: 15px;
    overflow-x: scroll;

    &::-webkit-scrollbar {
      border-radius: 10px;
      height: 10px;
      background-color: ${colors.navyGrey[300]};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${colors.navyGrey[200]};
      border-radius: 10px;
    }
  }
`

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 210px;
`

const ColumnWrapper = styled.div`
  background: rgba(224, 224, 224, 0.5);
  border-radius: 5px;
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 10px;
  flex: 1;
  gap: 15px;
`

const Heading = styled.h1`
  color: #333333;
  align-self: flex-start;
  margin: 0;
  padding: 0;
  ${({ theme }) => ({ ...theme.typography?.subtitle1 })};
`

export { Root, Heading, ItemWrapper, ColumnWrapper, BTActivityBoardWrapper }
