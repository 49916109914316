import styled from '@emotion/styled'
import { colors } from 'theme/Colors'

export const BTTaskCreateFormWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 290px;

  .title {
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
  }

  form {
    width: 100%;
  }

  .top-block {
    padding-bottom: 15px;
    border-bottom: 1px solid ${colors.navyGrey[500]};
    font-size: 12px;
    line-height: 12px;

    div {
      min-width: 290px;
    }

    .Mui-focused {
      .MuiOutlinedInput-root {
        border: 1px dashed ${colors.white};
      }
    }

    .MuiFormHelperText-root {
      position: absolute;
      top: 26px;
    }

    .search {
      width: 198px;

      .MuiOutlinedInput-root {
        padding: 5px;
        font-size: 12px;
        line-height: 12px;
        color: ${colors.navyGrey[500]};
        border-radius: 5px;
        padding-right: 10px;

        .MuiAutocomplete-endAdornment {
          position: static;
        }

        svg {
          font-size: 10px;
        }

        input {
          padding: 0;
        }
      }
    }

    .MuiOutlinedInput-root:hover {
      border-radius: 5px;
    }

    .Mui-focused {
      border-radius: 5px;
    }
  }

  .main-block {
    display: flex;
    align-items: center;
    flex-direction: column;

    .created-date {
      padding-top: 15px;
      padding-bottom: 15px;
      font-size: 10px;
      line-height: 10px;
      color: ${colors.navyGrey[500]};
      text-align: left;
      width: 100%;
    }

    .input-block {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      width: 100%;

      .error {
        font-size: 10px;
        line-height: 10px;
        color: ${colors.red[500]};
        padding-top: 5px;
      }

      .input-label {
        font-size: 10px;
        line-height: 10px;
        padding-bottom: 5px;
      }

      .MuiFormControl-root {
        width: 100%;
      }

      .MuiInputBase-multiline {
        padding: 6px 5px 6px 5px;

        textarea {
          padding: 0;
          border: none;

          &::-webkit-scrollbar {
            border-radius: 15px;
            height: 10px;
            width: 12px;
            background-color: ${colors.navyGrey[300]};
            cursor: pointer;
          }

          &::-webkit-scrollbar-thumb {
            background-color: ${colors.navyGrey[200]};
            border-radius: 10px;
            cursor: pointer;
          }
        }
      }

      input,
      textarea {
        padding: 5px;
        font-size: 12px;
        line-height: 12px;
        color: ${colors.navyGrey[500]};
        border-radius: 5px;
      }
    }

    .MuiFormControl-root {
      margin-bottom: 15px;
    }

    .Mui-focused {
      input {
        border: none;
      }
    }

    .MuiInputLabel-outlined {
      color: ${colors.projectColors.black};
      font-size: 10px;
      line-height: 10px;
    }
  }

  .assignee-block {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;

    .add-assignee-block {
      display: flex;
      align-items: center;
      flex-direction: row;
    }

    .title {
      margin-bottom: 10px;
    }

    .MuiOutlinedInput-root {
      padding: 0;
    }
  }

  .date-block {
    display: flex;
    flex-direction: column;
    align-self: flex-start;

    fieldset {
      border: none;
    }

    .MuiFormControl-root {
      margin-bottom: 0;

      .MuiInputAdornment-positionEnd {
        margin-left: 0;
        margin-right: 8px;
      }

      .Mui-error {
        color: ${colors.red[500]} !important;
      }

      .root-picker-wrapper {
        display: flex;
        align-items: center;
        color: ${colors.projectColors.main};

        .open-picker-button {
          padding: 0;

          svg {
            height: 14px;
            width: 14px;
            color: ${colors.projectColors.main};
          }
        }

        input {
          border: none;
          font-size: 10px;
          line-height: 10px;
          padding: 5px 0 5px 5px;
          text-align: start;
          order: 2;
        }
      }
    }
  }

  .basement-block {
    border-top: 1px solid ${colors.navyGrey[500]};

    .scroll-wrapper {
      max-height: 70px;
      padding-top: 5px;

      &::-webkit-scrollbar {
        border-radius: 15px;
        height: 10px;
        width: 12px;
        background-color: ${colors.navyGrey[300]};
        cursor: pointer;
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${colors.navyGrey[200]};
        border-radius: 10px;
        cursor: pointer;
      }
    }

    .title,
    .checklist-item {
      font-size: 10px;
      line-height: 10px;
      color: ${colors.projectColors.black};
    }

    .empty-checklist-item {
      display: flex;

      .MuiFormControl-root {
        margin-left: 10px;
      }

      input {
        font-size: 10px;
        line-height: 10px;
        color: ${colors.projectColors.black};
        padding: 5px;
      }

      fieldset {
        border: none;
      }

      .Mui-focused {
        fieldset {
          border: 1px solid ${colors.projectColors.main} !important;
        }
      }

      span {
        color: ${colors.projectColors.black};
        padding: 0;
      }
    }

    .checklist-item {
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 10px;
        line-height: 10px;
        color: ${colors.projectColors.black};
        padding: 0;
      }

      .checklist-item-label {
        padding-left: 5px;
      }

      .remove-button {
        color: ${colors.projectColors.main};
        transition: all 0.2s ease-in-out;

        &:hover {
          color: ${colors.blue[400]};
        }
      }
    }
  }

  .buttons {
    margin-top: 5px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;

    .submit-button {
      float: right;
    }

    button {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-transform: capitalize;
    }
  }
`
