/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
import { Document, Page, pdfjs } from 'react-pdf'
import { FC, useState, useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ScrollContainer from 'react-indiana-drag-scroll'
import { fileDownloadHelper } from 'helpers/file/fileDownload'
import { BTFileViewProps } from './bt-file-view.props'
import { FileViewWrapper } from './bt-file-view.presets'
import { BTNextPageButton } from '../../../../shared/bt-next-page-button'
import { toastNotifications } from '../../../../../helpers/toastNotifications'
import { BTFilesTableWithoutScroll } from '../../../bt-brief-upload/bt-files-table/bt-files-table'
import { BTRenamableActivityTitle } from '../../../../shared/bt-renamed-activity-title'
import {
  useGetBriefQuery,
  GetActivityByIdQuery,
  useDeleteAssetMutation,
  useDeleteBriefMutation,
  useGetActivityByIdQuery,
  GetActivityByIdDocument,
  useRemoveUploadedDocumentMutation,
} from '../../../../../generated/graphql'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

export const BTFileView: FC<BTFileViewProps> = ({
  file,
  fileId,
  assetId,
  numPages,
  pageNumber,
  pagesHandler,
  onLoadSuccess,
  fileForDownload,
}) => {
  const navigate = useNavigate()
  const [removeUploadDocument] = useRemoveUploadedDocumentMutation()
  const [deleteBrief] = useDeleteBriefMutation()
  const [hasError, setHasError] = useState<boolean | string | null>(null)
  const { activityId } = useParams()
  const path = useLocation().pathname
  const { id: projectId } = useParams()
  const [deleteAssetMutation] = useDeleteAssetMutation({
    variables: {
      input: assetId!,
    },

    update(cache) {
      const normalizedId = cache.identify({
        id: activityId,
      })
      cache.evict({ id: normalizedId })
      cache.gc()
    },
  })
  const { data: briefData } = useGetBriefQuery({
    variables: { id: projectId },
  })
  const { data: activityData } = useGetActivityByIdQuery({
    variables: {
      input: activityId!,
    },
  })

  const uploadDocumentId = activityData?.getActivityById.uploadedDocument?.id

  const replaceHandler = () => {
    activityId
      ? navigate(path.replace(`/${activityId}`, `/${activityId}/create/upload`))
      : navigate(path.replace('/brief', '/brief/create/upload-document'))
  }

  const deleteHandler = async () => {
    try {
      if (activityId && uploadDocumentId) {
        await removeUploadDocument({
          variables: {
            input: {
              activityId,
              uploadDocumentId,
            },
          },
          errorPolicy: 'ignore',
          update: (cache) => {
            const readedQuery = cache.readQuery({
              query: GetActivityByIdDocument,
              variables: {
                input: activityId,
              },
            }) as GetActivityByIdQuery

            cache.writeQuery({
              query: GetActivityByIdDocument,
              variables: {
                input: activityId,
              },
              data: {
                getActivityById: {
                  ...readedQuery,
                  uploadedDocument: null,
                },
              },
            })
          },
        })
        navigate(path.replace(`/${activityId}`, `/${activityId}/create`))
      } else if (briefData) {
        await deleteBrief({
          variables: {
            id: briefData?.getBrief.id,
          },

          update(cache) {
            const normalizedId = cache.identify({
              id: briefData?.getBrief.id,
              __typename: 'Brief',
            })
            cache.evict({ id: normalizedId })
            cache.gc()
          },
        })

        navigate(path.replace('/brief', '/brief/create'))
      }
    } catch (err) {
      const error = err as Error
      setHasError(error.message)
    }
  }

  const deleteAssetHandler = async () => {
    await deleteAssetMutation()
    navigate(path.replace(`global-assets/asset/${fileId}`, 'global-assets'))
  }

  const downloadHandler = () => {
    if (file?.length) {
      fileDownloadHelper(fileForDownload, file[0].name)
    }
  }

  const buttonItems = [
    {
      line: 'Download',
      handler: downloadHandler,
    },
    {
      line: 'Replace',
      handler: replaceHandler,
    },
    {
      line: 'Delete',
      handler: deleteHandler,
    },
  ]

  const assetButtonItems = [
    {
      line: 'Download',
      handler: downloadHandler,
    },
    {
      line: 'Delete',
      handler: deleteAssetHandler,
    },
  ]

  const checkForPrevPageButton = numPages && numPages > 1 && pageNumber > 1
  const checkForNextPageButton = numPages && numPages > 1 && pageNumber < numPages

  const setName = () => {
    if (!activityId && path.includes('global-assets')) {
      return <h2>Asset</h2>
    }
    if (!activityId) {
      return <h2>Project Brief</h2>
    }

    return (
      <div className="document-title">
        <BTRenamableActivityTitle activityId={activityId} />
      </div>
    )
  }

  useEffect(() => {
    toastNotifications(hasError, setHasError).error()
  }, [hasError])

  return (
    <FileViewWrapper>
      <div className="page-button-area">
        <div className="prev-page-button">
          {checkForPrevPageButton && <BTNextPageButton id="prev" handler={pagesHandler} />}
        </div>
      </div>

      <div>
        <div className="files-table">
          <BTFilesTableWithoutScroll
            files={file}
            buttonItems={path.includes('global-assets') ? assetButtonItems : buttonItems}
          />
        </div>
        <div className="title-area">
          {setName()}

          {document && numPages && (
            <p className="pages">
              Page {pageNumber}/{numPages}
            </p>
          )}
        </div>
        <ScrollContainer
          className="document-area"
          hideScrollbars={false}
          horizontal={false}
          vertical
        >
          <Document
            renderMode="svg"
            file={file && file[0].data}
            onLoadSuccess={onLoadSuccess}
            className="document"
          >
            <Page pageNumber={pageNumber} />
          </Document>
        </ScrollContainer>
      </div>

      <div className="page-button-area">
        <div className="next-page-button">
          {checkForNextPageButton && <BTNextPageButton id="next" handler={pagesHandler} />}
        </div>
      </div>
    </FileViewWrapper>
  )
}
