import { FC } from 'react'
import { Typography, Backdrop, Modal, Fade, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { MembersListModalWrapper } from './bt-mebers-list-modal.presets'
import type { IBTMembersListModalProps } from './bt-mebers-list-modal.props'
import { BTMemberListItem } from '../bt-member-list-item'
import ScrollContainer from 'react-indiana-drag-scroll'

export const BTMembersListModal: FC<IBTMembersListModalProps> = ({
  isOpen,
  assignedList,
  handleCloseModal,
  removeMember,
}) => {
  const previewMembersList = assignedList.map(({ id, name, avatarUrl }) => (
    <BTMemberListItem
      id={id}
      key={id}
      avatarUrl={avatarUrl}
      name={name}
      isSelected
      onClick={() => removeMember(id)}
    />
  ))

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <MembersListModalWrapper>
          <IconButton onClick={handleCloseModal} className="close-button">
            <CloseIcon />
          </IconButton>
          <div className="main-modal-block">
            <Typography className="transition-modal-title" variant="h5" component="span">
              Assigned members
            </Typography>
            <ScrollContainer
              hideScrollbars={false}
              vertical
              horizontal={false}
              className="scroll-wrapper members-list"
            >
              {previewMembersList}
            </ScrollContainer>
          </div>
        </MembersListModalWrapper>
      </Fade>
    </Modal>
  )
}
