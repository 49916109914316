import styled from 'styled-components'
import { colors } from '../../../../../theme/Colors'

export const BTMembersList = styled.div`
  width: 100%;

  .members-list {
    max-height: 300px;

    &::-webkit-scrollbar {
      background-color: transparent;
      height: 10px;
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${colors.navyGrey[200]};
      border-radius: 10px;
    }
  }

  button:not(:last-child) {
    margin-bottom: 8px;
  }
`
