/* eslint-disable no-case-declarations */
import { useGetAllNotificationsLazyQuery, GetAllNotificationsDocument } from 'generated/graphql'
import { NOTIFICATIONS_PAGINATION_LIMIT } from 'helpers/constants'
import { PAGINATION_EVENTS } from './types'
import { client } from '../../index'

export const useNotificationsPagination = () => {
  const [getNotifications] = useGetAllNotificationsLazyQuery()

  return async (event: PAGINATION_EVENTS, page: number) => {
    switch (event) {
      case PAGINATION_EVENTS.MORE_UNREAD:
        const notificationUnreadResponse = await getNotifications({
          variables: {
            unreadArgs: { limit: NOTIFICATIONS_PAGINATION_LIMIT, skip: page },
            readArgs: { limit: NOTIFICATIONS_PAGINATION_LIMIT },
          },
        })

        const notificationsUnreadCache = client.readQuery({
          query: GetAllNotificationsDocument,
          variables: {
            unreadArgs: { limit: NOTIFICATIONS_PAGINATION_LIMIT },
            readArgs: { limit: NOTIFICATIONS_PAGINATION_LIMIT },
          },
        })

        if (notificationUnreadResponse.data) {
          const oldNotifications = [...notificationsUnreadCache.notifications.unread.notifications]
          const newNotifications = [
            ...notificationUnreadResponse.data.notifications.unread.notifications,
          ]

          const updatedNotifications = [...oldNotifications, ...newNotifications]

          client.writeQuery({
            query: GetAllNotificationsDocument,
            variables: {
              unreadArgs: { limit: NOTIFICATIONS_PAGINATION_LIMIT },
              readArgs: { limit: NOTIFICATIONS_PAGINATION_LIMIT },
            },
            data: {
              ...notificationsUnreadCache,
              notifications: {
                ...notificationsUnreadCache.notifications,
                unread: {
                  ...notificationsUnreadCache.notifications.unread,
                  notifications: updatedNotifications,
                },
              },
            },
          })
        }
        break

      case PAGINATION_EVENTS.MORE_READ:
        const notificationReadResponse = await getNotifications({
          variables: {
            unreadArgs: { limit: NOTIFICATIONS_PAGINATION_LIMIT },
            readArgs: { limit: NOTIFICATIONS_PAGINATION_LIMIT, skip: page },
          },
        })

        const notificationsReadCache = client.readQuery({
          query: GetAllNotificationsDocument,
          variables: {
            unreadArgs: { limit: NOTIFICATIONS_PAGINATION_LIMIT },
            readArgs: { limit: NOTIFICATIONS_PAGINATION_LIMIT },
          },
        })

        if (notificationReadResponse.data) {
          const oldNotifications = [...notificationsReadCache.notifications.read.notifications]
          const newNotifications = [
            ...notificationReadResponse.data.notifications.read.notifications,
          ]

          const updatedNotifications = [...oldNotifications, ...newNotifications]

          client.writeQuery({
            query: GetAllNotificationsDocument,
            variables: {
              unreadArgs: { limit: NOTIFICATIONS_PAGINATION_LIMIT },
              readArgs: { limit: NOTIFICATIONS_PAGINATION_LIMIT },
            },
            data: {
              ...notificationsReadCache,
              notifications: {
                ...notificationsReadCache.notifications,
                read: {
                  ...notificationsReadCache.notifications.read,
                  notifications: updatedNotifications,
                },
              },
            },
          })
        }

        break
      default:
        break
    }
  }
}
