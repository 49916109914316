import { NOTIFICATIONS_PAGINATION_LIMIT } from 'helpers/constants'
import {
  GetAllNotificationsQuery,
  GetAllNotificationsDocument,
  useSetAllNotificationAsReadMutation,
} from 'generated/graphql'

export const useSetAllAsRead = () => {
  const [setAllAsRead] = useSetAllNotificationAsReadMutation()

  return async (userId: string) => {
    await setAllAsRead({
      variables: {
        input: userId,
      },

      update: (cache) => {
        const notificationsCache = cache.readQuery({
          query: GetAllNotificationsDocument,
          variables: {
            unreadArgs: { limit: NOTIFICATIONS_PAGINATION_LIMIT },
            readArgs: { limit: NOTIFICATIONS_PAGINATION_LIMIT },
          },
        }) as GetAllNotificationsQuery

        const unread = notificationsCache?.notifications?.unread?.notifications
        const read = notificationsCache?.notifications?.read?.notifications

        const modifiedUnread = [...unread].reverse()
        const modifiedRead = [...read]

        modifiedUnread.forEach((notification) => {
          const modifiedNotification = { ...notification }
          modifiedNotification.isRead = true

          modifiedRead.unshift(modifiedNotification)
        })

        cache.writeQuery({
          query: GetAllNotificationsDocument,
          variables: {
            unreadArgs: { limit: NOTIFICATIONS_PAGINATION_LIMIT },
            readArgs: { limit: NOTIFICATIONS_PAGINATION_LIMIT },
          },
          data: {
            notifications: {
              ...notificationsCache.notifications,
              unread: {
                ...notificationsCache.notifications.unread,
                /** clear notifications */
                notifications: [],
                pagination: {
                  ...notificationsCache.notifications.unread.pagination,
                  /** set total pages = 1, for pagination button disappear */
                  totalPages: 1,
                },
              },
              read: { ...notificationsCache.notifications.read, notifications: modifiedRead },
            },
          },
        })
      },
    })
  }
}
