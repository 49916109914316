import styled, { CSSProperties } from 'styled-components'
import ScrollContainer from 'react-indiana-drag-scroll'
import { Button, ButtonTypeMap, ExtendButtonBase, Divider } from '@mui/material'
import { FC, memo } from 'react'
import { colors } from '../../../theme/Colors'

const BTAppsLabel = styled.span<{ color?: string }>`
  ${({ theme }) => ({ ...theme.typography?.subtitle3 })};
  font-weight: 700;
  line-height: 18px;
  color: ${(props) => props.color || '#000000'};
  margin-bottom: 20px;
`
const BTAppsBlockWrapper = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.gap}px;
`

const BTAppsWrapper = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  gap: ${({ gap }) => (gap ? `${gap}px` : 'initial')};

  .feedback-scroll-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: auto;
    max-height: 450px;

    &::-webkit-scrollbar {
      background-color: transparent;
      height: 10px;
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${colors.navyGrey[200]};
      border-radius: 10px;
    }

    .empty-feedback-title {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
  }
  .pagination-block {
    margin: auto;
    margin-top: 12px;
    margin-bottom: 20px;

    button {
      background-color: ${colors.navyGrey[200]};

      &:hover {
        background-color: ${colors.navyGrey[300]};
      }
    }

    .Mui-selected {
      background-color: ${colors.projectColors.main};
      color: ${colors.white};

      &:hover {
        background-color: ${colors.projectColors.main};
      }
    }
  }
`
const BTAppsScrollableContainer = styled(ScrollContainer)`
  display: flex;
  height: 88%;
  flex-direction: column;
  gap: 15px;
  max-height: 70vh;
`
const BTAppsSubLabel = styled.div`
  ${({ theme }) => ({ ...theme.typography?.regular })};
  color: #333333;
`
const BTAppsDivider = styled(Divider)<{ isTop?: boolean; isBottom?: boolean }>`
  && {
    margin-bottom: ${({ isBottom }) => (isBottom ? '20px' : 'none')};
    margin-top: ${({ isTop }) => (isTop ? '30px' : 'none')};
    border: 1px solid #e0e0e0;
    background: #e0e0e0;
  }
`

// this name mui needs to und that it's a custom prop
type BTAppsButtonProps = { texttransform?: CSSProperties['textTransform'] }
type MemoizedButtonType = Parameters<ExtendButtonBase<ButtonTypeMap<{}, 'button'>>>['0'] &
  BTAppsButtonProps

const BTAppsButton = styled(Button)<BTAppsButtonProps>`
  && {
    text-transform: ${({ texttransform }) => texttransform || 'uppercase'};
    ${({ theme }) => ({ ...theme.typography?.subtitle1 })};
    padding: 10px;
  }
`

const MemoizedBTAppsButton = memo<MemoizedButtonType>((props) => <BTAppsButton {...props} />)

export {
  BTAppsLabel,
  BTAppsWrapper,
  BTAppsScrollableContainer,
  BTAppsSubLabel,
  BTAppsDivider,
  BTAppsButton,
  MemoizedBTAppsButton,
  BTAppsBlockWrapper,
}
