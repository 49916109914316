import React from 'react'
import type { CustomPage } from '../../helpers/types'
import { Link } from 'react-router-dom'

export const ErrorPage: CustomPage = () => {
  return (
    <>
      <div>
        <p
          style={{
            fontSize: 120,
            textAlign: 'center',
            margin: 0,
            borderBottom: '1px solid gray',
          }}
        >
          404
        </p>
        <p
          style={{
            fontSize: 30,
            textAlign: 'center',
            margin: '15px',
          }}
        >
          Page not found.
        </p>
        <Link to="/">
          <p style={{ fontSize: 14, textAlign: 'right', paddingTop: '15px' }}>Return to homepage</p>
        </Link>
      </div>
    </>
  )
}
