import { FC } from 'react'
import { Link } from 'react-router-dom'
import { BTNoProjectsTutorialWrapper } from './bt-no-projects-tutorial.presets'
import { ReactComponent as CurveTutorialArrow } from 'assets/images/curveNoProjectsArrow.svg'

export const BTNoProjectsTutorial: FC = () => {
  return (
    <BTNoProjectsTutorialWrapper>
      <div>You have no documents as you have not created your first project.</div>
      <div>
        To create your first project, click <Link to="/create-project">here</Link>.
      </div>
      <CurveTutorialArrow className="curve-arrow" />
    </BTNoProjectsTutorialWrapper>
  )
}
