import styled from '@emotion/styled'
import { colors } from '../../../theme/Colors'

export const AddMemberStepWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .MuiFormControl-root {
    background-color: #f2f2f2;
    border-radius: 5px;
    margin-bottom: 20px;

    input {
      color: ${colors.navyGrey[500]};
    }
  }

  label {
    color: ${colors.navyGrey[500]};
  }

  .button-group {
    width: 100%;
    button {
      height: 38px;
      width: 100%;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      text-transform: capitalize;
    }
  }

  .members-list {
    width: 100%;
    display: flex;
    gap: 10px 0;
    flex-direction: column;
    margin-bottom: 20px;
    align-items: center;
    justify-content: center;
  }

  .assignees-list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px 8px;
    margin-bottom: 20px;
    max-width: 240px;
    flex-direction: row;
    flex-wrap: wrap;

    .remove-button {
      padding: 0;
      background-color: transparent;
    }

    .rest-members-amount {
      background-color: ${colors.navyGrey[300]};
      border-radius: 50px;
      min-width: auto;
      height: 35px;
      width: 35px;
      color: ${colors.white};
      font-size: 18px;
      line-height: 27px;
    }
  }
`
