import { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useWizard } from 'react-use-wizard'
import { useAppSelector } from 'redux/store'
import { userFullRegister } from 'redux/LoginSlice'
import { useUpdateUserMutation, useMeWithOrganizationQuery } from 'generated/graphql'
import { BTPostSignupFormContainer } from './bt-post-signup-form-wrapper/bt-post-signup-form-wrapper'

export const BTWizardEnd: FC = () => {
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const [updateUser] = useUpdateUserMutation()

  const { image, pronouns } = useAppSelector((state) => state.postSignupForm)
  const { activeStep, stepCount } = useWizard()
  const { data, refetch } = useMeWithOrganizationQuery()

  const userUpdate = async () => {
    if (data?.meWithOrganization) {
      const { id } = data.meWithOrganization
      await updateUser({
        variables: {
          input: {
            id,
            name: data.meWithOrganization.name,
            email: data.meWithOrganization.email,
            avatar: image!,
            isPostSignUpFormCompleted: true,
            pronouns,
          } as any,
        },
      })
    }
    dispatch(userFullRegister({ isFullyRegistered: true }))
    await refetch()
  }

  const goToDashboard = async () => {
    setLoading(true)
    await userUpdate()
  }

  return (
    <BTPostSignupFormContainer
      title="You’re ready to get Brieft!"
      btnTitle="Go to Dashboard"
      goNext={goToDashboard}
      content={null}
      activeStep={activeStep}
      stepCount={stepCount}
      loading={loading}
    />
  )
}
