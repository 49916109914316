/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
import { useSetupHeaderData } from 'hooks/header/useSetupHeaderData'
import { BTArtwork } from 'components/bt-artwork/bt-artwork'
import {
  Spellcheck,
  Send,
  FormatListBulleted,
  QuestionAnswer,
  Restore,
  Collections,
} from '@mui/icons-material'
import { SidebarProp } from 'components/shared/bt-actions-sidebar/bt-actions-sidebar.types'
import { BTAssets } from 'components/shared/bt-assets'
import { BTActivityFeedback } from 'components/SidebarApps/bt-activity-feedback'
import { BTActivitySend } from 'components/SidebarApps/bt-activity-send'
import { BTApprove } from 'components/SidebarApps/bt-approve'
import { BTBriefAssets } from 'components/SidebarApps/bt-brief-assets'
import { BTBriefHistory } from 'components/SidebarApps/bt-brief-history'
import { BTBriefTasks } from 'components/SidebarApps/bt-brief-tasks'
import { useEffect, useMemo } from 'react'
import { BTActionsSidebar } from 'components/shared/bt-actions-sidebar'
import { useAppDispatch } from 'redux/store'
import { setAssetId } from 'redux/SidebarAppsSlice'
import { useGetAllActivityAssetsQuery } from 'generated/graphql'
import { useParams } from 'react-router-dom'
import {
  ComposePageWrapper,
  ComposePageWrapperSub,
} from '../brief-compose-page/brief-compose-page.presets'

export const ArtworkComposePage = () => {
  const dispatch = useAppDispatch()
  useSetupHeaderData()

  const { artworkId } = useParams()

  const { data } = useGetAllActivityAssetsQuery({ variables: { activityId: artworkId ?? '' } })

  const top: SidebarProp = useMemo(
    () => [
      { Logo: QuestionAnswer, application: BTActivityFeedback },
      /**
       * @desc commented by following ticket:
       * @link https://brieft-app.atlassian.net/browse/BRIEFT-499
       */
      // {
      //   Logo: Collections,
      //   application: BTBriefAssets,
      //   extendedApp: BTAssets,
      // },
      // { Logo: Restore, application: BTBriefHistory },
    ],
    []
  )
  const bottom: SidebarProp = useMemo(
    () => [
      { Logo: Spellcheck, application: BTApprove, name: 'approves' },
      { Logo: Send, application: BTActivitySend },
    ],
    []
  )

  useEffect(() => {
    return () => {
      dispatch(setAssetId(''))
    }
  }, [])

  return (
    <ComposePageWrapper>
      <ComposePageWrapperSub>
        <BTArtwork />
      </ComposePageWrapperSub>
      {data?.getAllActivityAssets.length ? (
        <BTActionsSidebar type="ACTIVITIES" top={top} bottom={bottom} />
      ) : null}
    </ComposePageWrapper>
  )
}
