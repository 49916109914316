export const getFileExtention = (fileName: string = '') => {
  let splitted

  if (fileName.includes('/')) {
    splitted = fileName.split('/')
  } else {
    splitted = fileName.split('.')
  }
  const result = splitted[1].split('_')[0]

  return result
}
