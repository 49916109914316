import { FileTypes } from 'generated/graphql'

export const getFileType = (fileType: string) => {
  const type = fileType.split('/')[1]

  switch (type) {
    case 'png':
      return FileTypes.Png
    case 'jpeg':
      return FileTypes.Jpeg
    case 'jpg':
      return FileTypes.Jpeg
    case 'pdf':
      return FileTypes.Pdf
    default:
      return FileTypes.Jpeg
  }
}
