import { useEffect, useState } from 'react'

export function useTransition(isMounted: boolean, delay: number = 250): [boolean] {
  const [shouldRenderComponent, setShouldRenderComponent] = useState(false)
  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>
    if (isMounted && !shouldRenderComponent) {
      setShouldRenderComponent(true)
    } else if (!isMounted && shouldRenderComponent) {
      timeout = setTimeout(() => {
        setShouldRenderComponent(false)
      }, delay)
    }
    return () => clearTimeout(timeout)
  }, [isMounted, delay, shouldRenderComponent])
  return [shouldRenderComponent]
}
