import { colors } from 'theme/Colors'
import styled from 'styled-components'

export const BTArchivedProjectsWrapper = styled.div`
  width: 100%;
  height: 100%;

  .no-projects {
    ${({ theme }) => ({ ...theme.typography.regular })}

    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export const BTSearchWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  padding-right: 40px;
  padding-left: 100px;

  .search-input {
    margin-bottom: 20px;
  }

  .border-bottom {
    border-bottom: 2px solid ${colors.projectColors.fileBorder};
  }
`

export const BTArchivedProjectsLayoutWrapper = styled.div`
  .projects-list {
    display: flex;
    justify-content: center;
    margin-left: 55px;

    max-height: 500px;

    &::-webkit-scrollbar {
      background-color: transparent;
      height: 10px;
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${colors.navyGrey[200]};
      border-radius: 10px;
    }
  }
`
