import assert from 'assert'
import { propEq } from 'ramda'
import {
  ActivityStatus,
  ActivitySummaryFragment,
  ActivityType,
  DeliverableMainType,
  useCreateBriefMutation,
} from '../../generated/graphql'
import { useCurrentProject } from '../project'

export function useCreateBrief() {
  const project = useCurrentProject().project
  const [createBrief] = useCreateBriefMutation()

  return async (
    name: string,
    authorId: string,
    milestoneId?: string,
    deliverables?: DeliverableMainType[]
  ) => {
    assert(project)

    const milestone = milestoneId ? project.milestones.find(propEq('id', milestoneId)) : null
    const existingActivities = milestone ? milestone.activities : project.activities
    const lastActivity = existingActivities[existingActivities.length - 1]
    const activity: ActivitySummaryFragment = {
      __typename: 'Brief',
      author: authorId,
      id: `newly-created-brief-${String(Math.random()).slice(2)}`,
      milestone,
      name,
      position: lastActivity ? lastActivity.position + 1000 : 0,
      updatedAt: new Date(),
      viewedAt: null,
      archivedAt: null,
      tasks: [],
      type: ActivityType.Brief,
      composedDocument: null,
      uploadedDocument: null,
      status: ActivityStatus.NewActivites,
    }

    return createBrief({
      variables: {
        input: {
          projectId: project.id,
          milestoneId,
          name,
          deliverables,
        },
      },
      optimisticResponse: {
        createBrief: {
          activity: {
            ...activity,
            project: {
              id: project.id,
              activities: !milestone ? [...project.activities, activity] : project.activities,
              milestones: project.milestones.map((currMilestone) => ({
                ...currMilestone,
                activities:
                  currMilestone.id === milestoneId
                    ? [...currMilestone.activities, activity]
                    : currMilestone.activities,
              })),
            },
          },
        },
      },
    })
  }
}
