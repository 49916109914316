/* eslint-disable no-shadow */
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { useNavLocation } from 'hooks/shared/useNavLocation'
import { BTDeleteButton } from 'components/shared/bt-delete-button'
import { useDeleteBriefMutation, useGetBriefQuery } from 'generated/graphql'

export const BriefCrashPage: FC = () => {
  const [deleteBrief] = useDeleteBriefMutation()
  const [{ pathname }, navigation] = useNavLocation()
  const { id: projectId } = useParams()

  const { data: briefData } = useGetBriefQuery({
    variables: { id: projectId },
  })

  const deleteHandler = async () => {
    const briefId = briefData?.getBrief.id
    if (briefId) {
      await deleteBrief({
        variables: { id: briefId },
        update(cache) {
          const id = cache.identify({
            id: briefId,
            __typename: 'Brief',
          })
          cache.evict({ id })
          cache.gc()
        },
      })

      navigation(`/projects/${projectId}/brief/create`)
    }
  }

  return (
    <div>
      <BTDeleteButton handler={deleteHandler} />
    </div>
  )
}
