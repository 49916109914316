import styled from '@emotion/styled'
import styledComponents from 'styled-components'
import { colors } from '../../../theme/Colors'

export const BTPostSignupFormWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  .wrapper {
    box-sizing: border-box;
    width: 400px;
    padding: 60px 80px;
    border-radius: 5px;
    gap: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${colors.white};

    .MuiLoadingButton-root {
      padding: 10px;
    }
  }
`

export const BTPostSignupFormTitle = styled.h1`
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  padding: 0;
  margin: 0;
`

export const BTPostSignupFormBtnText = styled.span`
  font-family: 'Poppins';
  text-transform: none;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
`

export const BTDotsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 75px;
`

export const BTDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 1000px;
`

export const BTAvatarUploadWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .avatar label {
    cursor: pointer;
    font-size: 13px;
    text-decoration: underline;
  }
`

export const BTAvatarIcon = styled.div`
  align-self: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 160px;
  height: 160px;
  border-radius: 160px;
  background-color: rgba(224, 224, 224, 1);
  overflow: hidden;
`

export const BTAvatarDescriptionTitle = styled.div`
  font-family: Poppins;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
`

export const BTAvatarDescriptionSubTitle = styled.div`
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
`

export const BTAvatarDescription = styled.div`
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
`

export const BTAvatarImage = styledComponents.img`
  width: 160px;
  height: 160px;
  object-fit: cover;
`

export const BTPronounsText = styledComponents.label`
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: rgba(51, 51, 51, 1);
  cursor: pointer;
`

export const BTPronounsWrapper = styled.div`
  min-width: 240px;
  background-color: rgba(242, 242, 242, 1);
  margin-bottom: 10px;
  border-radius: 5px;

  .MuiRadio-colorPrimary {
    color: rgba(218, 218, 218, 1);
  }
`
