import styled from '@emotion/styled'
import { colors } from 'theme/Colors'

interface IBTMarkUpLayoutWrapperProps {
  width: number
}

export const BTMarkUpLayoutWrapper = styled.div<IBTMarkUpLayoutWrapperProps>`
  span {
    align-self: center;
    justify-self: center;
  }

  .scroll-container {
    position: relative;
    max-height: 70vh;
    max-width: 70vw;

    &::-webkit-scrollbar {
      background-color: transparent;
      height: 10px;
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${colors.navyGrey[200]};
      border-radius: 10px;
    }
  }

  .main-layout {
    position: relative;
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
  }

  img {
    width: ${({ width }) => width && width}px;
    height: auto;
  }

  .markup-button {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-transform: capitalize;
    color: ${colors.projectColors.main};
    background-color: ${colors.white};
    position: absolute;
    bottom: 10px;
    right: 200px;

    &:hover {
      background-color: ${colors.navyGrey[200]};
    }
  }
`
