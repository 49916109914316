import styled from '@emotion/styled'
import { colors } from '../../../theme/Colors'

export const BTSignupFormWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  .wrapper {
    padding: 60px 80px;
    border-radius: 5px;
    gap: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${colors.white};

    .inputs-block {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .input-block {
        display: flex;
        flex-direction: column;
        width: 240px;

        .MuiOutlinedInput-input {
          padding: 8px 10px;
          font-family: 'Poppins';
          font-weight: 400;
          font-size: 14px;
          line-height: 14px;

          &::placeholder {
            font-family: 'Poppins';
            font-weight: 400;
            font-size: 10px;
            line-height: 14px;
          }
        }

        span {
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: ${colors.navyGrey[400]};
          padding-bottom: 5px;
        }

        .Mui-error {
          fieldset {
            border: 1px solid #ff5a78;
          }
        }

        fieldset {
          border: 1px solid ${colors.navyGrey[400]};
        }
      }
    }
  }
`

export const BTSignupTitle = styled.h1`
  font-family: 'Poppins';
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  padding: 0;
  margin: 0;
`

export const BTSignupText = styled.span`
  font-family: 'Poppins';
  text-transform: none;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
`
