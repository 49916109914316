import styled from 'styled-components'
import { colors } from '../../../theme/Colors'
const Wrapper = styled.div`
  width: clamp(420px, 25%, 600px);
  padding: 50px 50px 30px 50px;
  background: #ffffff;
  box-shadow: ${({ theme }) => theme.palette.shadows.main};
  & div:last-child {
    border: none;
  }
`

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${colors.projectColors.black};
  ${({ theme }) => ({ ...theme.typography?.regular })}
  border-bottom: 1px solid #e0e0e0;
  padding: 18px 0;
  cursor: pointer;
  & svg {
    color: #828282;
  }
`
const Heading = styled.div`
  ${({ theme }) => ({ ...theme.typography?.subtitle1 })}
  color: ${colors.projectColors.black};
  padding-bottom: 12px;
`

export { Wrapper, Item, Heading }
