import styled from '@emotion/styled'
import { colors } from 'theme/Colors'

interface IBTHeaderPresetsProps {
  isHide?: boolean
}

const HeaderWrapper = styled.div<IBTHeaderPresetsProps>`
  position: sticky;
  top: 0;
  height: max-content;
  background: ${colors.white};
  z-index: 2;
  padding: 24px 24px 0;
  margin-left: 73px;
  ${({ isHide }) => (isHide ? 'display: none' : '')};

  .bottom-line {
    border-bottom: 1px solid ${colors.navyGrey[300]};
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
  }

  .header-top-block {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .header-title-block {
      display: flex;
      flex-direction: column;

      .header-title {
        font-family: Poppins;
        font-size: 18px;
        font-weight: 700;
        line-height: 27px;
        letter-spacing: 0em;
      }

      & > div:nth-child(1) {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0 10px;
        padding-bottom: 5px;
      }

      .header-client-name {
        font-weight: 400;
        font-family: Poppins;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }

  .header-tabs-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tabs {
      display: flex;
      align-items: center;

      .tools-button {
        margin-left: 40px;
        width: 20px;
        height: 20px;
      }
    }
  }
`

export { HeaderWrapper }
