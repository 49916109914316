import React, { FC } from 'react'

//TODO
import { CardLabelWrapper } from './bt-card-label.presets'
import type { ICardLabelProps } from './bt-card-label.props'

export const BTCardLabel: FC<ICardLabelProps> = ({
  bgColor = '#0066FF',
  isTransparent,
  isOutlined,
  title,
  description,
  textColor,
}) => {
  const rest = { bgColor, isTransparent, isOutlined, textColor }
  return (
    <CardLabelWrapper {...rest}>
      <p>{title}</p>
      {description && <p>{` ${description}`}</p>}
    </CardLabelWrapper>
  )
}
