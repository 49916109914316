import { colors } from 'theme/Colors'
import styled from 'styled-components'

export const BTDashboardWrapper = styled.div`
  width: 100%;
`

export const BTSummaryLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const BTSearchWrapper = styled.div`
  margin-top: 20px;
  padding-right: 40px;
  margin-bottom: 20px;
  padding-left: 20px;

  .search-input {
    margin-bottom: 20px;
  }

  .border-bottom {
    border-bottom: 2px solid ${colors.projectColors.fileBorder};
  }
`
