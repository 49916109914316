import { useEffect, useState } from 'react'

export function useDebounce<T>(value: T, delay: number = 250) {
  const [state, setState] = useState(value)

  useEffect(() => {
    let timeout = setTimeout(() => {
      setState(value)
    }, delay)
    return () => clearTimeout(timeout)
  }, [value, delay])
  return state
}
