import { TextField } from '@mui/material'
import { ChangeEvent, FC, useEffect, useState } from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import { BTNavigationWrapper } from './bt-navigation.presets'

import type { IBTNavigationProps } from './bt-navigation.props'

export const BTNavigation: FC<IBTNavigationProps> = ({
  currentPage,
  numberOfPages,
  setCurrentPage,
}) => {
  const [value, setValue] = useState<number | string>(1)

  const onChangeHandler = ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const page = Number.parseInt(target.value, 10)
    if (!Number.isNaN(page)) {
      if (page < 1 || page > numberOfPages) {
        setValue(currentPage)
      } else {
        setValue(page)
      }
    } else if (target.value.trim() === '') {
      setValue('')
    } else {
      setValue(currentPage)
    }
  }

  useEffect(() => {
    if (value && typeof value === 'number') {
      setCurrentPage(value)
    }
  }, [value])

  useEffect(() => {
    if (currentPage !== value) {
      setValue(currentPage)
    }
  }, [currentPage])

  const onBlurHandler = () => {
    if (typeof value === 'string' && value.trim() === '') {
      setValue(currentPage)
    }
  }

  const onClickSetPage = (page: number) => setValue(page)

  const prevPage = () => onClickSetPage(currentPage - 1)
  const nextPage = () => onClickSetPage(currentPage + 1)

  return (
    <BTNavigationWrapper>
      {currentPage > 1 && <ArrowBackIosNewIcon onClick={prevPage} />}
      <span className="text">Page</span>
      <TextField
        value={value}
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
          min: 1,
          max: numberOfPages,
        }}
        onBlur={onBlurHandler}
        onChange={onChangeHandler}
      />
      / {numberOfPages}
      {currentPage < numberOfPages && <ArrowForwardIosIcon onClick={nextPage} />}
    </BTNavigationWrapper>
  )
}
