import styled from '@emotion/styled'
import { colors } from 'theme/Colors'

export const BTMessageTooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px 2px;

  .MuiOutlinedInput-root {
    padding: 5px;
    width: 210px;
    background-color: ${colors.navyGrey[200]};
  }

  textarea {
    font-size: 10px;
    font-weight: 300;
    line-height: 12px;
    color: ${colors.navyGrey[800]};
  }

  .buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 10px;

    button {
      color: ${colors.white};
      text-transform: capitalize;
      font-size: 10px;
      font-weight: 400;
      line-height: 14px;
      padding: 5px;
      min-width: 40px;
    }
  }
`
