import styled from 'styled-components'

const ComposePageWrapper = styled.div<{ marginTop?: number }>`
  display: flex;
  width: 100%;
  height: inherit;
  justify-content: space-between;
  @media screen and (max-height: 780px) {
    height: calc(90vh - ${({ marginTop }) => `${marginTop}px`});
  }
`
const ComposePageWrapperSub = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

const ComposePageButtonWrapper = styled.div<{ isOpened: boolean }>`
  margin: 10px;
  margin-right: ${({ isOpened }) => (isOpened ? '430px' : '10px')};
`

const ComposeButtonBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export {
  ComposePageWrapper,
  ComposePageWrapperSub,
  ComposeButtonBlockWrapper,
  ComposePageButtonWrapper,
}
