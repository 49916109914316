import styled from 'styled-components'
import LinkIcon from '@mui/icons-material/Link'
const BTQuestionsLinkInput = styled.input`
  color: #333333;
  flex: 4;
  padding: 10px;
  background: #f2f2f2;
  outline: none;
  border: none;
  ${({ theme }) => ({ ...theme.typography?.regular })};
  &::placeholder {
    color: #333333;
    ${({ theme }) => ({ ...theme.typography?.regular })};
  }
`
const BTQuestionsLinkWrapper = styled.div`
  padding-right: 10px;
  background: #f2f2f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const BTQuestionsLinkAdd = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`
const BTQuestionsLinkAddWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #828282;
  ${({ theme }) => ({ ...theme.typography?.regular })};
`

const BTLinkIcon = styled(LinkIcon)`
  transform: rotate(-45deg);
  color: #828282;
  cursor: pointer;
  && {
    width: 18px;
    height: 18px;
  }
`
export {
  BTQuestionsLinkInput,
  BTQuestionsLinkWrapper,
  BTQuestionsLinkAdd,
  BTQuestionsLinkAddWrapper,
  BTLinkIcon,
}
