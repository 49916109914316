import styled from '@emotion/styled'
import { colors } from 'theme/Colors'

export const BTCreateOrganizationWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 30px 50px;

  .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
  }

  .input-block {
    display: flex;
    flex-direction: column;
    gap: 5px;

    .label {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      color: ${colors.navyGrey[400]};
    }

    input {
      width: 240px;
      padding: 6px 10px;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }

    p {
      position: absolute;
      top: 30px;
    }
  }

  .buttons-block {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    button {
      width: 120px;
      text-transform: capitalize;
    }

    .MuiButton-containedSecondary {
      color: ${colors.white};
    }
  }
`
