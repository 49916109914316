import styled from '@emotion/styled'

export const BTTeamSettignsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 0 40px 0 40px;
  width: 100%;
  height: 100%;
`

export const BTListViewLayoutWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 32px;

  .role {
    text-transform: capitalize;
  }
`

export const BTButtonsBlock = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  padding-top: 30px;

  button {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    white-space: nowrap;
    width: 100%;
    justify-content: space-between;
    padding: 10px;
    text-transform: capitalize;
  }
`
