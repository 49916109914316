import { FC, ChangeEvent } from 'react'
import { BTConfirmTextAreaStyled } from './bt-confirm-textarea.presets'
import { BTConfirmTextAreaProps } from './bt-confirm-textarea.props'

export const BTConfirmTextArea: FC<BTConfirmTextAreaProps> = ({ callback }) => {
  const handler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    callback(e.currentTarget.value)
  }

  return <BTConfirmTextAreaStyled onChange={handler} />
}
