/* eslint-disable import/no-unresolved */
import { colors } from 'theme/Colors'
import styled from 'styled-components'

export const BTInboxBackdrop = styled.div`
  position: absolute;
  z-index: 10;

  width: 100%;
  height: 100%;
`

export const BTInboxMainWrapper = styled.div`
  position: absolute;
  left: 71px;
  z-index: 15;

  width: 340px;
  height: 100%;

  background: ${colors.white};
  border-left: 2px solid ${colors.projectColors.fileBorder};
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);

  .scroll {
    max-height: 600px;

    &::-webkit-scrollbar {
      background-color: transparent;
      height: 10px;
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${colors.navyGrey[200]};
      border-radius: 10px;
    }
  }
`

export const BTInboxContentWrapper = styled.div`
  padding: 20px;
`

export const BTInboxHeader = styled.div`
  margin-bottom: 30px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    ${({ theme }) => ({ ...theme.typography.caption })};
    color: ${colors.projectColors.filePages};
    text-decoration: underline;

    background: none;
    border: none;
    cursor: pointer;
  }
`

export const BTInboxSubtitle = styled.div`
  ${({ theme }) => ({ ...theme.typography.h5 })};
`

export const BTAgeTitle = styled.div`
  margin-bottom: 10px;

  ${({ theme }) => ({
    ...theme.typography.subtitle1,
  })};
`

export const BTCollapseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;

  width: 100%;
`

export const BTNotificationList = styled.div`
  margin-bottom: 40px;

  div:not(:last-child) {
    margin-bottom: 10px;
  }
`

export const BTNotificationItemUnderline = styled.div`
  border-bottom: 1px solid ${colors.projectColors.fileBorder};
`

export const BTNotificationsEmpty = styled.div`
  height: 38px;
  background: rgba(0, 102, 255, 0.1);

  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => ({
    ...theme.typography.subtitle2,
  })};

  color: ${colors.projectColors.secondaryFont};
`
