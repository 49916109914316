import styled from '@emotion/styled'
import { colors } from '../../../../theme/Colors'

export const BTChangePasswordModalWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  padding: 30px 50px;

  .Mui-disabled {
    background-color: ${colors.blue[100]};
  }

  fieldset {
    border: 1px solid ${colors.navyGrey[300]};
    border-radius: 5px;
  }

  button {
    background-color: ${colors.projectColors.main};
    color: ${colors.white};

    &:hover {
      background-color: ${colors.blue[700]};
    }
  }

  .inputs-block {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .MuiAlert-filledError {
      background-color: #ff5a78;
    }

    .MuiAlert-filledSuccess {
      background-color: #388e3c;
    }

    .input-block {
      min-width: 240px;
      span,
      input {
        font-size: 12px;
        line-height: 18px;
        color: ${colors.navyGrey[400]};
      }
    }
  }
`
