import * as yup from 'yup'
import { useLogin } from 'hooks'
import { useFormik } from 'formik'
import { useAppDispatch } from 'redux/store'
import { setupWhoami } from 'redux/LoginSlice'
import LoadingButton from '@mui/lab/LoadingButton'
import { useNavigate, Link } from 'react-router-dom'
import { BTLoginFormWrapper } from './bt-login.presets'
import { Box, TextField, Typography } from '@mui/material'
import { useMeWithOrganizationQuery } from 'generated/graphql'
import { toastNotifications } from 'helpers/toastNotifications'
import { ChangeEvent, useEffect, useState, KeyboardEvent } from 'react'

const LoginValidationSchema = yup.object({
  email: yup.string().email('Wrong email format').required('Email required'),
  password: yup.string().required('Password required'),
})

interface FormValues {
  email: string
  password: string
}

export const BTLogin = () => {
  const login = useLogin()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [error, setError] = useState<string | null | boolean>(null)
  const { refetch } = useMeWithOrganizationQuery()

  const onSubmit = async ({ email, password }: FormValues) => {
    try {
      await login(email, password)

      const response = await refetch()
      if (response.data) {
        dispatch(setupWhoami(response.data))
      }

      navigate('/workspace')
    } catch (err: any) {
      setError(err.message ?? err)
    }
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginValidationSchema,
    onSubmit,
  })

  const onChange = (value: string, fieldName: string) => {
    formik.setFieldValue(fieldName, value)
    formik.validateField(fieldName)
  }

  const keyDownHandler = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      formik.submitForm()
    }
  }

  useEffect(() => {
    if (error) {
      toastNotifications(error, setError).error()
    }
  }, [error])

  useEffect(() => {
    const email = sessionStorage.getItem('email')

    if (email) {
      formik.setFieldValue('email', email)
      formik.validateField('email')
    }
  }, [])

  return (
    <BTLoginFormWrapper>
      <Box className="wrapper" sx={{ boxShadow: 3 }}>
        <Typography variant="h5">Welcome Back</Typography>
        <form className="inputs-block">
          <div className="input-block">
            <span>Email</span>
            <TextField
              value={formik.values.email}
              placeholder="name@company.com"
              helperText={formik.errors.email}
              onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
                onChange(target.value, 'email')
              }
              error={!!formik.errors.email}
              autoComplete="off"
            />
          </div>
          <div className="input-block">
            <span>Password</span>
            <TextField
              placeholder="Password"
              type="password"
              helperText={formik.errors.password}
              error={!!formik.errors.password}
              onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
                onChange(target.value, 'password')
              }
              onKeyDown={(event) => keyDownHandler(event)}
              autoComplete="off"
            />

            <Link to="/forgot" className="forgot-pass-link">
              Forgot?
            </Link>
          </div>
        </form>
        <LoadingButton
          variant="contained"
          fullWidth
          onClick={() => formik.handleSubmit()}
          loading={formik.isSubmitting}
        >
          <Typography variant="subtitle1">Log in</Typography>
        </LoadingButton>
      </Box>
    </BTLoginFormWrapper>
  )
}
