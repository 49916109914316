import { propEq } from 'ramda'
import { client } from '../../../../index'
import { useAppSelector } from 'redux/store'
import { NavigationItems } from 'router/items'
import { BTProjectSearch } from '../project-search'
import ScrollContainer from 'react-indiana-drag-scroll'
import { FC, useCallback, useEffect, useState } from 'react'
import { SubButtonsContainer } from '../sub-buttons-container'
import { BTNavigationLink } from 'components/shared/bt-nav-link'
import { NOTIFICATIONS_PAGINATION_LIMIT } from 'helpers/constants'
import { ReactComponent as HomeLogo } from 'assets/images/homeLogo.svg'
import { ReactComponent as FileLogo } from 'assets/images/fileLogo.svg'
import { ReactComponent as TasksLogo } from 'assets/images/tasksLogo.svg'
import { ReactComponent as InboxIcon } from 'assets/images/inboxIcon.svg'
import { ReactComponent as GraphLogo } from 'assets/images/graphLogo.svg'
import { ReactComponent as AddButton } from 'assets/images/addButton.svg'
import { ReactComponent as FolderLogo } from 'assets/images/folderLogo.svg'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { ReactComponent as FavoriteLogo } from 'assets/images/favoriteLogo.svg'
import { useRoleContext } from 'helpers/CheckUserRoleAccess/CheckUserRoleAccess'
import { ListItem, ListItemIcon, ListItemText, ListItemButton } from '@mui/material'
import type { MainButtonsListProps, SidebarOptionsStructure } from './main-button-list.props'
import {
  ProjectSummaryFragment,
  useProjectSummariesQuery,
  GetAllNotificationsDocument,
} from 'generated/graphql'
import {
  SideList,
  NotFoundText,
  SearchWrapper,
  NewNotificationsIcon,
  ProjectsScrollWrapper,
} from './main-button-list.presets'
import {
  StyledSquare,
  CustomDivider,
  IconButtonWrapper,
  ListButtonsWrapper,
} from 'components/bt-sidebar/bt-sidebar.presets'

export const BTMainButtonsList: FC<MainButtonsListProps> = ({ open }) => {
  const { withCheckRoleAccess } = useRoleContext()
  const [searchValue, setSearchValue] = useState<string>('')
  const [searchedProjects, setSearchedProjects] = useState<ProjectSummaryFragment[]>([])
  const { data, refetch } = useProjectSummariesQuery()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const [queryParams] = useSearchParams()

  const { cognitoUser } = useAppSelector((state) => state.login)

  const inboxModalOpenCondition = !!queryParams.get('inbox-modal-open')

  const projects = data?.projects
  const moreThanOneProject = projects && projects.length > 1

  const inboxModalHandler = () => {
    if (inboxModalOpenCondition) {
      return pathname
    }
    return `${pathname}?inbox-modal-open=true`
  }

  const checkOptionsForUserRole = () => {
    return [
      [NavigationItems.Dashboard, HomeLogo, '/workspace'],
      [NavigationItems.Tasks, TasksLogo, '/global-tasks'],
      [NavigationItems.GlobalAssets, withCheckRoleAccess(FolderLogo), '/global-assets'],
      [NavigationItems.Inbox, InboxIcon, inboxModalHandler()],
      [NavigationItems.Insights, GraphLogo, '/insights'],
    ]
  }

  const sidebarMainOptions = checkOptionsForUserRole() as SidebarOptionsStructure

  const listItemIconClick = useCallback((event) => {
    event.stopPropagation()
    navigate('create-project')
  }, [])

  /** Notifications */
  const notificationsCache = client.readQuery({
    query: GetAllNotificationsDocument,
    variables: {
      unreadArgs: { limit: NOTIFICATIONS_PAGINATION_LIMIT },
      readArgs: { limit: NOTIFICATIONS_PAGINATION_LIMIT },
    },
  })
  const unreadNotifications = notificationsCache?.notifications.unread.notifications

  /** */

  useEffect(() => {
    if (cognitoUser !== undefined) {
      refetch()
    }
  }, [cognitoUser])

  const mainList = () => {
    return (
      <SideList open={open}>
        {sidebarMainOptions
          .filter(([_, Comp]) => Comp)
          .map(([name, Component, path]) => {
            const newNotificationsIconCondition =
              name === 'Inbox' && unreadNotifications && !!unreadNotifications.length

            return (
              <ListItem button key={name} component={BTNavigationLink} to={path}>
                <ListItemIcon>
                  <Component />
                  {newNotificationsIconCondition && <NewNotificationsIcon />}
                </ListItemIcon>
                {open && <ListItemText primary={name} />}
              </ListItem>
            )
          })}
      </SideList>
    )
  }

  const searchHandler = (value: string) => {
    setSearchValue(value)
    setSearchedProjects([])

    const lowerCasedValue = value.toLowerCase()

    const filteredProjects = projects?.filter((project) => {
      const lowerCasedProjectName = project.name?.toLowerCase()
      const lowerCasedClientName = project.client?.name.toLowerCase()

      if (lowerCasedProjectName && lowerCasedClientName) {
        return (
          lowerCasedProjectName.includes(lowerCasedValue) ||
          lowerCasedClientName.includes(lowerCasedValue)
        )
      }
      return false
    })

    if (filteredProjects) {
      setSearchedProjects(filteredProjects)
    }
  }

  const renderSearchResults = () => {
    return !searchedProjects.length ? (
      <NotFoundText>Not found</NotFoundText>
    ) : (
      searchedProjects?.map((project) => {
        const { color, name } = project
        const clientName = project.client?.name

        return (
          <ListItemButton
            key={project.id}
            sx={{ pl: 4 }}
            component={BTNavigationLink}
            to={`projects/${project.id}`}
          >
            <IconButtonWrapper>
              <div className="project-name-wrapper">
                <StyledSquare fill={color} />
                <div className="project-name">{name}</div>
              </div>

              <div className="client-name">{clientName}</div>
            </IconButtonWrapper>
          </ListItemButton>
        )
      })
    )
  }

  const renderAllProjects = () => {
    return projects?.map((project) => {
      const { color, name } = project
      const clientName = project.client?.name

      return (
        <ListItemButton
          key={project.id}
          sx={{ pl: 4 }}
          component={BTNavigationLink}
          to={`projects/${project.id}`}
        >
          <IconButtonWrapper>
            <div className="project-name-wrapper">
              <StyledSquare fill={color} />
              <div className="project-name">{name}</div>
            </div>

            <div className="client-name">{clientName}</div>
          </IconButtonWrapper>
        </ListItemButton>
      )
    })
  }

  const additionalList = () => {
    return (
      <>
        <CustomDivider />
        <SubButtonsContainer open={open} logo={FavoriteLogo} name="Favorites">
          <ListItemButton
            key="favorites"
            sx={{ pl: 4 }}
            component={BTNavigationLink}
            to="favorites"
          >
            <IconButtonWrapper>
              <StyledSquare fill="red" />
              <ListItemText primary="Coming soon..." />
            </IconButtonWrapper>
          </ListItemButton>

          {projects?.filter(propEq('favorite', true)).map((project) => {
            return (
              <ListItemButton
                key={project.id}
                sx={{ pl: 4 }}
                component={BTNavigationLink}
                to={`projects/${project.id}`}
              >
                <IconButtonWrapper>
                  <StyledSquare fill={project.color} />
                  <ListItemText primary={project.name} />
                </IconButtonWrapper>
              </ListItemButton>
            )
          })}
        </SubButtonsContainer>

        <CustomDivider />

        <SubButtonsContainer
          open={open}
          logo={FileLogo}
          name="Create Project"
          renderActionButton={() => (
            <ListItemIcon onClick={listItemIconClick}>
              <AddButton />
            </ListItemIcon>
          )}
        >
          <ListButtonsWrapper>
            {moreThanOneProject && (
              <SearchWrapper>
                <BTProjectSearch
                  placeholder="Search projects"
                  handler={searchHandler}
                  handledValue={searchValue}
                />
              </SearchWrapper>
            )}

            <ProjectsScrollWrapper>
              <ScrollContainer
                className="projects-list"
                hideScrollbars={false}
                horizontal={false}
                vertical
              >
                {searchValue ? renderSearchResults() : renderAllProjects()}
              </ScrollContainer>
            </ProjectsScrollWrapper>
          </ListButtonsWrapper>
        </SubButtonsContainer>
      </>
    )
  }

  return (
    <>
      {mainList()}
      {additionalList()}
    </>
  )
}
