import styled from '@emotion/styled'
import { colors } from 'theme/Colors'

interface IBTArtworkWrapperProps {
  loading: boolean
}

export const BTArtworkWrapper = styled.div<IBTArtworkWrapperProps>`
  display: flex;
  width: 100%;
  height: 100%;
  padding-left: 73px;
  flex-direction: column;

  .row {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
  }

  .column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .top-row {
    height: 70px;
  }

  .navigation-column,
  .preview-block-column {
    flex-basis: 180px;
  }

  .navigation-column {
    border-bottom: 1px solid ${colors.navyGrey[400]};
    border-right: 1px solid ${colors.navyGrey[400]};
  }

  .preview-block-column {
    border-right: 1px solid ${colors.navyGrey[400]};
    justify-content: ${({ loading }) => (loading && loading ? 'center' : 'flex-start')};
  }

  .center {
    justify-content: center;
    align-items: center;
  }

  .unset {
    justify-content: unset;
    padding-top: 50px;
  }

  .hide {
    display: none;
  }
`
