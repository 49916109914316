/* eslint-disable import/no-unresolved */
import { BTComingSoon } from 'components/shared/bt-coming-soon'
import { BTGlobalAssetsPage } from 'layouts/bt-global-assets-page'
import { RouteObject } from 'react-router-dom'
import { BTAssetView } from '../../components/AssetView'

const GlobalAssetsRoutes: RouteObject[] = [
  { path: 'global-assets', element: <BTGlobalAssetsPage /> },
  { path: 'global-assets/asset/:fileId', element: <BTAssetView /> },
  { path: 'global-assets/archived', element: <BTComingSoon /> },
]
export { GlobalAssetsRoutes }
