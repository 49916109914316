import React, { FC, MouseEvent } from 'react'
import LinkIcon from '@mui/icons-material/Link'
import CloseIcon from '@mui/icons-material/Close'
import type { TransitionProps, BTCopyLinkProps } from './bt-copy-link-button.props'
import { CustomSnackbar, TransitionUp } from './bt-copy-link-button.presets'
import { getFormattedPath } from 'helpers/getFormattedPath'

export const BTCopyLinkButton: FC<BTCopyLinkProps> = ({ path }) => {
  const [open, setOpen] = React.useState(false)
  const [transition, setTransition] =
    React.useState<React.ComponentType<TransitionProps> | undefined>(undefined)

  const handleClose = () => {
    setOpen(false)
  }
  const copyHandler = (event: MouseEvent) => {
    event.stopPropagation()
    setTransition(() => TransitionUp)
    setOpen(true)
    navigator.clipboard.writeText(window.location.href + getFormattedPath(path))
  }
  return (
    <>
      <LinkIcon sx={{ transform: 'rotate(-45deg)' }} onClick={copyHandler} />
      <CustomSnackbar
        open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={1400}
        onClose={handleClose}
        TransitionComponent={transition}
        message="Link copied to clipboard."
        key={transition ? transition.name : ''}
        action={<CloseIcon color="inherit" onClick={handleClose} />}
      />
    </>
  )
}
