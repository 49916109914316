import { setupOrganizations } from 'redux/LoginSlice'
import { useAppSelector, useAppDispatch } from 'redux/store'
import { getOrganizationMemberRole } from 'utils/getOrganizationMemberRole'
import { useUpdateOrganizationMemberRoleMutation } from 'generated/graphql'

export const useUpdateUserRole = () => {
  const { currentOrganizationId } = useAppSelector((state) => state.login)
  const allOrganizations = useAppSelector((state) => state.login.organizations)

  const dispatch = useAppDispatch()
  const [updateOrganizationMemberRole] = useUpdateOrganizationMemberRoleMutation()

  return async (userId: string, role: string) => {
    await updateOrganizationMemberRole({
      variables: {
        input: { userId, organizationId: currentOrganizationId, role },
      },
    })

    /** Update redux member role information */
    const allOrganizationsToChange = [...allOrganizations]

    const currentOrganizationIndex = allOrganizationsToChange.findIndex(
      (organization) => organization.id === currentOrganizationId
    )

    const organizationToChange = allOrganizationsToChange.splice(currentOrganizationIndex, 1)

    const targetMemberIndex = organizationToChange[0].members.findIndex(
      (member) => member.user.id === userId
    )

    const membersToChange = [...organizationToChange[0].members]

    const memberToChange = membersToChange.splice(targetMemberIndex, 1)[0]

    const updatedMember = { ...memberToChange, role: getOrganizationMemberRole(role) }

    membersToChange.splice(targetMemberIndex, 0, updatedMember)

    const updatedOrganization = { ...organizationToChange[0], members: membersToChange }

    const updatedAllOrganizations = [...allOrganizationsToChange, updatedOrganization]

    dispatch(setupOrganizations(updatedAllOrganizations))
  }
}
