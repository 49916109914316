/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
import { useEffect, useState } from 'react'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Popover } from '@mui/material'
import { BTAppsLabel } from 'components/SidebarApps/shared'
import { BTFilesDrop } from 'components/shared/bt-files-drop'
import { LoadingButton } from '@mui/lab'
import { BTPdfScrollableContainer } from './bt-brief-upload.presets'
import { Files } from '../../shared/bt-files-drop/bt-files-drop.types'
import { BTFilesTable } from './bt-files-table/bt-files-table'
import { useFileUpload } from '../../../hooks/file/useFileUpload'
import { useFileReplace } from '../../../hooks/file/useFileReplace'
import { toastNotifications } from '../../../helpers/toastNotifications'
import { useGetBriefQuery, useGetActivityByIdQuery } from '../../../generated/graphql'

export const BTBriefUploadDocument = () => {
  const uploadFile = useFileUpload()
  const replaceFile = useFileReplace()
  const navigate = useNavigate()
  const projectId = useParams().id
  const { pathname } = useLocation()
  const { activityId } = useParams()

  const [anchorEl, setAnchorEl] = useState(false)
  const [uploadedfiles, setUploadedFiles] = useState<Files | null>(null)
  const [loading, setLoading] = useState<boolean | undefined>(false)
  const [errorMessage, setErrorMessage] = useState<null | boolean | string>(null)

  const { data: briefData } = useGetBriefQuery({
    variables: { id: projectId },
  })

  const { data: activityData } = useGetActivityByIdQuery({
    variables: {
      input: activityId!,
    },
  })

  const uploadedDocument = activityData?.getActivityById.uploadedDocument

  const handleClose = () => {
    navigate(-1)
  }

  const addFile = (files: File[]): void => {
    setUploadedFiles(files)
  }
  const deleteFile = (): void => {
    setUploadedFiles(null)
  }

  const fileHandler = async () => {
    setLoading(true)
    try {
      if (briefData && !activityId) {
        // Replace file in current brief or activity
        await replaceFile(uploadedfiles)
        navigate(pathname.replace('/create/upload-document', ''))
      } else if (activityId && uploadedDocument) {
        // Replace file in current activity
        await replaceFile(uploadedfiles)
        navigate(pathname.replace('/create/upload', ''))
      } else if (activityId && !uploadedDocument) {
        // Add new file in activity
        await uploadFile(uploadedfiles)
        navigate(pathname.replace('/create/upload', ''))
      } else {
        // Сreate new brief and add file
        await uploadFile(uploadedfiles)
        navigate(pathname.replace('/create/upload-document', ''))
      }
    } catch (err) {
      const error = err as Error
      setLoading(false)
      setErrorMessage(error.message)
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const buttonItems = [{ line: 'Delete', handler: deleteFile }]

  useEffect(() => {
    setAnchorEl(true)
  }, [])

  useEffect(() => {
    if (errorMessage) {
      toastNotifications(errorMessage, setErrorMessage).error()
    }
  }, [errorMessage])

  return (
    <Popover
      id={id}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
    >
      <BTPdfScrollableContainer hideScrollbars={false}>
        <BTAppsLabel>Upload Document</BTAppsLabel>
        <BTFilesDrop handleDrop={addFile} fileType=".pdf" />
        {uploadedfiles && (
          <>
            <BTFilesTable files={uploadedfiles} buttonItems={buttonItems} />

            <div className="done-button">
              <LoadingButton
                variant="contained"
                sx={{ boxShadow: 3, padding: '8px 10px', width: '120px' }}
                loading={loading}
                onClick={fileHandler}
              >
                Done
              </LoadingButton>
            </div>
          </>
        )}
      </BTPdfScrollableContainer>
    </Popover>
  )
}
