import { TextField } from '@mui/material'
import { useAppSelector } from 'redux/store'
import { useState, FC, useEffect } from 'react'
import { useProjectSummariesQuery } from 'generated/graphql'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import type { ITaskCreationOption, IBTProjectSearchProps } from './bt-project-search.props'

const filter = createFilterOptions<ITaskCreationOption>()

export const BTProjectSearch: FC<IBTProjectSearchProps> = ({ formik, externalId }) => {
  const [value, setValue] = useState<ITaskCreationOption | null>(null)
  const { data, loading } = useProjectSummariesQuery()
  const [taskOptions, setTaskOptions] = useState<ITaskCreationOption[]>([])
  const { currentOrganizationId } = useAppSelector((store) => store.login)
  const userId = useAppSelector((store) => store.login.whoami?.id)

  const taskForUser = {
    name: 'User',
    id: userId,
  }

  const taskForOrganization = {
    name: 'Organization',
    id: currentOrganizationId,
  }

  const taskForProjects = data?.projects.map((elem) => ({
    name: `${elem.name}: ${elem.organization.name}`,
    id: elem.id,
  }))

  useEffect(() => {
    if (taskForProjects) {
      const inputOptions = [taskForUser, taskForOrganization, ...taskForProjects]
      setTaskOptions(inputOptions)
    }
  }, [loading])

  useEffect(() => {
    if (externalId && taskOptions) {
      const elem = taskOptions.filter(({ id }) => id === externalId)

      if (elem.length) {
        setValue(elem[0])
        formik.validateField('taskBelongsToId')
      }
    }
  }, [externalId, taskOptions])

  return (
    <div>
      <Autocomplete
        loading={loading}
        value={value}
        className="search"
        onChange={(event, newValue) => {
          if (typeof newValue !== 'string') {
            setValue(newValue)
            formik.setFieldValue('taskBelongsToId', newValue?.id || '')
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params)

          return filtered
        }}
        id="free-solo-dialog-demo"
        options={taskOptions}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option
          }
          if (option.name) {
            return option.name
          }
          return option.name
        }}
        selectOnFocus
        clearOnBlur
        ListboxProps={{ className: 'options-list' }}
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        freeSolo
        /** @description params: any - to resolve pipelines error */
        renderInput={(params: any) => (
          <TextField
            {...params}
            required
            error={formik.touched.taskBelongsToId && !!formik.errors.taskBelongsToId}
            helperText={formik.errors.taskBelongsToId}
          />
        )}
      />
    </div>
  )
}
