import { RouteObject } from 'react-router-dom'
import { BTBrief } from '../../components/Brief/bt-brief'
import { BTBriefBoard } from 'components/Brief/bt-brief-dnd'
import { ApolloClient, ApolloConsumer } from '@apollo/client'
import { ArtworkComposePage } from 'layouts/bt-artwork-compose-page'
import { BTBriefCreateItem } from 'components/Brief/brief-create-item'
import { BTCreateProjectForm } from 'components/bt-create-project-form'
import { BTProjectTasksLayout } from 'components/bt-project-tasks-layout'
import { BTActivityBoard } from 'components/ActivitiesBoard/activity-board'
import { BTProjectComingSoon } from 'components/shared/bt-project-coming-soon'
import { BTBriefDocument } from 'components/Brief/bt-brief-document/bt-brief-document'
import { BTActivityCreateItem } from 'components/ActivitiesBoard/activity-create-item'
import { BTBriefUploadDocument } from 'components/Brief/bt-brief-upload/bt-brief-upload'
import { ActivityPage } from '../../components/ActivitiesBoard/activity-page/activity-page'

const ProjectsRoutes: RouteObject[] = [
  { path: 'create-project', element: <BTCreateProjectForm /> },
  {
    path: 'projects/:id',
    element: (
      <ApolloConsumer>
        {(client: ApolloClient<object>) => <BTActivityBoard client={client} />}
      </ApolloConsumer>
    ),
  },
  { path: 'projects/:id/brief', element: <BTBrief /> },
  { path: 'projects/:id/calendar', element: <BTProjectComingSoon /> },
  { path: 'projects/:id/time-tracking', element: <BTProjectComingSoon /> },
  {
    path: 'projects/:id/brief/create/compose-template',
    element: <BTBriefBoard />,
  },
  {
    path: 'projects/:id/brief/create',
    element: <BTBriefCreateItem />,
  },
  {
    path: 'projects/:id/brief/create/compose-document',
    element: <BTBriefDocument />,
  },
  {
    path: 'projects/:id/brief/create/upload-document',
    element: <BTBriefUploadDocument />,
  },
  { path: 'projects/:id/tasks', element: <BTProjectTasksLayout /> },
  { path: 'projects/:id/chat', element: <BTProjectComingSoon /> },
  { path: 'projects/:id/archived', element: <BTProjectComingSoon /> },
  {
    path: 'projects/:id/activity/:activityId/',
    element: <ActivityPage />,
  },
  {
    path: 'projects/:id/activity/:activityId/create/',
    element: <BTActivityCreateItem />,
  },
  {
    path: 'projects/:id/activity/:activityId/create/compose',
    element: <BTBriefDocument />,
  },
  {
    path: 'projects/:id/activity/:activityId/create/upload',
    element: <BTBriefUploadDocument />,
  },
  {
    path: 'projects/:id/artwork/:artworkId',
    element: <ArtworkComposePage />,
  },
  {
    path: 'projects/:id/gantt',
    element: <BTProjectComingSoon />,
  },
  {
    path: 'projects/:id/scheduling',
    element: <BTProjectComingSoon />,
  },
]

export { ProjectsRoutes }
