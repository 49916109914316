import { useEffect } from 'react'
import { useAppDispatch } from 'redux/store'
import EditIcon from '@mui/icons-material/Edit'
import PublishIcon from '@mui/icons-material/Publish'
import { setupBriefId } from 'redux/BriefCreationSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSetupHeaderData } from 'hooks/header/useSetupHeaderData'
import { ReactComponent as ViewLogo } from 'assets/images/viewLogo.svg'
import { Item, Wrapper, Heading } from '../../shared/bt-styled-components'

export const BTBriefCreateItem = () => {
  const navigation = useNavigate()
  const { pathname } = useLocation()
  const dispatch = useAppDispatch()

  useSetupHeaderData()

  const handleComposeTemplate = () => {
    navigation('compose-template')
  }

  const handleComposeDocument = () => {
    navigation(`${pathname}/compose-document`)
  }

  const handleUploadDocument = () => {
    navigation(`${pathname}/upload-document`)
  }

  useEffect(() => {
    dispatch(setupBriefId(''))
  }, [])

  return (
    <Wrapper>
      <Heading>Create Brief</Heading>
      <Item onClick={handleComposeDocument}>
        <span>Compose Document</span>
        <EditIcon />
      </Item>
      <Item onClick={handleComposeTemplate}>
        <span>Compose Template</span>
        <ViewLogo />
      </Item>
      <Item onClick={handleUploadDocument}>
        <span>Upload Brief</span>
        <PublishIcon />
      </Item>
    </Wrapper>
  )
}
