import { FC } from 'react'
import { TutorialArrow } from './tutorialArrow'
import { BTNoAssetsTutorialWrapper } from './bt-no-assest-tutorial.presets'

export const BTNoAssetsTutorial: FC = () => {
  return (
    <BTNoAssetsTutorialWrapper>
      <div className="content">
        <div className="title">No Assets</div>
        <div className="info">Upload assets here.</div>
        <div className="tutorial-arrow">
          <TutorialArrow />
        </div>
      </div>
    </BTNoAssetsTutorialWrapper>
  )
}
