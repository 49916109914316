import ScrollContainer from 'react-indiana-drag-scroll'
import styled from 'styled-components'
import { colors } from 'theme/Colors'

const CustomScrollContainer = styled(ScrollContainer)`
  height: 460px;
  width: 270px;
  padding: 15px;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &::-webkit-scrollbar {
    background-color: transparent;
    height: 10px;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.navyGrey[200]};
    border-radius: 10px;
  }
`

const BTActivityList = styled.div`
  padding: 50px;
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export { CustomScrollContainer, BTActivityList }
