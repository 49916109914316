/* eslint-disable max-len */
export const SaveButtonAnchorIcon = () => {
  return (
    <svg width="6" height="7" viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.69564 4.4438L0.669477 5.85378C0.268479 5.99421 -3.2793e-07 6.3727 -2.74899e-07 6.79758L-1.09808e-06 0.202421C-1.04505e-06 0.627297 0.268477 1.00579 0.669475 1.14622L4.69564 2.5562C5.58827 2.86881 5.58828 4.13119 4.69564 4.4438Z"
        fill="#0066FF"
      />
    </svg>
  )
}
