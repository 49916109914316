import styled from 'styled-components'
import type { ICardWrapperProps } from './bt-card-label.props'

export const CardLabelWrapper = styled.div<ICardWrapperProps>`
  background: ${({ bgColor, isTransparent }: ICardWrapperProps) =>
    isTransparent ? `${bgColor}40` : bgColor};
  color: ${({ textColor }: ICardWrapperProps) => textColor || `#fff`};
  ${({ isOutlined, bgColor, textColor }: ICardWrapperProps) =>
    isOutlined &&
    `
    background-color: transparent;
    color: ${textColor || bgColor};
    border: 1px solid ${bgColor};
  `}

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 5px;
  white-space: pre-wrap;
  width: fit-content;

  & p {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: -0.24px;
    margin: 0;
  }
`
