/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { FC } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import ScrollContainer from 'react-indiana-drag-scroll'
import { Typography, Backdrop, Modal, Fade, IconButton } from '@mui/material'
import { BTMembersList } from './bt-selected-members-modal.presets'
import { IMember } from '../bt-entry-step/bt-entry-step.helpers'
import { BTSelectedMembersModalProps } from './bt-selected-members-modal.props'
import { BTMemberListItem } from '../../../../shared/bt-project-add-members-step/components/bt-member-list-item'
import { MembersListModalWrapper } from '../../../../shared/bt-project-add-members-step/components/bt-mebers-list-modal/bt-mebers-list-modal.presets'

export const BTSelectedMembersModal: FC<BTSelectedMembersModalProps> = ({
  isOpen,
  selectedMembers,
  unselectHandler,
  handleCloseModal,
}) => {
  const previewMembersList = selectedMembers.map((member: IMember) => {
    const { id, avatarUrl, name } = member.user

    if (id && avatarUrl && name) {
      return (
        <BTMemberListItem
          key={member.id}
          id={id}
          avatarUrl={avatarUrl}
          name={name}
          isSelected
          onClick={unselectHandler}
        />
      )
    }
    return <div />
  })

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={isOpen}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <MembersListModalWrapper>
          <IconButton onClick={handleCloseModal} className="close-button">
            <CloseIcon />
          </IconButton>
          <div className="main-modal-block">
            <Typography className="transition-modal-title" variant="h5" component="span">
              Assigned members
            </Typography>
            <BTMembersList>
              <ScrollContainer
                className="members-list"
                hideScrollbars={false}
                horizontal={false}
                vertical
              >
                {previewMembersList}
              </ScrollContainer>
            </BTMembersList>
          </div>
        </MembersListModalWrapper>
      </Fade>
    </Modal>
  )
}
