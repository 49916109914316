import { styled as MUIStyled } from '@mui/material'
const DrawerHeader = MUIStyled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 1),
  '& button': {
    alignSelf: 'flex-start',
  },
  ...theme.mixins.toolbar,
}))

export { DrawerHeader }
