import styled from '@emotion/styled'
import { colors } from '../../../../../theme/Colors'

const ApprovedByWrapper = styled.div`
  border-top: 1px solid ${colors.navyGrey[200]};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;

  span {
    font-size: 10px;
    line-height: 10px;
  }

  .approved-by {
    display: flex;
    align-items: center;

    span {
      margin-right: 5px;
    }
  }
`

export { ApprovedByWrapper }
