import React, { FC } from 'react'
import { useAppSelector } from 'redux/store'
import { DataTransform } from 'services/dataTransform'
import { mapSelectOptions } from './bt-questions-page-select.helpers'
import { BTSelect } from './bt-questions-page-select.presets'
import { BTQuestionsPageSelectProps, Option } from './bt-questions-page-select.props'

export const BTQuestionsPageSelect: FC<BTQuestionsPageSelectProps> = ({
  options,
  question,
  handler,
  defaultOption,
  isMulti = false,
}) => {
  const { currentDeliverable } = useAppSelector((state) => state.sidebarApps)
  const selectHandler = (external: unknown) => {
    const externalOption = external as Option
    const { id, questionTypeId } = question
    handler({
      content: DataTransform.serialize(externalOption),
      questionId: id,
      type: questionTypeId,
      deliverableId: currentDeliverable.relationId || currentDeliverable.id,
    })
  }
  return (
    <BTSelect
      isMulti={isMulti}
      onChange={selectHandler}
      defaultValue={defaultOption}
      options={mapSelectOptions(options)}
    />
  )
}
