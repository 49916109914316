import { LoadingButton } from '@mui/lab'
import { Button } from '@mui/material'
import { FC } from 'react'
import { BTConfirmationModalWindowWrapper } from './bt-confirmation-modal.presets'

import type { IBTConfirmationModalWindowProps } from './bt-confirmation-modal.props'

export const BTConfirmationModalWindow: FC<IBTConfirmationModalWindowProps> = ({
  acceptAction,
  declineAction,
  acceptButtonText,
  description,
  title = 'Are you sure?',
  isLoading = false,
}) => {
  return (
    <BTConfirmationModalWindowWrapper>
      <span className="title">{title}</span>
      <span className="description">{description}</span>
      <div className="buttons">
        <LoadingButton loading={isLoading} variant="contained" onClick={acceptAction}>
          {acceptButtonText}
        </LoadingButton>
        <Button variant="contained" color="secondary" onClick={declineAction} disabled={isLoading}>
          Cancel
        </Button>
      </div>
    </BTConfirmationModalWindowWrapper>
  )
}
