/* eslint-disable import/no-unresolved */
import { colors } from 'theme/Colors'
import styled from 'styled-components'

export const BTNotificationCardMainWrapper = styled.div<{
  isRead: boolean
  selectable?: boolean
}>`
  padding: 5px;
  width: 100%;
  border-radius: 5px;
  background: ${({ isRead }) => (isRead ? 'none' : colors.projectColors.lightGrey)};

  cursor: ${({ selectable }) => (selectable ? 'pointer' : 'auto')};

  &.not-readed {
    background: ${colors.projectColors.lightGrey};
  }
`

export const BTNotificationCardContentWrapper = styled.div<{
  isSelected: boolean
}>`
  padding: 5px;
  border: 1px dashed ${({ isSelected }) => (isSelected ? colors.navyGrey[500] : 'none')};
  border-radius: 5px;

  display: flex;
`

export const BTAvatarWrapper = styled.span`
  margin: 0px;
  margin-right: 10px;
`

export const BTCentralWrapper = styled.span`
  margin-right: 10px;
  width: 190px;
`

export const BTTime = styled.div`
  margin-bottom: 10px;

  ${({ theme }) => theme.typography.time}
  color: ${colors.projectColors.iconDarkGrey};
`

export const BTMessage = styled.div`
  max-width: 216px;
  ${({ theme }) => theme.typography.caption}
  line-height: 14px;

  color: ${colors.black};
`

export const BTTimeWrapper = styled.span`
  width: 50px;
  text-align: right;
`
