import styled from 'styled-components'

const BTQuestionsRowWrapper = styled.div`
  /* overflow: hidden; */
  border-radius: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`
const BTQuestionsRow = styled.div`
  position: relative;
  width: 100%;
  background: #f2f2f2;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const BTQuestionsRowContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

export { BTQuestionsRow, BTQuestionsRowContainer, BTQuestionsRowWrapper }
