import { FC } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { ActionButtonIconType } from 'redux/HeaderSlice'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import type { IBTButtonIconByType } from './bt-button-icon-by-type.props'
import { ReactComponent as SettingsIcon } from 'assets/images/settingsIconGrey.svg'

export const BTButtonIconByType: FC<IBTButtonIconByType> = ({ type }) => {
  switch (type) {
    case ActionButtonIconType.FAVOURITE:
      return <FavoriteBorderIcon fontSize="small" />

    case ActionButtonIconType.HORIZONTAL_MORE:
      return <MoreHorizIcon fontSize="small" />

    case ActionButtonIconType.VERTICAL_MORE:
      return <MoreVertIcon fontSize="small" />

    case ActionButtonIconType.SETTINGS:
      return <SettingsIcon />

    default:
      return <></>
  }
}
