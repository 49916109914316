/* eslint-disable jsx-a11y/label-has-associated-control */
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useDropzone } from 'react-dropzone'
import AvatarEditor from 'react-avatar-editor'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { IUserSettingSnapshot } from './bt-user-settings.props'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { BTUserSettingWrapper } from './bt-user-settings.prestes'
import { BTChangePassowrdModal } from './components/bt-change-password-modal'
import { useMeWithOrganizationQuery, useUpdateUserMutation } from 'generated/graphql'
import { Button, InputAdornment, OutlinedInput, Slider, TextField, Typography } from '@mui/material'

const SettingValidationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email address')
    .required('Required')
    .max(100, '100 characters max'),
  name: Yup.string().required('Client name is required!'),
})

export const BTUserSettings = () => {
  const [isEdited, setIsEdited] = useState(false)
  const [avatarScale, setAvatarScale] = useState(1)
  const [image, setImage] = useState<any>('')
  const [snapshot, setSnapshot] = useState<IUserSettingSnapshot>()
  const [isOpened, setIsOpened] = useState(false)
  const [updateUser] = useUpdateUserMutation()

  const { data } = useMeWithOrganizationQuery()

  const editorRef = useRef<AvatarEditor>(null)

  const handleModalOpen = () => setIsOpened(true)
  const handleModalClose = () => setIsOpened(false)

  const editHandler = () => {
    setIsEdited((prevState) => !prevState)
  }

  const changeAvatarScale = (event: Event, value: number | number[]) => {
    if (typeof value === 'number') {
      setAvatarScale(value)
    }
  }

  const saveImage = () => {
    if (editorRef.current) {
      const canvas: AvatarEditor = editorRef.current
      const resultImage = canvas?.getImage().toDataURL('image/jpeg')

      setImage(resultImage)
      setSnapshot((prevState) => ({
        ...prevState,
        avatar: resultImage,
      }))
    }
  }

  const onDrop = (files: File[]) => {
    files.forEach((e) => {
      const reader = new FileReader()
      reader.onload = () => {
        setImage(reader.result)
      }
      reader.readAsDataURL(e)
    })
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/jpeg', 'image/png'],
    onDrop,
    noDrag: !isEdited,
    noClick: true,
  })

  const onSubmit = async () => {
    if (snapshot !== undefined && data?.meWithOrganization) {
      const { id } = data.meWithOrganization

      await updateUser({
        variables: { input: { id, ...snapshot } },
        optimisticResponse: {
          updateUser: {
            user: {
              id,
              name: snapshot.name || data.meWithOrganization.name,
              email: snapshot.email || data.meWithOrganization.email,
              avatarUrl: image,
            },
          },
        },
      })
    }
  }

  const formik = useFormik({
    initialValues: {
      name: 'Fullname',
      email: 'Email',
    },
    validationSchema: SettingValidationSchema,
    onSubmit,
    validateOnChange: true,
  })

  useEffect(() => {
    formik.setFieldValue('name', data?.meWithOrganization?.name)
    formik.setFieldValue('email', data?.meWithOrganization?.email)
    setImage(data?.meWithOrganization?.avatarUrl)
  }, [data])

  return (
    <BTUserSettingWrapper sx={{ boxShadow: 3 }}>
      <div className="main-block">
        <div className="top-block">
          <Typography variant="h5" className="title">
            My Details
          </Typography>
          {!isEdited && (
            <Button
              variant="text"
              endIcon={<ModeEditIcon fontSize="small" />}
              onClick={editHandler}
            >
              Edit
            </Button>
          )}
        </div>
        <div className="settings-block">
          <div className="inputs-block">
            <div className="input-block">
              <span className="input-label">Full name</span>
              <TextField
                variant="outlined"
                fullWidth
                error={!!formik.errors.name}
                helperText={formik.errors.name}
                name="name"
                onChange={({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  formik.setFieldValue('name', target.value)
                  setSnapshot((prevState) => ({
                    ...prevState,
                    name: target.value,
                  }))
                }}
                value={formik.values.name}
                disabled={!isEdited}
                inputProps={{ defaultValue: data?.meWithOrganization?.name || 'Full name' }}
                required
              />
            </div>
            <div className="input-block">
              <span className="input-label">Email</span>
              <TextField
                variant="outlined"
                fullWidth
                error={!!formik.errors.email}
                helperText={formik.errors.email}
                name="email"
                onChange={({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                  formik.setFieldValue('email', target.value)
                  setSnapshot((prevState) => ({
                    ...prevState,
                    email: target.value,
                  }))
                }}
                value={formik.values.email}
                disabled={!isEdited}
                inputProps={{ defaultValue: data?.meWithOrganization?.email || 'Email' }}
                required
              />
            </div>
            <div className="input-block">
              <span className="input-label">Password</span>
              <OutlinedInput
                fullWidth
                type="password"
                disabled={!isEdited}
                readOnly
                onClick={isEdited ? handleModalOpen : undefined}
                endAdornment={
                  <InputAdornment position="end">
                    <VisibilityIcon />
                  </InputAdornment>
                }
                inputProps={{
                  defaultValue: 'Full name',
                }}
              />
            </div>
            <div className="input-block">
              <span className="input-label">Position</span>
              <TextField
                variant="outlined"
                focused
                disabled
                inputProps={{ defaultValue: data?.meWithOrganization?.position || '' }}
              />
            </div>
            {isEdited && (
              <Button
                onClick={() => {
                  if (data?.meWithOrganization?.avatarUrl !== image) {
                    saveImage()
                  }
                  editHandler()
                  formik.handleSubmit()
                }}
                variant="contained"
                className="save-button"
              >
                Save Changes
              </Button>
            )}
          </div>
          <div className="avatar-block">
            <span className="avatar-title">Profile Photo</span>

            <div {...getRootProps({ className: 'avatar' })}>
              <AvatarEditor
                image={image}
                width={110}
                height={110}
                borderRadius={150}
                scale={avatarScale}
                ref={editorRef}
              />
              <input id="file" {...getInputProps()} />
              {!isEdited && <div className="holder" />}
            </div>

            {isEdited && (
              <>
                <Slider step={0.1} onChange={changeAvatarScale} min={1} max={10} />
                <label htmlFor="file" className="file-label">
                  <div className="inner-wrapper">
                    <FileUploadIcon />
                    <div className="text">
                      <div>Drag and Drop or Click to Upload</div>
                      <div>Dimensions 123 x 123</div>
                    </div>
                  </div>
                </label>
              </>
            )}
          </div>
        </div>
      </div>
      <BTChangePassowrdModal
        isOpened={isOpened}
        handleClose={handleModalClose}
        email={data?.meWithOrganization?.email || ''}
      />
    </BTUserSettingWrapper>
  )
}
