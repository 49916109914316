import { FC, useState } from 'react'
import { Collapse } from '@mui/material'
import { UserFragment } from 'generated/graphql'
import { useRemoveFeedbackMessage, useUpdateFeedbackMessage } from '../../../hooks/feedback'
import { BTFeedbackMessageWrapper } from './bt-feedback-message.presets'
import { BTMessage } from './components/bt-message'

import type { IBTFeedbackMessageProps } from './bt-feedback-message.props'
import type {
  IBTMessageMetaData,
  IBTMessageHandlers,
  IBTMessageStyle,
} from './components/bt-message/bt-message.props'
import { BTCollabseButton } from './components/bt-collapse-button'

export const BTFeedbackMessage: FC<IBTFeedbackMessageProps> = ({
  message,
  activityId,
  positionNumber,
  numberOfMessages,
}) => {
  const {
    user,
    content,
    createdAt,
    replyedComments,
    feedbackId,
    messageId,
    feedbackMessageId,
    completedAt,
    pinned,
    members,
  } = message

  const removeFeedback = useRemoveFeedbackMessage()
  const updateFeedback = useUpdateFeedbackMessage()

  const [collapse, setCollapse] = useState(false)

  const removeFeedbackHandler = async () => {
    if (messageId && feedbackId) {
      await removeFeedback(messageId, feedbackId)
    }
  }

  const removeReplyFeedbackHandler = async (childMessageId: string) => {
    if (messageId && feedbackId && childMessageId) {
      await removeFeedback(messageId, feedbackId, childMessageId)
    }
  }

  const changePinStatusFeedbackHandler = async () => {
    if (messageId && feedbackId && feedbackMessageId) {
      await updateFeedback(feedbackId, messageId, undefined, {
        feedbackMessageId,
        pinned: !pinned,
      })
    }
  }

  const changeReplyFeedbackPinStatus = async (
    childMessageId: string,
    replyPinned: boolean,
    currentFeedbackMessageId: string
  ) => {
    if (feedbackId && currentFeedbackMessageId && messageId) {
      await updateFeedback(
        feedbackId,
        messageId,
        undefined,
        {
          feedbackMessageId: currentFeedbackMessageId,
          pinned: !replyPinned,
        },
        childMessageId
      )
    }
  }

  const changeCompleteStatusHandler = async () => {
    if (messageId && feedbackId && feedbackMessageId) {
      await updateFeedback(feedbackId, messageId, undefined, {
        feedbackMessageId,
        complete: completedAt === null,
        pinned: pinned!,
      })
    }
  }

  const changeReplyCompleteStatusHandler = async (
    childMessageId: string,
    currentFeedbackMessageId: string,
    replyPinned: boolean,
    replyCompletedAt?: Date | null
  ) => {
    if (feedbackId && currentFeedbackMessageId && messageId) {
      await updateFeedback(
        feedbackId,
        messageId,
        undefined,
        {
          feedbackMessageId: currentFeedbackMessageId,
          pinned: replyPinned,
          complete: replyCompletedAt === null,
        },
        childMessageId
      )
    }
  }

  const updateMessageHandler = async (value: string) => {
    if (feedbackId && value && messageId) {
      await updateFeedback(feedbackId, messageId, value)
    }
  }

  const member = (members && members.length > 0 && members[0]) || undefined

  const messageData: IBTMessageMetaData = {
    avatarUrl: user?.avatarUrl!,
    content: content!,
    createdAt,
    name: user?.name!,
    feedbackId: feedbackId!,
    activityId,
    parentFeedbackMessageId: feedbackMessageId!,
    member,
    position: positionNumber,
    relatedToUser: user as UserFragment,
  }

  const handlers: IBTMessageHandlers = {
    removeHandler: removeFeedbackHandler,
    changeCompleteStatusHandler,
    changePinStatusHandler: changePinStatusFeedbackHandler,
    updateMessageHandler,
  }

  const styleFlags: IBTMessageStyle = {
    completed: !!completedAt,
    pinned: pinned!,
  }

  return (
    <BTFeedbackMessageWrapper collapsed={collapse}>
      <BTMessage
        data={messageData}
        handlers={handlers}
        styleFlags={styleFlags}
        numberOfMessages={numberOfMessages}
      />
      {replyedComments && replyedComments.length > 0 && (
        <div className="reply-wrapper">
          <BTCollabseButton
            changeCollapseState={setCollapse}
            replyCommentsAmount={replyedComments.length}
          />
          <Collapse in={collapse} className="reply">
            {replyedComments.map(
              ({
                user: createdUser,
                content: text,
                createdAt: replyCreatedAt,
                repliedUser,
                messageId: childMessageId,
                completedAt: replyCompletedAt,
                pinned: replyPinned,
                feedbackMessageId: currentFeedbackMessageId,
                members: childMembers,
              }) => (
                <BTMessage
                  key={childMessageId}
                  data={{
                    name: createdUser?.name!,
                    avatarUrl: createdUser?.avatarUrl!,
                    content: text!,
                    createdAt: replyCreatedAt,
                    replyUserName: repliedUser?.name!,
                    feedbackId: feedbackId!,
                    activityId,
                    parentFeedbackMessageId: feedbackMessageId!,
                    relatedToUser: createdUser!,
                    childbackMessageId: currentFeedbackMessageId!,
                    member:
                      (childMembers && childMembers.length > 0 && childMembers[0]) || undefined,
                  }}
                  handlers={{
                    removeHandler: async () => {
                      await removeReplyFeedbackHandler(childMessageId!)
                    },
                    changeCompleteStatusHandler: async () => {
                      await changeReplyCompleteStatusHandler(
                        childMessageId!,
                        currentFeedbackMessageId!,
                        replyPinned!,
                        replyCompletedAt
                      )
                    },
                    changePinStatusHandler: async () => {
                      await changeReplyFeedbackPinStatus(
                        childMessageId!,
                        replyPinned!,
                        currentFeedbackMessageId!
                      )
                    },
                    updateMessageHandler: async (value: string) => {
                      if (feedbackId && messageId && value && childMessageId) {
                        await updateFeedback(
                          feedbackId,
                          messageId,
                          value,
                          undefined,
                          childMessageId
                        )
                      }
                    },
                  }}
                  styleFlags={{
                    completed: !!replyCompletedAt,
                    pinned: replyPinned!,
                  }}
                />
              )
            )}
          </Collapse>
        </div>
      )}
    </BTFeedbackMessageWrapper>
  )
}
