/* eslint-disable no-unused-expressions */
import React, { MouseEvent, memo, useMemo, SyntheticEvent } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Popover } from '@mui/material'
import type { BTMoreButtonProps } from './bt-more-button.props'
import { Wrapper, Item } from './bt-more-button.presets'
import { hash } from '../../../helpers/hash'
import { isItemsWithHandler } from './bt-more-button.helpers'

export const BTMoreButton = memo<BTMoreButtonProps>(({ index, isRounded, items, setFocus }) => {
  const [anchorEl, setAnchorEl] = React.useState<(EventTarget & Element) | null>(null)

  const handleClick = (event: MouseEvent) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
    setFocus && setFocus(true)
  }

  const handleClose = (event: MouseEvent) => {
    event.stopPropagation()
    setAnchorEl(null)

    setFocus && setFocus(false)
  }

  const MoreIcon = useMemo(() => (isRounded ? MoreHorizIcon : MoreVertIcon), [])

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const content = useMemo(
    () =>
      items.map((item) => {
        if (isItemsWithHandler(item)) {
          const onClick = (event: SyntheticEvent) => {
            item.handler(event)
            event.stopPropagation()
            handleClose(event as MouseEvent)
          }
          return (
            <Item key={hash(2)} onClick={onClick} id={index}>
              {item.line}
            </Item>
          )
        }
        return <Item key={hash(2)}>{item}</Item>
      }),
    [items]
  )
  return (
    <>
      <MoreIcon onClick={handleClick} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <Wrapper>{content}</Wrapper>
      </Popover>
    </>
  )
})
