/* eslint-disable max-len */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable import/no-unresolved */
import { Popover } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { BTFilesDrop } from 'components/shared/bt-files-drop'
import { MouseEvent, useState, SyntheticEvent, useEffect, FC } from 'react'
import { BTChangeCurrentOrganization } from '../../../../bt-change-current-organization/bt-change-current-organization'
import { BTFilesTable } from '../../../../Brief/bt-brief-upload/bt-files-table/bt-files-table'
import { useGlobalAssetsUpload } from '../../../../../hooks/file/useGlobalAssetsUpload/useGlobalAssetsUpload'
import { BTAssetsUploadButtonProps } from './bt-assets-upload.props'
import { BTAppsLabel } from '../../../../SidebarApps/shared'
import { useGetCurrentOrganization } from '../../../../../hooks/useGetCurrentOrganization'
import { GLOBAL_ASSETS_VALID_FILETYPES } from '../../../../../helpers/constants'
import {
  BTPublishIcon,
  BTAssetsPublish,
  BTAssetsScrollableContainer,
  BTLoadingButtonArea,
  BTUploadButtonText,
  BTChangeOrganization,
} from './bt-assets-upload.presets'

export const BTAssetsUploadButton: FC<BTAssetsUploadButtonProps> = ({
  filter,
  search,
  setPage,
}) => {
  const [state, setState] = useState<File[]>([])
  const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null)
  const [loading, setLoading] = useState(false)
  const [organizationModalOpen, setOrganizationModal] = useState(false)

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const globalAssetsUpload = useGlobalAssetsUpload()
  const currentOrganization = useGetCurrentOrganization()

  const handleOpenModal = (event?: MouseEvent) => {
    if (!event) return
    setAnchorEl(event.currentTarget)
  }

  const handleCloseModal = () => {
    setAnchorEl(null)
  }

  const addMoreFiles = (newFile: File[]) => {
    setState((prevState: File[]) => {
      return [...prevState, ...newFile]
    })
  }

  const removeFilesFromUpload = (event: SyntheticEvent) => {
    const index = Number(event.currentTarget.id)
    state.splice(index, 1)

    setState([...state])
  }

  const uploadGlobalAssets = async () => {
    globalAssetsUpload(state, setLoading, setAnchorEl, setState, filter, search)
    setPage(1)
  }

  const buttonItems = [
    {
      line: 'Remove',
      handler: removeFilesFromUpload,
    },
  ]

  const changeOrganizationModalOpen = () => {
    setOrganizationModal(true)
  }

  const changeOrganizationModalClose = () => {
    setOrganizationModal(false)
  }

  useEffect(() => {}, [state])

  return (
    <>
      <BTAssetsPublish
        index={Date.now().toString()}
        Button={BTPublishIcon}
        title="Upload"
        titleWeight={700}
        bgColor="#0066FF"
        onClick={handleOpenModal}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleCloseModal}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
      >
        <BTAssetsScrollableContainer hideScrollbars={false}>
          <BTAppsLabel>Upload Asset</BTAppsLabel>
          <BTFilesDrop
            handleDrop={addMoreFiles}
            text="Drop your file here or browse"
            fileType={GLOBAL_ASSETS_VALID_FILETYPES}
          />
          <BTChangeOrganization>
            This asset will be uploaded to{' '}
            <span>{currentOrganization && currentOrganization.name}</span>.
            <br /> If this is incorrect,{' '}
            <button
              className="change-organization-button"
              onClick={changeOrganizationModalOpen}
              type="button"
            >
              click here
            </button>
            .
          </BTChangeOrganization>
          <BTFilesTable files={state} buttonItems={buttonItems} />

          {state.length > 0 && (
            <BTLoadingButtonArea>
              <LoadingButton
                variant="contained"
                sx={{ boxShadow: 3, padding: '8px 10px', width: '120px' }}
                loading={loading}
                onClick={uploadGlobalAssets}
              >
                <BTUploadButtonText>DONE</BTUploadButtonText>
              </LoadingButton>
            </BTLoadingButtonArea>
          )}
        </BTAssetsScrollableContainer>
      </Popover>

      <BTChangeCurrentOrganization
        handleModalClose={changeOrganizationModalClose}
        isOpened={organizationModalOpen}
      />
    </>
  )
}
