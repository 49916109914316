/* eslint-disable max-len */
/* eslint-disable import/no-unresolved */
import { BTSendMachineProvider } from 'services/StateMachines/bt-apps-send/bt-apps-send'
import { BTActivitySendWrapper } from './components/bt-send-wrapper/bt-activity-send-wrapper'

export const BTActivitySend = () => {
  return (
    <BTSendMachineProvider>
      <BTActivitySendWrapper />
    </BTSendMachineProvider>
  )
}
