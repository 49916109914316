import { FC } from 'react'
import { Wizard } from 'react-use-wizard'
import { BTWizardEnd } from './components/bt-post-signup-form-end'
import { BTPostSignupFormPronouns } from './components/bt-post-signup-form-pronouns'
import { BTPostSignupFormStart } from './components/bt-post-signup-form-start'
import { BTPostSignupFormEditAvatar } from './components/bt-post-signup-form-edit-avatar'
import { BTPostSignupFormUploadAvatar } from './components/bt-post-signup-form-upload-avatar'

export const BTPostSignupForm: FC = () => {
  return (
    <Wizard>
      <BTPostSignupFormStart />
      <BTPostSignupFormUploadAvatar />
      <BTPostSignupFormEditAvatar />
      <BTPostSignupFormPronouns />
      <BTWizardEnd />
    </Wizard>
  )
}
