import styled, { keyframes } from 'styled-components'
import { ReactComponent as ArrowRightLogo } from 'assets/images/arrowRightLogoSidebar.svg'
import { colors } from 'theme/Colors'

const rotate = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`
const BTBlock = styled.div<{ open: boolean; isExtend: boolean }>`
  position: absolute;
  right: 100px;
  height: 100%;
  min-width: 388px;
  width: ${(props) => (props.isExtend ? 'clamp(700px, 60%, 1000px)' : 'clamp(350px, 20%, 400px)')};
  padding: 40px;
  background: ${colors.white};
  border: 1px solid ${colors.projectColors.iconGrey};
  border-top: none;
  border-right: none;
  transition: all 250ms ease;
  opacity: ${(props) => (props.open ? '1' : '0')};
  animation: ${rotate} 250ms linear 1;
  z-index: 1;
  box-shadow: ${(props) => (props.isExtend ? '-4px 0px 4px rgba(0, 0, 0, 0.15)' : 'none')};
`
const ArrowWrapper = styled.div`
  position: absolute;
  padding: inherit;
  top: 0;
  right: 0;
`
const ArrowLogoNew = styled(ArrowRightLogo)`
  cursor: pointer;
`
const BTBlockBackdrop = styled.div`
  top: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
`

export { BTBlock, ArrowWrapper, ArrowLogoNew, BTBlockBackdrop }
