/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable max-len */
import { FC, ReactChild, useMemo } from 'react'
import { BTBriefTasks } from 'components/SidebarApps/bt-brief-tasks'
import { BTActivityFeedback } from 'components/SidebarApps/bt-activity-feedback'
import { BTBriefAssets } from 'components/SidebarApps/bt-brief-assets'
import { BTBriefHistory } from 'components/SidebarApps/bt-brief-history'
import { BTActionsSidebar } from 'components/shared/bt-actions-sidebar'
import { BTAssets } from 'components/shared/bt-assets'
import { useSetupHeaderData } from 'hooks/header/useSetupHeaderData'
import ScrollContainer from 'react-indiana-drag-scroll'
import { BTApprove } from 'components/SidebarApps/bt-approve/bt-approve'
import { BTActivitySend } from 'components/SidebarApps/bt-activity-send'
import {
  Spellcheck,
  Send,
  FormatListBulleted,
  QuestionAnswer,
  Restore,
  Collections,
} from '@mui/icons-material'
import { SidebarProp } from 'components/shared/bt-actions-sidebar/bt-actions-sidebar.types'
import { ComposePageWrapper, ComposePageWrapperSub } from './brief-compose-page.presets'

export const ActivityComposePage: FC<{ children: ReactChild }> = ({ children }) => {
  const top: SidebarProp = useMemo(
    () => [
      { Logo: QuestionAnswer, application: BTActivityFeedback },
      /**
       * @desc commented by following ticket:
       * @link https://brieft-app.atlassian.net/browse/BRIEFT-499
       */
      // {
      //   Logo: Collections,
      //   application: BTBriefAssets,
      //   extendedApp: BTAssets,
      // },
      // { Logo: Restore, application: BTBriefHistory },
    ],
    []
  )
  const bottom: SidebarProp = useMemo(
    () => [
      { Logo: Spellcheck, application: BTApprove, name: 'approves' },
      { Logo: Send, application: BTActivitySend },
    ],
    []
  )
  useSetupHeaderData()

  return (
    <ComposePageWrapper>
      <ComposePageWrapperSub>
        <ScrollContainer
          hideScrollbars={false}
          horizontal={false}
          vertical
          className="scrol-wrapper-block"
          ignoreElements="*"
        >
          {children}
        </ScrollContainer>
      </ComposePageWrapperSub>
      <BTActionsSidebar type="ACTIVITIES" top={top} bottom={bottom} />
    </ComposePageWrapper>
  )
}
