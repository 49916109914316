import { LoadingButton } from '@mui/lab'
import { Avatar, InputAdornment, TextField } from '@mui/material'
import { FC, useState, useEffect, ChangeEvent, useCallback } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { PuffLoader } from 'react-spinners'
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from '@mui/icons-material/Clear'
import { colors } from '../../../../../theme/Colors'
import { useCurrentProject } from '../../../../../hooks'
import { BTChooseMessageAssigneeWrapper, BTUserItem } from './bt-choose-message-assignee.presets'

import type {
  IBTChooseMessageAssigneeProps,
  IBTUserItemData,
} from './bt-choose-message-assignee.props'
import { useAddFeedbackMember } from '../../../../../hooks/feedback'
import { useAppSelector } from 'redux/store'

export const BTChooseMessageAssignee: FC<IBTChooseMessageAssigneeProps> = ({
  feedbackId,
  selecetedUserId,
  parentFeedbackMessageId,
  childFeedbackMessageId,
  closeHandler,
}) => {
  const { data, loading: projectLoading } = useCurrentProject()

  const { id } = useAppSelector((state) => state.login.whoami)

  const addMember = useAddFeedbackMember()

  const [members, setMembers] = useState<IBTUserItemData[]>([])
  const [localSelectedUserId, setLocalSelectedUserId] = useState(selecetedUserId ?? '')
  const [searchValue, setSearchValue] = useState('')
  const [loading, setLoading] = useState(false)

  const setupUsers = () => {
    if (!loading && data && data.projects.length) {
      setMembers(
        data?.projects[0].members.map(({ user }) => ({
          ...user,
          selected: user.id === localSelectedUserId,
        }))
      )
    }
  }

  const selectUser = (userId: string) => {
    setMembers((prevState) => [
      ...prevState.map((user) => {
        const isCurrentUserId = user.id === userId
        let newUser = { ...user, selected: false }

        if (isCurrentUserId) {
          newUser = { ...user, selected: !user.selected }
          setLocalSelectedUserId(user.id)
        }
        return newUser
      }),
    ])
  }

  useEffect(() => {
    setupUsers()
  }, [loading])

  const filter = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target
    setSearchValue(value)

    if (value) {
      setMembers((prevState) => [
        ...prevState.filter(({ name }) =>
          name?.toLocaleLowerCase().includes(value.toLocaleLowerCase())
        ),
      ])
    } else {
      setupUsers()
    }
  }

  const save = async () => {
    if (data?.projects && data?.projects.length > 0) {
      const user = data?.projects[0].members.filter(
        ({ user: localUser }) => localUser.id === localSelectedUserId
      )

      if (user && user.length > 0) {
        setLoading(true)
        if (parentFeedbackMessageId && !childFeedbackMessageId) {
          await addMember(feedbackId, parentFeedbackMessageId, user[0].user)
        } else if (parentFeedbackMessageId && childFeedbackMessageId) {
          await addMember(feedbackId, parentFeedbackMessageId, user[0].user, childFeedbackMessageId)
        }
      }
    }
    setLoading(false)
    closeHandler()
  }

  const clearInput = useCallback(() => {
    setSearchValue('')
    setupUsers()
  }, [localSelectedUserId])

  return (
    <BTChooseMessageAssigneeWrapper>
      <span className="title">Assign Feedback Member</span>
      <TextField
        className="search"
        fullWidth
        value={searchValue}
        onChange={(event) => filter(event)}
        placeholder="Search"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {searchValue ? (
                <ClearIcon className="clear-icon" onClick={clearInput} />
              ) : (
                <SearchIcon />
              )}
            </InputAdornment>
          ),
        }}
      />
      {projectLoading && projectLoading ? (
        <PuffLoader size={50} color={colors.projectColors.main} />
      ) : (
        <ScrollContainer horizontal={false} vertical className="user-list" hideScrollbars={false}>
          {members.length > 0 ? (
            members.map(({ id: userId, name, avatarUrl, selected }) => (
              <BTUserItem
                selected={selected}
                key={userId}
                onClick={() => selectUser(userId)}
                disabled={id === userId}
              >
                <Avatar sx={{ width: 25, height: 25 }} src={avatarUrl} alt={name ?? id} />
                <span>{name}</span>
              </BTUserItem>
            ))
          ) : (
            <span className="not-found">No one was found, please try again</span>
          )}
        </ScrollContainer>
      )}
      <LoadingButton loading={loading} variant="contained" className="save-button" onClick={save}>
        Save
      </LoadingButton>
    </BTChooseMessageAssigneeWrapper>
  )
}
