import assert from 'assert'
import { useCurrentProject } from '../project'
import {
  useCreateBriefNoMilestonesMutation,
  ActivitySummaryFragment,
  ActivityType,
  ActivityStatus,
} from '../../generated/graphql'

export function useCreateBriefNoMilestones() {
  const project = useCurrentProject().project
  const [createBrief] = useCreateBriefNoMilestonesMutation()

  return async (name: string, authorId: string) => {
    assert(project)

    const existingActivities = project.activities
    const lastActivity = existingActivities[existingActivities.length - 1]
    const activity: ActivitySummaryFragment = {
      __typename: 'Brief',
      author: authorId,
      id: `newly-created-brief-${String(Math.random()).slice(2)}`,
      name,
      position: lastActivity ? lastActivity.position + 1000 : 0,
      updatedAt: new Date(),
      viewedAt: null,
      archivedAt: null,
      tasks: [],
      composedDocument: null,
      uploadedDocument: null,
      type: ActivityType.Brief,
      status: ActivityStatus.NewActivites,
    }
    const result = await createBrief({
      variables: {
        input: {
          projectId: project.id,
          name,
        },
      },
      optimisticResponse: {
        createBrief: {
          __typename: 'CreateBriefResponse',
          activity,
        },
      },
    })

    return result
  }
}
