/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import { FC } from 'react'
import { colors } from 'theme/Colors'
import { Avatar } from '@mui/material'
import ReactMarkdown from 'react-markdown'
import { setNotificationTime } from './helpers'
import MessageIcon from '@mui/icons-material/Message'
import { BTLinkToActivity } from '../bt-link-to-activity'
import { BTNotificationCardProps } from './bt-notification-card.props'
import { ReactComponent as BrieftLogo } from 'assets/images/brieftLogoBlue.svg'
import {
  BTTime,
  BTMessage,
  BTTimeWrapper,
  BTAvatarWrapper,
  BTCentralWrapper,
  BTNotificationCardMainWrapper,
  BTNotificationCardContentWrapper,
} from './bt-notification-card.presets'

export const BTNotificationCard: FC<BTNotificationCardProps> = ({
  id,
  date,
  isRead,
  onClick,
  technical,
  projectId,
  activityId,
  projectName,
  activityName,
  activityType,
  message = '',
  avatarUrl = '',
  clientName = '',
  isSelected = true,
  selectable = false,
  projectColor = 'grey',
}) => {
  const time = setNotificationTime(date)

  const handler = () => {
    onClick(id, selectable, isRead)
  }

  return (
    <BTNotificationCardMainWrapper isRead={isRead} onClick={handler} selectable={selectable}>
      <BTNotificationCardContentWrapper isSelected={isSelected}>
        <BTAvatarWrapper>
          {technical ? (
            <BrieftLogo />
          ) : (
            <Avatar alt="name" src={avatarUrl} sx={{ width: 20, height: 20 }} />
          )}
        </BTAvatarWrapper>

        <BTCentralWrapper>
          <BTMessage>
            <ReactMarkdown>{message}</ReactMarkdown>
          </BTMessage>

          <BTLinkToActivity
            projectId={projectId}
            activityId={activityId}
            clientName={clientName}
            projectName={projectName}
            projectColor={projectColor}
            activityType={activityType}
            activityName={activityName}
          />
        </BTCentralWrapper>

        <BTTimeWrapper>
          <BTTime>{time}</BTTime>
          {selectable && (
            <MessageIcon fontSize="small" style={{ fill: colors.projectColors.iconGrey }} />
          )}
        </BTTimeWrapper>
      </BTNotificationCardContentWrapper>
    </BTNotificationCardMainWrapper>
  )
}
