import { FC } from 'react'
import { Avatar } from '@mui/material'
import { ApprovedByWrapper } from './bt-approved-by.presets'
import type { IApprowedBy } from './bt-approved-by.props'

export const BTApprovedBy: FC<IApprowedBy> = ({ approvedBy }: IApprowedBy) => {
  const { name, imageUrl } = approvedBy

  return (
    <ApprovedByWrapper>
      <span className="approved-by-title">Approved by</span>
      <div className="approved-by">
        <span className="fullname">{name}</span>
        <Avatar src={imageUrl} alt={name} sx={{ height: 20, width: 20 }} />
      </div>
    </ApprovedByWrapper>
  )
}
