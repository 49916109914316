/* eslint-disable no-shadow */
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { FC, useEffect, useRef, useState } from 'react'
import { BTSaveButton } from 'components/shared/bt-save-button'
import { BTBriefDocumentProps } from './bt-brief-document.props'
import { CheckUserRoleAccess } from 'helpers/CheckUserRoleAccess'
import { BTDeleteButton } from 'components/shared/bt-delete-button'
import { modules, formats } from './bt-brief-document.quill-settings'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useCreateComposeDocument } from 'hooks/compose/useCreateComposeDocument'
import { useReplaceComposeDocument } from 'hooks/compose/useReplaceComposeDocument'
import { BTRenamableActivityTitle } from 'components/shared/bt-renamed-activity-title'
import {
  BTButtonsWrapper,
  BTMainPageWrapper,
  BTBriefDocumentWrapper,
} from './bt-brief-document.presets'
import {
  useGetBriefQuery,
  GetActivityByIdQuery,
  useDeleteBriefMutation,
  useGetActivityByIdQuery,
  GetActivityByIdDocument,
  useRemoveComposedDocumentMutation,
} from 'generated/graphql'

export const BTBriefDocument: FC<BTBriefDocumentProps> = ({ initialText = '' }) => {
  const [text, setText] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const { pathname } = useLocation()
  const { id: projectId, activityId } = useParams()
  const { data: briefData } = useGetBriefQuery({
    variables: { id: projectId },
  })
  const { data: activityData } = useGetActivityByIdQuery({
    variables: { input: activityId! || briefData?.getBrief.id || '' },
  })
  const composeNew = useCreateComposeDocument()
  const composeReplace = useReplaceComposeDocument()
  const navigate = useNavigate()
  const [deleteBrief] = useDeleteBriefMutation()
  const [removeComposedDocument] = useRemoveComposedDocumentMutation()

  const composedDocument = activityData?.getActivityById.composedDocument

  const saveComposedBrief = async () => {
    setLoading(true)
    if (briefData && !activityId) {
      // Replace composed document in current brief
      await composeReplace(text)
    } else if (composedDocument) {
      // Replace composed document in current activity
      await composeReplace(text)
    } else {
      // Сreate new brief and add composed document || Add/replace composed document in activity
      await composeNew(text)
      if (pathname.includes('brief')) {
        navigate(pathname.replace('/brief/create/compose-document', '/brief'))
      } else {
        navigate(pathname.replace(`/${activityId}/create/compose`, `/${activityId}`))
      }
    }
    setLoading(false)
  }

  const deleteComposedBrief = async () => {
    if (activityId && composedDocument) {
      await removeComposedDocument({
        variables: {
          input: {
            activityId,
            composeDocumentId: composedDocument?.id,
          },
        },
        errorPolicy: 'ignore',
        update: (cache) => {
          const readedQuery = cache.readQuery({
            query: GetActivityByIdDocument,
            variables: {
              input: activityId,
            },
          }) as GetActivityByIdQuery

          cache.writeQuery({
            query: GetActivityByIdDocument,
            variables: {
              input: activityId,
            },
            data: {
              getActivityById: {
                ...readedQuery,
                uploadedDocument: null,
              },
            },
          })
        },
      })
      navigate(pathname.replace(`/${activityId}`, `/${activityId}/create`))
    }
    if (briefData && !activityId) {
      await deleteBrief({
        variables: {
          id: briefData?.getBrief.id,
        },
        update(cache) {
          const normalizedId = cache.identify({
            id: briefData?.getBrief.id,
            __typename: 'Brief',
          })
          cache.evict({ id: normalizedId })
          cache.gc()
        },
      })
      navigate(pathname.replace('/brief', '/brief/create'))
    } else {
      navigate(pathname.replace('/brief/create/compose-document', '/brief/create'))
    }
  }

  const setName = () => {
    if (!activityId) {
      return <div className="default">Project Brief</div>
    }
    return <BTRenamableActivityTitle activityId={activityId} />
  }

  useEffect(() => {
    if (initialText) {
      setText(initialText)
    }
  }, [initialText])

  const id = activityId || briefData?.getBrief.id

  return (
    <BTMainPageWrapper>
      <BTBriefDocumentWrapper>
        <div className="activity-title">{setName()}</div>

        <div className="text-editor">
          <ReactQuill
            theme="snow"
            value={text}
            onChange={setText}
            modules={modules}
            formats={formats}
            placeholder="Type..."
          />
        </div>
      </BTBriefDocumentWrapper>

      <CheckUserRoleAccess activityId={id}>
        <BTButtonsWrapper>
          <BTSaveButton handler={saveComposedBrief} loading={loading} />
          <BTDeleteButton handler={deleteComposedBrief} />
        </BTButtonsWrapper>
      </CheckUserRoleAccess>
    </BTMainPageWrapper>
  )
}
