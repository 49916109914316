import { useAppSelector, useAppDispatch } from 'redux/store'
import { useDeleteDeliverablesMutation } from 'generated/graphql'
import { removeDeliverable, setupDeliverable } from 'redux/SidebarAppsSlice'

export function useDeleteDeliverables() {
  const dispatch = useAppDispatch()
  const { currentDeliverable, deliverables } = useAppSelector((state) => state.sidebarApps)
  const [deleteDeliverable] = useDeleteDeliverablesMutation()

  return async (name: string, id: string) => {
    dispatch(removeDeliverable({ name, id }))
    await deleteDeliverable({ variables: { ids: [id] } })
    if (deliverables.length <= 1) {
      dispatch(setupDeliverable({ name: '', id: '' }))
      return
    }
    if (currentDeliverable.id === id && deliverables.length > 1) {
      const deliverableIndex = deliverables.findIndex(
        (existDeliverable) => existDeliverable.id === id
      )
      const transformedDeliverableIndex = deliverableIndex === -1 ? 0 : deliverableIndex - 1
      dispatch(setupDeliverable(deliverables[transformedDeliverableIndex]))
    }
  }
}
