import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PuffLoader } from 'react-spinners'
import { useAppSelector } from 'redux/store'
import { useAddFeedback } from '../../../hooks/feedback'
import {
  useGetActivityByIdLazyQuery,
  useGetAllFeedbackMessagesLazyQuery,
  useGetBriefFeedbackIdLazyQuery,
} from '../../../generated/graphql'
import { BTAppsLabel, BTAppsScrollableContainer, BTAppsWrapper } from '../shared'
import { BTFeedbackSendBlock } from './bt-feedback-send-block'
import { BTFeedbackMessage } from '../../shared/bt-feedback-message'

const EMPTY_FEEDBACK_MESSAGES_ARR = 0

export const BTActivityFeedback = () => {
  const addFeedback = useAddFeedback()
  const { assetId } = useAppSelector((state) => state.sidebarApps)

  const [getFeedback, { data, loading }] = useGetAllFeedbackMessagesLazyQuery()
  const [getBrief, { loading: briefLoading }] = useGetBriefFeedbackIdLazyQuery()
  const [getActivity, { loading: activityLoading }] = useGetActivityByIdLazyQuery()

  const [feedbackId, setFeedbackId] = useState('')
  const [localActivityId, setLocalActivityId] = useState('')

  const { id, artworkId, activityId } = useParams()
  let positionNumber = 1

  useEffect(() => {
    if (artworkId || activityId) {
      setLocalActivityId(artworkId! ?? activityId!)
    }
  }, [artworkId, activityId])

  useEffect(() => {
    if (artworkId !== undefined || activityId !== undefined) {
      getActivity({ variables: { input: artworkId! || activityId! } }).then((activityData) =>
        setFeedbackId(activityData.data?.getActivityById.feedback.id!)
      )
    } else if (id) {
      getBrief({
        variables: { projectId: id },
      }).then((briefData) => {
        setFeedbackId(briefData.data?.getProjectBriefFeedbackId?.feedbackId!)
        setLocalActivityId(briefData.data?.getProjectBriefFeedbackId.briefId!)
      })
    }
  }, [])

  useEffect(() => {
    if (assetId) {
      getFeedback({
        variables: {
          input: { assetId },
        },
        fetchPolicy: 'cache-and-network',
      })
    } else if (feedbackId) {
      getFeedback({
        variables: {
          input: { feedbackId },
        },
        fetchPolicy: 'cache-and-network',
      })
    }
  }, [feedbackId, assetId])

  const createFeedback = async (value: string) => {
    if (feedbackId && localActivityId && value) {
      await addFeedback(localActivityId, value, feedbackId)
    }
  }

  const feedbacks = data?.getFeedbackMessagesByFeedbackId?.messages?.map((message) => (
    <BTFeedbackMessage
      key={message.feedbackMessageId}
      message={message}
      activityId={localActivityId}
      positionNumber={message.position ? positionNumber++ : undefined}
      numberOfMessages={data.getFeedbackMessagesByFeedbackId.messages.length}
    />
  ))

  const feedbackMessagesLenght = data?.getFeedbackMessagesByFeedbackId?.messages?.length
  // Commented because we dont have a notifications in the BE part of the app.

  return (
    <BTAppsWrapper>
      <BTAppsLabel>Feedback</BTAppsLabel>
      {loading || briefLoading || activityLoading ? (
        <div
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <PuffLoader color="#0066FF" size={50} />
        </div>
      ) : (
        <BTAppsWrapper>
          <BTAppsScrollableContainer hideScrollbars={false} className="feedback-scroll-wrapper">
            {feedbackMessagesLenght === EMPTY_FEEDBACK_MESSAGES_ARR ? (
              <span className="empty-feedback-title">
                There are no messages, start writing a new one.
              </span>
            ) : (
              feedbacks
            )}
          </BTAppsScrollableContainer>
        </BTAppsWrapper>
      )}
      <BTFeedbackSendBlock externalHandler={createFeedback} />
    </BTAppsWrapper>
  )
}
