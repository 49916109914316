/* eslint-disable import/no-unresolved */
import { FC, useEffect } from 'react'
import { useAppDispatch } from 'redux/store'
import { toggleExtend } from 'redux/SidebarAppsSlice'
import type { BTSidebarBlockProps } from './bt-sidebar-block.props'
import { BTBlock, ArrowWrapper, ArrowLogoNew } from './bt-sidebar-block.presets'
import { sendPurpose, sendMessage, adjustUsers } from '../../../../../redux/SidebarAppsSlice'

export const BTSidebarBlock: FC<BTSidebarBlockProps> = ({
  closeHandler,
  mounted,
  children,
  isExtended,
}) => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    return () => {
      dispatch(toggleExtend({ isExtended: false }))
      dispatch(sendPurpose(''))
      dispatch(sendMessage(''))
      dispatch(adjustUsers([]))
    }
  }, [])
  return (
    <BTBlock open={mounted} isExtend={isExtended}>
      <ArrowWrapper>
        <ArrowLogoNew onClick={closeHandler} />
      </ArrowWrapper>
      {children}
    </BTBlock>
  )
}
