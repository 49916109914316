import { FC } from 'react'
import { BTPaginationButtonProps } from './bt-pagination-button.props'
import { BTPaginationButtonWrapper } from './bt-pagination-button.presets'

export const BTPaginationButton: FC<BTPaginationButtonProps> = ({ handler }) => {
  const onClick = async () => {
    await handler()
  }

  return (
    <BTPaginationButtonWrapper type="button" onClick={onClick}>
      MORE
    </BTPaginationButtonWrapper>
  )
}
