import { CognitoUser } from '@aws-amplify/auth'
import { CognitoUserAttribute } from 'amazon-cognito-identity-js'
import { cognitoUserPool } from '../../helpers/cognito'
import { useLogin } from './useLogin'

export function useRegister(): (
  email: string,
  password: string,
  fullName: string,
  role: string,
  successPage?: string
) => Promise<CognitoUser> {
  const login = useLogin()

  return async (email, password, fullName, role) => {
    const attributeList = [
      new CognitoUserAttribute({
        Name: 'email',
        Value: email,
      }),
      new CognitoUserAttribute({
        Name: 'name',
        Value: fullName,
      }),
      new CognitoUserAttribute({
        Name: 'custom:role',
        Value: role,
      }),
    ]

    await new Promise<void>((resolve, reject) => {
      cognitoUserPool.signUp(email, password, attributeList, [], (err) => {
        if (err) {
          reject(err)
        } else {
          resolve()
        }
      })
    })

    return login(email, password)
  }
}
