import { Tab, Tabs, Typography } from '@mui/material'
import { FC, SyntheticEvent, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useLocation } from 'react-router-dom'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import type { IHeaderTabsProps } from './bt-header-tabs.props'
import { TabsWrapper } from './bt-header-tabs.presets'

export const BTHeaderTabs: FC<IHeaderTabsProps> = ({ labels }: IHeaderTabsProps) => {
  const [activeTab, setActiveTab] = useState(0)
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setActiveTab(newValue)
  }

  const location = useLocation()

  const getActiveTab = (): number => {
    let activeTabIndex = 0

    if (location.pathname) {
      labels.forEach(({ title }, index) => {
        if (location.pathname.includes(title.replace(' ', '-').toLowerCase())) {
          activeTabIndex = index
        }
      })
    }

    return activeTabIndex
  }

  useEffect(() => {
    const activeTabIndex = getActiveTab()
    setActiveTab(activeTabIndex)
  }, [labels])

  return (
    <TabsWrapper>
      <Tabs value={activeTab} onChange={handleChange} aria-label="header tabs">
        {labels.map(({ title, actionCallback, withIcon, secondaryCallback }, index) => (
          <Tab
            key={uuidv4()}
            label={
              <Typography variant="subtitle1" component="span">
                {title}
              </Typography>
            }
            id={`simple-tab-${index}`}
            aria-controls={`simple-tabpanel-${index}`}
            onClick={actionCallback}
            icon={withIcon ? <ArrowDropDownIcon onClick={secondaryCallback} /> : undefined}
            iconPosition="end"
          />
        ))}
      </Tabs>
    </TabsWrapper>
  )
}
