import { FC } from 'react'
import { CheckUserRoleAccess } from 'helpers/CheckUserRoleAccess'
import { BTAssets } from '../../components/shared/bt-assets/bt-assets'
import { BTGlobalAssetsWrapper } from './bt-global-assets-page.presets'

export const BTGlobalAssetsPage: FC = () => {
  return (
    <BTGlobalAssetsWrapper>
      <CheckUserRoleAccess redirect="/404">
        <BTAssets type="global" />
      </CheckUserRoleAccess>
    </BTGlobalAssetsWrapper>
  )
}
