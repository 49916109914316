import styled from 'styled-components'
import SearchIcon from '@mui/icons-material/Search'
import { SearchInputProps, SearchWrapperProps } from './bt-search.props'

const BTSearchInput = styled.input<SearchInputProps>`
  width: 100%;
  outline: none;
  background: transparent;
  border: none;
  color: #828282;
  &::placeholder {
    font-weight: 300;
    font-size: 10px;
    vertical-align: top;
  }
`

const BTSearchIconWrapper = styled.span<{ position?: string }>`
  display: flex;
  align-items: center;
  height: 100%;
  text-align: center;
  padding: ${({ position }) => (position === 'start' ? '0 0 0 10px' : '0 10px 0')};
  align-self: center;
`

const BTSearchWrapper = styled.div<SearchWrapperProps>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid;
  border-radius: ${({ borderStyle }) => (borderStyle === 'round' ? '50px' : '5px')};
  padding: ${({ searchSize }) => (searchSize === 'large' ? '6px' : '2px 4px')};
  border-color: ${({ borderColor }) => (borderColor === 'light' ? '#E0E0E0' : '#828282')};
  width: 100%;
`
const BTSearchIcon = styled(SearchIcon)`
  && {
    width: 14px;
    height: 14px;
    color: #828282;
  }
`

export { BTSearchInput, BTSearchIconWrapper, BTSearchWrapper, BTSearchIcon }
