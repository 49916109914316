import { ChangeEvent, FC, useCallback, useState, KeyboardEvent, useMemo } from 'react'
import { Avatar, ClickAwayListener, IconButton, InputAdornment, TextField } from '@mui/material'

import MessageIcon from '@mui/icons-material/Message'
import PushPinIcon from '@mui/icons-material/PushPin'
import CheckIcon from '@mui/icons-material/Check'

import { PuffLoader } from 'react-spinners'
import { useAppSelector } from 'redux/store'
import { useRemoveFeedbackMember } from '../../../../../hooks/feedback/useRemoveFeedbackMember'
import { BTModalWindow } from '../../../bt-modal-window'
import { getFileAge } from '../../../../../helpers/file/fileAge'
import { BTMessageWrapper } from './bt-message.presets'
import { BTMoreButton } from '../../../bt-more-button'
import { MoreButtonItems } from '../../../bt-more-button/bt-more-button.props'
import { useAddReplyFeedback } from '../../../../../hooks/feedback'
import { BTChooseMessageAssignee } from '../bt-choose-message-assignee'

import type { IBTMessage } from './bt-message.props'
import { BTEditMessage } from './components/bt-edit-message'

export const BTMessage: FC<IBTMessage> = ({ data, styleFlags, handlers, numberOfMessages }) => {
  const {
    createdAt,
    activityId,
    feedbackId,
    relatedToUser,
    childbackMessageId,
    parentFeedbackMessageId,
    content,
    avatarUrl,
    name,
    replyUserName,
    member,
    position,
  } = data

  const poisitionNumber = useMemo(() => position, [parentFeedbackMessageId, numberOfMessages])

  const { completed, pinned } = styleFlags

  const time = createdAt === undefined ? new Date().toString() : createdAt

  const addReplyComment = useAddReplyFeedback()
  const removeMember = useRemoveFeedbackMember()

  const userId = useAppSelector((state) => state.login.whoami.id)

  const [isInputOpen, setIsInputOpen] = useState(false)
  const [fieldValue, setFieldValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [focus, setFocus] = useState(false)
  const [isOpened, setIsOpened] = useState(false)
  const [editMessage, setEditMessage] = useState(false)

  const openChooseAssigneModal = () => setIsOpened(true)
  const closeChooseAssigneModal = () => setIsOpened(false)

  const openEditMessageHandler = () => setEditMessage(true)
  const closeEditMessageHandler = () => setEditMessage(false)

  const moreButtonItems: MoreButtonItems[] = [
    { handler: handlers.removeHandler, line: 'Delete' },
    { handler: openEditMessageHandler, line: 'Edit' },
  ]

  const onChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target

    setFieldValue(value)
  }

  const clickAway = () => {
    setFieldValue('')
    setIsInputOpen(false)
  }

  const removeMemberHandler = async () => {
    if (parentFeedbackMessageId && childbackMessageId && member) {
      await removeMember(feedbackId, parentFeedbackMessageId, member.id, childbackMessageId)
    } else if (parentFeedbackMessageId && member) {
      await removeMember(feedbackId, parentFeedbackMessageId, member.id)
    }
  }

  const memberActionHandler = async () => {
    if (member) {
      await removeMemberHandler()
    } else {
      openChooseAssigneModal()
    }
  }

  const sendHandler = useCallback(
    async (event: KeyboardEvent) => {
      if (event.key !== 'Enter') return
      setLoading(false)
      await addReplyComment(
        activityId,
        fieldValue,
        feedbackId,
        parentFeedbackMessageId!,
        relatedToUser
      )

      setLoading(false)
      clickAway()
    },
    [fieldValue]
  )

  return (
    <BTMessageWrapper focus={focus} pinned={pinned} completed={completed}>
      <div className="top-block">
        {position ? (
          <div className="position">{poisitionNumber}</div>
        ) : (
          <Avatar src={avatarUrl} alt={name || ''} sx={{ width: 20, height: 20 }} />
        )}
        <div className="info">
          <div className="created-user">
            <span className="name">{name}</span>
            <span className="time">
              {time ? getFileAge(time) : getFileAge(new Date().toString())}
            </span>
          </div>
          {replyUserName && (
            <span className="reply-user">
              reply to: <span>{replyUserName}</span>
            </span>
          )}
        </div>
      </div>
      {!editMessage ? (
        <div className="main-block">{content}</div>
      ) : (
        <BTEditMessage
          closeHandler={closeEditMessageHandler}
          content={content}
          editMessageHandler={handlers.updateMessageHandler}
        />
      )}
      {isInputOpen && (
        <ClickAwayListener onClickAway={clickAway}>
          <div className="input-block">
            <TextField
              value={fieldValue}
              onChange={onChange}
              placeholder="Reply"
              autoFocus
              onKeyDown={sendHandler}
              fullWidth
              autoComplete="off"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <PuffLoader loading={loading} color="#0066FF" size={10} />
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </ClickAwayListener>
      )}
      <div className={focus ? 'action-buttons show-buttons' : 'action-buttons'}>
        <div className="button-wrapper">
          {userId === relatedToUser?.id && (
            <IconButton size="small">
              <BTMoreButton items={moreButtonItems} setFocus={setFocus} isRounded />
            </IconButton>
          )}
          <IconButton size="small" onClick={() => setIsInputOpen((prevState) => !prevState)}>
            <MessageIcon fontSize="small" />
          </IconButton>
          {pinned && (
            <>
              <IconButton size="small" onClick={handlers.changeCompleteStatusHandler}>
                <CheckIcon fontSize="small" className="complete-icon" />
              </IconButton>
              <IconButton size="small" onClick={memberActionHandler}>
                <Avatar
                  src={member?.avatarUrl}
                  alt={member?.name ?? ''}
                  sx={{ width: 14, height: 14 }}
                />
              </IconButton>
            </>
          )}
          <IconButton size="small" onClick={handlers.changePinStatusHandler}>
            <PushPinIcon fontSize="small" className="pin-icon" />
          </IconButton>
        </div>
      </div>
      <div className="icons-block">
        {member && (
          <Avatar src={member?.avatarUrl} alt={member?.name ?? ''} sx={{ width: 14, height: 14 }} />
        )}
        {completed && <CheckIcon className="complete-icon" fontSize="small" />}
        {pinned && !completed && <PushPinIcon className="pinned-icon" fontSize="small" />}
      </div>
      <BTModalWindow isOpened={isOpened} handleClose={closeChooseAssigneModal}>
        <BTChooseMessageAssignee
          parentFeedbackMessageId={parentFeedbackMessageId!}
          childFeedbackMessageId={childbackMessageId}
          feedbackId={feedbackId}
          selecetedUserId={member?.id}
          closeHandler={closeChooseAssigneModal}
        />
      </BTModalWindow>
    </BTMessageWrapper>
  )
}
