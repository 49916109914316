import styled from 'styled-components'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { colors } from '../../../../../theme/Colors'

const BTConfirmBackButton = styled(ArrowBackIcon)`
  && {
    color: #ffffff;
    background: #bdbdbd;
    border-radius: 5px;
    margin: 30px 0;
    cursor: pointer;
  }
`

const BTLabel = styled.span`
  margin-bottom: 10px;

  font-weight: 600;
  font-size: 12px;
  line-height: 1.5;
  color: ${colors.projectColors.black};
`

const BTNavigationButtonsWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;

  .prev-button {
    padding: 0px;
    height: 38px;
    width: 20%;

    box-shadow: none;

    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .send-button {
    height: 38px;
    width: 80%;
    color: ${colors.white};
    background: ${colors.projectColors.main};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &:hover {
      background: #0151c9;
    }
  }

  .disabled {
    height: 38px;
    width: 80%;
    color: ${colors.white};
    background: ${colors.navyGrey[300]};
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`

const BTButtonsBorder = styled.div`
  height: 38px;
  border: 0.5px solid ${colors.white};
`

export { BTConfirmBackButton, BTLabel, BTNavigationButtonsWrapper, BTButtonsBorder }
