import { DateTime } from 'luxon'
import { BTAssignee } from '../bt-assignee'
import { useAppSelector } from 'redux/store'
import { BTCardLabel } from '../bt-card-label'
import { FC, useEffect, useState } from 'react'
import { BTMoreButton } from '../bt-more-button'
import { BTModalWindow } from '../bt-modal-window'
import ScrollContainer from 'react-indiana-drag-scroll'
import { useCreateTask } from 'hooks/task/useCreateTask'
import { BTCreateTaskForm } from '../bt-task-create-form'
import { useArchiveTask } from 'hooks/task/useArchiveTask'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { BTTaskDetailsWrapper } from './bt-task-details.presets'
import { Checkbox, FormControlLabel, Typography } from '@mui/material'
import { TaskRole, TaskStatus, useUpdateTaskMutation } from 'generated/graphql'
import type { IBTTaskDetailsProps, CheckListItem } from './bt-task-details.props'

export const BTTaskDetails: FC<IBTTaskDetailsProps> = ({
  id,
  labelData,
  taskName,
  description,
  dueDate,
  assigneesList,
  checklistItems,
  isOpened,
  handleClose,
  taskBelongsToId,
}) => {
  const [checklist, setChecklist] = useState<CheckListItem[]>([])
  const [updateTask] = useUpdateTaskMutation()
  const [createTask] = useCreateTask()
  const archiveTask = useArchiveTask()
  const [isEditModalOpened, setIsEditModalOpened] = useState(false)
  const { currentOrganizationId } = useAppSelector((store) => store.login)
  const userId = useAppSelector((store) => store.login.whoami?.id)

  const formattedDate = dueDate && DateTime.fromJSDate(new Date(dueDate)).toFormat('ha, dd.LL.yy')

  const openEditTaskModalHandler = () => setIsEditModalOpened(true)
  const closeEditTaskModalHandler = () => setIsEditModalOpened(false)

  const archiveHandler = async () => {
    await archiveTask(id, true)
    handleClose()
  }

  const duplicateTask = async () => {
    const userIdCondition = taskBelongsToId === userId ? taskBelongsToId : null
    const organizationIdCondition =
      taskBelongsToId === currentOrganizationId ? taskBelongsToId : null
    const projectIdCondition =
      taskBelongsToId === currentOrganizationId || taskBelongsToId === userId
        ? null
        : taskBelongsToId

    if (taskBelongsToId) {
      await createTask({
        variables: {
          input: {
            name: taskName,
            description,
            status: TaskStatus.Todo,
            userId: userIdCondition,
            organizationId: organizationIdCondition,
            projectId: projectIdCondition,
            activityId: null,
            completed: false,
            members: assigneesList.map(({ id: memberId }) => ({
              role: TaskRole.Assigned,
              userId: memberId!,
            })),
            checklist:
              checklistItems?.map(({ name }) => ({
                name,
                complete: false,
              })) ?? [],
            dueDate,
          },
        },
      })
      handleClose()
    }
  }

  useEffect(() => {
    if (checklistItems) {
      const checklistStateObject = checklistItems?.map((elem) => ({
        key: elem.id,
        isChecked: elem.completedAt != null,
        label: elem.name,
      }))

      setChecklist(checklistStateObject)
    }
  }, [checklistItems])

  const onClickHandler = async (event: any, key: string) => {
    event.stopPropagation()

    setChecklist((prevState) => {
      const index = prevState.findIndex((elem) => elem.key === key)

      const newState = [...prevState]
      newState[index].isChecked = !prevState[index].isChecked

      return newState
    })

    await updateTask({
      variables: {
        input: {
          id,
          checklistItems: checklist?.map(({ key: checklistItemId, label, isChecked }) => ({
            id: checklistItemId,
            name: label,
            complete: isChecked,
          }))!,
        },
      },
    })
  }

  const compare = (firstelem: string, secondelem: string) => {
    if (firstelem < secondelem) {
      return -1
    }
    if (firstelem > secondelem) {
      return 1
    }
    return 0
  }

  const checklistBlock =
    checklist &&
    checklist
      .sort((first, second) => compare(first.key, second.key))
      .map((elem) => (
        <FormControlLabel
          checked={elem.isChecked}
          control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }} />}
          label={elem.label}
          key={elem.key}
          data-key={elem.key}
          onChange={(event) => onClickHandler(event, elem.key)}
        />
      ))

  const moreButtonItems = [
    { line: 'Archive', handler: archiveHandler },
    { line: 'Duplicate', handler: duplicateTask },
    { line: 'Edit', handler: openEditTaskModalHandler },
  ]

  return (
    <BTModalWindow isOpened={isOpened} handleClose={handleClose}>
      <BTTaskDetailsWrapper>
        <div className="task-wrapper">
          <div className="task-header">
            <BTCardLabel {...labelData} />
            <BTMoreButton items={moreButtonItems} />
          </div>
          <div className="task-main-block">
            <Typography component="span" variant="subtitle1">
              {taskName}
            </Typography>
            <Typography component="span" variant="caption" className="text">
              {description}
            </Typography>
            <div className="basement-block">
              <div className="assignees-block">
                <BTAssignee
                  assignees={assigneesList}
                  isStacked={false}
                  avatarSX={{ height: 20, width: 20 }}
                />
              </div>
              {dueDate && (
                <div className="due-date">
                  <AccessTimeIcon sx={{ height: 16, width: 16 }} />
                  <span>{formattedDate}</span>
                </div>
              )}
            </div>
          </div>
          {checklist && checklist?.length > 0 && (
            <div className="task-checklist">
              <div className="checklist-title">Checklist</div>
              {checklistBlock && (
                <ScrollContainer
                  className="scroll-wrapper"
                  vertical
                  horizontal={false}
                  hideScrollbars={false}
                >
                  {checklistBlock}
                </ScrollContainer>
              )}
            </div>
          )}
        </div>
      </BTTaskDetailsWrapper>
      <BTModalWindow isOpened={isEditModalOpened} handleClose={closeEditTaskModalHandler}>
        <BTCreateTaskForm
          handleClose={closeEditTaskModalHandler}
          externalValuesForEdit={{
            formValues: {
              dueDate: dueDate || '',
              taskDescription: description,
              taskBelongsToId,
              taskName,
            },
            taskId: id,
            members: assigneesList.map(({ id: userId, name, imageUrl }) => ({
              avatarUrl: imageUrl,
              id: userId!,
              name,
              role: TaskRole.Assigned,
            })),
            checklistItems:
              checklistItems?.map(({ id: itemId, name, completedAt }) => ({
                id: itemId,
                label: name,
                isChecked: !!completedAt,
              })) || [],
          }}
        />
      </BTModalWindow>
    </BTModalWindow>
  )
}
