/* eslint-disable no-unused-vars */
import { Dispatch, SetStateAction } from 'react'
import { toast } from 'react-toastify'

export const toastNotifications = (
  message: string | boolean | null | undefined,
  componentErrorHandler?: Dispatch<SetStateAction<string | boolean | null>>
) => {
  return {
    error: () => {
      toast.error(message, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => componentErrorHandler && componentErrorHandler(null),
      })
    },
    success: () => {
      toast.success(message, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        onClose: () => componentErrorHandler && componentErrorHandler(null),
      })
    },
    warning: () => {
      toast.warning(message, {
        position: 'bottom-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    },
  }
}
