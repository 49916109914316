import styled from 'styled-components'
import { colors } from '../../../../../theme/Colors'

export const FileViewWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  padding-bottom: 20px;

  .scroll-document-wrapper {
    height: 70vh;
  }

  .files-table {
    margin: auto;
    width: 596px;
    margin-top: 40px;
    height: 60px;
  }

  .title-area {
    margin: auto;

    width: 596px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .pages {
      font-size: 12px;
      line-height: 1.5;
      color: ${colors.projectColors.filePages};
    }
  }

  .document-title {
    margin-top: 20px;
  }

  .document-area {
    margin: auto;

    max-width: 756px;
    max-height: 860px;

    text-align: center;

    &::-webkit-scrollbar {
      background-color: transparent;
      height: 10px;
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${colors.navyGrey[200]};
      border-radius: 10px;
    }

    .document {
      width: fit-content;
      margin: auto;
      text-align: center;
      box-shadow: 2px 2px 4px 0px rgba(34, 60, 80, 0.2);
    }
  }

  .page-button-area {
    height: 80vh;

    .prev-page-button {
      top: 650px;
      margin-left: 105px;

      height: 35px;
      width: 35px;

      position: sticky;
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }

    .next-page-button {
      top: 650px;
      margin-right: 130px;

      height: 35px;
      width: 35px;

      position: sticky;
    }
  }

  .sidebar-wrapper {
    .sidebar {
      top: 125px;
      position: sticky;
    }
  }
`
