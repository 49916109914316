import styled from 'styled-components'
import { colors } from '../../../../../theme/Colors'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 4px 8px;
  align-items: center;
`
const Counter = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  background: #e2e2e2;
  border-radius: 20px;
  width: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
`
const Title = styled.div`
  ${({ theme }) => ({ ...theme.typography?.h6 })}
  color: ${colors.navyGrey[800]};
`
const Raw = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export { Wrapper, Counter, Title, Raw }
