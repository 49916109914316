import Box from '@mui/material/Box'
import { Typography } from '@mui/material'
import { FC } from 'react'
import { DateTime } from 'luxon'
import CardWrapper from './bt-card.presets'
import { BTAssignee } from '../bt-assignee'
import { BTIconButtons } from '../bt-icon-buttons'
import { BTApprovedBy } from './components/bt-approved-by'
import type { ICardProps } from './bt-card.props'
import { BTCardLabel } from '../bt-card-label'
import { colors } from '../../../theme/Colors'

export const BTCard: FC<ICardProps> = ({
  id,
  title,
  description,
  assignees,
  labelData,
  createdDate,
  children,
  iconButtonsProps,
  isNew,
  approvedBy,
  bgColor,
  isAssigneeStacked,
  clickHandler,
  boxShadow,
}: ICardProps) => {
  const formattedCreateData =
    createdDate && DateTime.fromJSDate(new Date(createdDate)).toFormat('d.L.y')

  const approvedByBlock = approvedBy && <BTApprovedBy approvedBy={approvedBy} />

  const iconButtonsBlock = iconButtonsProps && <BTIconButtons buttonsProps={iconButtonsProps} />

  return (
    <CardWrapper bgColor={bgColor} onClick={clickHandler}>
      <Box sx={{ boxShadow: boxShadow || 2 }} className="card-container">
        <div className="top-block">
          <BTCardLabel {...labelData} />
          {iconButtonsBlock}
        </div>
        <div className="main-block">
          <Typography component="span" className="created-date">
            {formattedCreateData}
          </Typography>
          <Box className="title-block">
            <h4 className="title">{title}</h4>
            {isNew && <BTCardLabel title="NEW" bgColor="#0066FF40" textColor={colors.blue[500]} />}
          </Box>
          <Typography component="span" className="text">
            {description}
          </Typography>
        </div>
        <div className="footer-block">
          <BTAssignee
            avatarSX={{ height: 20, width: 20 }}
            assignees={assignees}
            isStacked={isAssigneeStacked}
          />
          {children}
        </div>
        {approvedByBlock}
      </Box>
    </CardWrapper>
  )
}
