/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
import { ProjectRole, UserOrganizationFragment } from 'generated/graphql'

export interface IMember {
  id: string
  role: ProjectRole
  __typename?: 'ProjectMember'
  user: {
    avatarUrl: string
    email?: string | null
    id: string
    name?: string | null
    __typename?: 'User'
  }
}

export const setMembersWithoutMe = (
  membrs: IMember[] | undefined,
  me: UserOrganizationFragment
) => {
  if (membrs && me) {
    let myIndex = 0
    membrs.map((member: IMember, index) => {
      if (member.user.id === me.id) {
        myIndex = index
      }
    })

    if (myIndex >= 0) {
      const result = [...membrs]
      result.splice(myIndex, 1)
      return result
    }
  }
}

export const setSelectedIcon = (selectedMembers: IMember[], currentMember: IMember) => {
  return selectedMembers.includes(currentMember)
}
