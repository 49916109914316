import { useEffect } from 'react'
import { client } from '../../index'
import { useAppSelector } from 'redux/store'
import { socket } from 'helpers/socketInitializing'
import { GetAllNotificationsDocument } from 'generated/graphql'
import { NOTIFICATIONS_PAGINATION_LIMIT } from 'helpers/constants'
import { WS_NOTIFICATION_EVENTS, WS_BASIC_EVENTS } from '@brieft-au/shared'

export const useGetUpdateNotifications = () => {
  const { cognitoUser: userId } = useAppSelector((state) => state.login)

  useEffect(() => {
    if (userId) {
      socket.emit(WS_BASIC_EVENTS.LOG_IN, userId)

      socket.on(WS_NOTIFICATION_EVENTS.NEW, (response) => {
        const readedQuery = client.readQuery({
          query: GetAllNotificationsDocument,
          variables: {
            unreadArgs: { limit: NOTIFICATIONS_PAGINATION_LIMIT },
            readArgs: { limit: NOTIFICATIONS_PAGINATION_LIMIT },
          },
        })

        const updatedNotifications = [...readedQuery.notifications.unread.notifications]
        updatedNotifications.unshift(response)

        client.writeQuery({
          query: GetAllNotificationsDocument,
          variables: {
            unreadArgs: { limit: NOTIFICATIONS_PAGINATION_LIMIT },
            readArgs: { limit: NOTIFICATIONS_PAGINATION_LIMIT },
          },
          data: {
            ...readedQuery,
            notifications: {
              ...readedQuery.notifications,
              unread: {
                ...readedQuery.notifications.unread,
                notifications: updatedNotifications,
              },
            },
          },
        })
      })
    }

    return () => {
      socket.off(WS_NOTIFICATION_EVENTS.NEW)
    }
  }, [])
}
