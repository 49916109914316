/* eslint-disable consistent-return */
/* eslint-disable import/extensions */
import axios from 'axios'
import { toastMessage } from '../../utils/ToastErrorMessage'

export const composedDownload = async (
  signedUrl: string | undefined
): Promise<Text | undefined> => {
  if (signedUrl) {
    try {
      const response = await axios.get<Text>(signedUrl, {
        responseType: 'text',
      })

      return response?.data
    } catch (err) {
      const error = err as Error
      toastMessage({ message: error.message })

      throw err
    }
  }
}
