/* eslint-disable import/no-unresolved */
import { colors } from 'theme/Colors'
import styled from 'styled-components'

export const BTLinkToActivityWrapper = styled.div`
  .link {
    padding: 5px;
    display: flex;
    align-items: center;
    width: max-content;
    background: ${({ color }) => color};
    border-radius: 5px;
    text-decoration: none;
  }

  .project {
    margin-right: 5px;

    font-weight: 600;
    font-size: 8px;
    line-height: 1;
    letter-spacing: -0.24px;
    color: ${colors.white};
  }

  .activity {
    font-weight: 400;
    font-size: 8px;
    line-height: 1;
    letter-spacing: -0.24px;
    color: ${colors.white};
  }
`
