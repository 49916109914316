import { useEffect } from 'react'
import {
  GetAllActivityAssetsDocument,
  GetAllActivityAssetsQuery,
  useRemoveActivityAssetMutation,
} from 'generated/graphql'
import { toastNotifications } from 'helpers/toastNotifications'

export function useRemoveAsset() {
  const [removeAsset, { error }] = useRemoveActivityAssetMutation()

  useEffect(() => {
    if (error) {
      toastNotifications(error.message).error()
    }
  }, [error])

  return async (fileId: string, artworkId: string) => {
    const response = await removeAsset({
      variables: { input: { fileId } },
      update: (cache) => {
        const oldAssets = cache.readQuery({
          query: GetAllActivityAssetsDocument,
          variables: { activityId: artworkId ?? '' },
        }) as GetAllActivityAssetsQuery

        cache.writeQuery({
          query: GetAllActivityAssetsDocument,
          variables: { activityId: artworkId ?? '' },
          data: {
            getAllActivityAssets: oldAssets.getAllActivityAssets.filter(
              (asset) => asset?.fileId !== fileId
            ),
          },
        })
      },
    })

    if (response.data?.deleteArtworkAsset.isDeleted) {
      toastNotifications('Asset successfully removed!').success()
    }
  }
}
