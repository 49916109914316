import styled from 'styled-components'
import { colors } from '../../../../../theme/Colors'

export const DueDateWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.projectColors.main};
  font-size: 8px;
  line-height: 16px;

  svg {
    font-size: 1.2rem;
  }

  span {
    margin-left: 5px;
  }
`
