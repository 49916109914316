import { useAppDispatch } from 'redux/store'
import { setupWhoami } from 'redux/LoginSlice'
import { useLogin } from 'hooks/user/useLogin'
import { cognitoUserPool } from 'helpers/cognito'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { toastNotifications } from 'helpers/toastNotifications'
import { useSearchParams, useNavigate } from 'react-router-dom'
import {
  useMatchForgotPasswordLazyQuery,
  useMeWithOrganizationLazyQuery,
} from '../../generated/graphql'

export const useResetPassword = () => {
  const [searchParams] = useSearchParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const login = useLogin()

  const [me] = useMeWithOrganizationLazyQuery()
  const [matchForgotPassword] = useMatchForgotPasswordLazyQuery()

  return async (email: string, password: string) => {
    const token = searchParams.get('token')

    try {
      if (token) {
        const result = await matchForgotPassword({
          variables: { input: token },
        })

        const code = result.data?.matchForgotPassword.code

        const cognitoUser = new CognitoUser({
          Username: email,
          Pool: cognitoUserPool,
        })

        if (code) {
          cognitoUser.confirmPassword(code, password, {
            onSuccess: async () => {
              await login(email, password)

              const response = await me()
              if (response.data) {
                dispatch(setupWhoami(response.data))
              }

              navigate('/workspace')
            },
            onFailure: (err) => {
              const error = err as Error
              toastNotifications(error.message).error()
            },
          })
        } else {
          const errorMessage = result.error?.message
          toastNotifications(errorMessage).error()
        }
      }
    } catch (err) {
      const error = err as Error
      toastNotifications(error.message).error()
    }
  }
}
