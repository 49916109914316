import { FC } from 'react'
import { Avatar } from '@mui/material'
import { useAppSelector } from 'redux/store'
import { useParams } from 'react-router-dom'
import { USER_ROLES } from 'helpers/constants'
import { useIsAuthor } from 'helpers/CheckUserRoleAccess'
import { useSendRemind } from 'hooks/approve/useSendRemind'
import { BTMoreButton } from '../../../../shared/bt-more-button'
import { ApprovalStatus, useGetBriefQuery } from 'generated/graphql'
import type { BTAssignedUserBlockProps } from './bt-user-block.props'
import { ReactComponent as ApproveLogo } from 'assets/images/approveIcon.svg'
import {
  BTUserWrapper,
  BTUserBlockText,
  BTUserBlockWrapper,
  BTMoreButtonWrapper,
} from './bt-user-block.presets'

export const BTAssignedUserBlock: FC<BTAssignedUserBlockProps> = ({
  assignee,
  approveStatus,
  removeHandler,
}) => {
  const currentUserId = useAppSelector((state) => state.login.whoami)?.id
  const sendRemind = useSendRemind()

  const { whoami } = useAppSelector((state) => state.login)
  const userRole = whoami?.organizationMember?.role
  const { id: projectId, activityId, artworkId } = useParams()
  const { data: briefData } = useGetBriefQuery({
    variables: { id: projectId },
  })
  const briefId = briefData?.getBrief?.id

  const id = activityId || artworkId || briefId

  const isAuthor = useIsAuthor(id)

  const remove = () => {
    if (assignee.id) {
      removeHandler(assignee.id)
    }
  }

  const remind = async () => {
    if (assignee.id) {
      await sendRemind(assignee.id)
    }
  }

  const isApproved = approveStatus === ApprovalStatus.Approved
  const isCurrentUser = currentUserId === assignee.id

  const checkOnUserRoleButtonItems = () => {
    const currentUserButtonItems = [
      {
        line: 'Remove',
        handler: remove,
      },
    ]

    const otherUsersButtonItems = [
      ...currentUserButtonItems,
      {
        line: 'Send Reminder',
        handler: remind,
      },
    ]

    if (isCurrentUser) {
      return currentUserButtonItems
    }

    return otherUsersButtonItems
  }

  const buttonItems = checkOnUserRoleButtonItems()

  const isExternal = userRole === USER_ROLES.EXTERNAL
  const moreButtonCondition = isAuthor || !isExternal

  return (
    <BTUserBlockWrapper className={isApproved ? 'approved' : ''}>
      <BTUserWrapper>
        <Avatar alt="Remy Sharp" src={assignee.avatarUrl} sx={{ width: 24, height: 24 }} />
        <BTUserBlockText>{assignee.name}</BTUserBlockText>
      </BTUserWrapper>

      <BTMoreButtonWrapper>
        {isApproved && <ApproveLogo />}
        {moreButtonCondition && <BTMoreButton items={buttonItems} />}
      </BTMoreButtonWrapper>
    </BTUserBlockWrapper>
  )
}
