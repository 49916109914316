export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes'

  const byteChunk = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['byte', 'kb', 'mb']

  const baseLog = Math.floor(Math.log(bytes) / Math.log(byteChunk))
  return parseFloat((bytes / byteChunk ** baseLog).toFixed(dm)) + sizes[baseLog]
}
