import React, { FC, ChangeEvent, useState, useEffect } from 'react'
import {
  BTQuestionsLinkInput,
  BTQuestionsLinkWrapper,
  BTLinkIcon,
} from './bt-questions-page-link.presets'
import { BTQuestionsPageLinkProps } from './bt-questions-page-link.props'
import { useDebounce } from 'hooks/shared/useDebounce'
import { useAppSelector } from 'redux/store'

export const BTQuestionsPageLink: FC<BTQuestionsPageLinkProps> = ({
  placeholder,
  handler,
  question,
  defaultValue = '',
}) => {
  const [state, setState] = useState(defaultValue)
  const { currentDeliverable } = useAppSelector((state) => state.sidebarApps)
  const inputHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setState(event.target.value)
  }
  const debouncedValue = useDebounce(state, 250)
  const copyHandler = () => {
    navigator.clipboard.writeText(state.toString())
  }

  useEffect(() => {
    const { questionTypeId, id } = question
    handler({
      content: debouncedValue,
      questionId: id,
      type: questionTypeId,
      deliverableId: currentDeliverable.relationId || currentDeliverable.id,
    })
  }, [debouncedValue])
  return (
    <BTQuestionsLinkWrapper>
      <BTQuestionsLinkInput placeholder={placeholder} value={state} onChange={inputHandler} />
      <BTLinkIcon onClick={copyHandler} />
    </BTQuestionsLinkWrapper>
  )
}
