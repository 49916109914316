/* eslint-disable max-len */
import styled from 'styled-components'
import { colors } from '../../../theme/Colors'

const BTMainWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const BTAssetsRow = styled.div`
  margin-bottom: 40px;

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  & > * {
    &:first-child {
      flex: 1;
    }
    &:last-child {
      flex: 0.15;
    }
  }

  input {
    height: 23px;
  }
`

const BTFilterWrapper = styled.div`
  .filter {
    height: 37px;
    width: 120px;
  }
`
const BTLastUpdatedFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const BTLastUpdatedFilterTitle = styled.div`
  margin-bottom: 10px;

  font-weight: 700;
  font-size: 12px;
  line-height: 1.5;
  color: ${colors.projectColors.black};
`

const BTLastUpdatedFilterButton = styled.button`
  background: none;
  border: none;

  font-size: 12px;
  line-height: 1.5;
  color: ${colors.projectColors.iconDarkGrey};

  cursor: pointer;

  .filter-icon-area {
    display: flex;
    align-items: center;
    margin-right: 5px;

    transition: transform, 100ms linear;
  }
  .filter-icon-area.entered {
    display: flex;
    align-items: center;
    margin-right: 5px;

    transform: rotate(180deg);
  }
`

const BTFilterButtonContent = styled.div`
  display: flex;
  align-items: center;
`

const BTAssetsFilesBlockWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;

  display: grid;
  gap: 20px 10px;
  grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));

  /** @desc This is necessary to avoid horizontal scrolling if a card with a PDF preview appears on the right edge of the grid table. */
  overflow: hidden;

  .no-assets {
    font-size: 10px;
  }
`

const BTAssetsContentWrapper = styled.div<{ gap: any }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.gap}px;

  .scroll {
    height: 60vh;
    max-height: 895px;

    &::-webkit-scrollbar {
      background-color: transparent;
      height: 10px;
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${colors.navyGrey[200]};
      border-radius: 10px;
    }
  }
`

const PaginationWrapper = styled.div`
  margin-top: 10px;

  display: flex;
  justify-content: center;
`

/** @desc this styles is for sidebar assets. Lets them keep here. Them will be removed after sidebar assets will be implemented */
const BTAssetsMore = styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: -0.24px;
  color: ${colors.projectColors.filePages};
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
  width: 100%;
`
const BTAssetsSubheader = styled.div`
  color: ${colors.projectColors.iconDarkGrey};
  ${({ theme }) => ({ ...theme.typography?.subtitle1 })};
`
/** ----------- */

export {
  BTAssetsRow,
  BTMainWrapper,
  BTFilterWrapper,
  BTAssetsContentWrapper,
  BTLastUpdatedFilterWrapper,
  BTLastUpdatedFilterTitle,
  BTLastUpdatedFilterButton,
  BTFilterButtonContent,
  BTAssetsFilesBlockWrapper,
  PaginationWrapper,
  BTAssetsMore,
  BTAssetsSubheader,
}
