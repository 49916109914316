import { FC } from 'react'
import { useAppSelector } from 'redux/store'
import { BTDashboardWrapper } from './bt-dashboard.presets'
import { useProjectSummariesQuery } from 'generated/graphql'
import { BTNoProjectsTutorial } from './components/bt-no-projects-tutorial'
import { BTSummaryLayout } from './components/bt-summary-layout/bt-summary-layout'

export const BTDashboard: FC = () => {
  const { sizes } = useAppSelector((state) => state.header)
  const { data: projectsResponse } = useProjectSummariesQuery()
  const haveProjects = projectsResponse?.projects.length

  return (
    <BTDashboardWrapper>
      {haveProjects ? (
        <div className="current-tab-panel">
          <BTSummaryLayout layoutMaxWidth={sizes.width - 80} />
        </div>
      ) : (
        <BTNoProjectsTutorial />
      )}
    </BTDashboardWrapper>
  )
}
