import { FC } from 'react'
import { Backdrop, Fade, Modal } from '@mui/material'
import { IBTModalWindowProps } from './bt-modal-window.props'
import { BTModalWindowWrapper } from './bt-modal-window.presets'

export const BTModalWindow: FC<IBTModalWindowProps> = ({
  isOpened,
  handleClose,
  children,
  wrapperClassName,
}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={wrapperClassName ?? ''}
      open={isOpened}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpened}>
        <BTModalWindowWrapper>{children}</BTModalWindowWrapper>
      </Fade>
    </Modal>
  )
}
