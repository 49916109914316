import { useParams } from 'react-router-dom'
import {
  GetArtworkFeedbackMessagesDocument,
  GetArtworkFeedbackMessagesQuery,
  FeedbackMessageType,
  AddImageFeedbackMutation,
  GetAllFeedbackMessagesQuery,
  useAddImageFeedbackMutation,
  GetAllFeedbackMessagesDocument,
} from '../../generated/graphql'
import { sortByBoolean } from '../../utils/sorting/sorting'
import { useAppSelector } from '../../redux/store'

export function useAddImageFeedback() {
  const user = useAppSelector((state) => state.login.whoami)
  const [addFeedback, { data, loading }] = useAddImageFeedbackMutation()

  const { artworkId } = useParams()

  return async (
    assetId: string,
    value: string,
    feedbackId: string,
    x: number,
    y: number,
    page: number,
    activityId?: string
  ): Promise<[AddImageFeedbackMutation | undefined | null, boolean]> => {
    await addFeedback({
      variables: {
        input: {
          assetId,
          userId: user.id,
          activityId,
          content: value,
          x: Math.round(x),
          y: Math.round(y),
          page,
        },
      },
      update: (cache, { data: newMessage }) => {
        if (artworkId) {
          const artwork = cache.readQuery({
            query: GetArtworkFeedbackMessagesDocument,
            variables: {
              input: { assetId },
            },
          }) as GetArtworkFeedbackMessagesQuery

          const artworkMessages = artwork === null ? [] : artwork.getArtworkFeedback

          cache.writeQuery({
            query: GetArtworkFeedbackMessagesDocument,
            variables: {
              input: { assetId },
            },
            data: {
              getArtworkFeedback: [
                ...artworkMessages,
                {
                  __typename: 'FeedbackMessage',
                  feedbackId,
                  messageId: newMessage?.addImageFeedback.message.id,
                  createdAt: new Date(),
                  userId: user.id,
                  content: value,
                  assetId,
                  position: {
                    x,
                    y,
                    page,
                  },
                },
              ],
            },
          })
        }

        const messages = cache.readQuery({
          query: GetAllFeedbackMessagesDocument,
          variables: {
            input: { assetId },
          },
        }) as GetAllFeedbackMessagesQuery

        const oldMessages =
          messages === null ? [] : messages.getFeedbackMessagesByFeedbackId.messages

        cache.writeQuery({
          query: GetAllFeedbackMessagesDocument,
          variables: {
            input: { assetId },
          },
          data: {
            getFeedbackMessagesByFeedbackId: {
              messages: [
                ...oldMessages,
                {
                  __typename: 'FeedbackMessage',
                  user: {
                    __typename: 'User',
                    id: user.id,
                    name: user.name,
                    avatarUrl: user.avatarUrl,
                    position: null,
                    email: null,
                  },
                  content: value,
                  messageId: newMessage?.addImageFeedback.message.id,
                  completedAt: null,
                  userId: user.id,
                  createdAt: new Date(),
                  replyedComments: [],
                  feedbackMessageId: newMessage?.addImageFeedback.feedbackMessageId || '',
                  pinned: false,
                  feedbackId,
                  assetId,
                  type: FeedbackMessageType.Parent,
                  members: [],
                  position: {
                    x,
                    y,
                    page,
                  },
                },
              ].sort((first, second) => sortByBoolean(first.pinned!, second.pinned!)),
            },
          },
        })
      },
    })

    return [data, loading]
  }
}
