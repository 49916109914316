import { useAppSelector } from '../redux/store'

export const useGetCurrentOrganization = () => {
  const { currentOrganizationId } = useAppSelector((state) => state.login)
  const { organizations } = useAppSelector((state) => state.login)

  const result = organizations.find((organization) => organization.id === currentOrganizationId)

  return result && result
}
