import styled from 'styled-components'
import { Button } from '@mui/material'
import { colors } from '../../../theme/Colors'

const BTApproveTextTopLabel = styled.div`
  margin-bottom: 20px;

  color: ${colors.projectColors.black};
  ${({ theme }) => ({ ...theme.typography?.regular })};
`

const BTApproveTextBottomLabel = styled.div`
  margin-bottom: 10px;

  color: ${colors.projectColors.black};
  ${({ theme }) => ({ ...theme.typography?.regular })};
`

const BTApproveButton = styled(Button)`
  && {
    ${({ theme }) => ({ ...theme.typography?.subtitle1 })};
    padding: 10px;
    color: white;
    text-transform: capitalize;
  }
  &&:disabled {
    background: #0066ff;
    color: white;
    opacity: 0.6;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
    cursor: not-allowed;
  }
  &&.approved {
    background: ${colors.projectColors.approved};
  }
`

const BTScrollWrapper = styled.div`
  margin: 0;
  padding: 0;

  .scroll {
    max-height: 85px;

    &::-webkit-scrollbar {
      background-color: transparent;
      height: 10px;
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${colors.navyGrey[200]};
      border-radius: 10px;
    }
  }
`
const BTAssigneeItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 10px;
  }
`
const BTDividerWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`

const BTMembersListWrapper = styled.ul`
  margin: 0;
  padding: 0;

  button {
    margin-top: 10px;
  }

  .scroll {
    max-height: 225px;

    &::-webkit-scrollbar {
      background-color: transparent;
      height: 10px;
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${colors.navyGrey[200]};
      border-radius: 10px;
    }
  }
`

const BTSeachInputWrapper = styled.div`
  input {
    height: 28px;
    padding-top: 6px;
    padding-bottom: 6px;

    ${({ theme }) => ({ ...theme.typography?.regular })};
  }
`

export {
  BTApproveTextTopLabel,
  BTApproveTextBottomLabel,
  BTApproveButton,
  BTScrollWrapper,
  BTAssigneeItem,
  BTDividerWrapper,
  BTMembersListWrapper,
  BTSeachInputWrapper,
}
