import styled from '@emotion/styled'
import { colors } from '../../../theme/Colors'

export const ProjectInfoStepWrapper = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  flex-direction: column;

  .MuiOutlinedInput-root {
    background-color: #f2f2f2;
    border-radius: 5px;
    width: 240px;
  }

  .MuiInputBase-root {
    transition: all 0.2s ease-in-out;
    border-color: inherit;
  }

  .MuiInputBase-root:hover {
    border-color: ${colors.projectColors.black};
  }

  label {
    color: ${colors.projectColors.black};
  }

  button {
    height: 38px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-transform: capitalize;
  }

  .inputs-wrapper {
    padding-bottom: 120px;
    display: flex;
    align-self: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px 0;
  }
`
