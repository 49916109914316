import { Button } from '@mui/material'
import { FC, useMemo, useState } from 'react'
import { LoadingButton } from '@mui/lab'
import { useUploadAsset } from 'hooks/file/useUploadAsset'
import { useParams } from 'react-router-dom'
import { FileData } from 'generated/graphql'
import { formatBytes } from '../../../../helpers/file'
import { BTFilesDrop } from '../../../shared/bt-files-drop'
import { getFileType } from './bt-choose-file.helper'
import { BTModalWindow } from '../../../shared/bt-modal-window'
import { BTFileItemInformation } from '../../../shared/bt-file-item-information'
import { ChooseFileModalWrapper } from './bt-choose-file-modal.presets'
import { getFilesDataToReorder } from '../bt-artwork-header/bt-artwork-header.helper'

import type { IBTChooseFileModalProps } from './bt-choose-file-modal.props'

export const BTChooseFileModal: FC<IBTChooseFileModalProps> = ({
  isOpened,
  closeModal,
  title = 'Upload',
  assetsNumber,
  setVersion,
  oldVersion,
  setOldVersion,
  getCurrentAsset,
  apolloClient,
  replaceAction,
}) => {
  const version = useMemo(() => oldVersion ?? assetsNumber + 1, [isOpened])
  const outerTitle = useMemo(
    () => (assetsNumber > 0 ? 'Upload the next version of this Artwork' : title),
    [isOpened]
  )

  const [file, setFile] = useState<File>()
  const [isLoading, setLoading] = useState(false)
  const { artworkId } = useParams()

  const addAssetToArtwork = useUploadAsset()

  const convertFile = (files: File[]) => {
    files.forEach(async (fileElem: File) => {
      const reader = new FileReader()

      reader.onload = async () => {
        setFile(fileElem)
      }
      reader.readAsDataURL(fileElem)
    })
  }

  const clearFileInput = () => setFile(undefined)

  const closeModalWindow = () => {
    clearFileInput()
    closeModal()
  }

  const uploadAsset = async () => {
    if (file && artworkId) {
      setLoading(true)

      if (replaceAction) {
        await replaceAction()
      }

      const pushIndex = oldVersion ?? undefined
      let reorderList: FileData[] = []

      if (oldVersion && oldVersion !== null) {
        const index = oldVersion === 1 ? 0 : oldVersion - 1

        reorderList = getFilesDataToReorder('', artworkId, apolloClient, true, index)
      }

      await addAssetToArtwork(file, version, artworkId, pushIndex, reorderList)

      if (getCurrentAsset) {
        getCurrentAsset(file)
      }

      setLoading(false)

      if (setOldVersion) {
        setOldVersion(null)
      }

      if (version === 1 || oldVersion) {
        setVersion(version)
      } else {
        setVersion(assetsNumber + 1)
      }
    }

    closeModalWindow()
  }

  const closeModalHandler = () => {
    if (setOldVersion) {
      setOldVersion(null)
    }
    closeModalWindow()
  }

  return (
    <BTModalWindow isOpened={isOpened} handleClose={closeModalHandler}>
      <ChooseFileModalWrapper>
        <span className="title">{outerTitle}</span>
        <BTFilesDrop
          handleDrop={convertFile}
          options={{ accept: ['image/png', 'image/jpeg', '.pdf'] }}
        />
        {file && (
          <BTFileItemInformation
            fileName={file?.name
              .split('.')[0]
              .concat(`_v${version}${oldVersion && oldVersion !== null ? `_new` : ''}`)}
            fileType={getFileType(file.type)}
            fileSize={formatBytes(file.size)}
            buttonItems={[{ title: 'Remove', action: clearFileInput }]}
          />
        )}
        <div className="buttons-block">
          <Button
            variant="contained"
            color="secondary"
            disabled={isLoading}
            onClick={closeModalHandler}
          >
            Close
          </Button>
          <LoadingButton
            variant="contained"
            loading={isLoading}
            onClick={uploadAsset}
            disabled={!file}
          >
            Done
          </LoadingButton>
        </div>
      </ChooseFileModalWrapper>
    </BTModalWindow>
  )
}
