/* eslint-disable import/no-unresolved */
import { colors } from 'theme/Colors'
import styled from 'styled-components'

export const BTMessageMainWrapper = styled.div`
  position: absolute;
  left: 410px;
  z-index: 15;

  width: 340px;
  height: 100%;

  background: ${colors.white};
  border-left: 2px solid ${colors.projectColors.fileBorder};
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.25);

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 50px;
`

export const BTMessageMainContentWrapper = styled.div`
  width: 100%;
`

export const BTFSendersList = styled.div`
  margin-top: 20px;

  .senders-list {
    max-height: 215px;

    &::-webkit-scrollbar {
      background-color: transparent;
      height: 10px;
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${colors.navyGrey[200]};
      border-radius: 10px;
    }
  }
`
export const BTFromItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  display: flex;
  align-items: center;
`

export const BTItemName = styled.span`
  margin-left: 10px;
  ${({ theme }) => ({ ...theme.typography?.regular })};

  color: ${colors.black};
`

export const BTMessageAreaWrapper = styled.div`
  margin-top: 20px;

  .message {
    max-height: 300px;

    border: 0.5px solid ${colors.projectColors.iconDarkGrey};
    border-radius: 5px;
    resize: none;

    outline: none;
    padding: 5px;

    font-weight: 300;
    font-size: 10px;
    line-height: 1.7;
    color: ${colors.projectColors.black};

    &::-webkit-scrollbar {
      background-color: transparent;
      height: 10px;
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${colors.navyGrey[200]};
      border-radius: 10px;
    }
  }
`
export const BTSendPurpose = styled.div`
  margin-bottom: 20px;

  padding-left: 10px;

  width: 100%;
  height: 38px;

  display: flex;
  justify-content: start;
  align-items: center;

  font-size: 12px;
  line-height: 1.5;

  color: ${colors.projectColors.black};

  background: ${colors.projectColors.lightGrey};
  border-radius: 5px;
`
