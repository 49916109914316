import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { Avatar, Button } from '@mui/material'
import { USER_ROLES } from 'helpers/constants'
import EmailIcon from '@mui/icons-material/Email'
import { setupOrganizations } from 'redux/LoginSlice'
import { useUpdateUserRole } from 'hooks/user/useUpdateUserRole'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { usePagination } from '../../hooks/pagination/usePagination'
import { BTInviteMemberModal } from './components/bt-invite-member-modal'
import { BTWordHighlighter } from 'components/shared/bt-word-highlighter'
import { BTActivityListView, IListItem } from '../shared/bt-activity-list-view'
import { BTChangeCurrentOrganization } from 'components/bt-change-current-organization'
import { useShouldBeDisabledForRoles } from 'helpers/CheckUserRoleAccess/CheckUserRoleAccess'
import { IBTHeaderSectionProps } from '../shared/bt-activity-list-view/bt-header-section-item'
import {
  BTButtonsBlock,
  BTTeamSettignsWrapper,
  BTListViewLayoutWrapper,
} from './bt-team-settings.presets'
import {
  OrganizationRole,
  OrganizationMember,
  useRemoveOrganizationMemeberMutation,
} from 'generated/graphql'

export const BTTeamSettings = () => {
  const { currentOrganizationId, organizations, cognitoUser } = useAppSelector(
    (state) => state.login
  )
  const { whoami } = useAppSelector((state) => state.login)
  const userRole = whoami?.organizationMember?.role

  const currentUserId = cognitoUser as unknown as string

  const allOrganizations = useAppSelector((state) => state.login.organizations)

  const dispatch = useAppDispatch()

  const disabledFor = useShouldBeDisabledForRoles()

  const [currentData, currentPage, maxPage, setCurrentData, changeCurrentPageData] =
    usePagination<OrganizationMember>(10, [])

  const [removeMember] = useRemoveOrganizationMemeberMutation()

  const [isInviteModalOpened, setInviteModalOpen] = useState(false)
  const [isChangeOrganizationModalOpen, setIsChangeOrganizationModalOpen] = useState(false)

  const openInviteModalHandler = () => setInviteModalOpen(true)
  const closeInviteModalHandler = () => setInviteModalOpen(false)

  const openChangeOrganizationModalHandler = () => setIsChangeOrganizationModalOpen(true)
  const closeChangeOrganizationModalHandler = () => setIsChangeOrganizationModalOpen(false)

  const updateUserRole = useUpdateUserRole()

  const setCurrentOrganizationMembers = () => {
    if (allOrganizations.length > 0) {
      const currentOrganization = allOrganizations.filter(({ id }) => id === currentOrganizationId)

      if (currentOrganization.length > 0 && currentOrganization[0].members) {
        const { members } = currentOrganization[0]

        setCurrentData(members as OrganizationMember[])
      }
    }
  }

  useEffect(() => {
    setCurrentOrganizationMembers()

    if (organizations.length > 1) {
      openChangeOrganizationModalHandler()
    }
  }, [currentOrganizationId, organizations])

  const headerSection: IBTHeaderSectionProps[] = [
    { title: 'Name' },
    { title: 'Email' },
    { title: 'Role' },
    { title: 'Date Added' },
  ]

  const removeAction = async (userId: string) => {
    const response = await removeMember({
      variables: { input: { organizationId: currentOrganizationId, userId } },
    })

    if (response.data?.removeMemberFromOrganization.isDeleted) {
      const filteredOrganizations = organizations.map((elem) =>
        elem.id === currentOrganizationId
          ? {
              ...elem,
              members: elem.members.filter(({ user }) => user.id !== userId),
            }
          : elem
      )

      dispatch(setupOrganizations(filteredOrganizations))
    }
  }

  const changeRoleTo = async (userId: string, role: string) => {
    await updateUserRole(userId, role)
  }

  const getOrganizationRole = (): OrganizationRole | undefined => {
    if (currentUserId) {
      const currentMember = currentData.filter(({ user }) => user.id === currentUserId)

      if (currentMember.length > 0) {
        return currentMember[0].role
      }
    }

    return undefined
  }

  const itemsList: IListItem[] =
    currentData?.map(({ user, role, createdAt }) => ({
      itemSections: [
        <div className="approved-by">
          <Avatar sx={{ width: 30, height: 30 }} src={user.avatarUrl} />
          <span>{user.name}</span>
        </div>,
        <span>{user.email}</span>,
        <span className="role">{role.toLowerCase()}</span>,
        <span>{DateTime.fromJSDate(new Date(createdAt)).toFormat('dd LLLL y')}</span>,
      ],
      handlerActions:
        userRole === 'admin' && user.id !== currentUserId
          ? [
              {
                label: <BTWordHighlighter>Remove</BTWordHighlighter>,
                action: async () => removeAction(user.id),
              },
              {
                label: <BTWordHighlighter>Change Role to %Admin%</BTWordHighlighter>,
                action: async () => changeRoleTo(user.id, USER_ROLES.ADMIN),
              },
              {
                label: <BTWordHighlighter>Change Role to %Member%</BTWordHighlighter>,
                action: async () => changeRoleTo(user.id, USER_ROLES.MEMBER),
              },
              {
                label: <BTWordHighlighter>Change Role to %External%,</BTWordHighlighter>,
                action: async () => changeRoleTo(user.id, USER_ROLES.EXTERNAL),
              },
            ]
          : undefined,
    })) || []

  return (
    <>
      <BTTeamSettignsWrapper>
        <BTListViewLayoutWrapper>
          <BTActivityListView
            currentPage={currentPage}
            headerSections={headerSection}
            itemsList={itemsList}
            pageCount={maxPage}
            paginationHandler={(event, page) => changeCurrentPageData(page)}
            headerSectionBackground
          />
        </BTListViewLayoutWrapper>
        <BTButtonsBlock>
          <Button
            endIcon={<EmailIcon />}
            variant="contained"
            onClick={openInviteModalHandler}
            disabled={disabledFor(['external'])}
          >
            Invite
          </Button>
        </BTButtonsBlock>
        <BTInviteMemberModal
          isOpened={isInviteModalOpened}
          closeHandler={closeInviteModalHandler}
          organizationRole={getOrganizationRole()}
        />
      </BTTeamSettignsWrapper>

      <BTChangeCurrentOrganization
        isOpened={isChangeOrganizationModalOpen}
        handleModalClose={closeChangeOrganizationModalHandler}
      />
    </>
  )
}
