import * as Yup from 'yup'
import { DateTime } from 'luxon'
import { useFormik } from 'formik'
import { v4 as uuidv4 } from 'uuid'
import { colors } from 'theme/Colors'
import { LoadingButton } from '@mui/lab'
import ScrollContainer from 'react-indiana-drag-scroll'
import { useCreateTask } from 'hooks/task/useCreateTask'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { BTTaskMembers } from './components/bt-task-members'
import { FC, useState, KeyboardEvent, useEffect } from 'react'
import { toastNotifications } from 'helpers/toastNotifications'
import { BTProjectSearch } from './components/bt-project-search'
import type { IBTTaskMember } from './components/bt-task-members'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { BTTaskCreateFormWrapper } from './bt-task-create-form.presets'
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import { Button, Checkbox, OutlinedInput, TextField, TextFieldProps } from '@mui/material'
import {
  TaskRole,
  TaskStatus,
  TaskFragment,
  TaskFragmentDoc,
  useUpdateTaskMutation,
  useRemoveChecklistItemsMutation,
} from 'generated/graphql'
import type {
  IFormValues,
  ICheckListItem,
  IBTTaskCreateFormProps,
} from './bt-task-create-form.props'
import { useAppSelector } from 'redux/store'

const TaskCreateValidationSchema = Yup.object().shape({
  taskName: Yup.string()
    .required('Task name is required!')
    .max(30, 'Task name must 30 characters or less'),

  dueDate: Yup.date()
    .nullable()
    .required('Due date is required!')
    .typeError('Due date has wrong format!'),

  taskBelongsToId: Yup.string().required('It is necessary to choose!'),
})

export const BTCreateTaskForm: FC<IBTTaskCreateFormProps> = ({
  handleClose: handleModalClose,
  outerId,
  externalValuesForEdit,
}) => {
  const [checklist, setChecklist] = useState<Array<ICheckListItem>>([])
  const [checklistToRemove, setChecklistToRemove] = useState<Array<ICheckListItem>>([])
  const [members, setMembers] = useState<IBTTaskMember[]>([])

  const { currentOrganizationId } = useAppSelector((store) => store.login)
  const userId = useAppSelector((store) => store.login.whoami?.id)

  const [createTask, { error }] = useCreateTask()
  const [updateTask] = useUpdateTaskMutation()
  const [removeChecklistItems] = useRemoveChecklistItemsMutation()

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      const target = event.target as HTMLInputElement

      setChecklist((prevState) => [
        ...prevState,
        {
          id: uuidv4(),
          isChecked: false,
          label: target.value,
        },
      ])

      target.value = ''
    }
  }

  const formattedDate = `${DateTime.fromJSDate(new Date()).setLocale('en').weekdayLong
    } ${DateTime.fromJSDate(new Date()).toFormat('dd LLLL y')}`

  const onSubmit = async ({ taskDescription, taskName, dueDate, taskBelongsToId }: IFormValues) => {
    if (externalValuesForEdit) {
      await updateTask({
        variables: {
          input: {
            id: externalValuesForEdit.taskId,
            name: taskName,
            dueDate,
            description: taskDescription,
            checklistItems: checklist.map(({ id, label, isChecked }) => ({
              id,
              name: label,
              complete: isChecked,
            })),
            members: members.map(({ id, role }) => ({
              role,
              userId: id,
            })),
          },
        },
      })

      const checklistToRemoveIds = checklistToRemove.map(({ id }) => id)

      if (checklistToRemoveIds.length) {
        await removeChecklistItems({
          variables: { input: { itemsIds: checklistToRemoveIds } },
          update: (cache) => {
            const readFragment = cache.readFragment({
              fragment: TaskFragmentDoc,
              id: `Task:${externalValuesForEdit.taskId}`,
              fragmentName: 'Task',
            }) as TaskFragment

            const items = readFragment.checklist.filter(
              ({ id }) => !(checklistToRemoveIds.findIndex((elemId) => elemId === id) !== -1)
            )

            cache.writeFragment({
              fragment: TaskFragmentDoc,
              id: `Task:${externalValuesForEdit.taskId}`,
              fragmentName: 'Task',
              data: {
                ...readFragment,
                checklist: items,
              },
            })
          },
        })
      }
    } else {
      const userIdCondition = taskBelongsToId === userId ? taskBelongsToId : null
      const organizationIdCondition =
        taskBelongsToId === currentOrganizationId ? taskBelongsToId : null
      const projectIdCondition =
        taskBelongsToId === currentOrganizationId || taskBelongsToId === userId
          ? null
          : taskBelongsToId

      await createTask({
        variables: {
          input: {
            name: taskName,
            description: taskDescription,
            status: TaskStatus.Todo,
            userId: userIdCondition,
            organizationId: organizationIdCondition,
            projectId: projectIdCondition,
            activityId: null,
            completed: false,
            members: members.map((elem) => ({
              role: TaskRole.Assigned,
              userId: elem.id,
            })),
            checklist: checklist.map((elem) => ({
              name: elem.label,
              complete: false,
            })),
            dueDate,
          },
        },
      })
    }
    handleModalClose()
  }

  const formik = useFormik<IFormValues>({
    initialValues: {
      taskName: '',
      taskDescription: '',
      dueDate: '',
      taskBelongsToId: '',
    },
    onSubmit,
    validationSchema: TaskCreateValidationSchema,
    validateOnChange: true,
  })

  useEffect(() => {
    if (outerId) {
      formik.setFieldValue('taskBelongsToId', outerId)
      formik.validateField('taskBelongsToId')
    }
  }, [outerId])

  useEffect(() => {
    if (externalValuesForEdit?.members && externalValuesForEdit?.members.length) {
      setMembers(externalValuesForEdit.members)
    }
  }, [externalValuesForEdit?.members])

  const isItemExist = (itemId: string): boolean => {
    const index = externalValuesForEdit?.checklistItems.findIndex(({ id }) => id === itemId)

    return index !== -1
  }

  const removeChecklistItem = (itemId: string) => {
    if (externalValuesForEdit && isItemExist(itemId)) {
      const itemToRemove = checklist.filter(({ id }) => id === itemId)

      setChecklistToRemove((prevState) => [...prevState, ...itemToRemove])
    }
    const filteredItems = checklist.filter(({ id }) => id !== itemId)

    setChecklist(filteredItems)
  }

  useEffect(() => {
    if (externalValuesForEdit?.formValues) {
      Object.entries(externalValuesForEdit.formValues).forEach((tuple) => {
        const [key, value] = tuple
        formik.setFieldValue(key, value)
      })
      formik.validateForm()
    }
    if (externalValuesForEdit?.checklistItems && externalValuesForEdit.checklistItems.length) {
      setChecklist(externalValuesForEdit.checklistItems)
    }
  }, [externalValuesForEdit])

  useEffect(() => {
    if (error) {
      toastNotifications(error.message).error()
    }
  }, [error])

  return (
    <BTTaskCreateFormWrapper>
      <form>
        {!outerId && (
          <div className="top-block">
            <BTProjectSearch
              formik={formik}
              externalId={externalValuesForEdit?.formValues?.taskBelongsToId}
            />
          </div>
        )}
        <div className="main-block">
          <span className="created-date">{formattedDate}</span>
          <div className="input-block">
            <span className="input-label">Task name</span>
            <TextField
              id="task-name"
              placeholder="Enter task name here"
              aria-describedby="component-error-text"
              autoComplete="off"
              value={formik.values.taskName}
              error={formik.touched.taskName && !!formik.errors.taskName}
              helperText={formik.errors.taskName}
              onChange={(event) => formik.setFieldValue('taskName', event.target.value)}
              size="small"
              type="text"
              required
              fullWidth
            />
          </div>
          <div className="input-block">
            <span className="input-label">Task description</span>
            <TextField
              id="task-description"
              placeholder="Enter task description here"
              autoComplete="off"
              value={formik.values.taskDescription}
              multiline
              maxRows={4}
              error={formik.touched.taskDescription && !!formik.errors.taskDescription}
              helperText={formik.errors.taskDescription}
              onChange={(event) => formik.setFieldValue('taskDescription', event.target.value)}
              size="small"
              fullWidth
            />
          </div>
          <div className="assignee-block">
            <span className="title">Team Members</span>
            <BTTaskMembers
              taskBelongsToId={formik.values.taskBelongsToId}
              membersData={{
                members,
                setMembers,
                isEditing: true,
              }}
            />
          </div>
          <div className="date-block">
            <span className="title">Due Date & Time</span>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                minDate={new Date()}
                value={formik.values.dueDate || null}
                onChange={(newValue: Date | null) => {
                  formik.setFieldValue('dueDate', newValue)
                }}
                inputFormat="d/MM/yyyy h:m bbb"
                components={{
                  OpenPickerIcon: AccessTimeIcon,
                }}
                hideTabs={false}
                OpenPickerButtonProps={{
                  size: 'small',
                  className: 'open-picker-button',
                }}
                InputProps={{
                  components: {
                    Root: 'div',
                  },
                  componentsProps: {
                    root: {
                      className: 'root-picker-wrapper',
                    },
                    input: {
                      placeholder: 'Enter Due Date',
                      required: true,
                    },
                  },
                }}
                renderInput={(params: TextFieldProps) => (
                  <TextField
                    id="dueDate"
                    name="dueDate"
                    autoComplete="off"
                    error={formik.touched?.dueDate && !!formik.errors?.dueDate}
                    required
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="basement-block">
          <span className="title">Checklist</span>
          {checklist && (
            <ScrollContainer className="scroll-wrapper" vertical hideScrollbars={false}>
              {checklist.map(({ id, label, isChecked }) => (
                <div key={id} className="checklist-item">
                  <div>
                    <Checkbox checked={isChecked} sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }} />
                    <span className="checklist-item-label">{label}</span>
                  </div>
                  <CancelPresentationIcon
                    sx={{ fontSize: '14px', color: colors.projectColors.main }}
                    onClick={() => removeChecklistItem(id)}
                    className="remove-button"
                  />
                </div>
              ))}
            </ScrollContainer>
          )}
          <div className="empty-checklist-item">
            <Checkbox checked={false} sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }} />
            <OutlinedInput
              id="empty-checkbox"
              placeholder="Add checklist item"
              onKeyDown={(event) => handleKeyDown(event)}
              autoComplete="off"
              fullWidth
            />
          </div>
        </div>
        <div className="buttons">
          <Button variant="outlined" onClick={handleModalClose}>
            Close
          </Button>
          <LoadingButton
            className="submit-button"
            type="button"
            variant="contained"
            loading={formik.isSubmitting}
            onClick={() => formik.handleSubmit()}
          >
            {externalValuesForEdit ? 'Save' : 'Create'}
          </LoadingButton>
        </div>
      </form>
    </BTTaskCreateFormWrapper>
  )
}
