/* eslint-disable max-len */
import { v4 as uuidv4 } from 'uuid'
import { useParams } from 'react-router-dom'
import { useCreateBriefNoMilestones } from '../activity/useCreateBriefNoMilestones'
import { composedUpload } from '../../helpers/compose/composedUpload'
import { toastNotifications } from '../../helpers/toastNotifications'
import {
  useUpdateBriefComposeDocumentMutation,
  GetBriefDocument,
  useUpdateActivityComposeDocumentMutation,
  GetActivityByIdDocument,
  GetActivityByIdQuery,
} from '../../generated/graphql'
import { useAppSelector } from 'redux/store'

export const useCreateComposeDocument = () => {
  const { id: projectId, activityId } = useParams()
  const { id } = useAppSelector((state) => state.login.whoami)
  const createBrief = useCreateBriefNoMilestones()
  const [getSignedUrlResponse] = useUpdateBriefComposeDocumentMutation()
  const [getSignedUrlResponseForActivity] = useUpdateActivityComposeDocumentMutation()

  return async (content: string | null): Promise<void> => {
    try {
      if (content) {
        if (activityId) {
          const activityName = uuidv4()
          const signedUrlResponse = await getSignedUrlResponseForActivity({
            variables: {
              input: {
                id: activityId,
                name: activityName,
              },
            },

            errorPolicy: 'ignore',
            update: (cache, { data }) => {
              const readedQuery = cache.readQuery({
                query: GetActivityByIdDocument,
                variables: {
                  input: activityId,
                },
              }) as GetActivityByIdQuery

              cache.writeQuery({
                query: GetActivityByIdDocument,
                variables: {
                  input: activityId,
                },
                data: {
                  getActivityById: {
                    ...readedQuery,
                    composedDocument: {
                      ...data?.updateActivityComposeDocument.activity.composedDocument,
                    },
                  },
                },
              })
            },
          })
          const signedUrl = signedUrlResponse.data?.updateActivityComposeDocument.url
          await composedUpload(content, signedUrl)
        } else {
          const briefName = 'brief'
          const newBrief = await createBrief(briefName, id)

          const briefId = newBrief.data?.createBrief.activity.id

          if (briefId) {
            const signedUrlResponse = await getSignedUrlResponse({
              variables: {
                input: {
                  id: briefId,
                  name: briefName,
                },
              },
              errorPolicy: 'ignore',
              update: (cache, { data }) => {
                cache.writeQuery({
                  query: GetBriefDocument,
                  variables: {
                    id: projectId,
                  },
                  data: {
                    getBrief: {
                      ...newBrief.data?.createBrief.activity,
                      composedDocument: {
                        id: data?.updateBriefComposeDocument.brief.composedDocument?.id,
                        name: briefName,
                      },
                    },
                  },
                })
              },
            })
            const signedUrl = signedUrlResponse.data?.updateBriefComposeDocument.url

            await composedUpload(content, signedUrl)
          }
        }
      }
    } catch (err) {
      const error = err as Error
      toastNotifications(error.message).error()
    }
  }
}
