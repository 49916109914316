import styled from '@emotion/styled'
import { colors } from '../../../theme/Colors'

export const BTTaskDetailsWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  background-color: ${colors.white};
  border-radius: 5px;
  outline: none;

  .scroll-wrapper {
    max-height: 70px;
    overflow-x: hidden;
    padding-top: 5px;

    &::-webkit-scrollbar {
      border-radius: 15px;
      height: 10px;
      width: 12px;
      background-color: ${colors.navyGrey[300]};
      cursor: pointer;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${colors.navyGrey[200]};
      border-radius: 10px;
      cursor: pointer;
    }
  }

  .task-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 290px;

    .task-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 15px;
    }

    .task-main-block {
      display: flex;
      flex-direction: column;
      width: 100%;

      .text {
        word-break: break-all;
      }

      span {
        width: 100%;
        padding-bottom: 15px;
      }

      .basement-block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 15px;

        .assignees-block {
          display: flex;
          align-items: center;
        }

        .due-date {
          display: flex;
          align-items: center;
          color: ${colors.projectColors.main};

          span {
            padding-bottom: 0;
            font-size: 10px;
            line-height: 10px;
            padding-left: 5px;
          }
        }
      }
    }

    .task-checklist {
      justify-self: flex-start;
      width: 100%;
      padding-top: 15px;
      border-top: 1px solid ${colors.navyGrey[300]};

      .MuiFormControlLabel-root {
        width: 100%;

        input {
          padding: 0;
        }

        span {
          font-size: 10px;
          line-height: 10px;
          color: ${colors.projectColors.black};
          padding: 0;
          padding-left: 10px;
        }

        .MuiFormControlLabel-label {
          padding-left: 5px;
        }
      }

      div {
        font-size: 10px;
        line-height: 10px;
      }
    }
  }
`
