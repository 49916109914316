/* eslint-disable no-shadow */
/* eslint-disable import/order */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { v4 as uuidv4 } from 'uuid'
import { client } from '../../index'
import { Collapse } from '@mui/material'
import { useAppSelector } from 'redux/store'
import { FC, useState, MouseEvent } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { useLocation, useNavigate } from 'react-router-dom'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { PAGINATION_EVENTS } from 'hooks/notifications/types'
import { BTInboxMessage } from './components/bt-inbox-message'
import { GetAllNotificationsDocument } from 'generated/graphql'
import { useSetAsRead } from 'hooks/notifications/useSetAsRead'
import { NOTIFICATIONS_PAGINATION_LIMIT } from 'helpers/constants'
import { useSetAllAsRead } from 'hooks/notifications/useSetAllAsRead'
import { BTNotificationCard } from './components/bt-notification-card'
import { BTPaginationButton } from './components/bt-pagination-button'
import { useNotificationsPagination } from 'hooks/notifications/useNotificationsPagination'
import { NotificationAge, IPreparedNotification, INotificationsSortedByAge } from './bt-inbox.props'
import {
  getPureNotificationId,
  preparedAllNotifications,
  prepareNotificationsAndSortByAge,
} from './helpers'
import {
  BTAgeTitle,
  BTInboxHeader,
  BTInboxSubtitle,
  BTInboxBackdrop,
  BTCollapseButton,
  BTNotificationList,
  BTInboxMainWrapper,
  BTNotificationsEmpty,
  BTInboxContentWrapper,
  BTNotificationItemUnderline,
} from './bt-inbox.presets'

export const BTInbox: FC = () => {
  const [selectedId, setSelectedId] = useState<string>('')
  const [collapseToday, setCollapseToday] = useState(true)
  const [collapseLastWeek, setCollapseLastWeek] = useState(true)
  const [nextReadPage, setNextReadPage] = useState<number>(2)
  const [nextUnreadPage, setNextUnreadPage] = useState<number>(2)
  const [messageWindowOpen, setMessageWindowOpen] = useState(false)

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const setNotificationAsRead = useSetAsRead()
  const pagination = useNotificationsPagination()
  const setAllNotificationsAsRead = useSetAllAsRead()

  const userId = useAppSelector((state) => state?.login?.whoami?.id)

  const notificationsCache = client.readQuery({
    query: GetAllNotificationsDocument,
    variables: {
      unreadArgs: { limit: NOTIFICATIONS_PAGINATION_LIMIT },
      readArgs: { limit: NOTIFICATIONS_PAGINATION_LIMIT },
    },
  })

  const unread = notificationsCache?.notifications?.unread?.notifications
  const read = notificationsCache?.notifications?.read?.notifications

  const unreadTotalPages = notificationsCache?.notifications?.unread?.pagination?.totalPages
  const readTotalPages = notificationsCache?.notifications?.read?.pagination?.totalPages

  const preparedForCardsNotifications = prepareNotificationsAndSortByAge(unread, read)
  const allNotifications = preparedAllNotifications(unread, read)

  const notificationsNotEmpty =
    !!unread?.length ||
    !!preparedForCardsNotifications?.read.today.length ||
    !!preparedForCardsNotifications?.read.lastWeek.length

  const moreUnreadButtonCondition = unreadTotalPages > 1 && nextUnreadPage <= unreadTotalPages

  const moreReadButtonCondition =
    readTotalPages > 1 &&
    nextReadPage <= readTotalPages &&
    !!preparedForCardsNotifications?.read.lastWeek.length

  const findNotification = () => {
    return allNotifications?.find((notification) => {
      const pureId = getPureNotificationId(selectedId)

      return notification.id === pureId
    })
  }

  const selectedNotification = findNotification()

  const closeInboxBar = (event: MouseEvent<HTMLDivElement>) => {
    const target = event.target as Element
    event.stopPropagation()

    if (target.id === 'backdrop') {
      navigate(pathname)
    }
  }

  const setAllReadHandler = async () => {
    await setAllNotificationsAsRead(userId)
  }

  const selectNotification = async (id: string, selectable: boolean, isRead: boolean) => {
    const pureId = getPureNotificationId(id)

    if (selectable) {
      if (selectedId === id) {
        setSelectedId('')
        setMessageWindowOpen(false)
      } else {
        setSelectedId(id)
        setMessageWindowOpen(true)
        if (!isRead) {
          await setNotificationAsRead(pureId, userId)
        }
      }
    } else {
      if (messageWindowOpen) {
        setMessageWindowOpen(false)
        setSelectedId('')
      }

      if (!isRead) {
        await setNotificationAsRead(pureId, userId)
      }
    }
  }

  const checkForSelected = (id: string) => {
    return id === selectedId
  }

  const renderNotifications = (
    notifications: (IPreparedNotification | undefined)[],
    age?: NotificationAge
  ) => {
    return (
      <BTNotificationList>
        {notifications &&
          notifications.map((notification: any, index: number) => {
            const underlineCondition: boolean = notifications.length !== index + 1
            const isSelected =
              notification.selectable && checkForSelected(`${age}_${notification.id}`)

            return (
              <div key={uuidv4()}>
                <BTNotificationCard
                  isSelected={isSelected}
                  date={notification.date}
                  onClick={selectNotification}
                  isRead={notification.isRead}
                  id={`${age}_${notification.id}`}
                  technical={notification.technical}
                  selectable={notification.selectable}
                  avatarUrl={notification.data.avatarUrl}
                  projectId={notification.data.projectId}
                  clientName={notification.data.clientName}
                  activityId={notification.data.activityId}
                  projectName={notification.data.projectName}
                  message={notification.data.notificationText}
                  activityType={notification.data.activityType}
                  activityName={notification.data.activityName}
                  projectColor={notification.data.projectColor}
                />
                {underlineCondition && <BTNotificationItemUnderline />}
              </div>
            )
          })}
      </BTNotificationList>
    )
  }

  const collapseList = (event: MouseEvent<HTMLButtonElement>) => {
    switch (event.currentTarget.id) {
      case NotificationAge.TODAY:
        if (selectedId.includes(NotificationAge.TODAY)) {
          setSelectedId('')
        }
        setCollapseToday((state) => !state)
        break

      case NotificationAge.LAST_WEEK:
        if (selectedId.includes(NotificationAge.LAST_WEEK)) {
          setSelectedId('')
        }
        setCollapseLastWeek((state) => !state)
        break

      default:
        break
    }
  }

  const unreadPaginationHandler = async () => {
    await pagination(PAGINATION_EVENTS.MORE_UNREAD, nextUnreadPage)
    setNextUnreadPage((state) => state + 1)
  }

  const readPaginationHandler = async () => {
    await pagination(PAGINATION_EVENTS.MORE_READ, nextReadPage)
    setNextReadPage((state) => state + 1)
  }

  const filterNotifications = (preparedNotifications: INotificationsSortedByAge | undefined) => {
    return (
      <>
        {/* Unread notifications */}
        {/* today */}
        {preparedNotifications && !!preparedNotifications.unread.today.length && (
          <>
            <BTAgeTitle>Today</BTAgeTitle>
            {renderNotifications(preparedNotifications.unread.today, NotificationAge.TODAY)}
          </>
        )}
        {/* lastWeek */}
        {preparedNotifications && !!preparedNotifications.unread.lastWeek.length && (
          <>
            <BTAgeTitle>Last Week</BTAgeTitle>
            {renderNotifications(preparedNotifications.unread.lastWeek, NotificationAge.LAST_WEEK)}
          </>
        )}
        {/* older */}
        {preparedNotifications && !!preparedNotifications.unread.older.length && (
          <>
            <BTAgeTitle>Older</BTAgeTitle>
            {renderNotifications(preparedNotifications.unread.older, NotificationAge.OLDER)}
          </>
        )}

        {moreUnreadButtonCondition && <BTPaginationButton handler={unreadPaginationHandler} />}

        {/* Read notifications */}
        <BTInboxHeader>
          <BTInboxSubtitle>Read</BTInboxSubtitle>
        </BTInboxHeader>
        {/* today */}
        {preparedNotifications && !!preparedNotifications.read.today.length && (
          <>
            <BTCollapseButton type="button" id="today" onClick={collapseList}>
              <BTAgeTitle>Today</BTAgeTitle>
              {!collapseToday ? (
                <ExpandMoreIcon fontSize="small" />
              ) : (
                <ExpandLessIcon fontSize="small" />
              )}
            </BTCollapseButton>

            <Collapse in={collapseToday} timeout="auto" unmountOnExit>
              {renderNotifications(preparedNotifications.read.today, NotificationAge.TODAY)}
            </Collapse>
          </>
        )}
        {/* lastWeek */}
        {preparedNotifications && !!preparedNotifications.read.lastWeek.length && (
          <>
            <BTCollapseButton type="button" id="lastWeek" onClick={collapseList}>
              <BTAgeTitle>Last Week</BTAgeTitle>
              {!collapseLastWeek ? (
                <ExpandMoreIcon fontSize="small" />
              ) : (
                <ExpandLessIcon fontSize="small" />
              )}
            </BTCollapseButton>
            <Collapse in={collapseLastWeek} timeout="auto" unmountOnExit>
              {renderNotifications(preparedNotifications.read.lastWeek, NotificationAge.LAST_WEEK)}
            </Collapse>
          </>
        )}
        {moreReadButtonCondition && <BTPaginationButton handler={readPaginationHandler} />}
      </>
    )
  }

  return (
    <BTInboxBackdrop onClick={closeInboxBar} id="backdrop">
      <BTInboxMainWrapper>
        <BTInboxContentWrapper>
          <BTInboxHeader>
            <BTInboxSubtitle>Inbox</BTInboxSubtitle>

            {notificationsNotEmpty && (
              <button type="button" onClick={setAllReadHandler}>
                Mark all as Read
              </button>
            )}
          </BTInboxHeader>

          <ScrollContainer className="scroll" hideScrollbars={false} horizontal={false} vertical>
            {notificationsNotEmpty ? (
              filterNotifications(preparedForCardsNotifications)
            ) : (
              <BTNotificationsEmpty>You have no notifications.</BTNotificationsEmpty>
            )}
          </ScrollContainer>
        </BTInboxContentWrapper>
      </BTInboxMainWrapper>

      {messageWindowOpen && selectedNotification && (
        <BTInboxMessage
          senders={selectedNotification.data.sender}
          purpose={selectedNotification.data.purpose}
          message={selectedNotification.data.message}
          recipients={selectedNotification.data.recipients}
          projectId={selectedNotification.data.projectId}
          clientName={selectedNotification.data.clientName}
          activityId={selectedNotification.data.activityId}
          projectName={selectedNotification.data.projectName}
          projectColor={selectedNotification.data.projectColor}
          activityType={selectedNotification.data.activityType}
          activityName={selectedNotification.data.activityName}
        />
      )}
    </BTInboxBackdrop>
  )
}
