import { MaybePad, Pad } from './bt-questions-page-pads.props'

export function mapQuestionsPads(maybePads: MaybePad): Pad[] | null {
  if (!maybePads) {
    return null
  }

  return maybePads.map((pad) => {
    return { name: pad, isSelected: false }
  })
}
