/* eslint-disable no-unused-vars */
import { FC, MouseEvent } from 'react'
import { ButtonWrapper } from './bt-next-page-button.presets'
import { NextPageIcon } from './next-page-icon'

interface BTNextPageButtonProps {
  id: string
  handler: (e: MouseEvent<HTMLButtonElement>) => void
}

export const BTNextPageButton: FC<BTNextPageButtonProps> = ({ id, handler }) => {
  return (
    <ButtonWrapper type="button" id={id} onClick={handler}>
      <NextPageIcon />
    </ButtonWrapper>
  )
}
