import styled from '@emotion/styled'

export const BTNoAssetWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;

  span {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  .arrow {
    position: absolute;
    right: 360px;
    top: 230px;
    width: 171px;
    height: 240px;
  }
`
