import { LoadingButton } from '@mui/lab'
import { TextField } from '@mui/material'
import {
  useAddImageFeedback,
  useRemoveImageFeedback,
  useUpdateFeedbackMessage,
} from 'hooks/feedback'
import { ChangeEvent, FC, useState } from 'react'
import { useAppSelector } from 'redux/store'
import { BTMessageTooltipWrapper } from './bt-message-tooltip.presets'

import type { IBTMessageTooltipProps } from './bt-message-tooltip.props'

export const BTMessageTooltip: FC<IBTMessageTooltipProps> = ({
  closeHandler,
  feedbackId,
  message,
  messageId,
  setPointData,
  position,
}) => {
  const { assetId } = useAppSelector((state) => state.sidebarApps)

  const [value, setValue] = useState(message ?? '')
  const [loading, setLoading] = useState(false)

  const addPointMessage = useAddImageFeedback()
  const updatePointMessage = useUpdateFeedbackMessage()
  const removePointMessage = useRemoveImageFeedback()

  const onChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value: inputValue } = target
    setValue(inputValue)
  }

  const addMessage = async () => {
    setLoading(true)
    if (assetId && feedbackId && value && !message) {
      const { page, x, y } = position

      await addPointMessage(assetId, value, feedbackId, x, y, page)
    } else if (feedbackId && messageId && message !== value && value) {
      await updatePointMessage(feedbackId, messageId, value)
    }
    setLoading(false)
    closeHandler()

    if (setPointData) {
      setPointData(undefined)
    }
  }

  const removePointComment = async () => {
    setLoading(true)

    if (!message && setPointData) {
      setPointData(undefined)
    } else if (messageId) {
      await removePointMessage(messageId)
    }

    setLoading(false)
    closeHandler()
  }

  return (
    <BTMessageTooltipWrapper>
      <TextField rows={2} multiline value={value} onChange={onChange} placeholder="Comment..." />
      <div className="buttons">
        <LoadingButton
          variant="contained"
          color="secondary"
          disabled={loading}
          onClick={removePointComment}
        >
          Remove
        </LoadingButton>
        <LoadingButton
          variant="contained"
          type="submit"
          disabled={value?.trim() === ''}
          onClick={addMessage}
          loading={loading}
        >
          {message ? 'Save' : 'Done'}
        </LoadingButton>
      </div>
    </BTMessageTooltipWrapper>
  )
}
