import styled from 'styled-components'
import DatePicker from 'react-datepicker'
import EventIcon from '@mui/icons-material/Event'
const BTDatePicker = styled(DatePicker)`
  & {
    width: auto;
    outline: none;
    border: none;
    background: transparent;
    color: #333333;
    ${({ theme }) => ({ ...theme.typography?.regular })}
    min-width: 28%;
  }
`
const BTDatePickerIcon = styled(EventIcon)`
  & {
    cursor: pointer;
    color: #828282;
  }
`
export { BTDatePicker, BTDatePickerIcon }
