import * as Yup from 'yup'
import { useFormik } from 'formik'
import { colors } from 'theme/Colors'
import { LoadingButton } from '@mui/lab'
import { PuffLoader } from 'react-spinners'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import { ChangeEvent, useEffect, useState } from 'react'
import { Button, MenuItem, TextField } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { toastNotifications } from 'helpers/toastNotifications'
import { setupOrganizations, setupWhoami } from 'redux/LoginSlice'
import { BTOrganizationSettingsWrapper } from './bt-organization-settings.presets'
import { BTChangeCurrentOrganization } from 'components/bt-change-current-organization'
import { OrganizationSettingsFormValues, OrganizationTypes } from './bt-organization-settings.props'
import {
  OrganizationRole,
  OrganizationType,
  OrganizationFragment,
  useGetOrganizationRangesQuery,
  useUpdateOrganizationMutation,
  useMeWithOrganizationLazyQuery,
} from 'generated/graphql'

const EdidOrganizationValidationSchema = Yup.object({
  name: Yup.string().required('Organization name is required'),
  range: Yup.string().required('Number of Employees is required'),
  type: Yup.string().required('Organization type is required'),
})

export const BTOrganizationSettings = () => {
  const [isEdit, setIsEdit] = useState(false)
  const [canEdit, setCanEdit] = useState(true)
  const [isChangeOrganizationModalOpen, setIsChangeOrganizationModalOpen] = useState(false)
  const [organization, setOrganization] = useState<OrganizationFragment>()
  const { data: ranges, loading } = useGetOrganizationRangesQuery()
  const dispatch = useAppDispatch()
  const [whoamiQuery] = useMeWithOrganizationLazyQuery()

  const { currentOrganizationId, organizations, whoami } = useAppSelector((state) => state.login)
  const allOrganizations = useAppSelector((state) => state.login.organizations)

  const [updateOrganization] = useUpdateOrganizationMutation()

  const openChangeOrganizationModalHandler = () => setIsChangeOrganizationModalOpen(true)
  const closeChangeOrganizationModalHandler = () => setIsChangeOrganizationModalOpen(false)

  const onSubmit = async ({ name, range, type }: OrganizationSettingsFormValues) => {
    if (formik.isValid && organization) {
      const response = await updateOrganization({
        variables: { input: { id: organization?.id!, name, range, type } },
        optimisticResponse: {
          updateOrganization: {
            organization: {
              ...organization,
              name,
              type,
              range,
            },
          },
        },
      })

      if (response.data) {
        const updatedOrganizations = organizations.map((elem) =>
          elem.id === currentOrganizationId ? { ...elem, name, type, range } : elem
        )
        dispatch(setupOrganizations(updatedOrganizations))
      }
      setIsEdit(false)
    }
  }

  const formik = useFormik<OrganizationSettingsFormValues>({
    initialValues: {
      name: 'Organization name',
      range: '1-5',
      type: OrganizationType.DesignAgency,
    },
    validationSchema: EdidOrganizationValidationSchema,
    validateOnMount: true,
    onSubmit,
  })

  const onChange = (
    { target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string
  ) => {
    const { value } = target

    if (value) {
      formik.setFieldValue(name, value)
      formik.validateField(name)
    }
  }

  const organizationTypes = Object.entries(OrganizationTypes).map(([name, title]) => ({
    name,
    title,
  }))

  const setOrganiation = () => {
    if (allOrganizations.length > 0) {
      const currentOrganization = allOrganizations.filter(({ id }) => id === currentOrganizationId)

      setOrganization(currentOrganization[0])
    }
  }

  useEffect(() => {
    setOrganiation()
    setIsEdit(false)
  }, [currentOrganizationId])

  useEffect(() => {
    if (!canEdit) {
      toastNotifications(`You can't edit this organization 
      because you aren't an Admin. 
      Please contact with the admistrator to change the data`).warning()
    }
  }, [canEdit])

  useEffect(() => {
    if (organization && whoami) {
      formik.setFieldValue('name', organization.name)
      formik.setFieldValue('type', organization.type || OrganizationType.DesignAgency)
      formik.setFieldValue('range', organization.range || '1-5')

      const currentMember = organization.members.filter(({ user }) => user?.id === whoami?.id)

      if (currentMember.length) {
        if (currentMember[0].role !== OrganizationRole.Admin) {
          setCanEdit(false)
        } else {
          setCanEdit(true)
        }
      }
    } else {
      whoamiQuery().then((response) => {
        dispatch(setupWhoami(response.data))
      })
      setOrganiation()
    }
  }, [organization, whoami])

  useEffect(() => {
    if (allOrganizations.length > 1) {
      openChangeOrganizationModalHandler()
    }
  }, [allOrganizations])

  if (loading) {
    return <PuffLoader size={100} color={colors.projectColors.main} />
  }

  return (
    <>
      <BTOrganizationSettingsWrapper sx={{ boxShadow: 3 }}>
        <form onSubmit={formik.handleSubmit}>
          <div className="head-block">
            <span className="title">Organisation</span>
            {!isEdit && canEdit && (
              <Button endIcon={<ModeEditIcon />} onClick={() => setIsEdit(!isEdit)}>
                Edit
              </Button>
            )}
          </div>
          <div className="main-block">
            <div className="input-block">
              <span>Name</span>
              <TextField
                fullWidth
                label
                disabled={!isEdit}
                autoComplete="off"
                value={formik.values.name}
                onChange={(event) => onChange(event, 'name')}
                error={!!formik.errors.name}
                helperText={formik.errors.name}
              />
            </div>
            <div className="input-block">
              <span>Number of Employees</span>
              <TextField
                fullWidth
                label
                disabled={!isEdit}
                select
                value={formik.values.range}
                autoComplete="off"
                onChange={(event) => onChange(event, 'range')}
              >
                {ranges?.organizationRanges.map(({ from, to, id }, index) => {
                  const value =
                    index !== ranges.organizationRanges.length - 1
                      ? `${from}-${to}`
                      : `${from}${to}`
                  return (
                    <MenuItem key={id} value={value}>
                      {value}
                    </MenuItem>
                  )
                })}
              </TextField>
            </div>
            <div className="input-block">
              <span>Type of Organisation</span>
              <TextField
                fullWidth
                label
                disabled={!isEdit}
                select
                value={formik.values.type}
                onChange={(event) => onChange(event, 'type')}
              >
                {organizationTypes.map(({ name, title }) => (
                  <MenuItem key={name} value={name}>
                    {title}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
          {isEdit && (
            <LoadingButton
              className="save-button"
              fullWidth
              variant="contained"
              type="submit"
              loading={formik.isSubmitting}
            >
              Save Changes
            </LoadingButton>
          )}
        </form>
      </BTOrganizationSettingsWrapper>

      <BTChangeCurrentOrganization
        isOpened={isChangeOrganizationModalOpen}
        handleModalClose={closeChangeOrganizationModalHandler}
      />
    </>
  )
}
